.achivements {
  position: relative;
  padding: 0 80px 45px;

  @media (max-width: 1000px) {
    padding: 0 16px 60px;
  }

  @media (max-width: 900px) {
    padding-right: 9px;
  }

  @media (max-width: 600px) {
    padding-bottom: 32px;
  }
}

.achivements__title {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #262626;

  @media (max-width: 599px) {
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.83333px;
  }
}

.achivements__qty {
  font-weight: normal;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: #A5A8AD;
  position: absolute;
  right: 80px;
  top: 0;

  @media (max-width: 900px) {
    display: none;
  }
}

.achivements__list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    margin-top: 21px;
  }
}

.achivements__item {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  filter: grayscale(100%);
  z-index: 101;

  &:last-of-type {
    margin-right: 0;
  }

  img:first-of-type {
    display: none;
  }

  img:nth-of-type(2) {
    display: block;
  }

  &--done {
    filter: none;

    img:first-of-type {
      display: block;
    }

    img:nth-of-type(2) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    margin-right: 10px;

    img {
      width: 70px;
      height: 70px;
    }
  }

  @media (max-width: 599px) {
    margin-bottom: 8px;

    img {
      width: 62px;
      height: 62px;
    }
  }
}

.achivements__tooltip {
  display: none;
  position: absolute;
  background-color: #ffffff;
  border: 0.5px solid #EEEEEE;
  box-shadow: 0px 25px 60px rgba(38, 38, 38, 0.07);
  border-radius: 10px;
  width: 294px;
  padding: 15px 19px 27px;
  z-index: 100;
  opacity: 0;
  left: 5px;
  transition: opacity 0.3s ease-in-out;

  h3 {
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    color: #0D2038;
    margin-bottom: 16px;

    span {
      margin-left: 6px;
    }
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    margin-bottom: 18px;
  }

  @media (max-width: 900px) {
    &:after {
      left: 19px;
    }
  }
}

.achivements__item:hover,
.achivements__item:focus {
  z-index: 102;

  &:after {
    content:'';
    display:block;
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    transform: translateX(-50%) rotate(45deg);
    border-left: 0.5px solid #EEEEEE;
    border-top: 0.5px solid #EEEEEE;
    position:absolute;
    //border-left: 16px solid transparent;
    //border-right: 16px solid transparent;
    //border-bottom: 16px solid #ffffff;
    left: 50%;
    bottom: -12px;
    z-index: 102;
  }
}

.achivements__item:hover .achivements__tooltip {
  display: block;
  opacity: 1;
}


.achivements-mini {
  display: inline-flex;
  flex-wrap: wrap;
  align-self: flex-start;
}

.achivements-mini__item {
  display: inline-block;
  margin-right: 5px;
  line-height: 0;
  font-size: 0;

  img {
    height: 17px;
    width: auto;
  }
}

.achivements-mini__count {
  height: 17px;
  width: 17px;
  background-color: #FFD24D;
  border-radius: 50%;
  font-weight: bold;
  font-size: 9px;
  line-height: 17px;
  text-align: center;
  padding-right: 3px;
}

p.achivements__ya-share {
  padding-top: 16px;
  padding-bottom: 9px;
  margin: 0;
  border-top: 1px solid #EEEEEE;
  font-size: 12px;
  line-height: 16px;
  color: #A5A8AD;
  margin-left: 2px;
}

.achivements .ya-share2 {
  margin-left: 3px;
}

.achivements .ya-share2__container_size_m .ya-share2__item {
  margin-right: 12px;
}

.achivements .ya-share2__item .ya-share2__badge {
  background: transparent;
}

.achivements .ya-share2__item_service_facebook .ya-share2__icon {
  background: url(/media/images/fb.svg);
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

.achivements .ya-share2__item_service_vkontakte .ya-share2__icon {
  background: url(/media/images/vk.svg);
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

.achivements .ya-share2__item_service_odnoklassniki .ya-share2__icon {
  background: url(/media/images/odnoclassniki.svg);
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

.achivements .ya-share2__item_service_whatsapp .ya-share2__icon {
  background: url(/media/images/whatsapp.svg);
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}