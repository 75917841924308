@keyframes bounce {
    0% {
        left: 6px;
    }
    80% {
        left: calc(100% - 30px);
    }
    90% {
        left: calc(100% - 35px);
    }
    100% {
        left: calc(100% - 30px);
    }
}

@keyframes bounceOut {
    0% {
        left: calc(100% - 30px);
    }
    80% {
        left: 6px;
    }
    90% {
        left: 10px;
    }
    100% {
        left: 6px;
    }
}