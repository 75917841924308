.page_footer {
    font-family: $fontOpenSans;
    position: relative;
    border-top: 1px solid $lineColor;
    padding: 17px 16px 17px 20px;
    flex-shrink: 0;
    box-sizing: border-box;

    @include for-tablet-portrait-up {
        padding: 25px 42px 115px;

        .gr {
            position: absolute;
            bottom: -84px;
            right: 0;
        }
    }

    @include for-tablet-landscape-up {
        padding: 25px 26px 37px;

        .gr {
            position: absolute;
            bottom: 0;
            right: 92px;
        }
    }

    @include desktop-up {
        height: $footerHeight;
        padding: 54px 40px 36px 36px;

        .gr {
            right: 0;
        }
    }
}

.page_footer--auth {
    height: 100px;
    border-top: none;
}

.page_footer__inner {
    position: relative;
    height: 100%;
}

.footer_logo {
    display: none;

    @include tablet-up {
        display: block;
        width: 206px;
        height: 47px;
    }

    @include desktop-up {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.footer_logo__img {
    width: 100%;
}

.footer_nav {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 0;
    justify-content: space-between;

    @include for-tablet-portrait-up {
        justify-content: flex-start;
    }

    @include for-tablet-landscape-up {
        padding-top: 31px;
    }

    @include desktop-up {
        padding-top: 15px;
        margin-right: 0;
        justify-content: flex-end;
        width: 75.5%;
        margin-left: auto;
        padding-right: 70px;
    }
}

.footer_nav__section {
    width: calc(50% - 19px);

    &--last {
        width: 100%;
        margin-top: -15px;
    }

    &--support {
        margin-left: calc(50% + 18px);
    }

    &--telegram {
        width: 100%;
        margin-top: 15px;

        .footer_nav__title {
            display: none;
        }
    }

    @include for-tablet-portrait-up {
        width: 31%;

        &--last {
            width: 35%;
            margin-left: auto;
            margin-top: 0;
        }

        &--support {
            width: 100%;
            margin-left: 0;
            margin-bottom: 15px;
        }

        &--telegram {
            width: 100%;
            margin-top: 0;

            .footer_nav__title {
                display: block;
            }
        }

    }

    @include for-tablet-landscape-up {
        width: calc(25% - 21px);
        margin-right: 20px;
        margin-bottom: 70px;

        &--last {
            width: 50%;
            margin-right: 0;
            display: flex;
        }

        &--support {
            width: 50%;
        }

        &--telegram {
            width: 50%;
        }
    }

    @include desktop-up {
    //    max-width: 180px;
        margin-bottom: 0;
    //
    //    &--last {
    //        max-width: 450px;
    //    }
    }
}

.footer_nav__title {
    font-size: 12px;
    font-weight: 600;
    color: $textPrimaryColor;
    margin-bottom: 6px;

    @include tablet-up {
        font-size: 14px;
        margin-bottom: 16px;
    }
}

.footer_nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer_nav__item {
    font-size: 12px;
    line-height: 2.5;
    color: $textSecondaryColor;

    @include tablet-up {
        font-size: 14px;
        line-height: 2.14;
    }
}

.footer_nav__link {
    color: $textSecondaryColor;

    &:hover {
        color: $textSecondaryHoverColor;
    }
}

.page_footer__copyright {
    font-size: 11px;
    color: $textSecondaryColor;
    margin-bottom: 8px;

    @include for-tablet-portrait-up {
        position: absolute;
        bottom: -84px;
        left: 0;
        margin-bottom: 0;
    }

    @include for-tablet-landscape-up {
        position: absolute;
        bottom: 0;
        left: 62px;
        margin-bottom: 0;
    }
}

.gr__logo {
    font-size: 11px;
    color: $textSecondaryColor;
}

@media screen and (max-width: 415px) {
  .page_footer--auth {
    text-align: center;
  }
}