.progress-bar-modal {
  width: 100%;
  max-width: 413px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #EDEEF0;
    height: 2px;
    width: 100%;
    z-index: -1;
  }

  li {
    height: 30px;
    width: 30px;
    border: 2px solid #EDEEF0;
    border-radius: 50%;
    background-color: #ffffff;
    position: relative;
  }

  .done {
    background-color: $btnPrimaryColor;
    border: 2px solid $btnPrimaryColor;

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 9px;
      left: 9px;
      border-bottom: 2px solid #000000;
      border-left: 2px solid #000000;
      transform: rotate(-45deg);
      height: 4px;
      width: 8px;
      z-index: 1;
      background-color: transparent;
    }
  }

  .active {
    box-shadow: 0px 4px 16px rgba(38, 38, 38, 0.15);
    border: 2px solid #ffffff;

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 12px;
      width: 12px;
      z-index: 1;
      border-radius: 50%;
      background-color: $btnPrimaryColor;
    }
  }

  @media (max-width: 599px) {
    max-width: 72%;

    li {
      height: 14px;
      width: 14px;

      &.done {
        &:after {
          height: 4px;
          width: 6px;
          left: 1px;
          top: 1px;
          border-width: 1px;
        }
      }

      &.active {
        &:after {
          height: 6px;
          width: 6px;
        }
      }
    }
  }
}

.ok-form {
  position: relative;
  filter: blur(8px);

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    background: rgba(255, 255, 255, 0.7);
  }
}