@charset "UTF-8";
.tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
  font-size: 11px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  letter-spacing: 1.8px;
  color: #262626;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 640px) {
  .tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
    font-size: 12px;
    letter-spacing: 2px;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

article,
details,
section,
summary,
aside,
main,
menu,
nav,
figcaption,
figure,
footer,
header {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

body {
  margin: 0;
  line-height: 20px;
  color: #000;
  background: #fff;
  font-family: "Open-Sans", "Arial", sans-serif;
}

body:before {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1000;
  content: url("/media/images/prev_btn.png") url("/media/images/next_btn.png");
}

html {
  text-size-adjust: 100%;
}

a {
  color: #263238;
  transition-property: color, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  text-decoration: none;
  display: inline-block;
  background-color: transparent;
}

@media (min-width: 1000px) {
  a:hover {
    background-color: transparent;
    color: #fd2d2c;
    border-color: #fd2d2c;
    outline: 0;
  }
}

a:active {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

b,
strong {
  font-weight: bold;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  appearance: button;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  appearance: textfield;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  vertical-align: middle;
}

td,
th {
  padding: 0;
}

ol,
ul {
  list-style: none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

a img {
  border: none;
}

.span-reg__label {
  width: 160px;
}

.span-reg__options {
  width: 247px;
}

.span-reg__options--photo {
  width: 548px;
}

.row--empty {
  display: none;
}

.row {
  zoom: 1;
  padding-left: 14px;
  padding-right: 13px;
}

.row:before,
.row:after {
  content: '';
  display: table;
}

.row:after {
  clear: both;
}

.row .row {
  margin-left: -27px;
  margin-right: -27px;
}

.span--u-info {
  width: 297px;
}

.span1 {
  width: 96px;
  margin-left: 13px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.span2 {
  width: 218px;
  margin-left: 10px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.span3 {
  width: 342px;
  margin-left: 11px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.span4 {
  width: 465px;
  margin-left: 9px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.span5 {
  width: 588px;
  margin-left: 13px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.span6 {
  width: 711px;
  margin-left: 13px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.span7 {
  width: 834px;
  margin-left: 13px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.span8 {
  width: 957px;
  margin-left: 13px;
  margin-right: 14px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.span {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin-left: 13px;
  margin-right: 14px;
}

.span2--with-paddings {
  width: 246px;
  margin-left: 0;
  margin-right: -13px;
}

.span--center {
  margin-left: auto;
  margin-right: auto;
}

.span-reg__dropdown {
  width: 265px;
  margin-right: 0;
}

.span-reg__dropdown .dropdown-skill-select {
  margin-left: 3px;
}

.span-reg__dropdown + .span-reg__dropdown {
  margin-left: 4px;
}

.form-span {
  width: 529px;
  padding-right: 32px;
  padding-left: 32px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.login-form {
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 32px;
}

.wrapper {
  width: 1020px;
  position: relative;
  margin: 0 auto 30px;
  flex-grow: 1;
  flex-shrink: 0;
}

.link__text {
  transition-property: border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  border-bottom: 2px solid transparent;
}

.hor-pad {
  padding-left: 14px;
  padding-right: 13px;
}

.link__text--gray {
  color: #808080;
  border-bottom: 2px solid transparent;
  transition-property: color, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.fancybox-skin {
  padding: 0 !important;
}

.fancybox-title {
  padding-left: 10px;
  padding-right: 10px;
}

.link__text--yellow {
  border-bottom: 2px solid #ffda4a;
}

.link__text--bord {
  border-bottom: 2px solid #eceff1;
}

.link__text--lightgray {
  color: #808080;
  border-bottom: 2px solid #eceff1;
}

.link {
  cursor: pointer;
  transition-property: color, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.link:hover {
  background-color: transparent;
  color: #fd2d2c;
  border-color: #fd2d2c;
}

.link:hover .header__link--yellow {
  border-color: #fd2d2c;
}

.link:hover .header__link--orange {
  border-color: #fd2d2c;
}

.link:hover .link__text {
  color: #fd2d2c;
  border-bottom: 2px solid #fd2d2c;
}

.link:hover .b-bottom--text {
  color: #fd2d2c;
  border-bottom: 2px solid #fd2d2c;
}

.link:hover .link__text--lightgray {
  border-bottom: 2px solid #eceff1;
}

.link:hover .link__text--gray {
  border-color: #808080;
  color: #808080;
}

.link:hover .link__text--footer-gray {
  border-color: #a3a6a8;
}

.link:hover .link__text--black {
  color: #263238;
  border-bottom: 2px solid transparent;
}

.link--with-border {
  border-bottom: 2px solid #fd2d2c;
}

.yellow-button {
  border: 2px solid #feda4a;
  background-color: #feda4a;
  padding: 10px 20px;
  border-radius: 70px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: black;
}

.yellow-button:hover {
  font-family: 'Ubuntu', sans-serif;
  background-color: transparent;
  color: black;
  border: 2px solid #feda4a;
}

.subscribe-title {
  font-style: inherit;
  font-family: inherit;
}

.unsubscribe_btn {
  width: 300px;
}

a.btn {
  color: #fff;
}

.btn {
  color: #fff;
  outline: none;
  background-color: #fd2d2c;
  display: inline-block !important;
  box-sizing: border-box;
  text-transform: uppercase;
  border: none;
  appearance: none !important;
  cursor: pointer;
  transition-property: background, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  padding: 15px 25px;
}

.btn:hover {
  background-color: #fc9801;
}

.btn:active {
  background-color: #fd2d2c;
}

.btn:disabled {
  background-color: #dcdedf !important;
  cursor: not-allowed !important;
}

.bl--line {
  display: inline;
}

.bl--inline {
  display: inline-block;
  white-space: normal;
}

.btn--full-width {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.btn--round {
  border-radius: 2px;
  padding: 10px 12px !important;
}

.btn--transp {
  background-color: transparent;
  border: 2px solid #eceff1;
  color: #eceff1;
  border-radius: 2px;
}

.btn--disable {
  background-color: #dcdedf !important;
  cursor: not-allowed !important;
}

.left-cont {
  float: left;
}

.right-cont {
  float: right;
}

.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.f-label {
  font-size: 24px;
  line-height: 28px;
  font-weight: 100;
}

.f-left {
  text-align: left;
}

.f-center {
  text-align: center;
}

.f-right {
  text-align: right;
}

.f-vcenter {
  vertical-align: middle;
}

.f-vbottom {
  vertical-align: bottom;
}

.f-big {
  font-size: 36px;
  line-height: 40px;
}

.f-med {
  font-size: 18px;
  line-height: 24px;
}

.f-medb {
  font-size: 20px;
  line-height: 24px;
}

.f-giga {
  font-size: 64px;
  line-height: 74px;
}

.f-med-bid {
  font-size: 24px;
  line-height: 28px;
}

.f-smal {
  font-size: 16px;
  line-height: 18px;
}

.f-supsmal {
  font-size: 12px;
  line-height: 13px;
}

.f-light {
  font-weight: 100;
}

.light-text {
  color: #515b60;
  font-weight: 100;
}

.f-med-width {
  font-weight: 800;
}

.mt4 {
  margin-top: 4px;
}

.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

.mt48 {
  margin-top: 48px;
}

.mt56 {
  margin-top: 56px;
}

.mt72 {
  margin-top: 72px;
}

.mt80 {
  margin-top: 80px;
}

.pr8 {
  padding-right: 8px;
}

.ml0 {
  margin-left: 0;
}

.ml8 {
  margin-left: 8px;
}

.ml16 {
  margin-left: 16px;
}

.ml24 {
  margin-left: 24px;
}

.ml38 {
  margin-left: 38px;
}

.ml48 {
  margin-left: 48px;
}

.help-text {
  color: #808080;
}

.help-text--inlblock {
  display: inline-block;
}

.darkgrey-text {
  color: #35464e;
}

.b-top {
  border-top: 1px solid #eceff1;
}

.b-bottom {
  padding-bottom: 32px;
  border-bottom: 1px solid #eceff1;
}

.b-bottom--8 {
  padding-bottom: 8px;
  border-bottom: 1px solid #eceff1;
}

.b-bottom--40 {
  padding-bottom: 40px;
  border-bottom: 1px solid #eceff1;
}

.b-bottom--24 {
  padding-bottom: 32px;
  border-bottom: 1px solid #eceff1;
}

.b-bottom--16 {
  padding-bottom: 16px;
  border-bottom: 1px solid #eceff1;
}

.b-bottom--text {
  border-bottom: 2px solid #eceff1;
  transition-property: color, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.b-bottom--text:hover {
  border-color: inherit;
}

.petrovich__img {
  display: inline;
}

.petrovich__img--right-space {
  margin-right: 8px;
}

.hbln {
  display: none;
}

.ovh {
  word-wrap: break-word;
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.nspace {
  white-space: normal;
}

.aside-login {
  line-height: 24px;
  width: 240px;
}

.dynamic-img {
  position: relative;
  float: left;
  margin-right: 24px;
  margin-top: 16px;
}

.dynamic-img__wrap {
  width: 96px;
  height: 96px;
  overflow: hidden;
}

.input--dynamic-gal_array {
  display: none;
}

.dynamic-img__img {
  width: 96px;
}

.btn--remove {
  z-index: 10;
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #fd2d2c;
  border-radius: 24px;
  top: -12px;
  right: -12px;
  background-position: center;
  background-image: url(/media/images/x.png);
  background-repeat: no-repeat;
  cursor: pointer;
}

a.btn-find-m {
  background-color: #fff;
  color: #fd2d2c !important;
  border: 2px solid #fd2d2c;
  border-radius: 3px;
}

a.btn-find-m:hover {
  color: #fff !important;
  background-color: #fd2d2c;
}

.image_tag--wrapper {
  position: relative;
  width: 220px;
  height: 165px;
  overflow: hidden;
}

.dn {
  display: none;
}

.master_links {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user__ava__img--empty-32 {
  border: 1px solid #808080;
  width: 30px;
  height: 30px;
  display: inline-block;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url(/media/images/fancybox_loading.gif);
  background-position: center center;
  background-repeat: no-repeat;
}

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url(/media/images/fancybox_sprite.png);
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url(/media/images/fancybox_sprite@2x.png);
    background-size: 44px 152px;
    /* The size of the normal image, half the size of the hi-res image */
  }
  #fancybox-loading div {
    background-image: url(/media/images/fancybox_loading@2x.gif);
    background-size: 24px 24px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url(/media/images/fancybox_sprite@2x.png);
    background-size: 44px 152px;
    /* The size of the normal image, half the size of the hi-res image */
  }
  #fancybox-loading div {
    background-image: url(/media/images/fancybox_loading@2x.gif);
    background-size: 24px 24px;
  }
}

.table_link {
  cursor: pointer;
}

.time {
  padding-left: 20px;
  background-image: url(/media/images/time.png);
  background-repeat: no-repeat;
  background-position: 0 2px;
}

.round-ava {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: top;
  border-radius: 24px;
  overflow: hidden;
}

.round-ava--img {
  width: 24px;
  height: 24px;
}

.album__u__text {
  display: inline-block;
  width: 185px;
  margin-left: 8px;
}

.top-ava {
  width: 32px;
  height: 32px;
  display: inline-block;
  overflow: hidden;
}

.top-ava__img {
  width: 32px;
  height: 32px;
}

.txt-ovh {
  text-overflow: ellipsis;
}

.fav__text {
  width: 140px;
  display: inline-block;
  vertical-align: top;
}

.fav__value {
  margin-left: 10px;
  width: 20px;
  display: inline-block;
  vertical-align: top;
}

.notice--info {
  background-color: #fbde42;
  color: #97841c;
}

.notice--info .notice__close {
  background-color: #e0c42d;
  border: 2px solid #fbde42;
}

.notice--alert {
  background-color: #fd2d2c;
  color: #fff;
}

.notice--alert:before {
  background-position: 0 -44px;
}

.notice--alert .notice__close {
  background-color: #c82221;
  border: 2px solid #fd2d2c;
}

.accept_request {
  padding: 24px 16px;
}

.notice__wrapper {
  position: fixed;
  width: 400px;
  top: 130px;
  right: 50px;
  z-index: 10000;
}

.notice + .notice {
  margin-top: 32px;
}

.notice {
  position: relative;
  margin-bottom: 0;
  width: 400px;
  box-sizing: border-box;
  padding-left: 78px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
  border-top: 1px solid #d3d3d3;
  padding-right: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 1000000;
  min-height: 76px;
  transition-property: opacity, margin;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.notice:before {
  content: '';
  width: 44px;
  height: 44px;
  background-image: url(/media/images/notice_icons.png);
  background-repeat: no-repeat;
  position: absolute;
  left: 14px;
  top: 16px;
}

.notice--close {
  opacity: 0;
  filter: alpha(opacity=0);
}

.notice__close {
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 40px;
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
  line-height: 24px;
  color: #fff;
  box-shadow: -3px 3px 0 0 transparent;
  transition-property: box-shadow;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.notice__close:before {
  content: '\2716';
}

.notice__close:hover {
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.75);
}

.pos_r {
  position: relative;
}

.proposal_count {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #fd2d2c;
  color: #fff;
  text-align: center;
  line-height: 24px;
}

.proposal_count--grey {
  background-color: #808080;
}

.add_request--hidden {
  display: none !important;
}

.feedback__pop-up {
  display: none;
}

.text-cell {
  width: 170px;
}

.login-row {
  position: relative;
}

.login-error {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 30px;
  width: 110px;
}

.ellipsis {
  display: block;
  word-wrap: break-word;
  height: 60px;
  overflow: hidden;
}

.ellipsis:before {
  content: '';
  float: left;
  width: 5px;
  height: 60px;
}

.ellipsis > *:first-child {
  float: right;
  width: 100%;
  margin-left: -5px;
}

.ellipsis:after {
  content: '\02026';
  box-sizing: content-box;
  float: right;
  position: relative;
  top: -22px;
  left: 100%;
  width: 3em;
  margin-left: -3em;
  padding-right: 5px;
  text-align: right;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white));
  background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
  background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
  background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
}

.comment.opened {
  height: auto;
}

.comment__full {
  height: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
}

.orange-icon {
  width: 108px;
  height: 108px;
  background-image: url(/media/images/orangeIcons.png);
  background-repeat: no-repeat;
}

.error-text {
  background-color: #fd2d2c;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  text-align: left;
  zoom: 1;
}

.error-text:before,
.error-text:after {
  content: '';
  display: table;
}

.error-text:after {
  clear: both;
}

.error-text:before {
  content: 'Ошибка: ';
  display: inline;
}

.orange-icon__eq1 {
  background-position: 0 -108px;
}

.orange-icon__eq2 {
  background-position: 0 -216px;
}

.orange-list {
  counter-reset: li;
}

.orange-list__itm {
  position: relative;
  padding-left: 24px;
}

.orange-list__itm:before {
  content: counter(li) ".";
  counter-increment: li;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes noticeShow {
  0% {
    visibility: visible;
    opacity: 1;
    filter: none;
  }
  50% {
    visibility: visible;
    opacity: .9;
    filter: alpha(opacity=90);
  }
  100% {
    opacity: 0;
    filter: alpha(opacity=0);
    display: none;
  }
}

li {
  list-style: none;
}

.grid_test_fixed {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.grid_test_fixed .span1 {
  height: 2000px;
  background-color: rgba(0, 0, 0, 0.3);
}

.body--main {
  padding-top: 40px;
  border-top: 1px solid #eceff1;
}

.we-do__img {
  width: 142px;
  height: 32px;
  display: inline;
}

.find-m {
  width: 575px;
}

.find-m:hover .find-m__input {
  border-color: #fc9801;
}

.find-m:hover .find-m__btn {
  background-color: #fc9801;
}

.find-m--short {
  width: 405px;
}

.find-m--short .dropdown--red {
  width: 230px;
}

.find-m__input {
  display: block;
  width: 370px;
  height: 42px;
  border: 2px solid #fd2d2c;
  box-sizing: border-box;
  vertical-align: bottom;
  transition-property: border, box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  padding-left: 10px;
  padding-right: 30px;
  outline: none;
  box-shadow: inset 0 0 0 1px transparent;
}

.find-m__input:focus {
  border-color: #fc9801;
  box-shadow: inset 0 0 0 1px #fc9801;
}

.find-m__btn {
  height: 42px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 42px;
}

.today {
  padding-left: 45px;
  position: relative;
}

.today__itm {
  zoom: 1;
  animation: todayShow .2s ease-out;
  position: relative;
  transition-property: height, margin, opacity, visibility, font-size, left;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.today__itm:before,
.today__itm:after {
  content: '';
  display: table;
}

.today__itm:after {
  clear: both;
}

.today__itm + .today__itm {
  margin-top: 8px;
}

.today__itm__text {
  display: inline-block;
  width: 200px;
  margin-left: 10px;
}

.today__list {
  height: 200px;
  overflow: hidden;
  padding-bottom: 16px;
  border-bottom: 1px solid #eceff1;
}

.today__itm--hidden {
  height: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  font-size: 0;
}

.service_promo {
  border-top: 1px solid #eceff1;
  padding-bottom: 30px;
  padding-top: 30px;
  font-size: 20px;
}

.service_promo--red {
  position: relative;
  background-color: #fd2d2c;
  color: #fff;
  display: inline-block;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 4px;
  font-style: italic;
}

.service_promo--red:before {
  background-image: url(/media/images/redline.png);
  content: '';
  position: absolute;
  width: 89px;
  height: 91px;
  top: -101px;
  left: 48px;
}

.service_promo--login .service_promo--red:before {
  display: none;
}

@keyframes todayShow {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 0;
    visibility: hidden;
    font-size: 0;
  }
}

.main-menu {
  line-height: 100px;
  text-align: center;
  border-top: 1px solid #eceff1;
  border-bottom: 1px solid #eceff1;
}

.main-menu__list {
  display: inline-block;
}

.main-menu__item {
  display: inline-block;
}

.main-menu__item + .main-menu__item {
  margin-left: 40px;
}

.main-menu__item--active .main-menu__link {
  color: #fd2d2c;
}

.main-menu__link {
  line-height: normal;
  font-size: 16px;
}

.main-menu--without-border {
  border-bottom: none;
}

.statistic {
  background-color: #ffda4a;
}

.statistic__list {
  margin-top: 32px;
  margin-bottom: 32px;
}

.statistic__item {
  line-height: normal;
  min-height: 332px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
  position: relative;
  padding-top: 55px;
  box-sizing: border-box;
  text-align: left;
  margin-left: 10px;
  padding-bottom: 20px;
}

.statistic__item:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 20px;
  background-repeat: no-repeat;
}

.statistic__wall:before {
  background-image: url(/media/images/statisticSprite.png);
  width: 40px;
  height: 32px;
  background-position: 0 0;
}

.statistic__brush:before {
  background-image: url(/media/images/statisticSprite.png);
  width: 32px;
  height: 40px;
  background-position: -40px 0;
}

.statistic__home:before {
  background-image: url(/media/images/statisticSprite.png);
  width: 32px;
  height: 33px;
  background-position: -73px 0;
}

.statistic__chat:before {
  background-image: url(/media/images/statisticSprite.png);
  width: 34px;
  height: 40px;
  background-position: -105px 0;
}

.statistic__value {
  font-size: 48px;
  font-weight: 100;
}

.statistic__label {
  font-size: 18px;
  line-height: 21px;
  height: 45px;
}

.statistic__points {
  margin-top: 24px;
}

.statistic__point {
  display: block;
}

.statistic__point + .statistic__point {
  margin-top: 8px;
}

.statistic_point__name {
  width: 130px;
  display: inline-block;
  color: #808080;
}

.statistic_point__value {
  float: right;
  color: #263238;
}

.baner {
  position: relative;
  vertical-align: middle;
}

.baner-240 {
  width: 219px;
  height: 400px;
}

.baner-240 img {
  width: 219px;
}

.baner--link {
  position: relative;
}

.baner--link--768 {
  margin-top: 32px;
}

.baner--link--240 {
  margin-top: 16px;
}

.listing__menu__label {
  color: #808080;
  display: inline-block;
  line-height: 32px;
}

.round-switcher,
.listing__menu__itm {
  line-height: 28px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 40px;
  cursor: pointer;
  border: 2px solid transparent;
  transition-property: border, color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  /* Redesign filter only on user page. To do so the class .is_user had been added */
}

.round-switcher.is_user,
.listing__menu__itm.is_user {
  border: none;
  color: #858c90;
  padding: 0;
  position: relative;
  padding-bottom: 3px;
  margin-left: -7px;
}

.round-switcher.is_user:before,
.listing__menu__itm.is_user:before {
  content: '';
  position: absolute;
  right: -22px;
  top: 6px;
  width: 12px;
  height: 8px;
  background-image: url(/media/images/ic_sort.png);
}

.round-switcher.is_user:hover,
.listing__menu__itm.is_user:hover {
  border: none;
  color: #fd2d2c;
}

.round-switcher.is_user:hover:before,
.listing__menu__itm.is_user:hover:before {
  content: "";
  position: absolute;
  right: -22px;
  top: 6px;
  width: 12px;
  height: 8px;
  background-image: url("/media/images/sort-down.png");
}

.round-switcher.is_rating,
.listing__menu__itm.is_rating {
  border: none;
  color: #858c90;
  padding: 0;
  position: relative;
  padding-bottom: 3px;
  margin-left: -7px;
}

.round-switcher.is_rating:before,
.listing__menu__itm.is_rating:before {
  content: "";
  position: absolute;
  right: 12px;
  top: 11px;
  width: 12px;
  height: 8px;
  background-image: url(/media/images/ic_sort.png);
}

.round-switcher.is_rating:hover,
.listing__menu__itm.is_rating:hover {
  border: none;
  color: #fd2d2c;
}

.round-switcher.is_rating:hover:before,
.listing__menu__itm.is_rating:hover:before {
  content: '';
  position: absolute;
  right: 12px;
  top: 11px;
  width: 12px;
  height: 8px;
  background-image: url(/media/images/sort-down.png);
}

.round-switcher:hover,
.listing__menu__itm:hover {
  border: 2px solid #fd2d2c;
  color: #fd2d2c;
}

.round-switcher--active,
.listing__menu__itm--active {
  border: 2px solid #fd2d2c;
  color: #fd2d2c;
  cursor: default;
  position: relative;
}

.round-switcher--active.is_user,
.listing__menu__itm--active.is_user {
  border: none;
  position: relative;
  color: #fd2d2c;
}

.round-switcher--active.is_user:hover:before,
.listing__menu__itm--active.is_user:hover:before {
  content: '';
  position: absolute;
  right: -24px;
  top: 6px;
  width: 12px;
  height: 8px;
  background-image: url("/media/images/sort-up.png");
}

.round-switcher--active.is_user:before,
.listing__menu__itm--active.is_user:before {
  content: "";
  position: absolute;
  right: -24px;
  top: 6px;
  width: 12px;
  height: 8px;
  background-image: url("/media/images/sort-up.png");
}

.round-switcher--active.is_rating,
.listing__menu__itm--active.is_rating {
  border: none;
  position: relative;
  color: #fd2d2c;
}

.round-switcher--active.is_rating:hover:before,
.listing__menu__itm--active.is_rating:hover:before {
  content: "";
  position: absolute;
  right: 12px;
  top: 11px;
  width: 12px;
  height: 8px;
  background-image: url(/media/images/sort-up.png);
}

.round-switcher--active.is_rating:before,
.listing__menu__itm--active.is_rating:before {
  content: '';
  position: absolute;
  right: 12px;
  top: 11px;
  width: 12px;
  height: 8px;
  background-image: url(/media/images/sort-up.png);
}

.listing__menu__itm {
  display: inline-block;
  margin-left: 6px;
  line-height: 18px;
}

.sort_itm.table__cell:hover {
  color: #fd2d2c;
  cursor: pointer;
}

.listing__menu__itm--active {
  cursor: pointer;
  padding-right: 24px;
}

.listing__menu__itm--active:before {
  content: '';
  position: absolute;
  right: 7px;
  top: 11px;
  width: 12px;
  height: 8px;
  background-image: url(/media/images/sort-up.png);
}

.listing__menu__itm--active.table__cell {
  padding-right: 20px;
  color: #fd2d2c;
  border: none;
  border-bottom: 1px solid #dddfe0;
}

.listing__menu__itm--active.table__cell:before {
  top: 26px;
  right: 16px;
}

.listing__menu__itm--active--minus:before {
  background-image: url(/media/images/sort-down.png) !important;
}

.listing__columns li {
  margin-left: 10px;
}

.listing__column__itm {
  zoom: 1;
}

.listing__column__itm:before,
.listing__column__itm:after {
  content: '';
  display: table;
}

.listing__column__itm:after {
  clear: both;
}

.listing__column__ava {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.listing__column__text {
  display: inline-block;
  color: #808080;
  vertical-align: middle;
}

.listing__column__itm__left {
  display: inline-block;
  width: 177px;
  margin-right: 7px;
  vertical-align: middle;
  white-space: nowrap;
}

.listing__column__itm__right {
  display: inline-block;
  vertical-align: middle;
}

.listing__columns--round .listing__column__ava {
  width: 32px;
  height: 32px;
  border-radius: 20px;
  overflow: hidden;
  border-width: 0 !important;
}

.listing__columns--round .listing__column__ava img {
  width: 32px;
  height: 32px;
}

.listing__columns--round .listing__column__text {
  line-height: 32px;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.listing__columns--round .listing__column__value {
  width: 30px;
  text-overflow: ellipsis;
  line-height: 32px;
}

.listing__column__itm + .listing__column__itm {
  margin-top: 8px;
}

.listing__column__list {
  margin-top: 16px;
}

.listing__baner {
  position: relative;
}

.listing__baner__img--wrapper {
  width: 220px;
  height: 165px;
  overflow: hidden;
  position: relative;
}

.listing__baner__img-count {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #fff;
}

.listing__baner__val {
  background-color: #ffda4a;
  position: absolute;
  width: 48px;
  height: 48px;
  top: 0;
  right: 16px;
  text-align: center;
  line-height: 48px;
  font-size: 24px;
  color: #263238;
}

.listing .table__cell--header {
  border-top: 1px solid #dddfe0;
}

.listing__paginator {
  display: block;
  position: relative;
  text-align: center;
}

.listing__paginator__itm {
  display: inline-block;
  border-bottom: 2px solid transparent;
  padding-left: 3px;
  padding-right: 3px;
  transition-property: border, color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

.listing__paginator__itm:hover {
  border-bottom: 2px solid #fd2d2c;
}

.listing__paginator__itm + .listing__paginator__itm {
  margin-left: 3px;
}

.listing__paginator__itm--active {
  color: #adadad;
  border-bottom: 2px solid #fd2d2c;
  cursor: default;
}

.listing__paginator__btn {
  position: absolute;
  color: #adadad;
}

.listing__paginator__btn:before {
  top: 3px;
  content: '';
  position: absolute;
  width: 8px;
  height: 13px;
}

.listing__paginator__btn--active {
  color: #000;
  cursor: pointer;
}

.listing__paginator__btn--active .listing__paginator__b-text {
  transition-property: border, color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  border-bottom: 2px solid transparent;
}

.listing__paginator__btn--active .listing__paginator__b-text:hover {
  border-bottom: 2px solid #fd2d2c;
}

.listing__paginator__itm--etc {
  border: none;
  cursor: default;
}

.listing__paginator__itm--etc:hover {
  border: none;
}

.listing__paginator__btn--prev {
  display: inline-block;
  left: 0;
  padding-left: 23px;
}

.listing__paginator__btn--prev:before {
  left: 0;
  background-image: url(/media/images/rotatorLeft.png);
}

.listing__paginator__btn--prev.listing__paginator__btn--active:before {
  background-image: url(/media/images/rotatorLeftActive.png);
}

.listing__paginator__btn--next {
  display: inline-block;
  right: 0;
  padding-right: 23px;
}

.listing__paginator__btn--next:before {
  right: 0;
  background-image: url(/media/images/rotatorRigth.png);
}

.listing__paginator__btn--next.listing__paginator__btn--active:before {
  background-image: url(/media/images/rotatorRigthActive.png);
}

.listing__paginator__list {
  display: inline-block;
  text-align: center;
}

.listing__filter .input__wrapper {
  cursor: pointer;
  border-radius: 4px;
}

.catalog-rotator {
  white-space: nowrap;
  padding-top: 32px;
  position: relative;
}

.catalog-rotator__itm {
  white-space: normal;
}

.catalog-rotator__wrapper {
  overflow: hidden;
}

.catalog-rotator__product {
  margin-bottom: 16px;
  width: 140px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.catalog-rotator__img {
  max-width: 140px;
  max-height: 120px;
}

.catalog-rotator__text {
  width: 180px;
  height: 74px;
  overflow: hidden;
  color: #808080;
  margin-bottom: 8px;
  text-overflow: ellipsis;
}

.catalog-rotator__price {
  color: #35464e;
  font-weight: 100;
  font-size: 20px;
  line-height: 24px;
}

.catalog-rotator__nav {
  position: absolute;
  right: 30px;
  top: 30px;
}

.catalog-rotator__nav__itm {
  float: left;
  opacity: .16;
  filter: alpha(opacity=16);
  width: 8px;
  height: 13px;
}

.catalog-rotator__nav__itm + .catalog-rotator__nav__itm {
  margin-left: 16px;
}

.catalog-rotator__list {
  transition-property: margin;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
}

.catalog-rotator__left {
  background-image: url(/media/images/rotatorLeftActive.png);
}

.catalog-rotator__right {
  background-image: url(/media/images/rotatorRigthActive.png);
}

.catalog-rotator__nav__itm--active {
  opacity: 1;
  filter: none;
  cursor: pointer;
}

.test_grid {
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  top: 250px;
  z-index: 100000;
}

.test_span {
  height: 100%;
  background-color: rgba(253, 45, 44, 0.2);
}

.accept_request_import-row {
  margin-bottom: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eceff1;
}

html {
  min-height: 100%;
  position: relative;
}

.subscribe__container {
  width: 340px;
}

.subscribe {
  height: 200px;
}

.subscribe__container--active .subscribe__input {
  width: 210px;
}

.subscribe__container--active .subscribe__btn {
  width: 117px;
  visibility: visible;
  height: 100%;
}

.subscribe__btn {
  height: 0;
  width: 0;
  border: none;
  padding: 0;
  color: #fd2d2c;
  background-color: transparent;
  visibility: hidden;
  outline: none;
  overflow: hidden;
  transition-property: width, visibility;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.subscribe__form {
  width: 100%;
  border: 1px solid #a3a6a8;
  height: 42px;
  box-sizing: border-box;
  overflow: hidden;
  transition-property: border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.subscribe__form--done {
  border-color: rgba(255, 255, 255, 0.08);
}

.subscribe__form--done .subscribe__input {
  display: none;
}

.subscribe__form--done .subscribe__btn {
  display: none;
}

.subscribe__form--done .subscribe__form--greetings {
  visibility: visible;
}

.subscribe__form--greetings {
  visibility: hidden;
  color: #fff;
  line-height: 42px;
  margin-left: 16px;
  transition-property: visibility;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.subscribe__input {
  display: inline-block;
  height: 100%;
  border: none;
  width: 100%;
  vertical-align: middle !important;
  box-sizing: border-box;
  background-color: transparent;
  transition-property: width;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  color: #a3a6a8;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
}

.site-map {
  display: inline-block;
}

.site-map__item {
  display: inline-block;
  margin-left: 24px;
}

.header {
  padding-top: 32px;
  line-height: 32px;
  margin-bottom: 32px;
}

.header--devise {
  padding-top: 16px;
  height: 116px;
}

.top_line--right {
  margin-top: 25px;
}

.logo__text--divese {
  display: block;
  padding-right: 0;
  text-align: center;
  margin-top: 16px;
}

.logo__text {
  white-space: nowrap;
  font-size: 11px;
  line-height: 12px;
  text-align: left;
  margin-left: 5px;
  display: inline-block;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  vertical-align: middle;
}

.dropdown {
  position: relative;
  display: block;
  vertical-align: bottom;
}

.dropdown:hover .dropdown__wrapper {
  border-color: #fc9801;
  box-shadow: inset 0 0 0 1px #fc9801;
}

.dropdown--full-width {
  width: 100%;
}

.dropdown--full-width .dropdown__wrapper {
  width: inherit;
}

.dropdown--red {
  width: 276px;
}

.dropdown--red .input__wrapper,
.dropdown--red .dropdown__wrapper {
  border-color: #fd2d2c;
}

.dropdown--red:hover .input__wrapper,
.dropdown--red:hover .dropdown__wrapper {
  border-color: #fc9801;
}

.dropdown__wrapper {
  border: 2px solid #dddfe0;
  height: 42px;
  width: 370px;
  box-sizing: border-box;
  line-height: 42px;
  position: relative;
  background-color: #fff;
  transition-property: border, box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  box-shadow: inset 0 0 0 1px transparent;
}

.dropdown__wrapper:before {
  position: absolute;
  content: '';
  width: 13px;
  height: 8px;
  top: 17px;
  right: 15px;
  background-image: url(/media/images/arrow.png);
}

.dropdown--autocomplete {
  position: relative;
}

.dropdown--autocomplete .input__wrapper {
  padding-right: 30px;
  color: #263238;
}

.dropdown--autocomplete:before {
  z-index: 10;
  position: absolute;
  content: '';
  width: 13px;
  height: 8px;
  top: 17px;
  right: 15px;
  background-image: url(/media/images/arrow.png);
}

.dropdown__value {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.dropdown__value--default {
  color: #808080;
}

.fancybox-inner .dropdown__list {
  max-height: 210px;
  overflow: auto;
}

.dropdown--active .dropdown__wrapper {
  z-index: 11;
}

.dropdown--active .dropdown__list {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
  visibility: visible;
  opacity: 1;
  filter: none;
}

.dropdown__list {
  position: absolute;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  max-height: 300px;
  overflow: auto;
  overflow-x: hidden;
  visibility: hidden;
  top: 100%;
  border-top: none;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 0 0 transparent;
  z-index: 200;
  transition-property: box-shadow, margin, visibility, opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.dropdown__itm,
.dropdown__itm--disabled {
  transition-property: background, box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  margin-top: 0;
  cursor: pointer;
}

.dropdown__itm a {
  padding-top: 12px;
  padding-bottom: 11px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}

.dropdown__itm:hover {
  background-color: #eceff1;
}

.dropdown__itm--hover {
  background-color: #eceff1;
}

.dropdown__itm--hidden {
  display: none !important;
}

.dropdown__itm__cont {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}

.dropdown__itm--active {
  background-color: #eceff1;
  cursor: default;
}

.dropdown__itm--disabled {
  background-color: #ccc;
}

.like-input,
.input {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 100%;
}

.like-input:hover .input__wrapper,
.input:hover .input__wrapper {
  border-color: #fc9801;
}

.input--error .input__wrapper {
  border-color: #fd2d2c;
}

.input--error .input__wrapper:hover {
  border-color: #fd2d2c;
}

.input--error .input__wrapper:focus {
  border-color: #fd2d2c;
  box-shadow: inset 0 0 0 1px #fd2d2c;
}

.input__wrapper {
  border: 1px solid #edeef0;
  height: 44px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
  line-height: 24px;
  position: relative;
  background-color: #fff;
  transition-property: border, box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  z-index: 2;
  box-shadow: inset 0 0 0 1px transparent;
  outline: none;
  color: #808080;
  padding: 9px 10px;
}

.input__wrapper:focus {
  border-color: #fc9801;
  box-shadow: inset 0 0 0 1px #fc9801;
}

.input__wrapper[disabled] {
  box-shadow: none !important;
  border-color: #e4e5e5 !important;
  color: #dadbdb !important;
}

.input--file .input {
  position: relative;
  cursor: pointer;
}

.input__upload {
  opacity: 0;
  filter: alpha(opacity=0);
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.input__btn {
  cursor: pointer;
}

.ui-state-disabled .ui-state-default {
  color: #eceff1 !important;
}

.ui-datepicker {
  width: 216px;
  height: auto;
  z-index: 9990000 !important;
  margin: 5px auto 0;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.ui-datepicker table {
  width: 100%;
}

.ui-datepicker th {
  text-align: center;
}

.ui-datepicker .ui-state-default {
  padding: 3px;
  color: #858c90;
}

.ui-datepicker td {
  text-align: center;
}

.ui-datepicker-header {
  line-height: 30px;
  border-style: solid;
  border-color: #eceff1;
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute !important;
  top: 5px !important;
  width: 12px !important;
  height: 10px !important;
  text-align: center !important;
  cursor: pointer !important;
  background-repeat: no-repeat !important;
  overflow: hidden !important;
}

.ui-widget-header .ui-icon {
  background-image: url(/media/images/ui-icons_444444_256x240.png);
}

.propetrovich_theme .ui-datepicker-prev {
  left: 10px !important;
  background-image: url(/media/images/next_btn.png) !important;
}

.propetrovich_theme .ui-datepicker-prev:hover {
  background-image: url(/media/images/next_btn_hov.png) !important;
}

.propetrovich_theme .ui-datepicker-next {
  right: 10px !important;
  background-image: url(/media/images/prev_btn.png) !important;
}

.propetrovich_theme .ui-datepicker-next:hover {
  background-image: url(/media/images/prev_btn_hov.png) !important;
}

.ui-state-hover {
  border: none !important;
  background: none !important;
}

.textarea {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 100%;
  height: 200px;
}

.textarea .field_with_errors {
  height: 100%;
}

.textarea:hover .textarea__wrapper,
.textarea:hover textarea {
  border-color: #fc9801;
}

.textarea--error .textarea__wrapper,
.textarea--error textarea {
  border-color: #fd2d2c;
}

.textarea--error .textarea__wrapper:hover,
.textarea--error textarea:hover {
  border-color: #fd2d2c;
}

.textarea--error .textarea__wrapper:focus,
.textarea--error textarea:focus {
  border-color: #fd2d2c;
  box-shadow: inset 0 0 0 1px #fd2d2c;
}

.textarea .textarea__wrapper,
.textarea textarea {
  resize: none;
  border: 2px solid #dddfe0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  transition-property: border, box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  z-index: 2;
  box-shadow: inset 0 0 0 1px transparent;
  outline: none;
  color: #808080;
  padding: 10px;
}

.textarea .textarea__wrapper:focus,
.textarea textarea:focus {
  border-color: #fc9801;
  box-shadow: inset 0 0 0 1px #fc9801;
}

.user {
  display: inline-block;
}

.user__ava {
  display: inline-block;
  vertical-align: middle;
}

.user__name {
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
}

.table-listing {
  width: 100%;
  table-layout: fixed;
}

.table-listing .btn {
  height: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.table-listing .table__cell__round-red {
  background-color: inherit;
  color: inherit;
}

.table__cell__round-red {
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  line-height: 35px;
  background-color: #fd2d2c;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0 !important;
}

.table__cell {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddfe0;
  transition-property: opacity, visibility, height, padding, margin;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.table__cell.wb {
  border-bottom-width: 0;
}

.table__comments {
  vertical-align: top;
  padding-top: 8px;
  padding-bottom: 8px;
}

.table__cell--header {
  color: #858c90;
}

.table__cell--user {
  width: 267px;
}

.table__cell--checkbox {
  width: 16px;
}

.table__user__ava--fav {
  width: 14px;
  height: 19px;
  background-repeat: no-repeat;
  background-image: url(/media/images/tableFlagActive.png);
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.table__checkbox {
  width: 14px;
  height: 19px;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(/media/images/tableFlag.png);
  transition-property: background;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
}

.table__checkbox-input {
  display: none;
}

.table__checkbox-input:checked + .table__checkbox {
  background-repeat: no-repeat;
  background-image: url(/media/images/tableFlagActive.png);
}

.table__row {
  background-color: #fff;
}

.table__row.table__row--close.table__row--yellow {
  background-color: #fff;
}

.table__row:nth-child(2n) {
  background-color: #f9fafb;
}

.table__row:nth-child(2n).table__row--close.table__row--yellow {
  background-color: #f9fafb;
}

.table__row:nth-child(2n).table__row--yellow {
  background-color: rgba(255, 218, 74, 0.8);
}

.table__row:nth-child(2n).table__row--yellow:hover {
  background-color: #eceff1;
}

.table__row:hover {
  background-color: #eceff1;
}

.table__row--hide > td {
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
}

.table__row--yellow {
  background-color: rgba(255, 218, 74, 0.4);
}

.table__row--close {
  text-decoration: line-through;
}

.table__row--close > * {
  opacity: .3;
  filter: alpha(opacity=30);
}

.table__row--close > * .help-text {
  opacity: .3;
  filter: alpha(opacity=30);
}

.table__row--close .table__cell--repeat {
  opacity: 1;
  filter: none;
  border-bottom: 1px solid rgba(221, 223, 224, 0.3);
}

.table__row--close .help-text {
  text-decoration: line-through;
}

.table__row--close .table__cell__round-red {
  background-color: inherit;
  color: inherit;
}

.table__row--decl {
  opacity: .3;
  filter: alpha(opacity=30);
}

.table__row--decl:hover {
  opacity: 1;
  filter: none;
}

.table__row--link {
  cursor: pointer;
}

.table__row--thead:hover {
  background-color: inherit;
}

.table__row .btn--transp {
  color: #263238;
}

.is-working .btn {
  background-color: #fc9801 !important;
  cursor: default;
  border-color: #fc9801 !important;
  color: #fff !important;
}

.phone-sms--done {
  background-color: #fc9801;
  color: #fff;
  border: 1px solid #fc9801;
}

.user__ava--small {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

.user__ava--small .user__ava__img {
  width: 24px;
  height: 24px;
}

.user__ava__img {
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.table-listing__row {
  color: #babdbf;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.table-listing__row .btn--transp {
  border-color: #fd2d2c;
  color: #fd2d2c;
}

.table-listing__row .phone-sms,
.table-listing__row .user__ava__img {
  opacity: .032;
  filter: alpha(opacity=3);
}

.table-listing__row .help-text {
  color: #babdbf;
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.table-listing__row.without__hover,
.table-listing__row.is-working,
.table-listing__row:hover {
  color: #263238;
}

.table-listing__row.without__hover .help-text,
.table-listing__row.is-working .help-text,
.table-listing__row:hover .help-text {
  color: #808080;
}

.table-listing__row.without__hover .btn--transp,
.table-listing__row.is-working .btn--transp,
.table-listing__row:hover .btn--transp {
  border-color: #babdbf;
  color: #263238;
}

.table-listing__row.without__hover .btn--transp:hover,
.table-listing__row.is-working .btn--transp:hover,
.table-listing__row:hover .btn--transp:hover {
  border-color: #fc9801;
  color: #fff;
  background-color: #fc9801;
}

.table-listing__row.without__hover .phone-sms,
.table-listing__row.is-working .phone-sms,
.table-listing__row:hover .phone-sms,
.table-listing__row.without__hover .user__ava__img,
.table-listing__row.is-working .user__ava__img,
.table-listing__row:hover .user__ava__img {
  opacity: 1;
  filter: none;
}

.gray-form__item {
  width: 254px;
  display: inline-block;
  margin-right: 8px;
}

.gray-form {
  background-color: #eceff1;
  padding: 16px;
}

.gray-form .btn {
  vertical-align: bottom;
  height: 42px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 2px;
}

.gray-form .btn--middle {
  vertical-align: middle;
}

.gray-form--order-show {
  padding-top: 32px;
  padding-bottom: 32px;
}

.breadcrumbs__item {
  display: inline-block;
}

.breadcrumbs__item + .breadcrumbs__item {
  margin-left: 8px;
}

.breadcrumbs__link {
  color: #858c90;
  border-bottom: 2px solid #858c90;
}

.breadcrumbs__link:hover {
  border-color: #fd2d2c;
  text-decoration: none;
}

.profile__name {
  position: relative;
  padding-right: 33px;
  display: inline-block;
}

.profile__ava {
  width: 128px;
  height: 128px;
  overflow: hidden;
  display: inline-block;
}

.profile__ava__img {
  width: 128px;
  height: 128px;
}

.profile__add-fav {
  position: relative;
  width: 14px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  margin-left: 16px;
  background-image: url(/media/images/tableFlagRed.png);
  top: 0;
  right: 0;
  cursor: pointer;
  transition-property: background-image;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.profile__add-fav--active {
  background-image: url(/media/images/tableFlagRedActive.png);
}

.profile__images__itm {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  margin-top: 8px;
  width: 96px;
  height: 86px;
  overflow: hidden;
  text-align: center;
}

.profile__images__itm img {
  max-height: 96px;
  max-width: 86px;
}

.profile__images__itm:hover .profile__show-more {
  background-color: #000;
}

.profile__show-more {
  background-color: rgba(0, 0, 0, 0.56);
  line-height: 72px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.profile__spec__val {
  display: inline-block;
  width: 30px;
  margin-right: 3px;
}

.profile__spec__line {
  width: 28px;
  height: 4px;
  background-color: #eceff1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
}

.profile__spec__line + .profile__spec__line {
  margin-left: 5px;
}

.profile__spec__line-color {
  background-color: #fc9801;
}

.portfolio__itm {
  width: 219px !important;
  height: 165px;
  line-height: 165px;
  background-color: #eceff1;
  text-align: center;
  position: relative;
  margin-bottom: 24px;
  margin: auto;
}

.portfolio-open--disabled {
  opacity: .3;
  filter: alpha(opacity=30);
}

.portfolio-open--disabled .link {
  cursor: default;
  color: #263238 !important;
}

.portfolio__itm__wrapper {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.portfolio__itm__img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  opacity: .7;
  filter: alpha(opacity=70);
  max-width: 100%;
  min-height: 165px;
}

.portfolio__cont {
  position: relative;
  z-index: 2;
  display: inline-block;
  line-height: normal;
}

.profile__avatar {
  width: 127px;
  height: 127px;
}

.profile__avatar img {
  width: 127px;
  height: 127px;
}

.star {
  width: 12px;
  height: 12px;
  background-image: url(/media/images/star.jpg);
  display: inline-block;
  margin-bottom: -1px;
}

.star + .star {
  margin-left: 8px;
}

.star--active {
  background-image: url(/media/images/star-active.jpg);
}

.star--big {
  width: 26px;
  height: 24px;
  cursor: pointer;
  background-image: url(/media/images/starBig.png);
  transition-property: background;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.star--big.star--hover,
.star--big.star--active {
  background-image: url(/media/images/starBig-active.png);
}

.review__raiting__start {
  display: inline-block;
  margin-right: 8px;
}

.review__user__ava {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}

.review__user__text {
  display: inline-block;
  width: 179px;
  vertical-align: middle;
}

.checkbox {
  display: inline-block;
  cursor: pointer;
  line-height: 20px;
}

.checkbox__input {
  display: none;
}

.checkbox__input:checked + .checkbox__box {
  background-color: #fd2d2c;
  border: 1px solid transparent;
}

.checkbox__input:checked + .checkbox__box:before {
  content: '';
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 5px;
  width: 14px;
  height: 11px;
  background-image: url(/media/images/checkbox.png);
}

.checkbox__box {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;
  border: 1px solid #dddfe0;
  display: inline-block;
  margin-right: 8px;
  background-color: #fff;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.checkbox__text {
  display: inline-block;
  vertical-align: top;
  line-height: 22px;
}

.switcher-hor {
  display: inline-block;
  cursor: pointer;
  line-height: 31px;
}

.switcher-hor__box {
  display: inline-block;
  vertical-align: middle;
  background-color: #dddfe0;
  position: relative;
  border-radius: 16px;
  width: 31px;
  height: 16px;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.switcher-hor__box:before {
  content: '';
  position: absolute;
  left: 3px;
  top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #fff;
  margin-left: 0;
  transition-property: margin;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.switcher-on {
  display: none;
}

.checkbox__input:checked + .switcher-hor__wrapper .switcher-hor__box {
  background-color: #fd2d2c;
}

.checkbox__input:checked + .switcher-hor__wrapper .switcher-hor__box:before {
  margin-left: 13px;
}

.checkbox__input:checked + .switcher-hor__wrapper .switcher-off {
  display: none;
}

.checkbox__input:checked + .switcher-hor__wrapper .switcher-on {
  display: inline-block;
}

.radio input[type='radio'] {
  display: none;
}

.radio input[type='radio']:checked + .radio__box--round {
  border: 2px solid #fd2d2c;
  color: #fd2d2c;
}

.radio input[type='radio']:checked + .radio__box:after {
  border-color: #fd2d2c;
}

.radio input[type='radio']:checked + .radio__box:before {
  opacity: 1;
  filter: none;
  visibility: visible;
}

.radio--checkbox__input {
  display: block;
}

.radio {
  text-align: left;
  display: list-item;
  text-decoration: none;
  list-style: none;
}

.radio:hover .radio__box:after {
  border-color: #fd2d2c;
}

.radio:hover .radio__box--round {
  border: 2px solid #fd2d2c;
  color: #fd2d2c;
}

.radio--round {
  display: inline-block;
}

.radio--round + .radio--round {
  margin-left: 8px;
}

.radio__box {
  line-height: 24px;
  position: relative;
  padding-left: 44px;
  cursor: pointer;
}

.radio__box:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #eceff1;
  left: 0;
  top: 0;
  transition-property: border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.radio__box:before {
  content: '';
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #fd2d2c;
  left: 5px;
  top: 5px;
  transition-property: opacity, visibility;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.radio__box--round {
  width: auto;
  height: auto;
  line-height: 28px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 40px;
  border: 2px solid transparent;
  transition-property: border, color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.radio__box--round:after {
  display: none;
}

.radio__box--round:before {
  display: none;
}

.radio--checkbox__input {
  display: none;
}

.radio--checkbox__input + .radio--checkbox .radio:first-child .radio__box:after {
  border-color: #fd2d2c;
}

.radio--checkbox__input + .radio--checkbox .radio:first-child .radio__box:before {
  opacity: 1;
  filter: none;
  visibility: visible;
}

.radio--checkbox__input + .radio--checkbox .radio:first-child .radio__box--round {
  border: 2px solid #fd2d2c;
  color: #fd2d2c;
}

.radio--checkbox__input:checked + .radio--checkbox .radio:first-child .radio__box:after {
  border-color: #eceff1;
}

.radio--checkbox__input:checked + .radio--checkbox .radio:first-child .radio__box:before {
  background-color: transparent;
}

.radio--checkbox__input:checked + .radio--checkbox .radio:first-child .radio__box--round {
  border: 2px solid transparent;
  color: #263238;
}

.radio--checkbox__input:checked + .radio--checkbox .radio + .radio .radio__box:after {
  border-color: #fd2d2c;
}

.radio--checkbox__input:checked + .radio--checkbox .radio + .radio .radio__box:before {
  opacity: 1;
  filter: none;
  visibility: visible;
}

.radio--checkbox__input:checked + .radio--checkbox .radio .radio__box--round {
  border: 2px solid #fd2d2c;
  color: #fd2d2c;
}

.input--file__photo.ava {
  width: 128px;
}

.for_not_individual {
  display: block;
}

.for_individual {
  display: none;
}

.is_individual .for_not_individual {
  display: none;
}

.is_individual .for_individual {
  display: block;
}

.for_master {
  display: none;
}

.lh42 {
  line-height: 42px;
}

.petrovich__login {
  height: 46px;
  display: inline-block;
  width: 100%;
  background-color: #ffda4a;
  color: #fd2d2c;
  cursor: pointer;
  text-transform: uppercase;
  transition-property: background, color, box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.petrovich__login:hover {
  background-color: #fd2d2c;
  color: #fff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
}

.petrovich__login__text {
  vertical-align: sub;
}

.pro_login {
  overflow: hidden;
  transition-property: opacity, height, visibility;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.pro_login--hide {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 0;
  display: none;
}

.petrovich__form-wrap {
  opacity: 0;
  filter: alpha(opacity=0);
  overflow: hidden;
  visibility: hidden;
  height: 0;
  transition-property: opacity, height, visibility;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.petrovich__form-wrap--active {
  opacity: 1;
  filter: none;
  visibility: visible;
  height: auto;
}

.login__tab {
  display: inline-block;
  border: 1px solid #eceff1;
  border-bottom-width: 0;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  transition-property: background, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  margin-right: 0 !important;
}

.login__tab + .login__tab.login__tab--active,
.login__tab + .login__tab:hover {
  background-color: #ffda4a;
  border-color: #ffda4a;
  color: #263238;
}

.login__tab + .login__tab.login__tab--active:before,
.login__tab + .login__tab:hover:before {
  background-color: #ffda4a;
}

.login__tab:hover {
  background-color: #fd2d2c;
  color: #fff;
  border-color: #fd2d2c;
}

.login__tab__wrapper {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
  box-sizing: border-box;
  border-bottom: 1px solid #eceff1;
}

.login__tab--active {
  background-color: #fd2d2c;
  color: #fff;
  border-color: #fd2d2c;
  position: relative;
}

.login__tab--active:before {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  padding-left: 2px;
  width: 100%;
  bottom: -1px;
  left: -1px;
  background-color: #fd2d2c;
  right: 0;
}

.login__tab--active .link--with-border {
  border-bottom-width: 0;
  cursor: default;
}

.login__tab--active .link:hover {
  color: inherit;
}

.login_form {
  margin-left: auto;
  margin-right: auto;
}

@keyframes showAgain {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    right: 0;
  }
  70% {
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    right: 0;
  }
}

.popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.popup__inner {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.popup--active {
  opacity: 1;
  filter: none;
  visibility: visible;
}

.popup__wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
}

.popup__body {
  padding: 35px 12px;
  background-color: #fff;
  display: inline-block;
}

.popup__close {
  border-bottom: 2px solid #eceff1;
  transition-property: border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.popup__close:hover {
  border-color: #fd2d2c;
}

.news__text {
  max-height: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article > * {
  margin-bottom: 24px;
}

.article img {
  max-width: 100%;
  margin-bottom: 7px;
  margin-top: -11px;
}

.article h1,
.article h2,
.article h3,
.article h4,
.article h5 {
  font-weight: bold;
}

.article h2 {
  font-size: 1.8rem;
}

.article h3 {
  font-size: 1.6rem;
}

.article h4 {
  font-size: 1.4rem;
}

.article h5 {
  font-size: 1.2rem;
}

.article table {
  width: 100%;
}

.article table td + td {
  border-left: 1px solid #dddfe0;
}

.article table tr {
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.article table tr:hover {
  background-color: #eceff1;
}

.article table tr + tr td,
.article table tr + tr th {
  border-top: 1px solid #dddfe0;
}

.article table th + td {
  border-left: 1px solid #dddfe0;
}

.article table td {
  color: #808080;
}

.article table th,
.article table td {
  font-weight: 100;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.article ol li {
  list-style: decimal inside;
  margin-bottom: 12px;
}

.article a {
  text-decoration: underline;
}

.services__item {
  background-color: #f9fafb;
  padding: 32px 24px;
  position: relative;
}

.services__item--disabled {
  opacity: .35;
  filter: alpha(opacity=35);
}

.services__item--disabled:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.services__item__price-text {
  width: 130px;
}

.service__price__text {
  width: 130px;
}

.service__date .round-switcher {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 3px;
}

.service__date__text {
  line-height: 32px;
}

.best-m-cont {
  margin-top: 30px;
}

.best-m--itm {
  display: inline-block;
  vertical-align: top;
  max-height: 150px;
  overflow: hidden;
}

.best-m--itm + .best-m--itm {
  margin-left: 15px;
}

.best-m--ava {
  width: 96px;
  height: 96px;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  display: inline-block;
  transition-property: border, background;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  position: relative;
}

.best-m--ava .best-m--img {
  opacity: 1;
  filter: none;
  transition-property: opacity;
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.best-m--ava .best-m--img--hover {
  opacity: 0;
  filter: alpha(opacity=0);
  transition-property: opacity;
}

.best-m--img {
  width: 96px;
  height: 96px;
}

.top-type {
  display: none;
}

.top-type--active {
  display: block;
}

.sms__wrapper {
  text-align: left;
  display: none;
  position: relative;
}

.sms__wrapper--active {
  display: block;
}

.sms__text {
  float: left;
  width: 130px;
}

.sms__link {
  float: right;
}

.sms__profile .sms__input {
  float: none;
}

.sms__profile .sms__link {
  float: none;
}

.sms__profile .sms__timer {
  float: none;
}

.sms__profile .sms__rep {
  float: none;
}

.sms__input {
  float: left;
  width: 106px;
}

.sms__timer {
  float: right;
  height: 42px;
  line-height: 42px;
}

.sms__rep__text {
  transition-property: color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.sms__rep {
  display: none;
  float: right;
}

.sms__rep:hover .reg__again__text {
  color: #fd2d2c;
}

.reg__sms__step {
  display: none;
}

.reg__sms--step-1 .reg__sms--send {
  display: block;
}

.reg__sms--step-2 .reg__sms--conf {
  display: block;
}

.reg--step-1 .reg__step-1 {
  display: block;
}

.reg--step-2 .reg__step-2 {
  display: block;
}

.reg__spec__row .btn--remove {
  display: none;
}

.reg__spec__row + .reg__spec__row {
  margin-top: 16px;
}

.reg__spec__row + .reg__spec__row .btn--remove {
  display: block;
}

.reg__spec__list_itm {
  position: relative;
  margin-bottom: 16px;
}

.fav__pop-up {
  width: 521px;
  position: relative;
  display: none;
  table-layout: fixed;
}

.pop-up__close {
  font-size: 16px;
  position: absolute;
  right: 27px;
  top: 25px;
  cursor: pointer;
  transition-property: color, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.pop-up__close:hover {
  color: #fd2d2c;
}

.fav-list {
  margin-left: auto;
  margin-right: auto;
}

.fav-list__itm {
  padding-top: 16px;
  padding-bottom: 16px;
}

.fav-list__itm + .fav-list__itm {
  border-top: 1px solid #eceff1;
}

.fav__pop-up--boby {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.fav__pop-up--bobyinner {
  height: 100%;
  overflow: auto;
  width: 100%;
}

.remove-fav {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  float: right;
  background-color: #fd2d2c;
  color: #fff;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.fav__pop-up--footer {
  background-color: #eceff1;
  height: 78px;
  width: 100%;
}

.fav .fancybox-skin {
  padding: 0 !important;
}

.fav .fancybox-inner {
  overflow: hidden !important;
}

.fav__pop-up--header {
  padding-left: 27px;
  padding-right: 27px;
  position: relative;
  border-bottom: 1px solid #eceff1;
}

.q-tip {
  position: relative;
  display: inline-block;
}

.q-tip:hover .q-tip--buble {
  margin-top: 0;
  opacity: 1;
  filter: none;
  visibility: visible;
}

.q-tip td {
  padding-top: 8px;
  vertical-align: top;
}

.q-tip--buble {
  visibility: hidden;
  position: absolute;
  font-weight: normal;
  width: 276px;
  bottom: 10px;
  margin-bottom: 100%;
  background-color: #263238;
  color: #e4e5e6;
  padding: 24px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  opacity: 0;
  filter: alpha(opacity=0);
  transition-property: visibility, opacity, margin;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  left: 50%;
  right: 0;
  margin-left: -138px;
  text-transform: none;
  z-index: 110;
}

.q-tip--buble:hover {
  margin-top: 0;
  opacity: 1;
  filter: none;
  visibility: visible;
}

.q-tip--buble .link__text--yellow {
  color: #e4e5e6;
}

.q-tip--buble:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #263238 transparent transparent;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  bottom: -5px;
}

.q-tip--buble--rigth {
  left: 0;
  margin-left: 0;
  padding: 14px;
  text-align: center;
  border-radius: 2px 2px 2px 0;
}

.q-tip--buble--rigth:after {
  margin-left: 0;
  border-width: 5px 5px 0 0;
  border-color: #263238 transparent transparent;
}

.q-tip--buble--inline {
  margin-bottom: 0;
  bottom: 100%;
}

.listing__column__value .q-tip--buble--inline {
  width: 140px;
  margin-left: -76px;
  padding: 10px;
}

.q-tip--buble--master {
  margin-bottom: 20px;
  bottom: 100%;
}

.q-tip--buble--rating {
  margin-bottom: 0;
  margin-left: 0;
  bottom: 100%;
  left: 80px;
  padding: 15px;
}

.q-tip--buble--rating:after {
  margin-left: 30px;
}

.q-tip--buble--small {
  width: 200px;
  top: auto;
  margin-bottom: -85px;
  margin-left: -100px;
  z-index: 100;
}

.q-tip--buble--small:after {
  margin-bottom: -85px;
  top: -55px;
  bottom: auto;
  border-width: 0 5px 5px;
  border-color: transparent transparent #263238;
}

.yellow-text {
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #ffda4a;
}

.service_promo .q-tip--buble--rating {
  margin-bottom: 6px;
  left: 70px;
}

.service_promo .q-tip--buble--rating:after {
  margin-left: 10px;
}

.feedback__pop-up,
.message__pop-up {
  width: 521px;
  position: relative;
  table-layout: fixed;
}

.feedback__pop-up .textarea,
.message__pop-up .textarea {
  height: 110px;
}

.feedback__body,
.feedback--header {
  padding-left: 27px;
  padding-right: 27px;
}

.feedback__body {
  margin-bottom: 32px;
}

.feedback__body .error {
  margin: 8px 0 0;
  display: block;
}

.feedback__body textarea {
  resize: none;
}

.feedback__body .url_field {
  position: absolute;
  left: -999999px;
}

.search__btn {
  border: none;
  background-image: url(/media/images/search.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #dddfe0;
  width: 42px;
  height: 42px;
  border: 2px solid #dddfe0;
  display: inline-block;
  transition-property: background, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  outline: none;
}

.search__btn--active {
  border-color: #fc9801;
  background-color: #fc9801;
}

.search__container:hover .search__btn {
  border-color: #fc9801;
  background-color: #fc9801;
}

.search__input {
  width: 295px;
}

.search__input.is_user_master {
  width: 665px;
  padding-left: 14px;
}

@media only screen and (max-width: 979px) {
  .search__input.is_user_master {
    width: calc(100% - 70px);
    padding-left: 14px;
  }
}

@media only screen and (max-width: 979px) {
  .span1 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 13px;
    padding-right: 14px;
    box-sizing: border-box;
    width: 12.5%;
  }
}

@media only screen and (max-width: 979px) {
  .span2 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 14px;
    box-sizing: border-box;
    width: 100%;
  }
}

@media only screen and (max-width: 979px) {
  .span3 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 13px;
    padding-right: 14px;
    box-sizing: border-box;
    width: 37.5%;
  }
}

@media only screen and (max-width: 979px) {
  .span4 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 13px;
    padding-right: 14px;
    box-sizing: border-box;
    width: 50%;
  }
}

@media only screen and (max-width: 979px) {
  .span5 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 13px;
    padding-right: 14px;
    box-sizing: border-box;
    width: 62.5%;
  }
}

@media only screen and (max-width: 979px) {
  .span6 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 13px;
    padding-right: 14px;
    box-sizing: border-box;
    width: 75%;
  }
}

@media only screen and (max-width: 979px) {
  .span7 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 13px;
    padding-right: 14px;
    box-sizing: border-box;
    width: 87.5%;
  }
}

@media only screen and (max-width: 979px) {
  .span8 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 13px;
    padding-right: 14px;
    box-sizing: border-box;
    width: 100%;
  }
}

.span {
  margin-left: 0;
  margin-right: 0;
  padding-left: 13px;
  padding-right: 14px;
  box-sizing: border-box;
}

@media only screen and (max-width: 979px) {
  .m-pad16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .span-reg__options--photo {
    width: auto;
  }
  .review__user__text {
    width: 100%;
  }
  .m-q-tip-ur {
    bottom: -400px;
    height: 300px;
    width: 240px !important;
    min-width: 240px;
    z-index: 4000;
    top: 0;
    left: -110px;
  }
  .m-q-tip-ur:after {
    bottom: auto;
    top: 25px;
    right: -5px;
    margin-right: 0;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #263238;
    margin-top: auto;
  }
  .row {
    padding-left: 0;
    padding-right: 0;
  }
  .row .row {
    margin-left: 0;
    margin-right: 0;
  }
  .m-mt8 {
    margin-top: 8px;
  }
  .m-mt16 {
    margin-top: 16px;
  }
  .m-mt32 {
    margin-top: 32px;
  }
  .m-dn {
    display: none;
  }
  .m-span8 {
    width: 100%;
    display: inline-block;
  }
  .m-pad0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .m-span4 {
    box-sizing: border-box;
    width: 50%;
    display: inline-block;
  }
  .m-span2 {
    box-sizing: border-box;
    width: 25%;
    display: inline-block;
  }
  .m-span6 {
    box-sizing: border-box;
    width: 75%;
    display: inline-block;
  }
  .m-span33 {
    box-sizing: border-box;
    width: 33.33%;
    display: inline-block;
  }
  .m-ml0 {
    margin-left: 0;
  }
  .m-mt24 {
    margin-top: 24px;
  }
  .m-mh0 {
    margin-left: 0;
    margin-right: 0;
  }
  .m-span-pad {
    box-sizing: border-box;
    padding-left: 13px;
    padding-right: 13.5px;
  }
  .m-row {
    zoom: 1;
    padding-left: 14px;
    padding-right: 13px;
  }
  .m-row:before,
  .m-row:after {
    content: '';
    display: table;
  }
  .m-row:after {
    clear: both;
  }
  .m-row .m-row {
    margin-left: -27px;
    margin-right: -27px;
  }
  .m-span4-wm {
    box-sizing: border-box;
    width: calc(50%);
    display: inline-block;
  }
  .m-span4-wm + .m-span4-wm {
    margin-left: 13px;
    width: calc(50% - 13px);
  }
  .m-span4-wm + .m-span4-wm:nth-child(2n+1) {
    width: calc(50%);
    margin-left: 0;
  }
}

@media only screen and (max-width: 400px) {
  .q-tip--buble {
    max-width: 100%;
  }
  .mm-ml0 {
    margin-left: 0;
  }
  .m-span4-wm + .m-span4-wm {
    margin-left: 0 !important;
  }
  .m-q-tip140 {
    width: 140px !important;
    min-width: 140px;
  }
  .mm-pad0 {
    padding-left: 0;
    padding-right: 0;
  }
  .mm-span8 {
    width: 100% !important;
    display: inline-block;
  }
  .mm-span4 {
    box-sizing: border-box;
    width: 50% !important;
    display: inline-block;
  }
  .mm-span2 {
    box-sizing: border-box;
    width: 25% !important;
    display: inline-block;
  }
  .mm-span6 {
    box-sizing: border-box;
    width: 75% !important;
    display: inline-block;
  }
  .mm-span33 {
    box-sizing: border-box;
    width: 33.33% !important;
    display: inline-block;
  }
  .m-f-vtop {
    vertical-align: top;
  }
  .mm-mt8 {
    margin-top: 8px;
  }
  .mm-mt16 {
    margin-top: 16px;
  }
  .mm-mt24 {
    margin-top: 24px;
  }
  .top_banner__container .order_letter__btn,
  .top_banner__container .order_phone__btn {
    float: none;
    width: 213px;
    margin: 10px auto 30px;
    padding: 12px 0;
  }
  .top_banner__container .top_banner_message,
  .top_banner__container .top_banner_phone {
    width: 235px;
    margin: 0 auto;
    float: none;
    padding: 0;
    padding-top: 40px;
  }
  .top_banner__container .top_banner_message .top_banner_message__title,
  .top_banner__container .top_banner_message .top_banner__message__title,
  .top_banner__container .top_banner_message .top_banner_phone__title,
  .top_banner__container .top_banner_phone .top_banner_message__title,
  .top_banner__container .top_banner_phone .top_banner__message__title,
  .top_banner__container .top_banner_phone .top_banner_phone__title {
    text-align: center;
  }
  .top_banner__container .top_banner_message .top_banner_message__text,
  .top_banner__container .top_banner_message .top_banner_phone__text,
  .top_banner__container .top_banner_phone .top_banner_message__text,
  .top_banner__container .top_banner_phone .top_banner_phone__text {
    text-align: center;
  }
  .login__tab__wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }
  .login__tab__wrapper .row {
    white-space: nowrap;
  }
  .login__tab__wrapper .login__tab {
    padding-left: 0;
    padding-right: 0;
    white-space: normal;
  }
}

.fancybox-overlay {
  z-index: 10000;
}

.fancybox-opened {
  z-index: 10001;
}

@media only screen and (max-width: 979px) {
  .top_banner__container .top_banner_message {
    width: 50%;
  }
  .service_promo .f-center {
    text-align: left;
  }
  body {
    width: 100%;
  }
  .scroll_stop {
    overflow: hidden;
    max-height: 100%;
    position: relative;
  }
  .wrapper {
    width: 100%;
  }
  .left-cont {
    float: none;
  }
  .logo__text {
    white-space: nowrap;
    font-size: 11px;
    line-height: 12px;
    text-align: left;
    margin-left: 5px;
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
  }
  .header {
    padding-top: 0;
    height: 75px;
  }
  .header:before {
    content: '';
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
  }
  .header--devise .logo {
    right: 0 !important;
    left: 0 !important;
  }
  .logo {
    position: absolute;
    width: auto;
    left: 25px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    top: 55px;
  }
  .logo__img {
    height: 42px;
    width: auto;
  }
  .right-cont {
    float: none;
  }
  .right-cont .header__menu {
    margin-left: 0;
  }
}

@media only screen and (max-width: 400px) {
  .dropdown--red {
    width: 100% !important;
  }
  .form-span {
    padding-left: 16px;
    padding-right: 16px;
  }
  .login__tab {
    vertical-align: bottom;
  }
  .best-m--itm {
    width: 50%;
  }
  .radio__box--round {
    padding-left: 9px;
    padding-right: 9px;
  }
  .radio--round + .radio--round {
    margin-left: 4px;
  }
  .header__menu {
    text-align: left !important;
  }
  .mm-mt8 {
    margin-top: 8px;
  }
  .mm-mt16 {
    margin-top: 16px;
  }
  .mm-dn {
    display: none !important;
  }
}

.m-main-menu__item {
  display: none;
}

@media only screen and (max-width: 520px) {
  .top_banner__container .order_letter__btn,
  .top_banner__container .order_phone__btn {
    float: none;
    width: 213px;
    margin: 10px auto 30px;
    padding: 12px 0;
  }
  .top_banner__container .top_banner_message,
  .top_banner__container .top_banner_phone {
    width: 235px;
    margin: 0 auto;
    float: none;
    padding: 0;
    padding-top: 40px;
  }
  .top_banner__container .top_banner_message .top_banner_message__title,
  .top_banner__container .top_banner_message .top_banner__message__title,
  .top_banner__container .top_banner_message .top_banner_phone__title,
  .top_banner__container .top_banner_phone .top_banner_message__title,
  .top_banner__container .top_banner_phone .top_banner__message__title,
  .top_banner__container .top_banner_phone .top_banner_phone__title {
    text-align: center;
  }
  .top_banner__container .top_banner_message .top_banner_message__text,
  .top_banner__container .top_banner_message .top_banner_phone__text,
  .top_banner__container .top_banner_phone .top_banner_message__text,
  .top_banner__container .top_banner_phone .top_banner_phone__text {
    text-align: center;
  }
  .top_line--left {
    display: none;
  }
  .notice__wrapper {
    right: 0;
    left: 0;
    width: 100%;
    top: auto;
    bottom: 0;
    box-sizing: border-box;
  }
  .notice {
    width: 100%;
  }
  .notice + .notice {
    margin-top: 0;
  }
  .notice__close {
    top: 0;
    right: 0;
    display: inline-block;
    bottom: 0;
    border-radius: 0;
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 20px;
  }
}

.navbar-toggle {
  display: none;
}

@media only screen and (max-width: 979px) {
  .m-main-menu__item {
    display: block;
  }
  .proposal_count {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 0;
    line-height: 60px;
    font-size: 20px;
    top: 0;
    right: 60px;
  }
  .top_line {
    position: absolute;
    top: 8px;
    margin-top: 0;
    z-index: 20001;
  }
  .top_line--left {
    left: 25px;
  }
  .top_line--right {
    right: 25px;
  }
  .b_user .petr_link {
    display: none;
  }
  .b_user .top_line__wrapper {
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .b_user .body--main {
    border-top-width: 0;
  }
  .b_user .top_line--right {
    top: 0;
    right: 0;
  }
  .b_user .navbar-toggle {
    top: 0;
  }
  .b_user .logo {
    top: 8px;
  }
  .b_user .header {
    height: 60px;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .top_line__wrapper {
    height: 48px;
    z-index: 20000;
    border-bottom: 1px solid #d3d3d3;
    background-color: #fff;
    position: relative;
  }
  .main-menu {
    border-width: 0;
  }
  .main-menu__buble {
    margin-right: -250px !important;
    clear: both;
    padding-left: 0;
    padding-right: 0;
    top: 0;
    right: 0;
    width: 250px;
    box-sizing: border-box;
    padding-top: 48px;
    transition-property: margin;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    position: fixed;
    height: 100%;
    display: block;
    background-color: #2d343a;
    z-index: 10000;
  }
  .main-menu__buble--active {
    margin-right: 0 !important;
  }
  .main-menu__buble--active .main-menu__buble__close {
    display: block;
  }
  .main-menu__list {
    width: 100%;
    overflow: auto;
  }
  .main-menu__item {
    display: block;
    margin-left: 0 !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-menu__item .main-menu__link {
    display: table-cell;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: middle;
    border-bottom-width: 0;
    color: #d3d3d3;
  }
  .main-menu__item .main-menu__link:hover {
    color: #fff;
  }
  .main-menu__item--mobile {
    padding-top: 8px;
    margin-top: 8px;
    border-top: 1px solid #808080;
  }
  .main-menu__item--active .main-menu__link {
    color: #fff;
  }
  .main-menu__buble__close {
    position: fixed;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .main-menu__buble__close:hover {
    color: #fd2d2c;
  }
  body {
    transition-property: padding;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
  }
  .petr_link {
    text-align: right;
  }
  .header__menu {
    margin-left: 0;
  }
}

.m-d {
  display: none;
}

.mm-b {
  display: none;
}

.mm-diln {
  display: none;
}

@media only screen and (max-width: 400px) {
  .f-med {
    font-size: 16px;
    line-height: 20px;
  }
  .f-medb {
    font-size: 18px;
    line-height: 22px;
  }
  .f-big {
    font-size: 30px;
    line-height: 34px;
  }
  .f-giga {
    font-size: 45px;
    line-height: 49px;
  }
  .span.span-reg__dropdown {
    margin-left: 0;
    width: 100% !important;
    padding-left: 0 !important;
  }
  .span.span-reg__dropdown + .span.span-reg__dropdown {
    margin-top: 8px;
  }
  .span.span-reg__dropdown + .span.span-reg__dropdown .dropdown--autocomplete {
    margin-left: 0 !important;
  }
  .mm-b {
    display: block;
  }
  .mm-diln {
    display: inline-block;
  }
  .find-m .dropdown--red .dropdown__list {
    width: 100% !important;
  }
  .article .rating_table {
    table-layout: fixed;
  }
  .article table td,
  .article table th {
    padding-left: 5px;
    padding-right: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media only screen and (max-width: 999px) {
  .profile__spec__val {
    width: 100%;
  }
  .profile__spec__line {
    width: 17%;
  }
  .profile__spec__line + .profile__spec__line {
    margin-left: 3%;
  }
  .profile__add-fav.user_page {
    position: absolute;
    right: 0;
    top: 0;
  }
  .m-f-right {
    text-align: right;
  }
  .m-f-center {
    text-align: center;
  }
  .m-btn {
    color: #fff;
    outline: none;
    background-color: #fd2d2c;
    display: inline-block;
    box-sizing: border-box;
    text-transform: uppercase;
    border: none;
    appearance: none !important;
    cursor: pointer;
    transition-property: background, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    width: 100%;
    border-radius: 2px;
    height: 36px;
    padding: 10px 25px;
  }
  .m-btn:hover {
    background-color: #fc9801;
  }
  .m-btn:active {
    background-color: #fd2d2c;
  }
  .listing__columns--round .q-tip--buble {
    max-width: 140px;
  }
  .m-q-tip--left .q-tip--buble {
    margin-left: -100%;
  }
  .m-q-tip--left .q-tip--buble:after {
    margin-left: auto !important;
    right: 20px;
  }
  .feedback__pop-up,
  .message__pop-up {
    width: 100%;
  }
  .m-wsp {
    white-space: normal;
  }
  .m-d {
    display: block;
  }
  .btn--round {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
  input {
    appearance: none;
    border-radius: 0;
  }
  .find-m .dropdown--red .dropdown__list {
    width: 200%;
  }
  .best-m--itm {
    margin-top: 16px;
  }
  .best-m--itm + .best-m--itm {
    margin-left: 0;
  }
  .today {
    padding-left: 0;
    padding-right: 0;
    margin-top: 32px;
  }
  .today__itm__text {
    width: calc(100% - 50px);
  }
  .service_promo {
    overflow: hidden;
  }
  .service_promo p {
    line-height: 24px;
  }
  .service_promo--red:before {
    display: none;
  }
  .listing__column__itm__left {
    margin: 0;
  }
  .today__list {
    height: auto;
  }
  .search__input {
    width: calc(100% - 43px);
  }
  .table__cell {
    display: block;
    box-sizing: border-box;
    text-overflow: ellipsis;
    word-break: break-all;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .table__cell.m-dn {
    display: none;
  }
  .table__cell .m-pad16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .table__cell .m-dn {
    display: none;
  }
  .table__cell .dn {
    display: none;
  }
  .table__cell > ul {
    display: inline;
  }
  .table__cell:before {
    content: attr(data-title) ":";
    width: 30%;
    font-weight: bold;
    margin-bottom: 8px;
    padding-right: 8px;
  }
  .table__cell--checkbox:before {
    display: none !important;
  }
  .table__row .table__cell {
    border-bottom-width: 0;
  }
  .table__row .table__cell:last-child {
    border-bottom-width: 1px;
  }
  .span.span-reg__dropdown {
    margin-left: 0;
    width: 50%;
  }
  .span.span-reg__dropdown + .span.span-reg__dropdown {
    width: calc(50% - 13px);
    padding-left: 5px;
  }
  .span.span-reg__dropdown + .span.span-reg__dropdown .dropdown--autocomplete {
    margin-left: 2px;
  }
  .m-fl {
    float: left;
  }
  .m-fr {
    float: right;
  }
  .m-db {
    display: block;
  }
  .table__cell--header {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .header__link .hidden-mobile {
    display: none !important;
  }
}

.article table td,
.article table th {
  margin: 4px;
  padding: 4px;
  font-size: 15px;
  font-weight: normal;
}

.logout__link {
  background: none;
  border: none;
}

.main-menu form {
  line-height: normal;
}

.profile .checkbox {
  margin: 0;
}

.profile .checkbox label {
  padding-left: 0;
}

.align-top {
  vertical-align: top;
}

.profile .change-input.link,
.profile .sms__send.link {
  text-decoration: none;
}

.profile.is_master .for_master {
  display: block;
}

.profile .speciality_dropdown {
  width: 500px;
}

.add-skill {
  text-transform: none;
}

.personal_data {
  text-align: center;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 11px;
  display: block;
  color: #737373;
  line-height: 1.455;
}

.personal_data a {
  text-decoration: underline;
}

.personal_data--is-signup {
  max-width: 334px;
  margin: 20px auto 0;
}

.personal_data--is-signup a {
  display: inline;
}

.personal_data--is-index {
  max-width: 579px;
  margin: 20px auto 0;
}

.personal_data--is-mailing-footer {
  max-width: 100%;
  text-align: left;
}

.personal_data--is-mailing-footer a {
  color: #999a9a;
}

.personal_data--is-mailing-footer a:hover {
  background-color: transparent;
  color: rgba(253, 45, 44, 0.61);
  border-color: rgba(253, 45, 44, 0.61);
}

.personal_data--is-write-pop-up {
  padding: 20px 41px 25px;
}

.personal_data--is-order {
  text-align: left;
  padding: 20px 0;
  max-width: 574px;
  margin-left: 14px;
}

.write_us__pop-up button {
  margin-top: 20px;
}

.enter {
  text-align: center;
  max-width: 334px;
  margin: 0 auto;
  display: block;
}

.top_banner {
  background: #ffd24d;
}

.menu_open .top_banner {
  display: none;
}

.top_banner__container:after {
  content: '';
  display: table;
  clear: both;
}

.top_banner_message,
.top_banner_phone {
  float: left;
  padding: 40px 0;
  width: 60%;
}

.top_banner_message__title,
.top_banner__message__title,
.top_banner_phone__title {
  font-size: 18px;
  color: #232323;
  line-height: 1.222;
  text-align: left;
  margin-bottom: 26px;
  font-family: "Open-Sans", "Arial", sans-serif;
}

.top_banner_message__text,
.top_banner_phone__text {
  font-size: 12px;
  color: #232323;
  line-height: 1.714;
  text-align: left;
  font-family: "Open-Sans", "Arial", sans-serif;
}

.order_letter__btn,
.order_phone__btn {
  display: block;
  text-transform: uppercase;
  border: 2px solid #1b1a22;
  padding: 12px 28px 12px 29px;
  border-radius: 100px/90px;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  color: #1b1a22;
  margin-top: 86px;
  float: right;
}

.order_phone__btn {
  margin-top: 60px;
}

.more_orders {
  text-align: center;
}

.sub_title {
  font-size: 21px;
  color: #263238;
  line-height: 3.81;
  text-align: left;
}

@media only screen and (max-width: 415px) {
  .offer_order_table__wrap {
    max-height: calc(85vh - 100px);
    overflow-y: auto;
  }
}

.offer_order_table .table__cell--header {
  border-top: 1px solid #eceff1;
}

.user_block_offer_order {
  margin-top: 37px;
  padding-bottom: 20px;
}

.user_block_offer_order .user__ava__img {
  position: relative;
  top: 3px;
}

.order_msgs {
  font-size: 14px;
  color: #263238;
  line-height: 1.714;
  text-align: left;
  margin-bottom: 8px;
  padding: 0 20px;
}

.msgs_link {
  border-bottom: 2px solid #dddfe0;
  cursor: pointer;
}

.order_msg_block {
  padding: 15px 62px 15px 22px;
  background: #eaeff2;
  position: relative;
}

.order_msg_block:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #eaeff2 transparent transparent;
  left: -10px;
  top: 0;
}

.order_msg_block__text {
  font-size: 14px;
  color: #263238;
  line-height: 1.714;
  text-align: left;
}

@media only screen and (max-width: 979px) {
  .offer_button button {
    height: 42px !important;
    padding-right: 26px;
    margin-left: -3px;
    margin-bottom: 10px;
  }
  .offer_button:before {
    content: '';
    width: 0;
    margin: 0;
    padding: 0;
  }
  .offer_order_table {
    margin-top: 32px;
  }
  .order_msg_block {
    padding: 15px 16px 15px 22px;
  }
}

.catalog-rotator__itm.span2 {
  margin-left: 14px;
}

.order_notice {
  height: 110px;
}

.order_notice__text {
  font-size: 36px;
  color: #fd2d2c;
  line-height: .5;
  text-align: left;
}

.order_notice__prolong {
  padding-right: 60px;
}

.order_notice__prolong p {
  color: #61c027;
  font-size: 14px;
  line-height: 1.286;
}

.form-error {
  font-family: "Open-Sans", "Arial", sans-serif;
  color: #fd2d2c;
  font-size: 11px;
}

.form-error.help-text {
  line-height: 10px;
}

.required_asterisk {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #fd2d2c;
}

.required-alert {
  font-size: 10px;
  color: #fd2d2c;
}

.help-text__is_required {
  font-family: "Arial", sans-serif;
  font-size: 12px;
  padding: 36px 0 0;
}

.help-text__is_required.is_popup {
  font-size: 11px;
  font-family: "Open-Sans", "Arial", sans-serif;
  padding: 0;
  margin-bottom: 20px;
}

.help-text__is_required.is_popup .required_asterisk {
  color: #fd2d2c;
  font-size: 16px;
  position: relative;
  top: 5px;
}

.help-text__is_required span {
  color: #808080;
}

.help-text__is_required .required_asterisk {
  color: #fd2d2c;
  font-size: 16px;
}

.required-alert {
  font-size: 10px;
  color: #fd2d2c;
}

.switch_type {
  font-size: 0;
  height: 33px;
  line-height: 33px;
  box-sizing: border-box;
}

.switch_type__label {
  font-size: 12px;
  text-align: center;
  display: inline-block;
  padding: 0 8px;
  cursor: pointer;
  border-left: 1px solid #dddfe0;
  border-top: 1px solid #dddfe0;
  border-bottom: 1px solid #dddfe0;
  box-sizing: inherit;
}

.switch_type__label:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.switch_type__label:last-child {
  border-right: 1px solid #dddfe0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

@media only screen and (max-width: 979px) {
  .switch_type__label {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 400px) {
  .switch_type__label {
    width: calc(100% / 3);
  }
}

.switch_type__radio_input:checked + label {
  background: #fd2d2c;
  color: #fff;
}

.switch_type__radio_input:checked + label:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.switch_type__radio_input:checked + label:last-child {
  border-right: 1px solid #dddfe0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.switch_type__radio_input:not(checked) {
  position: absolute;
  opacity: 0;
}

@media screen and (max-width: 400px) {
  .order_finsh {
    padding-left: 30px;
    width: 100%;
  }
}

.chose_order__title {
  text-align: left;
  margin-top: 40px;
  position: relative;
  top: 2px;
}

.line_bottom {
  width: 685px;
  border-bottom: 1px solid #eceff1;
}

@media only screen and (max-width: 979px) {
  .chose_order__title {
    text-align: left;
  }
}

@media screen and (max-width: 400px) {
  .chose_order__title {
    text-align: left;
  }
}

.descr_item {
  padding-right: 16px;
}

@media only screen and (max-width: 979px) {
  .m-span1 {
    box-sizing: border-box;
    width: 10%;
    display: inline-block;
  }
}

@media screen and (max-width: 400px) {
  .m-span1 {
    box-sizing: border-box;
    width: 20%;
    display: inline-block;
  }
}

.popup_city_alert {
  padding-top: 20px;
}

.popup_city_alert + .m-span-pad {
  padding-bottom: 40px;
}

/** autocomplete */
div.autocomplette {
  margin-bottom: 30px;
}

div.autocomplette .autocomplette-found,
div.autocomplette .autocomplette-exist {
  position: relative;
  display: none;
  visibility: hidden;
}

div.autocomplette .autocomplette-found ul,
div.autocomplette .autocomplette-exist ul {
  position: absolute;
  top: 0;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(38, 38, 38, 0.2);
  border-radius: 5px;
  z-index: 99;
  width: 100%;
  max-height: 157px;
  overflow-y: scroll;
  text-align: left;
}

div.autocomplette .autocomplette-found ul li,
div.autocomplette .autocomplette-exist ul li {
  padding: 15px 12px;
  cursor: pointer;
}

div.autocomplette .autocomplette-found ul li:hover,
div.autocomplette .autocomplette-exist ul li:hover {
  background: #ebebeb;
}

@font-face {
  font-family: 'OpenSans-ExtraboldItalic';
  src: url("/media/fonts/OpenSans-ExtraboldItalic/OpenSans-ExtraboldItalic.eot?#iefix") format("embedded-opentype"), url("/media/fonts/OpenSans-ExtraboldItalic/OpenSans-ExtraboldItalic.woff") format("woff"), url("/media/fonts/OpenSans-ExtraboldItalic/OpenSans-ExtraboldItalic.ttf") format("truetype"), url("/media/fonts/OpenSans-ExtraboldItalic/OpenSans-ExtraboldItalic.svg#OpenSans-ExtraboldItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open-Sans';
  src: url("/media/fonts/OpenSans-Regular/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("/media/fonts/OpenSans-Regular/OpenSans-Regular.woff") format("woff"), url("/media/fonts/OpenSans-Regular/OpenSans-Regular.ttf") format("truetype"), url("/media/fonts/OpenSans-Regular/OpenSans-Regular.svg#OpenSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url("/media/fonts/OpenSans-Bold/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("/media/fonts/OpenSans-Bold/OpenSans-Bold.woff") format("woff"), url("/media/fonts/OpenSans-Bold/OpenSans-Bold.ttf") format("truetype"), url("/media/fonts/OpenSans-Bold/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Semibold';
  src: url("/media/fonts/OpenSans-Semibold/OpenSans-Semibold.eot?#iefix") format("embedded-opentype"), url("/media/fonts/OpenSans-Semibold/OpenSans-Semibold.woff") format("woff"), url("/media/fonts/OpenSans-Semibold/OpenSans-Semibold.ttf") format("truetype"), url("/media/fonts/OpenSans-Semibold/OpenSans-Semibold.svg#OpenSans-Semibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Extrabold';
  src: url("/media/fonts/OpenSans-Extrabold/OpenSans-Extrabold.eot?#iefix") format("embedded-opentype"), url("/media/fonts/OpenSans-Extrabold/OpenSans-Extrabold.woff") format("woff"), url("/media/fonts/OpenSans-Extrabold/OpenSans-Extrabold.ttf") format("truetype"), url("/media/fonts/OpenSans-Extrabold/OpenSans-Extrabold.svg#OpenSans-Extrabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* stylelint-disable */
.ic {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
  position: relative;
}

.ic--about-1 {
  height: 44px;
  width: 44px;
}

.ic--about-2 {
  height: 44px;
  width: 44px;
}

.ic--add_file {
  height: 15px;
  width: 15px;
}

.ic--alert-circle {
  height: 32px;
  width: 32px;
}

.ic--alert-triangle {
  height: 16px;
  width: 16px;
}

.ic--arrow-left {
  height: 12px;
  width: 10px;
}

.ic--arrow_down {
  height: 8px;
  width: 12px;
}

.ic--arrow_main {
  height: 48px;
  width: 48px;
}

.ic--asterix {
  height: 6px;
  width: 6px;
}

.ic--award {
  height: 50px;
  width: 39px;
}

.ic--big_yellow_two {
  height: 93px;
  width: 68px;
}

.ic--calendar {
  height: 38px;
  width: 36px;
}

.ic--check {
  height: 10px;
  width: 13px;
}

.ic--check-circle {
  height: 32px;
  width: 32px;
}

.ic--checked {
  height: 10px;
  width: 13px;
}

.ic--checked_master_tooltip {
  height: 19px;
  width: 138px;
}

.ic--choose_master {
  height: 41px;
  width: 41px;
}

.ic--clip {
  height: 15px;
  width: 14px;
}

.ic--close {
  height: 11px;
  width: 11px;
}

.ic--close-hint {
  height: 12.31px;
  width: 12.31px;
}

.ic--close-new {
  height: 10px;
  width: 10px;
}

.ic--cls {
  height: 15px;
  width: 15px;
}

.ic--columns {
  height: 47px;
  width: 47px;
}

.ic--company {
  height: 48px;
  width: 48px;
}

.ic--contractor {
  height: 107px;
  width: 106px;
}

.ic--corner-up-left {
  height: 15px;
  width: 15px;
}

.ic--cross-big {
  height: 23px;
  width: 23px;
}

.ic--cross-new {
  height: 10px;
  width: 10px;
}

.ic--customer {
  height: 99px;
  width: 106px;
}

.ic--dark-lock {
  height: 11px;
  width: 10px;
}

.ic--dark-stars {
  height: 12px;
  width: 81px;
}

.ic--delete {
  height: 10px;
  width: 10px;
}

.ic--disabled {
  height: 37px;
  width: 36px;
}

.ic--done {
  height: 43px;
  width: 36px;
}

.ic--double-arrows {
  height: 7px;
  width: 9px;
}

.ic--download {
  height: 14px;
  width: 10px;
}

.ic--dropdown-arrow {
  height: 8px;
  width: 12px;
}

.ic--excellent {
  height: 82px;
  width: 82px;
}

.ic--excellent-mini {
  height: 17px;
  width: 18px;
}

.ic--exp {
  height: 82px;
  width: 83px;
}

.ic--exp-mini {
  height: 17px;
  width: 18px;
}

.ic--face {
  height: 48px;
  width: 48px;
}

.ic--failed {
  height: 15px;
  width: 15px;
}

.ic--fav-tag {
  height: 14px;
  width: 12px;
}

.ic--free {
  height: 48px;
  width: 48px;
}

.ic--good-start {
  height: 82px;
  width: 82px;
}

.ic--good-start-mini {
  height: 17px;
  width: 17px;
}

.ic--green-check {
  height: 8px;
  width: 10px;
}

.ic--hobby {
  height: 60px;
  width: 60px;
}

.ic--ic_dnd {
  height: 52px;
  width: 66px;
}

.ic--ic_sort {
  height: 8px;
  width: 9px;
}

.ic--key {
  height: 8px;
  width: 15px;
}

.ic--key-order {
  height: 50px;
  width: 50px;
}

.ic--king {
  height: 15px;
  width: 15px;
}

.ic--left-arrow {
  height: 16px;
  width: 16px;
}

.ic--liga {
  height: 13px;
  width: 24px;
}

.ic--liga-full {
  height: 89px;
  width: 110px;
}

.ic--liga-small {
  height: 10px;
  width: 18px;
}

.ic--lock {
  height: 13px;
  width: 11px;
}

.ic--lock-open {
  height: 15px;
  width: 11px;
}

.ic--lock-red {
  height: 16px;
  width: 15px;
}

.ic--logo-gr {
  height: 11px;
  width: 20px;
}

.ic--mail {
  height: 41px;
  width: 51px;
}

.ic--main_arrow_left {
  height: 24px;
  width: 32px;
}

.ic--main_arrow_right {
  height: 24px;
  width: 32px;
}

.ic--map-tag {
  height: 13px;
  width: 9px;
}

.ic--master {
  height: 48px;
  width: 48px;
}

.ic--mob_error {
  height: 50px;
  width: 50px;
}

.ic--next {
  height: 10px;
  width: 12px;
}

.ic--notify {
  height: 50px;
  width: 50px;
}

.ic--object {
  height: 112px;
  width: 145px;
}

.ic--offer_order {
  height: 44px;
  width: 48px;
}

.ic--ok {
  height: 9px;
  width: 11px;
}

.ic--ok_prime {
  height: 9px;
  width: 11px;
}

.ic--pencil {
  height: 10px;
  width: 10px;
}

.ic--pencil-blank {
  height: 16px;
  width: 16px;
}

.ic--photo_placeholder {
  height: 50px;
  width: 60px;
}

.ic--place_order {
  height: 48px;
  width: 48px;
}

.ic--popular {
  height: 82px;
  width: 82px;
}

.ic--popular-mini {
  height: 17px;
  width: 17px;
}

.ic--prev {
  height: 10px;
  width: 12px;
}

.ic--priceless {
  height: 82px;
  width: 82px;
}

.ic--priceless-mini {
  height: 17px;
  width: 17px;
}

.ic--reopen {
  height: 12px;
  width: 15px;
}

.ic--result {
  height: 48px;
  width: 48px;
}

.ic--review {
  height: 48px;
  width: 48px;
}

.ic--review_cloud {
  height: 12px;
  width: 12px;
}

.ic--round_waiting {
  height: 30px;
  width: 30px;
}

.ic--search {
  height: 17px;
  width: 17px;
}

.ic--send {
  height: 18px;
  width: 18px;
}

.ic--send-black {
  height: 18px;
  width: 18px;
}

.ic--shield {
  height: 43px;
  width: 36px;
}

.ic--skill-1 {
  height: 47px;
  width: 33px;
}

.ic--skill-2 {
  height: 48px;
  width: 43px;
}

.ic--skill-3 {
  height: 45px;
  width: 45px;
}

.ic--skill-4 {
  height: 43px;
  width: 43px;
}

.ic--skill-5 {
  height: 48px;
  width: 38px;
}

.ic--skill-6 {
  height: 46px;
  width: 33px;
}

.ic--skill-7 {
  height: 34px;
  width: 48px;
}

.ic--skill-8 {
  height: 44px;
  width: 35px;
}

.ic--smile {
  height: 48px;
  width: 48px;
}

.ic--sms {
  height: 34px;
  width: 36px;
}

.ic--sortable-handle {
  height: 10px;
  width: 10px;
}

.ic--speaker {
  height: 46px;
  width: 54px;
}

.ic--sprite {
  height: 0px;
  width: 0px;
}

.ic--star {
  height: 16px;
  width: 16px;
}

.ic--star-achive {
  height: 82px;
  width: 83px;
}

.ic--star-achive-mini {
  height: 17px;
  width: 18px;
}

.ic--star_half {
  height: 16px;
  width: 16px;
}

.ic--stars-grey {
  height: 18px;
  width: 90px;
}

.ic--stars1 {
  height: 30px;
  width: 94px;
}

.ic--stars2 {
  height: 34px;
  width: 94px;
}

.ic--stub_icon {
  height: 48px;
  width: 48px;
}

.ic--subscribe-disabled {
  height: 16px;
  width: 15px;
}

.ic--subscribe-enabled {
  height: 16px;
  width: 14px;
}

.ic--swiper-arrow-left {
  height: 10px;
  width: 12px;
}

.ic--swiper-arrow-right {
  height: 10px;
  width: 12px;
}

.ic--telegram {
  height: 240px;
  width: 240px;
}

.ic--time {
  height: 11px;
  width: 11px;
}

.ic--upload {
  height: 46px;
  width: 71px;
}

.ic--upload-orange {
  height: 46px;
  width: 71px;
}

.ic--user {
  height: 24px;
  width: 24px;
}

.ic--wise {
  height: 82px;
  width: 82px;
}

.ic--wise-mini {
  height: 17px;
  width: 17px;
}

.ic--wreath {
  height: 75px;
  width: 167px;
}

/* stylelint-enable */
.profile_edit_page .phone_input__wrap {
  width: 200px;
}

@media (max-width: 639px) {
  .profile_edit_page .phone_input__wrap {
    width: 100%;
  }
}

.profile_create_page .phone_input__wrap {
  width: 320px;
}

@media (max-width: 639px) {
  .profile_create_page .phone_input__wrap {
    width: 100%;
  }
}

.profile_create_page .phone_input__wrap.is_code {
  width: 200px;
}

@media (max-width: 639px) {
  .profile_create_page .phone_input__wrap.is_code {
    width: 100%;
  }
}

.phone_wrap {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

@media (max-width: 639px) {
  .phone_wrap {
    flex-direction: column;
  }
  .phone_wrap .button--confirm {
    margin-top: 20px;
    max-width: 132px;
  }
}

@media (max-width: 639px) and (max-width: 639px) {
  .phone_wrap .phone_input__wrap.is_code {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.phone_input__wrap {
  margin-right: 20px;
  width: 200px;
}

.phone_input__error {
  font-family: "Open Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #fe253c;
  letter-spacing: 0;
  line-height: 30px;
}

.sms_timer {
  font-size: 12px;
  color: #262626;
  letter-spacing: 0;
  line-height: 1;
}

@media (max-width: 639px) {
  .sms_timer {
    top: 118px;
  }
}

.phone_block.is_init .phone_input__wrap.is_code {
  display: none;
}

.phone_block.is_init .phone_service_text.phone_is_confimed {
  display: none;
}

.phone_block.is_init .phone_service_text.phone_is_empty {
  display: block;
}

.phone_block.is_init .button--confirm {
  display: none;
}

.phone_block.phone_is_confirmed .phone_input__wrap.is_code {
  display: none;
}

.phone_block.phone_is_confirmed .button--confirm {
  display: none;
}

.phone_block.phone_is_confirmed .phone_service_text {
  display: none;
}

.phone_block.phone_is_confirmed .phone_input__wrap {
  position: relative;
}

.phone_block.phone_is_confirmed .phone_input__wrap.is_phone:after {
  display: block;
  content: '';
  width: 11px;
  height: 9px;
  background: url(/media/images/is_confirmed.svg) no-repeat;
  position: absolute;
  right: 18px;
  top: 21px;
}

.phone_block.send_phone_code .phone_input__wrap.is_code {
  display: none;
}

.phone_block.send_phone_code .button--confirm {
  display: block;
}

.phone_block.send_phone_code .phone_service_text {
  display: none;
}

.phone_block.code_has_sent .phone_input__wrap.is_code {
  display: block;
}

.phone_block.code_has_sent .button--confirm {
  display: block;
}

.phone_block.code_has_sent .phone_service_text {
  display: none;
}

.phone_block.code_has_sent .sms_timer.phone_send_again {
  display: block;
}

.phone_block.run_timer .phone_input__wrap.is_code {
  display: block;
}

.phone_block.run_timer .button--confirm {
  display: block;
}

.phone_block.run_timer .phone_service_text {
  display: none;
}

.phone_block.run_timer .send_again_msg {
  display: none;
}

.phone_block.phone_error .phone_input__wrap.is_code {
  display: none;
}

.phone_block.phone_error .button--confirm {
  display: block;
}

.phone_block.phone_error .phone_service_text {
  display: none;
}

.phone_block.phone_error .phone_input__wrap.is_phone input {
  border: 1px solid #fe253c;
}

.phone_block.phone_error .phone_input__wrap .phone_input__error {
  display: block;
}

.phone_block.code_error .phone_input__wrap.is_code {
  display: block;
}

.phone_block.code_error .button--confirm {
  display: block;
}

.phone_block.code_error .phone_service_text {
  display: none;
}

.phone_block.code_error .phone_input__wrap.is_code input {
  border: 1px solid #fe253c;
}

.phone_block.code_error .phone_input__wrap .phone_input__error {
  display: inline-block;
}

.phone_block.is_code_fine .phone_input__wrap.is_code {
  display: none;
}

.phone_block.is_code_fine .button--confirm {
  display: none;
}

.phone_block.is_code_fine .phone_service_text.phone_is_confimed {
  display: block;
}

.phone_block.is_code_fine .phone_service_text.phone_is_empty {
  display: none;
}

.phone_block.is_code_fine .phone_input__wrap {
  position: relative;
}

.phone_block.is_code_fine .phone_input__wrap.is_phone:after {
  display: block;
  content: '';
  width: 11px;
  height: 9px;
  background: url(/media/images/is_confirmed.svg) no-repeat;
  position: absolute;
  right: 18px;
  top: 21px;
}

.form__text {
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
}

.form__text.phone_input__input {
  width: 100%;
}

.button {
  height: 44px;
  border-radius: 22px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #262626;
  line-height: 44px;
  padding: 0 20px;
  text-transform: none;
  text-decoration: none;
  border: none;
  box-sizing: border-box;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.button.is_sidebar_btn {
  width: 100%;
}

.button-telegram {
  width: calc(100% - 4px);
  border: 1px solid #C6C7CE;
  border-radius: 4px;
  padding: 9px 11px 11px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  margin: 0 2px;
  align-items: center;
  height: auto;
  box-sizing: border-box;
}

.button-telegram__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;
  position: relative;
  width: 260px;
}

.button-telegram__wrapper:after {
  content: '';
  background-color: transparent;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 10px;
  display: block;
  transform: translateY(-50%) rotate(45deg);
  border-right: 1px solid #181818;
  border-top: 1px solid #181818;
}

.button-telegram:hover {
  color: transparent;
  border-color: #C6C7CE;
  box-shadow: 0 0 0 2px #F2F2F2 inset, 0 0 0 2px #F2F2F2 inset;
}

.button-telegram__text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.button-telegram__title {
  font-size: 12px;
  line-height: 20px;
  color: #A5A8AD;
  letter-spacing: -0.2px;
  padding-right: 3px;
}

.button-telegram__title--bot {
  color: #000;
}

.button-telegram .ic--telegram {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  margin-right: 12px;
}

@media (min-width: 600px) {
  .button-telegram {
    padding: 8px 9px 10px;
    max-width: 162px;
    margin: 0;
    margin-right: auto;
    margin-top: 9px;
  }
  .button-telegram__wrapper {
    align-items: center;
  }
  .button-telegram__wrapper:after {
    display: none;
  }
  .button-telegram__text {
    flex-wrap: wrap;
  }
  .button-telegram .ic--telegram {
    margin-bottom: 3px;
    margin-right: 9px;
  }
}

@media (min-width: 900px) {
  .button-telegram {
    align-items: center;
    padding: 12px 9px 10px 15px;
    width: 216px;
    max-width: 216px;
  }
  .button-telegram .ic--telegram {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    margin-right: 14px;
  }
  .button-telegram__title {
    font-size: 14px;
  }
}

.button__primary {
  background-color: #ffd24d;
}

.button__primary:hover {
  color: #555659;
  background-color: #ffd966;
}

.button__secondary {
  background-color: #e3e5e8;
}

.button__secondary:hover {
  color: #555659;
  background-color: #edeef0;
}

.button__secondary--disabled {
  color: #555659;
  background-color: #edeef0;
  opacity: 0.5;
  pointer-events: none;
}

.button__secondary--chat {
  color: #FF4545;
}

.button__delete {
  background: #fd051f;
  width: 200px;
  color: #ffffff;
}

@media (max-width: 639px) {
  .button__delete {
    width: 100%;
  }
}

.button__delete:hover {
  background: #ff3d47;
  color: #ffffff;
}

.button__delete[disabled="disabled"], .button__delete.is_disabled {
  background: #e3e5e8;
  color: #a5a8ad;
  cursor: not-allowed;
}

.button__login .ic {
  top: -2px;
  margin-right: 10px;
}

.button__login .ic--lock-open {
  fill: #555659;
  display: none;
}

.button__login:hover .ic--lock {
  display: none;
}

.button__login:hover .ic--lock-open {
  display: inline-block;
}

.button--border {
  border: solid 2px #e3e5e8;
  font-size: 14px;
  color: #a5a8ad;
  line-height: 42px;
  font-weight: normal;
}

.button--border:hover {
  border-color: #edeef0;
  color: #555659;
  background-color: #edeef0;
}

.button--transparent {
  display: none;
}

.button--transparent .ic--left-arrow {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

@media (min-width: 600px) {
  .button--transparent {
    font-family: "Open-Sans", "Arial", sans-serif;
    display: inline-flex;
    align-items: center;
    border: 1px solid #e3e5e8;
    border-radius: 4px;
    font-size: 14px;
    line-height: 19px;
    color: #4D5054;
    padding: 12px 20px 12px 15px;
    margin-left: 20px;
    margin-bottom: 31px;
    margin-top: 23px;
  }
  .button--transparent:hover {
    color: #4D5054;
    border: 1px solid #e3e5e8;
    box-shadow: 0 0 0 2px #F2F2F2 inset, 0 0 0 2px #F2F2F2 inset;
  }
}

@media (min-width: 900px) {
  .button--transparent {
    margin-top: 37px;
    margin-left: 30px;
    margin-bottom: 12px;
  }
}

@media (min-width: 1280px) {
  .button--transparent {
    margin-left: 80px;
  }
}

.button--open_filters {
  border: solid 2px #e3e5e8;
  font-size: 14px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #262626;
  line-height: 40px;
  width: 93%;
  margin: 0 auto 8px;
  display: block;
}

@media (min-width: 900px) {
  .button--open_filters {
    display: none;
  }
}

.button--show_more {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 200px;
}

.button--icon {
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: center;
  line-height: 20px;
  position: relative;
}

.button--icon .ic {
  fill: #262626;
}

.button--icon:before, .button--icon:after {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  height: 20px;
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  z-index: 110;
}

.button--icon:before {
  content: '';
  background: url(/media/images/fav-label-tag.png) 0 0 no-repeat;
  width: 8px;
  margin-left: 13px;
}

.button--icon:after {
  content: attr(data-label);
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-left: 20px;
  border-radius: 0 3px 3px 0;
  background-color: #555659;
  white-space: nowrap;
  padding: 0 5px;
}

.button--icon:hover .ic {
  fill: #555659;
}

@media (min-width: 1000px) {
  .button--icon:hover:before, .button--icon:hover:after {
    opacity: 1;
    visibility: visible;
  }
}

.showmore__link {
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #a5a8ad;
}

.showmore__link:hover {
  color: #555659;
}

input:focus {
  outline-style: none;
  box-shadow: none;
}

button:focus {
  outline-style: none;
  box-shadow: none;
}

.text_descr {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 30px;
  text-transform: none;
  font-weight: 400;
  display: block;
}

@media (max-width: 639px) {
  .text_descr {
    line-height: 24px;
  }
}

.button--confirm {
  font-size: 14px;
  letter-spacing: 0;
  border: 1px solid #e3e5e8;
  border-radius: 21px;
  background: #e3e5e8;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #262626;
  display: inline-block;
  cursor: pointer;
  width: 160px;
}

.button--confirm:hover {
  border: 1px solid #edeef0;
  color: #555659;
  background-color: #edeef0;
}

.button--confirm.is_edit {
  padding: 7px 19px 8px 18px;
  line-height: 17px;
  height: auto;
  width: auto;
}

.button--confirm.is_offer {
  padding: 8px 38px 10px;
  line-height: 17px;
  height: auto !important;
  width: auto;
}

.button--confirm.is_create {
  padding: 9px 28px;
  height: auto !important;
  width: auto;
  line-height: 17px;
}

.button--confirm.button__primary {
  background: #ffd24d;
  border: none;
}

.button--confirm.button__primary:hover {
  border: none;
  background: #ffd24d;
}

@media (max-width: 599px) {
  .button--confirm {
    width: auto;
  }
  .button--confirm.is_offer.button__primary, .button--confirm.is_edit.button__primary {
    margin-left: 70px;
    margin-top: 10px;
  }
  .button--confirm.button__small {
    padding: 0;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .button--confirm.button__small {
    padding: 0;
  }
}

.button--confirm:disabled,
.button--confirm[disabled] {
  background: #e3e5e8;
  color: #ffffff;
  cursor: not-allowed;
}

.button--confirm:hover {
  border: 1px solid #edeef0;
  color: #555659;
  background-color: #edeef0;
}

.button__search {
  background-image: none;
  width: 44px;
  height: 44px;
  border-radius: 0 4px 4px 0;
  padding: 0;
}

.search__container:hover .button__search {
  background-color: #ffd966;
}

.button--chat {
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  transition-property: background, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  outline: none;
  background-image: none;
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 4px;
  padding: 0;
}

.button--chat:hover {
  background-color: #ffd966;
}

.button--chat:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.check_item {
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.check_item .check_item__input {
  opacity: 0;
}

.check_item input[type="checkbox"] {
  visibility: hidden;
}

input[type=checkbox]:checked + .check_item__checked {
  background: #ffd24d;
  border-color: #ffd24d;
}

input[type=checkbox]:checked + .check_item__checked:before {
  display: block;
}

input[type=checkbox]:disabled + .check_item__checked + .profile_create_spec__text {
  color: #a5a8ad;
  cursor: not-allowed;
}

input[type=checkbox]:disabled + .check_item__checked {
  font-size: 14px;
  color: #d2d2d4;
  letter-spacing: 0;
  line-height: 40px;
  cursor: not-allowed;
  background: #ffffff;
  border: 1px solid #edeef0;
  width: 25px;
  height: 25px;
}

input[type=checkbox]:disabled + .check_item__checked:before {
  display: none;
}

.check_item__checked {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  border-radius: 4px;
  left: -5px;
  display: inline-block;
  border: 1px solid #c6c7cE;
  background: #ffffff;
}

.check_item__checked:before {
  content: '';
  background: url(/media/images/ok.svg);
  width: 11px;
  height: 9px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  transform: translate(-50%, -50%);
}

.listing_sidebar .check_item__checked {
  border-color: #edeef0;
}

@media (max-width: 599px) {
  .check_item__checked {
    width: 18px;
    height: 18px;
    left: 2px;
    top: 5px;
  }
}

.check_item__text {
  font-size: 14px;
  color: #262626;
}

.select_spec {
  position: absolute;
  top: 50px;
  left: 0;
  background: #ffffff;
  z-index: 999;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding-top: 25px;
  padding-left: 19px;
  overflow-y: auto;
}

@media (max-width: 639px) {
  .select_spec {
    position: static;
    padding-bottom: 25px;
  }
}

@media (min-width: 640px) {
  .select_spec {
    padding-bottom: 25px;
    height: 480px;
  }
}

@media (min-width: 1280px) {
  .select_spec {
    padding-left: 12px;
  }
}

.select_spec_items {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 25px;
  display: none;
  position: static;
}

@media (min-width: 1280px) {
  .select_spec_items {
    position: absolute;
    left: 300px;
    top: 27px;
    padding-right: 12px;
  }
}

.select_spec_items__item {
  line-height: normal;
}

.select_spec_items__item:not(:last-child) {
  margin-bottom: 10px;
}

.select_spec_items__item:hover {
  cursor: pointer;
  background-color: #EDEEF0;
}

.select_spec_items__item--active {
  position: relative;
  background-color: #ffd24d !important;
}

.select_spec_items__item--active::after {
  display: block;
  content: "";
  position: absolute;
  top: 5px;
  left: -10px;
  border-bottom: 2px solid #000000;
  border-left: 2px solid #000000;
  transform: rotate(-45deg);
  height: 4px;
  width: 8px;
  z-index: 1;
  background-color: transparent;
}

.select_spec_categories__item {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 14px;
  color: #a5a8ad;
  line-height: 20px;
}

.select_spec_categories__item span {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.select_spec_categories__item span:hover {
  background-color: #edeef0 !important;
}

.select_spec_categories__item span.is_active {
  color: #262626;
  background-color: #ffd24d;
}

.select_spec_categories__item span.is_active:after {
  content: '';
  width: 7px;
  height: 10px;
  background: url(/media/images/arrow.svg);
  position: absolute;
  right: -26px;
  top: 6px;
}

@media (max-width: 1140px) {
  .select_spec_categories__item span.is_active:after {
    background: none;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .select_spec_categories__item span.is_active:after {
    background: none;
  }
}

@media (max-width: 639px) {
  .select_spec_categories__item span.is_active:after {
    background: none;
  }
}

.sub-wrap {
  width: 50%;
  background-color: rgba(237, 238, 240, 0.5);
  border-radius: 3px;
  overflow-y: scroll;
}

.select_spec_categories {
  margin-bottom: 20px;
}

.button__newsletter {
  display: block;
  width: 160px;
}

.form_newsletter__personal_data a {
  display: inline;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

@media (max-width: 639px) {
  .breadcrumbs {
    overflow-x: auto;
  }
}

.breadcrumb__item {
  margin-right: 28px;
  position: relative;
}

.breadcrumb__item:last-of-type {
  margin-right: 0;
}

.breadcrumb__item:last-of-type:after {
  position: static;
}

.breadcrumb__item a {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
}

.breadcrumb__item a:hover {
  color: #555659;
}

.breadcrumb__item > span {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
}

.breadcrumb__item:after {
  content: '';
  width: 10px;
  height: 5px;
  background: url(/media/images/bd_divider.svg) no-repeat;
  right: -18px;
  top: 10px;
  position: absolute;
}

@media (max-width: 639px) {
  .breadcrumb__item {
    white-space: nowrap;
  }
}

.file_input_link {
  display: inline-table;
  padding-top: 12px;
  cursor: pointer;
}

@media (min-width: 600px) {
  .file_input_link {
    padding-top: 18px;
  }
}

@media (min-width: 900px) {
  .file_input_link {
    padding-top: 28px;
  }
}

.file_input_link__msg {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 15px;
}

.file_input_link__msg:after {
  content: '+';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #d2d2d4;
  text-align: center;
  line-height: 15px;
  right: -23px;
  top: 1px;
  font-family: inherit;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  display: inline-block;
  margin-left: 8px;
}

.file_input_link__input {
  display: none;
}

.button__small {
  height: 34px;
  line-height: 34px;
  padding: 0 35px;
}

.button__small .ic--lock-open {
  margin-right: 14px;
}

@media (max-width: 600px) {
  .button__small {
    padding: 0 31px;
  }
}

.card_profile_msg {
  background: #f5f6f7;
  border-radius: 8px;
  width: 100%;
  padding: 17px 27px 21px;
  margin: 36px 0 19px;
  line-height: 21px;
  font-size: 14px;
}

.card_profile_msg.is_order {
  padding: 32px 40px 33px;
}

.top_msg {
  padding: 68px 0 75px 80px;
  background-color: #f5f6f7;
}

@media (max-width: 639px) {
  .top_msg {
    padding: 22px 20px 25px;
  }
}

.top_msg__msg {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
  max-width: 711px;
  padding-bottom: 31px;
}

@media (max-width: 639px) {
  .top_msg__msg {
    font-size: 14px;
    line-height: 25px;
    max-width: 280px;
    padding-bottom: 21px;
  }
}

.top_msg__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 14px;
}

@media (max-width: 639px) {
  .top_msg__text {
    font-size: 12px;
    max-width: 220px;
  }
}

.file-preview {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.file-preview .file-preview-thumbnails {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone_service_text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 40px;
}

.user_message {
  background: #f5f6f7;
  border-radius: 8px;
}

.user_message p {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  padding: 12px 34px 10px 27px;
  margin: 0;
}

.delete_item:hover {
  color: #262626;
}

.file_item {
  border: 1px solid #edeef0;
  border-radius: 4px;
  padding: 14px 0 14px 40px;
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.file_item:before {
  content: '';
  width: 11px;
  height: 9px;
  background: url(/media/images/is_confirmed.svg) no-repeat;
  position: absolute;
  left: 18px;
  top: 21px;
}

.file_item:first-of-type {
  margin-top: 26px;
}

.file_item:last-of-type {
  margin-bottom: 0;
}

.file_item__control .ic--delete {
  position: static;
  padding: 0;
  margin-right: 19px;
}

.file_descr_text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  line-height: normal;
  width: 100%;
  margin-top: 10px;
  display: block;
}

@media (min-width: 600px) {
  .file_descr_text {
    line-height: 20px;
  }
}

@media (min-width: 1280px) {
  .file_descr_text {
    text-align: center;
    margin-top: 16px;
  }
}

@media (max-width: 900px) {
  .file_descr_text {
    font-size: 10px;
    line-height: 14px;
    margin-top: 17px;
  }
}

@media (max-width: 599px) {
  .file_descr_text {
    margin-top: 9px;
  }
}

.portfolio_controls__btn {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 24px;
  color: #262626;
  line-height: normal;
  margin-top: 10px;
}

.dnd_file_input {
  width: 100%;
  margin-top: 13px;
  max-height: 454px;
  position: relative;
  overflow-y: auto;
}

@media (max-height: 1280px) {
  .dnd_file_input {
    max-height: 100%;
    overflow-y: visible;
  }
}

@media (min-width: 600px) and (max-width: 1279px) {
  .dnd_file_input {
    margin-top: 10px;
  }
}

@media (max-width: 599px) {
  .dnd_file_input {
    margin-top: 27px;
  }
}

.dnd_file_input__text {
  text-align: center;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #a5a8ad;
  line-height: normal;
  margin-top: 10px;
}

.dns_errors_container {
  margin-top: 15px;
}

.dns_errors_container p {
  padding-bottom: 15px;
}

.dns_errors_container .error {
  color: red;
}

.dns_errors_container .descr {
  color: #262626;
}

.dnd_file_error {
  color: red;
  padding-bottom: 15px;
}

.dnd_file_descr {
  color: #262626;
  padding-bottom: 15px;
}

.dnd_file_input__label {
  display: block;
  width: 100%;
  text-align: center;
}

.page_content__right .button {
  width: 100%;
  margin-top: 35px;
}

.page_content__right .button.js_offer_order {
  margin-top: 0;
}

.delete_popup {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(38, 38, 38, 0.1);
  border-radius: 10px;
  max-width: 640px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 202;
  padding: 69px 83px 69px 80px;
  display: none;
}

.delete_popup__inner {
  display: flex;
  flex-direction: column;
}

.delete_popup__top {
  display: flex;
  flex-direction: row;
}

.delete_popup__title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 20px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 15px;
}

.delete_popup__msg {
  margin-bottom: 32px;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 25px;
}

.delete_popup__btns {
  display: flex;
  justify-content: space-between;
  max-width: 420px;
}

.delete_popup__btns .button__secondary {
  width: 200px;
}

.delete_msg {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  margin-top: 11px;
}

.order_file_item {
  border: 1px solid #e3e5e8;
  border-radius: 4px;
  max-width: 479px;
  height: 44px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.order_file_item:last-of-type {
  margin-bottom: 0;
}

.order_file_item:hover {
  border: 1px solid #e3e5e8;
}

.order_file_item__icon {
  margin: 0 21px;
}

.order_file_item__file_name {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 24px;
}

.spec_wrap {
  position: relative;
}

.spec_wrap:after {
  content: '';
  width: 7px;
  height: 11px;
  background: url(/media/images/arrow.svg) no-repeat;
  position: absolute;
  right: 22px;
  top: 17px;
  transform: rotate(90deg);
}

.form_spec .spec_wrap {
  position: relative;
  margin-bottom: 30px;
}

.form_spec .spec_wrap:after {
  content: '';
  width: 7px;
  height: 11px;
  background: url(/media/images/arrow.svg) no-repeat;
  position: absolute;
  right: 15px;
  top: 17px;
  transform: rotate(90deg);
}

.top_banner .wrapper.is_top_banner_width_icon {
  width: auto;
  display: flex;
  margin: 41px auto 44px;
  align-items: center;
  padding: 0 20px;
}

@media (min-width: 640px) {
  .top_banner .wrapper.is_top_banner_width_icon {
    padding-left: 26px;
  }
}

@media (min-width: 1000px) {
  .top_banner .wrapper.is_top_banner_width_icon {
    padding: 0 30px 0 90px;
  }
}

.top_banner .wrapper .top_banner_message,
.top_banner .wrapper .top_banner_phone {
  margin: 0 auto 0 0;
}

@media (max-width: 639px) {
  .top_banner .wrapper .top_banner_message,
  .top_banner .wrapper .top_banner_phone {
    width: auto;
  }
}

.top_banner .wrapper .top_banner__message__icon {
  margin-right: 102px;
}

@media (max-width: 639px) {
  .top_banner .wrapper .top_banner__message__icon {
    display: none;
  }
}

.top_banner .wrapper .top_banner__close {
  align-self: flex-start;
  cursor: pointer;
}

.top_banner .wrapper .top_banner_message__title,
.top_banner .wrapper .top_banner__message__title,
.top_banner .wrapper .top_banner_phone__title {
  margin-bottom: 0;
}

.top_banner .wrapper .top_banner_message__text,
.top_banner .wrapper .top_banner_phone__text {
  margin-top: 28px;
}

.top_banner .wrapper .top_banner_message + .top_banner_phone {
  padding-top: 0;
}

.top_banner .wrapper order_message__btn + .order_phone__btn {
  margin-top: 5px;
}

.portfolio_controls__drag_container {
  height: 225px;
  width: 100%;
  border: 3px dashed #C6C7CE;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
}

.portfolio_controls__drag_container.is-dragover {
  background-color: #f5f6f7;
}

.portfolio_controls__drag_container.is_error {
  border-color: rgba(253, 45, 44, 0.61);
}

.portfolio_controls__drag_container:hover {
  border-color: #c6c7cE;
}

@media (min-width: 1280px) {
  .portfolio_controls__drag_container {
    display: flex;
  }
}

.dnd_mobile_btn {
  display: flex;
  height: 150px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.dnd_mobile_btn span {
  display: block;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #262626;
  margin-top: 10px;
}

.dnd_mobile_btn .button__attach {
  display: flex;
  position: relative;
}

.dnd_mobile_btn .ic--clip {
  position: absolute;
  top: 10px;
  left: 13px;
}

@media (min-width: 1280px) {
  .dnd_mobile_btn {
    display: none;
  }
}

.cities_list {
  position: relative;
}

.cities_list .cities_list__items {
  position: absolute;
  top: 0;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(38, 38, 38, 0.2);
  border-radius: 5px;
  z-index: 99;
  width: 100%;
  max-height: 157px;
  overflow-y: scroll;
  text-align: left;
}

.cities_list .cities_list__items .cities_list__item {
  padding: 15px 12px;
  cursor: pointer;
}

.cities_list .cities_list__items .cities_list__item:hover {
  background: #ebebeb;
}

.preselect_input {
  width: 100%;
  height: 100%;
}

input[readonly] {
  cursor: text;
  background-color: #ffffff;
}

.master_stats.is_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.master_stats.is_bottom .master {
  display: flex;
}

@media (max-width: 639px) {
  .master_stats.is_bottom {
    position: static !important;
  }
}

.ruble .fallback {
  display: none;
}

.ruble:after {
  content: "\0020\20BD";
  font-size: inherit;
  font-family: inherit;
  color: currentColor;
}

.field-city_id {
  background: #ffffff;
}

.field-city_id .input__wrapper {
  padding: 0 30px 0 9px;
  background: transparent;
}

.field-city_id.dropdown--autocomplete:before {
  z-index: 1;
}

.radio_item {
  display: flex;
  align-items: center;
}

.radio_item .radio_item__input {
  opacity: 0;
  visibility: hidden;
}

.radio_item__input:checked + .radio_item__checked {
  background: #ffd24d;
  border-color: #ffd24d;
}

.radio_item__input:checked + .radio_item__checked:before {
  width: 10px;
  height: 10px;
}

.radio_item__text {
  font-size: 14px;
}

.radio_item__checked {
  width: 24px;
  height: 24px;
  position: relative;
  top: 0;
  border-radius: 50%;
  left: 0;
  display: block;
  border: 1px solid #edeef0;
  background: #ffffff;
  margin-right: 15px;
}

.radio_item__checked:before {
  content: '';
  background-color: #ffffff;
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .15s ease-in-out;
}

.radio_item__checked-active {
  background: #ffd24d;
  border-color: #ffd24d;
}

.radio_item__checked-active:before {
  width: 10px;
  height: 10px;
}

.check_item__text {
  font-size: 14px;
  color: #262626;
}

.select_spec_subheader {
  position: absolute;
  right: 0;
  top: 30px;
}

.close-dropdown {
  position: absolute;
  right: 0;
  top: 0;
  height: 20px;
  width: 20px;
  border: none;
  appearance: none;
  background-color: transparent;
  background-image: url(/media/images/cross.png);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
}

.phone_block.phone_is_confirmed .button--confirm {
  display: block !important;
  opacity: 0.7;
}

.order_create_wrapper .order_signup .button--confirm {
  position: static;
  transform: none;
  width: 286px !important;
  min-width: 286px !important;
}

@media (min-width: 600px) {
  .order_create_wrapper .order_signup .button--confirm {
    width: calc(50% - 11px) !important;
    min-width: calc(50% - 11px) !important;
  }
}

@media (max-width: 599px) {
  .order_create_wrapper .order_signup .button--confirm {
    width: 280px !important;
    min-width: 280px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

.order_create_wrapper .order_signup .phone_input__wrap:first-child {
  margin-right: 18px;
}

.phone_nowrap .phone_wrap {
  flex-wrap: nowrap !important;
}

@media (min-width: 600px) {
  .phone_nowrap .phone_wrap {
    flex-direction: row;
  }
}

@media (max-width: 599px) {
  .phone_nowrap .phone_wrap {
    flex-direction: column;
  }
}

.phone_input__wrap {
  width: 286px !important;
  min-width: 286px !important;
}

@media (max-width: 900px) {
  .phone_input__wrap {
    width: calc(50% - 10px) !important;
    min-width: calc(50% - 10px) !important;
  }
}

@media (max-width: 599px) {
  .phone_input__wrap {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.check_item__checked-active {
  background: #ffd24d;
  border-color: #ffd24d;
}

.check_item__checked-active:before {
  display: block;
}

.file_container {
  margin-bottom: 5px;
  padding-top: 12px;
  max-height: 254px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.file_container:after {
  content: " ";
  clear: both;
  display: table;
}

@media (max-width: 1280px) {
  .file_container {
    max-height: 100%;
  }
}

.portfolio_controls.dnd_file_input__label {
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  overflow-y: scroll;
}

html.menu_open {
  overflow: hidden;
  position: fixed;
  top: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

@media (min-width: 640px) and (max-width: 999px) {
  .menu_open body:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 320px;
    bottom: 0;
    background-color: rgba(245, 246, 247, 0.5);
    z-index: 200;
  }
}

@media (min-width: 1000px) {
  body {
    overflow-x: inherit;
  }
}

body:after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(/media/images/stars.svg) url(/media/images/stars-h.svg);
}

body a {
  color: currentColor;
}

body label {
  margin: 0;
  font-weight: normal;
  max-width: none;
}

.layout_1col .page_content {
  flex-flow: column;
}

.page_content {
  font-family: "Open-Sans", "Arial", sans-serif;
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

.page_content__left {
  width: 100%;
  flex-shrink: 0;
  position: relative;
}

@media (min-width: 640px) {
  .page_content__left {
    width: 65%;
    padding-top: 28px;
  }
}

@media (min-width: 1000px) {
  .page_content__left {
    width: 68%;
  }
}

@media (min-width: 1280px) {
  .page_content__left {
    width: 75%;
  }
}

.page_content__right {
  background-color: #f5f6f7;
  flex-shrink: 0;
  padding: 74px 0 0;
  display: none;
}

@media (min-width: 640px) {
  .page_content__right {
    width: 35%;
    display: block;
    padding-top: 54px;
  }
}

@media (min-width: 1000px) {
  .page_content__right {
    min-width: 320px;
    max-width: 320px;
  }
}

@media (min-width: 1280px) {
  .page_content__right {
    width: 25%;
    max-width: inherit;
  }
}

@media (max-width: 899px) {
  .page_content__right {
    width: 100%;
  }
}

a:hover,
a:focus {
  text-decoration: none !important;
}

.js_link {
  cursor: pointer;
}

.page__title.is_mobile {
  display: none;
}

@media (min-width: 640px) and (max-width: 999px) {
  .page__title.is_mobile {
    display: block;
    font-family: "OpenSans-Bold", "Arial", sans-serif;
    font-size: 18px;
    color: #262626;
    letter-spacing: 0;
    line-height: 25px;
    margin: 0;
    text-align: center;
    padding: 20px 0 24px;
  }
}

@media (max-width: 639px) {
  .page__title.is_mobile {
    display: flex;
    flex-direction: column;
  }
}

.page__title .page_title {
  width: 75%;
  float: left;
  margin: 46px 61px 0;
}

@media (min-width: 1000px) {
  .page__title .page_title .master_descr {
    padding: 0 80px 74px;
  }
}

@media (min-width: 1000px) {
  .page__title .page_title {
    width: 68%;
  }
}

@media (max-width: 999px) {
  .page__title .page_title {
    width: 66%;
  }
}

@media (max-width: 639px) {
  .page__title .page_title {
    width: auto;
    float: none;
    order: 1;
    margin: 0;
  }
}

.page_content__left {
  width: 75%;
  float: left;
  padding-top: 0;
}

@media (min-width: 1000px) {
  .page_content__left {
    width: 69%;
  }
  .page_content__left .specialization {
    padding: 0 20px 20px;
  }
}

@media (max-width: 999px) {
  .page_content__left {
    width: 64%;
  }
}

@media (max-width: 639px) {
  .page_content__left {
    width: auto;
    float: none;
    order: 3;
    margin-left: 0;
  }
}

.page_content__right {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}

@media (max-width: 639px) {
  .page_content__right {
    position: static;
    display: block;
    float: none;
    order: 2;
    padding-top: 0;
  }
  .page_content__right .master {
    display: none;
  }
  .page_content__right .master_stats.is_fixed {
    position: static;
  }
  .page_content__right .master_stats.is_fixed .master {
    display: none;
  }
}

.page_title {
  margin-top: 20px;
}

.page_title .page_title__text {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  line-height: 25px;
  margin: 0;
  text-align: center;
}

@media (max-width: 599px) {
  .page_title {
    margin-top: 0;
  }
}

@media (min-width: 640px) {
  .page_title .page_title__text {
    text-align: left;
  }
}

@media (min-width: 600px) {
  .page_title .page_title__text {
    font-size: 36px;
    line-height: normal;
    letter-spacing: -0.5px;
  }
}

.lt_1col .page_content {
  flex-direction: column;
  justify-content: flex-start;
}

.lt_1col .page_content__left {
  width: 100%;
  padding: 0;
  margin: 0;
  float: none;
}

.lt_1col .page_title {
  margin: 20px 20px 30px;
}

@media (min-width: 600px) {
  .lt_1col .page_title {
    margin: 36px 42px 26px;
  }
}

@media (min-width: 900px) {
  .lt_1col .page_title {
    margin-left: 26px;
    margin-right: 26px;
  }
}

@media (min-width: 1280px) {
  .lt_1col .page_title {
    margin-left: 80px;
    margin-top: 60px;
  }
}

.totally-hidden {
  display: none !important;
}

.page_header {
  font-family: "Open-Sans", "Arial", sans-serif;
  background-color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
  position: relative;
}

@media (min-width: 1301px) {
  .page_header {
    padding-top: 21px;
  }
}

.page_header {
  font-family: "Open-Sans","Arial",sans-serif;
  background-color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 101;
  transition: .25s;
}

.header-flex {
  display: flex;
  align-items: center;
}

body {
  padding-top: 147px;
}

.page_header--auth {
  padding-top: 0;
}

@media (min-width: 640px) {
  .page_header--auth .page_header__inner {
    border: none;
  }
}

.page_header__inner {
  height: 100%;
  display: flex;
  flex-flow: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 20px;
  background-color: #ffffff;
}

@media (max-width: 1279px) {
  .page_header__inner {
    height: auto;
  }
}

@media (min-width: 640px) {
  .page_header__inner {
    padding-right: 24px;
    padding-left: 26px;
  }
}

@media (min-width: 1301px) {
  .page_header__inner {
    padding-left: 56px;
    padding-right: 40px;
    height: 100px;
  }
}

.page_header__left {
  display: flex;
  height: 100%;
  align-items: center;
  order: 0;
}

.page_header__right {
  display: flex;
  align-items: center;
  order: 0;
}

@media (min-width: 1301px) {
  .page_header__right {
    display: none;
  }
}

.header_logo {
  display: block;
  width: 165px;
  height: 36px;
  position: relative;
}

@media (min-width: 640px) {
  .header_logo {
    width: 206px;
    height: 47px;
  }
}

.header_logo__img {
  width: 100%;
}

.nav_toggle {
  width: 3em;
  height: 3em;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.nav_toggle:hover {
  background-color: #f5f6f7;
}

.is_open .nav_toggle {
  z-index: 210;
  position: fixed;
  top: 0;
}

@media (min-width: 640px) {
  .is_open .nav_toggle {
    right: 24px;
  }
}

@media (max-width: 639px) {
  .is_open .nav_toggle {
    right: 16px;
  }
}

@media (min-width: 1301px) {
  .nav_toggle {
    display: none;
  }
}

.nav_toggle__bar {
  display: block;
  width: 1.125em;
  height: .125em;
  margin-left: auto;
  margin-right: auto;
  background-color: #262626;
  transition: background 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.nav_toggle__bar + .nav_toggle__bar {
  margin-top: .25em;
}

.is_open .nav_toggle__bar:nth-child(1) {
  opacity: 0;
}

.is_open .nav_toggle__bar:nth-child(2) {
  margin-top: -.125em;
  transform: rotate(45deg);
}

.is_open .nav_toggle__bar:nth-child(3) {
  margin-top: -.125em;
  transform: rotate(-45deg);
}

.user_orders {
  width: 32px;
  height: 32px;
  background-color: #ffd24d;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #262626;
  line-height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  position: relative;
}

.is_open .user_orders {
  z-index: 110;
}

.user_orders.is-badge {
  position: absolute;
  height: 18px;
  width: 18px;
  font-size: 10px;
  line-height: 18px;
  top: -2px;
  right: -7px;
}

.user_orders--empty {
  background-color: #edeef0;
  color: #a5a8ad;
}

.nav_main {
  display: none;
  position: fixed;
  flex-shrink: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 201;
  text-align: center;
  padding: 94px 40px 155px;
  box-sizing: border-box;
  transition: transform 2s ease-in-out;
  min-width: 620px;
  order: 2;
}

@media (max-width: 1279px) {
  .nav_main {
    order: 1;
  }
}

.is_open .nav_main {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 36px;
}

@media (min-width: 1301px) {
  .is_open .nav_main {
    min-height: inherit;
  }
}

.nav_main .button {
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
}

@media (min-width: 640px) and (max-width: 1102px) {
  .nav_main {
    transform: translateX(100%);
    left: auto;
    padding-top: 95px;
  }
  .is_open .nav_main {
    transform: translateX(0);
  }
}

@media (min-width: 1301px) {
  .nav_main {
    transform: none;
    position: static;
    padding: 0;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .is_open .nav_main {
    display: flex;
  }
}

@media (max-width: 639px) {
  .nav_main {
    min-width: auto;
  }
}

@media (max-width: 1280px) {
  .nav_main {
    width: 100%;
  }
  .nav_main_buttons {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }
}

@media (min-width: 1301px) {
  .nav_main_list {
    display: flex;
    margin: 0;
  }
}

.nav_main_list__item {
  font-size: 14px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  line-height: 20px;
  margin-bottom: 20px;
  position: relative;
}

.nav_main_list__item:after {
  content: '';
  height: 2px;
  left: -10px;
  right: -10px;
  background-color: #ffd24d;
  position: absolute;
  top: 100%;
  display: none;
  margin-top: 39px;
}

@media (min-width: 1301px) {
  .nav_main_list__item {
    margin-left: 27px;
    margin-bottom: 0;
  }
  .nav_main_list__item.nav_main_list__item--active:after {
    display: block;
  }
}

@media (min-width: 1280px) {
  .nav_main_list__item {
    margin-left: 37px;
  }
}

.nav_main_list__link {
  color: #262626;
}

.nav_main_list__link:hover {
  color: #555659;
}

@media (max-width: 639px) {
  .nav_main_list__link {
    width: 100%;
  }
}

.nav_main_section {
  padding-bottom: 9px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.nav_main_section__desk {
  display: flex;
}

@media (max-width: 1300px) {
  .nav_main_section__desk {
    display: none;
  }
}

.nav_main_section__mob {
  display: none;
  max-width: 200px;
  margin: auto;
}

@media (max-width: 1300px) {
  .nav_main_section__mob {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 640px) and (max-width: 1140px) {
  .nav_main_section {
    padding-bottom: 19px;
  }
}

@media (min-width: 640px) {
  .nav_main_section {
    flex-direction: row;
  }
}

.nav_main_section__link {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  margin-bottom: 12px;
}

.nav_main_section__link:hover {
  color: #555659;
}

@media (min-width: 1301px) {
  .nav_main_section__link {
    margin-left: 18px;
    margin-bottom: 0;
  }
}

@media (min-width: 1301px) {
  .nav_main_footer {
    display: none;
  }
}

.nav_main_footer__copyright {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 11px;
  color: #a5a8ad;
}

@media (min-width: 640px) and (max-width: 1140px) {
  .nav_main_footer__copyright {
    margin-bottom: 30px;
  }
}

.nav_main_buttons {
  padding: 10px 0;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
}

.nav_main_buttons .button {
  width: 100%;
  margin-bottom: 16px;
  max-width: 320px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
}

@media (min-width: 640px) and (max-width: 1140px) {
  .nav_main_buttons {
    padding-top: 16px;
  }
}

@media (min-width: 1301px) {
  .nav_main_buttons {
    padding: 0;
    display: flex;
    align-items: center;
  }
  .nav_main_buttons .button {
    width: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  .nav_main_buttons .button__login {
    background-color: transparent;
    padding: 0;
    margin-left: 28px;
  }
  .nav_main_buttons .button__login:hover {
    color: #555659;
    background-color: transparent;
  }
  .nav_main_buttons .button__start {
    background-color: #ffd24d;
    margin-left: 30px;
  }
  .nav_main_buttons .button__start:hover {
    color: #555659;
    background-color: #ffd966;
  }
}

@media (min-width: 1280px) {
  .nav_main_buttons .button__login {
    margin-left: 38px;
  }
  .nav_main_buttons .button__start {
    margin-left: 40px;
  }
}

.nav_user {
  text-align: center;
  padding: 1px 0 20px;
}

@media (max-width: 639px) {
  .nav_user .user_orders {
    display: block;
  }
}

@media (min-width: 1301px) {
  .nav_user {
    padding-top: 12px;
  }
  .nav_user .user_orders {
    display: none;
  }
}

@media (min-width: 1301px) {
  .nav_user {
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: 28px;
  }
  .nav_user .user_orders {
    margin: 0;
  }
}

@media (min-width: 1280px) {
  .nav_user {
    margin-left: 40px;
  }
}

.nav_user__link {
  display: block;
  margin-bottom: 17px;
}

.nav_user__link:hover .nav_user__photo, .nav_user__link:hover .swiper_album .nav_user__photo-54, .swiper_album .nav_user__link:hover .nav_user__photo-54 {
  opacity: .7;
}

.nav_user__link.is--active .ic--arrow_down {
  transform: rotate(180deg);
}

@media (min-width: 1301px) {
  .nav_user__link {
    margin-bottom: 0;
  }
}

.nav_user__photo, .swiper_album .nav_user__photo-54 {
  width: 48px;
  height: 48px;
  overflow: hidden;
  margin: 0 auto 16px;
  border-radius: 50%;
  transition: opacity 0.2s ease-in-out;
  position: relative;
}

.nav_user__photo img, .swiper_album .nav_user__photo-54 img {
  max-width: 100%;
  border-radius: 50%;
}

@media (min-width: 1301px) {
  .nav_user__photo, .swiper_album .nav_user__photo-54 {
    margin: 0 20px;
  }
}

.nav_user__photo.is-drop, .swiper_album .is-drop.nav_user__photo-54 {
  overflow: inherit;
}

@media (min-width: 1301px) {
  .nav_user__photo.is-drop, .swiper_album .is-drop.nav_user__photo-54 {
    margin: 0 15px;
  }
}

@media (min-width: 640px) and (max-width: 1140px) {
  .nav_user__photo.is-drop, .swiper_album .is-drop.nav_user__photo-54 {
    margin: 0 20px 0 auto;
  }
  .nav_user__photo.is-drop .user_orders, .swiper_album .is-drop.nav_user__photo-54 .user_orders {
    margin: 0;
  }
}

@media (max-width: 639px) {
  .nav_user__photo.is-drop, .swiper_album .is-drop.nav_user__photo-54 {
    margin: 0;
    width: 34px;
    height: 34px;
  }
  .nav_user__photo.is-drop .user_orders, .swiper_album .is-drop.nav_user__photo-54 .user_orders {
    margin: 0;
  }
}

.nav_user__name {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #262626;
}

@media (min-width: 1301px) {
  .nav_user__name {
    display: none;
  }
}

.nav_user__name.is-drop {
  display: block;
  font-weight: 600;
  font-size: 12px;
  color: #a5a8ad;
  margin: 0;
}

@media (max-width: 639px) {
  .nav_user__name.is-drop {
    font-weight: 600;
    color: #262626;
    margin-top: 8px;
  }
}

.nav_user__logout {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #a5a8ad;
  padding: 0;
  background-color: transparent;
  border: none;
}

.nav_user__logout:hover {
  color: #555659;
}

.nav_footer {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  margin-top: auto;
}

@media (min-width: 640px) and (max-width: 1140px) {
  .nav_footer {
    bottom: 36px;
  }
}

@media (min-width: 1301px) {
  .nav_footer {
    bottom: auto;
    top: 10px;
    right: 30px;
    left: auto;
  }
}

@media (min-width: 1280px) {
  .nav_footer {
    right: 40px;
  }
}

.is_open .nav_footer {
  position: static;
  margin-top: auto;
}

.nav_user_drop {
  position: relative;
  margin-left: 25px;
  font-family: "Open-Sans", "Arial", sans-serif;
}

.nav_user_drop .nav_user__link {
  display: flex;
  align-items: center;
}

.nav_user_drop .nav_user__link * {
  pointer-events: none;
}

@media (min-width: 640px) and (max-width: 1140px) {
  .nav_user_drop {
    display: none;
  }
}

@media (max-width: 639px) {
  .nav_user_drop {
    display: flex;
    justify-content: center;
    margin: 20px 0 0;
  }
  .nav_user_drop .ic--arrow_down {
    display: none;
  }
  .nav_user_drop .nav_user__link {
    flex-direction: column-reverse;
    margin: 0;
  }
}

.nav_user_drop_menu {
  text-align: left;
  position: absolute;
  background: #ffffff;
  border: 1px solid #e3e5e8;
  box-sizing: border-box;
  border-radius: 3px;
  top: 56px;
  display: none;
  width: 160px;
  right: 0;
}

.nav_user_drop_menu__link {
  line-height: 30px;
  font-size: 14px;
  color: #000;
  display: block;
  padding: 7px 0 7px 17px;
}

.nav_user_drop_menu__link:hover {
  color: #000;
  background: #f5f6f7;
}

.nav_user_drop_menu__link.is-out {
  background-color: transparent;
  border: none;
  display: block;
  width: 100%;
  text-align: left;
}

.nav_user_drop_menu__link.is-out:hover {
  color: #000;
  background: #f5f6f7;
}

.nav_user_drop_menu__item {
  position: relative;
}

.nav_user_drop_menu__item.with-divider:after {
  content: '';
  height: 1px;
  background-color: #e3e5e8;
  position: absolute;
  top: 100%;
  left: 10px;
  right: 10px;
}

.nav_user_mobile {
  display: none;
}

@media (max-width: 639px) {
  .nav_user_mobile {
    display: block;
    margin-top: 4px;
  }
}

@media (min-width: 640px) and (max-width: 1140px) {
  .nav_user_mobile {
    display: block;
  }
}

.nav_user_mobile__link {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #a5a8ad;
}

.nav_user_mobile__link.is-out {
  background-color: transparent;
  border: none;
}

.nav_user_mobile__link.is-out:hover {
  color: #000;
  background: #f5f6f7;
}

.page_header__wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@media (max-width: 1300px) {
  .page_header__wrapper {
    width: 100%;
  }
}

.page_header__right .nav_user {
  padding: 0;
}

.page_header__right .nav_user .user_orders {
  display: block;
}

.nav_main_section__round-btn {
  height: 24px;
  width: 24px;
  border: none;
  border-radius: 50%;
  margin-left: 18px;
  background-color: #ffd24d;
  background-image: url(/media/images/burger.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.page_footer {
  font-family: "Open-Sans", "Arial", sans-serif;
  position: relative;
  border-top: 1px solid #edeef0;
  padding: 17px 16px 17px 20px;
  flex-shrink: 0;
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .page_footer {
    padding: 25px 42px 115px;
  }
  .page_footer .gr {
    position: absolute;
    bottom: -84px;
    right: 0;
  }
}

@media (min-width: 900px) {
  .page_footer {
    padding: 25px 26px 37px;
  }
  .page_footer .gr {
    position: absolute;
    bottom: 0;
    right: 92px;
  }
}

@media (min-width: 1000px) {
  .page_footer {
    height: 354px;
    padding: 54px 40px 36px 36px;
  }
  .page_footer .gr {
    right: 0;
  }
}

.page_footer--auth {
  height: 100px;
  border-top: none;
}

.page_footer__inner {
  position: relative;
  height: 100%;
}

.footer_logo {
  display: none;
}

@media (min-width: 640px) {
  .footer_logo {
    display: block;
    width: 206px;
    height: 47px;
  }
}

@media (min-width: 1000px) {
  .footer_logo {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.footer_logo__img {
  width: 100%;
}

.footer_nav {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0;
  justify-content: space-between;
}

@media (min-width: 600px) {
  .footer_nav {
    justify-content: flex-start;
  }
}

@media (min-width: 900px) {
  .footer_nav {
    padding-top: 31px;
  }
}

@media (min-width: 1000px) {
  .footer_nav {
    padding-top: 15px;
    margin-right: 0;
    justify-content: flex-end;
    width: 75.5%;
    margin-left: auto;
    padding-right: 70px;
  }
}

.footer_nav__section {
  width: calc(50% - 19px);
}

.footer_nav__section--last {
  width: 100%;
  margin-top: -15px;
}

.footer_nav__section--support {
  margin-left: calc(50% + 18px);
}

.footer_nav__section--telegram {
  width: 100%;
  margin-top: 15px;
}

.footer_nav__section--telegram .footer_nav__title {
  display: none;
}

@media (min-width: 600px) {
  .footer_nav__section {
    width: 31%;
  }
  .footer_nav__section--last {
    width: 35%;
    margin-left: auto;
    margin-top: 0;
  }
  .footer_nav__section--support {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .footer_nav__section--telegram {
    width: 100%;
    margin-top: 0;
  }
  .footer_nav__section--telegram .footer_nav__title {
    display: block;
  }
}

@media (min-width: 900px) {
  .footer_nav__section {
    width: calc(25% - 21px);
    margin-right: 20px;
    margin-bottom: 70px;
  }
  .footer_nav__section--last {
    width: 50%;
    margin-right: 0;
    display: flex;
  }
  .footer_nav__section--support {
    width: 50%;
  }
  .footer_nav__section--telegram {
    width: 50%;
  }
}

@media (min-width: 1000px) {
  .footer_nav__section {
    margin-bottom: 0;
  }
}

.footer_nav__title {
  font-size: 12px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 6px;
}

@media (min-width: 640px) {
  .footer_nav__title {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.footer_nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer_nav__item {
  font-size: 12px;
  line-height: 2.5;
  color: #a5a8ad;
}

@media (min-width: 640px) {
  .footer_nav__item {
    font-size: 14px;
    line-height: 2.14;
  }
}

.footer_nav__link {
  color: #a5a8ad;
}

.footer_nav__link:hover {
  color: #555659;
}

.page_footer__copyright {
  font-size: 11px;
  color: #a5a8ad;
  margin-bottom: 8px;
}

@media (min-width: 600px) {
  .page_footer__copyright {
    position: absolute;
    bottom: -84px;
    left: 0;
    margin-bottom: 0;
  }
}

@media (min-width: 900px) {
  .page_footer__copyright {
    position: absolute;
    bottom: 0;
    left: 62px;
    margin-bottom: 0;
  }
}

.gr__logo {
  font-size: 11px;
  color: #a5a8ad;
}

@media screen and (max-width: 415px) {
  .page_footer--auth {
    text-align: center;
  }
}

.tabs {
  padding: 0 10px;
  margin-bottom: 76px;
  overflow: hidden;
}

.tabs:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 640px) {
  .tabs {
    padding: 0 16px;
    overflow: inherit;
  }
}

@media (min-width: 1000px) {
  .tabs {
    padding: 0 80px;
  }
}

.tabs_links {
  display: flex;
  border-bottom: 1px solid #edeef0;
  padding-bottom: 15px;
  margin-bottom: 18px;
  white-space: nowrap;
  overflow-x: auto;
}

@media (min-width: 640px) {
  .tabs_links {
    margin-bottom: 20px;
  }
}

@media (min-width: 1000px) {
  .tabs_links {
    margin-bottom: 20px;
  }
}

.tabs_links__item {
  color: #a5a8ad;
  position: relative;
}

.tabs_links__item:after {
  content: '';
  display: none;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 13px;
  background-color: #ffd24d;
  height: 2px;
  position: absolute;
}

.tabs_links__item.is_active:after {
  display: block;
}

@media (min-width: 640px) {
  .tabs_links__item {
    padding-bottom: 10px;
  }
}

.tabs_links__link {
  color: #a5a8ad;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  align-items: center;
}

.is_active .tabs_links__link {
  color: #262626;
}

.is_active .tabs_links__link .quantity {
  background-color: #ffd24d;
}

.tabs_links__link .quantity {
  height: 20px;
  border-radius: 3px;
  background-color: #e3e5e8;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  line-height: 20px;
}

.tabs_links__link:hover {
  color: #262626;
}

@media (min-width: 1280px) {
  .tabs_links__link {
    padding: 0 20px;
  }
}

.tabs_content__item {
  display: none;
}

.tabs_content__item.is_active {
  display: block;
}

.tabs_content {
  margin-bottom: 20px;
}

.table_styled {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 38px;
}

.table_styled .is_link {
  cursor: pointer;
}

.table_styled tbody tr:hover .table_styled__value {
  background-color: #f5f6f7;
}

.table_styled tbody tr:hover .table_styled__value--icon .button--icon {
  display: block;
  float: right;
}

.table_styled tbody tr:hover .stars__mask {
  background-image: url(/media/images/stars-h.svg);
}

.table_styled [data-link] {
  cursor: pointer;
}

@media (max-width: 639px) {
  .table_styled {
    display: block;
    margin-bottom: 30px;
  }
  .table_styled thead {
    display: none;
  }
  .table_styled tbody {
    display: block;
  }
  .table_styled tbody tr:hover .table_styled__value--icon .button--icon {
    float: none;
  }
  .table_styled tr:not(.is_hidden) {
    display: flex;
    flex-flow: row wrap;
    position: relative;
  }
  .table_styled td {
    box-sizing: border-box;
  }
}

.table_styled__title {
  font-size: 12px;
  text-align: left;
  color: #a5a8ad;
  font-weight: normal;
  border-bottom: solid 1px #edeef0;
  padding: 10px 0 16px;
}

.table_styled__title:first-child {
  padding-left: 10px;
}

.table_styled__title:last-child {
  padding-right: 10px;
  text-align: right;
}

@media (min-width: 1280px) {
  .table_styled__title:first-child {
    padding-left: 20px;
  }
  .table_styled__title:last-child {
    padding-right: 20px;
  }
}

.table_styled__section .table_styled__value {
  text-align: left;
  font-weight: 600;
  padding-top: 18px;
}

@media (max-width: 639px) {
  .table_styled__section .table_styled__value {
    border-bottom: none;
  }
}

.table_styled__value {
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: #262626;
  border-bottom: solid 1px #edeef0;
  vertical-align: top;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: background-color 0.2s ease-in-out;
}

.table_styled__value:first-child {
  padding-left: 10px;
}

.table_styled__value:last-child {
  padding-right: 10px;
}

.is_cancelled .table_styled__value,
.is_closed .table_styled__value {
  color: #a5a8ad;
}

.table_styled__value .hint {
  font-size: 12px;
  color: #a5a8ad;
}

.table_styled__value .ic--key {
  margin-right: 8px;
  top: -1px;
}

@media (min-width: 640px) {
  .table_styled__value {
    line-height: 1.5;
  }
}

@media (min-width: 1280px) {
  .table_styled__value:first-child {
    padding-left: 20px;
  }
}

.table_styled__value--unknow {
  color: #a5a8ad;
}

@media (max-width: 639px) {
  .table_styled__value--prices {
    width: 100%;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;
  }
}

.table_styled__value--name {
  display: block;
  width: 100%;
  padding-right: 10px;
  line-height: 1.5;
}

.is_done .table_styled__value--name {
  color: #555659;
}

@media (max-width: 639px) {
  .table_styled__value--name {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 640px) {
  .table_styled__value--name {
    display: table-cell;
    width: auto;
  }
}

.table_styled__value--price {
  width: 100%;
  padding: 12px 10px 12px;
  line-height: 1.5;
}

@media (min-width: 640px) {
  .table_styled__value--price {
    width: 125px;
    padding-left: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .table_styled__value--price {
    width: 205px;
  }
}

.table_styled__value--stars {
  padding-left: 10px;
  width: 80%;
  padding-bottom: 12px;
}

@media (min-width: 640px) {
  .table_styled__value--stars {
    width: 142px;
    padding-left: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .table_styled__value--stars {
    width: 192px;
  }
}

.table_styled__value--rating {
  width: 25%;
  padding-bottom: 12px;
  text-align: right;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
}

@media (min-width: 640px) {
  .table_styled__value--rating {
    width: 40px;
    padding-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .table_styled__value--rating {
    width: 70px;
  }
}

.table_styled__value--empty {
  color: #a5a8ad;
}

.table_styled__value--time {
  width: 150px;
}

.table_styled__value--budget {
  width: 120px;
}

.table_styled__value--status {
  width: 190px;
}

.table_styled--open_orders .table_styled__value--status {
  width: 110px;
}

.table_styled__value--bids {
  width: 80px;
}

.table_styled__value--icon {
  width: 50px;
}

.table_styled__value--icon .button--icon {
  display: none;
}

.table_styled__value--icon .ic {
  fill: #262626;
}

.table_styled__value--close_spec {
  width: 20px;
}

@media (max-width: 639px) {
  .table_styled__value--close_spec {
    width: auto;
    position: absolute;
    border-bottom: none;
    right: 8px;
    top: 2px;
  }
}

.table_styled--bids .table_styled__col_title {
  display: none;
}

.table_styled--bids .table_styled__link {
  position: relative;
}

.table_styled--bids .table_styled__link.is_notvisited {
  font-weight: 600;
}

.table_styled--bids .table_styled__link.is_notvisited:before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background-color: #ffd24d;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: -10px;
}

@media (max-width: 999px) {
  .table_styled--bids {
    display: block;
  }
  .table_styled--bids thead {
    display: none;
  }
  .table_styled--bids tbody {
    display: block;
  }
  .table_styled--bids tbody tr:not(.is_hidden) {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #edeef0;
    padding-top: 10px;
    padding-bottom: 15px;
    position: relative;
  }
  .table_styled--bids .is_cancelled .button--icon,
  .table_styled--bids .is_closed .button--icon {
    margin-left: -5px;
  }
  .table_styled--bids .is_cancelled .button--icon:after,
  .table_styled--bids .is_closed .button--icon:after {
    opacity: 1;
    visibility: visible;
    color: #262626;
    background-color: transparent;
    margin-left: 0;
    top: 2px;
  }
  .table_styled--bids .table_styled__value {
    display: block;
    position: relative;
    padding-left: 90px;
    border-bottom: none;
    width: 100%;
    padding-bottom: 6px;
  }
  .table_styled--bids .table_styled__value--master {
    width: auto;
    padding-left: 20px;
  }
  .table_styled--bids .table_styled__value--name {
    padding-bottom: 0;
  }
  .table_styled--bids .table_styled__col_title {
    display: block;
    position: absolute;
    top: 12px;
    left: 8px;
    font-size: 12px;
    color: #a5a8ad;
  }
}

@media (min-width: 1280px) {
  .table_styled--bids .table_styled__link:before {
    top: 7px;
    width: 8px;
    height: 8px;
    left: -20px;
  }
}

.table_styled__link:hover {
  color: #262626;
}

.table_order tbody tr:hover {
  background-color: transparent;
}

.table_order .table_styled__title {
  border: none;
  padding: 0 3px;
}

.table_order .table_styled__title:first-child {
  padding: 0;
}

.table_order .table_styled__title:last-child {
  padding-right: 0;
  text-align: left;
}

.table_order .table_styled__value {
  border: none;
  padding: 0 3px;
}

.table_order .table_styled__value:first-child {
  padding-left: 0;
}

@media (min-width: 1280px) {
  .table_order .table_styled__title:first-child:first-child,
  .table_order .table_styled__value:first-child:first-child {
    padding-left: 0;
  }
  .table_order .table_styled__title:first-child:last-child,
  .table_order .table_styled__value:first-child:last-child {
    padding-right: 0;
  }
}

.table_styled--masters .table_styled__title.table_styled__value--rating_total {
  text-align: right;
  width: 100px;
}

.table_styled--masters .table_styled__title.table_styled__value--rating_spec {
  width: 150px;
  text-align: right;
}

.table_styled--masters .table_styled__value {
  vertical-align: middle;
}

.table_styled--masters .table_styled__value.table_styled__value--offer_btn {
  width: 270px;
}

.table_styled--masters .table_styled__value.table_styled__value--offer_btn .ic--fav-tag {
  display: none;
  fill: #e3e5e8;
  margin-top: 20px;
}

.table_styled--masters .table_styled__value.table_styled__value--fav_tag_icon {
  width: 100px;
  text-align: center;
}

.table_styled--masters .table_styled__value.table_styled__value--fav_tag_icon .fav_link .ic--fav-tag {
  fill: #e3e5e8;
}

.table_styled--masters .table_styled__value.table_styled__value--fav_tag_icon .fav_link.is_active .ic--fav-tag {
  fill: #ffd24d;
}

.table_styled--masters .table_styled__value.table_styled__value--fav_tag_icon .ic--fav-tag {
  fill: #e3e5e8;
}

@media (min-width: 1000px) {
  .table_styled--masters .table_styled__value.table_styled__value--master {
    width: 400px;
  }
}

.table_styled--masters .table_styled__value.table_styled__value--rating_spec {
  text-align: right;
  width: 145px;
}

.table_styled--masters .table_styled__value.table_styled__value--rating_total {
  text-align: right;
  width: 100px;
}

@media (max-width: 999px) {
  .table_styled--masters .table_styled__value.table_styled__value--offer_btn {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 0;
    position: static;
  }
  .table_styled--masters .table_styled__value.table_styled__value--offer_btn span {
    margin-top: 20px;
    margin-right: 20px;
  }
  .table_styled--masters .table_styled__value.table_styled__value--offer_btn .button {
    margin-top: 20px;
    margin-right: 20px;
  }
  .table_styled--masters .table_styled__value.table_styled__value--offer_btn .ic--fav-tag {
    display: block;
  }
  .table_styled--masters .table_styled__value.table_styled__value--fav_tag_icon {
    display: none;
  }
  .table_styled--masters .table_styled__value.table_styled__value--rating_spec, .table_styled--masters .table_styled__value.table_styled__value--rating_total {
    width: 100% !important;
    padding-left: 90px;
    text-align: left !important;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
  .table_styled--masters .table_styled__value .fav_link {
    position: absolute;
    left: 38px;
    top: 80px;
    z-index: 99999;
  }
  .table_styled--masters .table_styled__value .fav_link.is_active .ic--fav-tag {
    fill: #ffd24d;
  }
}

.tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
  font-size: 11px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  letter-spacing: 1.8px;
  color: #262626;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 640px) {
  .tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
    font-size: 12px;
    letter-spacing: 2px;
  }
}

.form_row {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 15px;
}

.form_row:last-child {
  margin-bottom: 0;
}

@media (min-width: 1280px) {
  .form_row {
    flex-direction: row;
    margin-bottom: 49px;
  }
}

.form_row.is_distance {
  position: relative;
}

@media (max-width: 639px) {
  .form_row.is_distance .form_item__input.is_short {
    width: 50%;
  }
}

.form_row.is_distance .form_item__input:first-child {
  margin-right: 20px;
}

@media (max-width: 639px) {
  .form_row.is_distance .form_item__input:first-child {
    margin-bottom: 20px;
  }
}

.form_row.is_phone .form_item__input:first-child {
  margin-right: 20px;
}

@media (max-width: 639px) {
  .form_row.is_phone .form_item__input:first-child {
    margin-bottom: 20px;
  }
}

.form_row.is_phone .form_item__input.is_short {
  margin-right: 20px;
}

.form_row.is_change_spec {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  max-width: 590px;
}

.form_row.is_change_spec:after {
  content: '';
  width: 7px;
  height: 11px;
  background: url(/media/images/arrow.svg) no-repeat;
  position: absolute;
  right: 15px;
  top: 17px;
  transform: rotate(90deg);
}

.form_row.is_active .label_point {
  display: block !important;
}

.form_row.is_phone_edit .form_cell__inner {
  margin-right: 20px;
}

.form_row.is_checkbox {
  position: relative;
  top: -18px;
  margin-bottom: 51px;
}

.form_row.is_subscriptions .check_item {
  margin-bottom: 27px;
}

.form_row.is_subscriptions .form_cell.is_label {
  padding-top: 0;
}

@media (max-width: 639px) {
  .form_row.is_subscriptions {
    margin-bottom: 35px;
  }
}

.form_row.is_master {
  margin: 15px 0;
}

.form_row.is_company {
  margin: 15px 0;
}

.form_row.is_order_btns {
  margin-top: 78px;
}

@media (max-width: 639px) {
  .form_row.is_order_btns {
    margin-top: 0;
  }
}

@media (max-width: 639px) {
  .form_row.status {
    height: 230px;
    position: relative;
  }
}

.form_row.has-error input,
.form_row.has-error textarea {
  border: 1px solid #fe253c;
}

.form_row.has-error .form_error {
  position: absolute;
  bottom: -36px;
  width: 100%;
  padding-top: 6px;
  display: block;
}

.has-error input,
.has-error textarea,
.has-error .form__select.selectize-control {
  border-color: #fe253c;
}

.has-error .form_error {
  position: absolute;
  top: 100%;
  width: 100%;
  display: block;
  line-height: 18px;
}

.form_cell {
  display: flex;
  position: relative;
  width: calc(100% - 25px);
}

@media (min-width: 640px) and (max-width: 999px) {
  .form_cell {
    width: auto;
  }
}

@media (max-width: 639px) {
  .form_cell {
    width: auto;
  }
}

.form_cell.is_label {
  width: 162px;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding-top: 10px;
}

.form_cell.is_label.is_centered {
  justify-content: center;
  padding-top: 0;
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .form_cell.is_label {
    width: 130px;
  }
}

@media (max-width: 1140px) {
  .form_cell.is_label {
    width: 120px;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .form_cell.is_label {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
  }
}

@media (max-width: 639px) {
  .form_cell.is_label {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
  }
}

.form_cell.is_field {
  display: flex;
}

.form_cell.is_field.is_card {
  flex-wrap: wrap;
  width: 510px;
}

.form_cell.is_field.is_card .form_cell__inner {
  margin-right: 38px;
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .form_cell.is_field.is_card {
    width: 410px;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .form_cell.is_field.is_card {
    flex-direction: column;
    width: auto;
  }
}

@media (max-width: 639px) {
  .form_cell.is_field.is_card {
    flex-direction: column;
    width: auto;
  }
}

.form_cell.is_field.is_clmn {
  flex-direction: column;
}

.form_cell.is_field.is_clmn .form__btns {
  text-align: left;
}

.form_cell.is_field.is_order_phone {
  flex-direction: column;
}

@media (max-width: 639px) {
  .form_cell.is_distance {
    width: 220px;
  }
}

@media (max-width: 639px) {
  .form_cell.is_about {
    flex-direction: row;
    align-items: center;
  }
}

.form_cell.is_phone_confirm .form_error {
  line-height: normal;
  padding-top: 8px;
}

@media (max-width: 639px) {
  .form_cell.is_phone_confirm {
    flex-direction: column;
  }
  .form_cell.is_phone_confirm .form_cell__inner {
    margin-bottom: 20px;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .form_cell.is_phone_confirm {
    flex-direction: column;
  }
  .form_cell.is_phone_confirm .form_cell__inner {
    margin-bottom: 20px;
  }
}

.order_create_wrapper .form_cell.is_label {
  width: 162px;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding-top: 10px;
}

.order_create_wrapper .form_cell.is_label.is_centered {
  justify-content: center;
  padding-top: 0;
}

@media (max-width: 639px) {
  .order_create_wrapper .form_cell.is_label.is_centered {
    justify-content: flex-start;
  }
}

.form_cell__inner {
  display: flex;
  flex-direction: column;
}

.form_cell__inner.is_long {
  width: 480px;
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .form_cell__inner.is_long {
    width: 410px;
  }
}

@media (max-width: 639px) {
  .form_cell__inner.is_long {
    width: 100%;
  }
}

.form_cell__inner.is_short {
  width: 200px;
}

@media (max-width: 639px) {
  .form_cell__inner.is_short {
    width: 100%;
  }
}

.form_cell__inner.is_error input, .form_cell__inner.is_error textarea {
  border: 1px solid #fe253c;
}

.form_cell__inner.is_success {
  position: relative;
}

.form_cell__inner.is_success:after {
  content: '';
  width: 11px;
  height: 9px;
  background: url(/media/images/is_confirmed.svg) no-repeat;
  position: absolute;
  right: 18px;
  top: 21px;
}

.form_cell__inner.is_change_spec {
  position: relative;
  width: 100%;
}

.form_cell__inner.is_change_spec:after {
  content: '';
  width: 7px;
  height: 11px;
  background: url(/media/images/arrow.svg) no-repeat;
  position: absolute;
  right: 15px;
  top: 17px;
  transform: rotate(90deg);
}

.form_cell__inner.is_change_spec .select_spec_items {
  position: static;
}

.form_cell__inner.is_change_spec .select_spec {
  position: absolute;
  padding-bottom: 25px;
  max-height: 320px;
  width: 100%;
  overflow-y: auto;
}

.form_cell__inner.is_change_spec .select_spec_categories__item span.is_active:after {
  background: none;
}

.form__label {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #000;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.form__label.is_photo {
  margin-top: 31%;
}

@media (min-width: 640px) and (max-width: 999px) {
  .form__label.is_photo {
    margin-top: 0;
  }
}

@media (max-width: 639px) {
  .form__label.is_photo {
    margin-top: 0;
  }
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .form__label {
    font-size: 13px;
  }
}

.form__text {
  border: 1px solid #edeef0;
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  color: #000;
  letter-spacing: 0;
  line-height: 30px;
  padding-left: 15px;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
  background: #fff;
  text-overflow: ellipsis;
}

.form__text::-ms-clear {
  display: none;
}

@media (max-width: 639px) {
  .form__text {
    width: auto;
  }
}

.form__text:focus {
  border: 1px solid #ffd24d;
  outline-style: none;
  box-shadow: none;
}

.form__text.is_textarea {
  max-width: 530px;
  min-width: 320px;
  height: 80px;
  min-height: 80px;
  max-height: 117px;
}

@media (max-width: 639px) {
  .form__text.is_textarea {
    width: 100%;
    height: 117px;
    max-width: none;
    min-width: 0;
    max-height: none;
    min-height: 0;
    padding-left: 15px;
    resize: none;
  }
}

.form__text.is_order_textarea {
  resize: none;
  height: 224px;
  line-height: 20px;
  padding: 12px 17px 15px;
}

.form__text.is_change_spec {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 25px;
}

@media (min-width: 640px) and (max-width: 999px) {
  .form__text.is_change_spec {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 25px;
  }
}

@media (max-width: 639px) {
  .form__text.is_change_spec {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 25px;
  }
}

.form__text.is_complex_number {
  appearance: textfield;
}

.form__text.is_complex_number::-webkit-inner-spin-button, .form__text.is_complex_number::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.form__text.is_dropdown_selector {
  padding: 0;
}

.form__text::-webkit-input-placeholder {
  color: #a5a8ad;
}

.form__text:-moz-placeholder, .form__text::-moz-placeholder {
  color: #a5a8ad;
  opacity: 1;
}

.form__text:-ms-input-placeholder {
  color: #a5a8ad;
}

.form_newsletter .is_dropdown_selector {
  border: none;
}

@media (max-width: 639px) {
  .form__btns {
    max-width: none;
    width: 100%;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .form__btns {
    max-width: none;
    width: 100%;
  }
}

.form__btns.is_spec {
  max-width: none;
  text-align: left;
}

.btn--submit {
  background: #ffd24d;
  border-radius: 21px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  border: 0;
  padding: 12px 41px 13px;
  width: 100%;
  margin: 0 0 20px;
  text-align: center;
}

.btn--submit.btn_is_big {
  padding: 18px 41px 19px;
  margin: 0;
  font-size: 18px;
  color: inherit;
  letter-spacing: -.2px;
  border-radius: 29px;
}

.btn--submit.btn_is_delete {
  background: #ff3d47;
  color: #fff;
  margin-left: 20px;
}

.btn--submit:hover {
  color: #555659;
  background-color: #ffd966;
}

@media (min-width: 600px) {
  .btn--submit {
    margin: 0 0 20px;
    width: 240px;
  }
}

.btn--submit:disabled,
.btn--submit[disabled] {
  background: #e3e5e8;
  color: #a5a8ad;
  cursor: not-allowed;
}

.btn--reset {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 20px;
  border: 1px solid #e3e5e8;
  border-radius: 21px;
  padding: 12px 41px 13px;
}

.btn--reset.is_load_photo {
  background: #ffffff;
  text-align: center;
}

@media (min-width: 640px) and (max-width: 999px) {
  .btn--reset {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 639px) {
  .btn--reset {
    margin: 0;
    width: 100%;
  }
}

.btn--reset:hover {
  border-color: #edeef0;
  color: #555659;
  background-color: #edeef0;
}

.btn--save {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 20px;
  border: 1px solid #e3e5e8;
  border-radius: 21px;
  padding: 11px 48px;
  background: #e3e5e8;
  margin-left: 20px;
  height: 42px;
}

@media (max-width: 639px) {
  .btn--save {
    margin: 0;
    color: #000;
  }
}

.btn--save:disabled,
.btn--save[disabled] {
  background: #e3e5e8;
  color: #ffffff;
  cursor: not-allowed;
}

.label_point {
  border-radius: 3px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  padding: 0 7px 0 8px;
  display: inline-flex;
  color: #262626;
  border: 2px solid #ffd24d;
  white-space: nowrap;
  height: 24px;
  align-items: center;
}

@media (min-width: 640px) and (max-width: 999px) {
  .label_point {
    position: static;
    margin-left: 8px;
  }
}

@media (max-width: 639px) {
  .label_point {
    position: static;
    margin-left: 8px;
  }
}

.form__message {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 30px;
}

@media (max-width: 639px) {
  .form__message {
    line-height: 20px;
    margin-top: 5px;
  }
}

.form__dimension {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #000;
  letter-spacing: 0;
  line-height: 30px;
  padding-left: 10px;
  padding-top: 7px;
  display: flex;
}

@media (max-width: 639px) {
  .form__dimension {
    display: block;
    line-height: 42px;
    padding: 0;
    margin: 0;
    position: relative;
    left: 8px;
  }
}

.form-section {
  margin-top: 39px;
  margin-bottom: 60px;
}

.form-section:first-child {
  margin-bottom: 0;
}

.form-section .section_title {
  margin-bottom: 30px;
  text-align: center;
  text-transform: none;
  letter-spacing: 0;
}

.form-section.profile_data {
  margin-bottom: 35px;
}

.form-section.profile_data .is_checkbox:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 639px) {
  .form-section.profile_data .is_checkbox {
    margin-bottom: 0;
  }
}

@media (max-width: 639px) {
  .form-section.profile_data {
    margin-bottom: 15px;
  }
}

.form-section:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 639px) {
  .form-section {
    margin-top: 0;
  }
  .form-section:first-child {
    margin-top: 0;
  }
  .form-section:first-of-type {
    margin-top: 0;
  }
  .form-section:last-of-type {
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) {
  .form-section .section_title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 35px;
  }
}

@media (min-width: 900px) {
  .form-section .section_title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 33px;
  }
}

@media (max-width: 599px) {
  .form-section .section_title {
    font-size: 16px;
    line-height: 22px;
  }
}

.form_btns {
  display: flex;
}

.form_btns.is_delete {
  margin-bottom: 60px;
}

@media (max-width: 639px) {
  .form_btns.is_delete {
    flex-direction: column;
  }
  .form_btns.is_delete .is_label {
    display: none;
  }
}

@media (max-width: 639px) {
  .form_btns.is_order_btns .form_cell.is_label {
    display: none;
  }
}

.form_btns .button {
  padding: 0 23px;
}

.input_upload_file {
  display: none;
}

.form_error {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #fe253c;
  letter-spacing: 0;
  line-height: 30px;
}

.form_error .form__text {
  border: 1px solid #fe253c;
}

.form_item__title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  line-height: 25px;
  margin-right: 17px;
}

.form_item__top {
  display: flex;
}

@media (max-width: 639px) {
  .form_item__top {
    margin-bottom: 9px;
  }
}

.form_item__input.is_long {
  width: 320px;
}

@media (max-width: 639px) {
  .form_item__input.is_long {
    width: 100%;
  }
}

.form_item__input.is_short {
  width: 200px;
}

@media (max-width: 639px) {
  .form_item__input.is_short {
    width: 100%;
  }
}

.form_item__input.is_textarea {
  margin-top: 14px;
  width: 520px;
}

@media (max-width: 639px) {
  .form_item__input.is_textarea {
    width: auto;
  }
}

.form_item__input.is_error input {
  border: 1px solid rgba(253, 45, 44, 0.61);
}

.form_item__input.is_error textarea {
  border: 1px solid rgba(253, 45, 44, 0.61);
}

.form_item__input.is_success {
  position: relative;
}

.form_item__input.is_success:after {
  content: '';
  width: 11px;
  height: 9px;
  background: url(/media/images/is_confirmed.svg) no-repeat;
  position: absolute;
  right: 18px;
  top: 21px;
}

@media (max-width: 639px) {
  .form_item__input input {
    width: 100%;
  }
}

.form_item__text {
  margin: 7px 0 14px;
  max-width: 580px;
  line-height: 22px;
}

.form_item {
  margin-bottom: 45px;
  position: relative;
}

.form_item.is_bottom_btn {
  display: flex;
}

.form_item.is_bottom_btn .btn--submit {
  height: 44px;
  margin-right: 20px;
}

@media (max-width: 639px) {
  .form_item.is_bottom_btn {
    flex-direction: column;
  }
  .form_item.is_bottom_btn .btn--submit {
    margin-right: 0;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .form_item.is_bottom_btn {
    flex-direction: column;
  }
}

.form_item.is_create_account {
  margin-top: 60px;
}

@media (max-width: 639px) {
  .form_item.is_create_account {
    margin: 0;
    position: relative;
  }
}

.form_item.is_visible {
  display: block;
}

.form_item.is_name .form_item__title {
  margin-bottom: 15px;
}

.form_msg {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
}

.form_msg.is_send_again {
  cursor: pointer;
}

.profile_form {
  padding-left: 19px;
}

@media (max-width: 639px) {
  .profile_form {
    padding-left: 10px;
    padding-right: 10px;
  }
  .profile_form .form_row {
    margin-bottom: 15px;
  }
  .profile_form .form_row:first-child {
    margin-bottom: 30px;
  }
  .profile_form .form_row.is_master,
  .profile_form .form_row.is_company {
    margin-top: 0;
  }
  .profile_form .form_row.is_checkbox {
    top: 0;
    margin-bottom: 18px;
  }
  .profile_form .form_cell.is_label {
    padding-top: 0;
  }
}

.form_message_light {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 11px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 15px;
  max-width: 590px;
  text-align: center;
  padding-top: 45px;
  margin-bottom: 40px;
}

.form_message_light p {
  text-align: center;
}

@media (min-width: 1000px) {
  .profile_create_form .form_item:first-of-type {
    margin-top: 45px;
  }
}

.form__select.selectize-control {
  border: 1px solid #edeef0;
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  color: #000;
  letter-spacing: 0;
  line-height: 30px;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
  background: #fff;
  text-overflow: ellipsis;
}

.form__select .selectize-dropdown-header {
  border-radius: 0;
}

.form__select .selectize-input {
  border: none;
  padding: 0 0 0 15px;
  border-radius: 4px;
  height: 100%;
  line-height: 44px;
  box-shadow: none;
  font-size: 14px;
}

.form__select .selectize-input.focus {
  outline-style: none;
  box-shadow: none;
}

.form__select .selectize-input.dropdown-active {
  border-radius: 0;
}

.form__select .selectize-input.dropdown-active::before {
  display: none;
}

.form__select .selectize-dropdown {
  border-radius: 0 0 3px 3px;
  box-shadow: none;
  border: 1px solid #edeef0;
  left: -1px !important;
  width: calc(100% + 2px) !important;
}

.form__select .selectize-dropdown [data-selectable] .highlight {
  font-weight: bold;
  background: #f5f6f7;
  border-radius: 0;
}

.form__select .selectize-dropdown [data-selectable],
.form__select .selectize-dropdown .optgroup-header {
  padding: 5px 15px;
}

.form__select .selectize-dropdown .active {
  color: #000;
  background: #f5f6f7;
}

.form__select .selectize-dropdown-content {
  overflow-y: initial;
  overflow-x: initial;
  max-height: 200px;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(38, 38, 38, 0.2);
  border-radius: 5px;
  /*-webkit-overflow-scrolling: touch;*/
}

.form__select.selectize-control.single .selectize-input.input-active,
.form__select.selectize-control.single .selectize-input.input-active input {
  cursor: default;
}

.form__select.selectize-control.single .selectize-input:after {
  width: 7px;
  height: 11px;
  background: url(/media/images/arrow.svg) no-repeat;
  right: 15px;
  top: 17px;
  transform: rotate(90deg);
  z-index: 0;
  margin-top: 0;
  border: none;
}

.form__select.selectize-control.single .selectize-input.dropdown-active:after {
  transform: rotate(-90deg);
}

.form__select .selectize-input .item:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 104%;
  height: 100%;
  z-index: 1;
}

.field-replyform-description {
  position: relative;
}

.field-replyform-description .form__message {
  position: absolute;
  bottom: -46px;
  display: block;
}

.field-replyform-description.has-error .form__message {
  display: none;
}

.field-replyform-description.has-success .form__message {
  display: block;
}

.form_button_next {
  appearance: none;
  border: none;
  background-color: #FFD24D;
  border-radius: 21px;
  width: 200px;
  height: 44px;
  padding: 0 20px;
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  color: #000000;
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.form_button_next:after {
  position: absolute;
  content: '';
  display: block;
  height: 28px;
  width: 28px;
  right: 7px;
  background-color: #ffffff;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.form_button_next:before {
  content: '';
  width: 10px;
  height: 15px;
  background: url(/media/images/arrow-right.png) no-repeat;
  position: absolute;
  right: 13px;
  top: 17px;
  z-index: 1;
}

.form_button_next:hover {
  color: #000000;
  background-color: #FFD24D;
  opacity: 0.7;
}

.form_button_prev {
  appearance: none;
  border: none;
  background-color: #FFD24D;
  border-radius: 21px;
  width: 200px;
  height: 44px;
  padding: 0 0 0 130px;
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  color: #000000;
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.form_button_prev:after {
  position: absolute;
  content: '';
  display: block;
  height: 28px;
  width: 28px;
  left: 7px;
  background-color: #ffffff;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.form_button_prev:before {
  content: '';
  width: 10px;
  height: 15px;
  background: url(/media/images/arrow-right.png) no-repeat;
  position: absolute;
  left: 13px;
  top: 14px;
  z-index: 1;
  transform: rotate(180deg);
}

.form_button_prev:hover {
  color: #000000;
  background-color: #FFD24D;
  opacity: 0.7;
}

.form_button_close {
  display: block;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: rgba(198, 199, 206, 0.3);
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  color: #000000;
}

.form_button_close svg {
  position: absolute;
  z-index: 2;
  height: 13px;
  width: 13px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form_button_close:after {
  position: absolute;
  content: '';
  display: block;
  height: 28px;
  width: 28px;
  right: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 50%;
}

.form_button_close:hover {
  color: #000000;
  background-color: rgba(198, 199, 206, 0.3);
  opacity: 0.7;
}

.form_button_unnecessary {
  background-color: rgba(120, 121, 132, 0.3);
}

.form_row.form_row_nowrap {
  align-items: flex-end;
}

.order_create_wrapper .form__label.no-opacity {
  opacity: 1;
}

feedback__body {
  margin: unset;
}

.feedback__image {
  margin-top: 50px;
}

.feedback__image .form_feedback_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 900px) {
  .feedback__image .form_feedback_wrapper {
    min-height: 440px;
  }
}

@media (max-width: 600px) {
  .feedback__image .form_feedback_wrapper {
    min-height: 385px;
  }
}

@media (max-width: 599px) {
  .feedback__image .form_feedback_wrapper {
    min-height: 350px;
    margin-bottom: 10px;
    padding: 0 6px;
  }
}

.feedback__image .form_feedback_wrapper .form_row--empty .feedback_controls__drag_container {
  transition: height 0.3s ease-in-out;
  height: 225px;
}

@media (max-width: 900px) {
  .feedback__image .form_feedback_wrapper .form_row--empty {
    max-width: 409px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
}

.feedback__image .form_feedback_wrapper .form_row--none-empty {
  height: auto;
}

.feedback__image .form_feedback_wrapper .form_row--none-empty .form_cell--label {
  height: 0 !important;
  padding-top: 0 !important;
}

.feedback__image .form_feedback_wrapper .form_row--none-empty .feedback_controls__drag_container {
  flex-direction: row;
  transition: height 0.3s ease-in-out;
  height: 94px;
}

.feedback__image .form_feedback_wrapper .form_row--none-empty .feedback_controls__drag_container .portfolio_controls__btn,
.feedback__image .form_feedback_wrapper .form_row--none-empty .feedback_controls__drag_container .feedback_file_input__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  margin-top: 0;
  margin-left: 9px;
}

.feedback__image .form_feedback_wrapper .form_row--none-empty .ic--upload {
  height: 34px;
  width: 55px;
}

@media (max-width: 900px) {
  .feedback__image .form_feedback_wrapper .form_row--none-empty {
    max-width: 409px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 599px) {
  .feedback__image .form_feedback_wrapper .form_row--none-empty {
    margin-top: 60px;
  }
}

.feedback__image .form_feedback_wrapper .form_cell--field {
  width: 100%;
  flex-flow: row wrap;
}

.feedback__image .form_feedback_wrapper .feedback_file_input {
  margin-top: 0;
  width: 100%;
}

.feedback_controls__drag_container {
  height: 225px;
  width: 100%;
  border: 3px dashed #C6C7CE;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
}

.feedback_controls__drag_container.is-dragover {
  background-color: #f5f6f7;
}

.feedback_controls__drag_container.is_error {
  border-color: rgba(253, 45, 44, 0.61);
}

.feedback_controls__drag_container:hover {
  border-color: #c6c7cE;
}

@media (min-width: 1280px) {
  .feedback_controls__drag_container {
    display: flex;
  }
}

.feedback_mobile_btn {
  display: flex;
  max-width: 409px;
  height: 127px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 210, 77, 0.12);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.feedback_mobile_btn span {
  display: block;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #262626;
  margin-top: 10px;
}

.feedback_mobile_btn .button__attach {
  display: flex;
  position: relative;
}

.feedback_mobile_btn .ic--clip {
  position: absolute;
  top: 10px;
  left: 13px;
}

@media (min-width: 1280px) {
  .feedback_mobile_btn {
    display: none;
  }
}

feedback__body {
  margin: unset;
}

.feedback__review .form_review_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 900px) {
  .feedback__review .form_review_wrapper {
    min-height: 440px;
  }
}

@media (max-width: 600px) {
  .feedback__review .form_review_wrapper {
    min-height: 385px;
  }
}

@media (max-width: 599px) {
  .feedback__review .form_review_wrapper {
    min-height: 350px;
    margin-bottom: 10px;
    padding: 0 6px;
  }
}

.feedback__review .form_review_wrapper .form_row--none-empty .form_cell--label {
  height: 0 !important;
  padding-top: 0 !important;
}

.feedback__review .form_review_wrapper .form_row--none-empty .review_controls__drag_container {
  flex-direction: row;
  transition: height 0.3s ease-in-out;
  height: 94px;
  text-align: center;
}

.feedback__review .form_review_wrapper .form_row--none-empty .review_controls__drag_container .review_controls__btn {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  margin-top: 0;
}

.feedback__review .form_review_wrapper .form_row--none-empty .ic--upload {
  height: 34px;
  width: 55px;
}

@media (max-width: 900px) {
  .feedback__review .form_review_wrapper .form_row--none-empty {
    max-width: 409px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 599px) {
  .feedback__review .form_review_wrapper .form_row--none-empty {
    margin-top: 60px;
  }
}

.feedback__review .form_review_wrapper .form_cell--field {
  width: 100%;
  flex-flow: row wrap;
}

.feedback__review .form_review_wrapper .feedback_file_input {
  margin-top: 0;
  width: 100%;
}

.feedback_controls__drag_container {
  height: 225px;
  width: 100%;
  border: 3px dashed #C6C7CE;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
}

.feedback_controls__drag_container.is-dragover {
  background-color: #f5f6f7;
}

.feedback_controls__drag_container.is_error {
  border-color: rgba(253, 45, 44, 0.61);
}

.feedback_controls__drag_container:hover {
  border-color: #c6c7cE;
}

@media (min-width: 1280px) {
  .feedback_controls__drag_container {
    display: flex;
  }
}

.feedback_mobile_btn {
  display: flex;
  max-width: 409px;
  height: 127px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 210, 77, 0.12);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.feedback_mobile_btn span {
  display: block;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #262626;
  margin-top: 10px;
}

.feedback_mobile_btn .button__attach {
  display: flex;
  position: relative;
}

.feedback_mobile_btn .ic--clip {
  position: absolute;
  top: 10px;
  left: 13px;
}

@media (min-width: 1280px) {
  .feedback_mobile_btn {
    display: none;
  }
}

.feedback__review .form_review_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 900px) {
  .feedback__review .form_review_wrapper {
    min-height: 440px;
  }
}

@media (max-width: 600px) {
  .feedback__review .form_review_wrapper {
    min-height: 385px;
  }
}

@media (max-width: 599px) {
  .feedback__review .form_review_wrapper {
    min-height: 350px;
    margin-bottom: 10px;
    padding: 0 6px;
  }
}

.feedback__review .form_review_wrapper .form_row--none-empty .form_cell--label {
  height: 0 !important;
  padding-top: 0 !important;
}

.feedback__review .form_review_wrapper .form_row--none-empty .review_controls__drag_container {
  flex-direction: row;
  transition: height 0.3s ease-in-out;
  height: 94px;
  text-align: center;
}

.feedback__review .form_review_wrapper .form_row--none-empty .review_controls__drag_container .review_controls__btn {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  margin-top: 0;
}

.feedback__review .form_review_wrapper .form_row--none-empty .ic--upload {
  height: 34px;
  width: 55px;
  margin-left: 10px;
}

@media (max-width: 900px) {
  .feedback__review .form_review_wrapper .form_row--none-empty {
    max-width: 409px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 599px) {
  .feedback__review .form_review_wrapper .form_row--none-empty {
    margin-top: 60px;
  }
}

.feedback__review .form_review_wrapper .form_cell--field {
  width: 100%;
  flex-flow: row wrap;
}

.feedback__review .form_review_wrapper .review_file_input {
  margin-top: 0;
  width: 100%;
}

.feedback__review .form_review_wrapper .has-error .form_error {
  position: unset;
}

.review_controls__drag_container {
  height: 225px;
  width: 100%;
  border: 3px dashed #C6C7CE;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
}

.review_controls__drag_container.is-dragover {
  background-color: #f5f6f7;
}

.review_controls__drag_container.is_error {
  border-color: rgba(253, 45, 44, 0.61);
}

.review_controls__drag_container:hover {
  border-color: #c6c7cE;
}

@media (min-width: 1280px) {
  .review_controls__drag_container {
    display: flex;
  }
}

.review_mobile_btn {
  display: flex;
  max-width: 409px;
  height: 127px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 210, 77, 0.12);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.review_mobile_btn span {
  display: block;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #262626;
  margin-top: 10px;
}

.review_mobile_btn .button__attach {
  display: flex;
  position: relative;
}

.review_mobile_btn .ic--clip {
  position: absolute;
  top: 10px;
  left: 13px;
}

@media (min-width: 1280px) {
  .review_mobile_btn {
    display: none;
  }
}

.hero-adv {
  margin-left: auto;
  margin-right: auto;
}

.hero-adv img {
  max-width: 100%;
  display: block;
}

.hero-adv p {
  line-height: 1;
}

.hero-adv p:last-child {
  margin-bottom: 0;
}

.hero-adv--horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 710px;
}

.main_section .hero-adv--horizontal {
  padding-bottom: 44px;
}

.section_order .hero-adv--horizontal {
  justify-content: flex-start;
  margin-left: inherit;
}

.hero-adv--horizontal img {
  max-width: 100%;
  display: block;
}

.hero-adv--vertical {
  max-width: 240px;
  display: none;
}

@media (min-width: 640px) {
  .hero-adv--vertical {
    margin-top: 58px;
    display: block;
  }
}

.advertising_wrap {
  width: 100%;
  display: none;
}

.table__cell .mobile_wrap {
  background: #ffffff;
  top: -5px;
  position: relative;
}

.mobile_wrap {
  width: 100%;
}

.desktop_wrap {
  width: 100%;
}

tr.table__row.table__row--adv.advertising_wrap.js_advertising {
  display: none;
}

.notify {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 300px;
  color: white;
  position: fixed;
  top: 30px;
  right: 30px;
  padding: 20px;
  background: rgba(242, 38, 19, 0.9);
  border: 1px solid rgba(217, 30, 24, 0.6);
  box-shadow: 0 0 5px rgba(217, 30, 24, 0.6);
  z-index: 5000;
}

.notify span {
  font-size: 13px;
}

.progress-bar-modal {
  width: 100%;
  max-width: 413px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
}

.progress-bar-modal:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #EDEEF0;
  height: 2px;
  width: 100%;
  z-index: -1;
}

.progress-bar-modal li {
  height: 30px;
  width: 30px;
  border: 2px solid #EDEEF0;
  border-radius: 50%;
  background-color: #ffffff;
  position: relative;
}

.progress-bar-modal .done {
  background-color: #ffd24d;
  border: 2px solid #ffd24d;
}

.progress-bar-modal .done:after {
  display: block;
  content: "";
  position: absolute;
  top: 9px;
  left: 9px;
  border-bottom: 2px solid #000000;
  border-left: 2px solid #000000;
  transform: rotate(-45deg);
  height: 4px;
  width: 8px;
  z-index: 1;
  background-color: transparent;
}

.progress-bar-modal .active {
  box-shadow: 0px 4px 16px rgba(38, 38, 38, 0.15);
  border: 2px solid #ffffff;
}

.progress-bar-modal .active:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px;
  width: 12px;
  z-index: 1;
  border-radius: 50%;
  background-color: #ffd24d;
}

@media (max-width: 599px) {
  .progress-bar-modal {
    max-width: 72%;
  }
  .progress-bar-modal li {
    height: 14px;
    width: 14px;
  }
  .progress-bar-modal li.done:after {
    height: 4px;
    width: 6px;
    left: 1px;
    top: 1px;
    border-width: 1px;
  }
  .progress-bar-modal li.active:after {
    height: 6px;
    width: 6px;
  }
}

.ok-form {
  position: relative;
  filter: blur(8px);
}

.ok-form::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background: rgba(255, 255, 255, 0.7);
}

.modal-wrapper, .album-confirmation--wrapper,
.image-confirmation--wrapper {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(38, 38, 38, 0.1);
  border-radius: 10px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 64px 110px 64px 60px;
  display: none;
  z-index: 299;
}

.modal-wrapper--order {
  max-width: 1280px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 33px 40px 40px;
}

.modal-wrapper--order .order_create_wrapper {
  padding: 0;
  margin: 0 auto;
  max-width: none;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .modal-wrapper--order {
    min-height: unset;
  }
}

.modal-wrapper--album .album_create_wrapper {
  padding: 0;
  margin: 50px auto;
  max-width: none;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .modal-wrapper--album {
    min-height: unset;
  }
}

.modal-wrapper--auth {
  max-width: 500px;
  padding: 0 50px 54px;
  border-radius: 10px;
}

.modal-wrapper--auth .modal-wrapper__close {
  right: 31px;
  top: 22px;
}

.modal-wrapper--auth .modal-wrapper__close .ic--cross-big {
  height: 16px;
  width: 16px;
}

.modal-wrapper--telegram {
  max-width: 550px;
  padding: 54px 75px 65px;
}

.modal-wrapper--telegram .modal-wrapper__close {
  top: 26px;
  right: 31px;
}

.modal-wrapper--telegram .modal-wrapper__close .ic--close {
  height: 17px;
  width: 17px;
  fill: #979797;
}

@media (max-width: 1280px) {
  .modal-wrapper--order {
    overflow-x: scroll;
    height: 100%;
    max-height: 100vh;
  }
}

@media (min-width: 900px) and (max-width: 1279px) {
  .modal-wrapper, .album-confirmation--wrapper,
  .image-confirmation--wrapper {
    left: 0;
    right: 0;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .modal-wrapper, .album-confirmation--wrapper,
  .image-confirmation--wrapper {
    padding: 30px 30px 20px;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transform: none;
    overflow-y: auto;
  }
  .modal-wrapper--order {
    overflow-y: unset;
  }
  .modal-wrapper--telegram {
    top: 50%;
    transform: translateY(-50%);
    padding: 54px 75px 65px;
    bottom: auto;
  }
}

@media (max-width: 599px) {
  .modal-wrapper, .album-confirmation--wrapper,
  .image-confirmation--wrapper {
    padding: 25px 20px;
  }
  .modal-wrapper--order {
    padding: 22px 14px 25px;
  }
  .modal-wrapper--telegram {
    max-width: calc(100% - 26px);
    padding: 28px 17px 50px;
  }
  .modal-wrapper--telegram .modal-wrapper__close {
    top: 11px;
    right: 14px;
  }
}

.modal-wrapper--check_passport_data {
  height: 90%;
}

.modal-wrapper--check_passport_data .modal-wrapper__content {
  position: relative;
  overflow-y: auto;
  height: 95%;
}

@media (max-width: 639px) {
  .modal-wrapper--check_passport_data .modal-wrapper__content {
    height: auto;
  }
}

.modal-wrapper--national_expert {
  max-width: 740px;
}

@media (max-width: 639px) {
  .modal-wrapper--national_expert {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

.modal-wrapper--add-spec {
  height: 100%;
  max-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 64px 60px;
  border-radius: 0;
}

@media (max-width: 1279px) {
  .modal-wrapper--add-spec {
    max-height: 100%;
  }
}

.modal-wrapper--add-spec .modal-wrapper__container {
  padding: 23px 21px 21px 24px;
  max-width: 590px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 3px;
}

@media (min-width: 600px) and (max-width: 899px) {
  .modal-wrapper--add-spec .modal-wrapper__container {
    max-width: 600px;
    padding: 23px 25px 21px 29px;
    max-height: 462px;
  }
}

@media (max-width: 599px) {
  .modal-wrapper--add-spec .modal-wrapper__container {
    padding: 17px 8px 15px 0;
  }
}

.modal-wrapper--add-spec .modal-wrapper__title {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 22px;
}

.modal-wrapper--add-spec .modal-wrapper__close {
  top: 16px;
  right: 16px;
}

.modal-wrapper--add-spec .modal-wrapper__close .ic--cross-big {
  width: 18px;
  height: 18px;
}

.modal-wrapper--add-spec .button + .button {
  margin-left: 16px;
}

@media (max-width: 599px) {
  .modal-wrapper--add-spec {
    padding: 18px 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    transform: none;
  }
  .modal-wrapper--add-spec .modal-wrapper__close {
    position: absolute;
    right: 5px;
  }
  .modal-wrapper--add-spec .modal-wrapper__close .ic--cross-big {
    height: 20px;
    width: 20px;
  }
  .modal-wrapper--add-spec .modal-wrapper__title {
    padding-left: 14px;
    margin-bottom: 10px;
  }
  .modal-wrapper--add-spec .add_spec_title_desktop {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .modal-wrapper--add-spec {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    transform: none;
  }
  .modal-wrapper--add-spec .modal-wrapper__close {
    position: absolute;
    right: 20px;
  }
}

@media (max-width: 600px) {
  .modal-wrapper--add-spec {
    max-height: none;
    padding: 0;
  }
}

.modal-wrapper__title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 20px;
  color: #262626;
  letter-spacing: 0;
  margin-bottom: 32px;
  line-height: 28px;
}

@media (max-width: 639px) {
  .modal-wrapper__title {
    padding-right: 25px;
  }
}

.modal-wrapper__close {
  position: absolute;
  top: 34px;
  right: 38px;
  cursor: pointer;
  z-index: 205;
}

.modal-wrapper__close .ic--close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal-wrapper__close .ic--cross-big {
  width: 28px;
  height: 28px;
}

@media (min-width: 900px) and (max-width: 1279px) {
  .modal-wrapper__close {
    top: 30px;
    right: 32px;
  }
  .modal-wrapper__close .ic--cross-big {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .modal-wrapper__close {
    top: 33px;
    right: 35px;
  }
  .modal-wrapper__close .ic--cross-big {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 599px) {
  .modal-wrapper__close {
    top: 21px;
    right: 11px;
  }
}

@media (max-width: 600px) {
  .modal-wrapper__close .ic--cross-big {
    height: 20px;
    width: 20px;
  }
}

.modal-wrapper__content {
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 15px;
}

.modal-wrapper__content .ic--liga-full {
  flex-shrink: 0;
  margin-right: 34px;
  top: 10px;
}

@media (max-width: 639px) {
  .modal-wrapper__content {
    flex-direction: column;
    align-items: center;
  }
  .modal-wrapper__content .ic--liga-full {
    margin-bottom: 30px;
    margin-right: 0;
    top: 0;
  }
}

.modal-wrapper__text p {
  line-height: 24px;
}

.modal-wrapper__text p:not(:last-child) {
  margin-bottom: 20px;
}

.add_spec_list {
  width: 100%;
  position: relative;
}

@media (max-width: 599px) {
  .add_spec_list .form_btns {
    justify-content: center;
  }
}

.add_spec_list__wrapper {
  display: flex;
  margin-bottom: 20px;
}

@media (min-width: 600px) and (max-width: 899px) {
  .add_spec_list__wrapper {
    margin-bottom: 12px;
  }
}

@media (max-width: 599px) {
  .add_spec_list__wrapper {
    margin-bottom: 30px;
  }
}

.add_spec_list__col {
  width: 50%;
  max-height: 328px;
  overflow-y: auto;
}

@media (max-width: 599px) {
  .add_spec_list__col {
    width: 100%;
  }
}

.add_spec_list__col--fav {
  background-color: rgba(237, 238, 240, 0.5);
  border-radius: 3px;
  width: 51.5%;
}

@media (max-width: 599px) {
  .add_spec_list__col--fav {
    display: none;
  }
}

.add_spec_list__col--full {
  position: relative;
  width: 48.5%;
}

.add_spec_list__col--full:after {
  content: '';
  width: 100%;
  height: 65px;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.add_spec_list__col--full .spec_list:last-child {
  padding-bottom: 40px;
}

.add_spec_list__col--full .spec_list__name {
  padding-top: 1px;
  padding-bottom: 1px;
}

.add_spec_list__col--full .add_spec_list__title {
  margin-bottom: 11px;
}

.add_spec_list__col--full .spec_list + .add_spec_list__section_name {
  margin-top: 13px;
}

@media (max-width: 599px) {
  .add_spec_list__col--full {
    width: 100%;
  }
}

.add_spec_form {
  max-height: 328px;
}

.add_spec_list__title {
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.3);
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 9px;
}

@media (max-width: 599px) {
  .add_spec_list__title {
    margin-top: 13px;
    padding-left: 14px;
  }
}

.add_spec_list__section_name {
  font-size: 12px;
  font-weight: bold;
  padding-left: 36px;
  margin-bottom: 5px;
}

@media (max-width: 599px) {
  .add_spec_list__section_name {
    padding-left: 26px;
  }
}

.spec_list + .add_spec_list__section_name {
  margin-top: 22px;
}

.spec_list__item {
  cursor: pointer;
}

.spec_list__item:not(:last-child) {
  margin-bottom: 2px;
}

.spec_list__label {
  display: block;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  font-size: 12px;
  cursor: pointer;
}

.add_spec_list__col--full .spec_list__label {
  font-size: 10px;
}

.spec_list__label .ic--check {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7px;
  display: none;
}

@media (min-width: 600px) {
  .spec_list__label:hover {
    background-color: #edeef0;
  }
}

.spec_list__input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.spec_list__input:checked + .ic--check {
  display: block;
  height: 6px;
  width: 6px;
}

.spec_list__input:checked ~ .spec_list__name {
  background-color: #ffd24d;
  font-weight: bold;
}

.spec_list__name {
  width: 100%;
  display: block;
  padding: 4px 0 4px 20px;
}

.add_spec_list__col--full .spec_list__name {
  padding-left: 36px;
}

@media (max-width: 599px) {
  .add_spec_list__col--full .spec_list__name {
    padding-left: 26px;
  }
}

.add_spec_list__btns {
  display: flex;
}

.add_spec_list__selected {
  position: absolute;
  right: 24px;
  bottom: 12px;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 599px) {
  .add_spec_list__selected {
    display: none;
  }
}

.form_step_wrapper {
  min-height: 466px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 900px) {
  .form_step_wrapper {
    min-height: 440px;
  }
}

@media (max-width: 600px) {
  .form_step_wrapper {
    min-height: 385px;
  }
}

@media (max-width: 599px) {
  .form_step_wrapper {
    min-height: 350px;
    margin-bottom: 10px;
    padding: 0 6px;
  }
}

@media (max-width: 1280px) {
  .form_step_wrapper--dnd .form_row.form_row--none-empty {
    height: auto;
  }
}

@media (max-width: 599px) {
  .form_step_wrapper--dnd {
    padding: 0;
  }
}

.modal-wrapper--services {
  height: 100vh;
  left: auto;
  width: 370px;
  padding: 0;
  overflow: auto;
}

.modal-wrapper__round-close {
  height: 24px;
  width: 24px;
  border: none;
  border-radius: 50%;
  margin-left: 0;
  background-color: #ffd24d;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 40px;
  top: 13px;
  position: absolute;
}

.modal-wrapper__round-close svg {
  height: 8px;
  width: 8px;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 3px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.main_notification {
  position: fixed;
  bottom: 10%;
  z-index: 100000;
  right: 20px;
  padding: 20px 25px 22px 25px;
  background: #ffffff;
  /*border: 1px solid #F2F2F2;*/
  box-sizing: border-box;
  box-shadow: 0px 10px 30px black;
  /*border-radius: 6px;*/
  min-width: 310px;
  border: 30px solid #ffd24d;
}

.main_popup {
  max-width: 255px;
}

.main_popup__ico {
  margin-right: 15px;
}

.main_popup__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_popup__title {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
}

.main_popup__content {
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.main_popup__text {
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  font-weight: bold;
}

.main_popup .ic--check-circle {
  color: #262626;
  width: 32px;
  height: 32px;
}

.main_popup .ic--alert-circle {
  color: #FF0000;
  width: 32px;
  height: 32px;
}

.main_popup .ic--close {
  fill: #C5C5C5;
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin: 0 5px;
  position: absolute;
  top: 8px;
  right: 10px;
}

.telegram-bot__header {
  font-weight: bold;
  font-size: 32px;
  line-height: normal;
  text-align: center;
  margin-bottom: 40px;
  color: #262626;
  letter-spacing: 1px;
}

.telegram-bot__element {
  background: #F7F7F7;
  border: 1px solid #E9E9E9;
  border-radius: 4px;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 25px;
}

.telegram-bot__element .ic--telegram {
  height: 24px;
  width: 24px;
  margin-right: 15px;
}

.telegram-bot__element span {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #262626;
}

.ic--telegram {
  color: transparent;
  background-image: linear-gradient(203.2deg, #37AEE2 21.67%, #1E96C8 70%);
  border-radius: 50%;
}

.telegram-bot__text {
  font-size: 14px;
  line-height: 24px;
  color: #929292;
  padding-left: 24px;
  position: relative;
  margin-bottom: 46px;
}

.telegram-bot__text:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 4px;
  height: 100%;
  background-color: #C6C7CE;
}

.telegram-bot__row {
  display: flex;
  justify-content: center;
}

.telegram-bot .button {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  height: auto;
  max-width: 306px;
  width: 100%;
}

@media (max-width: 599px) {
  .telegram-bot__header {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  .telegram-bot__element {
    padding: 0 14px;
    margin-bottom: 21px;
  }
  .telegram-bot__element span {
    font-size: 14px;
    line-height: 19px;
  }
  .telegram-bot__text {
    font-size: 12px;
    line-height: 20px;
    padding-left: 20px;
    margin-bottom: 24px;
  }
  .telegram-bot .button {
    font-size: 14px;
    line-height: 19px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
  font-size: 11px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  letter-spacing: 1.8px;
  color: #262626;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 640px) {
  .tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
    font-size: 12px;
    letter-spacing: 2px;
  }
}

@keyframes bounce {
  0% {
    left: 6px;
  }
  80% {
    left: calc(100% - 30px);
  }
  90% {
    left: calc(100% - 35px);
  }
  100% {
    left: calc(100% - 30px);
  }
}

@keyframes bounceOut {
  0% {
    left: calc(100% - 30px);
  }
  80% {
    left: 6px;
  }
  90% {
    left: 10px;
  }
  100% {
    left: 6px;
  }
}

.order_create_wrapper {
  padding: 0 20px;
  margin-bottom: 60px;
  max-width: 670px;
  /* Hack for IE */
}

.order_create_wrapper .form_row {
  margin-bottom: 10px;
  flex-flow: row wrap;
  align-items: flex-start;
  max-width: 590px;
}

@media (min-width: 900px) {
  .order_create_wrapper .form_row {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .order_create_wrapper .form_row {
    width: 100%;
    margin-bottom: 25px;
  }
}

@media (max-width: 599px) {
  .order_create_wrapper .form_row {
    width: 100%;
    margin-bottom: 24px;
  }
}

.order_create_wrapper .form_row--half-width {
  width: calc(50% - 10px);
}

.order_create_wrapper .form_row--half-width:first-child {
  margin-right: 10px;
}

.order_create_wrapper .form_row--half-width:nth-child(2) {
  margin-left: 10px;
}

@media (max-width: 599px) {
  .order_create_wrapper .form_row--half-width {
    width: 100%;
  }
  .order_create_wrapper .form_row--half-width:first-child {
    margin-right: 0;
  }
  .order_create_wrapper .form_row--half-width:nth-child(2) {
    margin-left: 0;
  }
}

.order_create_wrapper .form_row--with_hint .form_cell--label {
  padding-left: 24px;
}

.order_create_wrapper .form_row--with_hint .form_cell--label--budget {
  margin-left: 25px;
}

.order_create_wrapper .form_cell--field {
  width: 100%;
  flex-flow: row wrap;
}

.order_create_wrapper .form_cell--centered {
  align-items: center;
}

.order_create_wrapper .form_cell--visibility {
  justify-content: space-between;
  align-items: stretch;
}

.order_create_wrapper .form_row--account {
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.order_create_wrapper .form_row--account .form_cell--label {
  flex-shrink: 0;
  margin-right: 10px;
}

.order_create_wrapper .form_row--account .form_cell--field {
  width: auto;
}

.order_create_wrapper .form_row--account .form__label {
  margin-bottom: 0;
}

@media (min-width: 600px) {
  .order_create_wrapper .form_row--account .form_cell--label {
    margin-right: 0;
  }
}

.order_create_wrapper .form__label {
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  opacity: 0.5;
}

.order_create_wrapper .form__label span {
  display: block;
  font-size: 12px;
  font-family: "Open-Sans", "Arial", sans-serif;
  color: #a5a8ad;
  padding-top: 10px;
}

.order_create_wrapper .form__label .ic {
  fill: #ffd24d;
}

@media (max-width: 599px) {
  .order_create_wrapper .form__label {
    margin-bottom: 8px;
  }
}

.order_create_wrapper .form__text {
  border-color: #c6c7cE;
  width: 100%;
}

.order_create_wrapper .form__text:focus {
  border-color: #ffd24d;
}

.order_create_wrapper .form_cell__inner {
  width: 100%;
}

.order_create_wrapper .form_message_long:not(:last-child) {
  margin-bottom: 30px;
}

@media (max-width: 639px) {
  .order_create_wrapper .form_cell.is_spec {
    flex-direction: column;
  }
  .order_create_wrapper .form_cell.is_spec .form_cell__inner {
    flex-basis: unset;
  }
}

.order_create_wrapper .form-section {
  margin-top: 0;
  margin-bottom: 30px;
}

@media (min-width: 600px) {
  .order_create_wrapper .form-section {
    margin-bottom: 0;
  }
}

.order_create_wrapper textarea.form__text {
  resize: none;
  height: 164px;
  line-height: 20px;
  padding: 12px 17px 15px;
}

@media (max-width: 599px) {
  .order_create_wrapper textarea.form__text {
    height: 158px;
  }
}

.order_create_wrapper .form__btns {
  margin: 32px auto 0 auto;
}

.order_create_wrapper .form_row--account {
  margin-top: -10px;
  margin-bottom: 36px;
}

.order_create_wrapper .form_row--account .form_cell--label {
  padding-top: 0;
}

.order_create_wrapper .file_input_link__container {
  display: none;
}

.order_create_wrapper .phone_input__wrap {
  width: 100%;
  margin-right: 0;
}

@media (max-width: 599px) {
  .order_create_wrapper .form_message_light {
    text-align: center;
    padding-top: 26px;
  }
}

@media (max-width: 599px) {
  .order_create_wrapper .form_message_light {
    text-align: center;
    padding-top: 6px;
    margin-bottom: 26px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .order_create_wrapper .phone_input__wrap + .button--confirm {
    margin-top: 0;
    max-width: 132px;
    min-width: calc(50% - 10px);
  }
}

@media (min-width: 600px) {
  .order_create_wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }
  .order_create_wrapper .form__label {
    margin-bottom: 7px;
  }
  .order_create_wrapper textarea.form__text {
    height: 100px;
  }
  .order_create_wrapper .form_cell--visibility {
    margin-bottom: 20px;
  }
  .order_create_wrapper .form_row--account {
    margin-top: -19px;
    margin-bottom: 25px;
  }
  .order_create_wrapper .form_message_light {
    padding-top: 5px;
    margin-bottom: 32px;
  }
}

@media (min-width: 900px) {
  .order_create_wrapper {
    padding: 20px 0 0 26px;
    margin-bottom: 80px;
  }
  .order_create_wrapper .form_cell--label {
    width: 160px;
    padding-top: 16px;
  }
  .order_create_wrapper .form_row--with_hint .form_cell--label {
    padding-left: 0;
  }
  .order_create_wrapper .form_cell--field {
    width: 480px;
  }
  .order_create_wrapper .form__label {
    margin-bottom: 7px;
  }
  .order_create_wrapper .form_row--account {
    margin-top: 0;
    margin-bottom: 15px;
    padding-top: 0;
  }
  .order_create_wrapper .form_row--account .form_cell--label {
    margin-right: 0;
  }
  .order_create_wrapper .form_message_light {
    padding-top: 32px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1280px) {
  .order_create_wrapper {
    padding-left: 80px;
    max-width: 720px;
  }
  .order_create_wrapper .form__text:focus ~ .hint_block .hint_block__content {
    opacity: 1;
    visibility: visible;
  }
  .order_create_wrapper .file_input_link__container {
    display: none;
  }
  .order_create_wrapper .form_cell--visibility {
    margin-bottom: 24px;
  }
}

.order_create_wrapper input[type='number']::-webkit-inner-spin-button,
.order_create_wrapper input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.order_create_wrapper input[type='number'] {
  -moz-appearance: textfield;
  text-align: left;
}

.order_create_wrapper .number-spin-btn-container {
  display: none !important;
}

.order.lt_2col .page_title {
  padding-left: 28px;
}

@media (max-width: 639px) {
  .order.lt_2col .page_title {
    margin-left: 20px;
    margin-top: 21px;
    padding: 0 0 28px !important;
  }
}

@media (min-width: 1280px) {
  .order.lt_2col .page_title {
    padding-left: 60px;
  }
}

@media (max-width: 639px) {
  .order.lt_2col .page_title .page_title__text {
    text-align: left;
  }
}

.order .master_stats_section .button {
  margin-top: 0;
  margin-bottom: 18px;
}

.order .master_stats_section .button:last-of-type {
  margin-bottom: 0;
}

.form_cell__inner.is_duration {
  display: flex;
  flex-flow: row nowrap;
}

.form_cell__inner.is_duration .form_cell__inner_sect {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.form_cell__inner.is_duration .form_cell__inner_sect:last-of-type {
  margin-bottom: 0;
}

.form_cell__inner.is_duration .form_cell__inner_sect .form__text {
  width: 100%;
}

@media (min-width: 600px) {
  .form_cell__inner.is_duration .form_cell__inner_sect {
    width: 50%;
    margin-bottom: 0;
  }
  .form_cell__inner.is_duration .form_cell__inner_sect--budget {
    width: 100%;
    margin-left: 25px;
  }
}

@media (min-width: 900px) {
  .form_cell__inner.is_duration .form_cell__inner_sect:first-of-type .hint_block {
    left: 480px;
  }
}

@media (min-width: 1280px) {
  .form_cell__inner.is_duration .form_cell__inner_sect:first-of-type .hint_block {
    left: 460px;
  }
}

.form_message_long {
  background: #f5f6f7;
  border-radius: 4px;
  padding: 15px 0 22px 20px;
  margin-bottom: 44px;
  margin-top: 13px;
  opacity: 1;
}

@media (max-width: 599px) {
  .form_message_long {
    padding-right: 20px;
  }
}

@media (min-width: 600px) {
  .form_message_long {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .form_message_long {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .form_message_long {
    margin-top: 28px;
    margin-bottom: 7px;
  }
}

@media (max-width: 599px) {
  .form_message_long {
    padding: 6px 6px 12px 12px;
    margin-bottom: 0;
  }
}

.form_message_long_low {
  margin-bottom: 0;
}

.form_message_long__text {
  margin: 10px 0 0 30px;
  font-family: "Open-Sans", "Arial", sans-serif;
  color: #a5a8ad;
  letter-spacing: 0;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}

@media (max-width: 599px) {
  .form_message_long__text {
    font-size: 8px;
    line-height: 10px;
  }
}

.form_cell__inner_sect.is_date {
  min-width: 200px;
}

.form_cell__inner_sect.is_date:first-child .form__text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
}

.form_cell__inner_sect.is_date:last-child .form__text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  left: -1px;
}

.form_cell__inner_sect.is_date:before {
  content: '';
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 1;
  background: url(/media/images/ic_date.svg);
  right: 15px;
  top: 13px;
  pointer-events: none;
}

@media (max-width: 599px) {
  .form_cell__inner_sect.is_date {
    min-width: 50%;
  }
}

@media (min-width: 900px) {
  .form_cell__inner_sect.is_date {
    position: relative;
  }
}

.form_cell__inner_quantity {
  width: 55px;
  margin-right: 25px;
}

.form_cell__inner_select {
  width: 160px;
}

@media (max-width: 639px) {
  .form_cell__inner_select {
    width: calc(100% - 80px);
  }
}

.form_cell__inner_budget {
  width: 240px;
}

@media (max-width: 639px) {
  .form_cell__inner_budget {
    width: 100%;
  }
}

.order_wrapper {
  margin-left: 61px;
  padding-top: 33px;
  padding-bottom: 79px;
}

@media (max-width: 639px) {
  .order_wrapper {
    padding: 35px 0 20px;
    margin: 0;
  }
}

.order__description {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 25px;
}

.order__description p {
  padding-bottom: 25px;
}

.order__description ol li {
  list-style: decimal;
  padding-bottom: 25px;
  margin-left: 17px;
  padding-left: 28px;
}

.order__description ol li:last-child {
  padding-bottom: 0;
}

.order__description div:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.order_description__text {
  margin-bottom: 36px;
  word-break: break-word;
}

.order_info {
  margin-top: 10px;
}

.order_info__item {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
}

.account_confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 499px;
}

@media (min-width: 640px) and (max-width: 999px) {
  .account_confirm {
    min-height: 380px;
  }
}

@media (max-width: 639px) {
  .account_confirm {
    min-height: 380px;
  }
}

.account_confirm__inner {
  text-align: center;
  max-width: 410px;
}

.account_confirm__title {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 24px;
  color: #262626;
  letter-spacing: -.5px;
  font-weight: bold;
  margin-bottom: 25px;
}

@media (max-width: 639px) {
  .account_confirm__title {
    font-size: 18px;
  }
}

.account_confirm__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 52px;
}

@media (max-width: 639px) {
  .account_confirm__text {
    width: 257px;
    margin: 0 auto 53px;
  }
}

.section_order {
  margin-bottom: 56px;
}

.section_order .section_title {
  line-height: 1;
  padding: 0;
  margin: 0 0 36px;
}

@media (max-width: 639px) {
  .section_order .section_title {
    margin-bottom: 22px;
  }
}

.section_order.order__description {
  padding-top: 0;
}

.section_order.order__description ~ .advertising_wrap {
  padding-bottom: 40px;
}

.section_order:first-child {
  padding-top: 0;
}

@media (max-width: 639px) {
  .section_order.is_offers {
    padding: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 639px) {
  .section_order.is_offers .section_title {
    padding-left: 20px;
  }
}

.section_order:last-child {
  padding-bottom: 0;
}

@media (max-width: 639px) {
  .section_order {
    padding: 0 20px;
    margin-bottom: 33px;
  }
  .section_order:last-child {
    margin-bottom: 0;
  }
}

.client_info {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.client_info__img {
  width: 48px;
  height: 48px;
  background-color: #e3e5e8;
  border-radius: 50%;
}

.client_info__name {
  display: block;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  margin-left: 21px;
}

.client_info__phone {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  color: #262626;
  margin-bottom: 12px;
}

.client_info__phone.is_stub {
  color: #a5a8ad;
}

.client_info__phone.is_phone {
  color: #262626;
  font-size: 14px;
}

.goods_slider {
  border-top: 1px solid #edeef0;
  padding: 76px 0 79px 80px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 639px) {
  .goods_slider {
    padding: 37px 20px;
  }
}

.goods_slider .more-link {
  margin-top: 40px;
}

.goods_slider .slick-arrow {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.goods_slider .ic {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.goods_slider__controls {
  position: absolute;
  top: 76px;
  right: 80px;
}

.goods_slider__controls .ic--next {
  margin-right: 40px;
}

.goods_slider_wrapper {
  font-size: 0;
}

@media (max-width: 639px) {
  .goods_slider_wrapper {
    margin-bottom: 40px;
  }
}

.goods_slider_wrapper .slick-slide {
  margin: 0 10px;
}

.goods_slider_wrapper .slick-list {
  margin: 0 -10px;
}

.goods_slider_item {
  width: 280px;
  display: inline-block;
  vertical-align: top;
  margin-right: 40px;
}

@media (max-width: 639px) {
  .goods_slider_item {
    margin-right: 0;
  }
}

.goods_slider_item__img {
  text-align: center;
}

.goods_slider_item__img img {
  max-width: 160px;
  max-height: 182px;
}

@media (max-width: 639px) {
  .goods_slider_item__img {
    display: flex;
    justify-content: center;
  }
}

.goods_slider_item__category {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  margin-bottom: 11px;
}

.goods_slider_item__name {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 34px;
}

.goods_slider_item__price {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 20px;
  color: #262626;
  letter-spacing: 0;
}

.goods_slider_item__price_amount {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  text-align: left;
  color: #a5a8ad;
}

.goods_slider__btn {
  text-align: center;
  margin-top: 36px;
}

@media (max-width: 639px) {
  .goods_slider__btn {
    margin-top: 76px;
  }
}

.master_offer {
  border: 1px solid #edeef0;
  padding: 36px 34px;
  border-radius: 8px;
}

.master_offer .is_selected {
  display: none;
}

.master_offer .is_init {
  display: flex;
}

.master_offer .button--icon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.master_offer.is_in_progress {
  background: #f5f6f7;
}

.master_offer.is_in_progress .stars__mask {
  background: url(/media/images/stars-h.svg) 0 0 no-repeat;
}

.master_offer.is_in_progress .is_selected {
  display: block;
}

.master_offer.is_in_progress .is_init {
  display: none;
}

@media (max-width: 639px) {
  .master_offer.is_in_progress {
    padding: 25px 20px;
  }
}

.master_offer.is_deleted {
  padding: 26px 34px;
}

@media (max-width: 639px) {
  .master_offer {
    border-radius: 0;
    border: none;
    padding: 26px 20px 30px;
  }
}

.master_offer_info__status {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #a5a8ad;
  max-width: 122px;
  display: block;
  position: relative;
  right: 23px;
}

.master_offer_info__status .ic--ok_prime {
  position: absolute;
  top: 7px;
  left: -20px;
}

.master_offer_info__status .master_offer_info__in_progress {
  margin: 0;
  position: absolute;
  top: -2px;
  left: -35px;
}

@media (max-width: 639px) {
  .master_offer_info__status {
    width: 100%;
    max-width: none;
    right: auto;
    left: 34px;
    margin-top: 10px;
  }
}

.master_offer_info__col {
  display: flex;
  flex-direction: column;
  margin-right: 68px;
}

.master_offer_info__col.is_edit_btn {
  margin-left: auto;
}

.master_offer_info__col.is_edit_btn.is_msg .js_decline_text {
  display: block;
}

@media (max-width: 639px) {
  .master_offer_info__col.is_edit_btn {
    text-align: center;
    position: relative;
    height: 30px;
  }
  .master_offer_info__col.is_edit_btn .button {
    margin: 0;
    width: 100%;
  }
}

.master_offer_info__col:last-of-type {
  margin-right: 0;
}

@media (max-width: 639px) {
  .master_offer_info__col:last-of-type {
    margin-bottom: 30px;
  }
}

@media (max-width: 639px) {
  .master_offer_info__col {
    margin: 0;
    display: table-row;
  }
}

.master_offer_info__title {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
}

@media (max-width: 639px) {
  .master_offer_info__title {
    display: table-cell;
    padding-bottom: 13px;
    width: 50%;
  }
}

.master_offer_info__description {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 25px;
  margin-bottom: 28px;
}

.is_deleted .master_offer_info__description {
  color: #a5a8ad;
  margin: 0;
}

@media (max-width: 639px) {
  .master_offer_info__description {
    font-size: 14px;
    margin-top: 9px;
    margin-bottom: 10px;
  }
}

@media (max-width: 639px) {
  .master_offer_info__value {
    display: table-cell;
    width: 50%;
  }
}

.master_offer_person {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.master_offer_info {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 639px) {
  .master_offer_info {
    display: table;
    width: 100%;
  }
}

.master_offer_info.is_master_info .master_offer_info__col {
  margin-right: 8%;
}

.master_offer_info.is_master_info .master_offer_info__col:last-of-type {
  margin-right: 0;
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .master_offer_info.is_master_info .master_offer_info__col:first-of-type {
    width: 100%;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .master_offer_info.is_master_info .master_offer_info__col:first-of-type {
    width: 100%;
  }
}

@media (max-width: 639px) {
  .master_offer_info.is_offer_info {
    display: flex;
    flex-direction: column;
  }
}

.master_offer_info.is_offer_info .master_offer_info__col {
  margin-right: 8%;
}

@media (max-width: 639px) {
  .master_offer_info.is_offer_info .master_offer_info__col {
    margin-right: 0;
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 639px) {
  .master_offer_info.is_offer_info .master_offer_info__col .master_offer_info__title {
    margin-right: 0;
    display: flex;
  }
}

@media (max-width: 639px) {
  .master_offer_info.is_offer_info .master_offer_info__col .master_offer_info__value {
    display: flex;
    margin-left: auto;
    width: 42%;
  }
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .master_offer_info.is_offer_info .master_offer_info__col {
    margin-right: 5%;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .master_offer_info.is_offer_info .master_offer_info__col {
    margin-right: 3%;
  }
}

.master_offer_info.is_offer_info .master_offer_info__col:nth-child(3) {
  margin-right: 0;
}

.master_offer_info.is_offer_info .master_offer_info__col:last-of-type {
  margin-right: auto;
  margin-left: 0;
  margin-top: 20px;
}

@media (max-width: 639px) {
  .master_offer_info.is_offer_info .master_offer_info__col:last-of-type {
    margin: 0;
  }
}

@media (min-width: 1280px) {
  .master_offer_info.is_offer_info .master_offer_info__col:last-of-type {
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
  }
}

.master_offer_info__data {
  margin-bottom: 12px;
}

.master_offer_info__data_value {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 639px) {
  .master_offer_info__data_value {
    display: table-cell;
    font-weight: 600;
  }
}

.master_offer_info__data_title {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
}

@media (max-width: 639px) {
  .master_offer_info__data_title {
    display: table-cell;
    padding-left: 70px;
  }
}

.master_offer_info__photo {
  width: 48px;
  border-radius: 50%;
  margin-right: 21px;
}

.master_offer_info__img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.master_offer_info__grade {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
}

.master_offer_info__name {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 25px;
}

.master_offer_info__name .checked_master {
  display: inline-block;
  position: relative;
}

.master_offer_info__name .checked_master svg {
  height: 11px;
  width: 11px;
}

.master_offer_info__name .checked_master > svg.ic.ic--king {
  position: relative;
}

.master_offer_info__name .checked_master > svg.ic.ic--king:hover + .checked_master_tooltip {
  display: block;
}

.master_offer_info__name .checked_master_tooltip {
  display: none;
  background: #555659;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 11px;
  line-height: 15px;
  color: #FFFFFF;
  padding: 2.3px 5px;
  position: absolute;
  top: 3px;
  right: -145px;
  z-index: 100;
}

.master_offer_info__name .checked_master_tooltip:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #555659 transparent transparent;
  position: absolute;
  left: -14px;
  top: 0;
}

.master_offer_info__stars {
  display: flex;
}

.master_offer_info__in_progress {
  width: 24px;
  height: 24px;
  background-color: #ffd24d;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
}

@media (max-width: 639px) {
  .master_offer_info__in_progress {
    margin-left: 70px;
  }
}

.master_tabs .tabs {
  padding: 0;
}

@media (max-width: 639px) {
  .master_tabs {
    padding: 0 20px;
  }
}

.master_offers .master_offer__outer {
  margin-bottom: 44px;
  position: relative;
}

.master_offers .master_offer__outer:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 639px) {
  .master_offers .master_offer__outer {
    margin-bottom: 40px;
    border-top: 1px solid #edeef0;
    border-bottom: 1px solid #edeef0;
    padding: 0 20px;
  }
}

@media (max-width: 639px) {
  .master_offers {
    margin: 0 -20px;
  }
  .master_offers .request_form {
    margin: 0 20px;
  }
}

.favourite-masters__wrap .master_stub__message {
  width: 220px;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 36px;
}

.evaluate_popup {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(38, 38, 38, 0.1);
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  position: fixed;
  left: 12px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 202;
  padding: 16px 16px 25px;
  display: none;
  height: auto;
  max-height: 100vh;
  overflow: auto;
}

@media (min-width: 600px) {
  .evaluate_popup {
    padding: 24px 30px 40px;
  }
}

.evaluate_popup .stars__list {
  width: 90px;
  margin-right: 6px;
  align-self: flex-start;
}

@media (min-width: 600px) {
  .evaluate_popup .stars__list {
    width: 126px;
    height: 24px;
    margin-right: 18px;
  }
}

.evaluate_popup .stars__mask {
  background-image: url(/media/images/stars2.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 92px;
  height: 18px;
  top: -1px;
  left: -1px;
}

@media (min-width: 600px) {
  .evaluate_popup .stars__mask {
    width: 128px;
    height: 26px;
  }
}

.evaluate_popup .stars__fill {
  top: 1px;
  height: 15px;
}

@media (min-width: 600px) {
  .evaluate_popup .stars__fill {
    height: 23px;
  }
}

.evaluate_popup .stars__quantity {
  font-size: 14px;
}

@media (min-width: 600px) {
  .evaluate_popup .stars__quantity {
    font-size: 18px;
  }
}

.evaluate_popup__close {
  margin-top: 5px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.evaluate_popup__close .ic--close {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.evaluate_popup__close:hover {
  opacity: 0.7;
}

.evaluate_popup__title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 20px;
  line-height: 25px;
  color: #262626;
  letter-spacing: 0;
  width: 90%;
}

.evaluate_popup__list {
  padding-bottom: 5px;
}

@media (min-width: 600px) {
  .evaluate_popup__list {
    padding-bottom: 20px;
  }
}

.evaluate_popup__element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-bottom: 15px;
}

@media (min-width: 600px) {
  .evaluate_popup__element {
    padding-bottom: 16px;
  }
}

.evaluate_popup__element--top {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 17px;
  padding-bottom: 12px;
}

.evaluate_popup__element--top .evaluate_popup__description {
  letter-spacing: normal;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
}

.evaluate_popup__element--top .stars__quantity {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
}

@media (min-width: 600px) {
  .evaluate_popup__element--top {
    margin-bottom: 20px;
    padding-bottom: 18px;
  }
}

.evaluate_popup__description {
  letter-spacing: -0.02em;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  font-family: "Open-Sans", "Arial", sans-serif;
}

@media (min-width: 600px) {
  .evaluate_popup__description {
    letter-spacing: normal;
    font-size: 18px;
    line-height: 24px;
  }
}

.evaluate_popup__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

@media (min-width: 345px) {
  .evaluate_popup__top {
    margin-bottom: 36px;
  }
}

@media (min-width: 600px) {
  .evaluate_popup__top {
    margin-bottom: 41px;
  }
}

.evaluate_popup__stars {
  margin-bottom: 42px;
}

.evaluate_popup__stars .stars__list {
  width: 384px;
  height: 75px;
  margin: 0 auto;
}

@media (max-width: 639px) {
  .evaluate_popup__stars .stars__list {
    width: 315px;
    height: 63px;
  }
}

.evaluate_popup__stars .stars__mask {
  width: 392px;
  height: 79px;
  top: -2px;
  left: -3px;
  background-size: 100% 100%;
}

@media (max-width: 639px) {
  .evaluate_popup__stars .stars__mask {
    width: 318px;
    height: 67px;
  }
}

.evaluate_popup__stars .stars__fill {
  height: 75px;
}

@media (max-width: 639px) {
  .evaluate_popup__stars .stars__fill {
    height: 63px;
  }
}

.evaluate_popup__textarea {
  border: 1px solid #e3e5e8;
  border-radius: 4px;
  height: 120px;
  width: 100%;
  resize: none;
  padding: 5px 60px 0 15px;
  font-size: 14px;
  line-height: 30px;
  -webkit-appearance: none;
}

.evaluate_popup__textarea:focus {
  outline: none;
  border-color: #ffd24d;
}

.evaluate_popup__textarea ::-webkit-input-placeholder {
  /* Chrome */
  color: #a5a8ad;
}

.evaluate_popup__textarea :-ms-input-placeholder {
  /* IE 10+ */
  color: #a5a8ad;
}

.evaluate_popup__textarea ::-moz-placeholder {
  /* Firefox 19+ */
  color: #a5a8ad;
  opacity: 1;
}

.evaluate_popup__textarea :-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #a5a8ad;
  opacity: 1;
}

@media (min-width: 600px) {
  .evaluate_popup__textarea {
    padding-top: 8px;
    height: 140px;
  }
}

.comment_container {
  margin-bottom: 27px;
}

.evaluate_popup__text {
  margin-bottom: 20px;
}

.evaluate_popup__btns {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 639px) {
  .evaluate_popup__btns {
    flex-direction: column;
  }
  .evaluate_popup__btns .button {
    margin-bottom: 18px;
  }
  .evaluate_popup__btns .button:last-of-type {
    margin-bottom: 0;
  }
}

@media (max-width: 639px) {
  .order_top {
    padding: 0;
  }
  .order_top .page_title__text {
    max-width: 260px;
    text-align: left;
  }
}

.goods_slider_next {
  position: absolute;
  top: -36px;
  right: 14%;
}

.goods_slider_prev {
  position: absolute;
  top: -36px;
  right: 10%;
}

.mobile_title {
  padding-right: 44px;
  display: inline-block;
}

@media (min-width: 1280px) {
  .mobile_title {
    display: none;
  }
}

.order_form_title {
  padding-bottom: 38px;
}

@media (max-width: 639px) {
  .order_form_title {
    padding-bottom: 0;
  }
}

.order_form_msg {
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  font-family: "Open-Sans", "Arial", sans-serif;
  line-height: 20px;
  font-weight: normal;
  position: relative;
  margin-left: 20px;
}

.order_form_msg:before {
  content: '!';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffd24d;
  position: absolute;
  left: -26px;
  top: 2px;
  line-height: 16px;
  text-align: center;
}

.master_offer_info__btns_wrap.js_decline_text {
  display: none;
}

.master_offer_info__btns_wrap .button:nth-child(2) {
  margin-left: 30px;
  width: 100px;
}

@media (max-width: 639px) {
  .master_offer_info__btns_wrap {
    justify-content: space-between;
    position: absolute;
  }
  .master_offer_info__btns_wrap .button {
    width: auto;
  }
  .master_offer_info__btns_wrap .button--confirm.is_edit {
    height: 34px;
  }
}

.confirm_decline {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(38, 38, 38, 0.1);
  border-radius: 10px;
  max-width: 640px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 202;
  padding: 54px 81px 56px;
}

@media (max-width: 639px) {
  .confirm_decline {
    padding: 30px 20px 41px;
  }
}

.confirm_decline__avatar {
  margin-right: 50px;
}

.confirm_decline__avatar img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

@media (max-width: 639px) {
  .confirm_decline__avatar {
    margin: 0;
    width: 100%;
    margin-bottom: 18px;
  }
}

.confirm_decline__info {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  position: relative;
}

@media (max-width: 639px) {
  .confirm_decline__info {
    flex-wrap: wrap;
    margin-bottom: 18px;
  }
}

.confirm_decline__text {
  max-width: 273px;
  color: #262626;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
}

@media (max-width: 639px) {
  .confirm_decline__text {
    max-width: 190px;
  }
}

.confirm_decline__close_btn {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 20px;
}

.confirm_decline__close_btn .ic {
  width: 14px;
  height: 14px;
}

.confirm_decline__btns {
  display: flex;
  justify-content: space-between;
}

.confirm_decline__btns .button.decl_btn {
  width: auto;
  height: 34px;
}

.confirm_decline__btns .button.decl_btn.button__primary {
  padding: 7px 29px;
  line-height: 20px;
}

.confirm_decline__btns .button.decl_btn.button__secondary {
  padding: 7px 47px;
  line-height: 20px;
}

@media (max-width: 639px) {
  .confirm_decline__btns {
    flex-direction: column;
  }
  .confirm_decline__btns .button.decl_btn {
    margin-bottom: 20px;
  }
  .confirm_decline__btns .button.decl_btn:last-child {
    margin-bottom: 0;
  }
}

.confirm_decline__comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.confirm_decline__comment .confirm_decline__comment_inner.is_error .form__text {
  border: 1px solid #fe253c;
}

.confirm_decline__comment textarea {
  width: 100%;
  height: 110px;
  resize: none;
  margin: 15px 0 0;
}

.confirm_decline_comment__label {
  color: #262626;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.master_offers__selected {
  margin-bottom: 40px;
}

.button--border.decline_has_send {
  height: 34px;
  line-height: 30px;
  display: block;
  padding: 0 19px;
}

.button--border.decline_has_send:hover .master_stats_section__title {
  background-color: #ffffff;
  border: solid 1px #e3e5e8;
  color: #a5a8ad;
}

.auth_buttons {
  display: flex;
  padding-top: 24px;
  flex-flow: row wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.auth_buttons .button {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}

@media (min-width: 640px) {
  .auth_buttons .button {
    margin-bottom: 0;
  }
}

@media (min-width: 640px) {
  .auth_buttons {
    justify-content: flex-start;
    flex-direction: row;
  }
  .auth_buttons .button + .button {
    margin-left: 48px;
  }
}

.request_form .form_row {
  margin-bottom: 26px;
}

@media (max-width: 639px) {
  .request_form {
    max-width: 360px;
  }
  .request_form .form_row {
    margin-bottom: 20px;
  }
  .request_form .form_cell__inner.is_duration {
    flex-direction: row;
  }
  .request_form .form__text {
    width: 100%;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .request_form .form_row {
    flex-flow: row wrap;
    margin-bottom: 20px;
  }
  .request_form .form_cell__inner.is_long {
    max-width: 400px;
    width: 100%;
    min-width: 300px;
  }
  .request_form .form_cell.is_label {
    width: 120px;
    align-items: flex-start;
  }
}

.master_offer__date {
  font-size: 12px;
  color: #a5a8ad;
  position: absolute;
  top: 100%;
  right: 20px;
  margin-top: 5px;
}

@media (min-width: 640px) {
  .master_offer__date {
    right: 0;
    margin-top: 8px;
  }
}

@media (max-width: 639px) {
  .master_offer__date {
    right: 40px;
  }
}

.order_type_switcher {
  position: relative;
  width: 100%;
  cursor: pointer;
  height: 270px;
  overflow: hidden;
  display: flex;
  flex-flow: row;
}

.order_type_switcher .order_type__content {
  display: flex;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffd24d;
  flex-shrink: 0;
  flex-basis: 100%;
  transition: transform 0.2s ease-in-out;
}

@media (min-width: 600px) {
  .order_type_switcher {
    display: none;
  }
}

.order_type_switcher__input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.order_type_switcher__input + .order_type__content {
  transform: translateX(0);
}

.order_type_switcher__input:checked + .order_type__content {
  transform: translateX(-100%);
}

.order_type_switcher__input:checked + .order_type__content + .order_type__content {
  transform: translateX(-100%);
}

.order_type_switcher__input:checked ~ .order_type_switcher__label:before {
  animation: bounce 0.5s linear;
  left: calc(100% - 30px);
}

.order_type_switcher__label {
  width: 82px;
  height: 36px;
  display: block;
  border-radius: 36px;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
}

.order_type_switcher__label:before {
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  background: #ffd24d;
  animation: bounceOut 0.5s linear;
  left: 6px;
}

.order_type {
  position: relative;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 900px) {
  .order_type {
    width: calc(50% - 10px);
  }
}

@media (max-width: 599px) {
  .order_type:first-of-type {
    margin-bottom: 10px;
  }
}

@media (max-width: 599px) {
  .order_type {
    width: 100%;
  }
}

@media (min-width: 900px) {
  .order_type {
    width: 285px;
    height: 80px;
  }
}

.order_type__input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.order_type__input:checked + .order_type__content {
  background-color: #ffd24d;
}

.order_type__content {
  border-radius: 3px;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  height: 100%;
  padding: 13px 18px;
  background-color: #eceeef;
}

@media (max-width: 600px) {
  .order_type__content {
    padding: 13px 7px;
    min-height: 80px;
    min-width: 260px;
  }
}

@media (max-width: 599px) {
  .order_type__content {
    padding: 10px 14px;
    min-height: 60px;
    min-width: 260px;
  }
}

.page_content__left .order_type__content {
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {
  .page_content__left .order_type__content {
    padding-bottom: 70px;
  }
}

.page_content__left .order_type {
  height: auto;
  /* @media (max-width: 600px) {
        display: none;
    }*/
}

.page_content__left .order_create_wrapper .order_signup .button--confirm {
  margin-left: 0;
}

.page_content__left .phone_wrap {
  justify-content: space-between;
}

@media (max-width: 600px) {
  .page_content__left .order_type_switcher {
    height: auto;
  }
}

.order_type__icon {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-bottom: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 14px;
}

.order_type__icon svg {
  height: 31px;
  width: 31px;
}

@media (min-width: 600px) {
  .order_type__icon {
    margin-bottom: 0;
  }
}

@media (max-width: 600px) {
  .order_type__icon {
    margin-right: 7px;
  }
}

@media (max-width: 599px) {
  .order_type__icon {
    width: 42px;
    height: 42px;
    margin-right: 15px;
  }
}

.order_type__name {
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  line-height: normal;
  margin-bottom: 5px;
  display: block;
}

@media (max-width: 599px) {
  .order_type__name {
    font-size: 10px;
    line-height: 14px;
  }
}

.order_type__text {
  font-size: 10px;
  line-height: 14px;
  display: block;
}

@media (max-width: 599px) {
  .order_type__text {
    font-size: 8px;
    line-height: 10px;
  }
}

.hint_block {
  position: absolute;
  left: 0;
  top: -34px;
}

@media (min-width: 600px) {
  .hint_block {
    top: -30px;
  }
}

@media (min-width: 900px) {
  .hint_block {
    left: 100%;
    margin-left: 22px;
    top: 12px;
  }
}

@media (min-width: 1280px) {
  .hint_block {
    top: 0;
    width: 480px;
    margin-left: 40px;
  }
}

.hint_block__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 202;
  background-color: rgba(38, 38, 38, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 1280px) {
  .hint_block__overlay {
    display: none;
  }
}

.hint_block__content {
  background: #ffffff;
  border-radius: 14px 14px 0 0;
  padding: 34px 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 203;
}

.hint_block__content p {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 12px;
}

@media (min-width: 1280px) {
  .hint_block__content {
    position: relative;
    background: #eceeef;
    border-radius: 4px;
    padding: 16px 35px;
  }
  .hint_block__content:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 9px 0;
    border-color: transparent #eceeef transparent transparent;
    position: absolute;
    left: -9px;
    top: 15px;
  }
}

.hint_block__icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #d2d2d4;
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  text-align: center;
  color: #ffffff;
  line-height: 15px;
  border-radius: 50%;
  padding: 0;
  border: none;
}

.hint_block__icon.hint_active ~ .hint_block__overlay,
.hint_block__icon.hint_active ~ .hint_block__content {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1280px) {
  .hint_block__icon {
    display: none;
  }
}

.hint_block__close {
  position: absolute;
  bottom: 100%;
  width: 26px;
  height: 26px;
  margin-bottom: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  right: 20px;
}

.hint_block__close .ic {
  fill: #262626;
}

@media (min-width: 600px) {
  .hint_block__close {
    right: 38px;
  }
}

@media (min-width: 1280px) {
  .hint_block__close {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .order_signup {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .order_signup .form_row:nth-child(1) {
    order: 1;
  }
  .order_signup .form_row:nth-child(2) {
    order: 2;
  }
  .order_signup .form_row:nth-child(3) {
    order: 4;
  }
  .order_signup .form_row:nth-child(4) {
    order: 3;
  }
}

@media (min-width: 900px) {
  .order_signup .form_cell--field {
    width: 240px;
  }
  .order_signup .button--confirm {
    position: absolute;
    left: 260px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 132px;
    padding: 0;
  }
}

.form_step {
  display: none;
}

.form_step.js-active-step {
  display: block;
}

.not-valid {
  box-shadow: 0px 0px 1px 1px #ed552b !important;
}

.upload-thumb__img {
  height: 107px;
  width: 107px;
  object-fit: cover;
  border-radius: 3px;
}

@media (max-width: 900px) {
  .upload-thumb__img {
    height: 125px;
    width: 125px;
  }
}

@media (max-width: 599px) {
  .upload-thumb__img {
    height: 136px;
    width: 136px;
  }
}

.upload-thumb__img-text {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 48px;
  line-height: normal;
  text-transform: uppercase;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  color: #ffd24d;
}

.upload-thumb__del {
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.4);
}

.upload-thumb__del svg {
  width: 7px;
  height: 7px;
}

@media (max-width: 1280px) {
  .upload-thumb__del {
    height: 17px;
    width: 17px;
    visibility: visible;
    opacity: 1;
  }
}

.upload-thumb__move {
  position: absolute;
  left: 3px;
  top: 3px;
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #000000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  pointer-events: none;
}

.upload-thumb__stripe {
  display: block;
  background-color: #ffffff;
  width: 7px;
  height: 1px;
}

.upload-thumb__stripe:not(:first-child) {
  margin-top: 1px;
}

.upload-thumb__cover {
  background-color: #EDEEF0;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  width: auto;
  padding: 0 5px;
  height: 20px;
  border-radius: 30px;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  cursor: pointer;
}

.upload-thumb__cover.is-cover {
  background-color: #ffd24d;
  padding-left: 16px;
}

.upload-thumb__cover.is-cover:after {
  position: absolute;
  content: '';
  display: block;
  height: 6px;
  width: 4px;
  left: 5px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
}

.upload-thumb__error {
  background-color: rgba(38, 38, 38, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.upload-thumb__error span {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.loader {
  height: 57px;
  width: 57px;
  animation: loader-2-1 3s linear infinite;
}

@-webkit-keyframes loader-2-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-2-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  height: 57px;
  width: 57px;
  border: 3px solid transparent;
  border-top: 3px solid #FFD24D;
  border-right: 3px solid #FFD24D;
  border-radius: 50%;
  z-index: 2;
}

.loader::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  height: 57px;
  width: 57px;
  border: 3px solid #C6C7CE;
  border-radius: 50%;
  z-index: 1;
}

.script-steps, .progress-bar--album {
  width: 100%;
  max-width: 413px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
}

.script-steps:after, .progress-bar--album:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #EDEEF0;
  height: 2px;
  width: 100%;
  z-index: -1;
}

.script-steps li, .progress-bar--album li {
  height: 30px;
  width: 30px;
  border: 2px solid #EDEEF0;
  border-radius: 50%;
  background-color: #ffffff;
  position: relative;
}

.script-steps .done, .progress-bar--album .done {
  background-color: #ffd24d;
  border: 2px solid #ffd24d;
}

.script-steps .done:after, .progress-bar--album .done:after {
  display: block;
  content: "";
  position: absolute;
  top: 9px;
  left: 9px;
  border-bottom: 2px solid #000000;
  border-left: 2px solid #000000;
  transform: rotate(-45deg);
  height: 4px;
  width: 8px;
  z-index: 1;
  background-color: transparent;
}

.script-steps .active, .progress-bar--album .active {
  box-shadow: 0px 4px 16px rgba(38, 38, 38, 0.15);
  border: 2px solid #ffffff;
}

.script-steps .active:after, .progress-bar--album .active:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px;
  width: 12px;
  z-index: 1;
  border-radius: 50%;
  background-color: #ffd24d;
}

@media (max-width: 599px) {
  .script-steps, .progress-bar--album {
    max-width: 72%;
  }
  .script-steps li, .progress-bar--album li {
    height: 14px;
    width: 14px;
  }
  .script-steps li.done:after, .progress-bar--album li.done:after {
    height: 4px;
    width: 6px;
    left: 1px;
    top: 1px;
    border-width: 1px;
  }
  .script-steps li.active:after, .progress-bar--album li.active:after {
    height: 6px;
    width: 6px;
  }
}

.ok-form {
  position: relative;
  filter: blur(8px);
}

.ok-form::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background: rgba(255, 255, 255, 0.7);
}

.order_create_wrapper .form_cell--field {
  width: 100%;
  max-width: 590px;
}

.order_create_wrapper .form_cell--short {
  width: 66%;
}

.form_row_nowrap {
  flex-wrap: nowrap !important;
  max-width: 590px;
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .form_row_nowrap {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 600px) {
  .location_selector + .form_row_nowrap {
    flex-wrap: nowrap !important;
  }
}

@media (max-width: 599px) {
  .location_selector + .form_row_nowrap {
    flex-wrap: wrap !important;
  }
}

.form_row.form_row--btns {
  justify-content: center;
  margin-bottom: 0;
}

.order_upper-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #262626;
  opacity: 0.5;
  margin-top: 13px;
  margin-bottom: 22px;
}

@media (max-width: 599px) {
  .order_upper-text {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 25px;
  }
}

.order_create_wrapper .form_row--empty .portfolio_controls__drag_container {
  transition: height 0.3s ease-in-out;
  height: 225px;
}

@media (max-width: 900px) {
  .order_create_wrapper .form_row--empty {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
}

.order_create_wrapper .form_row--none-empty {
  height: 425px;
  margin-top: 22px;
}

.order_create_wrapper .form_row--none-empty .form_cell--label {
  height: 0 !important;
  padding-top: 0 !important;
}

.order_create_wrapper .form_row--none-empty .portfolio_controls__drag_container {
  flex-direction: row;
  transition: height 0.3s ease-in-out;
  height: 94px;
}

.order_create_wrapper .form_row--none-empty .portfolio_controls__drag_container .portfolio_controls__btn,
.order_create_wrapper .form_row--none-empty .portfolio_controls__drag_container .dnd_file_input__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  margin-top: 0;
  margin-left: 9px;
}

.order_create_wrapper .form_row--none-empty .dnd_file_input {
  margin-top: 0;
}

.order_create_wrapper .form_row--none-empty .ic--upload {
  height: 34px;
  width: 55px;
}

@media (max-width: 900px) {
  .order_create_wrapper .form_row--none-empty {
    max-width: 409px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 599px) {
  .order_create_wrapper .form_row--none-empty {
    margin-top: 60px;
  }
}

.form_cell--label--budget {
  margin-left: 25px;
}

@media (max-width: 599px) {
  .form_cell--label--budget {
    margin-left: 0;
  }
}

.form__label--not-label {
  font-weight: normal;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #262626 !important;
  opacity: 1 !important;
  width: 150px !important;
}

@media (max-width: 600px) {
  .form__label--not-label {
    width: 143px !important;
  }
}

@media (max-width: 599px) {
  .form__label--not-label {
    width: 130px !important;
  }
}

.order_created {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 202;
  width: 100%;
  height: 100%;
}

.order_created__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order_created__icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ffd24d;
  margin: 0 auto;
  position: relative;
}

.order_created__icon:after {
  display: block;
  content: "";
  position: absolute;
  top: calc(50% - 5px);
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  border-bottom: 3px solid #000000;
  border-left: 3px solid #000000;
  height: 14px;
  width: 20px;
  z-index: 1;
  background-color: transparent;
}

.order_created__text {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #262626;
  margin-top: 26px;
}

@media (max-width: 599px) {
  .form_subrow {
    width: 100%;
  }
}

@media (min-width: 1279px) {
  .order_create_wrapper .form_row--account {
    margin-top: 2px;
    margin-bottom: 32px;
  }
}

@media (max-width: 599px) {
  .order_create_wrapper .form_row--account {
    margin-top: -15px;
    margin-bottom: 29px;
  }
}

@media (min-width: 1279px) {
  .order_create_wrapper .form_message_light {
    padding-top: 45px;
    margin-bottom: 40px;
  }
}

@media (min-width: 900px) {
  .order_create_wrapper .form_cell--long {
    width: 100%;
  }
}

.file__descr_text {
  font-size: 12px;
  color: #a5a8ad;
  line-height: 14px;
  width: 100%;
  margin-top: 10px;
  display: block;
}

@media (max-width: 900px) {
  .file__descr_text {
    font-size: 10px;
  }
}

.auth__reg_hint {
  font-size: 14px;
  color: #262626;
}

.auth {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding-top: 55px;
}

.form-row__master__info {
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}

.form-row__master__info svg {
  margin-right: 8px;
}

.modal-wrapper .auth, .album-confirmation--wrapper .auth, .image-confirmation--wrapper .auth {
  padding-top: 38px;
  max-width: 510px;
}

.auth__title {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  text-align: center;
  color: #262626;
  margin-bottom: 45px;
}

@media (min-width: 640px) {
  .auth__title {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 25px;
    font-weight: 700;
  }
}

@media (max-width: 639px) {
  .auth__title {
    margin-bottom: 25px;
  }
}

@media (min-width: 640px) {
  .modal-wrapper .auth__title, .album-confirmation--wrapper .auth__title, .image-confirmation--wrapper .auth__title {
    letter-spacing: 1px;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 18px;
    font-weight: 900;
  }
}

.auth__text {
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  max-width: 300px;
  margin-bottom: 32px;
}

.auth__form {
  width: 100%;
  max-width: 380px;
  padding: 0 20px;
}

@media (max-width: 639px) {
  .auth__form {
    max-width: 280px;
  }
}

.auth__form .form_row {
  margin-bottom: 32px;
  flex-direction: column;
}

.auth__form .form_row.has-error .form_error {
  display: block;
}

.auth__form .form_row.has-error .form__text {
  border: 1px solid #fe253c;
}

.auth__form .form_row.has-success .form_error {
  display: none;
}

.auth__form .form_row:last-of-type {
  margin-bottom: 0;
}

.auth__form .form_row .form_error {
  display: none;
  position: absolute;
  bottom: -34px;
  width: 100%;
  text-align: center;
}

.auth__form .form_error {
  margin: 0;
  justify-content: center;
  align-items: center;
}

.modal-wrapper .auth__form, .album-confirmation--wrapper .auth__form, .image-confirmation--wrapper .auth__form {
  max-width: none;
  padding: 0;
}

.modal-wrapper .auth__form .form_row, .album-confirmation--wrapper .auth__form .form_row, .image-confirmation--wrapper .auth__form .form_row {
  margin-bottom: 22px;
}

.auth__footer {
  padding-top: 16px;
  text-align: center;
}

.auth__footer .button__login {
  min-width: 160px;
  max-width: 310px;
  margin-bottom: 14px;
  border-radius: 29px;
}

@media (min-width: 640px) {
  .auth__footer {
    padding-top: 26px;
  }
}

.modal-wrapper .auth__footer .button__login, .album-confirmation--wrapper .auth__footer .button__login, .image-confirmation--wrapper .auth__footer .button__login {
  width: 100%;
  margin-bottom: 28px;
  height: 55px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.auth__restore_pass {
  display: inline-block;
  color: gray;
  border-bottom: 2px solid  #eceff1;
  margin-top: 8px;
}

.auth__restore_pass.is_cancel {
  font-size: 14px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
}

.modal-wrapper .auth__restore_pass, .album-confirmation--wrapper .auth__restore_pass, .image-confirmation--wrapper .auth__restore_pass {
  line-height: 25px;
  font-size: 18px;
  padding-bottom: 10px;
  font-weight: 600;
  color: #A3A3A3;
}

.modal-wrapper .auth__restore_pass:hover, .album-confirmation--wrapper .auth__restore_pass:hover, .image-confirmation--wrapper .auth__restore_pass:hover {
  border-bottom: 2px solid  #eceff1;
  color: #fd2d2c;
}

.auth_msg {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  text-align: left;
  color: #a5a8ad;
  margin-bottom: 23px;
}

@media (max-width: 639px) {
  .auth_msg {
    text-align: center;
  }
}

.form_row--text_input {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.form_row--text_input:last-child {
  margin-bottom: 0;
}

.signup_form__msg {
  font-family: "Open-Sans", "Arial", sans-serif;
  line-height: 22px;
  font-size: 12px;
  color: #a5a8ad;
  max-width: 397px;
  text-align: left;
  margin-top: 32px;
}

.signup_form__msg a {
  display: inline;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
}

.registration {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding-top: 55px;
}

@media (max-width: 639px) {
  .registration {
    padding: 55px 20px 0;
  }
}

.registration .auth__footer {
  margin: 0;
  padding: 0;
}

.registration .auth__title {
  font-size: 28px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
}

.registration .role_switcher__ic {
  padding: 0;
}

@media (max-width: 639px) {
  .registration .role_switcher__ic {
    margin: 0;
    padding: 0;
  }
}

.registration .role_switcher__radio_label {
  margin-right: 74px;
}

@media (max-width: 639px) {
  .registration .role_switcher__radio_label {
    flex-direction: row;
    align-items: center;
  }
}

.registration .role_switcher__role {
  padding-left: 14px;
}

@media (max-width: 639px) {
  .registration .form__text {
    width: 100%;
  }
}

.registration .form_row {
  position: relative;
}

.registration .form_row .form_error {
  text-align: center;
  padding-top: 6px;
  position: absolute;
  bottom: -36px;
  width: 100%;
  display: none;
}

.registration .form_row.form_row--user_role {
  margin-bottom: 32px;
}

.registration .form_row.form_row--user_role .role_switcher {
  width: 100%;
  justify-content: center;
}

.registration .form_row.has_arrow:after {
  content: '';
  width: 7px;
  height: 11px;
  background: url(/media/images/arrow.svg) no-repeat;
  position: absolute;
  right: 15px;
  top: 17px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.registration .form_row.has-error input {
  border: 1px solid #fe253c;
}

.registration .form_row.has-error .form_error {
  position: absolute;
  bottom: -36px;
  width: 100%;
  padding-top: 6px;
  text-align: center;
  display: block;
}

.registration .form_row.has-success .form_error {
  display: none;
}

.registration .role_switcher__radio_label:last-child {
  margin-right: 0;
}

.registration .auth__restore_pass {
  font-size: 14px;
}

.button__reg {
  padding: 0 31px;
}

.signup_form {
  max-width: 400px;
}

.auth__register {
  display: inline-block;
  line-height: 40px;
  font-size: 12px;
  color: #a5a8ad;
  font-weight: 600;
  margin-top: auto;
}

.registration .js_dropdown_cities.has-error .form_error {
  position: absolute;
  bottom: -28px;
  width: 100%;
  padding-top: 6px;
  text-align: center;
  display: block;
}

.registration_info {
  position: absolute;
  max-width: 300px;
  background: #f5f6f7;
  border-radius: 4px;
  right: -324px;
  top: 0;
  padding: 29px 35px 28px 45px;
}

.registration_info:after {
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f5f6f7;
  position: absolute;
  left: -10px;
  top: 15px;
}

@media (max-width: 639px) {
  .registration_info {
    position: relative;
    margin: 30px auto 0;
    right: 0;
    left: 0;
    max-width: none;
  }
  .registration_info:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #f5f6f7;
    position: absolute;
    top: -15px;
    left: 10%;
    transform: rotate(90deg);
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .registration_info {
    position: relative;
    margin: 30px auto 0;
    right: 0;
    left: 0;
    max-width: none;
  }
  .registration_info:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #f5f6f7;
    position: absolute;
    top: -15px;
    left: 10%;
    transform: rotate(90deg);
  }
}

.registration_info__title {
  font-weight: bold;
  line-height: 20px;
  font-size: 12px;
  color: #262626;
  padding-bottom: 12px;
}

.registration_info__msg {
  line-height: 20px;
  font-size: 12px;
  color: #262626;
  padding-bottom: 17px;
}

.registration_info__close {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.enter_btn {
  height: 34px;
  padding: 0 38px;
  line-height: 34px;
}

.ic--close.grey {
  fill: #979797;
}

.field-signupform-inn {
  position: relative;
}

.petrovich_form {
  background: #FAFAFA;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin: 0 0 30px;
  padding: 10px 40px;
}

.petrovich_form-icon {
  stroke: #FFD24D;
  margin-right: 16px;
  min-width: 24px;
}

.petrovich_form:hover {
  background-color: #ffd24d;
}

.petrovich_form:hover .petrovich_form-text {
  color: #FFFFFF;
}

.petrovich_form:hover .petrovich_form-icon {
  stroke: #FFFFFF;
}

@media (max-width: 500px) {
  .petrovich_form {
    padding: 10px 20px;
    width: 100%;
  }
}

.petrovich_form-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #676767;
}

.modal-wrapper-petrovich {
  padding: 30px;
  width: 500px;
  z-index: 300;
}

.modal-wrapper-petrovich .modal-body--header .title {
  font-size: 20px;
  line-height: 25px;
  font-style: normal;
  font-weight: bold;
  color: #262626;
  margin-bottom: 40px;
}

.modal-wrapper-petrovich .modal-body--text .petrovich-link {
  color: red;
  line-height: 135%;
  text-decoration: underline;
}

.modal-wrapper-petrovich .modal-body--text p {
  margin-bottom: 25px;
}

.modal-wrapper-petrovich .modal-body--text p .ic--asterix {
  fill: #ffd966;
}

.modal-wrapper-petrovich .modal-body--text p:last-child {
  margin-bottom: unset;
}

.modal-wrapper-petrovich .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-wrapper-petrovich .modal-footer .button__login {
  width: 308px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0 16px 0;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #262626;
}

.modal-wrapper-petrovich .modal-footer .button__problems {
  text-decoration: underline;
}

.modal-wrapper-petrovich .modal-wrapper__close {
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
  z-index: 205;
}

.modal-wrapper-petrovich .modal-wrapper__close .ic--cross-modal {
  width: 20px;
  height: 20px;
}

.modal-wrapper-petrovich .modal-petrovich_form {
  margin-bottom: 20px;
}

@media (max-width: 500px) {
  .modal-wrapper-petrovich {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    overflow-y: auto;
    padding: 80px 30px;
  }
  .modal-wrapper-petrovich .modal-wrapper__close {
    top: 80px;
  }
}

.lt_2col .page_content {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 800px;
}

.lt_2col .page_content:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 639px) {
  .lt_2col .page_content {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
  .lt_2col .page_content > div[itemscope] {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 899px) {
  .lt_2col .page_content--card-master {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
  .lt_2col .page_content--card-master > div[itemscope] {
    display: flex;
    flex-direction: column;
  }
}

.lt_2col .page_content--chat .page_content__left--listing {
  order: 1;
  float: none;
}

@media (min-width: 900px) and (max-width: 1279px) {
  .lt_2col .page_content--chat .page_content__left--chat {
    width: 100%;
    padding: 0;
  }
}

.lt_2col .page_title {
  width: 75%;
  float: none;
  padding-top: 28px;
  padding-left: 28px;
}

@media (min-width: 1000px) {
  .lt_2col .page_title .master_descr {
    padding: 0 80px 74px 220px;
  }
}

@media (min-width: 1000px) {
  .lt_2col .page_title {
    width: 68%;
    padding-left: 80px;
  }
}

@media (max-width: 999px) {
  .lt_2col .page_title {
    width: 66%;
  }
}

@media (max-width: 639px) {
  .lt_2col .page_title {
    width: auto;
    float: none;
    order: 1;
    padding: 28px 0;
  }
  .lt_2col .page_title:not(.page_title--listing) > .breadcrumbs {
    padding-left: 28px;
  }
}

.lt_2col .page_content__left {
  width: 75%;
  float: left;
  padding-top: 0;
}

@media (min-width: 1000px) {
  .lt_2col .page_content__left {
    width: 69%;
  }
  .lt_2col .page_content__left .specialization {
    padding: 0 80px 20px;
  }
}

@media (max-width: 999px) {
  .lt_2col .page_content__left {
    width: 64%;
    float: none;
  }
}

@media (max-width: 639px) {
  .lt_2col .page_content__left {
    width: auto;
    float: none;
    order: 3;
  }
  .lt_2col .page_content__left .master_descr {
    display: none;
  }
}

@media (max-width: 899px) {
  .lt_2col .page_content__left {
    width: 100%;
    order: 3;
  }
}

.lt_2col .page_content__right {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 99;
}

@media (max-width: 899px) {
  .lt_2col .page_content__right {
    height: auto;
    position: static;
    display: block;
    float: none;
    order: 2;
    padding-top: 0;
    margin-bottom: 20px;
  }
  .lt_2col .page_content__right .master {
    display: none;
  }
  .lt_2col .page_content__right .master_stats.is_fixed {
    position: static;
  }
  .lt_2col .page_content__right .master_stats.is_fixed .master {
    display: none;
  }
}

.lt_2col .page_content__right .check_passport_data {
  background: #FFFFFF;
  box-shadow: 0px 14px 64px rgba(38, 38, 38, 0.09);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.lt_2col .page_content__right .check_passport_data .check_passport_data_context {
  display: block;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  height: auto;
  max-width: 207px;
}

.lt_2col .page_content__right .check_passport_data .check_passport_data_context .icon_text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 19px;
}

.lt_2col .page_content__right .check_passport_data .check_passport_data_context .icon_text .ic.ic--king {
  height: 30px;
  width: 30px;
  display: block;
  margin-right: 13px;
}

.lt_2col .page_content__right .check_passport_data .check_passport_data_context .icon_text span {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
}

.lt_2col .page_content__right .check_passport_data .check_passport_data_context span.text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  line-height: 150.5%;
  color: #262626;
}

.lt_2col .page_content__right .check_passport_data .check_passport_data_context a.btn--submit.check_passport_data_button {
  margin-top: 19px;
  margin-bottom: 0;
  text-align: center;
  height: auto;
  width: auto;
  cursor: pointer;
}

.lt_2col .page_content__right .checked_master_tooltip {
  top: 3px;
}

@media (max-width: 639px) {
  .lt_2col .page_content__right {
    position: static;
    display: block;
    float: none;
    order: 2;
    padding-top: 0;
    margin-bottom: 20px;
  }
  .lt_2col .page_content__right .master {
    display: none;
  }
  .lt_2col .page_content__right .master_stats.is_fixed {
    position: static;
  }
  .lt_2col .page_content__right .master_stats.is_fixed .master {
    display: none;
  }
}

.lt_2col .page_content__right--empty.page_content__right--listing {
  display: none;
}

@media (min-width: 1280px) {
  .lt_2col .page_content__right--empty.page_content__right--listing {
    display: block;
  }
}

@media (max-width: 639px) {
  .lt_2col .page_content__right.page_content__right--chat {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 1000px) {
  .lt_2col .page_content--grid {
    display: grid;
    grid-template-columns: 68% 30%;
    justify-content: space-between;
  }
  .lt_2col .page_content--grid:after {
    display: none;
  }
  .lt_2col .page_content--grid .page__title {
    grid-column: 1 / span 1;
    width: 100%;
  }
  .lt_2col .page_content--grid .page_content__left {
    grid-column: 1;
    grid-row: 2;
    width: 100%;
  }
  .lt_2col .page_content--grid .page_content__right {
    position: static;
    margin-left: auto;
    grid-column: 2;
    grid-row: 1 / span 3;
    width: 100%;
  }
}

.icon_hover {
  display: inline-block;
  position: relative;
  width: auto;
}

.icon_hover svg {
  margin-left: 3px;
}

.icon_hover .ic.ic--checked_master_tooltip {
  display: none;
  position: absolute;
  top: 13px;
  right: -145px;
  z-index: 100;
  margin: 0;
}

.icon_hover > svg.ic.ic--king {
  position: relative;
}

.icon_hover > svg.ic.ic--king:hover + .ic.ic--checked_master_tooltip {
  display: block;
}

.master {
  padding-bottom: 16px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.master.mobile {
  display: none;
  visibility: hidden;
}

.master .user_photo {
  width: 52px;
  height: 52px;
  margin-left: auto;
  margin-right: auto;
  flex-shrink: 0;
  border: 2px solid white;
}

.master .user_photo img {
  height: 100%;
}

.master .user_photo.checked {
  border: 2px solid #FFD24D;
}

.master .fav_link {
  position: absolute;
  top: -33px;
  right: 35px;
  z-index: 200;
}

@media (min-width: 640px) {
  .master {
    padding: 0 6px 46px 26px;
    flex-wrap: nowrap;
    overflow: inherit;
  }
  .master .user_photo {
    width: inherit;
    height: inherit;
    margin-right: 20px;
    margin-left: -10px;
    min-width: 60px;
    flex-shrink: 1;
  }
  .master .fav_link {
    top: 7px;
    right: auto;
    left: -40px;
  }
}

@media (min-width: 1000px) {
  .master {
    padding: 0 20px 46px 0;
  }
  .master .user_photo {
    width: 160px;
    height: 160px;
    margin-right: 50px;
    margin-left: 10px;
    flex-shrink: 0;
  }
}

.master .button {
  height: auto;
}

@media (min-width: 1280px) {
  .master .fav_link {
    top: 6px;
    right: auto;
    left: -44px;
  }
}

.user_photo {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.user_photo .user_photo__img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.master_info {
  padding-top: 10px;
  width: 100%;
  position: relative;
}

@media (min-width: 640px) {
  .master_info {
    width: auto;
    padding-top: 6px;
  }
}

.master_info__grade {
  margin-bottom: 6px;
  min-height: 19px;
}

@media (max-width: 639px) {
  .master_info__grade {
    text-align: center;
  }
}

@media (min-width: 640px) {
  .master_info__grade {
    text-align: left;
    margin-bottom: 12px;
  }
}

.master_info__name {
  font-size: 18px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  line-height: 1.39;
  color: #262626;
  text-align: center;
  padding: 0 10px;
  position: relative;
  margin: 0;
}

@media (min-width: 640px) {
  .master_info__name {
    text-align: left;
    font-size: 36px;
    line-height: 1.11;
    padding: 0;
  }
}

.page_title .master_info__name svg.ic.ic--king {
  height: 22px;
  width: 22px;
}

.page_content__right .master_info__name svg.ic.ic--king {
  height: 12px;
  width: 12px;
}

.page_content__right .master_info__name svg.ic.ic--checked_master_tooltip {
  top: 5px;
}

@media screen and (max-width: 639px) {
  .page_title .master_info__name svg.ic.ic--king {
    height: 12px;
    width: 12px;
  }
}

.checked_master_tooltip {
  display: none;
  background: #555659;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 11px;
  line-height: 15px;
  color: #FFFFFF;
  padding: 2.3px 5px;
  position: absolute;
  top: 0;
  right: -150px;
  z-index: 100;
}

.checked_master_tooltip:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #555659 transparent transparent;
  position: absolute;
  left: -14px;
  top: 0;
}

.master_info__row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  flex-flow: row wrap;
}

.master_info__row .petr_tooltip {
  margin-bottom: 0;
  margin-left: 8px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
}

.master_info__row .petr_tooltip__hint {
  margin-left: 0;
}

@media (max-width: 999px) {
  .master_info__row .petr_tooltip__text {
    width: 100%;
  }
}

@media (min-width: 640px) {
  .master_info__row {
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .master_info__row:last-child {
    margin-top: 30px;
  }
  .master_info__row .petr_tooltip {
    margin-left: 0;
  }
  .master_info__row .petr_tooltip__content {
    min-width: 270px;
  }
}

.master_info__start_date,
.master_info__status {
  font-size: 12px;
  color: #a5a8ad;
  margin-right: 18px;
}

.master_info__status {
  display: flex;
  align-items: center;
}

@media (max-width: 639px) {
  .master_info__status {
    margin-right: 0;
  }
}

.ic_status {
  width: 7px;
  height: 7px;
  background-color: #cfd1d4;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.ic_status--online {
  background-color: #ffd24d;
}

.master_info__ur,
.master_info__location {
  font-size: 14px;
  color: #262626;
  margin-right: 18px;
}

.master_info__location .ic--map-tag {
  margin-right: 6px;
  top: -2px;
}

.master_info__location .petr_tooltip {
  display: inline-flex;
}

@media (max-width: 639px) {
  .master_info__location {
    margin-right: 0;
  }
}

.specialization {
  padding: 28px 10px 54px;
}

.specialization .section_title {
  padding-left: 10px;
}

@media (min-width: 640px) {
  .specialization {
    padding: 0 16px 74px;
  }
}

@media (min-width: 1000px) {
  .specialization {
    padding: 0 80px 74px;
  }
}

@media (min-width: 1280px) {
  .specialization .section_title {
    padding-left: 20px;
  }
}

.section_title {
  margin-bottom: 10px;
}

.stars {
  display: flex;
  align-items: center;
}

.stars__list {
  margin-right: 20px;
  background-color: #e3e5e8;
  width: 90px;
  height: 17px;
  position: relative;
  overflow: hidden;
}

.stars__mask {
  background-image: url(/media/images/stars.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 90px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.stars__fill {
  background-color: #ffd24d;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.stars__fill--f05 {
  width: 9px;
}

.stars__fill--f1 {
  width: 15px;
}

.stars__fill--f15 {
  width: 27px;
}

.stars__fill--f2 {
  width: 33px;
}

.stars__fill--f25 {
  width: 45px;
}

.stars__fill--f3 {
  width: 51px;
}

.stars__fill--f35 {
  width: 63px;
}

.stars__fill--f4 {
  width: 69px;
}

.stars__fill--f45 {
  width: 81px;
}

.stars__fill--f5 {
  width: 87px;
}

.master_grade {
  display: flex;
  margin-bottom: 25px;
  padding: 0 10px;
}

@media (min-width: 1000px) {
  .master_grade {
    margin-bottom: 50px;
  }
}

@media (min-width: 1280px) {
  .master_grade {
    padding: 0 20px;
  }
}

.master_grade_average {
  display: flex;
  align-items: flex-end;
}

@media (min-width: 640px) {
  .master_grade_average {
    display: block;
    margin-right: 0;
    width: 102px;
    flex-shrink: 0;
  }
}

@media (min-width: 1000px) {
  .master_grade_average {
    margin-right: 56px;
    width: auto;
  }
}

.master_grade_average__quantity {
  font-size: 56px;
  line-height: 56px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  color: #262626;
  margin-right: 20px;
}

@media (min-width: 640px) {
  .master_grade_average__quantity {
    line-height: 70px;
    margin-bottom: 6px;
    margin-right: 0;
  }
}

.master_grade_average__hint {
  font-size: 12px;
  line-height: 1.67;
  text-align: left;
  color: #a5a8ad;
}

.master_stars {
  display: none;
}

@media (min-width: 640px) {
  .master_stars {
    display: block;
  }
}

.master_stars__row {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

.master_stars__row .stars {
  width: 72px;
  justify-content: flex-end;
}

.master_stars__row .stars__list {
  margin-right: 0;
  background-color: transparent;
  justify-content: flex-end;
  display: flex;
}

.master_stars__row .stars__item {
  width: 10px;
  height: 12px;
  background: url(/media/images/star_small.png) no-repeat;
}

.master_stars__text {
  font-size: 12px;
  line-height: 2.08;
  text-align: right;
  color: #262626;
  width: 30px;
  margin-right: 20px;
}

.master_stars__line {
  width: 150px;
  position: relative;
  height: 6px;
  border-radius: 2px;
  background-color: #edeef0;
}

.master_stars__line_inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 2px;
  background-color: #ffd24d;
}

.master_feedbacks {
  padding: 0 10px 20px;
}

@media (min-width: 1280px) {
  .master_feedbacks {
    padding: 0 20px;
  }
}

.master_feedback {
  border: solid 1px #edeef0;
  margin: -1px -20px 0;
  padding: 15px 19px;
}

.master_feedback:last-of-type {
  margin-bottom: 30px;
}

@media (min-width: 640px) {
  .master_feedback {
    border-radius: 8px;
    margin: 0 -16px 30px;
    padding: 25px 19px 25px 25px;
  }
}

@media (min-width: 600px) {
  .master_feedback {
    margin: 0 10px 20px;
    border-radius: 8px;
    padding: 25px 19px 25px 24px;
  }
}

@media (min-width: 900px) {
  .master_feedback {
    margin-bottom: 20px;
    padding: 25px 20px 24px 24px;
  }
}

@media (min-width: 1000px) {
  .master_feedback {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1280px) {
  .master_feedback {
    margin: 0 -40px 20px;
    padding: 36px 35px 30px 39px;
  }
}

.master_feedback .feedback-review-image img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 3px;
  margin: 0 5px 5px 0;
}

.master_feedback__header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.master_feedback__header .stars__list {
  margin-right: 8px;
}

@media (min-width: 600px) {
  .master_feedback__header {
    margin-bottom: 25px;
  }
}

@media (min-width: 900px) {
  .master_feedback__header {
    margin-bottom: 25px;
  }
}

@media (min-width: 1000px) {
  .master_feedback__header {
    margin-bottom: 24px;
  }
}

.master_feedback__specialization {
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #a5a8ad;
  margin-bottom: 0;
  line-height: 16px;
  margin-left: 11px;
}

.master_feedback__text {
  font-size: 14px;
  line-height: 1.79;
  text-align: left;
  color: #262626;
  margin-bottom: 7px;
}

@media (min-width: 640px) {
  .master_feedback__text {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

@media (min-width: 600px) {
  .master_feedback__text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 5px;
  }
}

@media (min-width: 900px) {
  .master_feedback__text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 5px;
  }
}

@media (min-width: 1000px) {
  .master_feedback__text {
    font-size: 18px;
    line-height: 1.67;
    margin-bottom: 6px;
  }
}

.master_feedback__author,
.master_feedback__date {
  font-size: 12px;
  text-align: left;
  color: #a5a8ad;
}

.master_stats {
  padding: 26px 20px 3px;
  background-color: #f5f6f7;
}

@media (min-width: 600px) {
  .master_stats {
    padding-top: 40px;
  }
}

.master_stats.is_fixed {
  top: 0;
  position: fixed;
  background-color: #f5f6f7;
  z-index: 100;
  width: inherit;
  max-width: 360px;
}

@media (max-width: 639px) {
  .master_stats.is_fixed {
    width: 100%;
  }
}

.master_stats.is_fixed .master {
  display: flex;
}

.master_stats.is_fixed .master_info {
  max-width: 240px;
}

.master_stats.is_fixed .master_info__grade_text {
  margin-right: 10px;
}

.master_stats.is_fixed .link_with_icons {
  margin-right: 15px;
}

.page_content__left .master_stats {
  display: block;
}

@media (min-width: 640px) {
  .page_content__left .master_stats {
    display: none;
  }
}

.master_stats + .tabs {
  margin-top: 28px;
}

@media (min-width: 1000px) {
  .master_stats {
    padding: 0 40px 40px;
  }
}

@media (min-width: 1280px) {
  .master_stats {
    padding: 0 40px 40px;
  }
}

.master_stats__inner .master {
  padding: 0 20px 36px 16px;
  margin: 0 -20px 36px -16px;
  border-bottom: 1px solid #edeef0;
  flex-wrap: nowrap;
  display: none;
}

@media (min-width: 1000px) {
  .master_stats__inner .master {
    margin: 0 -40px 36px;
    padding: 0 40px 36px;
  }
}

@media (min-width: 1000px) {
  .is_fixed .master_stats__inner .user_photo {
    margin-top: 0;
  }
  .is_fixed .master_stats__inner .master {
    padding-top: 30px;
    padding-bottom: 0;
    margin-bottom: 33px;
    border-bottom-width: 0;
    align-items: center;
  }
  .is_fixed .master_stats__inner .master_info {
    padding-top: 0;
  }
  .is_fixed .master_stats__inner .master_info__grade {
    position: absolute;
    bottom: 100%;
    display: flex;
    align-items: center;
  }
  .is_fixed .master_stats__inner .master + .master_stats_section {
    padding-top: 15px;
    margin-top: 0;
    position: relative;
  }
  .is_fixed .master_stats__inner .master + .master_stats_section:before {
    content: '';
    display: block;
    background-color: #edeef0;
    height: 1px;
    left: -17px;
    right: -17px;
    top: 0;
    position: absolute;
  }
  .is_fixed .master_stats__inner .master + .master_stats_section .master_stats_section__rating {
    top: 10px;
  }
}

.master_stats__inner .user_photo {
  width: 54px;
  height: 54px;
  box-shadow: 0 1px 1px #e3e5e8;
  flex-shrink: 0;
  margin-right: 12px;
  margin-left: -67px;
  margin-top: 28px;
  min-width: inherit;
  border: 3px solid #ffffff;
  box-sizing: border-box;
}

.master_stats__inner .master_info__grade {
  margin-bottom: 6px;
}

.master_stats__inner .master_info__name {
  font-size: 18px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  line-height: 1.39;
  margin-bottom: 0;
}

.master_stats_section {
  position: relative;
  margin-bottom: 26px;
}

.master_stats_section:not(:first-of-type) {
  margin-top: 26px;
}

@media (min-width: 640px) {
  .master_stats_section {
    margin-bottom: 36px;
  }
}

.master_stats_section__rating {
  font-size: 56px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  line-height: 1;
  text-align: right;
  color: #262626;
  position: absolute;
  top: -6px;
  right: 0;
}

@media (min-width: 1000px) {
  .master_stats_section__rating {
    right: 19px;
  }
}

.master_stats_section__title {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  margin-bottom: 10px;
}

.master_stats_section__title .ic--key {
  top: -2px;
}

.master_stats_section__hint {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #a5a8ad;
  margin-bottom: 12px;
}

.master_stats_section__link {
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #a5a8ad;
  display: inline-block;
  margin-bottom: 12px;
}

.master_stats_section__link:hover {
  color: #555659;
}

.master_stats_orders {
  font-size: 12px;
  line-height: 2.08;
  color: #000000;
  margin-bottom: 8px;
}

.master_stats_orders__row {
  position: relative;
  overflow: hidden;
}

.master_stats_orders__row:after {
  content: '';
  display: block;
  height: 1px;
  border-bottom: 1px dotted #d7dade;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: -11px;
}

.master_stats_orders__row--novalue:after {
  display: none;
}

.master_stats_orders__key,
.master_stats_orders__value {
  background-color: #f5f6f7;
  position: relative;
  z-index: 5;
}

.master_stats_orders__key {
  padding-right: 10px;
  float: left;
}

.master_stats_orders__value {
  text-align: right;
  padding-left: 10px;
  float: right;
}

.portfolio_items {
  display: flex;
  flex-flow: row wrap;
  padding-top: 11px;
}

@media (min-width: 640px) {
  .portfolio_items {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -60px;
  }
}

@media (min-width: 1280px) {
  .portfolio_items {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.portfolio_item {
  margin-bottom: 28px;
  display: block;
  width: 100%;
}

@media (min-width: 640px) {
  .portfolio_item {
    margin-bottom: 20px;
    max-width: 360px;
    margin-right: 40px;
  }
}

@media (min-width: 1000px) {
  .portfolio_item {
    width: auto;
  }
}

.master_stats.is_fixed {
  width: auto;
}

.portfolio_item__img {
  border-radius: 8px;
  background-color: #e3e5e8;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;
}

.portfolio_item__img img {
  border-radius: 8px;
  width: 100%;
  display: block;
}

@media (min-width: 640px) {
  .portfolio_item__img {
    margin-bottom: 20px;
  }
}

@media (min-width: 1000px) {
  .portfolio_item__img {
    width: 360px;
    height: 220px;
  }
}

.portfolio_item__type {
  font-size: 12px;
  color: #a5a8ad;
  margin-bottom: 5px;
}

.portfolio_item__title {
  font-size: 14px;
  color: #262626;
  line-height: 2.14;
  margin-bottom: 5px;
}

@media (min-width: 640px) {
  .portfolio_item__title {
    font-size: 18px;
    line-height: 30px;
  }
}

.portfolio_item__photo_count {
  font-size: 12px;
  color: #a5a8ad;
}

@media (min-width: 640px) {
  .portfolio_item__photo_count {
    margin-bottom: 20px;
  }
}

.master_stub {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 110px 0 100px;
}

.master_stub__message {
  font-size: 14px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 30px;
}

.ic--stub_icon {
  margin-bottom: 10px;
}

.stars__item {
  width: 16px;
  height: 16px;
  background: url(/media/images/star.png) no-repeat;
  margin-right: 4px;
}

.stars__item:last-child {
  margin-right: 0;
}

.stars__item.is_full {
  background: url(/media/images/star_is_active.png) no-repeat;
}

.stars__item.is_half {
  background: url(/media/images/star_half.png) no-repeat;
}

.petr_tooltip {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #a5a8ad;
  margin-bottom: 12px;
}

.petr_tooltip--float {
  position: absolute;
  left: 40px;
  top: 40px;
  font-size: 14px;
  line-height: 19px;
}

.petr_tooltip .petr_tooltip__hint:hover {
  background-color: #ffd966;
}

.petr_tooltip .petr_tooltip__hint:hover + .petr_tooltip__content {
  opacity: 1;
  visibility: visible;
  display: block;
}

.form_order_create {
  width: 100%;
  max-width: 590px;
}

.form_order_create .petr_tooltip {
  margin-left: auto;
  margin-bottom: -8px;
}

@media (max-width: 899px) {
  .form_order_create .petr_tooltip.petr_tooltip--float {
    margin-bottom: 0;
    top: 33px;
    left: 27px;
  }
  .form_order_create .petr_tooltip.petr_tooltip--float .petr_tooltip__hint {
    width: 24px;
    height: 24px;
    font-size: 15px;
    line-height: 24px;
  }
}

@media (max-width: 899px) {
  .form_order_create .petr_tooltip {
    margin-bottom: 3px;
  }
  .form_order_create .petr_tooltip span {
    display: none;
  }
}

@media (max-width: 599px) {
  .form_order_create .petr_tooltip.petr_tooltip--float {
    margin-bottom: 0;
    top: 20px;
    left: 4px;
  }
  .form_order_create .petr_tooltip.petr_tooltip--float .petr_tooltip__hint {
    width: 24px;
    height: 24px;
    font-size: 15px;
    line-height: 24px;
  }
  .form_order_create .petr_tooltip span {
    display: none;
  }
}

.petr_tooltip__hint {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #e3e5e8;
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  text-align: center;
  color: #ffffff;
  line-height: 15px;
  border-radius: 50%;
  margin-left: 7px;
  cursor: pointer;
}

.petr_tooltip__hint:hover {
  background-color: #ffd966;
}

.petr_tooltip__content {
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  display: none;
  right: -10px;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 15px 20px;
  z-index: 50;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  min-width: 400px;
}

.petr_tooltip__content .master_stats_orders__key,
.petr_tooltip__content .master_stats_orders__value {
  background-color: #ffffff;
  font-family: "Open-Sans", "Arial", sans-serif;
}

@media (max-width: 599px) {
  .petr_tooltip__content {
    min-width: 300px;
  }
}

.petr_tooltip__header {
  margin: 0 -20px 15px;
  padding: 0 20px;
  border-bottom: 1px solid #edeef0;
}

.petr_tooltip__header .master_stats_orders__row:after {
  display: none;
}

.petr_tooltip__text {
  font-size: 12px;
  line-height: 1.67;
  color: #262626;
  font-family: "Open-Sans", "Arial", sans-serif;
}

.is_hidden {
  display: none;
}

.fav_link {
  position: relative;
}

.fav_link:before, .fav_link:after {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  height: 20px;
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  z-index: 20;
}

.fav_link:before {
  content: '';
  background: url(/media/images/fav-label-tag.png) 0 0 no-repeat;
  width: 8px;
  margin-left: 13px;
}

.fav_link:after {
  content: attr(data-label);
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-left: 20px;
  border-radius: 0 3px 3px 0;
  background-color: #555659;
  white-space: nowrap;
  padding: 0 5px;
}

.fav_link .ic--fav-tag {
  fill: #e3e5e8;
  pointer-events: none;
}

.fav_link:hover .ic--fav-tag {
  fill: #cecfd0;
}

.fav_link.is_active:after {
  content: attr(data-label-active);
}

.fav_link.is_active .ic--fav-tag {
  fill: #ffd24d;
}

@media (min-width: 640px) {
  .fav_link:hover:before, .fav_link:hover:after {
    opacity: 1;
    visibility: visible;
  }
}

.master_descr {
  padding: 4px 20px 35px;
}

.master_descr .button__secondary {
  display: none;
}

@media (min-width: 640px) {
  .master_descr {
    padding: 0 16px 74px;
  }
}

@media (min-width: 1000px) {
  .master_descr {
    padding: 0 80px 74px 280px;
  }
}

@media (max-width: 639px) {
  .master_descr .button__secondary {
    display: block;
    margin-top: 20px;
  }
}

.master_descr__content {
  max-height: 150px;
  font-size: 14px;
  line-height: 1.86;
  color: #262626;
  overflow: hidden;
  margin-bottom: 28px;
}

@media (min-width: 640px) {
  .master_descr__content {
    font-size: 18px;
    line-height: 1.67;
    margin-bottom: 10px;
  }
}

.master_descr__content.is_active {
  max-height: inherit;
}

.bids_counter {
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #a5a8ad;
}

.bids_counter.bids_counter--open {
  color: #262626;
  background-color: #ffd24d;
}

.is_closed .bids_counter {
  background-color: transparent;
  color: #a5a8ad;
}

@media (max-width: 639px) {
  .bids_counter {
    text-align: left;
  }
  .is_closed .bids_counter,
  .is_closed .bids_counter.bids_counter--open {
    text-align: left;
  }
  .bids_counter.bids_counter--open {
    text-align: center;
  }
}

.masters {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 0 0 30px;
  margin-top: -10px;
}

@media (min-width: 640px) {
  .masters {
    margin-top: 0;
    padding: 0 10px;
  }
}

@media (min-width: 1000px) {
  .masters {
    padding: 0;
    margin-left: -60px;
    margin-right: -40px;
  }
}

.masters_item {
  width: 100%;
  border-radius: 8px;
  border: solid 1px #edeef0;
  position: relative;
  box-sizing: border-box;
  padding: 14px 20px 14px 14px;
  margin: 0 0 10px;
}

.masters_item .fav_link {
  position: absolute;
  top: 13px;
  right: 20px;
  z-index: 110;
}

.masters_item .user_photo {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 12px;
  min-width: inherit;
  box-sizing: border-box;
  margin-left: 0;
}

.masters_item .master {
  padding: 0;
  align-items: flex-start;
  margin-bottom: 4px;
}

.masters_item .master_info {
  padding-top: 0;
  width: 200px;
}

.masters_item .master_info__grade {
  margin-bottom: 6px;
  text-align: left;
}

.masters_item .master_info__name {
  font-size: 16px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  line-height: 1.56;
  text-align: left;
  padding: 0;
}

.masters_item .master_stats_orders__key {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.masters_item .master_stats_orders__key,
.masters_item .master_stats_orders__value {
  background-color: #ffffff;
}

.masters_item .master_stats_orders {
  padding: 0 0 0 5px;
  margin-bottom: 0;
  margin-top: 18px;
}

@media (min-width: 640px) {
  .masters_item {
    max-width: 400px;
  }
}

@media (min-width: 1000px) {
  .masters_item {
    padding: 35px 3% 32px;
    margin: 0 0 40px 40px;
    min-width: 360px;
    width: calc(50% - 40px);
  }
  .masters_item .fav_link {
    top: 30px;
    right: 30px;
  }
  .masters_item .master {
    margin-bottom: 18px;
  }
  .masters_item .master_info__name {
    font-size: 18px;
    line-height: 1.39;
  }
  .masters_item .master_stats_orders {
    padding: 0 8px 0 60px;
  }
}

@media (min-width: 1280px) {
  .masters_item {
    width: 400px;
    padding: 35px 30px 32px 40px;
  }
}

.masters_item__link {
  position: relative;
  display: block;
}

.masters_item__link .master {
  margin-top: 0 !important;
}

.masters_item__link .master_stats_section__rating {
  position: absolute;
  left: 7px;
  top: 40px;
  width: 36px;
  height: 36px;
  background-color: #ffd24d;
  border: solid 3px #ffffff;
  border-radius: 50%;
  font-size: 12px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #262626;
  text-align: center;
  box-sizing: border-box;
  line-height: 32px;
  z-index: 20;
}

.card_profile_msg {
  background: #f5f6f7;
  border-radius: 8px;
  width: 100%;
  padding: 17px 27px 21px;
  margin: 36px 0 19px;
  line-height: 21px;
  font-size: 14px;
}

.master_stats_section__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 20px;
}

.master_stats_section__phone {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
}

.master_info__header {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  flex-flow: row wrap;
}

.master_info__header .master_info__grade {
  margin-right: 17px;
  margin-bottom: 6px;
  background-color: #f5f6f7;
  height: 23px;
  border-radius: 2px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.master_info__header .master_info__grade:last-child {
  margin-right: 0;
}

@media (max-width: 639px) {
  .master_info__header {
    flex-direction: column;
    align-items: center;
  }
  .master_info__header .master_info__grade {
    margin-right: 0;
  }
  .master_info__header .master_info__grade:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .master_info__header .master_info__grade {
    font-size: 10px;
  }
}

.link_with_icons {
  display: inline-flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
}

.link_with_icons .ic {
  pointer-events: none;
}

.link_with_icons .ic--liga {
  margin-right: 5px;
  margin-left: -2px;
  width: 20px;
  height: 11px;
}

.link_with_icons .ic--star {
  fill: #ffd24d;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-left: -4px;
}

.link_with_icons .petr_tooltip__hint {
  background-color: #d2d2d4;
  pointer-events: none;
  letter-spacing: normal !important;
}

.link_with_icons:hover {
  color: #262626;
}

.link_with_icons:hover .petr_tooltip__hint {
  background-color: #ffd24d;
}

.page_content__right .link_with_icons .ic--liga {
  margin-left: 5px;
}

.page_content__right .link_with_icons .ic--star {
  margin-right: 2px;
  width: 15px;
  height: 15px;
}

@media (max-width: 639px) {
  .link_with_icons.link_with_icons--high_liga .link_with_icons__text {
    order: 1;
  }
  .link_with_icons.link_with_icons--high_liga .ic {
    order: 2;
    margin-left: 5px;
  }
  .link_with_icons.link_with_icons--high_liga .petr_tooltip__hint {
    order: 3;
  }
}

#user_avatar {
  display: none;
}

.field-user_avatar .btn-file {
  display: none !important;
}

.master--national-expert .user_photo {
  overflow: visible;
}

.master--national-expert .ic--wreath {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50px);
}

.page_content__right .master--national-expert .ic--wreath {
  width: 70px;
  height: 30px;
  transform: translate(-50%, -15px);
}

@media (max-width: 639px) {
  .master--national-expert .user_photo {
    margin-bottom: 20px;
  }
  .master--national-expert .ic--wreath {
    width: 115px;
    height: 70px;
    transform: translate(-50%, -37px);
  }
}

/** svg fav @ovverride */
svg.ic.ic--fav-tag {
  height: 23px;
  width: 23px;
}

/** mobile @ovveride */
@media screen and (max-width: 420px) {
  .master.desktop {
    display: none;
    visibility: hidden;
  }
  .master.mobile {
    display: block;
    visibility: visible;
    padding: 0 20px;
  }
  .master.mobile .fav_link {
    top: 20px;
  }
  .master.mobile .fav_link svg.ic.ic--fav-tag {
    height: 18px;
    width: 18px;
  }
  .master.mobile .master_info_image_and_status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }
  .master.mobile .master_info__header {
    align-items: flex-start;
    margin: 0;
    margin-left: 15px;
  }
  .master.mobile .master_info__name {
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
  }
  .master.mobile .master_info__name .icon_hover svg.ic.ic--king {
    height: 14px;
    width: 14px;
    margin: 0;
  }
  .master.mobile .master_info__row {
    justify-content: flex-start;
  }
  .master_descr {
    display: none;
    visibility: hidden;
  }
}

.form_cell--long .petr_tooltip,
.form_cell--label--budget .petr_tooltip {
  margin-bottom: 6px;
}

.master_feedback__answer {
  border: none;
  background-color: transparent;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
  padding-right: 0;
  margin-left: auto;
  color: #a5a8ad;
  cursor: pointer;
  transition: color 0.3s ease;
}

.master_feedback__answer svg {
  height: 15px;
  width: 15px;
  fill: #a5a8ad;
  margin-left: 6px;
}

.master_feedback__answer:hover {
  color: #262626;
}

.master_feedback__answer:hover svg {
  fill: #262626;
}

.master_feedback__answer span {
  display: none;
}

@media (min-width: 600px) {
  .master_feedback__answer {
    padding-right: 5px;
  }
  .master_feedback__answer span {
    display: inline;
  }
}

.page_content__right--chat .master_stats__inner .petr_tooltip {
  margin-bottom: 23px;
}

.page_content__right--chat .master_stats__inner .button:not(:first-of-type) {
  margin-top: 14px;
}

.page_content__right--chat .master_stats__inner .ic--lock-red {
  margin-right: 4px;
}

@media (min-width: 600px) and (max-width: 899px) {
  .page_content__right--chat .master_stats__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .page_content__right--chat .master_stats__inner .master_stats_section:first-child {
    width: 39.7%;
    margin-bottom: 0;
  }
  .page_content__right--chat .master_stats__inner .master_stats_section:first-child .petr_tooltip {
    margin-bottom: 27px;
  }
  .page_content__right--chat .master_stats__inner .master_stats_section:last-child {
    width: 48.3%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .page_content__right--chat .master_stats__inner .master_stats_section:last-child .petr_tooltip {
    margin-bottom: 16px;
    justify-content: flex-end;
  }
  .page_content__right--chat .master_stats__inner .master_stats_section__title {
    letter-spacing: 2.8px;
  }
  .page_content__right--chat .master_stats__inner .master_stats_orders {
    padding-top: 5px;
    margin-bottom: 17px;
  }
  .page_content__right--chat .master_stats__inner .master_stats_orders__row {
    margin-bottom: 4px;
  }
}

@media (min-width: 900px) {
  .page_content__right--chat .master_stats {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0;
  }
  .page_content__right--chat .master_stats__inner .master_stats_section__rating {
    margin-right: 18px;
  }
  .page_content__right--chat .master_stats__inner .petr_tooltip {
    margin-bottom: 32px;
  }
  .page_content__right--chat .master_stats__inner .master_stats_section {
    margin-bottom: 28px;
  }
}

@media (min-width: 1280px) {
  .page_content__right--chat .master_stats {
    padding-left: 40px;
    padding-right: 40px;
  }
  .page_content__right--chat .master_stats__inner .master_stats_section__rating {
    margin-right: 0;
  }
}

.page_content--chat {
  align-items: flex-start;
  height: 100%;
}

.page_content--chat .breadcrumbs {
  display: flex;
  visibility: unset;
  margin-bottom: 6px;
}

.page_content--chat .page_title:not(.page_title--listing) > .breadcrumbs {
  padding-left: 20px;
}

.page_content--chat .page_title {
  padding-top: 18px;
  padding-bottom: 20px;
}

@media (max-width: 599px) {
  .page_content--chat .page_title__text {
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin-left: 20px;
  }
}

@media (min-width: 600px) {
  .page_content--chat .page_title {
    padding-top: 8px;
    padding-bottom: 31px;
  }
  .page_content--chat .page_title:not(.page_title--listing) > .breadcrumbs {
    padding-left: 28px;
  }
  .page_content--chat .page_title__text {
    margin-left: 28px;
  }
}

@media (min-width: 900px) {
  .page_content--chat {
    overflow-x: hidden;
  }
  .page_content--chat .page_title:not(.page_title--listing) > .breadcrumbs {
    padding-left: 0;
  }
  .page_content--chat .page_title__text {
    margin-left: 0;
  }
}

@media (min-width: 1280px) {
  .page_content--chat .page_title {
    margin-top: 25px;
    padding-bottom: 25px;
  }
}

.page_content__left--chat .listing__paginator--v2 {
  padding-top: 24px;
  padding-bottom: 25px;
}

@media (min-width: 1280px) {
  .page_content__left--chat .listing__paginator--v2 {
    padding-top: 20px;
  }
}

@media (min-width: 900px) {
  .page_content__left--chat .listing--v2 {
    padding-right: 25px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1280px) {
  .page_content__left--chat .listing--v2 {
    padding-bottom: 5px;
  }
}

@media (min-width: 900px) {
  .lt_2col .page_content--listing.page_content--chat {
    min-height: unset;
    min-height: 800px;
  }
}

.block-relative {
  position: relative;
}

.btn_pro {
  width: 100%;
  height: 42px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #FFD24D;
}

.info-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-item:hover .info-absolute {
  display: block;
}

.info-absolute {
  margin-top: 10px;
  width: 420px;
  height: 106px;
  position: absolute;
  top: 95%;
  right: -23px;
  background: #FFFFFF;
  border: 1px solid #F1F3F5;
  box-sizing: border-box;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  z-index: 2;
  display: none;
}

.info-absolute:before, .info-absolute:after {
  content: "";
  position: absolute;
  top: -14px;
  right: 15px;
  border-bottom: 15px solid #FFFFFF;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.info-absolute:before {
  top: -14px;
  border-bottom-color: #F1F3F5;
}

.btn_pro_yes {
  background: #FFD24D;
}

.btn_pro_yes:hover {
  border: 1px solid #ffd966;
  background: #ffd966;
  color: #263238;
}

@media (max-width: 767px) {
  .info-item {
    position: static;
  }
  .btn_pro {
    position: relative;
  }
  .info-absolute {
    width: 100%;
    height: auto;
    right: 0;
  }
}

.btn_pro_no {
  background: transparent;
}

.btn_pro_no:hover {
  border: 1px solid #ffd966;
  color: #ffd966;
  color: #263238;
}

.btn_pro_no:hover .info--absolute {
  color: #263238;
}

.tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
  font-size: 11px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  letter-spacing: 1.8px;
  color: #262626;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 640px) {
  .tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
    font-size: 12px;
    letter-spacing: 2px;
  }
}

.teaser {
  border-bottom: 1px solid #edeef0;
  position: relative;
  background: linear-gradient(to bottom, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 0.82) 51%, #f3f4f4 62%, #edeef0 80%);
}

@media (max-width: 639px) {
  .teaser {
    border-top: none;
  }
}

.teaser__container {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  height: 620px;
}

@media (max-width: 639px) {
  .teaser__container {
    height: 290px;
  }
}

.teaser__col {
  width: 967px;
  margin: 0 auto;
}

@media (max-width: 639px) {
  .teaser__col {
    padding-left: 20px;
    padding-top: 36px;
    width: auto;
  }
}

.teaser__slogan {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 44px;
  color: #262626;
  letter-spacing: 0;
  line-height: 60px;
  padding-top: 153px;
}

@media (max-width: 639px) {
  .teaser__slogan {
    font-size: 24px;
    color: #262626;
    letter-spacing: 0;
    line-height: 30px;
    padding-top: 0;
    position: relative;
    max-width: 100px;
    z-index: 2;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .teaser__slogan {
    padding-left: 20px;
    max-width: 250px;
  }
}

.teaser__title {
  font-family: "OpenSans-ExtraboldItalic", "Arial", sans-serif;
  font-size: 160px;
  color: #ffd24d;
  letter-spacing: 4px;
  line-height: 155px;
  text-transform: uppercase;
  position: relative;
  padding-top: 105px;
  z-index: 2;
}

@media (max-width: 639px) {
  .teaser__title {
    width: 250px;
    font-family: "OpenSans-ExtraboldItalic", "Arial", sans-serif;
    font-size: 48px;
    color: #ffd24d;
    letter-spacing: 1.2px;
    line-height: 50px;
  }
}

.teaser__read_more {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 78px;
  text-transform: uppercase;
  position: relative;
}

@media (max-width: 639px) {
  .teaser__read_more {
    padding-top: 20px;
    width: 200px;
    padding-bottom: 25px;
    z-index: 2;
  }
  .teaser__read_more:before {
    content: '';
    display: none;
    left: 0;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .teaser__read_more {
    padding-left: 20px;
    max-width: 200px;
  }
  .teaser__read_more:before {
    content: '';
    display: none;
    left: 0;
  }
}

.teaser__img {
  width: 580px;
  height: 580px;
  background: url(../images/rating-head.png) no-repeat;
  background-position: center bottom;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 38%;
  z-index: 1;
}

@media (max-width: 639px) {
  .teaser__img {
    width: 190px;
    height: 259px;
    background: url(../images/rating-head-600.png) no-repeat;
    background-position: center bottom;
    background-size: contain;
    position: absolute;
    left: auto;
    right: 0;
    z-index: 1;
  }
}

.teaser__rating {
  font-family: "OpenSans-Extrabold", "Arial", sans-serif;
  font-size: 228px;
  color: #ffd24d;
  letter-spacing: -10px;
  position: absolute;
  right: 103px;
  top: 124px;
  display: block;
  z-index: 0;
  line-height: 1;
}

.teaser__rating span {
  font-size: 114px;
  position: relative;
  top: -74px;
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .teaser__rating {
    font-size: 185px;
    right: 40px;
  }
  .teaser__rating span {
    font-size: 90px;
  }
}

@media (max-width: 639px) {
  .teaser__rating {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .teaser__rating {
    display: none;
  }
}

.points_info__top {
  padding-top: 115px;
  text-align: center;
}

@media (max-width: 639px) {
  .points_info__top {
    padding-top: 55px;
  }
}

.points_info__bottom {
  text-align: center;
  border-top: 1px solid #edeef0;
  padding-top: 120px;
  padding-bottom: 100px;
}

@media (max-width: 639px) {
  .points_info__bottom {
    padding-bottom: 45px;
    border-top: none;
    padding-top: 0;
  }
}

.section__container_inner {
  max-width: 960px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding: 0 20px;
}

.section__sub_title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 25px;
}

@media (max-width: 639px) {
  .section__sub_title {
    font-size: 11px;
  }
  .section__sub_title span {
    display: none;
  }
}

.section__title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 32px;
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 50px;
  line-height: 30px;
}

@media (max-width: 639px) {
  .section__title {
    font-family: "OpenSans-Bold", "Arial", sans-serif;
    font-size: 20px;
    color: #262626;
    letter-spacing: 0;
    margin-bottom: 26px;
  }
}

.section_text {
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
}

.section_text i {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  color: #262626;
  font-size: 20px;
  letter-spacing: 0;
  font-style: normal;
}

.section_text.is_top_text {
  margin-bottom: 51px;
}

.section_text.is_sort_section {
  margin-bottom: 79px;
}

.section_text--bold {
  font-weight: bold;
}

.section_text--left {
  margin-bottom: 10px;
}

@media (max-width: 639px) {
  .section_text {
    font-family: "Open-Sans", "Arial", sans-serif;
    font-size: 14px;
    color: #262626;
    letter-spacing: 0;
    line-height: 25px;
    padding: 0 20px;
  }
  .section_text.is_sort_section {
    margin-bottom: 56px;
  }
  .section_text i {
    font-family: "OpenSans-Bold", "Arial", sans-serif;
    color: #262626;
    font-size: 20px;
    letter-spacing: 0;
    font-style: normal;
  }
}

.points_info_item__title {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  margin-bottom: 22px;
}

@media (max-width: 639px) {
  .points_info_item__title {
    font-size: 14px;
  }
}

.points_info_item__data {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #919599;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 20px;
}

.points_info_item__score {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 2px;
  line-height: 20px;
}

.points_info_items__middle {
  display: flex;
  justify-content: space-between;
}

.points_info_items__col.is_middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.points_info_items__col.is_aside .points_info_item:first-child {
  padding-bottom: 54px;
}

.points_info_items {
  margin-bottom: 115px;
}

.points_info_items__top {
  display: flex;
  justify-content: center;
}

.points_info_items__top .points_info_item {
  margin-left: 23px;
  width: 49.9%;
  text-align: center;
}

.points_info_items__bottom .points_info_item {
  margin-left: 27px;
}

.points_info_item__middle_img {
  width: 240px;
  height: 266px;
  background: url(../images/ic_score_10.png) no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 639px) {
  .points_info_item__middle_img {
    width: 160px;
    height: 180px;
    background: url(../images/hexagon.png) no-repeat;
    margin-top: 63px;
    margin-left: 0 !important;
  }
}

.points_info_item__middle_number {
  font-family: "OpenSans-Extrabold", "Arial", sans-serif;
  font-size: 96px;
  line-height: normal;
  color: #ffffff;
  text-indent: -7px;
}

.points_info_item__number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ffd24d;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 22px;
}

@media (max-width: 639px) {
  .points_info_item__number {
    margin-left: auto;
    margin-right: auto;
  }
}

.points_info_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 639px) {
  .points_info_item {
    display: none;
    margin-bottom: 60px;
  }
}

.points_info__max_text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #919599;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 30px;
  margin-top: 70px;
}

.points_info_item__img {
  margin-bottom: 30px;
}

.points_info__max_value {
  font-family: "OpenSans-Extrabold", "Arial", sans-serif;
  font-size: 128px;
  color: #ffd24d;
}

.buy_items {
  position: relative;
  border-top: 1px solid #edeef0;
  background: url(../images/rating-bonuses.png) no-repeat;
  background-size: contain;
  background-position: bottom -165px left;
}

.buy_items .section__container_inner {
  padding: 63px 0 92px;
}

@media (max-width: 1920px) {
  .buy_items {
    background-position: bottom -165px left calc(50% - 500px);
  }
}

@media (max-width: 639px) {
  .buy_items {
    background: url(../images/rating-bonuses-600.png) no-repeat;
    background-position: bottom left;
    border-top: none;
  }
  .buy_items .section__container_inner {
    padding: 0 0 100px;
  }
  .buy_items:after {
    content: '';
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .buy_items:after {
    content: '';
    display: none;
  }
}

.sort_how_to {
  padding-top: 117px;
  padding-bottom: 140px;
  border-top: 1px solid #edeef0;
}

@media (max-width: 639px) {
  .sort_how_to {
    border-top: none;
    padding-top: 59px;
    padding-bottom: 59px;
  }
  .sort_how_to .section__sub_title {
    margin-bottom: 13px;
  }
}

.sort_how_to__bottom_container {
  display: flex;
  justify-content: space-between;
  margin-top: 59px;
}

@media (max-width: 639px) {
  .sort_how_to__bottom_container {
    margin-top: 40px;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .sort_how_to__bottom_container {
    padding: 0 20px;
  }
}

.sort_how_to__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 639px) {
  .sort_how_to__item {
    padding-bottom: 59px;
  }
}

.sort_how_to__title {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 25px;
}

@media (max-width: 639px) {
  .sort_how_to__title {
    font-size: 14px;
  }
}

.sort_how_to__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #919599;
  letter-spacing: 0;
  line-height: 20px;
}

.sort_how_to__number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ffd24d;
  line-height: 32px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  margin-bottom: 23px;
}

@media (max-width: 639px) {
  .sort_how_to__number {
    margin-left: auto;
    margin-right: auto;
  }
}

.section__part_title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 20px;
  color: #262626;
  letter-spacing: 0;
}

@media (max-width: 639px) {
  .section__part_title {
    font-size: 16px;
  }
  .section__part_title.is_value {
    margin-top: 88px;
    font-size: 16px;
  }
}

.section__inner {
  text-align: center;
  max-width: 960px;
  margin: 0 auto;
}

.new_rating_count {
  padding-top: 100px;
  padding-bottom: 116px;
  background: #f5f6f7;
}

@media (max-width: 639px) {
  .new_rating_count {
    padding-top: 52px;
    padding-bottom: 59px;
  }
}

.points_info_order__container {
  display: flex;
  justify-content: space-between;
  margin: 71px 0 69px;
}

@media (min-width: 640px) and (max-width: 999px) {
  .points_info_order__container {
    padding: 0 20px;
  }
}

.points_info_order__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 16px;
}

.points_info_order__item:last-child:after {
  display: none;
  width: 164px;
  height: 2px;
  background: #edeef0;
  position: absolute;
}

.points_info_order__item:after {
  content: '';
  width: 164px;
  height: 2px;
  background: #edeef0;
  position: absolute;
  right: -167px;
  top: 14px;
}

@media (min-width: 640px) and (max-width: 999px) {
  .points_info_order__item:after {
    display: none;
  }
}

@media (max-width: 639px) {
  .points_info_order__item {
    margin-bottom: 58px;
    left: 0;
  }
  .points_info_order__item:after {
    display: none;
  }
}

.points_info_order__number {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 32px;
  background: #ffd24d;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  margin-bottom: 17px;
}

@media (max-width: 639px) {
  .points_info_order__number {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 639px) {
  .points_info_items__mobile .points_info_item {
    display: block;
    margin-bottom: 61px;
  }
}

.points_info_order__title {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 17px;
}

@media (max-width: 639px) {
  .points_info_order__title {
    font-size: 14px;
  }
}

.points_info_order__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #919599;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 20px;
}

.points_info_order__text.is_not_first {
  margin-bottom: 40px;
}

.points_info_order__score {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
}

.new_rating_count__text {
  max-width: 640px;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 24px;
  color: #262626;
  letter-spacing: 0;
  line-height: 40px;
  margin: 0 auto 115px;
}

@media (max-width: 639px) {
  .new_rating_count__text {
    padding: 0 20px;
    font-family: "Open-Sans", "Arial", sans-serif;
    font-size: 14px;
    color: #262626;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 73px;
  }
}

.new_rating_count__title {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  margin-bottom: 32px;
}

@media (max-width: 639px) {
  .new_rating_count__title {
    margin-bottom: 21px;
    font-size: 14px;
  }
}

.new_rating_count__teaser_text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #919599;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 40px;
}

@media (max-width: 639px) {
  .new_rating_count__teaser_text {
    margin-bottom: 34px;
  }
}

.new_rating_count__btn {
  padding: 0 76px;
}

.grade__wrapper {
  max-width: 480px;
  margin: 44px auto 0;
}

@media (max-width: 639px) {
  .grade__wrapper {
    padding: 0 20px;
    margin: 32px auto 0;
  }
}

.grade_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 38px;
}

.grade_table .grade_table__title {
  border-bottom: none;
}

.grade_table .grade_table__value {
  border-bottom: none;
}

.grade_table .with_border td {
  border-bottom: solid 1px #edeef0;
  padding-bottom: 20px;
}

.grade_table .with_border + tr td {
  padding-top: 18px;
}

.grade_table_stars__list {
  margin-right: 20px;
  width: 90px;
  height: 18px;
  position: relative;
  display: flex;
}

.grade_table__title {
  font-size: 12px;
  text-align: left;
  color: #a5a8ad;
  font-weight: normal;
  border-bottom: solid 1px #edeef0;
  padding: 10px 0 16px;
}

.grade_table__title:not(:first-child) {
  text-align: right;
}

.grade_table__title:last-child {
  width: 160px;
}

@media (max-width: 639px) {
  .grade_table__title.grade_table__value--grade {
    display: none;
  }
}

.grade_table__value {
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: #262626;
  border-bottom: solid 1px #edeef0;
}

.grade_table__value:last-child {
  text-align: right;
  font-weight: bold;
}

@media (min-width: 640px) {
  .grade_table__value {
    line-height: 3.57;
  }
}

.grade_table__value.grade_table__value--grade {
  text-align: right;
}

@media (max-width: 639px) {
  .grade_table__value.grade_table__value--grade {
    display: none;
  }
}

.grade_table__value.grade_table__value--ratio span {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 20px;
  color: #262626;
  letter-spacing: 0;
  line-height: 50px;
  position: relative;
  display: block;
  text-align: right;
}

.grade_table__value.grade_table__value--ratio span:before {
  content: 'X';
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 0;
  padding-right: 20px;
  position: absolute;
  top: 1px;
  left: 10px;
}

.grade_categories {
  display: flex;
  margin: 76px 0;
  justify-content: center;
}

@media (min-width: 640px) and (max-width: 999px) {
  .grade_categories {
    padding: 0 20px;
  }
}

@media (max-width: 639px) {
  .grade_categories {
    flex-wrap: wrap;
    min-width: 280px;
    justify-content: flex-start;
    margin: 53px auto;
    padding: 0 20px;
  }
}

.grade_categories__item {
  width: 160px;
  text-align: center;
  padding: 27px 0;
}

@media (max-width: 639px) {
  .grade_categories__item {
    width: 50%;
  }
}

.grade_categories__item.is_no_card {
  background-color: #ffffff;
}

.grade_categories__item.is_specialist {
  background-color: #f5f6f7;
}

.grade_categories__item.is_master {
  background-color: #edeef0;
}

.grade_categories__item.is_profi {
  background-color: #e8e8e8;
}

.grade_categories__item.is_expert {
  background-color: #e3e3e3;
}

.grade_categories__item.is_superexpert {
  background-color: #ffd24d;
}

.grade_categories__type {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.grade_categories__budget {
  margin-bottom: 31px;
}

.grade_categories__ratio_percentage {
  margin-bottom: 25px;
}

.grade_categories__ratio_value {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 20px;
  color: #262626;
  letter-spacing: 0;
}

.grade_categories__ratio_value span:first-child {
  padding-right: 5px;
  position: relative;
  top: -1px;
  font-size: 14px;
}

.tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
  font-size: 11px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  letter-spacing: 1.8px;
  color: #262626;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 640px) {
  .tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
    font-size: 12px;
    letter-spacing: 2px;
  }
}

.lt_2col .page_content__left {
  padding-bottom: 14px;
}

.lt_2col .page_content__left .master {
  margin-top: 44px;
}

@media (max-width: 639px) {
  .lt_2col .page_content__left .master {
    margin-top: 0;
  }
}

@media (max-width: 639px) {
  .lt_2col .page_content__right {
    display: block;
    padding: 0;
    padding-bottom: 34px;
  }
  .lt_2col .master_stats.is_fixed {
    position: static;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .lt_2col .page_content__right {
    display: block;
    padding: 0;
    padding-bottom: 34px;
  }
}

@media (min-width: 1280px) {
  .lt_2col .page_content__right {
    min-width: 360px;
    max-width: 360px;
  }
  .lt_2col .page_content__left {
    width: 70%;
  }
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .lt_2col .page_content__right {
    min-width: 360px;
    max-width: 360px;
  }
  .lt_2col .page_content__left {
    width: 62.5%;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .lt_2col .page_content__right {
    padding: 26px 20px 3px;
  }
  .lt_2col .page_content__right .profile_info {
    width: 100%;
    margin: 0;
  }
}

.lt_2col .table_styled__value--rating {
  width: 50px;
}

@media (max-width: 639px) {
  .lt_2col .table_styled__value--rating {
    width: 20%;
    padding-right: 10px;
  }
}

.lt_2col .table_styled__value--subscribe {
  width: 100px;
  text-align: center;
}

.lt_2col .table_styled tr {
  cursor: default;
}

.lt_2col .table_styled tr.is_done {
  background: #f5f6f7;
}

.profile {
  margin: 50px 59px 27px 70px;
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .profile {
    margin-right: 0;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .profile {
    margin-left: 30px;
  }
}

@media (max-width: 639px) {
  .profile {
    margin: 0;
  }
}

.profile__title {
  font-size: 36px;
  color: #262626;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: bold;
  margin: 20px 0 0 78px;
}

@media (min-width: 640px) and (max-width: 999px) {
  .profile__title {
    margin-left: 41px;
    font-size: 24px;
  }
}

@media (max-width: 639px) {
  .profile__title {
    font-family: "OpenSans-Bold", "Arial", sans-serif;
    font-size: 18px;
    color: #262626;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 25px;
  }
}

.text-center {
  text-align: center;
}

.tabs.is_profile {
  padding: 0;
}

.tabs.is_profile .tabs_links {
  margin-bottom: 34px;
}

@media (max-width: 639px) {
  .tabs.is_profile .tabs_links {
    margin-bottom: 26px;
    margin-top: 20px;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .tabs.is_profile .tabs_links {
    margin-bottom: 26px;
    margin-top: 20px;
  }
}

@media (max-width: 639px) {
  .tabs.is_profile {
    padding: 0 10px;
  }
}

.role_switcher {
  display: flex;
  align-items: center;
}

.card_info {
  display: flex;
  align-items: center;
  line-height: 42px;
  justify-content: flex-start;
  height: 44px;
}

@media (min-width: 640px) and (max-width: 999px) {
  .card_info {
    position: static;
  }
}

@media (max-width: 639px) {
  .card_info {
    left: 0;
    justify-content: space-between;
  }
}

.card_info__item {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0 38px 0 0;
}

.card_info__item:last-of-type {
  padding: 0;
}

@media (max-width: 639px) {
  .card_info__item {
    padding: 0;
  }
}

.page_content__right.is_profile {
  background-color: #ffffff;
}

.profile_info {
  width: 320px;
  margin: 0 40px 0 auto;
  background-color: #f5f6f7;
}

@media (max-width: 639px) {
  .profile_info {
    width: auto;
    margin: 0;
  }
}

.profile_info.is_fixed {
  top: 0;
  position: fixed;
  z-index: 100;
}

@media (min-width: 640px) and (max-width: 999px) {
  .profile_info.is_fixed {
    position: static;
  }
}

@media (max-width: 639px) {
  .profile_info.is_fixed {
    position: static;
  }
}

.profile_info.is_fixed .user_info__inner {
  position: relative;
  left: -64px;
}

@media (min-width: 640px) and (max-width: 999px) {
  .profile_info.is_fixed .user_info__inner {
    position: static;
  }
}

@media (max-width: 639px) {
  .profile_info.is_fixed .user_info__inner {
    position: static;
  }
}

.profile_info__inner {
  padding: 33px 40px 34px 37px;
}

.role_switcher__ic {
  padding: 0 16px;
  margin-bottom: 20px;
}

.role_switcher__ic:first-child {
  padding-left: 0;
}

@media (min-width: 640px) {
  .role_switcher__ic {
    margin-bottom: 0;
  }
}

.role_switcher__role:last-child {
  color: #a5a8ad;
}

.role_switcher__radio_input {
  display: none;
}

.role_switcher__radio_label {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 38px;
  cursor: pointer;
}

@media (min-width: 640px) {
  .role_switcher__radio_label {
    align-items: center;
    flex-direction: row;
  }
}

.user_info {
  margin-bottom: 32px;
}

.user_info__inner {
  display: flex;
}

.user_info__data {
  max-width: 240px;
}

.user_info__photo {
  width: 54px;
  height: 54px;
  box-shadow: 0 1px 1px #e3e5e8;
  flex-shrink: 0;
  min-width: inherit;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  align-self: flex-end;
  margin-right: 13px;
}

.user_info__photo .user_info__img {
  max-width: 100%;
  border-radius: 50%;
}

.user_info__grade {
  margin-bottom: 7px;
}

.user_info__name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.39;
  margin-bottom: 0;
}

.status_bar {
  display: flex;
  margin-top: 22px;
}

.status_bar__scale {
  background-color: #ffd24d;
  height: 6px;
  border-radius: 2px;
}

.status_bar__container {
  width: 200px;
  height: 6px;
  background: #edeef0;
  border-radius: 2px;
}

.status_bar__in_percents_value {
  position: relative;
  top: -9px;
  left: 20px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 0;
}

.profile_efficiency__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 24px;
}

.profile_content {
  margin-top: 34px;
}

.profile_content__marker {
  width: 10px;
  height: 10px;
  background-color: #a5a8ad;
  border-radius: 50%;
  position: relative;
}

.profile_content__marker:after {
  content: '';
  width: 6px;
  height: 6px;
  background-color: #f5f6f7;
  position: absolute;
  border-radius: 50%;
  top: 2px;
  left: 2px;
}

.profile_content__item {
  display: flex;
  margin-bottom: 20px;
}

.profile_content__item:last-child {
  margin-bottom: 0;
}

.profile_content__item.is_active .profile_content__marker {
  width: 24px;
  height: 24px;
  background: url(/media/images/ic_is_done.svg);
  position: relative;
  left: -7px;
  top: -7px;
}

.profile_content__item.is_active .profile_content__marker:after {
  content: '';
  opacity: 0;
}

.profile_content__item.is_active .profile_content__texts {
  margin-left: 10px;
}

.profile_content__texts {
  margin-left: 20px;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #000;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  top: -6px;
}

.profile_content__point {
  color: #a5a8ad;
}

.delete_item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 639px) {
  .delete_item {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .delete_item .ic--delete {
    padding: 0;
    left: 0;
    top: -35px;
  }
}

.ic--master {
  fill: #e3e5e8;
}

.ic--company {
  fill: #e3e5e8;
}

.role_switcher__radio_input:checked + label .ic--master {
  fill: #ffd24d;
  transition: all .2s ease;
}

.role_switcher__radio_input:checked + label .ic--company {
  fill: #ffd24d;
  transition: all .2s ease;
}

.role_switcher__radio_input:checked + label .role_switcher__role {
  color: #000;
  transition: all .2s ease;
}

.user_photo.is_profile {
  position: relative;
  cursor: pointer;
  height: 160px;
  width: 160px;
  margin-right: 25px;
  background-size: cover;
}

.user_photo.is_profile .file-drop-zone {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  min-height: unset !important;
}

@media (max-width: 639px) {
  .user_photo.is_profile {
    width: 100px;
    height: 100px;
    max-width: none;
  }
}

.user_photo.is_profile .user_photo__img {
  height: 160px;
  width: 160px;
  position: relative;
}

@media (max-width: 639px) {
  .user_photo.is_profile .user_photo__img {
    width: 100px;
    height: 100px;
    max-width: none;
  }
}

.user_photo.is_profile:hover:after {
  content: '';
  width: 160px;
  height: 160px;
  background: #ffd24d;
  position: absolute;
  border-radius: 50%;
  opacity: .8;
  top: 0;
  right: 0;
}

@media (max-width: 639px) {
  .user_photo.is_profile:hover:after {
    width: 100px;
    height: 100px;
  }
}

.user_photo.is_profile:hover:before {
  content: '';
  width: 56px;
  height: 41px;
  position: absolute;
  z-index: 1;
  background: url("/media/images/photo.svg") no-repeat;
  background-size: cover;
  top: 62px;
  left: 53px;
}

@media (max-width: 639px) {
  .user_photo.is_profile:hover:before {
    top: 36px;
    left: 31px;
    width: 37px;
    height: 26px;
  }
}

.img_profile_placeholder {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 2px solid #edeef0;
  box-sizing: border-box;
}

.img_profile_placeholder:before {
  content: '';
  width: 56px;
  height: 41px;
  position: absolute;
  background: url("/media/images/photo_placeholder.svg");
  left: 53px;
  top: 60px;
}

.user_photo_upload {
  display: inline-flex;
  align-items: center;
}

.user_photo_upload:hover .user_photo__text {
  display: flex;
}

@media (max-width: 639px) {
  .user_photo_upload {
    display: block;
  }
  .user_photo_upload:hover .user_photo__text {
    display: none;
  }
}

.user_photo__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 24px;
  display: none;
}

.user_photo__text.is_portfolio {
  display: block;
  left: 0;
  margin-top: 10px;
}

.ic--photo_placeholder {
  stroke: #000;
  fill: #fff;
  stroke-width: 2px;
}

.portfolio_controls__file_input,
.review_controls__file_input,
.feedback_controls__file_input {
  display: none !important;
}

.modal_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  z-index: 201;
}

.confirm_popup {
  width: 577px;
  position: fixed;
  left: 22%;
  background: #ffffff;
  text-align: center;
  z-index: 1000;
  padding: 42px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: none;
}

.confirm_popup .btn--reset {
  background: #e3e5e8;
  color: #262626;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
}

.confirm_popup .btn--reset:hover {
  opacity: 0.9;
}

.confirm_popup .btn--submit {
  margin-right: 18px;
}

@media (max-width: 639px) {
  .confirm_popup {
    width: 280px;
    left: 50%;
    top: 50% !important;
    transform: translate(-50%, -50%);
    padding: 18px 0;
  }
  .confirm_popup .form__btns {
    padding: 0 10px;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .confirm_popup .form__btns {
    padding: 0 60px;
  }
}

.confirm_popup__qstn {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 23px;
}

@media (max-width: 639px) {
  .confirm_popup__qstn {
    text-align: left;
    margin: 0 22px 0 18px;
    margin-bottom: 19px;
  }
}

.confirm_popup__text {
  text-align: left;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 76px;
  padding-right: 53px;
  margin-bottom: 33px;
}

@media (max-width: 639px) {
  .confirm_popup__text {
    margin: 0 22px 0 18px;
    padding: 0;
    margin-bottom: 28px;
  }
}

.star_cover {
  background: rgba(255, 210, 77, 0.15);
  z-index: 11;
  height: 18px;
  position: absolute;
  width: 90px;
}

@media (max-width: 639px) {
  .portfolio_items.is_profile {
    margin: 0;
    padding: 0;
  }
}

.portfolio_items.is_profile .portfolio_item {
  margin-right: 40px;
}

@media (max-width: 639px) {
  .portfolio_items.is_profile .portfolio_item {
    margin: 0;
  }
}

.portfolio_controls__label.is_load_photo {
  display: none;
}

@media (max-width: 639px) {
  .portfolio_controls__label.is_load_photo {
    display: block;
  }
  .portfolio_controls__label.is_load_photo .btn--reset {
    width: 100%;
  }
}

.portfolio_controls {
  padding: 0 20px 28px;
}

.portfolio_controls .section_title {
  margin-bottom: 37px;
}

@media (max-width: 639px) {
  .portfolio_controls {
    margin-top: 32px;
    padding: 0;
  }
}

@media (max-width: 639px) {
  .portfolio_controls__form {
    padding: 0;
  }
}

.portfolio_controls__form .form_row {
  margin-bottom: 20px;
}

@media (max-width: 999px) {
  .portfolio_controls__form .form_row {
    flex-direction: column;
  }
}

.portfolio_controls__form .form__btns {
  text-align: left;
}

@media (max-width: 639px) {
  .portfolio_controls__form .form__btns {
    padding-bottom: 50px;
  }
}

.portfolio_controls_imgs {
  margin: 33px -20px 0 0;
}

.portfolio_controls_imgs__container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 639px) {
  .portfolio_controls_imgs__container {
    justify-content: center;
  }
}

.portfolio_controls_imgs__item {
  margin: 0 20px 20px 0;
  position: relative;
  display: block;
}

.portfolio_controls_imgs__item:hover:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: #f5f6f7;
  z-index: 1;
  left: 0;
  opacity: .8;
  cursor: pointer;
}

.portfolio_controls_imgs__item:hover:before {
  content: '';
  width: 29px;
  height: 29px;
  top: 36%;
  left: 56px;
  position: absolute;
  background: url(/media/images/del_img.svg);
  z-index: 2;
}

.portfolio_controls_imgs__item img {
  width: 140px;
}

.portfolio_controls_imgs_error__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #fe253c;
  letter-spacing: 0;
  line-height: 30px;
  padding-left: 9px;
}

.portfolio_controls_imgs_error:last-of-type {
  margin-bottom: 41px;
}

.portfolio_controls_imgs_error .ic--delete {
  fill: #fe253c;
  padding: 0;
  position: static;
}

.portfolio_controls_imgs_error.is_mobile {
  display: none;
}

@media (max-width: 639px) {
  .portfolio_controls_imgs_error.is_mobile {
    display: block;
    text-align: center;
    padding-right: 20px;
    padding-top: 36px;
  }
  .portfolio_controls_imgs_error.is_mobile .ic--mob_error {
    margin-bottom: 7px;
  }
  .portfolio_controls_imgs_error.is_mobile .form_error {
    margin-bottom: 29px;
  }
  .portfolio_controls_imgs_error.is_mobile .btn--reset {
    width: 100%;
  }
}

.portfolio_controls_imgs_error.is_desktop {
  display: block;
}

@media (max-width: 639px) {
  .portfolio_controls_imgs_error.is_desktop {
    display: none;
  }
}

@media (max-width: 639px) {
  .skills_table tbody tr:hover .table_styled__value {
    background: none;
  }
}

@media (max-width: 639px) {
  .skills_table .skills_table__value--name {
    flex: 0 0 80%;
    order: 0;
    flex-grow: 1;
  }
  .skills_table .skills_table__value--subscribe {
    width: 30px;
    order: 1;
    border-bottom: 0 none;
  }
  .skills_table .skills_table__value--close_spec {
    width: 30px;
    margin: 0 5px;
    position: relative;
    order: 2;
    right: 0;
    padding-right: 0;
  }
  .skills_table .skills_table__value--close_spec .delete_item {
    align-items: center;
  }
  .skills_table .skills_table__value--close_spec .delete_item .ic--delete {
    top: 5px;
  }
  .skills_table .skills_table__value--prices {
    padding: 0 10px;
    order: 3;
    border-bottom: 0 none;
  }
  .skills_table .skills_table__value--stars {
    order: 4;
  }
  .skills_table .skills_table__value--rating {
    order: 4;
  }
}

.spec_control {
  margin-top: 5px;
  position: relative;
  display: flex;
}

.spec_control:first-child {
  margin-top: 0;
}

.spec_control__label {
  max-height: 32px;
  display: flex;
  align-items: center;
  position: relative;
  color: #333;
  cursor: pointer;
}

.spec_control--active .spec_control__label {
  cursor: default;
}

.spec_control__icons {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 4px 3px 3px 4px;
  border: #e8e8e8 1px solid;
  background: #e8e8e8;
}

.spec_control--active .spec_control__icons:after {
  border-color: #ffd24d;
  background: #ffd24d;
}

.spec_control__icon_pencil {
  display: block;
}

.spec_control--active .spec_control__icon_pencil {
  display: none;
}

.spec_control__icon_check {
  display: none;
}

.spec_control--active .spec_control__icon_check {
  display: block;
}

.spec_control__prefix {
  width: 25px;
  margin-right: 8px;
}

.spec_control__input {
  padding-left: 10px;
  max-width: 110px;
  height: 32px;
  border-radius: 4px;
  cursor: default;
  border: #c6c7ce 1px solid;
  background: #f1f1f1;
}

.spec_control--active .spec_control__input {
  cursor: text;
  border-color: #ffd24d;
  background: #fff;
}

.section_title.is_company {
  display: none;
}

.profile_form.status_is_individual .js_is_company {
  display: none;
}

.profile_form.status_is_individual .js_is_master {
  display: flex;
}

.profile_form.status_is_company .js_is_company {
  display: flex;
}

.profile_form.status_is_company .js_is_master {
  display: none;
}

.profile_create_form.status_is_individual .js_is_company {
  display: none;
}

.profile_create_form.status_is_individual .js_is_master {
  display: inline-block;
}

.profile_create_form.status_is_company .js_is_company {
  display: inline-block;
}

.profile_create_form.status_is_company .js_is_master {
  display: none;
}

.change_status {
  display: flex;
  background-color: #f5f6f7;
  padding: 39px 87px 34px 40px;
  align-items: flex-end;
  max-width: 696px;
  flex-wrap: wrap;
}

@media (max-width: 639px) {
  .change_status {
    padding: 39px 20px;
    position: absolute;
    left: -20px;
    right: -20px;
  }
}

.change_status__inquiry {
  color: #262626;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 9px;
}

.change_status__controls {
  width: 185px;
  margin-top: 20px;
}

.change_status__controls .button {
  width: 100%;
  height: auto;
}

@media (max-width: 639px) {
  .change_status__controls {
    width: 100%;
  }
}

.change_status__msgs {
  margin-right: 64px;
}

.change_status__explanation {
  color: #a5a8ad;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  max-width: 309px;
}

.passport_data_apply {
  padding: 30px;
  background: #F5F6F7;
  border-radius: 10px;
  margin-bottom: 50px;
}

.passport_data_apply p {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 30px;
}

.passport_data_apply p.failed {
  display: none;
  visibility: hidden;
}

.passport_data_apply .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.passport_data_apply .title p {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #262626;
  margin: 0;
}

.passport_data_apply .title svg {
  margin-right: 5px;
}

.passport_data_apply .title svg.ic.ic--failed {
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.passport_data_apply .management {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.passport_data_apply .management a.hint {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  line-height: 30px;
  display: inline-block;
  align-items: center;
  color: #A5A8AD;
}

.passport_data_apply .management a.check_passport_data_button_js_open_modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.passport_data_apply .management a.btn--submit.check_passport_data_button {
  margin-right: 40px;
  margin-bottom: 0;
  text-align: center;
}

.passport_data_apply .management p.checked_successfull {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  margin-right: 40px;
  margin-bottom: 0;
}

.passport_data_apply .management .checking_in_progress {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #E8E9EC;
  border-radius: 21px;
  padding: 6px;
  padding-right: 20px;
  text-align: center;
  width: auto;
  height: auto;
  margin-right: 40px;
}

.passport_data_apply .management .checking_in_progress svg {
  margin-right: 10px;
}

.passport_data_apply .management .checking_in_progress span {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
}

@media (max-width: 639px) {
  .passport_data_apply {
    padding-bottom: 0;
  }
  .passport_data_apply .ic {
    height: 30px;
    width: 30px;
    margin-right: 10px !important;
  }
  .passport_data_apply a.btn--submit.check_passport_data_button {
    margin: 0 auto;
    margin-right: 0 !important;
  }
  .passport_data_apply .hint {
    display: block !important;
    text-align: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 30px;
  }
}

.passport_data_apply .pending,
.passport_data_apply .success,
.passport_data_apply .failed {
  display: none;
  visibility: hidden;
}

.passport_data_apply.pending .default {
  display: none;
  visibility: hidden;
}

.passport_data_apply.pending .pending {
  display: block;
  visibility: visible;
}

.passport_data_apply.success .default {
  display: none;
  visibility: hidden;
}

.passport_data_apply.success .success {
  display: block;
  visibility: visible;
}

.passport_data_apply.failed .default {
  display: none;
  visibility: hidden;
}

.passport_data_apply.failed .failed {
  display: block;
  visibility: visible;
}

.button.button__small.button--confirm.js_confirm_btn {
  height: 44px;
}

.subscribe_button {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  border: none;
  cursor: pointer;
}

.subscribe_button .ic {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subscribe_button .ic--subscribe-enabled {
  display: none;
}

.subscribe_button.is_subscribed {
  background-color: #ffd24d;
}

.subscribe_button.is_subscribed .ic--subscribe-disabled {
  display: none;
}

.subscribe_button.is_subscribed .ic--subscribe-enabled {
  display: inline-block;
}

.subscribe_button__checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 26px;
  visibility: hidden;
}

.subscribe_button__checkbox:checked ~ .ic--subscribe-disabled {
  display: none;
}

.subscribe_button__checkbox:checked ~ .ic--subscribe-enabled {
  display: inline-block;
}

.subscribe_button__checkbox:checked ~ .subscribe_button__bg {
  background-color: #ffd24d;
}

.subscribe_button__checkbox:checked:hover ~ .subscribe_hint {
  opacity: 1;
}

.subscribe_button__bg {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #edeef0;
}

.subscribe_hint {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  color: #ffffff;
  background-color: #555659;
  font-size: 11px;
  width: 146px;
  padding: 3px 5px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  margin-left: 16px;
  text-align: left;
  border-radius: 3px;
}

.subscribe_hint:before {
  content: "";
  background: url(../images/bg-subscribe-hint@2x.png);
  position: absolute;
  top: 0;
  left: -9px;
  height: 100%;
  bottom: 0;
  width: 10px;
  background-size: auto 100%;
}

.button {
  cursor: pointer;
}

.empty-album {
  background: url(../images/empty_album.png) no-repeat center center;
}

.parent_portfolio {
  min-height: 300px;
}

.portfolio__itm {
  cursor: pointer;
  width: 100%;
}

.portfolio__info {
  color: #A5A8AD;
  position: absolute;
  top: 80px;
  left: 7%;
}

.portfolio .portfolio__cont {
  margin-top: 200px;
  width: 100%;
}

.album_nav {
  display: flex;
}

.album_nav .button {
  display: inline-flex;
  position: relative;
}

.album_nav .button span {
  background: #fff;
  font-size: 16px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: #a5a8ad;
  display: inline-block;
  margin-top: 8px;
  margin-left: 25px;
  line-height: 27px;
}

.upload_album {
  margin-left: auto;
  display: flex;
}

.upload_album:before {
  content: '+';
  background: rgba(0, 0, 0, 0.1);
  font-size: 25px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: block;
  margin-top: 7px;
  margin-right: 7px;
  margin-left: -8px;
  line-height: 27px;
}

.albums__container {
  margin-top: 5%;
  min-height: 400px;
}

.albums__container .album {
  width: 50%;
}

@media (max-width: 596px) {
  .albums__container .album {
    width: 100%;
  }
}

@media (min-width: 900px) and (max-width: 1279px) {
  .albums__container .album {
    width: 100%;
  }
}

.albums__container.albums__container_preview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 321px) and (max-width: 619px) {
  .albums__container.albums__container_preview {
    justify-content: center;
  }
}

@media (min-width: 899px) and (max-width: 1279px) {
  .albums__container.albums__container_preview {
    justify-content: center;
  }
}

.album:hover .album_edit {
  display: flex;
}

.album:hover .img_wrap {
  filter: grayscale(65%);
}

.album_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  flex-grow: 1;
  text-align: center;
  position: relative;
  min-height: 360px;
}

.album_item .img_wrap {
  position: absolute;
  overflow: hidden;
  width: 100%;
  padding: 1%;
}

.album_item .img_wrap:nth-child(1) {
  top: 0;
  z-index: 10;
}

.album_item .img_wrap:nth-child(2) {
  opacity: 0.7;
  top: 20px;
  width: 95%;
  z-index: 8;
}

.album_item .img_wrap:nth-child(3) {
  opacity: 0.7;
  top: 35px;
  width: 90%;
  z-index: 7;
}

.album_item img {
  border-radius: 8px;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.album_title {
  font-family: 'Open Sans';
  font-size: 18px;
  color: #262626;
  margin-left: 3px;
}

.album_subtitle {
  font-size: 12px;
  font-family: 'Open Sans';
  color: #A5A8AD;
  margin-left: 3px;
}

.portfolio__itm__wrapper {
  height: 150%;
  width: 360px;
}

.album_edit {
  position: absolute;
  z-index: 11;
  width: 100%;
  display: none;
  padding: 15px;
}

.album_edit .btn_update {
  height: 25px;
  font-size: 12px;
  line-height: 0;
  padding-left: 25px;
  padding-right: 10px;
}

.album_edit .btn_update:before {
  content: '';
  display: inline-block;
  height: 20px;
  width: 20px;
  background: url(../images/pencil.svg) no-repeat;
  position: absolute;
  top: 21px;
  left: 25px;
}

.album_edit .btn_close {
  background: url(../images/close-new.svg) #fff no-repeat center center;
  border-radius: 50px;
  border: none;
  width: 0;
  height: 0;
  position: absolute;
  right: 50px;
  padding: 10px;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-size: contain;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.gallery-top {
  height: 80%;
  width: 100%;
}

.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-thumbs .swiper-slide {
  width: 20%;
  height: 100%;
  opacity: 0.4;
  background-size: cover;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

@media (max-width: 1024px) {
  .album_nav .button {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .album_nav .button {
    margin: 2%;
  }
  .album_nav .button span {
    margin-left: 10px;
  }
  .album_nav .upload_album {
    margin: unset;
    z-index: 200;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    height: 44px;
    justify-content: center;
  }
  .album_nav .upload_album:before {
    background: unset;
  }
  .empty-album {
    padding-bottom: 50px;
    margin: auto;
  }
}

.photo-items-wrapper- {
  display: flex;
  flex-flow: row wrap;
}

.tmp_photos {
  padding-left: 30px;
}

.photo-items-wrapper- {
  margin: 15px -5px;
}

.portfolio_item, .tmp_photo_item__img_placeholder {
  margin: 5px !important;
  width: auto;
}

.master-pro .master {
  width: 36%;
}

@media (max-width: 991px) {
  .photo-items-wrapper-, .tmp_photos, .photo-items-, .master-pro .master {
    width: 100% !important;
  }
}

.profile_create_page .form_cell.is_field.is_card {
  width: 510px;
}

@media (max-width: 639px) {
  .profile_create_page .form_cell.is_field.is_card {
    width: auto;
  }
}

.profile_create_page .form_cell.is_field.is_card .form_cell__inner {
  margin-right: 38px;
}

.profile_create_page .form_cell.is_field.is_card .form__text.is_textarea {
  max-width: 530px;
}

.profile_create_page .card_info {
  line-height: 44px;
}

@media (max-width: 639px) {
  .profile_create_page .profile_content {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .profile_create_page .form__text.is_textarea {
    max-width: 400px;
  }
}

.profile_create_page .is_company.is_visible {
  display: inline;
}

.profile_create_page .is_company.is_visible.form_item {
  display: block;
}

.profile_create {
  margin: 45px 59px 0 80px;
}

.profile_create .portfolio_controls {
  padding: 0;
}

.profile_create .portfolio_controls.is_old {
  position: relative;
  right: 27px;
}

@media (max-width: 639px) {
  .profile_create .portfolio_controls.is_old {
    position: relative;
    padding-left: 20px;
    top: -20px;
  }
}

@media (max-width: 639px) {
  .profile_create .form__dimension {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 66px;
    left: 54%;
  }
}

@media (min-width: 640px) and (max-width: 999px) {
  .profile_create {
    margin-left: 30px;
  }
}

@media (max-width: 639px) {
  .profile_create {
    margin: 0 19px 0 21px;
    padding-top: 33px;
  }
}

.profile_create__title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 36px;
  color: #262626;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 46px;
}

@media (min-width: 640px) and (max-width: 999px) {
  .profile_create__title {
    font-size: 24px;
  }
}

@media (max-width: 639px) {
  .profile_create__title {
    font-family: "OpenSans-Bold", "Arial", sans-serif;
    font-size: 18px;
    color: #262626;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 25px;
  }
}

.profile_create__descr {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 600px;
}

.profile_create_spec__sect_inner {
  display: flex;
  margin-bottom: 14px;
}

@media (max-width: 999px) {
  .profile_create_spec__sect_inner {
    flex-direction: column;
  }
}

@media (max-width: 639px) {
  .profile_create_spec__sect_inner {
    flex-direction: column;
  }
}

.profile_create_spec__container {
  padding-top: 46px;
}

@media (max-width: 639px) {
  .profile_create_spec__container {
    padding-top: 26px;
  }
}

.profile_create_spec__sect .section_title {
  margin-bottom: 26px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  padding-left: 38px;
  align-items: center;
}

.profile_create_spec__sect .section_title:before {
  content: '';
  width: 7px;
  height: 11px;
  background: url(/media/images/arrow.svg) no-repeat;
  position: absolute;
  left: 10px;
  top: 3px;
  transform: rotate(90deg);
}

.profile_create_spec__sect .section_title.is_active:before {
  transform: rotate(270deg);
}

.profile_create_spec__quantity {
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 2px;
  color: #c6c7cE;
  margin-left: 20px;
}

.profile_create_spec__col {
  margin-right: 111px;
}

@media (min-width: 1000px) and (max-width: 1279px) {
  .profile_create_spec__col {
    margin-right: 60px;
  }
}

@media (max-width: 639px) {
  .profile_create_spec__col {
    margin-right: 0;
  }
}

.profile_create_spec__col:last-child {
  margin-right: 0;
}

.profile_create_spec__item_wrap {
  margin-bottom: 22px;
  position: relative;
  width: 292px;
  display: flex;
  justify-content: space-between;
  min-height: 26px;
}

.profile_create_spec__item_wrap:before {
  content: '';
  background-color: #c6c7cE;
  top: -8px;
  left: -8px;
  bottom: -8px;
  right: -8px;
  opacity: 0;
  border-radius: 6px;
  position: absolute;
}

.profile_create_spec__item_wrap:hover:before {
  opacity: 0.2;
}

.profile_create_spec__item_wrap .subscribe_button {
  display: none;
}

.profile_create_spec__item_wrap .subscribe_button:not(.is_subscribed) {
  background-color: transparent;
}

.profile_create_spec__item_wrap.is_spec_checked .subscribe_button {
  display: inline-flex;
}

.profile_create_spec__text {
  float: right;
  padding-left: 17px;
  position: relative;
  top: 4px;
  user-select: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

@media (max-width: 900px) {
  .profile_create_spec__text {
    padding-left: 30px;
  }
}

@media (max-width: 599px) {
  .profile_create_spec__text {
    font-size: 10px;
    line-height: 14px;
  }
}

.user_info__photo.is_stub {
  border: 0;
  box-shadow: none;
}

.profile_create_form .user_photo_upload {
  vertical-align: top;
}

@media (max-width: 639px) {
  .profile_create_form .user_photo_upload {
    display: flex;
  }
}

.profile_create_form .file-preview {
  border: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 639px) {
  .profile_create {
    padding-top: 0;
  }
  .profile_create .portfolio_controls {
    margin-top: 0;
  }
  .profile_create .portfolio_controls .mt40 {
    margin-top: 0;
  }
}

.tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
  font-size: 11px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  letter-spacing: 1.8px;
  color: #262626;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 640px) {
  .tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
    font-size: 12px;
    letter-spacing: 2px;
  }
}

mark {
  background: #ffd24d;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
}

.main_promo__container {
  background: url(../images/main/new-back.png) no-repeat;
  height: 512px;
  background-size: cover;
  background-position: center center;
  margin-bottom: 244px;
  position: relative;
}

@media (min-width: 640px) {
  .main_promo__container {
    background-image: url(../images/main/new-back.png);
    height: 560px;
    margin-bottom: -18px;
  }
}

@media (min-width: 1441px) {
  .main_promo__container {
    background-image: url(../images/main/new-back.png);
    height: 620px;
  }
}

.main_promo__texts {
  background: #ffffff;
  box-shadow: 0 4px 84px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 40px 30px 46px;
  position: absolute;
  top: 177px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #000;
  max-width: 411px;
  width: calc(100% - 20px);
}

@media (min-width: 640px) {
  .main_promo__texts {
    right: calc(50% + 10px);
    left: inherit;
    max-width: 285px;
    padding: 34px 30px 34px;
    transform: none;
  }
}

@media (min-width: 680px) {
  .main_promo__texts {
    right: calc(50% + 15px);
  }
}

@media (min-width: 720px) {
  .main_promo__texts {
    right: calc(50% + 20px);
  }
}

@media (min-width: 800px) {
  .main_promo__texts {
    max-width: 330px;
  }
}

@media (min-width: 900px) {
  .main_promo__texts {
    top: auto;
    bottom: 116px;
    padding: 44px 56px 40px;
    max-width: 411px;
  }
}

.main_promo__texts.right {
  top: 485px;
}

@media (min-width: 640px) {
  .main_promo__texts.right {
    left: calc(50% + 10px);
    right: inherit;
    top: 177px;
  }
}

@media (min-width: 680px) {
  .main_promo__texts.right {
    left: calc(50% + 15px);
  }
}

@media (min-width: 720px) {
  .main_promo__texts.right {
    left: calc(50% + 20px);
  }
}

@media (min-width: 900px) {
  .main_promo__texts.right {
    top: auto;
  }
}

.page_content_section_about {
  background-color: #f5f6f7;
  background-image: url(../images/about-house.png);
  background-repeat: no-repeat;
  background-size: auto 92%;
  background-position: bottom left;
  padding: 70px 0 62px;
}

@media (max-width: 1140px) {
  .page_content_section_about {
    background-size: 30% auto;
  }
}

@media (max-width: 639px) {
  .page_content_section_about {
    background-image: none;
  }
}

.ic--hobby {
  margin-bottom: 30px;
}

.page_content_context .about-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  max-width: 650px;
  margin-left: auto;
  margin-right: 28px;
  position: relative;
  z-index: 2;
}

.page_content_context .about-content--reverse {
  margin-left: 35px;
  margin-right: auto;
}

.page_content_context .about-content p.bold {
  margin-left: 0;
  text-align: left;
}

.page_content_context .about-content h3 {
  text-align: left;
}

.page_content_context .about-content p {
  text-align: left;
  margin-bottom: 15px;
  width: 100%;
}

@media (max-width: 1140px) {
  .page_content_context .about-content {
    width: 70%;
  }
}

@media (max-width: 639px) {
  .page_content_context .about-content {
    width: auto;
  }
}

.page_content_section_best {
  background-color: #f5f6f7;
  padding: 70px 0 71px;
  position: relative;
}

.page_content_context .work_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content_context .work_content p {
  max-width: 640px;
  margin-bottom: 15px;
}

.page_content_context .work_content p:last-of-type {
  margin-bottom: 0;
}

.page_content_context .work_content h3 {
  margin-bottom: 47px;
}

.page_content_section_work {
  padding: 70px 0 70px;
}

.page_content_section_history {
  padding: 70px 0 73px;
}

.page_content_section_history .page_content_container h3 {
  margin-top: 10px;
  max-width: 460px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 48px;
  text-align: center;
}

.page_content_section_history .page_content_container p {
  max-width: 630px !important;
}

.page_content_section_cost {
  padding: 70px 0 80px;
  background-color: #ffd24d;
}

.page_content_section_cost .page_content_container h3 {
  margin-top: 10px;
  margin-bottom: 47px;
}

.page_content_section_cost .page_content_container p {
  max-width: 640px;
}

.info--about {
  position: relative;
  z-index: 2;
  width: 50%;
}

@media (max-width: 1140px) {
  .info--about {
    width: 70%;
  }
}

@media (max-width: 639px) {
  .info--about {
    width: 100%;
  }
}

.image--about {
  position: absolute;
  right: 0;
  bottom: -4px;
  z-index: 1;
}

@media (max-width: 1140px) {
  .image--about {
    width: 45%;
    height: 100%;
    overflow: hidden;
  }
  .image--about img {
    height: 100%;
    width: auto !important;
  }
}

@media (max-width: 639px) {
  .image--about {
    display: none;
  }
}

.image--best {
  display: block;
  position: absolute;
  right: 0;
  bottom: -4px;
  z-index: 1;
  width: 598px;
  height: 557px;
  background-image: url(../images/about-worker.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}

@media (max-width: 1140px) {
  .image--best {
    width: 50%;
    height: 100%;
    right: -10%;
  }
}

@media (max-width: 639px) {
  .image--best {
    display: none;
  }
}

.main__title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 28px;
  line-height: 30px;
  margin: 0 0 20px;
  text-align: center;
}

@media (min-width: 640px) {
  .main__title {
    font-size: 28px;
    line-height: 30px;
  }
}

@media (min-width: 800px) {
  .main__title {
    font-size: 34px;
    line-height: 36px;
  }
}

@media (min-width: 900px) {
  .main__title {
    font-size: 40px;
    line-height: 46px;
  }
}

@media (min-width: 1000px) {
  .main__title {
    font-size: 40px;
    line-height: 46px;
  }
}

.main__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  line-height: 24px;
  max-width: 276px;
  margin: 0 auto 32px;
}

@media (min-width: 640px) {
  .main__text {
    font-size: 14px;
    line-height: 24px;
    max-width: 286px;
    margin-bottom: 30px;
  }
}

@media (min-width: 800px) {
  .main__text {
    font-size: 16px;
    line-height: normal;
  }
}

@media (min-width: 1000px) {
  .main__text {
    font-size: 16px;
    line-height: normal;
    max-width: 286px;
    margin-bottom: 30px;
  }
}

.button__place_order {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  padding: 0;
  width: 100%;
  max-width: 241px;
}

@media (min-width: 640px) {
  .button__place_order {
    font-size: 14px;
    padding: 0;
    width: 100%;
    max-width: 241px;
  }
}

@media (min-width: 1000px) {
  .button__place_order {
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    border-radius: 29px;
  }
}

@media (max-width: 639px) {
  .main_section {
    padding: 0 15px;
  }
}

.main_section.is_gray {
  background-color: #f5f6f7;
}

.main_section.is_testimonials {
  padding: 0;
}

.main_section.is_advantages {
  padding: 0 25px;
  background-color: #ffd24d;
}

.main_section.is_portfolio {
  padding: 0 25px;
}

@media (max-width: 639px) {
  .main_section.is_portfolio {
    padding: 0 20px;
  }
}

.main_section.is_newsletter {
  padding: 0 10px;
  background-color: #f5f6f7;
}

.main_favorite {
  padding-bottom: 41px;
}

.main__section_title {
  text-align: center;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 32px;
  color: #000;
  letter-spacing: 0;
  margin: 0 0 20px 0;
  line-height: 45px;
}

@media (max-width: 639px) {
  .main__section_title {
    font-size: 20px;
    line-height: 30px;
  }
}

.main__section_title.is_switch span {
  cursor: pointer;
  color: #ffd24d;
}

.main__section_title.is_switch span.is_active {
  color: #000;
}

.main__section_sub_title {
  text-align: center;
  font-size: 18px;
  color: #262626;
  letter-spacing: 0;
  line-height: 30px;
}

.main__section_sub_title.is_capitalized {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  color: #262626;
  letter-spacing: 2px;
  line-height: 30px;
  margin-top: 41px;
  text-transform: uppercase;
}

@media (max-width: 639px) {
  .main__section_sub_title span {
    display: none;
  }
}

@media (max-width: 639px) {
  .main__section_sub_title {
    font-size: 14px;
    line-height: 1.79;
    text-align: center;
    max-width: 200px;
    display: block;
    margin: 0 auto;
  }
}

.main_section__container {
  padding: 86px 0 0;
  max-width: 1046px;
  margin: 0 auto;
}

@media (max-width: 639px) {
  .main_section__container {
    padding: 47px 0 41px;
  }
}

.main_favorite__content {
  margin-bottom: 105px;
}

.main_favorite__content.is_order {
  display: none;
}

@media (max-width: 639px) {
  .main_favorite__content {
    margin-bottom: 66px;
  }
}

.main_favorite_specs {
  display: flex;
  flex-wrap: wrap;
  padding: 78px 0 0;
  justify-content: space-between;
}

@media (max-width: 639px) {
  .main_favorite_specs {
    padding: 10px 0 54px;
  }
}

.main_favorite_spec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  margin-bottom: 77px;
  flex-grow: 1;
  width: calc(100% * (1 / 4) - 10px - 1px);
}

.main_favorite_spec__href {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 639px) {
  .main_favorite_spec {
    width: 49%;
    margin-bottom: 16px;
  }
}

.main_favorite_spec:hover .main_favorite_spec__title {
  color: #ffd24d;
}

.main_favorite_spec:hover .main_favorite_spec__icon .ic {
  fill: #ffd24d;
}

.main_favorite_spec .main_favorite_spec__icon .ic {
  fill: #262626;
}

.main_favorite_spec .sub_favorite_spec {
  width: 150px;
  margin: 0 auto;
}

.main_favorite_spec .sub_favorite_spec li {
  margin-bottom: 8px;
}

@media (max-width: 639px) {
  .main_favorite_spec .sub_favorite_spec {
    width: 120px;
  }
}

.main_favorite_spec .sub_favorite_spec__title {
  font-size: 12px;
  word-wrap: break-word;
  width: inherit;
  line-height: 16px;
}

.main_favorite_spec .sub_favorite_spec .show_more {
  display: none;
}

.main_favorite_spec .btn_show_more {
  color: #3c4146;
  font-size: 13px;
}

.main_favorite_spec .btn_show_more:hover {
  color: #0b72b8;
}

.about_specs {
  display: flex;
  flex-wrap: wrap;
  padding: 78px 0 0;
  justify-content: space-between;
}

@media (max-width: 639px) {
  .about_specs {
    padding: 10px 0 54px;
  }
}

.about_specs--about .about_spec {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 105px;
}

.about_spec {
  display: flex;
  margin-bottom: 77px;
  width: calc(100% * (1 / 4) - 10px - 1px);
}

@media (max-width: 639px) {
  .about_spec {
    width: 49%;
    margin-bottom: 16px;
  }
}

.about_spec:hover .main_favorite_spec__title {
  color: #ffd24d;
}

.about_spec:hover .main_favorite_spec__icon .ic {
  fill: #ffd24d;
}

.about_spec .main_favorite_spec__icon .ic {
  fill: #262626;
}

.about_spec__title {
  margin-left: 20px;
}

.main_favorite_spec__title {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  width: 175px;
}

@media (max-width: 639px) {
  .main_favorite_spec__title {
    font-size: 12px;
    width: 85px;
  }
}

.main_favorite_spec__icon {
  margin-bottom: 16px;
}

.main_favorite_spec__icon.is_stub {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #000;
}

.main_favorite_spec__icon .ic {
  height: 49px;
}

.ic--engineering {
  margin-bottom: 20px;
}

.button__see_all {
  width: 240px;
  display: block;
  margin: 0 auto;
}

.steps__container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0;
  justify-content: space-between;
}

.step__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  margin-bottom: 75px;
  flex-grow: 1;
  margin-right: 1px;
  text-align: center;
}

.step__item .step_link {
  margin-top: 20px;
}

@media (max-width: 639px) {
  .step__item {
    margin-bottom: 37px;
  }
}

.step_link {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 12px;
  color: #a5a8ad;
  letter-spacing: 0;
}

.step_num {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ffd24d;
  line-height: 32px;
  margin-bottom: 10px;
  position: relative;
  right: 30px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
}

@media (max-width: 639px) {
  .step_num {
    right: -52px;
  }
}

.step_name {
  width: 240px;
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 25px;
}

@media (max-width: 639px) {
  .step_name {
    width: 100%;
    text-align: center;
  }
}

.main_warranty {
  padding: 41px 0 79px;
  background-color: #f5f6f7;
}

@media (max-width: 639px) {
  .main_warranty {
    padding: 41px 0 39px;
  }
}

.main_warranty .main__section_title {
  margin: 26px 0 36px;
}

@media (max-width: 639px) {
  .main_warranty .main__section_title.is_desktop {
    display: none;
  }
}

.main_warranty .main__section_title.is_mobile {
  display: none;
}

@media (max-width: 639px) {
  .main_warranty .main__section_title.is_mobile {
    display: block;
    margin: 28px 0 25px;
  }
}

.main_warranty .main__section_sub_title.is_desktop {
  display: block;
  margin-bottom: 51px;
}

@media (max-width: 639px) {
  .main_warranty .main__section_sub_title.is_desktop {
    display: none;
  }
}

.main_warranty .main__section_sub_title.is_mobile {
  display: none;
}

@media (max-width: 639px) {
  .main_warranty .main__section_sub_title.is_mobile {
    display: block;
    margin-bottom: 20px;
    max-width: 100%;
  }
}

.main_warranty__icon {
  text-align: center;
}

.main_testimonials {
  padding: 106px 0 96px;
}

@media (max-width: 639px) {
  .main_testimonials {
    padding: 49px 0 51px;
  }
}

.main_testimonials__container {
  margin: 41px auto 0;
}

.main_testimonials__container .master_feedback {
  margin: 20px;
  padding: 35px 40px;
}

.main_testimonials__container .master_feedback:not(.slick-current) {
  filter: blur(1px);
  opacity: 0.8;
}

.arrow_right, .arrow_left {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  width: 44px;
  height: 44px;
  fill: #a5a8ad;
}

.arrow_right:hover, .arrow_left:hover {
  fill: #EDEEF0;
}

.arrow_left {
  left: -85px;
  transform: rotate(270deg);
}

.arrow_right {
  right: -85px;
  transform: rotate(90deg);
}

.main_advantages {
  padding: 106px 0 81px;
  background: #ffd24d;
}

@media (max-width: 639px) {
  .main_advantages {
    padding: 47px 0 21px;
  }
}

.main_advantages .main__section_title {
  display: block;
  max-width: 480px;
  margin: 0 auto;
}

@media (max-width: 639px) {
  .main_advantages .main__section_title {
    max-width: 100%;
  }
}

@media (max-width: 639px) {
  .main_advantages .main__section_sub_title {
    max-width: 100%;
  }
}

.main_advantages__container {
  max-width: 820px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 60px;
}

@media (max-width: 639px) {
  .main_advantages__container {
    max-width: 100%;
    padding-top: 30px;
  }
}

.main_advantage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  flex-grow: 1;
  margin-right: 1px;
  text-align: center;
}

@media (max-width: 639px) {
  .main_advantage {
    margin-bottom: 34px;
  }
}

.main_advantage__title {
  font-size: 14px;
  color: #262626;
  letter-spacing: 0;
  line-height: 25px;
}

.main_portfolio {
  padding: 108px 0 80px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 639px) {
  .main_portfolio {
    padding: 42px 0 77px;
  }
}

.main_portfolio__inner {
  margin-top: 30px;
}

@media (max-width: 639px) {
  .main_portfolio__inner {
    max-width: 100%;
  }
}

.main_portfolio__container {
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  padding-bottom: 60px;
}

.main_portfolio__container.slick-dotted {
  margin-bottom: 0;
}

.main_portfolio__item {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

@media (max-width: 639px) {
  .main_portfolio__item {
    margin-right: 0;
  }
}

.main_portfolio__item:hover .main_portfolio__user_info {
  visibility: visible;
}

.main_portfolio__item.checked .main_portfolio__user_info .image img {
  border: 2px solid #ffd24d;
}

.main_portfolio__item.checked .main_portfolio__user_info .info .name svg {
  height: 12px;
  width: 12px;
}

.main_portfolio__item_inner {
  position: relative;
}

.main_portfolio__item_inner_wrap {
  padding: 23px;
}

.main_portfolio__item_img {
  border-radius: 8px;
  overflow: hidden;
}

.main_portfolio__item_img img {
  width: 100%;
}

@media (max-width: 639px) {
  .main_portfolio__item_img {
    width: auto;
    height: auto;
    background-color: transparent;
  }
  .main_portfolio__item_img img {
    border-radius: 8px;
    margin: 0 auto;
  }
}

.main_portfolio__nav {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_portfolio__nav .arrow_left--is_port,
.main_portfolio__nav .arrow_right--is_port {
  position: static;
}

.main_portfolio__nav .arrow_left--is_port {
  order: 1;
}

.main_portfolio__nav .arrow_right--is_port {
  order: 3;
}

.main_portfolio__nav .slick-dots {
  position: relative;
  order: 2;
  width: auto;
  bottom: 2px;
  min-width: 200px;
}

.checked_master_tooltip {
  display: none;
  background: #555659;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 11px;
  line-height: 15px;
  color: #FFFFFF;
  padding: 2.3px 5px;
  position: absolute;
  top: 3px;
  right: -145px;
  z-index: 100;
}

.checked_master_tooltip:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #555659 transparent transparent;
  position: absolute;
  left: -14px;
  top: 0;
}

.checked_master {
  display: inline-block;
  position: relative;
}

.checked_master > svg.ic.ic--king {
  position: relative;
}

.checked_master > svg.ic.ic--king:hover + .checked_master_tooltip {
  display: block;
}

.main_portfolio__user_info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(38, 38, 38, 0.7);
  mix-blend-mode: normal;
  border-radius: 8px;
  padding: 23px 19px 23px;
  visibility: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.main_portfolio__user_info .image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid transparent;
}

.main_portfolio__user_info .info {
  padding-left: 20px;
}

.main_portfolio__user_info .info .name {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 18px;
  color: white;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 15px;
}

.main_portfolio__user_info .info .name a {
  display: inline;
}

.main_portfolio__user_info .info .name a:hover {
  color: white;
  border-color: transparent;
}

.main_portfolio__user_info .info .grade {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 10px;
  color: #262626;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 5px;
  background: #F5F6F7;
  border-radius: 2px;
  padding: 2px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
}

.main_portfolio__user_info .info .grade .ic.ic--liga-small {
  margin-right: 5px;
}

.main_portfolio__user_info .info .grade.last {
  margin-bottom: 15px;
}

.main_portfolio__user_info .info ul li {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: white;
  letter-spacing: 0;
  line-height: 30px;
}

.main_portfolio__user_info .rating {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 56px;
  color: white;
  letter-spacing: 0;
  line-height: 1;
}

.main_portfolio__item_name {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  text-align: left;
  color: #a5a8ad;
  margin-top: 10px;
}

.portfolio_slider_user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.arrow_left--is_port {
  position: absolute;
  z-index: 20;
  bottom: -46px;
  left: 40%;
  width: 44px;
  height: 44px;
  fill: #a5a8ad;
  transform: rotate(270deg);
}

.arrow_left--is_port:hover {
  fill: #EDEEF0;
}

.arrow_right--is_port {
  position: absolute;
  z-index: 20;
  bottom: -46px;
  right: 40%;
  width: 44px;
  height: 44px;
  fill: #a5a8ad;
  transform: rotate(90deg);
}

.arrow_right--is_port:hover {
  fill: #EDEEF0;
}

.slick-dots {
  bottom: -34px;
}

.main_start_work {
  padding: 106px 0 81px;
  border-top: 1px solid #edeef0;
}

@media (max-width: 639px) {
  .main_start_work {
    border-top: none;
    padding: 0 0 59px;
  }
}

.start_work__container {
  max-width: 672px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 639px) {
  .start_work__container {
    justify-content: center;
  }
}

.start_work_item {
  text-align: center;
}

@media (max-width: 639px) {
  .start_work_item:last-child {
    margin-top: 39px;
  }
}

.start_work_item:hover {
  color: #262626;
}

.start_work_item__icon {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 1px solid #edeef0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
}

.start_work_item:hover .start_work_item__icon {
  background-color: #f5f6f7;
  border-color: #f5f6f7;
}

.start_work_item__text {
  font-size: 14px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 22px;
}

@media (max-width: 639px) {
  .start_work_item__text {
    font-size: 12px;
  }
}

.start_work_item__status {
  margin: 31px 0 17px;
  font-size: 18px;
}

@media (max-width: 639px) {
  .ic--customer, .ic--contractor {
    width: 90px;
    height: 90px;
  }
}

.main_newsletter {
  background: #f5f6f7;
  padding: 57px 0 34px;
}

@media (max-width: 639px) {
  .main_newsletter {
    padding: 38px 0 27px;
  }
}

.main_newsletter__inner {
  text-align: center;
}

.main_newsletter__inner .section_title {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
}

.form_newsletter {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 840px;
  margin: 35px auto;
}

@media (max-width: 639px) {
  .form_newsletter {
    max-width: 100%;
    flex-direction: column;
    margin: 26px auto 0;
  }
}

.form_newsletter__cell {
  display: flex;
  flex-basis: 280px;
  margin-right: 35px;
  text-align: left;
  position: relative;
}

.form_newsletter__cell.is_btn {
  flex-basis: 160px;
}

@media (max-width: 639px) {
  .form_newsletter__cell.is_btn {
    flex-basis: auto;
    justify-content: center;
  }
}

.form_newsletter__cell:last-child {
  margin-right: 0;
}

@media (max-width: 639px) {
  .form_newsletter__cell {
    margin-right: 0;
    flex-basis: auto;
    width: 280px;
    margin-bottom: 18px;
  }
  .form_newsletter__cell .form__text {
    width: 100%;
  }
}

.form_newsletter__cell.is_error .form__text {
  border: 1px solid #fe253c;
}

.form_newsletter__cell .form_error {
  position: absolute;
  bottom: -30px;
}

.form_newsletter__personal_data {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 11px;
  color: #a5a8ad;
  letter-spacing: 0;
  line-height: 20px;
  max-width: 508px;
  margin: 0 auto;
}

.form_newsletter__personal_data a {
  color: #a5a8ad;
}

@media (max-width: 639px) {
  .form_newsletter__personal_data {
    max-width: 100%;
    line-height: 15px;
  }
  .form_newsletter__personal_data a {
    color: #a5a8ad;
  }
}

.main_favorite_orders {
  margin-top: 78px;
}

@media (max-width: 639px) {
  .main_favorite_orders {
    margin-top: 0;
  }
}

.main_favorite_orders .table_styled__title:last-child {
  text-align: left;
}

.step__item .ic {
  height: 48px;
}

.ic--main_arrow_left {
  cursor: pointer;
}

.ic--main_arrow_right {
  cursor: pointer;
}

.selectize-dropdown.js_user_selector .option {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/** Master card on main page in slider */
.main_testimonials__container .master_feedback:hover {
  border-color: #C6C7CE;
  outline: none;
}

.main_testimonials__container .master_feedback.checked .master_feedback__header .master_feedback__image img {
  border: 2px solid #ffd24d;
}

.main_testimonials__container .master_feedback.checked .master_feedback__header .master_feedback__fullname a {
  display: inline;
}

.main_testimonials__container .master_feedback.checked .master_feedback__header .master_feedback__fullname a:hover {
  color: black;
  border-color: transparent;
}

.main_testimonials__container .master_feedback.checked .master_feedback__header .master_feedback__fullname svg {
  height: 12px;
  width: 12px;
}

.main_testimonials__container .master_feedback .master_feedback__header .master_feedback__image {
  margin-right: 20px;
}

.main_testimonials__container .master_feedback .master_feedback__header .master_feedback__image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.main_testimonials__container .master_feedback .master_feedback__header .master_feedback__fullname {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 18px;
  color: black;
  letter-spacing: 0;
  line-height: 25px;
}

.main_testimonials__container .master_feedback .master_feedback__header .master_feedback__fullname .checked_master {
  display: inline-block;
  position: relative;
}

.main_testimonials__container .master_feedback .master_feedback__header .master_feedback__rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.main_testimonials__container .master_feedback .master_feedback__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_testimonials__container .master_feedback .master_feedback__info .master_feedback__author {
  font-style: italic;
}

@media screen and (max-width: 900px) {
  .main_portfolio__user_info {
    visibility: visible;
    position: static;
    height: auto;
    background: transparent;
    border-radius: 0;
    padding: 0;
    width: 95%;
    margin: 0 auto;
    position: relative;
    top: -25px;
    flex-wrap: wrap;
  }
  .main_portfolio__user_info .image {
    width: 100%;
  }
  .main_portfolio__user_info .info {
    padding-left: 0;
    margin-top: 20px;
    width: 75%;
  }
  .main_portfolio__user_info .info .name {
    color: black;
    width: 70%;
  }
  .main_portfolio__user_info .info .name a:hover {
    color: white;
    border-color: transparent;
  }
  .main_portfolio__user_info .info ul {
    visibility: hidden;
    display: none;
  }
  .main_portfolio__user_info .rating {
    color: black;
    margin-top: 20px;
  }
  .main_portfolio__item_name {
    width: 95%;
    position: relative;
    top: -25px;
    font-size: 14px;
    margin: 0 auto;
  }
  .main_portfolio__container.js_portfolio_slider.slick-initialized.slick-slider.slick-dotted {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 415px) {
  .main_testimonials__container .master_feedback__header .master_feedback__rating .stars__list {
    margin: 5px 0;
  }
}

.hidden {
  display: none !important;
}

.block-online {
  max-width: 800px;
  margin: 0 auto;
}

.title-page__text {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  font-family: 'OpenSans-Bold';
}

.block-store__grey {
  background: #E9E9E9;
  border: 1px solid #E9E9E9;
  padding: 25px 38px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
}

.block-store__pic {
  margin-right: 70px;
}

.block-store__content {
  max-width: 350px;
  padding-bottom: 15px;
}

.block-store__content a {
  font-size: 12px;
  line-height: 34px;
  height: 34px;
  font-family: 'OpenSans-Bold';
  font-weight: 600;
}

.block-store__title {
  font-family: 'OpenSans-Bold';
  font-size: 20px;
  margin-bottom: 14px;
}

.main-block {
  padding: 0 20px;
}

.main-block .breadcrumbs {
  padding-left: 0 !important;
}

.block-store__description {
  margin-bottom: 22px;
  font-size: 12px;
  font-family: 'Open-Sans';
  line-height: 17px;
}

.block-store__grey_bg {
  background: #E9E9E9;
  padding: 54px 50px 40px;
  margin-top: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

@media (max-width: 768px) {
  .block-store__content {
    padding-bottom: 0;
  }
  .block-store__pic {
    display: none;
  }
  .block-store__grey {
    padding: 20px;
  }
  .block-store__grey {
    margin-bottom: 30px;
  }
  .block-store__grey_bg {
    padding: 20px;
    background-image: none !important;
  }
}

.page_error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 136px 0;
}

@media (max-width: 639px) {
  .page_error {
    margin: 105px 0;
  }
}

.page_error__text {
  font-size: 14px;
  line-height: 1.79;
  text-align: center;
  color: #262626;
  margin-bottom: 36px;
}

@media (max-width: 639px) {
  .page_error__text {
    max-width: 226px;
    line-height: 1.4;
    margin-bottom: 96px;
  }
}

.page_error_promo {
  opacity: 0.7;
  font-size: 14px;
  line-height: 1.79;
  color: #262626;
  margin-bottom: 29px;
}

@media (max-width: 639px) {
  .page_error_promo {
    max-width: 258px;
    text-align: center;
  }
}

.page_error_links {
  display: flex;
}

@media (max-width: 639px) {
  .page_error_links {
    max-width: 258px;
    justify-content: space-between;
  }
}

.page_error_links__item {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #262626;
  margin-right: 50px;
  display: block;
  cursor: pointer !important;
}

.page_error_links__item:last-of-type {
  margin-right: 0;
}

@media (max-width: 639px) {
  .page_error_links__item {
    margin-right: 19px;
    max-width: 258px;
  }
}

.page_error__value {
  font-size: 150px;
  line-height: 1;
  display: block;
  margin-bottom: 80px;
  font-weight: 900;
  position: relative;
}

.page_error__value:after {
  content: '';
  background: url("/media/images/overlay.png");
  width: 239px;
  height: 143px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.page_error__value + .page_error__text {
  margin-bottom: 88px;
}

.page_error_img {
  max-width: 282px;
  height: 169px;
}

.page_error_img img {
  width: 100%;
  height: auto;
}

@media (max-width: 639px) {
  .page_error_img {
    max-width: 226px;
    margin-bottom: 20px;
  }
}

.propetrovich_theme.ui-datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  padding: 15px;
}

.propetrovich_theme.ui-datepicker th {
  font-family: inherit;
  font-size: 12px;
  color: #A5A8AD;
  letter-spacing: 0;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: normal;
}

.propetrovich_theme.ui-datepicker td:hover {
  color: transparent;
}

.propetrovich_theme.ui-datepicker td a {
  width: 33px;
  height: 33px;
  padding-top: 8px;
  border-radius: 50%;
}

.propetrovich_theme.ui-datepicker td .ui-state-active {
  background: #ffd24d;
  color: #262626;
}

.propetrovich_theme.ui-datepicker td .ui-state-active.ui-state-hover {
  background: #ffd24d !important;
  color: #262626;
}

.propetrovich_theme.ui-datepicker td .ui-state-highlight {
  background: #E3E5E8;
}

.propetrovich_theme.ui-datepicker td .ui-state-highlight.ui-state-hover {
  background: #E3E5E8 !important;
  color: #262626;
}

.propetrovich_theme.ui-datepicker td .ui-state-hover {
  background: none;
  border: none;
}

.propetrovich_theme.ui-datepicker td .ui-state-disabled {
  color: #A5A8AD;
  width: 35px !important;
  height: 35px !important;
}

.propetrovich_theme.ui-datepicker td .ui-state-disabled .ui-state-default {
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
}

.propetrovich_theme .ui-widget-header {
  border: none;
  background: #fff;
}

.propetrovich_theme .ui-datepicker-title {
  text-transform: uppercase;
  font-size: 12px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
}

.propetrovich_theme .ui-datepicker-other-month {
  color: #A5A8AD;
}

.propetrovich_theme .ui-state-default {
  text-align: center;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  border: 0;
  background: none;
  color: #262626;
}

.propetrovich_theme .ui-datepicker td:hover .ui-state-default {
  color: inherit;
}

.propetrovich_theme .ui-datepicker td .ui-state-active {
  color: inherit;
}

.propetrovich_theme .ui-datepicker-unselectable {
  opacity: 1;
  color: inherit;
  height: 35px;
}

.propetrovich_theme .ui-datepicker-unselectable .ui-state-default {
  color: #A5A8AD !important;
  height: 35px;
  padding-top: 8px;
}

@media (min-width: 640px) {
  .propetrovich_theme.ui-datepicker {
    padding: 27px 43px;
  }
}

.ui-datepicker {
  display: none;
}

.add_new_spec {
  display: flex;
  font-size: 11px;
  max-width: 330px;
  align-items: center;
  background: #EDEEF0;
  padding: 5%;
  border-radius: 5px;
}

.add_new_spec .btn--submit {
  padding: 0;
  font-size: 12px;
  width: 150px;
  height: 40px;
}

.add_new_spec .popmechanic-icon {
  width: 70px;
  height: 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAqFBMVEUAAAD/zwD/0AD/ygD/zwH/zwH/zgD/zwD/zgD/zwD/yQD/0AH/zwD/zwD/zQD/zwD/zwD/3wD/zwD/zwD/zgD/zwD/zwD/wgD/zwH/zwD/0AD/zwD/zgD/0AD/zgD/zwD/zwD/zQD/zwD/zwD/0AD/0AD/zwD/zwD/0gD/ygD/zwD/zgD/zwD/0AD/zwD/zgD/zQD/zwD/zwD/zgD/0AD/zwD/zwD/zwFA2zDOAAAAN3RSTlMAQFQh/fV960orDPry13Q+BQPm0lw5GAj37s2/vbSyqGwdnJSJhHhhExDIwq6fjYIz3rmjZ0ZR3PMH8wAAAeNJREFUWMPt1NlyqkAUheGlzKAIKk5RwDkep4xnv/+bpei2KJpgAt1XqfK7teqHttkbD3/NyIl6rUq9F8fF7w7/V13L8OgOz+gfd06An7wkU6phNhzfT7xNqKaBjUpjf0r1GZeql1kuqJnFCGXajJqaP0P0PKDmklBsHEmCbqJoTVI6UaFhT0jOPMwboy5J8vZ5xCdp/3Dj9kmaEYFrk4I1uCdS0AfjeqRiySI9UsLH+YPKOom5yifaWpuLSb5INteZToJd9dfadwBog8KU2RYxWxcIfBKsWOREJcPCKXV+5Av/KNh+DcWLOCEzp5IxX7bs6V3wosdeBIxJRW/VN+zySytGIvZHbMDsxPFBZlF9nFbxOBu+WNmWD8Snxsi8U4mlFYbyyc2CBi9uKibtPT+iyDiZZyO/q5UZ5790L9vyCjT5MiElbb5NSIkDpksKOgGYKyk4gxtZJE1v4eassB5D3DhTtbvhtiQpHiN3mJEUy0FBr0MS9BSCoS4RMQOI0ubvsUNZ6E+okf4Q34X2K9VnJA4qaQnVNI1t3DP+HNQoeEdTw49sKrm2BZ/RMsBvNBKlkCBGJj6UI8YH1CN7qEV4Qz3ShnpkCOWIkULegS+Wzh4qfC9rpFDTi18XEZSFeHgQfQG79uZfGIkg/wAAAABJRU5ErkJggg==);
  background-size: 100% 100%;
  margin: 0 15px 0 0;
}

.spec_on_registration {
  position: absolute;
  top: 17%;
  right: 20%;
}

@media (max-width: 1746px) {
  .spec_on_registration {
    right: 5%;
  }
}

@media (max-width: 1024px) {
  .spec_on_registration {
    position: static;
  }
}

.popup_content {
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(38, 38, 38, 0.1);
  border-radius: 10px;
  max-width: 880px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 202;
  display: none;
}

.popup_content__close {
  display: inline-flex;
  position: absolute;
  right: 60px;
  top: 64px;
  cursor: pointer;
  z-index: 20;
}

@media (max-width: 639px) {
  .popup_content__close {
    right: 20px;
    top: 25px;
  }
}

.offer_order {
  margin: 64px 60px 60px;
}

@media (max-width: 639px) {
  .offer_order {
    margin: 20px;
  }
}

.offer_order__title {
  color: #262626;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 29px;
}

@media (max-width: 639px) {
  .offer_order__title {
    margin-bottom: 0;
  }
}

.offer_order_table__title {
  color: #a5a8ad;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding-bottom: 17px;
  border-bottom: 1px solid #edeef0;
}

.offer_order_table {
  display: flex;
  flex-basis: 760px;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 19px;
}

.offer_order_table__row {
  padding: 17px 0;
  border-bottom: 1px solid #edeef0;
  display: flex;
}

.offer_order_table__row:first-child {
  border-bottom: 1px solid #edeef0;
}

.offer_order_table__row:last-of-type {
  border: none;
}

@media (max-width: 639px) {
  .offer_order_table__row {
    flex-wrap: wrap;
  }
}

.offer_order_table_order__title {
  color: #262626;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 13px;
  line-height: 25px;
}

.offer_order_table_order {
  display: inline-flex;
  flex-direction: column;
}

@media (max-width: 639px) {
  .offer_order_table_order {
    margin-bottom: 17px;
  }
}

.offer_order_table_order__data {
  color: #a5a8ad;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 12px;
  line-height: 14px;
}

.offer_order_table_order__data .ic--key {
  margin-right: 10px;
  top: -1px;
}

.offer_order_table__clm {
  padding-right: 10px;
}

.offer_order_table__clm.is_order {
  flex: 1;
}

@media (max-width: 639px) {
  .offer_order_table__clm.is_order {
    flex: auto;
    width: 100%;
  }
}

@media (max-width: 639px) {
  .offer_order_table__clm.is_controls {
    width: 100%;
    padding-right: 0;
  }
  .offer_order_table__clm.is_controls .button--confirm.is_offer.button__primary {
    margin: 0;
    width: 100%;
  }
}

.order_has_sent {
  border: solid 1px #e3e5e8;
  padding: 8px 39px 10px;
  width: auto;
  height: auto;
  line-height: 17px;
  color: #a5a8ad;
  font-size: 14px;
}

.offer_order_stub {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-bottom: 41px;
}

.offer_order_stub .ic--stub_icon {
  margin-bottom: 27px;
  margin-top: 27px;
}

.offer_order_stub .offer_order_stub__message {
  margin-bottom: 40px;
  color: #a5a8ad;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  line-height: 16px;
}

@media (max-width: 639px) {
  .offer_order_stub .button__primary.button--confirm {
    margin-left: 0 !important;
  }
}

.offer_order_table__body {
  max-height: 370px;
  overflow-y: auto;
}

@media (max-width: 639px) {
  .offer_order_table__body {
    max-height: 420px;
  }
}

@media (max-width: 639px) {
  .offer_order_controls .button {
    width: 100%;
  }
}

.ajax_loader {
  width: 200px;
  height: 200px;
  background: url("/media/images/loader.gif");
  margin: 100px auto;
}

.offer_order__inner--stub {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding: 50px 0 40px;
  z-index: 10;
}

.offer_order__inner--stub:before {
  content: '';
  display: block;
  height: 240px;
  width: 1px;
  background-color: #dadada;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.offer_order__inner--stub .offer_order_stub {
  flex-basis: 50%;
  text-align: center;
}

.offer_order__inner--stub .offer_order_stub .offer_order_stub__message {
  color: #262626;
}

@media (max-width: 639px) {
  .offer_order__inner--stub {
    padding: 20px 0;
  }
  .offer_order__inner--stub:before {
    left: 0;
    height: 1px;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: -20px;
  }
  .offer_order__inner--stub .offer_order_stub {
    flex-basis: 100%;
  }
}

.modal-geoip {
  position: fixed;
  z-index: 100000;
  padding: 20px 25px 22px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 10px 30px black;
  border: 2px solid #ffd24d;
  top: 10%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

.modal-geoip__wrapper {
  max-width: none;
}

.modal-geoip__content {
  padding-top: 5px;
}

.modal-geoip__footer {
  display: flex;
  padding-top: 15px;
}

.modal-geoip__btn {
  white-space: nowrap;
}

.modal-geoip__discard {
  background: none;
}

.filters-open {
  overflow-y: hidden;
}

@media (min-width: 600px) and (max-width: 899px) {
  .filters-open .listing--v2 {
    z-index: 99;
  }
}

@media (min-width: 900px) {
  .filters-open {
    overflow-y: auto;
  }
}

.lt_2col .page_content--listing {
  display: flex;
  flex-direction: column;
  min-height: 0;
  border-top: 1px solid #edeef0;
  margin-top: -1px;
}

@media (min-width: 900px) {
  .lt_2col .page_content--listing {
    display: block;
    position: relative;
    min-height: 900px;
  }
}

.lt_2col .page_title--listing {
  padding: 28px 20px 0;
  order: 1;
}

.lt_2col .page_title--listing .page_title__text {
  text-align: left;
  margin-bottom: 10px;
  line-height: normal;
}

@media (min-width: 600px) and (max-width: 899px) {
  .lt_2col .page_title--listing {
    width: 100%;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .lt_2col .page_title--listing .page_title__text {
    margin-bottom: 16px;
  }
}

@media (min-width: 900px) {
  .lt_2col .page_title--listing {
    width: 64%;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1280px) {
  .lt_2col .page_title--listing {
    padding-left: 80px;
    padding-right: 0;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .lt_2col .page_content__left--listing {
    width: 100%;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    order: 3;
  }
}

@media (min-width: 900px) {
  .lt_2col .page_content__left--listing {
    width: 64%;
    padding-right: 25px;
  }
}

@media (min-width: 1280px) {
  .lt_2col .page_content__left--listing {
    width: 75%;
  }
}

.lt_2col .page_content__right--listing {
  order: 2;
}

@media (min-width: 600px) and (max-width: 899px) {
  .lt_2col .page_content__right--listing {
    background-color: #ffffff;
    padding: 0;
    width: 100%;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    float: none;
    position: static;
    margin-bottom: 0;
  }
  .lt_2col .page_content__right--listing .listing_sidebar > .button__primary {
    width: 170px;
  }
}

@media (min-width: 900px) {
  .lt_2col .page_content__right--listing {
    width: 320px;
    min-width: inherit;
  }
}

@media (min-width: 1280px) {
  .lt_2col .page_content__right--listing {
    width: 320px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .lt_2col .page_content__right--chat {
    max-width: none;
  }
}

@media (min-width: 900px) {
  .lt_2col .page_content__left--chat {
    float: none;
  }
}

.listing_filter__chosen {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  line-height: 18px;
  font-size: 14px;
  margin-bottom: 28px;
}

.listing_filter__chosen.order_btob {
  margin-bottom: 0;
  line-height: 0;
  font-size: 13px;
}

.listing_filter__chosen span {
  display: inline-block;
  border-radius: 3px;
  background-color: #ffd24d;
  padding: 0 5px;
}

@media (min-width: 600px) {
  .listing_filter__chosen {
    font-size: 18px;
    line-height: 27px;
  }
}

.listing_filter__chosen--b2b {
  margin-bottom: 0;
  margin-left: auto;
  font-size: 13px;
  display: none;
}

@media (min-width: 600px) {
  .listing_filter__chosen--b2b {
    display: block;
  }
}

.listing--v2 {
  padding-left: 16px;
  padding-right: 16px;
}

.listing--v2 .button--open_filters {
  width: 100%;
}

.listing--v2 .user_not-found_text,
.listing--v2 .order_not-found_text {
  margin-bottom: 15px;
}

@media (min-width: 600px) {
  .listing--v2 {
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    z-index: 100;
  }
  .listing--v2 .button--open_filters {
    width: 200px;
    margin-right: 0;
    position: absolute;
    right: 20px;
    bottom: 100%;
    margin-bottom: 0;
  }
}

@media (min-width: 900px) {
  .listing--v2 {
    padding-left: 25px;
    padding-right: 0;
  }
}

@media (min-width: 1280px) {
  .listing--v2 {
    padding-left: 60px;
  }
}

.listing__menu {
  margin-bottom: 27px;
}

.listing__menu .form__text {
  padding-right: 56px;
}

.listing__menu .button__search {
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 900px) {
  .listing__menu {
    margin-bottom: 14px;
  }
}

@media (min-width: 1280px) {
  .listing__menu {
    margin-bottom: 0;
  }
}

.table-listing--v2 {
  display: block;
}

.table-listing--v2 .ic--liga,
.table-listing--v2 .ic--liga-small,
.table-listing--v2 .ic--star {
  pointer-events: none;
}

.table-listing--v2 .ic--star {
  fill: #ffd24d;
  width: 12px;
  height: 12px;
  top: -1px;
}

.table-listing--v2 .table__row {
  display: block;
  width: 100%;
  padding-top: 16px;
  transition: background-color 0.2s ease-in-out;
}

.table-listing--v2 .table__row:nth-child(2n) {
  background-color: #ffffff;
}

.table-listing--v2 .table__row--thead {
  display: none;
}

.table-listing--v2 .table__cell {
  vertical-align: top;
  font-size: 12px;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.table-listing--v2 .table__cell:before {
  content: attr(data-title);
  width: auto;
  font-weight: normal;
  margin-bottom: 0;
  padding-right: 0;
}

.table-listing--v2 .table__cell:first-child {
  margin-bottom: 4px;
}

.table-listing--v2 .table__cell--town {
  font-size: 10px;
  padding-bottom: 6px;
}

.table-listing--v2 .table__cell--town:before {
  display: none;
}

.table-listing--v2 td.table__cell:not(:first-child) {
  justify-content: space-between;
}

.table-listing--v2 td.table__cell--materials {
  border-bottom: 1px solid #edeef0;
}

.table-listing--v2 td.table__cell--town,
.table-listing--v2 td.table__cell--rating,
.table-listing--v2 td.table__cell--total_rating,
.table-listing--v2 td.table__cell--feedback {
  max-width: 280px;
  font-weight: bold;
  padding-left: 72px;
  width: 100%;
}

.table-listing--v2 td.table__cell--specialization {
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 16px;
  padding-left: 72px;
  display: block;
  word-break: break-word;
}

.table-listing--v2 td.table__cell--specialization:before {
  width: 100%;
  font-size: 11px;
  color: #a5a8ad;
  display: block;
}

.table-listing--v2 td.table__cell--name {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  font-size: 13px;
  display: block;
}

.table-listing--v2 td.table__cell--name .order-date {
  display: inline-block;
  font-size: 11px;
  font-weight: normal;
  margin-left: 10px;
}

.table-listing--v2 td.table__cell--name .order-date .ic--time {
  margin-right: 6px;
  top: -1px;
}

.table-listing--v2 td.table__cell--name p {
  font-size: 12px;
  font-family: Open-Sans, Arial, sans-serif;
}

.table-listing--v2 td.table__cell--name .help-text {
  background-color: transparent;
  padding: 0;
  font-weight: normal;
  line-height: inherit;
  color: #262626;
}

.table-listing--v2 td.table__cell--name a:hover {
  color: #262626;
}

.table-listing--v2 td.table__cell--name:before {
  display: none;
}

.table-listing--v2 td.table__cell--deadline,
.table-listing--v2 td.table__cell--budget {
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
}

.table-listing--v2 td.table__cell--deadline:before,
.table-listing--v2 td.table__cell--budget:before,
.table-listing--v2 td.table__cell--city:before,
.table-listing--v2 td.table__cell--materials:before {
  color: #262626;
}

.table-listing--v2 td.table__cell--materials {
  padding-bottom: 16px;
}

.table-listing--v2 td.table__cell--date {
  display: none;
}

.table-listing--v2 .table__cell--header {
  border-top: none;
  border-bottom: none;
  font-size: 11px;
  color: #a5a8ad;
  vertical-align: top;
  padding-top: 25px;
  padding-bottom: 0;
}

.table-listing--v2 .table__user {
  padding-top: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.table-listing--v2 .table__user .fav_link {
  position: absolute;
  top: 62px;
  left: 22px;
}

.table-listing--v2 .table__user .ic--fav-tag {
  pointer-events: none;
  width: 12px;
  height: 14px;
}

.table-listing--v2 .table__user__ava {
  width: 54px;
  min-width: 54px;
  height: 54px;
  margin-right: 18px;
  border-radius: 50%;
  overflow: hidden;
}

.table-listing--v2 .checked_master {
  border: 2px solid #FFD24D;
}

.table-listing--v2 .user__ava__img {
  border-radius: 50%;
}

.table-listing--v2 .table__user__text {
  position: relative;
  width: 100%;
}

.table-listing--v2 .table__user__text > a {
  font-size: 13px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  line-height: 25px;
}

.table-listing--v2 .table__user__text > a:hover {
  color: #555659;
}

.table-listing--v2 .table__user__text .high_liga_link {
  display: inline-flex;
}

.table-listing--v2 .table__user__text .icon_hover {
  display: inline-block;
  position: relative;
  width: auto;
}

.table-listing--v2 .table__user__text .icon_hover svg {
  margin-left: 3px;
}

.table-listing--v2 .table__user__text .icon_hover .ic.ic--checked_master_tooltip {
  display: none;
  position: absolute;
  top: 0;
  right: -145px;
  z-index: 100;
  margin: 0;
}

.table-listing--v2 .table__user__text .icon_hover > svg.ic.ic--king {
  position: relative;
}

.table-listing--v2 .table__user__text .icon_hover > svg.ic.ic--king:hover + .ic.ic--checked_master_tooltip {
  display: block;
}

.table-listing--v2 .listing__menu__itm {
  color: #a5a8ad;
  padding: 0;
  margin: 0;
}

.table-listing--v2 .listing__menu__itm:before {
  display: none;
}

.table-listing--v2 .listing__menu__itm .ic--ic_sort {
  margin-left: 5px;
  fill: #daddde;
  transition: fill 0.2s ease-in-out;
}

.table-listing--v2 .listing__menu__itm:hover {
  color: #a5a8ad;
}

.table-listing--v2 .listing__menu__itm:hover .ic--ic_sort {
  fill: #a5a8ad;
}

.table-listing--v2 .listing__menu__itm--active .ic--ic_sort {
  fill: #ffd24d;
}

.table-listing--v2 .listing__menu__itm--active--minus .ic--ic_sort {
  transform: scaleX(-1) rotate(180deg);
}

.table-listing--v2 .table__user__tags {
  margin-left: -7px;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}

.table-listing--v2 .help-text {
  display: inline-block;
  height: 17px;
  line-height: 17px;
  white-space: nowrap;
  border-radius: 18px;
  background-color: #f4f4f4;
  font-size: 11px;
  padding: 0 8px;
  color: #262626;
  margin-right: 6px;
  vertical-align: middle;
  margin-bottom: 6px;
}

.table-listing--v2 .help-text:first-letter {
  text-transform: uppercase;
}

.table-listing--v2 .more {
  white-space: nowrap;
  color: #a5a8ad;
}

.table-listing--v2 .table__cell--date .ic--time {
  margin-left: -17px;
  margin-right: 6px;
}

.table-listing--v2 .table__row--adv {
  padding-top: 4px;
  padding-bottom: 4px;
}

.table-listing--v2 .table__row--adv .table__cell {
  padding: 4px 0;
}

.table-listing--v2 .table__row--adv .table__cell:first-child {
  margin-bottom: 0;
}

.table-listing--v2 .table__row--adv .table__cell:last-child {
  border-bottom-width: 0;
}

.table-listing--v2 .table__row--adv:hover {
  background-color: #ffffff;
}

@media (max-width: 599px) {
  .table-listing--v2 tbody {
    display: block;
  }
  .table-listing--v2 .table__row--adv {
    border-bottom-width: 0;
    margin-top: -1px;
    padding-top: 5px;
  }
  .table-listing--v2 .table__row--adv .table__cell {
    border-bottom-width: 0;
  }
  .table-listing--v2 .ic--liga-small {
    width: 13px;
    height: 7px;
    margin-left: 5px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .table-listing--v2 tbody {
    display: block;
  }
  .table-listing--v2 td.table__cell--materials,
  .table-listing--v2 .table__cell:last-child {
    border-bottom-width: 0;
  }
}

@media (min-width: 600px) {
  .table-listing--v2 {
    padding-top: 30px;
  }
  .table-listing--v2 .table__row:not(.table__row--thead) {
    border-bottom: 1px solid #edeef0;
    display: flex;
    flex-flow: row wrap;
    position: relative;
  }
  .table-listing--v2 td.table__cell--town,
  .table-listing--v2 td.table__cell--rating,
  .table-listing--v2 td.table__cell--total_rating,
  .table-listing--v2 td.table__cell--feedback {
    margin-right: 50%;
  }
  .table-listing--v2 td.table__cell--specialization {
    margin-left: 320px;
    padding-left: 0;
    position: absolute;
    top: 64px;
  }
  .table-listing--v2 td.table__cell--specialization:before {
    margin-bottom: 5px;
  }
  .table-listing--v2 td.table__cell--materials,
  .table-listing--v2 td.table__cell {
    border-bottom: none;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell {
    width: 100%;
  }
}

@media (min-width: 900px) {
  .table-listing--v2 {
    padding-top: 0;
  }
}

@media (min-width: 1280px) {
  .table-listing--v2 {
    display: table;
    border-bottom: 1px solid #edeef0;
  }
  .table-listing--v2:not(.table-listing--masters) .table__row {
    display: table-row;
    padding-top: 0;
  }
  .table-listing--v2:not(.table-listing--masters) .table__row.table_link:hover {
    background-color: #f5f6f7;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell {
    display: table-cell;
    padding-top: 26px;
    padding-bottom: 21px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell:before {
    display: none !important;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell:first-child {
    padding-left: 20px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--header {
    padding-top: 27px;
    padding-bottom: 0;
    padding-left: 0;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--rating {
    width: 120px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--total_rating {
    width: 80px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--feedback {
    width: 80px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--specialization {
    width: 160px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--deadline {
    width: 128px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--budget {
    width: 102px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--city {
    width: 134px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--materials {
    width: 109px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--date {
    width: 82px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__cell--town {
    width: 134px;
  }
  .table-listing--v2:not(.table-listing--masters) td.table__cell:not(:first-child) {
    padding-left: 0;
  }
  .table-listing--v2:not(.table-listing--masters) td.table__cell--town,
  .table-listing--v2:not(.table-listing--masters) td.table__cell--rating,
  .table-listing--v2:not(.table-listing--masters) td.table__cell--total_rating,
  .table-listing--v2:not(.table-listing--masters) td.table__cell--feedback {
    font-family: "OpenSans-Semibold", "Arial", sans-serif;
  }
  .table-listing--v2:not(.table-listing--masters) th.table__cell--name {
    padding-left: 20px;
  }
  .table-listing--v2:not(.table-listing--masters) td.table__cell--name {
    padding-left: 20px;
    padding-right: 10px;
    display: table-cell;
  }
  .table-listing--v2:not(.table-listing--masters) td.table__cell--name .order-date {
    display: none;
  }
  .table-listing--v2:not(.table-listing--masters) td.table__cell--specialization {
    padding-top: 26px;
    display: table-cell;
    position: static;
  }
  .table-listing--v2:not(.table-listing--masters) td.table__cell--specialization:before {
    display: none;
  }
  .table-listing--v2:not(.table-listing--masters) td.table__cell--materials {
    padding-bottom: 21px;
  }
  .table-listing--v2:not(.table-listing--masters) td.table__cell--date {
    display: table-cell;
  }
  .table-listing--v2:not(.table-listing--masters) .table__user .fav_link {
    top: 4px;
    left: 60px;
  }
  .table-listing--v2:not(.table-listing--masters) .table__row--adv .table__cell {
    padding: 4px 0;
    border-bottom-width: 1px;
  }
}

.table-listing--v2.table-listing--masters {
  border-bottom-width: 0;
}

.table-listing--v2.table-listing--masters .table__row--thead {
  display: none;
}

.table-listing--v2.table-listing--masters .table__row:not(.table__row--thead) {
  border-bottom: 1px solid #edeef0;
  padding-bottom: 30px;
}

.table-listing--v2.table-listing--masters .table__row:not(.table__row--thead) .table__cell {
  border-bottom-width: 0;
}

@media (min-width: 1280px) {
  .table-listing--v2.table-listing--masters {
    margin-top: 0;
  }
  .table-listing--v2.table-listing--masters .table__row {
    display: table-row;
    padding-top: 0;
  }
  .table-listing--v2.table-listing--masters .table__row:not(.table__row--thead):hover {
    background-color: #f5f6f7;
    cursor: pointer;
  }
  .table-listing--v2.table-listing--masters .table__row:not(.table__row--thead):hover .help-text {
    background-color: #ffffff;
  }
  .table-listing--v2.table-listing--masters .table__cell {
    display: table-cell;
    padding-top: 26px;
    padding-bottom: 21px;
    padding-left: 0;
  }
  .table-listing--v2.table-listing--masters .table__cell:before {
    display: none;
  }
  .table-listing--v2.table-listing--masters .table__cell:first-child {
    padding-left: 20px;
  }
  .table-listing--v2.table-listing--masters .table__cell:last-child {
    padding-right: 20px;
  }
  .table-listing--v2.table-listing--masters td.table__cell--town,
  .table-listing--v2.table-listing--masters td.table__cell--rating,
  .table-listing--v2.table-listing--masters td.table__cell--total_rating,
  .table-listing--v2.table-listing--masters td.table__cell--feedback {
    font-family: "OpenSans-Semibold", "Arial", sans-serif;
    padding-left: 0;
    width: auto;
  }
  .table-listing--v2.table-listing--masters .table__cell--header {
    padding-top: 27px;
    padding-bottom: 0;
    padding-left: 0;
  }
  .table-listing--v2.table-listing--masters .table__cell--rating {
    width: 120px;
  }
  .table-listing--v2.table-listing--masters .table__cell--total_rating {
    width: 80px;
  }
  .table-listing--v2.table-listing--masters .table__cell--specialization {
    width: 160px;
  }
  .table-listing--v2.table-listing--masters .table__cell--feedback {
    width: 80px;
  }
  .table-listing--v2.table-listing--masters .table__cell--town {
    width: 134px;
    font-size: 12px;
  }
  .table-listing--v2.table-listing--masters th.table__cell--name {
    padding-left: 20px;
  }
  .table-listing--v2.table-listing--masters td.table__cell--name {
    padding-left: 20px;
    padding-right: 10px;
    display: table-cell;
  }
  .table-listing--v2.table-listing--masters td.table__cell--name .order-date {
    display: none;
  }
  .table-listing--v2.table-listing--masters td.table__cell--specialization {
    padding-top: 26px;
    position: static;
  }
  .table-listing--v2.table-listing--masters td.table__cell--specialization:before {
    display: none;
  }
  .table-listing--v2.table-listing--masters .table__user {
    align-items: flex-start;
  }
  .table-listing--v2.table-listing--masters .table__user .fav_link {
    top: 0;
    left: 60px;
  }
  .table-listing--v2.table-listing--masters .table__user__ava {
    margin-right: 30px;
  }
  .table-listing--v2.table-listing--masters .table__user__text > a {
    line-height: normal;
  }
  .table-listing--v2.table-listing--masters .table__row--adv .table__cell {
    padding: 4px 0;
    border-bottom-width: 1px;
  }
}

.listing__paginator--v2 {
  padding-top: 44px;
  padding-bottom: 62px;
}

.listing__paginator--v2 .listing__paginator__list {
  display: flex;
  position: relative;
  justify-content: center;
}

.listing__paginator--v2 .listing__paginator__itm {
  margin: 0 5px;
  display: block;
  border-bottom: none;
  padding: 0;
}

.listing__paginator--v2 .listing__paginator__itm--active .listing__paginator__btn {
  cursor: default;
}

.listing__paginator--v2 .listing__paginator__btn {
  position: relative;
  display: block;
  min-width: 26px;
  height: 26px;
  line-height: 26px;
  background-color: #edeef0;
  text-align: center;
  padding: 0 7px;
  color: #262626;
  font-size: 11px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  border-radius: 1px;
  transition: background-color 0.2s ease-in-out;
}

.listing__paginator--v2 .listing__paginator__btn:hover {
  background-color: #e0e0e0;
  color: #262626;
}

.listing__paginator--v2 .listing__paginator__itm--active .listing__paginator__btn {
  background-color: #ffd24d;
}

.listing__paginator--v2 .listing__paginator__btn--prev {
  background-color: transparent;
}

.listing__paginator--v2 .listing__paginator__btn--prev:before {
  display: none;
}

.listing__paginator--v2 .listing__paginator__btn--prev .ic {
  transform: rotate(90deg);
  width: 6px;
  height: 4px;
}

.listing__paginator--v2 .listing__paginator__btn--next {
  background-color: transparent;
}

.listing__paginator--v2 .listing__paginator__btn--next:before {
  display: none;
}

.listing__paginator--v2 .listing__paginator__btn--next .ic {
  transform: rotate(-90deg);
  width: 6px;
  height: 4px;
}

.listing__paginator--v2 .listing__paginator__btn--to-top .ic {
  transform: rotate(180deg);
  fill: #262626;
  width: 10px;
  height: 6px;
}

.listing_sidebar {
  padding: 22px 20px 0;
}

.listing_sidebar .dropdown {
  margin-bottom: 30px;
  border-radius: 4px;
  font-size: 14px;
}

.listing_sidebar .dropdown--autocomplete .ic--dropdown-arrow {
  position: absolute;
  top: 19px;
  right: 15px;
  z-index: 20;
  fill: #c4c4c4;
}

.listing_sidebar .dropdown--autocomplete:before {
  display: none;
}

.listing_sidebar .dropdown__itm {
  padding: 0;
}

.listing_sidebar .dropdown__itm > a {
  display: inline-block;
  padding: 12px 10px 11px 10px;
  width: 100%;
}

.listing_sidebar .dropdown__itm > a:hover {
  color: currentColor;
}

.listing_sidebar .skill-chouser {
  padding-bottom: 7px;
}

.listing_sidebar .skill-chouser--additional .section_title {
  margin-bottom: 18px;
}

.listing_sidebar .check_item {
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.listing_sidebar .check_item:first-child {
  margin-top: 20px;
}

.listing_sidebar .check_item__checked {
  position: relative;
  margin-right: 15px;
}

.listing_sidebar .check_item__checked:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.listing_sidebar .radio_item {
  margin-bottom: 20px;
  cursor: pointer;
}

.listing_sidebar .radio_item:first-child {
  margin-top: 20px;
}

@media (min-width: 900px) {
  .listing_sidebar {
    padding: 22px 0 40px;
  }
}

@media (min-width: 1280px) {
  .listing_sidebar {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.listing_sidebar__masters .dropdown__itm {
  padding: 0;
}

.listing_sidebar__masters .dropdown__itm > a {
  display: inline-block;
  padding: 12px 10px 11px 10px;
  width: 100%;
}

@media (min-width: 600px) {
  .listing_sidebar__masters {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
  .listing_sidebar__masters .advertising_wrap {
    order: 3;
  }
  .listing_sidebar__masters .advertising_wrap .hero-adv--vertical {
    margin-top: 0;
  }
  .listing_sidebar__masters .listing__filter {
    order: 2;
  }
}

.listing_sidebar__hint {
  color: #a5a8ad;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 22px;
}

.listing__filter {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 -4px 15px 0 rgba(0, 0, 0, 0.12);
  background-color: #f5f6f7;
  width: 290px;
  z-index: 300;
  padding: 20px;
  margin-bottom: 0;
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  overflow-y: auto;
}

.filters-open .listing__filter {
  transform: translateX(0);
}

@media (min-width: 900px) {
  .listing__filter {
    margin-bottom: 44px;
    position: static;
    box-shadow: none;
    width: auto;
    transform: none;
    padding: 0 40px;
    overflow-y: visible;
  }
  .listing__filter .button {
    margin-top: 7px;
  }
}

@media (min-width: 1280px) {
  .listing__filter {
    padding: 0;
  }
}

.listing__filter_close {
  position: absolute;
  right: 22px;
  top: 22px;
}

.listing__filter_close .ic--close {
  width: 14px;
  height: 14px;
}

@media (min-width: 900px) {
  .listing__filter_close {
    display: none;
  }
}

.listing__filter_title {
  font-size: 16px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  line-height: 20px;
  color: #262626;
  margin-bottom: 28px;
}

@media (min-width: 900px) {
  .listing__filter_title {
    display: none;
  }
}

@media (min-width: 900px) {
  .button__filter {
    display: none;
  }
}

@media (min-width: 900px) {
  .table__cell__item {
    display: none;
  }
}

.articles_list {
  margin-bottom: 25px;
}

.articles_list__item {
  margin-bottom: 20px;
}

.articles_list__item:last-child {
  margin-bottom: 0;
}

.articles_list__link {
  display: block;
  background-color: #ffffff;
  padding: 12px 13px;
  font-size: 13px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  line-height: 17px;
  color: #262626;
  border-radius: 3px;
}

.articles_list__link:hover {
  background-color: #ffffff;
  color: rgba(38, 38, 38, 0.7);
}

.articles_list__preview {
  font-size: 12px;
  color: #94989d;
  display: block;
  font-weight: normal;
  margin-top: 6px;
}

.more-link {
  font-size: 14px;
  color: #a5a8ad;
}

.more-link:hover {
  color: #262626;
}

.sorting {
  display: flex;
  flex-flow: row wrap;
  padding-left: 20px;
}

@media (min-width: 600px) {
  .sorting {
    padding-left: 30px;
  }
}

@media (min-width: 1280px) {
  .sorting {
    padding-left: 82px;
  }
}

.sorting__label {
  font-size: 12px;
  color: #262626;
  margin-right: 24px;
  width: 100%;
  margin-bottom: 5px;
}

@media (min-width: 1280px) {
  .sorting__label {
    width: auto;
    margin-bottom: 0;
  }
}

.sorting__item {
  font-size: 12px;
  color: #262626;
  margin-right: 28px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.sorting__item:hover {
  color: #555659;
}

.sorting__item .ic--ic_sort {
  margin-left: 5px;
  fill: #daddde;
  transition: fill 0.2s ease-in-out;
}

.sorting__item:hover {
  color: #262626;
}

.sorting__item:hover .ic--ic_sort {
  fill: #a5a8ad;
}

.sorting__item.listing__menu__itm--active {
  border: none;
  padding: 0;
}

.sorting__item.listing__menu__itm--active:before {
  display: none;
}

.sorting__item.listing__menu__itm--active .ic--ic_sort {
  fill: #ffd24d;
}

.sorting__item.listing__menu__itm--active--minus .ic--ic_sort {
  transform: scaleX(-1) rotate(180deg);
}

.dropdown--full-width input {
  width: 100%;
}

@media (min-width: 600px) {
  .place_order {
    background-color: #ffffff;
    max-width: 240px;
    border-radius: 4px;
    padding: 16px 6px 0;
    order: 1;
  }
}

@media (min-width: 900px) {
  .place_order {
    padding: 36px 26px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
  }
}

.table__cell--town span {
  display: block;
}

@media (min-width: 1280px) {
  .table__cell--town span {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

/** minitoggle */
.minitoggle {
  position: relative;
  width: 60px;
  height: 25px;
  background-color: #C6C7CE;
  border: 1px solid #EDEEF0;
  border-radius: 56px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
}

.minitoggle .toggle-handle {
  position: absolute;
  top: 3px;
  left: 2px;
  right: 2px;
  width: 17px;
  height: 17px;
  z-index: 2;
  background-color: #fff;
  background-image: -webkit-linear-gradient(top, #fff 0, #f2f2f2 100%);
  background-image: linear-gradient(to bottom, #fff 0, #f2f2f2 100%);
  border-radius: 100px;
  -webkit-transition: -webkit-transform 0.1s ease-in-out, border 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, border 0.1s ease-in-out;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.minitoggle.active {
  background-color: #FFD24D;
  border-color: #EDEEF0;
  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.25);
}

.minitoggle.active .toggle-handle {
  border-color: #EDEEF0;
  transform: translate3d(23px, 0, 0) !important;
}

/** override miniswitcher + switcher block styles */
.only_checked_masters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}

.only_checked_masters .switcher .toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.only_checked_masters .switcher .toggle .text {
  margin-left: 20px;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  color: #262626;
}

.only_checked_masters .switcher .toggle .text svg {
  position: relative;
  top: 3px;
}

.only_checked_masters .switcher .checked_masters_hidden {
  display: none;
}

.only_checked_masters.notavailable {
  opacity: 0.7;
  pointer-events: none;
}

/** user masters not found page */
.not_found_info_block {
  text-align: center;
  max-height: 335px;
  max-width: 560px;
  margin: 0 auto;
  margin-top: 200px;
}

.not_found_info_block p {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #262626;
  margin-top: 50px;
  margin-bottom: 30px;
}

.not_found_info_block span {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #262626;
}

@media screen and (max-width: 899px) {
  .not_found_info_block {
    margin-top: 30px;
  }
}

@media (max-width: 899px) {
  .table-listing--v2 .table__user__text > a {
    font-size: 16px;
    word-break: break-word;
  }
  .table-listing--v2 td.table__cell--name {
    font-size: 16px;
    word-break: break-word;
  }
}

@media screen and (max-width: 415px) {
  .not_found_info_block p {
    font-size: 26px;
    line-height: 30px;
    margin-top: 20px;
  }
}

.lt_2col .page_content__right--chat {
  height: 100%;
}

@media (min-width: 900px) and (max-width: 1279px) {
  .lt_2col .page_content__right--chat {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 1280px) {
  .lt_2col .page_content__right--chat {
    position: absolute;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .lt_2col .page_content__left--chat {
    max-width: unset;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 1280px) {
  .lt_2col .page_content__left--chat {
    padding-right: 25px;
  }
}

.lt_2col .page_content__left--listing {
  order: 1;
  float: none;
}

.lt_2col .page_content__left--messages {
  padding-bottom: 17px;
}

@media (min-width: 600px) {
  .lt_2col .page_content__left--messages {
    max-width: none;
  }
}

@media (min-width: 1280px) {
  .lt_2col .page_content__left--messages {
    padding-right: 25px;
  }
}

.listing__list__items {
  text-align: center;
  padding: 72px 0;
  background: #F9F9F9;
}

.listing__list__items li {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #262626;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .listing__list__items li {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 8px;
  }
}

.listing__list__items li:last-child {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .listing__list__items {
    margin: 0 -16px 0 -20px;
    padding: 40px 20px;
  }
}

@media (max-width: 767px) {
  .listing__list__items {
    padding: 32px 16px;
  }
}

.services-page__tab {
  background: #F9F9F9;
  padding: 50px 0 0;
}

.section-title__block {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #262626;
  text-align: center;
}

.services-links {
  margin: 32px auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 1040px;
  justify-content: center;
}

.services-link {
  margin: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: #EDEEF0;
  letter-spacing: .5px;
  border-radius: 23px;
  color: #262626;
  transition: .25s;
  font-weight: 600;
}

.services-link:hover {
  background: #FFD24D;
  color: #262626;
}

@media (max-width: 1024px) {
  .services-page__tab {
    padding: 50px 20px 0;
  }
  .services-links {
    margin: 32px -10px 0;
  }
  .services-link {
    margin: 10px 5px;
    letter-spacing: 0;
  }
}

@media (max-width: 767px) {
  .services-page__tab {
    padding: 32px 20px 0;
  }
  .section-title__block {
    font-size: 20px;
    line-height: 27px;
  }
  .services-links {
    margin: 20px -5px 0;
  }
  .services-link {
    margin: 5px;
    font-size: 12px;
    height: 33px;
    padding: 0 14px;
  }
}

.tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
  font-size: 11px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  letter-spacing: 1.8px;
  color: #262626;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 640px) {
  .tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
    font-size: 12px;
    letter-spacing: 2px;
  }
}

.page_content_context .page_content_container {
  width: 90%;
  padding: 0;
  margin: 0 auto;
}

.page_content_context p {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  max-width: 704px;
  margin: 0 auto;
}

.page_content_context p.bold {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}

.page_content_context h3 {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  color: #262626;
  margin-bottom: 40px;
}

.page_content_context .page_content_section_head {
  width: 100%;
  background: radial-gradient(513px at 50% 100%, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 100%), #EDEEF0;
  padding: 40px 0;
  position: relative;
}

.page_content_context .page_content_section_head .breadcrumbs {
  margin: 0;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container--about {
  width: 84.5%;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info--about {
  margin-top: 28px;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info--about .about_specs--about {
  padding-top: 21px;
  max-width: 476px;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h1 {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 44px;
  line-height: 60px;
  color: #262626;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h1 svg {
  height: 28px;
  width: 28px;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h2 {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #262626;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .image {
  max-width: 717px;
  max-height: 707px;
}

.page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .image img {
  height: auto;
  width: 100%;
}

.page_content_context .page_content_section_what_for .page_content_container {
  padding: 100px 0 40px 0;
}

.page_content_context .page_content_section_what_for .page_content_container .inline_blocks {
  margin-top: 60px;
}

.page_content_context .page_content_section_how_to_pass_checking .page_content_container {
  padding-bottom: 100px;
  text-align: center;
}

.page_content_context .page_content_section_how_to_pass_checking .page_content_container .inline_blocks {
  margin-top: 60px;
}

.page_content_context .page_content_section_how_to_pass_checking .page_content_container .inline_blocks .yellow_round {
  background: #ffd24d;
  height: 50px;
  width: 50px;
  padding: 10px 0 5px 0;
  border-radius: 100px;
  margin: 0 auto;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #262626;
}

.page_content_context .big_yellow_number {
  display: block;
  margin: 0 auto 17px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 128px;
  line-height: 175px;
  text-align: center;
  letter-spacing: -10px;
  text-transform: uppercase;
  color: #ffd24d;
}

.page_content_context .small_grey {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #919599;
  display: block;
  margin-bottom: 100px;
}

.page_content_context .page_content_section_ready_to_pass_checking {
  background: #f5f6f7;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container {
  padding: 50px 0 80px 0;
  text-align: center;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container .checkmark_container {
  max-width: 300px;
  margin: 0 auto;
  padding-left: 44px;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container .checkmark_container .checkmark_container_content {
  text-align: center;
  position: relative;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container .checkmark_container .checkmark_container_content img {
  height: auto;
  width: 100%;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container .checkmark_container .checkmark_container_content .checkmark_text {
  position: absolute;
  top: 0;
  left: -24px;
  height: 100%;
  width: 100%;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container .checkmark_container .checkmark_container_content .checkmark_text p {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #262626;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container .checkmark_container .checkmark_container_content .checkmark_text p.grey {
  font-size: 13px;
  text-align: center;
  color: #919599;
  margin-top: 15px;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container .checkmark_container .checkmark_container_content .checkmark_text button {
  background: #ffd24d;
  border-radius: 21px;
  border: none;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #262626;
  margin-top: 15px;
  padding: 12px 58px;
}

.page_content_context .page_content_section_ready_to_pass_checking .page_content_container .checkmark_container .checkmark_container_content .checkmark_text a {
  margin-top: 15px;
}

.page_content_context .inline_blocks {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.page_content_context .inline_blocks .inline_blocks_item {
  text-align: center;
}

.page_content_context .inline_blocks .inline_blocks_item .__icon {
  display: block;
  height: 55px;
  margin-bottom: 35px;
}

.page_content_context .inline_blocks .inline_blocks_item .__info h4 {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #262626;
  margin-bottom: 10px;
}

.page_content_context .inline_blocks .inline_blocks_item .__info p {
  display: block;
  margin: 0 auto;
  max-width: 300px;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #919599;
}

.page_content_context .inline_blocks--about {
  margin-top: 50px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.page_content_context .inline_blocks--about .inline_blocks_item {
  width: 42%;
  min-width: 220px;
}

.page_content_context .inline_blocks--about .inline_blocks_item .__icon {
  margin-bottom: 20px;
}

.page_content_context .inline_blocks--about .inline_blocks_item .__info h4 {
  margin-bottom: 17px;
}

.page_content_section_how_to_pass_checking {
  background-color: #f5f6f7;
  padding-top: 106px;
}

@media screen and (max-width: 900px) {
  .page_content_context .page_content_section_what_for .page_content_container .inline_blocks .inline_blocks_item p svg.ic.ic--king {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 683px) {
  .page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h1 {
    font-size: 34px;
    line-height: 40px;
  }
  .page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h2 {
    line-height: 20px;
  }
  .page_content_context .inline_blocks .inline_blocks_item:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 500px) {
  .page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h1 {
    font-size: 24px;
    line-height: 25px;
  }
  .page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h1 svg {
    height: 15px;
    width: 15px;
  }
  .page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h2 {
    line-height: 15px;
    font-size: 11px;
  }
}

@media screen and (max-width: 420px) {
  .page_content_context h1 {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }
  .page_content_context h2 {
    font-size: 9px;
    line-height: 21px;
    text-align: center;
  }
  .page_content_context h3 {
    font-size: 24px;
    line-height: 43px;
    margin-bottom: 10px;
  }
  .page_content_context p.bold {
    font-size: 10px !important;
    line-height: 20px !important;
  }
  .page_content_context .page_content_section_head {
    padding: 0;
  }
  .page_content_context .page_content_section_head .breadcrumbs_container {
    display: none;
    visibility: hidden;
  }
  .page_content_context .page_content_section_head .page_content_section_head_title .page_content_container {
    flex-wrap: wrap;
    padding-top: 0 !important;
    padding-bottom: 34px;
  }
  .page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .image {
    order: -1;
  }
  .page_content_context .page_content_section_head .page_content_section_head_title .page_content_container .info h1 svg {
    display: block;
    margin: 10px auto;
    height: 28px;
    width: 28px;
  }
  .page_content_context .page_content_section_what_for .page_content_container,
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .page_content_context .page_content_section_what_for .page_content_container .inline_blocks,
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container .inline_blocks {
    margin-top: 51px;
  }
  .page_content_context .page_content_section_what_for .page_content_container .inline_blocks .inline_blocks_item h4,
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container .inline_blocks .inline_blocks_item h4 {
    font-size: 12px;
    line-height: 20px;
    margin-top: 19px;
    margin-bottom: 10px;
  }
  .page_content_context .page_content_section_what_for .page_content_container .inline_blocks .inline_blocks_item p,
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container .inline_blocks .inline_blocks_item p {
    font-size: 12px;
    line-height: 22px;
  }
  .page_content_context .page_content_section_what_for .page_content_container p,
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container p {
    font-size: 14px;
    line-height: 34px;
  }
  .page_content_context .page_content_section_what_for .page_content_container p.first,
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container p.first {
    margin-bottom: 20px;
  }
  .page_content_context .page_content_section_what_for .page_content_container p.bold,
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container p.bold {
    margin: 0;
  }
  .page_content_context .page_content_section_what_for {
    padding-top: 50px;
  }
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container {
    padding-bottom: 34px;
  }
  .page_content_context .page_content_section_how_to_pass_checking .page_content_container .small_grey {
    margin-bottom: 60px;
  }
}

.passport_check_security {
  padding: 76px 0;
  text-align: center;
}

.passport_check_security .ic--shield {
  margin-bottom: 22px;
}

.passport_check_security p {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 24px;
}

.passport_check_security p a {
  color: #ffd24d;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  text-decoration: underline;
}

.passport_check_security p a:hover {
  text-decoration: none;
}

.passport_check_security p.grey {
  font-size: 12px;
  line-height: 22px;
  color: #919599;
}

@media (min-width: 600px) {
  .passport_check_security .page_content_container {
    width: auto;
    padding: 0 20px;
  }
}

@media (min-width: 900px) {
  .passport_check_security {
    padding: 90px 0 100px;
  }
  .passport_check_security p {
    font-size: 18px;
    line-height: 30px;
  }
  .passport_check_security p.grey {
    font-size: 13px;
    line-height: 24px;
  }
}

.achivements {
  position: relative;
  padding: 0 80px 45px;
}

@media (max-width: 1000px) {
  .achivements {
    padding: 0 16px 60px;
  }
}

@media (max-width: 900px) {
  .achivements {
    padding-right: 9px;
  }
}

@media (max-width: 600px) {
  .achivements {
    padding-bottom: 32px;
  }
}

.achivements__title {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #262626;
}

@media (max-width: 599px) {
  .achivements__title {
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.83333px;
  }
}

.achivements__qty {
  font-weight: normal;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: #A5A8AD;
  position: absolute;
  right: 80px;
  top: 0;
}

@media (max-width: 900px) {
  .achivements__qty {
    display: none;
  }
}

.achivements__list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .achivements__list {
    margin-top: 21px;
  }
}

.achivements__item {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  filter: grayscale(100%);
  z-index: 101;
}

.achivements__item:last-of-type {
  margin-right: 0;
}

.achivements__item img:first-of-type {
  display: none;
}

.achivements__item img:nth-of-type(2) {
  display: block;
}

.achivements__item--done {
  filter: none;
}

.achivements__item--done img:first-of-type {
  display: block;
}

.achivements__item--done img:nth-of-type(2) {
  display: none;
}

@media (max-width: 900px) {
  .achivements__item {
    margin-right: 10px;
  }
  .achivements__item img {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 599px) {
  .achivements__item {
    margin-bottom: 8px;
  }
  .achivements__item img {
    width: 62px;
    height: 62px;
  }
}

.achivements__tooltip {
  display: none;
  position: absolute;
  background-color: #ffffff;
  border: 0.5px solid #EEEEEE;
  box-shadow: 0px 25px 60px rgba(38, 38, 38, 0.07);
  border-radius: 10px;
  width: 294px;
  padding: 15px 19px 27px;
  z-index: 100;
  opacity: 0;
  left: 5px;
  transition: opacity 0.3s ease-in-out;
}

.achivements__tooltip h3 {
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #0D2038;
  margin-bottom: 16px;
}

.achivements__tooltip h3 span {
  margin-left: 6px;
}

.achivements__tooltip p {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 18px;
}

@media (max-width: 900px) {
  .achivements__tooltip:after {
    left: 19px;
  }
}

.achivements__item:hover,
.achivements__item:focus {
  z-index: 102;
}

.achivements__item:hover:after,
.achivements__item:focus:after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  transform: translateX(-50%) rotate(45deg);
  border-left: 0.5px solid #EEEEEE;
  border-top: 0.5px solid #EEEEEE;
  position: absolute;
  left: 50%;
  bottom: -12px;
  z-index: 102;
}

.achivements__item:hover .achivements__tooltip {
  display: block;
  opacity: 1;
}

.achivements-mini {
  display: inline-flex;
  flex-wrap: wrap;
  align-self: flex-start;
}

.achivements-mini__item {
  display: inline-block;
  margin-right: 5px;
  line-height: 0;
  font-size: 0;
}

.achivements-mini__item img {
  height: 17px;
  width: auto;
}

.achivements-mini__count {
  height: 17px;
  width: 17px;
  background-color: #FFD24D;
  border-radius: 50%;
  font-weight: bold;
  font-size: 9px;
  line-height: 17px;
  text-align: center;
  padding-right: 3px;
}

p.achivements__ya-share {
  padding-top: 16px;
  padding-bottom: 9px;
  margin: 0;
  border-top: 1px solid #EEEEEE;
  font-size: 12px;
  line-height: 16px;
  color: #A5A8AD;
  margin-left: 2px;
}

.achivements .ya-share2 {
  margin-left: 3px;
}

.achivements .ya-share2__container_size_m .ya-share2__item {
  margin-right: 12px;
}

.achivements .ya-share2__item .ya-share2__badge {
  background: transparent;
}

.achivements .ya-share2__item_service_facebook .ya-share2__icon {
  background: url(/media/images/fb.svg);
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

.achivements .ya-share2__item_service_vkontakte .ya-share2__icon {
  background: url(/media/images/vk.svg);
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

.achivements .ya-share2__item_service_odnoklassniki .ya-share2__icon {
  background: url(/media/images/odnoclassniki.svg);
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

.achivements .ya-share2__item_service_whatsapp .ya-share2__icon {
  background: url(/media/images/whatsapp.svg);
  background-position: center center;
  background-size: 24px 24px;
  background-repeat: no-repeat;
}

.city-block {
  position: relative;
  order: 1;
  display: flex;
  align-items: center;
  justify-items: flex-start;
}

@media (max-width: 1600px) {
  .city-block {
    order: 2;
    position: absolute;
    top: 10px;
    left: 55px;
  }
}

@media (max-width: 1300px) {
  .city-block {
    position: static;
    margin-bottom: 10px;
    margin-top: 5px;
  }
}

@media (max-width: 639px) {
  .city-block {
    margin-top: 0;
  }
}

.city-block__pointer {
  width: 140px;
  min-width: 140px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px 0 24px;
  position: relative;
  background-image: url(../images/geoip-arrow.svg);
  background-repeat: no-repeat;
  background-position: center left;
  margin-right: 20px;
}

.city-block__pointer span {
  color: #000;
  font-size: 14px;
}

@keyframes moveit {
  from {
    left: 0;
  }
  10% {
    left: 20px;
  }
  20% {
    left: 0;
  }
  to {
    left: 0;
  }
}

.page-header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 56px 0 40px;
}

.header_logo {
  display: flex;
  align-items: center;
}

.logo-pic {
  width: 48px;
  margin-right: 16px;
}

.logo-text {
  width: 141px;
}

.city-block__name {
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  transition-property: color, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  font-family: "OpenSans-Semibold", "Arial", sans-serif;
  color: #262626;
  display: flex;
  align-items: center;
}

.city-block__name img {
  margin-right: 16px;
}

.city-block__name:hover {
  color: #555659;
}

.city-block__name span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.city-block__name span::before {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  transform: translateY(-3px);
  content: url(../images/arrow-bottom.svg);
}

.page_header, .page_header__inner {
  transition: .25s;
}

@media (max-width: 1279px) {
  .page_header__inner {
    height: auto;
  }
}

.menu-fixed {
  padding-top: 147px;
}

.menu-fixed .page_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 101;
  padding-top: 5px;
}

.menu-fixed .page_header__inner {
  height: 78px;
}

.menu-fixed .nav_main_list__item:after {
  margin-top: 26px;
}

.modal-city__mobile {
  display: none;
}

.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
  border-bottom: 1px solid #edeef0;
}

@media (max-width: 1300px) {
  body {
    padding-top: 83px;
  }
  .page-header__hamburger {
    display: none;
  }
  .city-block__name {
    display: none;
  }
  .modal-city__mobile {
    display: flex;
    padding-left: 0;
  }
  .page_header__inner {
    padding: 17px 26px;
  }
  .menu-fixed {
    padding-top: 83px;
  }
  .menu-fixed .page_header {
    padding-top: 0;
  }
  .logo-text {
    display: none;
  }
  .menu_open .logo-text {
    display: block;
  }
  .menu_open .header_logo {
    z-index: 202;
  }
  .header_logo {
    width: auto;
    height: 48px;
  }
}

@media (max-width: 767px) {
  .page_header__inner {
    padding-left: 21px;
    padding-right: 21px;
    width: 100%;
  }
  .city-block__name img {
    display: none;
  }
  .header-flex {
    flex-direction: column;
    padding-right: 0;
  }
}

.page_header__left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.city-popup__header {
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 1;
  color: #000000;
}

.city-popup__list,
.city-popup__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.city-popup__list--more,
.city-popup__wrapper--more {
  height: 100%;
  max-height: 445px;
  overflow-y: scroll;
}

.city-popup__elem {
  width: 30%;
  margin-bottom: 18px;
}

.city-popup__elem a {
  color: #000000;
  text-decoration: underline;
  font-size: 14px;
  line-height: 18px;
}

.city-popup__elem a:hover {
  color: #ffd24d;
  text-decoration: underline !important;
}

.city-popup__more {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 30px;
}

.city-popup__more span {
  border-bottom: 1px dotted #000;
  color: #000000;
  transition-property: color, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}

.city-popup__more span:hover {
  color: #ffd24d;
  border-bottom: 1px dotted #ffd24d;
}

.city-popup__input,
.page-cities__input {
  margin-bottom: 30px;
}

.city-popup__input .selectize-input,
.page-cities__input .selectize-input {
  border: 1px solid #000000;
  width: 100%;
  max-width: 450px;
  height: 40px;
  line-height: 28px;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-right: 20px;
  color: #000000;
}

.selectize-input > input {
  width: 100% !important;
}

.order-number {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
}

.order-number__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 9px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

.order-number__text {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #a5a8ad;
  margin-bottom: 12px;
  padding-right: 40px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

.order-number__row {
  border: 1px solid #c6c7cE;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 44px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
}

.order-number__form {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 5px;
  max-width: 240px;
}

.order-number__form button {
  appearance: none;
  border: none;
  border-radius: 4px;
  background-color: #ffd24d;
  background-image: url(/media/images/send.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px;
  width: 42px;
  min-width: 42px;
}

.order-number__form button:hover {
  background-color: #ffd966;
}

.order-number__form input {
  min-width: 50px;
  max-width: 100%;
  width: 100%;
  margin-left: 15px;
  border: none;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
}

.order-number__add {
  color: #a5a8ad;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  align-self: flex-start;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

.order-number__add:hover {
  color: #0D2038;
}

.order-history {
  width: 100%;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  background-color: #e3e5e8;
}

.order-history__title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #a5a8ad;
  font-style: normal;
}

.order-history__list {
  display: flex;
  flex-direction: column;
}

.order-history__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  padding-top: 6px;
  padding-right: 12px;
  padding-bottom: 4px;
  padding-left: 12px;
  background: #f5f6f7;
  border-radius: 4px;
}

.order-history__item--success .order-history__order-number:after {
  background-image: url(/media/images/order-success.svg);
}

.order-history__item--waiting .order-history__order-number:after {
  background-image: url(/media/images/order-waiting.svg);
}

.order-history__item--error .order-history__order-number:after {
  background-image: url(/media/images/order-error.svg);
}

.order-history__order-number {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  cursor: unset;
}

.order-history__order-number:after {
  content: '';
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
  margin-left: 7px;
}

.master_answer {
  border-left: 2px solid #ffd24d;
  padding-left: 14px;
  margin-top: 21px;
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .master_answer {
    border-left: 4px solid #ffd24d;
    margin-top: 24px;
    padding-left: 25px;
    padding-right: 6px;
    margin-bottom: 2px;
  }
}

@media (min-width: 900px) {
  .master_answer {
    margin-top: 24px;
    margin-bottom: 6px;
  }
}

@media (min-width: 1280px) {
  .master_answer {
    margin-top: 30px;
    margin-bottom: 14px;
  }
}

.master_answer .master_answer__flag--edit,
.master_answer .master_answer__flag--moderation,
.master_answer .master_answer__flag--rejected {
  display: none;
}

.master_answer--error {
  margin-bottom: 5px;
}

.master_answer--error .master_answer__flag--edit,
.master_answer--error .master_answer__flag--rejected {
  display: inline-block;
}

.master_answer--approved .master_answer__action {
  display: none;
}

.master_answer--error {
  border-left: 2px solid #f5f6f7;
}

@media (min-width: 600px) {
  .master_answer--error {
    border-left: 4px solid #f5f6f7;
  }
}

@media (min-width: 900px) {
  .master_answer--error {
    margin-bottom: 11px;
  }
}

.master_answer--error .master_reply .button {
  margin-right: -1px;
}

@media (min-width: 600px) {
  .master_answer--error .master_reply .button {
    margin-right: 15px;
  }
}

.master_answer--edit .master_answer__flag--edit {
  display: none;
}

.master_answer--moderation {
  border-left: 2px solid #f5f6f7;
  margin-top: 24px;
  margin-bottom: 6px;
}

@media (min-width: 600px) {
  .master_answer--moderation {
    border-left: 4px solid #f5f6f7;
    margin-bottom: 2px;
  }
}

@media (min-width: 900px) {
  .master_answer--moderation {
    margin-top: 25px;
    margin-bottom: 7px;
  }
}

@media (min-width: 1280px) {
  .master_answer--moderation {
    margin-top: 30px;
    margin-bottom: 13px;
  }
}

.master_answer--moderation .master_answer__flag--moderation {
  display: inline-block;
}

.master_answer--moderation .master_reply h4 {
  display: none;
}

.master_answer__text {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #515151;
  margin-bottom: 8px;
}

@media (min-width: 600px) {
  .master_answer__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}

.master_answer__info {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #a5a8ad;
  padding-bottom: 6px;
}

@media (min-width: 600px) {
  .master_answer__info {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 900px) {
  .master_answer__info {
    padding-bottom: 2px;
  }
}

.master_answer__status {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: flex-start;
}

@media (min-width: 600px) {
  .master_answer__status {
    margin-bottom: 15px;
  }
}

.master_answer__flag {
  border-radius: 21px;
  padding: 3px 7px;
  font-family: "Open-Sans", "Arial", sans-serif;
  color: #181818;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  margin-right: 7px;
}

.master_answer__flag--moderation {
  background-color: #e3e5e8;
}

.master_answer__flag--rejected {
  color: #ffffff;
  background-color: #EA3924;
}

.master_answer__flag--edit {
  font-weight: 600;
  color: #262626;
  background-color: #ffd24d;
  cursor: pointer;
}

@media (min-width: 600px) {
  .master_answer__flag {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 15px;
    margin-right: 15px;
  }
}

.master_answer__action {
  cursor: pointer;
  font-family: "Open-Sans", "Arial", sans-serif;
  color: #a5a8ad;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  transition: color 0.3s ease;
  margin-left: auto;
  margin-top: 3px;
}

.master_answer__action span {
  display: none;
}

.master_answer__action:hover {
  color: #262626;
}

@media (min-width: 600px) {
  .master_answer__action {
    font-size: 12px;
    line-height: 16px;
  }
  .master_answer__action span {
    display: inline;
  }
}

.master_answer__flags {
  width: 100%;
}

.master_answer__error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF5F3;
  border: 1px solid #ECECEC;
  border-radius: 4px;
  padding: 6px 12px;
  margin-bottom: 13px;
}

.master_answer__error span {
  font-family: "Open-Sans", "Arial", sans-serif;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #515151;
  max-width: 70%;
}

.master_answer__error svg {
  height: 14px;
  width: 14px;
}

@media (min-width: 600px) {
  .master_answer__error {
    padding: 10px 20px;
    margin-bottom: 21px;
  }
  .master_answer__error span {
    font-size: 14px;
    line-height: 24px;
    max-width: 100%;
  }
}

@media (min-width: 900px) {
  .master_answer__error {
    margin-bottom: 21px;
  }
}

@media (min-width: 1280px) {
  .master_answer__error {
    margin-bottom: 16px;
  }
}

.master_reply h4 {
  margin-top: 15px;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-size: 10px;
  line-height: 14px;
  color: #262626;
  opacity: 0.5;
  margin-bottom: 8px;
}

@media (min-width: 600px) {
  .master_reply h4 {
    margin-top: 30px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }
}

@media (min-width: 900px) {
  .master_reply h4 {
    margin-top: 24px;
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 900px) {
  .master_reply h4 {
    margin-top: 30px;
  }
}

.master_reply textarea {
  width: 100%;
  height: 90px;
  border: 1px solid #C6C7CE;
  border-radius: 4px;
  padding: 10px 16px;
  margin-bottom: 11px;
  font-family: "Open-Sans", "Arial", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #515151;
  resize: none;
  appearance: none;
}

@media (min-width: 600px) {
  .master_reply textarea {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 19px;
    padding: 12px 14px;
  }
}

@media (min-width: 900px) {
  .master_reply textarea {
    padding: 12px 14px;
    line-height: 19px;
  }
}

.master_reply__error {
  margin-bottom: 15px;
  color: rgba(253, 45, 44, 0.61);
}

.master_reply .button {
  margin-right: 15px;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  padding: 0 31px;
  cursor: pointer;
}

@media (min-width: 600px) {
  .master_reply .button {
    font-size: 14px;
    height: 44px;
    line-height: 44px;
    padding: 0 20px;
  }
}

.master_reply .button__secondary {
  padding: 0 24px;
  margin-right: 0;
}

@media (min-width: 600px) {
  .master_reply .button__secondary {
    padding: 0 20px;
  }
}

.all_cities .page_cities {
  padding: 0 80px;
}

@media (max-width: 599px) {
  .all_cities .page_cities {
    padding: 0 10px;
  }
}

.all_cities .page_cities ul li {
  display: inline-block;
  width: 100%;
  margin-bottom: .5em;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.all_cities .page_cities .cities-column {
  column-count: 4;
  margin-bottom: 20px;
}

@media (max-width: 599px) {
  .all_cities .page_cities .cities-column {
    column-count: 1;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .all_cities .page_cities .cities-column {
    column-count: 2;
  }
}

@media (min-width: 900px) and (max-width: 1279px) {
  .all_cities .page_cities .cities-column {
    column-count: 3;
  }
}

.all_cities .page_cities__title h1 {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: bold;
}

.all_cities .page_cities .favorite-cities__title,
.all_cities .page_cities .all-cities__title {
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 18px;
}

.all_cities .page_cities .search-city {
  margin-bottom: 20px;
}

.all_cities .page_cities .search-city a {
  color: #00678c;
  cursor: pointer;
}

.all_cities .page_cities .search-city a:hover {
  color: #fd2d2c;
  border-color: #fd2d2c;
  outline: 0;
}

.all_cities .page_content {
  flex-direction: column;
  justify-content: flex-start;
}

.all_cities .page_title {
  margin: 20px 20px 30px;
}

@media (min-width: 600px) {
  .all_cities .page_title {
    margin: 36px 42px 26px;
  }
}

@media (min-width: 900px) {
  .all_cities .page_title {
    margin-left: 26px;
    margin-right: 26px;
  }
}

@media (min-width: 1280px) {
  .all_cities .page_title {
    margin-left: 80px;
    margin-top: 60px;
  }
}

.grade-rating {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.grade-rating__line {
  height: 4px;
  width: 62px;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  margin-right: 15px;
}

.grade-rating__back {
  height: 4px;
  width: 62px;
  background-color: #ACB0B6;
  position: absolute;
  z-index: 1;
  left: 0;
  top: -1px;
}

.grade-rating__fill {
  height: 4px;
  width: 62px;
  background-color: #ffd24d;
  position: absolute;
  z-index: 2;
  left: -62px;
  top: -1px;
}

.grade-rating__text {
  color: #ffd24d;
  font-size: 12px;
  line-height: 16px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
}

.grade-list {
  background-color: #f8f8f8;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 19px;
}

.grade-list__element {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 8px 12px 8px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EDEDED;
  min-height: 35px;
}

.grade-list__element:nth-last-of-type(2) {
  border: none;
}

.grade-list__element:last-of-type {
  display: none;
}

.grade-list__text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #84878B;
}

.grade-list .master_stars {
  display: flex;
}

.grade-list .master_stars__row {
  flex-direction: row-reverse;
}

.grade-list .master_stars__line {
  width: 62px;
  height: 4px;
  background-color: #ACB0B6;
  border-radius: 0;
  margin-right: 8px;
}

.grade-list .master_stars__line_inner {
  height: 4px;
  border-radius: 0;
}

.grade-list .master_stars__text {
  color: #ffd24d;
  font-size: 12px;
  line-height: 16px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  margin-right: 0;
}

@media (min-width: 600px) {
  .grade-list {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    margin-bottom: 26px;
    border-left: 1px solid #EDEDED;
    border-top: 1px solid #EDEDED;
  }
  .grade-list__element {
    padding: 9px 12px 10px;
    width: 100%;
    border: none;
    border-right: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    min-height: 38px;
  }
  .grade-list__element:nth-last-of-type(2) {
    border-right: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
  }
  .grade-list__element:last-of-type {
    display: block;
  }
  .grade-list .master_stars__text {
    margin-left: 0;
  }
  .grade-list .master_stars {
    padding-top: 0;
  }
}

@media (min-width: 1280px) {
  .grade-list {
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(2, auto);
    margin-bottom: 24px;
  }
  .grade-list__element {
    padding: 9px 13px 10px 15px;
  }
  .grade-list__element:first-of-type {
    order: 0;
  }
  .grade-list__element:nth-of-type(2) {
    order: 1;
  }
  .grade-list__element:nth-of-type(3) {
    order: 4;
  }
  .grade-list__element:nth-of-type(4) {
    order: 2;
  }
  .grade-list__element:nth-of-type(5) {
    order: 3;
  }
  .grade-list__element:nth-of-type(6) {
    order: 5;
  }
}

.chat {
  padding: 0 0 81px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 110px;
}

.chat__wrapper {
  width: 100%;
}

.chat__phrase {
  margin: 18px 15px 0 15px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.chat__disabled-message {
  padding: 10px 16px;
  margin-bottom: 6px;
  background-color: #f5f6f7;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
  border: 1px solid #ffd24d;
}

.chat__photo {
  display: none;
  margin-left: 6px;
}

.chat__photo img {
  height: 54px;
  width: 54px;
}

.chat__message {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 10px 16px 14px;
  margin-right: 13px;
  margin-bottom: 10px;
  background-color: #f5f6f7;
  position: relative;
  font-size: 12px;
  line-height: 160%;
  border-radius: 4px;
}

.chat__message:after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background-color: #f5f6f7;
  transform: rotate(45deg);
  position: absolute;
  right: -7px;
  top: 12px;
  z-index: -1;
}

.chat__time {
  display: block;
  font-size: 10px;
  line-height: 14px;
  color: #a5a8ad;
}

.chat__time span {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.chat__window {
  max-height: 493px;
  height: 493px;
  overflow-y: scroll;
  display: block;
}

@media (min-width: 600px) {
  .chat {
    border: 1px solid #F1F3F5;
    border-radius: 4px;
    margin: 130px 20px 22px;
  }
  .chat__photo {
    display: block;
  }
  .chat__phrase {
    margin: 18px 17px 0 116px;
    padding-bottom: 15px;
  }
  .chat__message {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 12px;
    padding: 15px 25px 15px 19px;
  }
  .chat__disabled-message {
    font-size: 14px;
    line-height: 23px;
    padding: 14px 25px 14px 19px;
  }
  .chat__time {
    font-size: 12px;
    line-height: 16px;
  }
  .chat__window {
    max-height: 518px;
    height: 518px;
  }
}

@media (min-width: 900px) {
  .chat {
    height: 100%;
    margin-left: 30px;
    margin-right: 0;
    margin-bottom: 15px;
    margin-top: 21px;
  }
  .chat__window {
    height: 700px;
    max-height: 700px;
  }
  .chat__phrase {
    margin-left: 76px;
  }
}

@media (min-width: 1280px) {
  .chat {
    margin-left: 80px;
    margin-right: 8px;
    margin-bottom: 29px;
  }
  .chat__phrase {
    margin-left: 156px;
    margin-right: 15px;
  }
  .chat__message {
    padding-left: 21px;
    padding-bottom: 16px;
  }
}

#messageform-message {
  margin-right: 10px;
  resize: none;
  padding-top: 11px;
  line-height: 20px;
}

.chat__phrase--master .chat__message {
  background-color: #FCE8B1;
  margin-right: 0;
  margin-left: 14px;
}

.chat__phrase--master .chat__message:after {
  right: auto;
  left: -7px;
  background-color: #FCE8B1;
}

.chat__phrase--master .chat__time {
  text-align: right;
}

.chat__phrase--master .chat__photo {
  margin-left: 0;
  margin-right: 6px;
}

@media (min-width: 600px) {
  .chat__phrase--master {
    margin: 18px 116px 0 17px;
    padding-bottom: 15px;
  }
  .chat__phrase--master .chat__message {
    padding: 14px 19px 18px 22px;
  }
  .chat__phrase--master .chat__time {
    text-align: left;
    margin-left: 14px;
  }
}

@media (min-width: 900px) {
  .chat__phrase--master {
    margin-right: 76px;
  }
}

@media (min-width: 1280px) {
  .chat__phrase--master {
    margin-right: 156px;
    margin-left: 15px;
  }
  .chat__phrase--master .chat__message {
    padding-bottom: 16px;
  }
}

@media (min-width: 900px) {
  .lt_2col .page_content--listing.page_content--chat {
    display: flex;
  }
}

.lt_2col .page_content__right--empty.page_content__right--listing {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 99;
}

.chats-list {
  padding: 0 20px;
}

.chats-list__status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  right: -9px;
}

.chats-list__status span {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #181818;
}

.chats-list__status svg {
  height: 11px;
  width: 11px;
  margin-top: -1px;
}

.chats-list__link {
  display: flex;
  flex-direction: column;
}

.chats-list__element {
  border: 1px solid #F1F3F5;
  border-radius: 4px;
  background-color: transparent;
  padding-bottom: 14px;
  margin-bottom: 14px;
  position: relative;
}

.chats-list__element:hover {
  background-color: #f5f6f7;
}

.chats-list__element:hover .stars__mask {
  background-image: url("/media/images/stars-grey.svg");
}

.chats-list__element--active {
  border: 1px solid #ffd24d;
}

.chats-list__element--active .chats-list__status {
  background-color: #ffd24d;
}

.chats-list__element--closed {
  background-color: #f5f6f7;
}

.chats-list__element--closed .chats-list__link .stars__mask {
  background-image: url(/media/images/dark-stars.svg);
  background-size: 85px;
  background-position: left center;
}

.chats-list__element--closed .stars__list, .chats-list__element--closed .stars__fill {
  background-color: transparent;
}

.chats-list__element--closed .chats-list__status {
  background-color: #C6C9CC;
}

.chats-list__head {
  border-bottom: 1px solid #F1F3F5;
  padding: 9px 11px 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.chats-list__master-data {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}

.chats-list__photo {
  margin-right: 12px;
}

.chats-list__photo img {
  width: 24px;
  height: 24px;
}

.chats-list__name {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
}

.chats-list__rating .stars {
  align-items: flex-end;
}

.chats-list__rating .stars__quantity {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #a5a8ad;
}

.chats-list__message {
  padding: 0 12px;
}

.chats-list__text {
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  margin: 8px 0 4px;
}

.chats-list__time {
  font-size: 10px;
  line-height: 14px;
  color: #a5a8ad;
  padding: 0;
}

.chats-list .stars__mask {
  height: 17px;
  transition: none;
}

.chats-list .stars__list {
  display: none;
  height: 17px;
  width: 85px;
}

@media (min-width: 600px) {
  .chats-list {
    padding: 0 6px 0 13px;
  }
  .chats-list__head {
    padding: 12px 15px 9px;
  }
  .chats-list .stars__list {
    display: block;
    margin-right: 8px;
    margin-top: -1px;
  }
  .chats-list__message {
    padding: 0  15px;
  }
  .chats-list__text {
    margin: 14px 0 7px;
  }
  .chats-list__time {
    font-size: 12px;
    line-height: 16px;
  }
  .chats-list__element {
    padding-bottom: 12px;
  }
  .chats-list__rating .stars__quantity {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (min-width: 900px) {
  .chats-list {
    padding: 0 28px;
  }
  .chats-list__element {
    padding-bottom: 1px;
    margin-bottom: 16px;
  }
  .chats-list__head {
    border: none;
    padding-top: 14px;
    align-items: flex-start;
    width: 236px;
  }
  .chats-list__master-data {
    flex-direction: column;
    margin-top: 3px;
  }
  .chats-list__message {
    display: flex;
    flex-direction: column;
    padding-right: 45px;
  }
  .chats-list__text {
    margin: 17px 0 11px;
  }
  .chats-list__status {
    top: 15px;
    right: 15px;
  }
  .chats-list__link {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .chats-list__name {
    margin-bottom: 8px;
  }
  .chats-list__photo {
    margin-right: 14px;
  }
  .chats-list__photo img {
    height: 54px;
    width: 54px;
  }
  .chats-list .stars {
    margin-left: -2px;
  }
}

@media (min-width: 1280px) {
  .chats-list {
    padding-left: 80px;
    padding-right: 8px;
  }
  .chats-list__element {
    margin-bottom: 12px;
  }
}

.chats-list__message--user .chats-list__mini-photo {
  display: none;
  margin-right: 8px;
  margin-top: 12px;
}

.chats-list__message--user .chats-list__mini-photo img {
  height: 16px;
  width: 16px;
}

@media (min-width: 1280px) {
  .chats-list__message--user .chats-list__mini-photo {
    display: block;
  }
}

.chats-list__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.chat-reply {
  border-top: 1px solid #F1F3F5;
  border-radius: 4px;
  padding: 18px 15px 0;
  width: 100%;
  background-color: #ffffff;
}

.chat-reply__row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.chat-reply input {
  width: 100%;
  margin-right: 14px;
}

@media (min-width: 600px) {
  .chat-reply {
    padding: 18px;
  }
}

@media (min-width: 1280px) {
  .chat-reply {
    border: 1px solid #F1F3F5;
  }
}

.master-chat {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 16px 23px 14px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f5f6f7;
}

.master-chat__photo {
  margin-right: 14px;
}

.master-chat__photo img {
  height: 52px;
  width: 52px;
}

.master-chat__name {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 18px;
  line-height: 25px;
  color: #262626;
  margin-bottom: 6px;
  margin-top: 2px;
}

.master-chat__status {
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  font-size: 10px;
  line-height: 14px;
  padding-left: 17px;
  position: relative;
  color: #a5a8ad;
}

.master-chat__status:before {
  content: '';
  display: block;
  height: 12px;
  width: 12px;
  background-color: #ffd24d;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 600px) {
  .master-chat {
    padding: 20px 23px 14px;
    top: 98px;
  }
  .master-chat__name {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 12px;
  }
  .master-chat__status {
    font-size: 12px;
    line-height: 16px;
    padding-left: 24px;
    letter-spacing: -0.3px;
  }
  .master-chat__status:before {
    height: 16px;
    width: 16px;
  }
  .master-chat__photo {
    margin-right: 20px;
  }
  .master-chat__photo img {
    height: 72px;
    width: 72px;
  }
}

@media (min-width: 900px) {
  .master-chat {
    position: relative;
    width: 400px;
    right: 97px;
    top: -8px;
    background-color: transparent;
    z-index: 100;
    padding-bottom: 24px;
  }
}

@media (min-width: 900px) {
  .page_content__right--chat .master_stats, .master_stats.is_fixed, .master_stats.is_bottom {
    top: 0;
    max-width: 320px;
  }
}

@media (min-width: 1024px) {
  .lt_1col .page_title {
    margin-left: 120px;
  }
}

.masters-listing {
  padding-left: 0;
}

.main_portfolio {
  padding: 50px 0 80px;
}

.container-wrapper {
  margin-left: 120px;
  margin-right: 150px;
}

.info-top {
  display: flex;
  position: relative;
}

.is_gray_wrapper .main_testimonials {
  background: #F5F6F7;
}

.is_gray_wrapper .master_feedback {
  background: #fff;
}

.main_ready_banner {
  text-align: center;
  background: url("../images/check-mark.png") no-repeat 52%;
}

.main_ready_banner .main__section_title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  text-align: center;
  color: #262626;
}

.main_ready_banner .main__section_sub_title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #919599;
}

.master-grid-container {
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr;
  grid-template-rows: 0.7fr 0.7fr;
  grid-template-areas: "master master photos photos" "inf-1 inf-1 photos photos";
}

.master-grid-container > div {
  border: 1px solid #EDEEF0;
}

.master-grid-container .master .fav_link {
  position: unset;
}

.master-grid-container .master .fav_link svg.ic.ic--fav-tag {
  height: 15px;
  width: 15px;
  margin-top: -5px;
}

.photos {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 1fr;
  grid-template-rows: 1.4fr 0.6fr;
  gap: 1px 1px;
  grid-template-areas: "photo-items photo-items photo-items" "btn-1 btn-1 btn-1";
  grid-area: photos;
}

.photos .title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #262626;
}

.photo-items {
  grid-area: photo-items;
  border-bottom: 2px solid #EDEEF0;
  padding: 2%;
}

.photo-items-wrapper {
  display: flex;
  margin-top: 15px;
}

.photo-items-wrapper .photo_item__img {
  height: 150px;
  overflow: hidden;
}

.photo-items-wrapper .photo_item__img img {
  width: 100%;
  height: auto;
}

.filter-check__mobile {
  display: none;
}

.listing.listing--v2.masters-listing p {
  text-align: center;
  padding: 0 15px;
}

.is_gray_wrapper {
  margin: 50px 0 0;
}

.filter_block {
  display: flex;
  border-bottom: 2px solid #EDEEF0;
  justify-content: space-between;
  padding-bottom: 50px;
  margin-bottom: 50px;
  align-items: flex-end;
}

.filter_block .dropdown--autocomplete:before {
  display: none;
}

.filter_block .ic--dropdown-arrow {
  position: absolute;
  right: 15px;
  top: 20px;
  fill: #C4C4C4;
  z-index: 5;
}

.filter_block .filter_item.apply {
  display: flex;
  align-items: flex-end;
}

.filter_block .dropdown__list.additional_filer {
  padding: 15px;
  min-height: 200px;
}

.filter_block .dropdown__list.additional_filer .radio_list {
  border-bottom: 1px solid #EDEEF0;
}

.filter_block .dropdown__list.additional_filer .radio_list .radio_item {
  margin-bottom: 7%;
}

.filter_block .dropdown__list.additional_filer .radio_list .radio_item__text {
  color: #808080;
}

.filter_block .dropdown__list.additional_filer .radio_list .radio_item__checked-active {
  background-color: #fff;
}

.filter_block .dropdown__list.additional_filer .radio_list .radio_item__checked-active + .radio_item__text {
  color: #000;
}

.filter_block .dropdown__list.additional_filer .radio_list .radio_item__checked-active:before {
  width: 13px;
  height: 13px;
  background-color: #FFD24D;
}

.filter_block .only_checked_masters {
  margin-bottom: 0;
}

.filter_block .only_checked_masters .switcher {
  margin-top: 20px;
}

.filter_block .only_checked_masters .switcher .toggle .minitoggle {
  width: 50px;
}

.filter_block .only_checked_masters .switcher .toggle .text {
  margin-left: 10px;
  font-size: 12px;
}

.master_list {
  margin: 3%;
}

.master_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-review {
  width: 70%;
}

.btn-chat {
  width: 90%;
}

.btn-1 {
  grid-area: btn-1;
  height: 90px;
}

.btn-1 .button {
  margin: 5%;
}

.btn-2 {
  grid-area: btn-2;
}

.master {
  grid-area: master;
  padding: 2%;
}

.master_skills {
  font-size: 12px;
  line-height: 18px;
  color: #262626;
  margin-top: 10px;
}

.master_skills .more {
  color: #a5a8ad;
}

.master .help-text {
  display: inline-block;
  height: 17px;
  line-height: 17px;
  white-space: nowrap;
  border-radius: 18px;
  background-color: #f4f4f4;
  font-size: 11px;
  padding: 0 8px;
  color: #262626;
  margin-right: 6px;
  vertical-align: middle;
  margin-bottom: 6px;
  cursor: pointer;
}

.master .ic--star {
  fill: #ffd24d;
  width: 12px;
  height: 12px;
  top: -1px;
}

.master_rating .ic--star {
  fill: #E3E5E8;
  width: 15px;
  height: 15px;
}

.master_rating .yellow {
  fill: #ffd24d;
}

.master_rating .number {
  font-weight: 600;
  font-size: 12px;
  color: #333333;
}

.master .main_skill {
  font-size: 12px;
}

.master .count_review {
  font-size: 12px;
  color: #333333;
}

.master .count_review .number {
  font-weight: 600;
}

.master-info {
  display: flex;
}

.master-info .title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  color: #262626;
}

.master-info .ava_wrapper {
  width: 54px;
  min-width: 54px;
  height: 54px;
  margin-right: 18px;
  border-radius: 50%;
  overflow: hidden;
}

.master-info .checked_master {
  border: 2px solid #FFD24D;
}

.master-info .achivements-mini {
  padding: 3px;
  padding-left: 10px;
}

.master_bottom_info {
  display: flex;
  justify-content: space-between;
}

.inf-1 {
  position: relative;
  grid-area: inf-1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a5a8ad;
}

.inf-1:after {
  width: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  content: '';
  display: none;
  background: #edeef0;
}

.inf-1.empty:after {
  display: none;
}

@media (min-width: 640px) {
  .inf-1:after {
    display: block;
  }
}

.master-prices {
  width: 100%;
  padding: 4px 0;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
}

@media (min-width: 640px) {
  .master-prices {
    padding: 0;
  }
}

@media (min-width: 640px) {
  .master-prices {
    flex-direction: row;
  }
}

.master-prices__btn-all {
  margin-top: 5px;
  color: #d9aa21;
}

@media (min-width: 640px) {
  .master-prices__btn-all {
    margin-top: 0;
  }
}

.master-price {
  width: 100%;
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  color: #262626;
  font-size: 12px;
  line-height: 18px;
}

@media (min-width: 640px) {
  .master-price {
    width: 50%;
    max-width: 50%;
    padding: 6px 24px;
  }
}

.master-price__val {
  font-weight: 600;
}

.image--master {
  position: absolute;
  right: 10%;
  bottom: -4px;
  z-index: 1;
}

.subtitle {
  font-family: Open Sans;
  font-size: 20px;
  color: #262626;
}

.master_specs {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #262626;
  display: flex;
  padding: 50px 0 0;
  justify-content: space-between;
}

.info--master {
  position: relative;
  z-index: 2;
  width: 50%;
}

.info--master .page_title__text {
  width: 80%;
  margin-bottom: 5%;
}

.master_spec {
  display: flex;
  margin-bottom: 77px;
  width: calc(100% * (1 / 3) - 11px);
  align-items: center;
  margin-right: 20px;
}

.master_spec:first-child {
  width: 50px;
  min-width: 99px;
}

.master_spec:last-child {
  width: 290px;
}

.master_spec:before {
  content: '';
  display: block;
  border: 4px solid #FFD24D;
  margin-right: 20px;
  height: 50px;
}

.create_order_banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Open Sans;
  font-weight: 600;
  background: #EAECEF;
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  height: 115px;
  padding-left: 120px;
  padding-right: 200px;
}

@media (max-width: 1024px) {
  .master_specs {
    justify-content: unset;
    flex-wrap: wrap;
  }
  .masters-listing {
    padding-left: 20px;
  }
  .filter_block {
    padding: 20px;
  }
  .container-wrapper {
    margin-left: 25px;
    margin-right: 25px;
  }
  .info--master {
    width: 80%;
  }
  .image--master {
    right: -15%;
  }
  .master_spec:first-child {
    width: 100px;
  }
  .create_order_banner {
    padding-left: 50px;
    padding-right: 50px;
  }
  .main_testimonials__container {
    margin: 41px auto 0;
  }
  .main_testimonials__container .master_feedback {
    margin: 0;
    padding: 35px 40px;
  }
  .main_testimonials__container .master_feedback:not(.slick-current) {
    filter: unset;
    opacity: 1;
  }
  .btn-1 .button {
    margin: auto;
  }
  .btn-review {
    width: 100%;
  }
  .btn-chat {
    width: 100%;
  }
}

.filter_item .section_title {
  margin: 20px 0 10px;
}

@media (max-width: 768px) {
  p.section_title {
    margin: 20px 0 10px 0;
  }
  .filter_block .skill-chouser {
    margin: 0 !important;
  }
  .filter-check__mobile {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    justify-content: center;
    padding-bottom: 34px;
    margin-top: 48px;
  }
  .filter-check__mobile:after {
    content: url(../images/filter-check__arrow.svg);
    margin-left: 8px;
    transition: .25s;
  }
  .filter-check__mobile.active:after {
    transform: rotate(180deg);
  }
  .container-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .filter_block {
    display: none;
    padding-top: 0;
  }
  .info-top {
    display: block;
  }
  .master_button {
    display: block;
    height: auto;
    text-align: center;
  }
  .btn-1 .button {
    margin: 2%;
  }
  .btn-review {
    width: 80%;
    font-size: 12px;
  }
  .btn-chat {
    width: 80%;
    font-size: 12px;
  }
  .master-grid-container {
    display: block;
  }
  .main_warranty {
    background-position: 0;
  }
  .create_order_banner .button__place_order {
    margin: 10%;
  }
  .page_content_section_head_title {
    text-align: center;
  }
  .breadcrumbs {
    padding-left: 30%;
  }
  .info--master {
    width: 100%;
    text-align: center;
  }
  .info--master .page_title__text {
    width: 100%;
    margin-bottom: 5%;
  }
  .master_specs {
    padding: 20px 0 0;
  }
  .master_spec {
    margin-bottom: 10px;
  }
  .subtitle {
    font-size: 16px;
  }
  .image--master {
    right: 8%;
    width: 400px;
    bottom: 0;
    position: relative;
    height: 260px;
    overflow: hidden;
  }
  .image--master img {
    position: absolute;
    top: 0;
    right: 0;
  }
  .create_order_banner {
    text-align: center;
    display: block;
    padding: 10%;
    height: 260px;
  }
}

.upload-thumb {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  line-height: 0;
  height: 107px;
  width: 107px;
  margin-right: 13px;
  margin-bottom: 14px;
  border-radius: 3px;
  background-color: #EDEEF0;
}

.upload-thumb:nth-child(5n) {
  margin-right: 0;
}

@media (max-width: 900px) {
  .upload-thumb {
    height: 125px;
    width: 125px;
    margin-right: 16px;
  }
  .upload-thumb:nth-child(5n) {
    margin-right: 16px;
  }
  .upload-thumb:nth-child(3n) {
    margin-right: 0;
  }
}

.upload-thumb--loader {
  justify-content: center;
}

@media (max-width: 599px) {
  .upload-thumb {
    height: 136px;
    width: 136px;
    margin-right: 17px;
  }
  .upload-thumb:nth-child(5n) {
    margin-right: 17px;
  }
  .upload-thumb:nth-child(3n) {
    margin-right: 17px;
  }
  .upload-thumb:nth-child(2n) {
    margin-right: 0;
  }
}

.upload-thumb:hover .upload-thumb__del,
.upload-thumb:hover .upload-thumb__sortable-handler,
.upload-thumb:hover .upload-thumb__move,
.upload-thumb:hover .upload-thumb__cover {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 600px) {
  .upload-thumb:hover .upload-thumb__sortable-handler {
    display: none;
  }
}

.upload-thumb__sortable-handler {
  height: 15px;
  width: 15px;
  background: #262626;
  border-radius: 50%;
  box-shadow: 0 0 2px #EDEEF0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  position: absolute;
  left: 3px;
  top: 3px;
  cursor: pointer;
}

.upload-thumb__sortable-handler svg {
  width: 15px;
  height: 15px;
}

@media (max-width: 1280px) {
  .upload-thumb__sortable-handler {
    height: 17px;
    width: 17px;
    visibility: hidden;
    opacity: 1;
  }
}

.swiper_album {
  overflow: hidden;
  display: flex;
  font-family: "OpenSans", "Arial", sans-serif;
}

.swiper_album .btn_update {
  height: 25px;
  font-size: 12px;
  line-height: 0;
  padding-left: 25px;
  padding-right: 10px;
}

.swiper_album .plain-text {
  font-weight: normal;
}

.swiper_album--gallery {
  background-color: #262626;
}

.swiper_album--gallery .top {
  color: #262626;
  display: inline-flex;
  justify-content: space-between;
}

.swiper_album--gallery .top .div svg {
  margin: 0 5px;
}

.swiper_album--gallery .top .divider {
  display: inline-flex;
}

.swiper_album--gallery .swiper-slide .upload-thumb__del {
  display: none;
}

.swiper_album--gallery .gallery-thumbs {
  margin-top: 5px;
}

.swiper_album--gallery .scrollbar-container {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.swiper_album--gallery .swiper-arrows svg {
  width: 12px;
  height: 10px;
}

.swiper_album--gallery .swiper-scrollbar {
  display: block !important;
  height: 6px;
  width: auto;
  background-color: grey;
  margin: auto 30px;
}

.swiper_album--gallery .swiper-scrollbar-drag {
  background-color: white;
}

.swiper_album--description {
  overflow: paged-y;
}

.swiper_album--description .top {
  display: flex;
}

.swiper_album--description .usermedia {
  display: inline-flex;
}

.swiper_album--description .usermedia .name {
  vertical-align: middle;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  width: 135px;
}

.swiper_album--description .object-description .title {
  margin: 0 0 6px 0;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
}

.swiper_album--description .object-description .plain-text {
  font-size: 16px;
}

.swiper_album .description-content {
  font-size: 16px;
}

.swiper_album .description-content p {
  margin-bottom: 30px;
}

.swiper_album .description-content .shadow-title {
  opacity: .5;
}

.swiper_album .nav_user__photo-54 {
  width: 54px;
  height: 54px;
  margin: 0 15px 0 0;
}

.swiper_album .slim-btn {
  border-radius: 30px;
  align-content: center;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 10px;
  line-height: 23px;
  height: 24px;
}

.swiper_album .slim-btn a {
  magrin: 12px 12px;
  font-size: 10px;
  line-height: 14px;
}

.swiper_album .slim-btn svg {
  margin: 0 5px;
}

.swiper_album .slim-btn--primary {
  background-color: #FFD24D;
  color: #262626;
}

.swiper_album .slim-btn--secondary {
  background-color: #5F5F61;
  color: white;
}

.swiper_album .back-link {
  color: white;
  opacity: .5;
  font-weight: 400;
  width: 62px;
  line-height: 24px;
  cursor: pointer;
  transition-duration: 0.5s;
}

.swiper_album .back-link:hover {
  transition-duration: 0.2s;
  opacity: 0.8;
}

.swiper_album .close-link {
  width: 24px;
  height: 24px;
  margin-left: 0;
}

.swiper_album .edit-link {
  width: auto;
  padding: 0 10px;
  line-height: 24px;
}

.swiper_album .delete-link {
  line-height: 24px;
  background-color: #5F5F61;
  color: white;
  font-size: 12px;
  padding: 0 10px;
}

@media (min-width: 1300px) {
  .swiper_album {
    flex-direction: row;
    min-height: 720px;
    width: 1280px;
    overflow: hidden;
  }
  .swiper_album--gallery {
    width: 853px;
    min-height: 720px;
  }
  .swiper_album--gallery .top {
    width: 773px;
    margin: 38px;
  }
  .swiper_album--gallery .top .close-link {
    display: none;
  }
  .swiper_album--gallery .gallery-top {
    height: 421px;
    width: 773px;
  }
  .swiper_album--gallery .gallery-thumbs {
    width: 773px;
    height: 95px;
  }
  .swiper_album--gallery .scrollbar-container {
    margin: 43px 40px;
  }
  .swiper_album--gallery .swiper-scrollbar {
    width: 694px;
  }
  .swiper_album--description {
    max-width: 427px;
    max-height: 720px;
    overflow-y: auto;
    width: 100%;
  }
  .swiper_album--description .top {
    flex-direction: column;
    margin: 23px 40px 30px 40px;
  }
  .swiper_album--description .top .usermedia {
    padding-bottom: 23px;
    margin-bottom: 30px;
    border-bottom: 1px solid #EDEEF0;
  }
  .swiper_album--description .description-content {
    margin: 0 40px;
  }
}

@media (max-width: 1301px) and (min-width: 930px) {
  .swiper_album {
    flex-direction: column;
    width: 900px;
  }
  .swiper_album .modal-wrapper__close {
    display: none;
  }
  .swiper_album--gallery {
    width: 900px;
    height: 693px;
  }
  .swiper_album--gallery .top {
    margin: 33px;
    width: 840px;
    display: inline-flex;
    justify-content: space-between;
  }
  .swiper_album--gallery .top .divider {
    padding-left: 381px;
  }
  .swiper_album--gallery .gallery-top {
    height: 431px;
    width: 834px;
  }
  .swiper_album--gallery .gallery-thumbs {
    width: 834px;
    height: 95px;
  }
  .swiper_album--gallery .scrollbar-container {
    padding: 30px 33px;
  }
  .swiper_album--gallery .swiper-scrollbar {
    width: 754px;
  }
  .swiper_album--description {
    width: 900px;
    padding: 29px 33px 41px 33px;
  }
  .swiper_album--description .top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: 19px;
    margin-bottom: 23px;
    border-bottom: 1px solid #EDEEF0;
  }
}

@media (max-width: 931px) and (min-width: 600px) {
  .swiper_album {
    flex-direction: column;
    width: 600px;
  }
  .swiper_album .modal-wrapper__close {
    display: none;
  }
  .swiper_album--gallery {
    width: 600px;
    padding: 22px;
  }
  .swiper_album--gallery .gallery-top {
    width: 556px;
    height: 281px;
  }
  .swiper_album--gallery .gallery-thumbs {
    height: 64px;
    margin-top: 6px;
  }
  .swiper_album--gallery .top {
    margin-bottom: 22px;
    width: 560px;
  }
  .swiper_album--gallery .divider {
    padding-left: 160px;
  }
  .swiper_album--gallery .scrollbar-container {
    margin: 20px 20px 0 20px;
  }
  .swiper_album--gallery .swiper-scrollbar {
    width: 507px;
  }
  .swiper_album--description {
    width: 600px;
    height: auto;
    padding: 22px;
  }
  .swiper_album--description .top {
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }
  .swiper_album--description .top .object-description {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EDEEF0;
  }
}

@media (max-width: 600px) and (min-width: 320px) {
  .swiper_album {
    flex-direction: column;
    width: 280px;
  }
  .swiper_album .gallery-top-wrapper {
    width: 260px;
    margin: 0 30px;
  }
  .swiper_album .modal-wrapper__close {
    display: none;
  }
  .swiper_album--gallery {
    width: 280px;
    height: 289px;
  }
  .swiper_album--gallery .top {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 18px 15px 18px 22px;
    width: auto;
  }
  .swiper_album--gallery .top .edit-link {
    font-size: 10px;
    line-height: 24px;
    width: 157px;
  }
  .swiper_album--gallery .top .close-link {
    margin: 0;
  }
  .swiper_album--gallery .top* {
    margin: 0;
  }
  .swiper_album--gallery .top .back-link {
    width: auto;
  }
  .swiper_album--gallery .top .back-link span {
    display: none;
  }
  .swiper_album--gallery .gallery-top {
    height: 194px;
    width: 262px;
  }
  .swiper_album--gallery .gallery-thumbs {
    display: none;
  }
  .swiper_album--gallery .delete-link {
    display: none;
  }
  .swiper_album--gallery .scrollbar-container {
    display: none;
  }
  .swiper_album--description {
    width: 280px;
    height: auto;
    padding: 22px;
  }
  .swiper_album--description .top {
    margin-bottom: 30px;
    flex-direction: column-reverse;
  }
  .swiper_album--description .top .object-description {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #EDEEF0;
  }
  .swiper_album--description .top .object-description .title {
    font-size: 16px;
  }
  .swiper_album--description .top .object-description .plain-text {
    font-size: 12px;
  }
  .swiper_album--description .top .usermedia .name {
    font-size: 14px;
    line-height: 19px;
  }
}

@media (max-width: 600px) {
  .swiper_album--gallery .swiper-slide .upload-thumb__del {
    display: flex;
    top: 15px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
}

@keyframes bounce {
  0% {
    left: 6px;
  }
  80% {
    left: calc(100% - 30px);
  }
  90% {
    left: calc(100% - 35px);
  }
  100% {
    left: calc(100% - 30px);
  }
}

@keyframes bounceOut {
  0% {
    left: calc(100% - 30px);
  }
  80% {
    left: 6px;
  }
  90% {
    left: 10px;
  }
  100% {
    left: 6px;
  }
}

.modal-wrapper--album {
  max-width: 800px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 0;
}

.modal-wrapper--album .button__shadow {
  color: #A5A8AD;
  border: 2px solid #E3E5E8 !important;
}

.modal-wrapper--album .form_button_next {
  width: 121px;
}

.modal-wrapper--album .form_row {
  margin-bottom: 10px;
  flex-flow: row nowrap;
  align-items: flex-start;
}

@media (min-width: 900px) {
  .modal-wrapper--album .form_row {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .modal-wrapper--album .form_row {
    width: 100%;
    margin-bottom: 25px !important;
  }
}

@media (max-width: 599px) {
  .modal-wrapper--album .form_row {
    width: 100%;
    margin-bottom: 24px;
  }
}

.modal-wrapper--album .form_row__description {
  display: flex;
  flex-direction: row;
}

.modal-wrapper--album .form_row__description .form_cell--label {
  width: 100%;
}

.modal-wrapper--album .form_row--half-width {
  margin-bottom: 16px;
  width: calc(50% - 10px);
}

.modal-wrapper--album .form_row--half-width:first-child {
  margin-right: 10px;
}

.modal-wrapper--album .form_row--half-width:nth-child(2) {
  margin-left: 10px;
}

@media (max-width: 599px) {
  .modal-wrapper--album .form_row--half-width {
    width: 100%;
  }
  .modal-wrapper--album .form_row--half-width:first-child {
    margin-right: 0;
  }
  .modal-wrapper--album .form_row--half-width:nth-child(2) {
    margin-left: 0;
  }
}

.modal-wrapper--album .form_button_prev {
  background-color: rgba(120, 121, 132, 0.3);
  width: 120px;
  padding: 0 50px;
}

.modal-wrapper--album .is_hidden {
  display: none;
}

.modal-wrapper--album .select-type-block {
  box-shadow: 0 14px 64px rgba(38, 38, 38, 0.09);
}

@media (min-width: 900px) {
  .modal-wrapper--album .form-section {
    width: 590px;
  }
}

.modal-wrapper--album .album_create_wrapper {
  padding: 0 20px;
  margin-bottom: 60px;
  max-width: 670px;
}

.modal-wrapper--album .album_create_wrapper .select-type-wrapper {
  display: flex;
  flex-direction: row;
}

.modal-wrapper--album .album_create_wrapper .form_row_nowrap {
  justify-content: space-between;
}

.modal-wrapper--album .album_create_wrapper .select-type-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #FFFFFF;
  border-radius: 6px;
  height: 365px;
  width: 285px;
  margin: 10px;
  padding: 0 20px;
}

.modal-wrapper--album .album_create_wrapper .select-type-block small {
  font-size: 14px;
  opacity: .5;
  text-align: center;
}

.modal-wrapper--album .form_cell--field {
  width: 100%;
  flex-flow: row wrap;
}

.modal-wrapper--album .form_cell--centered {
  align-items: center;
}

.modal-wrapper--album .form_row--with_hint .form_cell--label {
  padding-left: 24px;
  width: 100%;
}

.modal-wrapper--album .form_row--with_hint .form_cell--label--budget {
  margin-left: 25px;
}

.modal-wrapper--album .form__label {
  margin-bottom: 16px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
  opacity: 0.5;
}

.modal-wrapper--album .form__label span {
  display: block;
  font-size: 12px;
  font-family: "Open-Sans", "Arial", sans-serif;
  color: #a5a8ad;
  padding-top: 10px;
}

.modal-wrapper--album .form__label .ic {
  fill: #ffd24d;
}

@media (max-width: 599px) {
  .modal-wrapper--album .form__label {
    margin-bottom: 8px;
  }
}

.modal-wrapper--album .form__text {
  border-color: #c6c7cE;
  width: 100%;
}

.modal-wrapper--album .form__text:focus {
  border-color: #ffd24d;
}

.modal-wrapper--album .form_cell__inner {
  width: 100%;
}

@media (max-width: 639px) {
  .modal-wrapper--album .form_cell.is_spec {
    flex-direction: column;
  }
  .modal-wrapper--album .form_cell.is_spec .form_cell__inner {
    flex-basis: unset;
  }
}

.modal-wrapper--album .form-section {
  margin-top: 0;
  margin-bottom: 30px;
}

@media (min-width: 600px) {
  .modal-wrapper--album .form-section {
    margin-bottom: 0;
  }
}

.modal-wrapper--album .form-section .file__descr_text {
  margin-bottom: 30px;
}

.modal-wrapper--album textarea.form__text {
  resize: none;
  height: 164px;
  line-height: 20px;
  padding: 12px 17px 15px;
}

@media (max-width: 599px) {
  .modal-wrapper--album textarea.form__text {
    height: 158px;
  }
}

.modal-wrapper--album .ic--upload-orange {
  height: 46px;
  width: 71px;
}

.modal-wrapper--album .ic--upload {
  height: 34px;
  width: 55px;
}

.modal-wrapper--album .album_form_row--empty .portfolio_controls__drag_container {
  transition: height 0.3s ease-in-out;
  height: 225px;
}

@media (max-width: 900px) {
  .modal-wrapper--album .album_form_row--empty {
    max-width: 409px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
}

@media (max-width: 599px) {
  .modal-wrapper--album .album_form_row--empty {
    max-width: 409px;
    margin-left: auto;
    margin-right: auto;
  }
}

.modal-wrapper--album .album_form_row--none-empty {
  height: max-content;
  margin-top: 22px;
}

.modal-wrapper--album .album_form_row--none-empty .form_cell--label {
  height: 0 !important;
  padding-top: 0 !important;
}

.modal-wrapper--album .album_form_row--none-empty .portfolio_controls__drag_container {
  flex-direction: row;
  transition: height 0.3s ease-in-out;
  height: 94px;
}

.modal-wrapper--album .album_form_row--none-empty .portfolio_controls__drag_container .portfolio_controls__btn,
.modal-wrapper--album .album_form_row--none-empty .portfolio_controls__drag_container .dnd_file_input__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  margin-top: 0;
  margin-left: 9px;
}

.modal-wrapper--album .album_form_row--none-empty .dnd_file_input {
  margin-top: 0;
}

@media (max-width: 900px) {
  .modal-wrapper--album .album_form_row--none-empty {
    max-width: 409px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 599px) {
  .modal-wrapper--album .album_form_row--none-empty {
    margin-top: 60px;
  }
}

.modal-wrapper--album .form__btns {
  margin: 32px auto 0 auto;
}

@media (max-width: 899px) {
  .modal-wrapper--album {
    height: 100vh;
  }
  .modal-wrapper--album > .album_create_wrapper, .modal-wrapper--album > .album_edit_wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .modal-wrapper--album .modal-wrapper__close {
    top: 65px;
    right: 20px;
  }
}

.modal-wrapper--album .album-delete-action--wrapper {
  width: 100%;
  height: 240px;
  background-color: #EDEEF0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 48px;
  padding-bottom: 98px;
}

@media (max-width: 440px) {
  .modal-wrapper--album .album-delete-action--wrapper {
    padding: 35px 20px;
    padding-bottom: 85px;
  }
}

.modal-wrapper--album .album-delete-action .button__red {
  background-color: #FD051F;
  color: white;
}

.modal-wrapper--album .album-delete-action--text {
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}

.album_create_wrapper {
  /* Hack for IE */
}

.album_create_wrapper .form_row__description {
  display: flex;
  flex-direction: column;
}

.album_create_wrapper .form_row__description .form_cell--label {
  width: 100%;
}

.album_create_wrapper .form_row .form-step--1 .form_row--btns {
  display: none;
}

.album_create_wrapper .form_row--account {
  margin-top: -10px;
  margin-bottom: 36px;
}

.album_create_wrapper .form_row--account .form_cell--label {
  padding-top: 0;
}

.album_create_wrapper .file_input_link__container {
  display: none;
}

.album_create_wrapper .phone_input__wrap {
  width: 100%;
  margin-right: 0;
}

.album_create_wrapper .button__shadow {
  border: none;
}

.album_create_wrapper .button__shadow::before {
  top: 15px;
}

@media (max-width: 599px) {
  .album_create_wrapper .form_message_light {
    text-align: center;
    padding-top: 26px;
  }
}

@media (max-width: 599px) {
  .album_create_wrapper .form_message_light {
    text-align: center;
    padding-top: 6px;
    margin-bottom: 26px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .album_create_wrapper .phone_input__wrap + .button--confirm {
    margin-top: 0;
    max-width: 132px;
    min-width: calc(50% - 10px);
  }
}

@media (min-width: 600px) {
  .album_create_wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }
  .album_create_wrapper .form__label {
    margin-bottom: 7px;
  }
  .album_create_wrapper textarea.form__text {
    height: 100px;
  }
  .album_create_wrapper .form_cell--visibility {
    margin-bottom: 20px;
  }
  .album_create_wrapper .form_row--account {
    margin-top: -19px;
    margin-bottom: 25px;
  }
  .album_create_wrapper .form_message_light {
    padding-top: 5px;
    margin-bottom: 32px;
  }
}

@media (min-width: 900px) {
  .album_create_wrapper {
    padding: 20px 0 0 26px;
    margin-bottom: 80px;
  }
  .album_create_wrapper .form_cell--label {
    width: 160px;
    padding-top: 16px;
  }
  .album_create_wrapper .form_row--with_hint .form_cell--label {
    padding-left: 0;
  }
  .album_create_wrapper .form__label {
    margin-bottom: 7px;
  }
  .album_create_wrapper .form_row--account {
    margin-top: 0;
    margin-bottom: 15px;
    padding-top: 0;
  }
  .album_create_wrapper .form_row--account .form_cell--label {
    margin-right: 0;
  }
  .album_create_wrapper .form_message_light {
    padding-top: 32px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1280px) {
  .album_create_wrapper {
    padding-left: 80px;
    max-width: 720px;
  }
  .album_create_wrapper .form__text:focus ~ .hint_block .hint_block__content {
    opacity: 1;
    visibility: visible;
  }
  .album_create_wrapper .file_input_link__container {
    display: none;
  }
  .album_create_wrapper .form_cell--visibility {
    margin-bottom: 24px;
  }
}

.album_create_wrapper input[type='number']::-webkit-inner-spin-button,
.album_create_wrapper input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.album_create_wrapper input[type='number'] {
  -moz-appearance: textfield;
  text-align: left;
}

.album_create_wrapper .number-spin-btn-container {
  display: none !important;
}

.album_create_wrapper .form_cell--short {
  width: 66%;
}

@media (min-width: 900px) {
  .album_create_wrapper .form_cell--long {
    width: 100%;
  }
}

.success-create--album {
  width: 250px;
  height: 109px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 205;
}

.album_edit_wrapper {
  padding: 0 20px;
  max-width: 720px;
  margin: auto;
  /* Hack for IE */
}

.album_edit_wrapper .form_row--btns {
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}

.album_edit_wrapper .form_row--btns .button {
  width: 200px;
}

@media (max-width: 600px) {
  .album_edit_wrapper .form_row--btns {
    flex-direction: column-reverse;
  }
  .album_edit_wrapper .form_row--btns .button {
    width: 100%;
  }
  .album_edit_wrapper .form_row--btns .button:last-child {
    margin-bottom: 20px;
  }
}

.album_edit_wrapper .select-type-wrapper {
  display: flex;
  flex-direction: row;
}

.album_edit_wrapper .form_row {
  margin-bottom: 16px;
}

.album_edit_wrapper .form_row__description {
  display: flex;
  flex-direction: column;
}

.album_edit_wrapper .form_row__description .form_cell--label {
  width: 100%;
}

.album_edit_wrapper .form_row_nowrap {
  max-width: none;
}

.album_edit_wrapper .file_input_link__container {
  display: none;
}

@media (min-width: 600px) {
  .album_edit_wrapper {
    padding-left: 42px;
    padding-right: 42px;
  }
  .album_edit_wrapper .form__label {
    margin-bottom: 7px;
  }
  .album_edit_wrapper textarea.form__text {
    height: 100px;
  }
}

@media (min-width: 900px) {
  .album_edit_wrapper .form_cell--label {
    width: 160px;
    padding-top: 16px;
  }
  .album_edit_wrapper .form_row--with_hint .form_cell--label {
    padding-left: 0;
  }
  .album_edit_wrapper .form__label {
    margin-bottom: 7px;
  }
}

@media (min-width: 1280px) {
  .album_edit_wrapper .form__text:focus ~ .hint_block .hint_block__content {
    opacity: 1;
    visibility: visible;
  }
  .album_edit_wrapper .file_input_link__container {
    display: none;
  }
  .album_edit_wrapper .form_cell--visibility {
    margin-bottom: 24px;
  }
}

.album_edit_wrapper input[type='number']::-webkit-inner-spin-button,
.album_edit_wrapper input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.album_edit_wrapper input[type='number'] {
  -moz-appearance: textfield;
  text-align: left;
}

.album_edit_wrapper .number-spin-btn-container {
  display: none !important;
}

.album_edit_wrapper .form_cell--short {
  width: 66%;
}

@media (min-width: 900px) {
  .album_edit_wrapper .form_cell--long {
    width: 100%;
  }
}

.album_edit_wrapper .section_title {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
}

.album_edit_wrapper .album_upper-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #262626;
  opacity: 0.5;
  margin-top: 13px;
  margin-bottom: 22px;
}

@media (max-width: 599px) {
  .album_edit_wrapper .album_upper-text {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 25px;
  }
}

.tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
  font-size: 11px;
  font-family: "OpenSans-Bold", "Arial", sans-serif;
  letter-spacing: 1.8px;
  color: #262626;
  text-transform: uppercase;
  position: relative;
}

@media (min-width: 640px) {
  .tabs_links__item, .master_info__grade, .section_title, .master_stats_section__title, .user_info__grade, .profile_efficiency__title {
    font-size: 12px;
    letter-spacing: 2px;
  }
}

.album-confirmation--wrapper,
.image-confirmation--wrapper {
  display: block;
  min-width: 290px;
  padding: 41px 80px;
  width: 550px;
  height: min-content;
}

.album-confirmation--wrapper .text,
.image-confirmation--wrapper .text {
  font-weight: bold;
  margin: 26px auto;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
}

.album-confirmation--wrapper .buttons,
.image-confirmation--wrapper .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.album-confirmation--wrapper .buttons > .button__primary,
.image-confirmation--wrapper .buttons > .button__primary {
  width: 256px;
}

.album-confirmation--wrapper .buttons > .button__disabled,
.image-confirmation--wrapper .buttons > .button__disabled {
  border: 2px solid #E3E5E8;
  color: #A5A8AD;
  line-height: 0;
  background-color: inherit;
}

.album-confirmation--wrapper .buttons > .button__disabled:hover,
.image-confirmation--wrapper .buttons > .button__disabled:hover {
  color: #75787D;
  border-color: #C3C5C8;
}

@media (max-width: 899px) and (min-width: 600px) {
  .album-confirmation--wrapper,
  .image-confirmation--wrapper {
    margin: auto;
    transform: none;
    overflow-y: auto;
  }
}

@media (max-width: 600px) {
  .album-confirmation--wrapper,
  .image-confirmation--wrapper {
    padding: 33px;
    width: 290px;
  }
  .album-confirmation--wrapper .text,
  .image-confirmation--wrapper .text {
    font-size: 16px;
    line-height: 22px;
  }
  .album-confirmation--wrapper .buttons,
  .image-confirmation--wrapper .buttons {
    flex-direction: column;
  }
  .album-confirmation--wrapper .buttons .button,
  .image-confirmation--wrapper .buttons .button {
    width: 100%;
    justify-content: stretch;
  }
  .album-confirmation--wrapper .buttons .button:first-child,
  .image-confirmation--wrapper .buttons .button:first-child {
    margin-bottom: 14px;
  }
}

.album-confirmation--wrapper .timer,
.image-confirmation--wrapper .timer {
  margin: auto;
  position: relative;
  height: 120px;
  width: 120px;
}

.album-confirmation--wrapper .timer span,
.image-confirmation--wrapper .timer span {
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 76px;
  position: absolute;
  top: 20%;
  left: 0;
  width: 120px;
  text-align: center;
}

.album-confirmation--wrapper .timer .base-timer__circle,
.image-confirmation--wrapper .timer .base-timer__circle {
  fill: none;
  stroke: none;
}

.album-confirmation--wrapper .timer .base-timer__path-elapsed,
.image-confirmation--wrapper .timer .base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: #ffd24d;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transition: 975ms linear all;
  transform-origin: center;
}

.image-confirmation--wrapper {
  z-index: 10001;
}

.image-confirmation--wrapper.fancybox-margin {
  margin-right: 0;
}

.image-confirmation--wrapper .text {
  flex-direction: column;
}

.file-input .file-preview .file-drop-zone {
  min-height: unset;
}

.file-input .file-preview .file-drop-zone:focus {
  border: 2px solid #FFD24D;
}

.file-input .file-preview .file-preview-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.file-input .file-preview .file-preview-frame {
  padding: unset;
}

.file-input .file-preview .file-preview-frame .kv-file-content {
  width: unset;
  height: unset;
}

.file-input .file-preview .file-preview-frame .kv-file-content .file-preview-image {
  max-width: 100%;
  max-height: 100%;
  image-orientation: from-image;
  height: 107px !important;
  width: 107px !important;
  object-fit: cover;
  border-radius: 3px;
}

.file-input .file-preview .file-preview-frame:hover .actions .file-delete-wrapper {
  visibility: visible;
  opacity: 1;
}

.file-input .file-preview .file-preview-frame .actions button {
  border: none;
  background-color: unset;
  padding: 0;
}

.file-input .file-preview .file-preview-frame .actions button:focus {
  outline: unset;
}

.file-input .file-preview .file-preview-frame .actions .file-drag-handle {
  margin: unset;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.file-input .file-preview .file-preview-frame .actions .file-drag-handle .glyphicon-move {
  position: absolute;
  top: 4px;
  left: 2px;
}

.file-input .file-preview .file-preview-frame .actions .file-delete-wrapper {
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
  padding: 0;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  font-size: unset !important;
  line-height: unset !important;
}

.file-input .file-preview .file-preview-frame .actions .file-delete-wrapper .file-delete-wrapper-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease-in-out;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  line-height: 0;
}

.file-input .file-preview .file-preview-frame .actions .file-delete-wrapper .file-delete-wrapper-icon svg {
  height: 7px;
  width: 7px;
}

@media (max-width: 800px) {
  .file-input .file-preview .file-preview-frame .actions .file-delete-wrapper {
    right: 0;
    top: 0;
    width: 34px;
    height: 34px;
    justify-content: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
  }
  .file-input .file-preview .file-preview-frame .actions .file-delete-wrapper .file-delete-wrapper-icon {
    position: absolute;
    top: 3px;
    left: 14px;
  }
}
