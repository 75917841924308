.propetrovich_theme {
    &.ui-datepicker {
        background: #fff;
        border: 1px solid #dbdbdb;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        box-sizing: content-box;
        font-family: $fontOpenSans;
        font-size: 14px;
        color: #4a4a4a;
        width: 250px;
        padding: 15px;

        th {
            font-family: inherit;
            font-size: 12px;
            color: #A5A8AD;
            letter-spacing: 0;
            line-height: 30px;
            text-transform: uppercase;
            font-weight: normal;
        }

        td {
            &:hover {
                color: transparent;
            }
            a {
                width: 33px;
                height: 33px;
                padding-top: 8px;
                border-radius: 50%;
            }
            .ui-state-active {
                background: $btnPrimaryColor;
                color: $textPrimaryColor;
                &.ui-state-hover {
                    background: $btnPrimaryColor !important;
                    color: $textPrimaryColor;
                }
            }
            .ui-state-highlight {
                background: #E3E5E8;
                &.ui-state-hover {
                    background: #E3E5E8 !important;
                    color: $textPrimaryColor;
                }
            }
            .ui-state-hover {
                background:none;
                border:none;
                //color: transparent;
            }
            .ui-state-disabled {
                color: #A5A8AD;
                width: 35px !important;
                height: 35px !important;
                .ui-state-default {
                    display: flex;
                    width: 35px;
                    height: 35px;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .ui-widget-header {
        border: none;
        background: #fff;
    }
    .ui-datepicker-title {
        text-transform: uppercase;
        font-size: 12px;
        font-family: $fontOpenSansBold;
    }

    .ui-datepicker-other-month {
        color: #A5A8AD;
    }

    .ui-state-default {
        text-align: center;
        font-family: $fontOpenSans;
        font-size: 12px;
        letter-spacing: 0;
        border: 0;
        background: none;
        color: #262626;
    }

    .ui-datepicker td:hover .ui-state-default {
        color: inherit;
    }

    .ui-datepicker td .ui-state-active {
        color: inherit;
    }

    .ui-datepicker-unselectable {
        opacity: 1;
        color: inherit;
        height: 35px;
        .ui-state-default {
            color: #A5A8AD !important;
            height: 35px;
            padding-top: 8px;
        }
    }

    @include tablet-up {
        &.ui-datepicker {
            padding: 27px 43px;
        }
    }
}

.ui-datepicker {
    display: none;
}
