.chats-list {
  padding: 0 20px;

  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    top: -9px;
    right: -9px;

    span {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #181818;
    }

    svg {
      height: 11px;
      width: 11px;
      margin-top: -1px;
      //margin-left: -1px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__element {
    border: 1px solid #F1F3F5;
    border-radius: 4px;
    background-color: transparent;
    padding-bottom: 14px;
    margin-bottom: 14px;
    position: relative;

    &:hover {
      background-color: $bgColor;

      .stars__mask {
        background-image: url("/media/images/stars-grey.svg")
      }
    }

    &--active {
      border: 1px solid $btnPrimaryColor;

      .chats-list__status {
        background-color: $btnPrimaryColor;
      }
    }

    &--closed {
      background-color: $bgColor;

      .chats-list__link .stars__mask {
        background-image: url(/media/images/dark-stars.svg);
        background-size: 85px;
        background-position: left center;
      }

      .stars__list, .stars__fill {
        background-color: transparent;
      }

      .chats-list__status {
        background-color: #C6C9CC;
      }
    }
  }

  &__head {
    border-bottom: 1px solid #F1F3F5;
    padding: 9px 11px 7px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__master-data {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
  }

  &__photo {
    margin-right: 12px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__name {
    //font-weight: 600;
    font-family: $fontOpenSansBold;
    font-size: 14px;
    line-height: 19px;
    color: $textPrimaryColor;
  }

  &__rating {
    .stars {
      align-items: flex-end;
    }

    .stars__quantity {
      //font-weight: 600;
      font-family: $fontOpenSansBold;
      font-size: 14px;
      line-height: 19px;
      color: $textSecondaryColor;
    }
  }

  &__message {
    padding: 0 12px;
  }

  &__text {
    font-size: 14px;
    line-height: 19px;
    color: $textPrimaryColor;
    margin: 8px 0 4px;
  }

  &__time {
    font-size: 10px;
    line-height: 14px;
    color: $textSecondaryColor;
    padding: 0;
  }

  .stars__mask {
    height: 17px;
    transition: none;
  }

  .stars__list {
    display: none;
    height: 17px;
    width: 85px;
  }

  @include for-tablet-portrait-up {
    padding: 0 6px 0 13px;

    &__head {
      padding: 12px 15px 9px;
    }

    .stars__list {
      display: block;
      margin-right: 8px;
      margin-top: -1px;
    }

    &__message {
      padding: 0  15px;
    }

    &__text {
      margin: 14px 0 7px;
    }

    &__time {
      font-size: 12px;
      line-height: 16px;
    }

    &__element {
      padding-bottom: 12px;
    }

    &__rating {
      .stars__quantity {
        //font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  @include for-tablet-landscape-up {
    padding: 0 28px;

    &__element {
      padding-bottom: 1px;
      margin-bottom: 16px;
    }

    &__head {
      border: none;
      padding-top: 14px;
      align-items: flex-start;
      width: 236px;
    }

    &__master-data {
      flex-direction: column;
      margin-top: 3px;
    }

    &__message {
      display: flex;
      flex-direction: column;
      padding-right: 45px;
    }

    &__text {
      margin: 17px 0 11px;
    }

    &__status {
      top: 15px;
      right: 15px;
    }

    &__link {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &__name {
      margin-bottom: 8px;
    }

    &__photo  {
      margin-right: 14px;

      img {
        height: 54px;
        width: 54px;
      }
    }

    .stars {
      margin-left: -2px;
    }
  }

  @include for-desktop-up {
    padding-left: 80px;
    padding-right: 8px;

    &__element {
      margin-bottom: 12px;
    }
  }
}

.chats-list__message--user {
  .chats-list__mini-photo  {
    display: none;
    margin-right: 8px;
    margin-top: 12px;

    img {
      height: 16px;
      width: 16px;
    }
  }

  @include for-desktop-up {
    .chats-list__mini-photo {
      display: block;
    }
  }
}

.chats-list__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}