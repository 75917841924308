.master_reply {
  h4 {
    margin-top: 15px;
    font-family: $fontOpenSans;
    font-size: 10px;
    line-height: 14px;
    color: $textPrimaryColor;
    opacity: 0.5;
    margin-bottom: 8px;

    @media (min-width: 600px) {
      margin-top: 30px;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 5px;
    }

    @media (min-width: 900px) {
      margin-top: 24px;
      font-size: 12px;
      line-height: 16px;
    }

    @media (min-width: 900px) {
      margin-top: 30px;
    }
  }


  textarea {
    width: 100%;
    height: 90px;
    border: 1px solid #C6C7CE;
    border-radius: 4px;
    padding: 10px 16px;
    margin-bottom: 11px;
    font-family: $fontOpenSans;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #515151;
    resize: none;
    appearance: none;

    @media (min-width: 600px) {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 19px;
      padding: 12px 14px;
    }

    @media (min-width: 900px) {
      padding: 12px 14px;
      line-height: 19px;
    }
  }

  &__error {
    margin-bottom: 15px;
    color: $colorRed;
  }
}

.master_reply .button {
  margin-right: 15px;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  padding: 0 31px;
  cursor: pointer;

  @media (min-width: 600px) {
    font-size: 14px;
    height: 44px;
    line-height: 44px;
    padding: 0 20px;
  }
}

.master_reply .button__secondary {
  padding: 0 24px;
  margin-right: 0;

  @media (min-width: 600px) {
    padding: 0 20px;
  }
}
