@font-face {
    font-family: 'OpenSans-ExtraboldItalic';
    src: url('/media/fonts/OpenSans-ExtraboldItalic/OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),  url('/media/fonts/OpenSans-ExtraboldItalic/OpenSans-ExtraboldItalic.woff') format('woff'), url('/media/fonts/OpenSans-ExtraboldItalic/OpenSans-ExtraboldItalic.ttf')  format('truetype'), url('/media/fonts/OpenSans-ExtraboldItalic/OpenSans-ExtraboldItalic.svg#OpenSans-ExtraboldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open-Sans';
    src: url('/media/fonts/OpenSans-Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),  url('/media/fonts/OpenSans-Regular/OpenSans-Regular.woff') format('woff'), url('/media/fonts/OpenSans-Regular/OpenSans-Regular.ttf')  format('truetype'), url('/media/fonts/OpenSans-Regular/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('/media/fonts/OpenSans-Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),  url('/media/fonts/OpenSans-Bold/OpenSans-Bold.woff') format('woff'), url('/media/fonts/OpenSans-Bold/OpenSans-Bold.ttf')  format('truetype'), url('/media/fonts/OpenSans-Bold/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'OpenSans-Semibold';
    src: url('/media/fonts/OpenSans-Semibold/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),  url('/media/fonts/OpenSans-Semibold/OpenSans-Semibold.woff') format('woff'), url('/media/fonts/OpenSans-Semibold/OpenSans-Semibold.ttf')  format('truetype'), url('/media/fonts/OpenSans-Semibold/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'OpenSans-Extrabold';
    src: url('/media/fonts/OpenSans-Extrabold/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),  url('/media/fonts/OpenSans-Extrabold/OpenSans-Extrabold.woff') format('woff'), url('/media/fonts/OpenSans-Extrabold/OpenSans-Extrabold.ttf')  format('truetype'), url('/media/fonts/OpenSans-Extrabold/OpenSans-Extrabold.svg#OpenSans-Extrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
