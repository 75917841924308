@import '../../common/animations';

.modal-wrapper {
  &--album {
    max-width: 800px;
    max-height: 100vh;
    overflow-y: auto;
    padding: 0;

    .button__shadow {
      color: #A5A8AD;
      border: 2px solid #E3E5E8 !important;
    }

    .form_button_next {
      width: 121px;
    }

    .form_row {
      margin-bottom: 10px;
      flex-flow: row nowrap;
      align-items: flex-start;


      @media (min-width: 900px) {
        width: 100%;
        margin-bottom: 10px;
      }

      @media (min-width: 600px) and (max-width: 899px) {
        width: 100%;
        margin-bottom: 25px !important;
      }

      @media (max-width: 599px) {
        width: 100%;
        margin-bottom: 24px;
      }

      &__description {
        display: flex;
        flex-direction: row;

        .form_cell--label {
          width: 100%;
        }
      }

      &--half-width {
        margin-bottom: 16px;
        width: calc(50% - 10px);

        &:first-child {
          margin-right: 10px;
        }

        &:nth-child(2) {
          margin-left: 10px;
        }

        @media (max-width: 599px) {
          width: 100%;

          &:first-child {
            margin-right: 0;
          }

          &:nth-child(2) {
            margin-left: 0;
          }
        }
      }
    }

    .form_button_prev {
      background-color: rgba(#787984, 0.3);
      width: 120px;
      padding: 0 50px;
    }

    .is_hidden {
      display: none
    }

    .select-type-block {
      box-shadow: 0 14px 64px rgba(38, 38, 38, 0.09);
    }

    .form-section {
      @media (min-width: 900px) {
        width: 590px;
      }
    }

    .album_create_wrapper {
      padding: 0 20px;
      margin-bottom: 60px;
      max-width: 670px;

      .select-type-wrapper {
        display: flex;
        flex-direction: row;
      }

      .form_row_nowrap {
        justify-content: space-between;
      }

      .select-type-block {
        small {
          font-size: 14px;
          opacity: .5;
          text-align: center;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #FFFFFF;
        border-radius: 6px;
        height: 365px;
        width: 285px;
        margin: 10px;
        padding: 0 20px;
      }
    }

    .form_cell--field {
      width: 100%;
      flex-flow: row wrap;
    }

    .form_cell--centered {
      align-items: center;
    }

    .form_row--with_hint {
      .form_cell--label {
        padding-left: 24px;
        width: 100%;

        &--budget {
          margin-left: 25px;
        }
      }
    }

    .form__label {
      margin-bottom: 16px;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #262626;
      opacity: 0.5;

      span {
        display: block;
        font-size: 12px;
        font-family: $fontOpenSans;
        color: $textSecondaryColor;
        padding-top: 10px;
      }

      .ic {
        fill: $colorYellow;
      }

      @media (max-width: 599px) {
        margin-bottom: 8px;
      }
    }

    .form__text {
      border-color: $inputBorder;
      width: 100%;

      &:focus {
        border-color: $colorYellow;
      }
    }

    .form_cell__inner {
      width: 100%;
    }

    .form_cell {
      &.is_spec {
        @include mobile {
          flex-direction: column;
          .form_cell__inner {
            flex-basis: unset;
          }
        }
      }
    }

    .form-section {
      margin-top: 0;
      margin-bottom: 30px;

      @include for-tablet-portrait-up {
        margin-bottom: 0;
      }

      .file__descr_text {
        margin-bottom: 30px;
      }
    }

    textarea.form__text {
      resize: none;
      height: 164px;
      line-height: 20px;
      padding: 12px 17px 15px;

      @media (max-width: 599px) {
        height: 158px;
      }
    }

    .ic--upload-orange {
      height: 46px;
      width: 71px;
    }

    .ic--upload {
      height: 34px;
      width: 55px;
    }

    .album_form_row--empty {

      .portfolio_controls__drag_container {
        transition: height 0.3s ease-in-out;
        height: 225px;
      }

      @media (max-width: 900px) {
        max-width: 409px;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
      }

      @media (max-width: 599px) {
          max-width: 409px;
          margin-left: auto;
          margin-right: auto;
      }
    }

    .album_form_row--none-empty {
      height: max-content;
      margin-top: 22px;

      .form_cell--label {
        height: 0 !important;
        padding-top: 0 !important;
      }

      .portfolio_controls__drag_container {
        flex-direction: row;
        transition: height 0.3s ease-in-out;
        height: 94px;

        .portfolio_controls__btn,
        .dnd_file_input__text {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #262626;
          margin-top: 0;
          margin-left: 9px;
        }
      }

      .dnd_file_input {
        margin-top: 0;
      }

      @media (max-width: 900px) {
        max-width: 409px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
      }

      @media (max-width: 599px) {
        margin-top: 60px;
      }
    }

    .form__btns {
      margin: 32px auto 0 auto;
    }

    @media (max-width: 899px) {
      height: 100vh;

      &>.album_create_wrapper, &>.album_edit_wrapper {
        margin-top: 50px;
        margin-bottom: 50px;
      }

      .modal-wrapper__close {
        top: 65px;
        right: 20px;
      }
    }

    @media (max-width: 599px) {

    }

    .album-delete-action {
      &--wrapper {
        width: 100%;
        height: 240px;
        background-color: #EDEEF0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 48px;
        padding-bottom: 98px;

        @media (max-width: 440px) {
          padding: 35px 20px;
          padding-bottom: 85px;
        }
      }

      .button__red {
        background-color: #FD051F;
        color: white;
      }

      &--text {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}

.progress-bar--album {
  @extend .script-steps;
}
