//.telegram-bot {
//  max-width: 400px;
//}
//
.telegram-bot__header {
  font-weight: bold;
  font-size: 32px;
  line-height: normal;
  text-align: center;
  margin-bottom: 40px;
  color: #262626;
  letter-spacing: 1px;
}

.telegram-bot__element {
  background: #F7F7F7;
  border: 1px solid #E9E9E9;
  border-radius: 4px;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 25px;

  .ic--telegram {
    height: 24px;
    width: 24px;
    margin-right: 15px;
  }

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #262626;
  }
}

.ic--telegram {
  color: transparent;
  background-image: linear-gradient(203.2deg, #37AEE2 21.67%, #1E96C8 70%);
  border-radius: 50%;
}

.telegram-bot__text {
  font-size: 14px;
  line-height: 24px;
  color: #929292;
  padding-left: 24px;
  position: relative;
  margin-bottom: 46px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 4px;
    height: 100%;
    background-color: #C6C7CE;
  }
}

.telegram-bot__row {
  display: flex;
  justify-content: center;
}

.telegram-bot .button {
  padding-top: 15px;
  padding-bottom: 15px;
  //padding: 15px 52px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  height: auto;
  max-width: 306px;
  width: 100%;
}

@media (max-width: 599px) {
  .telegram-bot__header {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  .telegram-bot__element {
    padding: 0 14px;
    margin-bottom: 21px;

    span {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .telegram-bot__text {
    font-size: 12px;
    line-height: 20px;
    padding-left: 20px;
    margin-bottom: 24px;
  }

  .telegram-bot .button {
    font-size: 14px;
    line-height: 19px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}