.city-block {
  position: relative;
  order: 1;
  display: flex;
  align-items: center;
  justify-items: flex-start;

  @media (max-width: 1600px) {
    order: 2;
    position: absolute;
    top: 10px;
    left: 55px;
  }

  @include desktop-up-header-max {
    position: static;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  @media (max-width: 639px) {
    margin-top: 0;
  }
}

.city-block__pointer {
  width: 140px;
  min-width: 140px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px 0 24px;
  //border: 1px solid $colorYellow;
  //border-right: none;
  position: relative;
  background-image: url(../images/geoip-arrow.svg);
  background-repeat: no-repeat;
  background-position: center left;
  margin-right: 20px;
  //animation: moveit 5s ease-in-out infinite;
  //animation-delay: 5s;

  span {
    color: #000;
    font-size: 14px;
  }
}

@keyframes moveit {
  from {
    left: 0;
  }
  10% {
    left: 20px;
  }
  20% {
    left: 0;
  }
  to {
    left: 0;
  }
}


.page-header__top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 56px 0 40px;
}

.header_logo{
  display: flex;
  align-items: center;
}

.logo-pic{
  width: 48px;
  margin-right: 16px;
}

.logo-text{
width: 141px;
}

.city-block__name {
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  transition-property: color, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  font-family: $fontOpenSansSemibold;
  color: #262626;
  display: flex;
  align-items: center;
  img{
    margin-right: 16px;
  }
  &:hover {
    color: #555659;
  }
  span{
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      transform: translateY(-3px);
      content: url(../images/arrow-bottom.svg);
    }
  }
}

.page_header, .page_header__inner{
  transition: .25s;
}
@media (max-width: 1279px){
  .page_header__inner {
    height: auto;
  }
}


.menu-fixed{
  padding-top: 147px;
  .page_header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 101;
    padding-top: 5px;
  }
  .page_header__inner{
    height: 78px;
  }
  .nav_main_list__item:after{
    margin-top: 26px;
  }
}

.modal-city__mobile{
  display: none;
}
.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
  border-bottom: 1px solid #edeef0;

}
@media(max-width: 1300px)  {
  body {
    padding-top: 83px;
  }
  .page-header__hamburger{
    display: none;
  }
  .city-block__name{
    display: none;
  }
  .modal-city__mobile{
    display: flex;
    padding-left: 0;
  }
  .page_header__inner{
    padding: 17px 26px;
  }
  .menu-fixed{
    padding-top: 83px;
    .page_header{
      padding-top: 0;
    }
  }
  .logo-text{
    display: none;
  }
  .menu_open{
    .logo-text{
      display: block;
    }
    .header_logo{
      z-index: 202;
    }
  }
  .header_logo{
    width: auto;
    height: 48px;
  }
}

@media(max-width: 767px)  {
  .page_header__inner{
    padding-left: 21px;
    padding-right: 21px;
    width: 100%;
  }
  .city-block__name img{
    display: none;
  }
  .header-flex {
    flex-direction: column;
    padding-right: 0;
  }
}

.page_header__left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}