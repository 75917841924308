@import "progressbar.scss";

.modal-wrapper {
  background: $colorWhite;
  box-shadow: 0 4px 16px 0 rgba(38, 38, 38, .1);
  border-radius: 10px;
  //max-width: 880px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 64px 110px 64px 60px;
  display: none;
  z-index: 299;

  &--order {
    max-width: 1280px;
    max-height: 100vh;
    overflow-y: auto;
    padding: 33px 40px 40px;

    .form-section {
      //overflow-y: scroll;
    }

    .order_create_wrapper {
      padding: 0;
      margin: 0 auto;
      max-width: none;
      display: flex;
      justify-content: center;

      @media (max-width: 600px) {
        //margin-bottom: 20px;
      }
    }

    @media (max-width: 600px) {
      min-height: unset;
    }
  }

  &--album {
    .album_create_wrapper {
        padding: 0;
        margin: 50px auto;
        max-width: none;
        display: flex;
        justify-content: center;
    }

    @media (max-width: 600px) {
      min-height: unset;
    }
  }

  &--auth {
    max-width: 500px;
    padding: 0 50px 54px;
    border-radius: 10px;

    .modal-wrapper__close {
      right: 31px;
      top: 22px;

      .ic--cross-big {
        height: 16px;
        width: 16px;
      }
    }
  }

  &--telegram {
    max-width: 550px;
    padding: 54px 75px 65px;
    //margin: 0 13px;
    //left: auto;

    .modal-wrapper__close {
      top: 26px;
      right: 31px;

      .ic--close {
        height: 17px;
        width: 17px;
        fill: #979797;
      }
    }
  }

  @media (max-width: 1280px) {
    &--order {
      overflow-x: scroll;
      height: 100%;
      max-height: 100vh;
    }
  }

  @media (min-width: 900px) and (max-width: 1279px) {
    left: 0;
    right: 0;
  }

  //@media (min-width: 601px ) and (max-width: 899px) {
  //    padding: 25px 20px;
  //    bottom: 10px;
  //    top: 10px;
  //    //left: 10px;
  //    //right: 10px;
  //    transform: none;
  //    overflow-y: auto;
  //
  //    &--order {
  //        overflow-y: unset;
  //    }
  //}

  @media (min-width: 600px) and (max-width: 899px) {
    padding: 30px 30px 20px;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transform: none;
    overflow-y: auto;

    &--order {
      overflow-y: unset;
    }

    &--telegram {
      top: 50%;
      transform: translateY(-50%);
      padding: 54px 75px 65px;
      bottom: auto;
    }

  }

  @media (max-width: 599px) {
    padding: 25px 20px;

    &--order {
      padding: 22px 14px 25px;
    }

    &--telegram {
      max-width: calc(100% - 26px);
      padding: 28px 17px 50px;

      .modal-wrapper__close {
        top: 11px;
        right: 14px;
      }
    }
  }
}

.modal-wrapper--check_passport_data {
  height: 90%;

  .modal-wrapper__content {
    position: relative;
    overflow-y: auto;
    height: 95%;

    @include mobile {
      height: auto;
    }
  }
}

.modal-wrapper--national_expert {
  max-width: 740px;

  @include mobile {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

.modal-wrapper--add-spec {
  height: 100%;
  max-height: 100vh;
  //max-height: 720px;
  //max-width: 1280px;
  width: 100%;
  background-color: rgba(#000000, 0.6);
  padding: 64px 60px;
  border-radius: 0;

  @media (max-width: 1279px) {
    max-height: 100%;
  }

  .modal-wrapper__container {
    padding: 23px 21px 21px 24px;
    //max-height: 490px;
    max-width: 590px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 3px;

    @media (min-width: 600px) and (max-width: 899px) {
      max-width: 600px;
      padding: 23px 25px 21px 29px;
      max-height: 462px;
    }
    @media (max-width: 599px) {
      padding: 17px 8px 15px 0;
    }
  }

  .modal-wrapper__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 22px;
  }

  .modal-wrapper__close {
    top: 16px;
    right: 16px;

    .ic--cross-big {
      width: 18px;
      height: 18px;
    }
  }

  .button + .button {
    margin-left: 16px;
  }

  @include for-phone-only {
    padding: 18px 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    transform: none;

    .modal-wrapper__close {
      position: absolute;
      right: 5px;

      .ic--cross-big {
        height: 20px;
        width: 20px;
      }
    }

    .modal-wrapper__title {
      padding-left: 14px;
      margin-bottom: 10px;
    }

    .add_spec_title_desktop {
      display: none;
    }
  }

  @include for-tablet-portrait-only {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    transform: none;

    .modal-wrapper__close {
      position: absolute;
      right: 20px;
    }
  }

  @media (max-width: 600px) {
    max-height: none;
    padding: 0;
  }
}

.modal-wrapper__title {
  font-family: $fontOpenSansBold;
  font-size: 20px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  margin-bottom: 32px;
  line-height: 28px;

  @include mobile {
    padding-right: 25px;
  }
}

.modal-wrapper__close {
  position: absolute;
  top: 34px;
  right: 38px;
  cursor: pointer;
  z-index: 205;

  .ic--close {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .ic--cross-big {
    width: 28px;
    height: 28px;
  }

  @media (min-width: 900px) and (max-width: 1279px) {
    top: 30px;
    right: 32px;

    .ic--cross-big {
      width: 28px;
      height: 28px;
    }
  }

  @media (min-width: 600px) and (max-width: 899px) {
    top: 33px;
    right: 35px;

    .ic--cross-big {
      width: 20px;
      height: 20px;
    }
  }

  //@include mobile {
  //    position: absolute;
  //    top: 25px;
  //    right: 33px;
  //}

  @include for-phone-only {
    top: 21px;
    right: 11px;
  }

  //.ic--cross-big {
  //    height: 20px;
  //    width: 20px;
  //}

  @media (max-width: 600px) {
    .ic--cross-big {
      height: 20px;
      width: 20px;
    }
  }
}

.modal-wrapper__content {
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 15px;
  .ic--liga-full {
    flex-shrink: 0;
    margin-right: 34px;
    top: 10px;
  }

  @include mobile {
    flex-direction: column;
    align-items: center;

    .ic--liga-full {
      margin-bottom: 30px;
      margin-right: 0;
      top: 0;
    }
  }
}

.modal-wrapper__text {
  p {
    line-height: 24px;
  }

  p:not(:last-child) {
    margin-bottom: 20px;
  }
}

.add_spec_list {
  width: 100%;
  position: relative;

  @include for-phone-only {
    .form_btns {
      justify-content: center;
    }
  }
}

.add_spec_list__wrapper {
  display: flex;
  margin-bottom: 20px;

  @media (min-width: 600px) and (max-width: 899px) {
    margin-bottom: 12px;
  }

  @media (max-width: 599px) {
    margin-bottom: 30px;
  }
}

.add_spec_list__col {
  width: 50%;
  max-height: 328px;
  overflow-y: auto;

  @include for-phone-only {
    width: 100%;
  }
}

.add_spec_list__col--fav {
  background-color: rgba($btnSecondaryHoverColor, 0.5);
  border-radius: 3px;
  width: 51.5%;

  @include for-phone-only {
    display: none;
  }
}

.add_spec_list__col--full {
  position: relative;
  width: 48.5%;

  &:after {
    content: '';
    width: 100%;
    height: 65px;
    background-image: linear-gradient(to top, $colorWhite, rgba(255, 255, 255, 0));
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .spec_list:last-child {
    padding-bottom: 40px;
  }

  .spec_list__name {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .add_spec_list__title {
    margin-bottom: 11px;
  }

  .spec_list + .add_spec_list__section_name {
    margin-top: 13px;
  }

  @media (max-width: 599px) {
    width: 100%;
  }
}

.add_spec_form {
  max-height: 328px;
  //overflow-y: auto;
}

.add_spec_list__title {
  font-size: 12px;
  font-weight: bold;
  color: rgba($colorBlack, 0.3);
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 9px;

  @media (max-width: 599px) {
    margin-top: 13px;
    padding-left: 14px;
  }
}

.add_spec_list__section_name {
  font-size: 12px;
  font-weight: bold;
  padding-left: 36px;
  margin-bottom: 5px;

  @media (max-width: 599px) {
    padding-left: 26px;
  }
}

.spec_list {
  + .add_spec_list__section_name {
    margin-top: 22px;
  }
}

.spec_list__item {
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.spec_list__label {
  display: block;
  position: relative;
  transition: background-color $animationTime $animationFunc;
  font-size: 12px;
  cursor: pointer;

  .add_spec_list__col--full & {
    font-size: 10px;
  }

  .ic--check {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 7px;
    display: none;
  }

  @include for-tablet-portrait-up {
    &:hover {
      background-color: $btnSecondaryHoverColor;
    }
  }
}

.spec_list__input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;

  &:checked {
    + .ic--check {
      display: block;
      height: 6px;
      width: 6px;
    }

    ~ .spec_list__name {
      background-color: $colorYellow;
      font-weight: bold;
    }

  }
}

.spec_list__name {
  width: 100%;
  display: block;
  padding: 4px 0 4px 20px;

  .add_spec_list__col--full & {
    padding-left: 36px;

    @media (max-width: 599px) {
      padding-left: 26px;
    }
  }
}

.add_spec_list__btns {
  display: flex;
}

.add_spec_list__selected {
  position: absolute;
  right: 24px;
  bottom: 12px;
  font-size: 14px;
  font-weight: 600;

  @include for-phone-only {
    display: none;
  }
}

.form_step_wrapper {
  min-height: 466px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 900px) {
    min-height: 440px;
  }
  @media (max-width: 600px) {
    min-height: 385px;
  }
  @media (max-width: 599px) {
    min-height: 350px;
    margin-bottom: 10px;
    padding: 0 6px;
  }

  &--dnd {
    .form_row.form_row--none-empty {
      @media (max-width: 1280px) {
        height: auto;
      }
    }
    @media (max-width: 599px) {
      padding: 0;
    }
  }
}

.modal-wrapper--services {
  height: 100vh;
  left: auto;
  width: 370px;
  padding: 0;
  overflow: auto;
}

.modal-wrapper__round-close {
  height: 24px;
  width: 24px;
  border: none;
  border-radius: 50%;
  margin-left: 0;
  background-color: $btnPrimaryColor;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 40px;
  top: 13px;
  position: absolute;

  svg {
    height: 8px;
    width: 8px;
  }

}
