.main {
  &_notification {
    position: fixed;
    bottom: 10%;
    z-index: 100000;
    right: 20px;
    padding: 20px 25px 22px 25px;
    background: #ffffff;
    /*border: 1px solid #F2F2F2;*/
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 1);
    /*border-radius: 6px;*/
    min-width: 310px;

    border: 30px solid #ffd24d;
  }
  &_popup {
    &__ico {
      margin-right: 15px;
    }
    max-width: 255px;
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title {
      font-family: "OpenSans-Semibold", "Arial", sans-serif;
      font-size: 14px;
      line-height: 19px;
      color: #262626;
    }
    &__content {
      padding-top: 15px;
      display: flex;
      align-items: center;

    }
    &__text {
      font-size: 12px;
      line-height: 16px;
      color: #262626;
      font-weight: bold;
    }
    .ic--check-circle {
      color: #262626;
      width: 32px;
      height: 32px;
    }
    .ic--alert-circle {
      color: #FF0000;
      width: 32px;
      height: 32px;
    }
    .ic--close {
      fill: #C5C5C5;
      width: 10px;
      height: 10px;
      cursor: pointer;
      margin: 0 5px;
      position: absolute;
      top: 8px;
      right: 10px;
    }
  }
}
