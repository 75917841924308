.album_create_wrapper {
  .form_row {
    &__description {
      display: flex;
      flex-direction: column;

      .form_cell--label {
        width: 100%;
      }
    }

    .form-step--1 {
      .form_row--btns {
        display: none;
      }
    }
  }

  //.profile_create_spec__text {
  //    font-size: 14px;
  //}

  .form_row--account {
    margin-top: -10px;
    margin-bottom: 36px;

    .form_cell--label {
      padding-top: 0;
    }
  }

  .file_input_link__container {
    display: none;
  }

  .phone_input__wrap {
    width: 100%;
    margin-right: 0;
  }

  .button__shadow {
    border: none;
    &::before { top: 15px }
  }

  @include for-phone-only {

    .form_message_light {
      text-align: center;
      padding-top: 26px;
    }
  }
  @media (max-width: 599px) {

    .form_message_light {
      text-align: center;
      padding-top: 6px;
      margin-bottom: 26px;
    }
  }

  @include for-tablet-portrait-only {
    .phone_input__wrap {
      + .button--confirm {
        margin-top: 0;
        max-width: 132px;
        min-width: calc(50% - 10px);
      }
    }
  }

  @include for-tablet-portrait-up {
    padding-left: 42px;
    padding-right: 42px;

    .form__label {
      margin-bottom: 7px;
    }

    textarea.form__text {
      height: 100px;
    }

    .form_cell--visibility {
      margin-bottom: 20px;
    }

    .form_row--account {
      margin-top: -19px;
      margin-bottom: 25px;
    }

    .form_message_light {
      padding-top: 5px;
      margin-bottom: 32px;
    }
  }

  @include for-tablet-landscape-up {
    padding: 20px 0 0 26px;
    margin-bottom: 80px;

    .form_cell--label {
      width: 160px;
      padding-top: 16px;
    }

    .form_row--with_hint {
      .form_cell--label {
        padding-left: 0;
      }
    }

    //.form_cell--field {
    //  width: 480px;
    //}

    .form__label {
      margin-bottom: 7px;
    }

    .form_row--account {
      margin-top: 0;
      margin-bottom: 15px;
      padding-top: 0;

      .form_cell--label {
        margin-right: 0;
      }
    }

    .form_message_light {
      padding-top: 32px;
      margin-bottom: 50px;
      //max-width: 350px;
    }
  }

  @include for-desktop-up {
    padding-left: 80px;
    max-width: 720px;

    .form__text:focus ~ .hint_block {
      .hint_block__content {
        opacity: 1;
        visibility: visible;
      }
    }

    .file_input_link__container {
      display: none;
    }

    .form_cell--visibility {
      margin-bottom: 24px;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    text-align: left;
  }

  /* Hack for IE */
  .number-spin-btn-container {
    display: none !important;
  }

  .form_cell--short {
    width: 66%;
  }

  .form_cell--long {
    @media (min-width: 900px) {
      width: 100%;
    }
  }
}

.success-create--album {
  width: 250px;
  height: 109px;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 205;
}