.table_styled {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 38px;

    .is_link {
        cursor: pointer;
    }

    tbody {
        tr:hover {
            .table_styled__value {
                background-color: $bgColor;
            }

            .table_styled__value--icon {
                .button--icon {
                    display: block;
                    float: right;
                }
            }

            .stars__mask {
                background-image: url(/media/images/stars-h.svg);
            }
        }
    }

    [data-link] {
        cursor: pointer;
    }

    @include mobile {
        display: block;
        margin-bottom: 30px;

        thead {
            display: none;
        }

        tbody {
            display: block;

            tr:hover {
                .table_styled__value--icon {
                    .button--icon {
                        float: none;
                    }
                }
            }
        }

        tr:not(.is_hidden) {
            display: flex;
            flex-flow: row wrap;
            position: relative;
        }

        td {
            box-sizing: border-box;
        }
    }
}

.table_styled__title {
    font-size: 12px;
    text-align: left;
    color: $textSecondaryColor;
    font-weight: normal;
    border-bottom: solid 1px $lineColor;
    padding: 10px 0 16px;

    &:first-child {
        padding-left: 10px;
    }

    &:last-child {
        padding-right: 10px;
        text-align: right;
    }

    @include big-desktop {
        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }
}

.table_styled__section {
    .table_styled__value {
        text-align: left;
        font-weight: 600;
        padding-top: 18px;
    }

    @include mobile {
        .table_styled__value {
            border-bottom: none;
        }
    }
}

.table_styled__value {
    font-size: 14px;
    line-height: 1;
    text-align: left;
    color: $textPrimaryColor;
    border-bottom: solid 1px $lineColor;
    vertical-align: top;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: background-color $animationTime $animationFunc;

    &:first-child {
        padding-left: 10px;
    }

    &:last-child {
        padding-right: 10px;
    }

    .is_cancelled &,
    .is_closed & {
        color: $textSecondaryColor;
    }

    .hint {
        font-size: 12px;
        color: $textSecondaryColor;
    }

    .ic--key {
        margin-right: 8px;
        top: -1px;
    }

    @include tablet-up {
        line-height: 1.5;
    }

    @include big-desktop {

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            //padding-right: 20px;
        }
    }
}

.table_styled__value--unknow {
    color: $textSecondaryColor;
}

.table_styled__value--prices {
    @include mobile {
        width: 100%;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        justify-content: space-between;
    }
}

.table_styled__value--name {
    display: block;
    width: 100%;
    padding-right: 10px;
    line-height: 1.5;

    .is_done & {
        color: $textSecondaryHoverColor;
    }

    @include mobile {
        border-bottom: none;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include tablet-up {
        display: table-cell;
        width: auto;
    }
}

.table_styled__value--price {
    width: 100%;
    padding: 12px 10px 12px;
    line-height: 1.5;

    @include tablet-up {
        width: 125px;
        padding-left: 0;
        padding-bottom: 0;
    }

    @include desktop-up {
        width: 205px;
    }
}

.table_styled__value--stars {
    padding-left: 10px;
    width: 80%;
    padding-bottom: 12px;

    @include tablet-up {
        width: 142px;
        padding-left: 0;
        padding-bottom: 0;
    }

    @include desktop-up {
        width: 192px;
    }
}

.table_styled__value--rating {
    width: 25%;
    padding-bottom: 12px;
    text-align: right;
    font-family: $fontOpenSansBold;

    @include tablet-up {
        width: 40px;
        padding-bottom: 0;
    }

    @include desktop-up {
        width: 70px;
    }
}

.table_styled__value--empty {
    color: $textSecondaryColor;
}

.table_styled__value--time {
    width: 150px;
}

.table_styled__value--budget {
    width: 120px;
}

.table_styled__value--status {
    width: 190px;

    .table_styled--open_orders & {
        width: 110px;
    }
}

.table_styled__value--bids {
    width: 80px;
}

.table_styled__value--icon {
    width: 50px;

    .button--icon {
        display: none;
    }

    .ic {
        fill: $textPrimaryColor;
    }
}

.table_styled__value--close_spec {
    width: 20px;
    @include mobile {
        width: auto;
        position: absolute;
        border-bottom: none;
        right: 8px;
        top: 2px;
    }
}

.table_styled--bids {

    .table_styled__col_title {
        display: none;
    }

    .table_styled__link {
        position: relative;

        &.is_notvisited {
            font-weight: 600;

            &:before {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: $colorYellow;
                border-radius: 50%;
                position: absolute;
                top: 8px;
                left: -10px;
            }
        }
    }

    @include desktop-less {
        display: block;

        thead {
            display: none;
        }

        tbody {
            display: block;

            tr:not(.is_hidden) {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid $btnSecondaryHoverColor;
                padding-top: 10px;
                padding-bottom: 15px;
                position: relative;
            }
        }

        .is_cancelled,
        .is_closed {
            .button--icon {
                margin-left: -5px;
            }

            .button--icon:after {
                opacity: 1;
                visibility: visible;
                color: $textPrimaryColor;
                background-color: transparent;
                margin-left: 0;
                top: 2px;
            }
        }

        .table_styled__value {
            display: block;
            position: relative;
            padding-left: 90px;
            border-bottom: none;
            width: 100%;
            padding-bottom: 6px;
        }

        .table_styled__value--master {
            width: auto;
            padding-left: 20px;
        }

        .table_styled__value--name {
            padding-bottom: 0;
        }

        .table_styled__col_title {
            display: block;
            position: absolute;
            top: 12px;
            left: 8px;
            font-size: 12px;
            color: $textSecondaryColor;
        }
    }

    @include big-desktop {
        .table_styled__link {
            &:before {
                top: 7px;
                width: 8px;
                height: 8px;
                left: -20px;
            }
        }
    }
}

.table_styled__link {
    &:hover {
        color: $textPrimaryColor;
    }
}

.table_order {
    tbody {
        tr {
            &:hover {
                background-color: transparent;
            }
        }
    }

    .table_styled__title {
        border: none;
        padding: 0 3px;

        &:first-child {
            padding: 0;
        }

        &:last-child {
            padding-right: 0;
            text-align: left;
        }
    }

    .table_styled__value {
        border: none;
        padding: 0 3px;

        &:first-child {
            padding-left: 0;
        }
    }

    @include big-desktop {
        .table_styled__title,
        .table_styled__value {
            &:first-child {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.table_styled--masters {
    .table_styled__title {
        &.table_styled__value--rating_total {
            text-align: right;
            width: 100px;
        }

        &.table_styled__value--rating_spec {
            width: 150px;
            text-align: right;
        }
    }

    .table_styled__value {
        vertical-align: middle;

        &.table_styled__value--offer_btn {
            width: 270px;

            .ic--fav-tag {
                display: none;
                fill: $btnSecondaryColor;
                margin-top: 20px;
            }
        }

        &.table_styled__value--fav_tag_icon {
            width: 100px;
            text-align: center;

            .fav_link {
                .ic--fav-tag {
                    fill: $btnSecondaryColor;
                }

                &.is_active {
                    .ic--fav-tag {
                        fill: #ffd24d;
                    }
                }
            }

            .ic--fav-tag {
                fill: $btnSecondaryColor;
            }
        }

        &.table_styled__value--master {
            @include desktop-up {
                width: 400px;
            }
        }

        &.table_styled__value--rating_spec {
            text-align: right;
            width: 145px;
        }

        &.table_styled__value--rating_total {
            text-align: right;
            width: 100px;
        }
    }

    @include desktop-less {
        .table_styled__value {
            &.table_styled__value--offer_btn {
                width: 100%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding-top: 0;
                position: static;

                span {
                    margin-top: 20px;
                    margin-right: 20px;
                }

                .button {
                    margin-top: 20px;
                    margin-right: 20px;
                }

                .ic--fav-tag {
                    display: block;
                }
            }

            &.table_styled__value--fav_tag_icon {
                display: none;
            }

            &.table_styled__value--rating_spec,
            &.table_styled__value--rating_total {
                width: 100% !important;
                padding-left: 90px;
                text-align: left !important;
                display: flex;
                justify-content: space-between;
                padding-right: 20px;
            }

            .fav_link {
                position: absolute;
                left: 38px;
                top: 80px;
                z-index: 99999;

                &.is_active {
                    .ic--fav-tag {
                        fill: $colorYellow;
                    }
                }
            }
        }
    }
}
