.filters-open {
  overflow-y: hidden;

  @include for-tablet-portrait-only {
    .listing--v2 {
      z-index: 99;
    }
  }

  @include for-tablet-landscape-up {
    overflow-y: auto;
  }
}

.lt_2col {
  .page_content--listing {
    display: flex;
    flex-direction: column;
    min-height: 0;
    border-top: 1px solid $lineColor;
    margin-top: -1px;

    @include for-tablet-landscape-up {
      display: block;
      position: relative;
      min-height: 900px;
    }
  }

  .page_title--listing {
    padding: 28px 20px 0;
    order: 1;

    .page_title__text {
      text-align: left;
      margin-bottom: 10px;
      line-height: normal;
    }

    @include for-tablet-portrait-only {
      width: 100%;
      max-width: 570px;
      margin-left: auto;
      margin-right: auto;
      float: none;

      .page_title__text {
        margin-bottom: 16px;
      }
    }

    @include for-tablet-landscape-up {
      width: 64%;
      padding-left: 25px;
      padding-right: 25px;
    }

    @include for-desktop-up {
      padding-left: 80px;
      padding-right: 0;
    }
  }

  .page_content__left--listing {
    @include for-tablet-portrait-only {
      width: 100%;
      max-width: 570px;
      margin-left: auto;
      margin-right: auto;
      float: none;
      order: 3;
    }

    @include for-tablet-landscape-up {
      width: 64%;
      padding-right: 25px;
    }

    @include for-desktop-up {
      width: 75%;
    }
  }

  .page_content__right--listing {
    order: 2;

    @include for-tablet-portrait-only {
      background-color: $colorWhite;
      padding: 0;
      width: 100%;
      max-width: 570px;
      margin-left: auto;
      margin-right: auto;
      float: none;
      position: static;
      margin-bottom: 0;

      .listing_sidebar > .button__primary {
        width: 170px;
      }
    }

    @include for-tablet-landscape-up {
      width: 320px;
      min-width: inherit;
    }

    @include for-desktop-up {
      width: 320px;
    }
  }

  .page_content__right--chat {
    @include for-tablet-portrait-only {
      max-width: none;
    }

    //@include for-tablet-landscape-up {
    //    padding-top: 145px;
    //}
  }

  .page_content__left--chat {
    @include for-tablet-landscape-up {
      float: none;
    }
  }
}

.listing_filter__chosen {
  font-family: $fontOpenSansSemibold;
  line-height: 18px;
  font-size: 14px;
  margin-bottom: 28px;

  &.order_btob {
    margin-bottom: 0;
    line-height: 0;
    font-size: 13px;
  }

  span {
    display: inline-block;
    border-radius: 3px;
    background-color: $colorYellow;
    padding: 0 5px;
  }

  @include for-tablet-portrait-up {
    font-size: 18px;
    line-height: 27px;
  }
}

.listing_filter__chosen--b2b {
  margin-bottom: 0;
  margin-left: auto;
  font-size: 13px;
  display: none;

  @include for-tablet-portrait-up {
    display: block;
  }
}

.listing--v2 {
  padding-left: 16px;
  padding-right: 16px;

  .button--open_filters {
    width: 100%;
  }

  .user_not-found_text,
  .order_not-found_text {
    margin-bottom: 15px;
  }

  @include for-tablet-portrait-up {
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    z-index: 100;

    .button--open_filters {
      width: 200px;
      margin-right: 0;
      position: absolute;
      right: 20px;
      bottom: 100%;
      margin-bottom: 0;
    }
  }

  @include for-tablet-landscape-up {
    padding-left: 25px;
    padding-right: 0;
  }

  @include for-desktop-up {
    padding-left: 60px;
  }
}

.listing__menu {
  margin-bottom: 27px;

  .form__text {
    padding-right: 56px;
  }

  .button__search {
    position: absolute;
    right: 0;
    top: 0;
  }

  @include for-tablet-landscape-up {
    margin-bottom: 14px;
  }

  @include for-desktop-up {
    margin-bottom: 0;
  }
}

.table-listing--v2 {
  display: block;

  .ic--liga,
  .ic--liga-small,
  .ic--star {
    pointer-events: none;
  }

  .ic--star {
    fill: $colorYellow;
    width: 12px;
    height: 12px;
    top: -1px;
  }

  .table__row {
    display: block;
    width: 100%;
    padding-top: 16px;
    transition: background-color $animationTime $animationFunc;

    &:nth-child(2n) {
      background-color: $colorWhite;
    }
  }

  .table__row--thead {
    display: none;
  }

  .table__cell {
    vertical-align: top;
    font-size: 12px;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    &:before {
      content: attr(data-title);
      width: auto;
      font-weight: normal;
      margin-bottom: 0;
      padding-right: 0;
    }

    &:first-child {
      margin-bottom: 4px;
    }
  }

  .table__cell--town {
    font-size: 10px;
    padding-bottom: 6px;

    &:before {
      display: none;
    }
  }

  td.table__cell:not(:first-child) {
    justify-content: space-between;
  }

  td.table__cell--materials {
    border-bottom: 1px solid $lineColor;
  }

  td.table__cell--town,
  td.table__cell--rating,
  td.table__cell--total_rating,
  td.table__cell--feedback {
    max-width: 280px;
    font-weight: bold;
    padding-left: 72px;
    width: 100%;
  }

  td.table__cell--specialization {
    font-weight: normal;
    padding-top: 10px;
    padding-bottom: 16px;
    padding-left: 72px;
    display: block;
    word-break: break-word;

    &:before {
      width: 100%;
      font-size: 11px;
      color: $textSecondaryColor;
      display: block;
    }
  }

  td.table__cell--name {
    font-family: $fontOpenSansSemibold;
    font-size: 13px;
    display: block;

    .order-date {
      display: inline-block;
      font-size: 11px;
      font-weight: normal;
      margin-left: 10px;

      .ic--time {
        margin-right: 6px;
        top: -1px;
      }
    }

    p {
      font-size: 12px;
      font-family: Open-Sans, Arial, sans-serif;
    }

    .help-text {
      background-color: transparent;
      padding: 0;
      font-weight: normal;
      line-height: inherit;
      color: $textPrimaryColor;
    }

    a:hover {
      color: $textPrimaryColor;
    }

    &:before {
      display: none;
    }
  }

  td.table__cell--deadline,
  td.table__cell--budget {
    font-family: $fontOpenSansSemibold;
  }

  td.table__cell--deadline,
  td.table__cell--budget,
  td.table__cell--city,
  td.table__cell--materials {
    &:before {
      color: $textPrimaryColor;
    }
  }

  td.table__cell--materials {
    padding-bottom: 16px;
  }

  td.table__cell--date {
    display: none;
  }

  .table__cell--header {
    border-top: none;
    border-bottom: none;
    font-size: 11px;
    color: $textSecondaryColor;
    vertical-align: top;
    padding-top: 25px;
    padding-bottom: 0;
  }

  .table__user {
    padding-top: 0;
    position: relative;
    display: flex;
    align-items: center;

    .fav_link {
      position: absolute;
      top: 62px;
      left: 22px;
    }

    .ic--fav-tag {
      pointer-events: none;
      width: 12px;
      height: 14px;
    }
  }

  .table__user__ava {
    width: 54px;
    min-width: 54px;
    height: 54px;
    margin-right: 18px;
    border-radius: 50%;
    overflow: hidden;
  }

  .checked_master {
    border: 2px solid #FFD24D;
  }

  .user__ava__img {
    border-radius: 50%;
  }

  .table__user__text {
    position: relative;
    width: 100%;

    > a {
      font-size: 13px;
      font-family: $fontOpenSansSemibold;
      line-height: 25px;

      &:hover {
        color: $textSecondaryHoverColor;
      }
    }

    .high_liga_link {
      display: inline-flex;
    }

    .icon_hover {
      display: inline-block;
      position: relative;
      width: auto;

      svg {
        margin-left: 3px;
      }

      .ic.ic--checked_master_tooltip {
        display: none;
        position: absolute;
        top: 0;
        right: -145px;
        z-index: 100;
        margin: 0;
      }

      > svg.ic.ic--king {
        position: relative;

        &:hover + .ic.ic--checked_master_tooltip {
          display: block;
        }
      }
    }
  }

  .listing__menu__itm {
    color: $textSecondaryColor;
    padding: 0;
    margin: 0;

    &:before {
      display: none;
    }

    .ic--ic_sort {
      margin-left: 5px;
      fill: #daddde;
      transition: fill $animationTime $animationFunc;
    }

    &:hover {
      color: $textSecondaryColor;

      .ic--ic_sort {
        fill: $textSecondaryColor;
      }
    }
  }

  .listing__menu__itm--active {
    .ic--ic_sort {
      fill: $colorYellow
    }
  }

  .listing__menu__itm--active--minus {
    .ic--ic_sort {
      transform: scaleX(-1) rotate(180deg);
    }
  }

  .table__user__tags {
    margin-left: -7px;
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
  }

  .help-text {
    display: inline-block;
    height: 17px;
    line-height: 17px;
    white-space: nowrap;
    border-radius: 18px;
    background-color: #f4f4f4;
    font-size: 11px;
    padding: 0 8px;
    color: $textPrimaryColor;
    margin-right: 6px;
    vertical-align: middle;
    margin-bottom: 6px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .more {
    white-space: nowrap;
    color: $textSecondaryColor;
  }

  .table__cell--date {
    .ic--time {
      margin-left: -17px;
      margin-right: 6px;
    }
  }

  .table__row--adv {
    padding-top: 4px;
    padding-bottom: 4px;

    .table__cell {
      padding: 4px 0;

      &:first-child {
        margin-bottom: 0;
      }

      &:last-child {
        border-bottom-width: 0;
      }
    }

    &:hover {
      background-color: $colorWhite;
    }
  }

  @include for-phone-only {
    tbody {
      display: block;
    }

    .table__row--adv {
      .table__cell {
        border-bottom-width: 0;
      }

      border-bottom-width: 0;
      margin-top: -1px;
      padding-top: 5px;
    }

    .ic--liga-small {
      width: 13px;
      height: 7px;
      margin-left: 5px;
    }
  }

  @include for-tablet-portrait-only {
    tbody {
      display: block;
    }

    td.table__cell--materials,
    .table__cell:last-child {
      border-bottom-width: 0;
    }

  }

  @include for-tablet-portrait-up {
    padding-top: 30px;

    .table__row:not(.table__row--thead) {
      border-bottom: 1px solid $lineColor;
      display: flex;
      flex-flow: row wrap;
      position: relative;
    }

    td.table__cell--town,
    td.table__cell--rating,
    td.table__cell--total_rating,
    td.table__cell--feedback {
      margin-right: 50%;
    }

    td.table__cell--specialization {
      margin-left: 320px;
      padding-left: 0;
      position: absolute;
      top: 64px;

      &:before {
        margin-bottom: 5px;
      }
    }

    td.table__cell--materials,
    td.table__cell {
      border-bottom: none;
    }

    &:not(.table-listing--masters) {
      .table__cell {
        width: 100%;
      }
    }
  }

  @include for-tablet-landscape-up {
    padding-top: 0;
  }

  @include for-desktop-up {
    display: table;
    border-bottom: 1px solid $lineColor;

    &:not(.table-listing--masters) {
      .table__row {
        display: table-row;
        padding-top: 0;

        &.table_link:hover {
          background-color: $bgColor;
        }
      }

      .table__cell {
        display: table-cell;
        padding-top: 26px;
        padding-bottom: 21px;

        &:before {
          display: none !important;
        }

        &:first-child {
          padding-left: 20px;
        }
      }

      .table__cell--header {
        padding-top: 27px;
        padding-bottom: 0;
        padding-left: 0;
      }

      .table__cell--rating {
        width: 120px;
      }

      .table__cell--total_rating {
        width: 80px;
      }

      .table__cell--feedback {
        width: 80px;
      }

      .table__cell--specialization {
        width: 160px;
      }

      .table__cell--deadline {
        width: 128px;
      }

      .table__cell--budget {
        width: 102px;
      }

      .table__cell--city {
        width: 134px;
      }

      .table__cell--materials {
        width: 109px;
      }

      .table__cell--date {
        width: 82px;
        //padding-right: 20px;
      }

      .table__cell--town {
        width: 134px;
      }

      td.table__cell:not(:first-child) {
        padding-left: 0;
      }

      td.table__cell--town,
      td.table__cell--rating,
      td.table__cell--total_rating,
      td.table__cell--feedback {
        font-family: $fontOpenSansSemibold;
      }

      th.table__cell--name {
        padding-left: 20px;
      }

      td.table__cell--name {
        padding-left: 20px;
        padding-right: 10px;
        display: table-cell;

        .order-date {
          display: none;
        }
      }

      td.table__cell--specialization {
        padding-top: 26px;
        display: table-cell;
        position: static;

        &:before {
          display: none;
        }
      }

      td.table__cell--materials {
        padding-bottom: 21px;
      }

      td.table__cell--date {
        display: table-cell;
      }

      .table__user {
        .fav_link {
          top: 4px;
          left: 60px;
        }
      }

      .table__row--adv {
        .table__cell {
          padding: 4px 0;
          border-bottom-width: 1px;
        }
      }
    }
  }
}

.table-listing--v2.table-listing--masters {
  border-bottom-width: 0;

  .table__row--thead {
    display: none;
  }

  .table__row:not(.table__row--thead) {
    border-bottom: 1px solid $lineColor;
    padding-bottom: 30px;

    .table__cell {
      border-bottom-width: 0;
    }
  }

  @include for-desktop-up {
    margin-top: 0;

    .table__row {
      display: table-row;
      padding-top: 0;

      &:not(.table__row--thead):hover {
        background-color: $bgColor;
        cursor: pointer;

        .help-text {
          background-color: $colorWhite;
        }
      }
    }

    .table__cell {
      display: table-cell;
      padding-top: 26px;
      padding-bottom: 21px;
      padding-left: 0;

      &:before {
        display: none;
      }

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }

    td.table__cell--town,
    td.table__cell--rating,
    td.table__cell--total_rating,
    td.table__cell--feedback {
      font-family: $fontOpenSansSemibold;
      padding-left: 0;
      width: auto;
    }

    .table__cell--header {
      padding-top: 27px;
      padding-bottom: 0;
      padding-left: 0;
    }

    .table__cell--rating {
      width: 120px;
    }

    .table__cell--total_rating {
      width: 80px;
    }

    .table__cell--specialization {
      width: 160px;
    }

    .table__cell--feedback {
      width: 80px;
    }

    .table__cell--town {
      width: 134px;
      font-size: 12px;
    }

    th.table__cell--name {
      padding-left: 20px;
    }

    td.table__cell--name {
      padding-left: 20px;
      padding-right: 10px;
      display: table-cell;

      .order-date {
        display: none;
      }
    }

    td.table__cell--specialization {
      padding-top: 26px;
      position: static;

      &:before {
        display: none;
      }
    }

    .table__user {
      align-items: flex-start;

      .fav_link {
        top: 0;
        left: 60px;
      }
    }

    .table__user__ava {
      margin-right: 30px;
    }

    .table__user__text {
      > a {
        line-height: normal;
      }
    }

    .table__row--adv {
      .table__cell {
        padding: 4px 0;
        border-bottom-width: 1px;
      }
    }
  }
}

.listing__paginator--v2 {
  padding-top: 44px;
  padding-bottom: 62px;

  .listing__paginator__list {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .listing__paginator__itm {
    margin: 0 5px;
    display: block;
    border-bottom: none;
    padding: 0;
  }

  .listing__paginator__itm--active {
    .listing__paginator__btn {
      cursor: default;
    }
  }

  .listing__paginator__btn {
    position: relative;
    display: block;
    min-width: 26px;
    height: 26px;
    line-height: 26px;
    background-color: $btnSecondaryHoverColor;
    text-align: center;
    padding: 0 7px;
    color: $textPrimaryColor;
    font-size: 11px;
    font-family: $fontOpenSansSemibold;
    border-radius: 1px;
    transition: background-color $animationTime $animationFunc;

    &:hover {
      background-color: #e0e0e0;
      color: $textPrimaryColor;
    }
  }

  .listing__paginator__itm--active {
    .listing__paginator__btn {
      background-color: $btnPrimaryColor;
    }
  }

  .listing__paginator__btn--prev {
    background-color: transparent;

    &:before {
      display: none;
    }

    .ic {
      transform: rotate(90deg);
      width: 6px;
      height: 4px;
    }
  }

  .listing__paginator__btn--next {
    background-color: transparent;

    &:before {
      display: none;
    }

    .ic {
      transform: rotate(-90deg);
      width: 6px;
      height: 4px;
    }
  }

  .listing__paginator__btn--to-top {
    .ic {
      transform: rotate(180deg);
      fill: $textPrimaryColor;
      width: 10px;
      height: 6px;
    }
  }

}

.listing_sidebar {
  padding: 22px 20px 0;

  .dropdown {
    margin-bottom: 30px;
    border-radius: 4px;
    font-size: 14px;
  }

  .dropdown--autocomplete {
    .ic--dropdown-arrow {
      position: absolute;
      top: 19px;
      right: 15px;
      z-index: 20;
      fill: #c4c4c4;
    }

    &:before {
      display: none;
    }
  }

  .dropdown__itm {
    padding: 0;

    > a {
      display: inline-block;
      padding: 12px 10px 11px 10px;
      width: 100%;

      &:hover {
        color: currentColor;
      }
    }
  }

  .skill-chouser {
    padding-bottom: 7px;
  }

  .skill-chouser--additional {
    .section_title {
      margin-bottom: 18px;
    }
  }

  .check_item {
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;

    &:first-child {
      margin-top: 20px;
    }
  }

  .check_item__checked {
    position: relative;
    margin-right: 15px;

    &:before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .radio_item {
    margin-bottom: 20px;
    cursor: pointer;

    &:first-child {
      margin-top: 20px;
    }
  }

  @include for-tablet-landscape-up {
    padding: 22px 0 40px;
  }

  @include for-desktop-up {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.listing_sidebar__masters {
  .dropdown__itm {
    padding: 0;

    > a {
      display: inline-block;
      padding: 12px 10px 11px 10px;
      width: 100%;
    }
  }

  @include for-tablet-portrait-up {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    .advertising_wrap {
      order: 3;

      .hero-adv--vertical {
        margin-top: 0;
      }
    }

    .listing__filter {
      order: 2;
    }
  }
}

.listing_sidebar__hint {
  color: $textSecondaryColor;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 22px;
}

.listing__filter {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 0 -4px 15px 0 rgba(0, 0, 0, 0.12);
  background-color: $bgColor;
  width: 290px;
  z-index: 300;
  padding: 20px;
  margin-bottom: 0;
  transform: translateX(-100%);
  transition: transform $animationTime $animationFunc;
  overflow-y: auto;

  .filters-open & {
    transform: translateX(0);
  }

  @include for-tablet-landscape-up {
    margin-bottom: 44px;
    position: static;
    box-shadow: none;
    width: auto;
    transform: none;
    padding: 0 40px;
    overflow-y: visible;

    .button {
      margin-top: 7px;
    }
  }

  @include for-desktop-up {
    padding: 0;
  }
}

.listing__filter_close {
  position: absolute;
  right: 22px;
  top: 22px;

  .ic--close {
    width: 14px;
    height: 14px;
  }

  @include for-tablet-landscape-up {
    display: none;
  }
}

.listing__filter_title {
  font-size: 16px;
  font-family: $fontOpenSansBold;
  line-height: 20px;
  color: $textPrimaryColor;
  margin-bottom: 28px;

  @include for-tablet-landscape-up {
    display: none;
  }
}

.button__filter {
  @include for-tablet-landscape-up {
    display: none;
  }
}

.table__cell__item {
  @include for-tablet-landscape-up {
    display: none;
  }
}

.articles_list {
  margin-bottom: 25px;
}

.articles_list__item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.articles_list__link {
  display: block;
  background-color: $colorWhite;
  padding: 12px 13px;
  font-size: 13px;
  font-family: $fontOpenSansSemibold;
  line-height: 17px;
  color: $textPrimaryColor;
  border-radius: 3px;

  &:hover {
    background-color: $colorWhite;
    color: rgba($textPrimaryColor, .7);
  }
}

.articles_list__preview {
  font-size: 12px;
  color: #94989d;
  display: block;
  font-weight: normal;
  margin-top: 6px;
}

.more-link {
  font-size: 14px;
  color: $textSecondaryColor;

  &:hover {
    color: $textPrimaryColor;
  }
}

.sorting {
  display: flex;
  flex-flow: row wrap;
  padding-left: 20px;

  @include for-tablet-portrait-up {
    padding-left: 30px;
  }

  @include for-desktop-up {
    padding-left: 82px;
  }
}

.sorting__label {
  font-size: 12px;
  color: $textPrimaryColor;
  margin-right: 24px;
  width: 100%;
  margin-bottom: 5px;

  @include for-desktop-up {
    width: auto;
    margin-bottom: 0;
  }
}

.sorting__item {
  font-size: 12px;
  color: #262626;
  margin-right: 28px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: $textSecondaryHoverColor;
  }

  .ic--ic_sort {
    margin-left: 5px;
    fill: #daddde;
    transition: fill $animationTime $animationFunc;
  }

  &:hover {
    color: $textPrimaryColor;

    .ic--ic_sort {
      fill: $textSecondaryColor;
    }
  }

  &.listing__menu__itm--active {
    border: none;
    padding: 0;

    &:before {
      display: none;
    }

    .ic--ic_sort {
      fill: $colorYellow;
    }
  }

  &.listing__menu__itm--active--minus {
    .ic--ic_sort {
      transform: scaleX(-1) rotate(180deg);
    }
  }
}

.dropdown--full-width {
  input {
    width: 100%;
  }
}

.place_order {
  @include for-tablet-portrait-up {
    background-color: $colorWhite;
    max-width: 240px;
    border-radius: 4px;
    padding: 16px 6px 0;
    order: 1;
  }

  @include for-tablet-landscape-up {
    padding: 36px 26px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
  }
}

.table__cell--town {
  span {
    display: block;
  }

  @include for-desktop-up {
    span {
      max-width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

}

/** minitoggle */

.minitoggle {
  position: relative;
  width: 60px;
  height: 25px;
  background-color: #C6C7CE;
  border: 1px solid #EDEEF0;
  border-radius: 56px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, .1);
}

.minitoggle .toggle-handle {
  position: absolute;
  top: 3px;
  left: 2px;
  right: 2px;
  width: 17px;
  height: 17px;
  z-index: 2;
  background-color: #fff;
  background-image: -webkit-linear-gradient(top, #fff 0, #f2f2f2 100%);
  background-image: linear-gradient(to bottom, #fff 0, #f2f2f2 100%);
  border-radius: 100px;
  -webkit-transition: -webkit-transform 0.1s ease-in-out, border 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, border 0.1s ease-in-out;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.20);

}

.minitoggle.active {
  background-color: #FFD24D;
  border-color: #EDEEF0;
  box-shadow: inset 0 0 15px rgba(255, 255, 255, .25);
}

.minitoggle.active .toggle-handle {
  border-color: #EDEEF0;
  transform: translate3d(23px, 0, 0) !important;
}

/** override miniswitcher + switcher block styles */

.only_checked_masters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;

  .switcher {
    .toggle {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .text {
        margin-left: 20px;
        font-family: $fontOpenSans;
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        color: #262626;

        svg {
          position: relative;
          top: 3px;
        }
      }
    }

    .checked_masters_hidden {
      display: none;
    }
  }

  &.notavailable {
    opacity: 0.7;
    pointer-events: none;
  }
}

/** user masters not found page */

.not_found_info_block {
  text-align: center;
  max-height: 335px;
  max-width: 560px;
  margin: 0 auto;
  margin-top: 200px;

  img {

  }

  p {
    font-family: $fontOpenSansBold;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #262626;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  span {
    font-family: $fontOpenSans;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #262626;
  }
}

@media screen and (max-width: 899px) {
  .not_found_info_block {
    margin-top: 30px;
  }
}

@media (max-width: 899px) {
  .table-listing--v2 .table__user__text {
    & > a {
      font-size: 16px;
      word-break: break-word;
    }
  }
  .table-listing--v2 td.table__cell--name {
    font-size: 16px;
    word-break: break-word;
  }
}

@media screen and (max-width: 415px) {
  .not_found_info_block {
    p {
      font-size: 26px;
      line-height: 30px;
      margin-top: 20px;
    }
  }
}

.lt_2col .page_content__right--chat {
  height: 100%;

  @include for-tablet-landscape-only {
    padding-right: 20px;
    padding-left: 20px;
  }

  @include for-desktop-up {
    position: absolute;
  }
}

.lt_2col .page_content__left--chat {
  @include for-tablet-portrait-only {
    max-width: unset;
    padding-left: 16px;
    padding-right: 16px;
  }

  @include for-desktop-up {
    padding-right: 25px;
  }
}

.lt_2col {

  .page_content__left--listing {
    order: 1;
    float: none;
  }

  .page_content__left--messages {
    padding-bottom: 17px;

    @include for-tablet-portrait-up {
      max-width: none;
    }
    @include for-desktop-up {
      padding-right: 25px;
    }
  }
}

.listing__list__items{
  text-align: center;
  padding: 72px 0;
  background: #F9F9F9;
  li{
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #262626;
    margin-bottom: 15px;
    @media(max-width: 767px)  {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 8px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  @media(max-width: 1024px)  {
    margin: 0 -16px 0 -20px;
    padding: 40px 20px;
  }
  @media(max-width: 767px)  {
    padding: 32px 16px;
  }
}

.services-page__tab{
  background: #F9F9F9;
  padding: 50px 0 0;
}

.section-title__block{
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #262626;
  text-align: center;
}

.services-links{
  margin: 32px auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 1040px;
  justify-content: center;
}

.services-link{
  margin: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: #EDEEF0;
  letter-spacing: .5px;
  border-radius: 23px;
  color: #262626;
  transition: .25s;
  font-weight: 600;
  &:hover{
    background: #FFD24D;
    color: #262626;
  }
}

@media(max-width: 1024px)  {
  .services-page__tab{
    padding: 50px 20px 0;
  }
  .services-links{
    margin: 32px -10px 0;
  }
  .services-link{
    margin: 10px 5px;
    letter-spacing: 0;
  }
}

@media(max-width: 767px){
  .services-page__tab{
    padding: 32px 20px 0;
  }
  .section-title__block{
    font-size: 20px;
    line-height: 27px;
  }
  .services-links{
    margin: 20px -5px 0;
  }
 .services-link{
   margin: 5px;
   font-size: 12px;
   height: 33px;
   padding: 0 14px;
 }
}