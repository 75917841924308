// layout
html {
    height: 100%;
    overflow-y: scroll;

    &.menu_open {
        overflow: hidden;
        position: fixed;
        top: 0;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    @include tablet {
        .menu_open & {
            &:before {
                content: '';
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                right: 320px;
                bottom: 0;
                background-color: rgba(245, 246, 247, .5);
                z-index: 200;
            }
        }
    }

    @include desktop-up {
        overflow-x: inherit;
    }
    &:after {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1;
        content: url(/media/images/stars.svg) url(/media/images/stars-h.svg);
    }
    a {
        color: currentColor;
    }
    label {
        margin: 0;
        font-weight: normal;
        max-width: none;
    }
}

.layout_1col {
    .page_content {
        flex-flow: column;
    }
}

.page_content {
    font-family: $fontOpenSans;
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    //overflow-x: auto;
}

.page_content__left {
    width: 100%;
    flex-shrink: 0;
    //padding: 22px 0 88px;
    position: relative;

    @include tablet-up {
        width: 65%;
        padding-top: 28px;
    }

    @include desktop-up {
        width: 68%;
    }

    @include big-desktop {
        width: 75%;
    }
}

.page_content__right {
    background-color: $bgColor;
    flex-shrink: 0;
    padding: 74px 0 0;
    display: none;

    @include tablet-up {
        width: 35%;
        display: block;
        padding-top: 54px;
    }

    @include desktop-up {
        min-width: 320px;
        max-width: 320px;
    }

    @include big-desktop {
        width: 25%;
        max-width: inherit;
    }

    @media (max-width: 899px) {
        width: 100%;
    }
}

a:hover,
a:focus {
    text-decoration: none !important;
}

.js_link {
    cursor: pointer;
}

.page__title {
    &.is_mobile {
        display: none;
        @include tablet {
            display: block;
            font-family: $fontOpenSansBold;
            font-size: 18px;
            color: $textPrimaryColor;
            letter-spacing: 0;
            line-height: 25px;
            margin: 0;
            text-align: center;
            padding: 20px 0 24px;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
        }
    }
    .page_title {
        width: 75%;
        float: left;
        margin: 46px 61px 0;

        .master_descr {
            @include desktop-up {
                padding: 0 80px 74px;
            }
        }
        @include desktop-up {
            width: 68%;
        }

        @include desktop-less {
            width: 66%;
        }

        @include mobile {
            width: auto;
            float: none;
            order: 1;
            margin: 0;
        }
    }
}

.page_content__left {
    width: 75%;
    float: left;
    padding-top: 0;
    @include desktop-up {
        width: 69%;
        .specialization {
            padding: 0 20px 20px
        }
    }
    @include desktop-less {
        width: 64%;
    }

    @include mobile {
        width: auto;
        float: none;
        order: 3;
        //.master {
        //    display: none;
        //}
        margin-left: 0;
    }
}

.page_content__right {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;

    @include mobile {
        position: static;
        display: block;
        float: none;
        order: 2;
        padding-top: 0;
        .master {
            display: none;
        }
        .master_stats.is_fixed {
            position: static;
        }
        .master_stats.is_fixed .master {
            display: none;
        }
    }
}

.page_title {
    margin-top: 20px;

    .page_title__text {
        font-family: $fontOpenSansBold;
        font-size: 18px;
        color: $textPrimaryColor;
        line-height: 25px;
        margin: 0;
        text-align: center;
    }

    @include for-phone-only {
        margin-top: 0;
    }
    //todo remove after new breakpoints update
    @media (min-width: 640px) {
        .page_title__text {
            text-align: left;
        }
    }

    @include for-tablet-portrait-up {
        .page_title__text {
            font-size: 36px;
            line-height: normal;
            letter-spacing: -0.5px;
        }
    }
}

.lt_1col {
    .page_content {
        flex-direction: column;
        justify-content: flex-start;
    }
    .page_content__left {
        width: 100%;
        padding: 0;
        margin: 0;
        float: none;
    }

    .page_title {
        margin: 20px 20px 30px;

        @include for-tablet-portrait-up {
            margin: 36px 42px 26px;
        }

        @include for-tablet-landscape-up {
            margin-left: 26px;
            margin-right: 26px;
        }

        @include for-desktop-up {
            margin-left: 80px;
            margin-top: 60px;
        }
    }
}

.totally-hidden {
    display: none !important;
}
