.chat {
  padding: 0 0 81px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 110px;

  &__wrapper {
    width: 100%;
  }

  &__phrase {
    margin: 18px 15px 0 15px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__disabled-message {
    padding: 10px 16px;
    margin-bottom: 6px;
    background-color: $bgColor;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    border: 1px solid $btnPrimaryColor;
  }

  &__photo {
    display: none;
    margin-left: 6px;

    img {
      height: 54px;
      width: 54px;
    }
  }

  &__message {
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 10px 16px 14px;
    margin-right: 13px;
    margin-bottom: 10px;
    background-color: $bgColor;
    position: relative;
    font-size: 12px;
    line-height: 160%;
    border-radius: 4px;

    &:after {
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      background-color: $bgColor;
      transform: rotate(45deg);
      //border-left: 0.5px solid #EEEEEE;
      //border-top: 0.5px solid #EEEEEE;
      position: absolute;
      right: -7px;
      top: 12px;
      z-index: -1;
    }
  }

  &__time {
    display: block;
    font-size: 10px;
    line-height: 14px;
    color: $textSecondaryColor;

    span {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }
  }

  &__window {
    max-height: 493px;
    height: 493px;
    overflow-y: scroll;
    display: block;
  }

  @include for-tablet-portrait-up {
    border: 1px solid #F1F3F5;
    border-radius: 4px;
    margin: 130px 20px 22px;

    &__photo {
      display: block;
    }

    &__phrase {
      margin: 18px 17px 0 116px;
      padding-bottom: 15px;
    }

    &__message {
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 12px;
      padding: 15px 25px 15px 19px;
    }

    &__disabled-message {
      font-size: 14px;
      line-height: 23px;
      padding: 14px 25px 14px 19px;
    }

    &__time {
      font-size: 12px;
      line-height: 16px;
    }

    &__window {
      max-height: 518px;
      height: 518px;
    }
  }

  @include for-tablet-landscape-up {
    height: 100%;
    margin-left: 30px;
    margin-right: 0;
    margin-bottom: 15px;
    margin-top: 21px;

    &__window {
      height: 700px;
      max-height: 700px;
    }

    &__phrase {
      margin-left: 76px;
    }
  }

  @include for-desktop-up {
    margin-left: 80px;
    margin-right: 8px;
    margin-bottom: 29px;

    &__phrase {
      margin-left: 156px;
      margin-right: 15px;
    }

    &__message {
      padding-left: 21px;
      padding-bottom: 16px;
    }
  }
}

#messageform-message{
  margin-right: 10px;
  resize: none;
  padding-top: 11px;
  line-height: 20px;
}

.chat__phrase--master {
  .chat__message {
    background-color: #FCE8B1;
    margin-right: 0;
    margin-left: 14px;

    &:after {
      right: auto;
      left: -7px;
      background-color: #FCE8B1;
    }
  }

  .chat__time {
    text-align: right;
  }

  .chat__photo {
    margin-left: 0;
    margin-right: 6px;
  }

  @include for-tablet-portrait-up {
    margin: 18px 116px 0 17px;
    padding-bottom: 15px;

    .chat__message {
      padding: 14px 19px 18px 22px;
    }

    .chat__time {
      text-align: left;
      margin-left: 14px;
    }
  }

  @include for-tablet-landscape-up {
    margin-right: 76px;
  }

  @include for-desktop-up {
    margin-right: 156px;
    margin-left: 15px;

    .chat__message {
      padding-bottom: 16px;
    }
  }
}

.lt_2col .page_content--listing.page_content--chat {
  @include for-tablet-landscape-up {
    display: flex;
  }
}

.lt_2col .page_content__right--empty.page_content__right--listing {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 99;
}