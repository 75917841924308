@import 'base/variables';

.page_content_context {
  .page_content_container {
    width: 90%;
    padding: 0;
    margin: 0 auto;
  }

  p {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    max-width: 704px;
    margin: 0 auto;

    &.bold {
      font-family: $fontOpenSansBold;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  h3 {
    font-family: $fontOpenSansBold;
    font-size: 32px;
    line-height: 43px;
    text-align: center;
    color: #262626;
    margin-bottom: 40px;
  }

  .page_content_section_head {
    width: 100%;
    background: radial-gradient(513.00px at 50% 100%, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 100%), #EDEEF0;
    padding: 40px 0;
    position: relative;

    .breadcrumbs {
      margin: 0;
    }
    .page_content_section_head_title {
      .page_content_container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--about {
          width: 84.5%;
        }

        .info {
          &--about {
            margin-top: 28px;

            .about_specs--about {
              padding-top: 21px;
              max-width: 476px;
            }
          }

          h1 {
            font-family: $fontOpenSansBold;
            font-size: 44px;
            line-height: 60px;
            color: #262626;

            svg {
              height: 28px;
              width: 28px;
            }
          }
          h2 {
            font-family: $fontOpenSansBold;
            font-size: 12px;
            line-height: 25px;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #262626;
          }
        }
        .image {
          max-width: 717px;
          max-height: 707px;

          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }
  .page_content_section_what_for {
    .page_content_container {
      padding: 100px 0 40px 0;

      .inline_blocks {
        margin-top: 60px;
      }
    }
  }

  .page_content_section_how_to_pass_checking {
    .page_content_container {
      padding-bottom: 100px;
      text-align: center;

      .inline_blocks {
        margin-top: 60px;

        .yellow_round {
          background: $colorYellow;
          height: 50px;
          width: 50px;
          padding: 10px 0 5px 0;
          border-radius: 100px;
          margin: 0 auto;
          font-family: $fontOpenSansBold;
          font-size: 18px;
          line-height: 30px;
          text-align: center;
          color: #262626;
        }
      }
    }
  }

  .big_yellow_number  {
    display: block;
    margin: 0 auto 17px;
    font-family: $fontOpenSansBold;
    font-size: 128px;
    line-height: 175px;
    text-align: center;
    letter-spacing: -10px;
    text-transform: uppercase;
    color: $colorYellow;
  }

  .small_grey {
    font-family: $fontOpenSans;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #919599;
    display: block;
    margin-bottom: 100px;
  }

  .page_content_section_ready_to_pass_checking {
    background: $bgColor;

    .page_content_container {
      padding: 50px 0 80px 0;
      text-align: center;

        .checkmark_container {
          max-width: 300px;
          margin: 0 auto;
          padding-left: 44px;

          .checkmark_container_content {
            text-align: center;
            position: relative;

            img {
              height: auto;
              width: 100%;
            }

            .checkmark_text {
              position: absolute;
              top: 0;
              left: -24px;
              height: 100%;
              width: 100%;

              p {
                font-family: $fontOpenSans;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #262626;

                &.grey {
                  font-size: 13px;
                  text-align: center;
                  color: #919599;
                  margin-top: 15px;
                }
              }
              button {
                background: $colorYellow;
                border-radius: 21px;
                border: none;
                font-family: $fontOpenSans;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #262626;
                margin-top: 15px;
                padding: 12px 58px;
              }
              a {
                margin-top: 15px;
              }
            }
          }
        }
    }
  }

  .inline_blocks {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;


    .inline_blocks_item {
      text-align: center;

      .__icon {
        display: block;
        height: 55px;
        margin-bottom: 35px;
      }
      .__info {
        h4 {
          font-family: $fontOpenSansBold;
          font-weight: bold;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #262626;
          margin-bottom: 10px;
        }
        p {
          display: block;
          margin: 0 auto;
          max-width: 300px;
          font-family: $fontOpenSans;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          color: #919599;
        }
      }
    }

    &--about {
      margin-top: 50px;
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;

      .inline_blocks_item {
        width: 42%;
        min-width: 220px;

        .__icon {
          margin-bottom: 20px;
        }

        .__info {
          h4 {
            margin-bottom: 17px;
          }
        }
      }
    }
  }
}

.page_content_section_how_to_pass_checking {
  background-color: $bgColor;
  padding-top: 106px;
}

@media screen and (max-width: 900px) {
  .page_content_context {
    .page_content_section_what_for {
      .page_content_container {
        .inline_blocks {
          .inline_blocks_item {
            p {
              svg.ic.ic--king {
                display: none;
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 683px) {
  .page_content_context {
    .page_content_section_head {
      .page_content_section_head_title {
        .page_content_container {
          .info {
            h1 {
              font-size: 34px;
              line-height: 40px;
            }
            h2 {
              line-height: 20px;
            }
          }
        }
      }
    }
    .inline_blocks {
      .inline_blocks_item:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .page_content_context {
    .page_content_section_head {
      .page_content_section_head_title {
        .page_content_container {
          .info {
            h1 {
              font-size: 24px;
              line-height: 25px;

              svg {
                height: 15px;
                width: 15px;
              }
            }
            h2 {
              line-height: 15px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .page_content_context {
    h1 {
      font-size: 24px;
      line-height: 34px;
      text-align: center;
    }

    h2 {
      font-size: 9px;
      line-height: 21px;
      text-align: center;
    }

    h3 {
      font-size: 24px;
      line-height: 43px;
      margin-bottom: 10px;
    }

    p.bold {
      font-size: 10px !important;
      line-height: 20px !important;
    }

    .page_content_section_head {
      padding: 0;

      .breadcrumbs_container {
        display: none;
        visibility: hidden;
      }
      .page_content_section_head_title {
        .page_content_container {
          flex-wrap: wrap;
          padding-top: 0 !important;
          padding-bottom: 34px;

          .image {
            order: -1;
          }
          .info {
            h1 {
              svg {
                display: block;
                margin: 10px auto;
                height: 28px;
                width: 28px;
              }
            }
          }
        }
      }
    }

    .page_content_section_what_for,
    .page_content_section_how_to_pass_checking {
      .page_content_container {
        padding-top: 0;
        padding-bottom: 0;

        .inline_blocks {
          margin-top: 51px;

          .inline_blocks_item {
            h4 {
              font-size: 12px;
              line-height: 20px;
              margin-top: 19px;
              margin-bottom: 10px;
            }

            p {
              font-size: 12px;
              line-height: 22px;
            }
          }
        }

        p {
          font-size: 14px;
          line-height: 34px;

          &.first {
            margin-bottom: 20px;
          }

          &.bold {
            margin: 0;
          }
        }
      }
    }

    .page_content_section_what_for {
      padding-top: 50px;
    }

    .page_content_section_how_to_pass_checking {
      .page_content_container {
        padding-bottom: 34px;

        .small_grey {
          margin-bottom: 60px;
        }
      }
    }
    .page_content_section_ready_to_pass_checking {
      .page_content_container {

      }
    }
  }
}

.passport_check_security {
  padding: 76px 0;
  text-align: center;

  .ic--shield {
    margin-bottom: 22px;
  }

  p {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 24px;

    a {
      color: $colorYellow;
      font-family: $fontOpenSansSemibold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &.grey {
      font-size: 12px;
      line-height: 22px;
      color: #919599;
    }
  }

  @include for-tablet-portrait-up {
    .page_content_container {
      width: auto;
      padding: 0 20px;
    }
  }

  @include for-tablet-landscape-up {
    padding: 90px 0 100px;

    p {
      font-size: 18px;
      line-height: 30px;

      &.grey {
        font-size: 13px;
        line-height: 24px;
      }
    }
  }
}
