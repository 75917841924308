.city-popup__header {
  font-size: 22px;
  margin-bottom: 30px;
  line-height: 1;
  color: #000000;
}

.city-popup__list,
.city-popup__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  &--more {
    height: 100%;
    max-height: 445px;
    overflow-y: scroll;
  }
}

.city-popup__elem {
  width: 30%;
  margin-bottom: 18px;

  a {
    color: #000000;
    text-decoration: underline;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      color: $colorYellow;
      text-decoration: underline !important;
    }
  }
}

.city-popup__more {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 30px;

  span {
    border-bottom: 1px dotted #000;
    color: #000000;
    transition-property: color, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;

    &:hover {
      color: $colorYellow;
      border-bottom: 1px dotted $colorYellow;
    }
  }
}

.city-popup__input,
.page-cities__input {
  margin-bottom: 30px;

}

.city-popup__input .selectize-input,
.page-cities__input .selectize-input {
  border: 1px solid #000000;
  width: 100%;
  max-width: 450px;
  height: 40px;
  line-height: 28px;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-right: 20px;
  color: #000000;
}

.selectize-input > input {
  width: 100% !important;
}
