.upload-thumb {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  line-height: 0;
  height: 107px;
  width: 107px;
  margin-right: 13px;
  margin-bottom: 14px;
  border-radius: 3px;
  background-color: #EDEEF0;

  &:nth-child(5n) {
    margin-right: 0;
  }

  @media (max-width: 900px) {
    height: 125px;
    width: 125px;
    margin-right: 16px;

    &:nth-child(5n) {
      margin-right: 16px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &--loader {
    justify-content: center;
  }

  @media (max-width: 599px) {
    height: 136px;
    width: 136px;
    margin-right: 17px;
    &:nth-child(5n) {
      margin-right: 17px;
    }

    &:nth-child(3n) {
      margin-right: 17px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

.upload-thumb:hover {
  .upload-thumb__del,
  .upload-thumb__sortable-handler,
  .upload-thumb__move,
  .upload-thumb__cover {
    visibility: visible;
    opacity: 1;
  }

  .upload-thumb__sortable-handler {
    @media (max-width: 600px) { display: none }
  }
}

.upload-thumb__sortable-handler {
  height: 15px;
  width: 15px;
  background: #262626;
  border-radius: 50%;
  box-shadow: 0 0 2px #EDEEF0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  position: absolute;
  left: 3px;
  top: 3px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }

  @media (max-width: 1280px) {
    height: 17px;
    width: 17px;
    visibility: hidden;
    opacity: 1;
  }
}