.grade-rating {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  &__line {
    height: 4px;
    width: 62px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    margin-right: 15px;
  }

  &__back {
    height: 4px;
    width: 62px;
    background-color: #ACB0B6;
    position: absolute;
    z-index: 1;
    left: 0;
    top: -1px;
  }

  &__fill {
    height: 4px;
    width: 62px;
    background-color: $btnPrimaryColor;
    position: absolute;
    z-index: 2;
    left: -62px;
    top: -1px
  }

  &__text {
    color: $btnPrimaryColor;
    font-size: 12px;
    line-height: 16px;
    font-family: $fontOpenSansBold;
  }
}