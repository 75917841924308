.page_header {
    font-family: $fontOpenSans;
    background-color: $colorWhite;
    //height: 100px;
    font-size: 16px;
    box-sizing: border-box;
    position: relative;

    @include tablet-up {
        //height: 100px;
    }

    @include desktop-up-header {
        padding-top: 21px;
        //height: 140px;
    }
    //
    //@media (min-width:1000px) and (max-width: 1399px) {
    //    padding-top: 50px;
    //}
}
.page_header {
    font-family: "Open-Sans","Arial",sans-serif;
    background-color: #fff;
    font-size: 16px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 101;
    transition: .25s;
}
.header-flex{
    display: flex;
    align-items: center;
}
body {
    padding-top: 147px;
}
.page_header--auth {
    padding-top: 0;

    .page_header__inner {


        @include tablet-up {
            border: none;
        }
    }
}

.page_header__inner {
    height: 100%;
    display: flex;
    flex-flow: row;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 20px;
    background-color: $colorWhite;


    @media (max-width: 1279px) {
        height: auto;
    }

    @include tablet-up {
        padding-right: 24px;
        padding-left: 26px;
    }

    @include desktop-up-header {
        padding-left: 56px;
        padding-right: 40px;
        height: 100px;
    }
}

.page_header__left {
    display: flex;
    height: 100%;
    align-items: center;
    order: 0;
}

.page_header__right {
    display: flex;
    align-items: center;
    order: 0;

    @include desktop-up-header {
        display: none;
    }
}

.header_logo {
    display: block;
    width: 165px;
    height: 36px;
    position: relative;

    @include tablet-up {
        width: 206px;
        height: 47px;
    }
}

.header_logo__img {
    width: 100%;
}

.nav_toggle {
    width: 3em;
    height: 3em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    transition: all $animationTime $animationFunc;
    position: relative;

    &:hover {
        background-color: $bgColor;
    }

    .is_open & {
        z-index: 210;
        position: fixed;
        top: 0;

        @include tablet-up {
            right: 24px;
        }

        @media (max-width: 639px) {
            right: 16px;
        }
    }

    @include desktop-up-header {
        display: none;
    }
}

.nav_toggle__bar {
    display: block;
    width: 1.125em;
    height: .125em;
    margin-left: auto;
    margin-right: auto;
    background-color: $textPrimaryColor;
    transition: background $animationTime $animationFunc, transform $animationTime $animationFunc, opacity $animationTime $animationFunc;

    & + & {
        margin-top: .25em;
    }

    .is_open & {
        //position: fixed;
        &:nth-child(1) {
            opacity: 0;
        }
        &:nth-child(2) {
            margin-top: -.125em;
            transform: rotate(45deg);
        }
        &:nth-child(3) {
            margin-top: -.125em;
            transform: rotate(-45deg);
        }
    }
}

.user_orders {
    width: 32px;
    height: 32px;
    background-color: $btnPrimaryColor;
    font-family: $fontOpenSans;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: $textPrimaryColor;
    line-height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    position: relative;

    .is_open & {
        z-index: 110;
    }
    &.is-badge {
        position: absolute;
        height: 18px;
        width: 18px;
        font-size: 10px;
        line-height: 18px;
        top: -2px;
        right: -7px;
    }
}

.user_orders--empty {
    background-color: $btnSecondaryHoverColor;
    color: $textSecondaryColor;
}

.nav_main {
    display: none;
    position: fixed;
    flex-shrink: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $colorWhite;
    z-index: 201;
    text-align: center;
    padding: 94px 40px 155px;
    box-sizing: border-box;
    transition: transform 2s $animationFunc;
    min-width: 620px;
    order: 2;

    @media (max-width: 1279px) {
        order: 1;
    }

    .is_open & {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        padding-bottom: 36px;
        //min-height: 490px;

        @include desktop-up-header {
            min-height: inherit;
        }
    }

    .button {
        height: 34px;
        line-height: 34px;
        border-radius: 17px;
    }

    @media (min-width: 640px) and (max-width: 1102px) {
        transform: translateX(100%);
        left: auto;
        padding-top: 95px;

        .is_open & {
            transform: translateX(0);
        }
    }

    @include desktop-up-header {
        transform: none;
        position: static;
        padding: 0;
        text-align: left;
        display: flex;
        align-items: center;

        .is_open & {
            display: flex;
        }
    }

    @include mobile {
        min-width: auto;
    }

    @include desktop-less-header {
        width: 100%;

        &_buttons {
            width: 100%;
            max-width: 320px;
            margin: 0 auto;
        }
    }
}

.nav_main_list {
    @include desktop-up-header {
        display: flex;
        margin: 0;
    }
}

.nav_main_list__item {
    font-size: 14px;
    font-family: $fontOpenSansSemibold;
    line-height: 20px;
    margin-bottom: 20px;
    position: relative;

    &:after {
        content: '';
        height: 2px;
        left: -10px;
        right: -10px;
        background-color: $btnPrimaryColor;
        position: absolute;
        top: 100%;
        display: none;
        margin-top: 39px;
    }

    @include desktop-up-header {
        margin-left: 27px;
        margin-bottom: 0;

        &.nav_main_list__item--active:after {
            display: block;
        }
    }

    @include big-desktop {
        margin-left: 37px;
    }
}

.nav_main_list__link {
    color: $textPrimaryColor;

    &:hover {
        color: $textSecondaryHoverColor;
    }
    @include mobile {
        width: 100%;
    }
}

.nav_main_section {
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &__desk{
        display: flex;
        @include desktop-up-header-max {
            display: none;
        }
    }
    &__mob{
        display: none;
        max-width: 200px;
        margin: auto;
        @include desktop-up-header-max {
            display: flex;
            flex-direction: column;
        }
    }

    @include tablet-header {
        padding-bottom: 19px;
    }

    @include tablet-up {
        flex-direction: row;
    }
}

.nav_main_section__link {
    font-family: $fontOpenSansSemibold;
    font-size: 12px;
    color: $textSecondaryColor;
    margin-bottom: 12px;

    &:hover {
        color: $textSecondaryHoverColor;
    }

    @include desktop-up-header {
        margin-left: 18px;
        margin-bottom: 0;
    }
}

.nav_main_footer {
    @include desktop-up-header {
        display: none;
    }
}

.nav_main_footer__copyright {
    font-family: $fontOpenSans;
    font-size: 11px;
    color: $textSecondaryColor;

    @include tablet-header {
        margin-bottom: 30px;
    }
}

.nav_main_buttons {
    padding: 10px 0;
    font-family: $fontOpenSansSemibold;

    .button {
        width: 100%;
        margin-bottom: 16px;
        max-width: 320px;
        font-family: $fontOpenSansSemibold;
    }

    @include tablet-header {
        padding-top: 16px;
    }

    @include desktop-up-header {
        padding: 0;
        display: flex;
        align-items: center;

        .button {
            width: auto;
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }

        .button__login {
            background-color: transparent;
            padding: 0;
            margin-left: 28px;

            &:hover {
                color: $textSecondaryHoverColor;
                background-color: transparent;
            }
        }

        .button__start {
            background-color: $btnPrimaryColor;
            margin-left: 30px;

            &:hover {
                color: $textSecondaryHoverColor;
                background-color: $btnPrimaryHoverColor;
            }
        }
    }

    @include big-desktop {
        .button__login {
            margin-left: 38px;
        }

        .button__start {
            margin-left: 40px;
        }
    }
}

.nav_user {
    text-align: center;
    padding: 1px 0 20px;

    @include mobile {
        .user_orders {
            display: block;
        }
    }

    @include desktop-up-header {
        padding-top: 12px;

        .user_orders {
            display: none;
        }
    }

    @include desktop-up-header {
        display: flex;
        align-items: center;
        padding: 0;
        margin-left: 28px;

        .user_orders {
            margin: 0;
        }
    }

    @include big-desktop {
        margin-left: 40px;
    }
}

.nav_user__link {
    display: block;
    margin-bottom: 17px;

    &:hover {
        .nav_user__photo {
            opacity: .7;
        }
    }
    &.is--active {
        .ic--arrow_down {
            transform: rotate(180deg);
        }
    }

    @include desktop-up-header {
        margin-bottom: 0;
    }
}

.nav_user__photo {
    width: 48px;
    height: 48px;
    overflow: hidden;
    margin: 0 auto 16px;
    border-radius: 50%;
    transition: opacity $animationTime $animationFunc;
    position: relative;

    img {
        max-width: 100%;
        border-radius: 50%;
    }

    @include desktop-up-header {
        margin: 0 20px;
    }

    &.is-drop {
        overflow: inherit;
        @include desktop-up-header {
            margin: 0 15px;
        }
        @include tablet-header {
            margin: 0 20px 0 auto;
            .user_orders {
                margin: 0;
            }
        }
        @include mobile {
            margin: 0;
            width: 34px;
            height: 34px;
            .user_orders {
                margin: 0;
            }
        }
    }
}

.nav_user__name {
    font-family: $fontOpenSans;
    font-size: 14px;
    font-weight: 600;
    color: $textPrimaryColor;

    @include desktop-up-header {
        display: none;
    }
    &.is-drop {
        display: block;
        font-weight: 600;
        font-size: 12px;
        color: $textSecondaryColor;
        margin: 0;
        @include mobile {
            font-weight: 600;
            color: $textPrimaryColor;
            margin-top: 8px;
        }

    }
}

.nav_user__logout {
    font-family: $fontOpenSansSemibold;
    font-size: 14px;
    color: $textSecondaryColor;
    padding: 0;
    background-color: transparent;
    border: none;

    &:hover {
        color: $textSecondaryHoverColor;
    }
}

.nav_footer {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin-top: auto;

    @include tablet-header {
        bottom: 36px;
    }

    @include desktop-up-header {
        bottom: auto;
        top: 10px;
        right: 30px;
        left: auto;
    }

    @include big-desktop {
        right: 40px;
    }
}

.is_open .nav_footer {
    position: static;
    margin-top: auto;
}

.nav_user_drop {
    position: relative;
    margin-left: 25px;
    font-family: $fontOpenSans;
    .nav_user__link {
        display: flex;
        align-items: center;

        * {
            pointer-events: none;
        }
    }
    @include tablet-header {
        display: none;
    }
    @include mobile {
        display: flex;
        justify-content: center;
        margin: 20px 0 0;
        .ic--arrow_down {
            display: none;
        }
        .nav_user__link {
            flex-direction: column-reverse;
            margin: 0;
        }
    }
}

.nav_user_drop_menu {
    text-align: left;
    position: absolute;
    background: $colorWhite;
    border: 1px solid $btnSecondaryColor;
    box-sizing: border-box;
    border-radius: 3px;
    top: 56px;
    display: none;
    width: 160px;
    right: 0;
}

.nav_user_drop_menu__link {
    line-height: 30px;
    font-size: 14px;
    color: $colorBlack;
    display: block;
    padding: 7px 0 7px 17px;
    &:hover {
        color: $colorBlack;
        background: $bgColor;
    }
    &.is-out {
        background-color: transparent;
        border: none;
        display: block;
        width: 100%;
        text-align: left;
        &:hover {
            color: $colorBlack;
            background: $bgColor;
        }
    }
}

.nav_user_drop_menu__item {
    position: relative;

    &.with-divider {
        &:after {
            content: '';
            height: 1px;
            background-color: $btnSecondaryColor;
            position: absolute;
            top: 100%;
            left: 10px;
            right: 10px;
        }
    }
}

.nav_user_mobile {
    display: none;
    @include mobile {
        display: block;
        margin-top: 4px;
    }
    @include tablet-header {
        display: block;
    }
}

.nav_user_mobile__link {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: $placeholderColor;
    &.is-out {
        background-color: transparent;
        border: none;
        &:hover {
            color: $colorBlack;
            background: $bgColor;
        }
    }
}

.page_header__wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    @include desktop-up-header-max {
        width: 100%;
    }
}


.page_header__right .nav_user {
    padding: 0;
}

.page_header__right .nav_user .user_orders {
    display: block;
}

.nav_main_section__round-btn {
    height: 24px;
    width: 24px;
    border: none;
    border-radius: 50%;
    margin-left: 18px;
    background-color: $btnPrimaryColor;
    background-image: url(/media/images/burger.png);
    background-position: center center;
    background-repeat: no-repeat;
}
