.order-history {
  width: 100%;
  padding-top: 15px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  background-color: #e3e5e8;
}

.order-history__title {
  margin: 0;
  margin-bottom: 10px;

  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #a5a8ad;
  font-style: normal;
}

.order-history__list {
  display: flex;
  flex-direction: column;
}

.order-history__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  padding-top: 6px;
  padding-right: 12px;
  padding-bottom: 4px;
  padding-left: 12px;

  background: #f5f6f7;
  border-radius: 4px;

  &--success {
    .order-history__order-number:after {
      background-image: url(/media/images/order-success.svg);
    }
  }

  &--waiting {
    .order-history__order-number:after {
      background-image: url(/media/images/order-waiting.svg);
    }
  }

  &--error {
    .order-history__order-number:after {
      background-image: url(/media/images/order-error.svg);
    }
  }
}

.order-history__order-number {
  position: relative;

  display: flex;
  align-items: center;
  margin: 0;

  font-size: 12px;
  line-height: 20px;
  cursor: unset;

  &:after {
    content: '';
    position: relative;

    display: block;
    width: 12px;
    height: 12px;
    margin-left: 7px;
  }
}
