.file-input {

  .file-preview {

    .file-drop-zone {
      min-height: unset;

      &:focus {
        border: 2px solid #FFD24D;
      }
    }

    .file-preview-thumbnails {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .file-preview-frame {
      padding: unset;

      .kv-file-content {
        width: unset;
        height: unset;

        .file-preview-image {
          max-width: 100%;
          max-height: 100%;
          image-orientation: from-image;

          height: 107px !important;
          width: 107px !important;
          object-fit: cover;
          border-radius: 3px;
        }
      }

      &:hover {
        .actions .file-delete-wrapper {
          visibility: visible;
          opacity: 1;
        }
      }

      .actions {

        button {
          &:focus {
            outline: unset;
          }

          border: none;
          background-color: unset;
          padding: 0;
        }

        .file-drag-handle {
          margin: unset;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          .glyphicon-move {
            position: absolute;
            top: 4px;
            left: 2px;
          }
        }

        .file-delete-wrapper {
          position: absolute;
          right: 3px;
          top: 3px;
          cursor: pointer;
          padding: 0;
          width: 17px;
          height: 17px;

          display: flex;
          align-items: center;
          justify-content: center;

          visibility: hidden;
          opacity: 0;

          font-size: unset !important;
          line-height: unset !important;


          .file-delete-wrapper-icon {
            display: flex !important;
            justify-content: center;
            align-items: center;

            box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.4);
            transition: opacity 0.3s ease-in-out;
            background-color: #fff;
            border-radius: 50%;
            padding: 5px;
            line-height: 0;

            svg {
              height: 7px;
              width: 7px;
            }
          }

          @media (max-width: 800px) {
            right: 0;
            top: 0;
            width: 34px;
            height: 34px;
            justify-content: center;
            align-items: center;
            visibility: visible;
            opacity: 1;

            .file-delete-wrapper-icon {
              position: absolute;
              top: 3px;
              left: 14px;
            }
          }
        }
      }
    }
  }
}