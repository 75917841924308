// page custom

.profile_edit_page {
    .phone_input__wrap {
        width: 200px;
        @include mobile {
            width: 100%;
        }
    }
}

.profile_create_page {
    .phone_input__wrap {
        width: 320px;
        @include mobile {
            width: 100%;
        }
    }
    .phone_input__wrap {
        &.is_code {
            width: 200px;
            @include mobile {
                width: 100%;
            }
        }
    }
}

// common
.phone_wrap {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    @include mobile {
        flex-direction: column;
        .button--confirm {
            margin-top: 20px;
            max-width: 132px;
        }
        .phone_input__wrap {
            &.is_code {
                @include mobile {
                    margin-top: 20px;
                    margin-bottom: 30px;
                }
            }
        }

    }
}

.phone_input__wrap {
    margin-right: 20px;
    width: 200px;
}

.phone_input__error {
    font-family: "Open Sans", "Arial", sans-serif;
    font-size: 12px;
    color: #fe253c;
    letter-spacing: 0;
    line-height: 30px;
}

.sms_timer {
    font-size: 12px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    //position: absolute;
    //pointer-events: none;
    //bottom: -24px;
    //width: 100%;
    line-height: 1;

    @include mobile {
        top: 118px;
    }
}

.phone_block {
    &.is_init {
        .phone_input__wrap {
            &.is_code {
                display: none;
            }
        }
        .phone_service_text {
            &.phone_is_confimed {
                display: none;
            }
            &.phone_is_empty {
                display: block;
            }
        }
        .button--confirm {
            display: none;
        }
    }
    &.phone_is_confirmed {
        .phone_input__wrap {
            &.is_code {
                display: none;
            }
        }
        .button--confirm {
            display: none;
        }
        .phone_service_text {
            display: none;
        }
        .phone_input__wrap {
            position: relative;
            &.is_phone {
                &:after {
                    display: block;
                    content: '';
                    width: 11px;
                    height: 9px;
                    background: url(/media/images/is_confirmed.svg) no-repeat;
                    position: absolute;
                    right: 18px;
                    top: 21px;
                }
            }
        }
    }
    &.send_phone_code {
        .phone_input__wrap {
            &.is_code {
                display: none;
            }
        }
        .button--confirm {
            display: block;
        }
        .phone_service_text {
            display: none;
        }
    }
    &.code_has_sent {
        .phone_input__wrap {
            &.is_code {
                display: block;
            }
        }
        .button--confirm {
            display: block;
        }

        .phone_service_text {
            display: none;
        }
        .sms_timer {
            &.phone_send_again {
                display: block;
            }
        }
    }
    &.run_timer {
        .phone_input__wrap {
            &.is_code {
                display: block;
            }
        }
        .button--confirm {
            display: block;
        }
        .phone_service_text {
            display: none;
        }
        .send_again_msg {
            display: none;
        }
    }

    &.phone_error {
        .phone_input__wrap {
            &.is_code {
                display: none;
            }
        }
        .button--confirm {
            display: block;
        }
        .phone_service_text {
            display: none;
        }
        .phone_input__wrap {
            &.is_phone {
                input {
                    border: 1px solid #fe253c;
                }
            }
            .phone_input__error {
                display: block;
            }
        }
    }
    &.code_error {
        .phone_input__wrap {
            &.is_code {
                display: block;
            }
        }
        .button--confirm {
            display: block;
        }
        .phone_service_text {
            display: none;
        }
        .phone_input__wrap {
            &.is_code {
                input {
                    border: 1px solid #fe253c;
                }
            }
            .phone_input__error {
                display: inline-block;
            }
        }
    }

    &.is_code_fine {
        .phone_input__wrap {
            &.is_code {
                display: none;
            }
        }
        .button--confirm {
            display: none;
        }
        .phone_service_text {
            &.phone_is_confimed {
                display: block;
            }
            &.phone_is_empty {
                display: none;
            }
        }
        .phone_input__wrap {
            position: relative;
            &.is_phone {
                &:after {
                    display: block;
                    content: '';
                    width: 11px;
                    height: 9px;
                    background: url(/media/images/is_confirmed.svg) no-repeat;
                    position: absolute;
                    right: 18px;
                    top: 21px;
                }
            }
        }
    }
}

.form__text {
    appearance: none;
    -moz-osx-font-smoothing: grayscale;
    &.phone_input__input {
        width: 100%;
    }
}
