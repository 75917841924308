.petrovich_form {
  background: #FAFAFA;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin: 0 0 30px;
  padding: 10px 40px;

  &-icon {
    stroke: #FFD24D;
    margin-right: 16px;
    min-width: 24px;
  }

  &:hover {
    background-color: #ffd24d;

    .petrovich_form-text {
      color: #FFFFFF;
    }

    .petrovich_form-icon {
      stroke: #FFFFFF;
    }
  }

  @media (max-width: 500px) {
    padding: 10px 20px;
    width: 100%;
  }

  &-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    color: #676767;
  }
}

.modal-wrapper-petrovich {
  padding: 30px;
  width: 500px;
  z-index: 300;

  .modal-body {
    &--header {
      .title {
        font-size: 20px;
        line-height: 25px;

        font-style: normal;
        font-weight: bold;
        color: #262626;
        margin-bottom: 40px;
      }
    }

    &--text {
      .petrovich-link {
        color: red;
        line-height: 135%;
        text-decoration: underline;
      }

      p {
        margin-bottom: 25px;

        .ic--asterix {
          fill: #ffd966;
        }
      }

      p:last-child {
        margin-bottom: unset;
      }
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button__login {
      width: 308px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 32px 0 16px 0;

      font-style: normal;
      font-size: 18px;
      line-height: 25px;
      color: #262626;
    }

    .button__problems {
      text-decoration: underline;
    }
  }

  .modal-wrapper__close {
    position: absolute;
    top: 25px;
    right: 30px;
    cursor: pointer;
    z-index: 205;

    .ic--cross-modal {
      width: 20px;
      height: 20px;
    }
  }

  .modal-petrovich_form {
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    overflow-y: auto;
    padding: 80px 30px;

    .modal-wrapper__close {
      top: 80px;
    }
  }
}
