.master-chat {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 16px 23px 14px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  //z-index: 100;
  background-color: $bgColor;

  &__photo {
    margin-right: 14px;

    img {
      height: 52px;
      width: 52px;
    }
  }

  &__name {
    //font-weight: 600;
    font-family: $fontOpenSansBold;
    font-size: 18px;
    line-height: 25px;
    color: $textPrimaryColor;
    margin-bottom: 6px;
    margin-top: 2px;
  }

  &__status {
    //font-weight: 600;
    font-family: $fontOpenSansBold;
    font-size: 10px;
    line-height: 14px;
    padding-left: 17px;
    position: relative;
    color: $textSecondaryColor;

    &:before {
      content: '';
      display: block;
      height: 12px;
      width: 12px;
      background-color: $btnPrimaryColor;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @include for-tablet-portrait-up {
    padding: 20px 23px 14px;
    top: 98px;

    &__name {
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 12px;
    }

    &__status {
      font-size: 12px;
      line-height: 16px;
      padding-left: 24px;
      letter-spacing: -0.3px;

      &:before {
        height: 16px;
        width: 16px;
      }
    }

    &__photo {
      margin-right: 20px;

      img {
        height: 72px;
        width: 72px;
      }
    }
  }

  @include for-tablet-landscape-up {
    position: relative;
    width: 400px;
    right: 97px;
    top: -8px;
    background-color: transparent;
    z-index: 100;
    padding-bottom: 24px;
  }
}

.page_content__right--chat .master_stats, .master_stats.is_fixed, .master_stats.is_bottom {

  @include for-tablet-landscape-up {
    top: 0;
    max-width: 320px;
  }
}