.order-number {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: $colorWhite;
  border-radius: 4px;
}

.order-number__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 9px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

.order-number__text {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: $placeholderColor;
  margin-bottom: 12px;
  padding-right: 40px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
}

.order-number__row {
  border: 1px solid $inputBorder;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 44px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
}

.order-number__form {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 5px;
  max-width: 240px;

  button {
    appearance: none;
    border: none;
    border-radius: 4px;
    background-color: $colorYellow;
    background-image: url(/media/images/send.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px;
    width: 42px;
    min-width: 42px;

    &:hover {
      background-color: $btnPrimaryHoverColor;
    }
  }

  input {
    min-width: 50px;
    max-width: 100%;
    width: 100%;
    margin-left: 15px;
    border: none;
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
  }
}

.order-number__add {
  color: $placeholderColor;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  align-self: flex-start;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;

  &:hover {
    color: #0D2038;
  }
}