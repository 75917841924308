@import 'base/variables';

.lt_2col {

    .page_content__left {
        padding-bottom: 14px;
        .master {
            margin-top: 44px;
            @include mobile {
                margin-top: 0;
            }
        }
    }

    @include mobile {
        .page_content__right {
            display: block;
            padding: 0;
            padding-bottom: 34px;
        }
        .master_stats {
            &.is_fixed {
                position: static;
            }
        }
    }

    @include tablet {
        .page_content__right {
            display: block;
            padding: 0;
            padding-bottom: 34px;
        }
    }

    @include big-desktop {
        .page_content__right {
            min-width: 360px;
            max-width: 360px;
        }

        .page_content__left {
            width: 70%;
        }
    }

    @include desktop {
        .page_content__right {
            min-width: 360px;
            max-width: 360px;
        }

        .page_content__left {
            width: 62.5%;
        }
    }

    @include tablet {
        .page_content__right {
            padding: 26px 20px 3px;
            .profile_info {
                width: 100%;
                margin: 0;
            }
        }
    }
    .table_styled__value--rating {
        width: 50px;
        @include mobile {
            width: 20%;
            padding-right: 10px;
        }
    }

    .table_styled__value--subscribe {
        width: 100px;
        text-align: center;
    }

    .table_styled tr {
        cursor: default;
        &.is_done {
            background: #f5f6f7;
        }
    }
}

.profile {
    margin: 50px 59px 27px 70px;
    @include desktop {
        margin-right: 0;
    }
    @include tablet {
        margin-left: 30px;
    }
    @include mobile {
        margin: 0;
    }
}

.profile__title {
    font-size: 36px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: bold;
    margin: 20px 0 0 78px;
    @include tablet {
        margin-left: 41px;
        font-size: 24px;
    }
    @include mobile {
        font-family: $fontOpenSansBold;
        font-size: 18px;
        color: $textPrimaryColor;
        letter-spacing: 0;
        line-height: 25px;
        text-align: center;
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 25px;
    }
}

.text-center {
    text-align: center;
}

.tabs {
    &.is_profile {
        padding: 0;
        .tabs_links {
            margin-bottom: 34px;
            @include mobile {
                margin-bottom: 26px;
                margin-top: 20px;
            }
            @include tablet {
                margin-bottom: 26px;
                margin-top: 20px;
            }
        }
        @include mobile {
            padding: 0 10px;
        }
    }
}

.role_switcher {
    display: flex;
    align-items: center;
}

.card_info {
    display: flex;
    align-items: center;
    line-height: 42px;
    justify-content: flex-start;
    height: 44px;
    @include tablet {
        position: static;
    }
    @include mobile {
        left: 0;
        justify-content: space-between;
    }
}

.card_info__item {
    font-family: $fontOpenSansBold;
    font-size: 12px;
    color: $textPrimaryColor;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 38px 0 0;
    &:last-of-type {
        padding: 0;
    }
    @include mobile {
        padding: 0;
    }
}

.page_content__right {
    &.is_profile {
        background-color: $colorWhite;
    }
}

.profile_info {
    width: 320px;
    margin: 0 40px 0 auto;
    background-color: $bgColor;

    @include mobile {
        width: auto;
        margin: 0;
    }
    &.is_fixed {
        top: 0;
        position: fixed;
        z-index: 100;
        @include tablet {
            position: static;
        }
        @include mobile {
            position: static;
        }
        .user_info__inner {
            position: relative;
            left: -64px;
            @include tablet {
                position: static;
            }
            @include mobile {
                position: static;
            }
        }
    }
}

.profile_info__inner {
    padding: 33px 40px 34px 37px;
}

.role_switcher__ic {
    padding: 0 16px;
    margin-bottom: 20px;

    &:first-child {
        padding-left: 0;
    }

    @include tablet-up {
        margin-bottom: 0;
    }
}

.role_switcher__role {
    &:last-child {
        color: $textSecondaryColor;
    }
}

.role_switcher__radio_input {
    display: none;
}

.role_switcher__radio_label {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 38px;
    cursor: pointer;

    @include tablet-up {
        align-items: center;
        flex-direction: row;
    }
}

.user_info {
    margin-bottom: 32px;
}

.user_info__inner {
    display: flex;
}

.user_info__data {
    max-width: 240px;
}

.user_info__photo {
    width: 54px;
    height: 54px;
    box-shadow: 0 1px 1px $btnSecondaryColor;
    flex-shrink: 0;
    min-width: inherit;
    border: 3px solid $colorWhite;
    box-sizing: border-box;
    border-radius: 50%;
    align-self: flex-end;
    margin-right: 13px;

    .user_info__img {
        max-width: 100%;
        border-radius: 50%;
    }
}

.user_info__grade {
    margin-bottom: 7px;
    @extend %section_title;
}

.user_info__name {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.39;
    margin-bottom: 0;
}

.profile_efficiency__title {
    @extend %section_title;
}

.status_bar {
    display: flex;
    margin-top: 22px;
}

.status_bar__scale {
    background-color: $btnPrimaryColor;
    height: 6px;
    border-radius: 2px;
}

.status_bar__container {
    width: 200px;
    height: 6px;
    background: $btnSecondaryHoverColor;
    border-radius: 2px;
}

.status_bar__in_percents_value {
    position: relative;
    top: -9px;
    left: 20px;
    font-family: $fontOpenSansBold;
    font-size: 12px;
    color: #262626;
    letter-spacing: 0;
}

.profile_efficiency__text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    line-height: 24px;
}

.profile_content {
    margin-top: 34px;
}

.profile_content__marker {
    width: 10px;
    height: 10px;
    background-color: $textSecondaryColor;
    border-radius: 50%;
    position: relative;

    &:after {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #f5f6f7;
        position: absolute;
        border-radius: 50%;
        top: 2px;
        left: 2px;
    }
}

.profile_content__item {
    display: flex;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    &.is_active {
        .profile_content__marker {
            width: 24px;
            height: 24px;
            background: url(/media/images/ic_is_done.svg);
            position: relative;
            left: -7px;
            top: -7px;
            &:after {
                content: '';
                opacity: 0;
            }
        }

        .profile_content__texts {
            margin-left: 10px;
        }
    }
}

.profile_content__texts {
    margin-left: 20px;
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $colorBlack;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    top: -6px;
}

.profile_content__point {
    color: $textSecondaryColor;
}

.delete_item {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include mobile {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        .ic--delete {
            padding: 0;
            left: 0;
            top: -35px;
        }
    }
}

//.ic--delete {
//    padding-left: 20px;
//    position: relative;
//    left: 7px;
//    cursor: pointer;
//    box-sizing: content-box;
//}

.ic--master {
    fill: #e3e5e8;
}

.ic--company {
    fill: #e3e5e8;
}

.role_switcher__radio_input:checked + label {
    .ic--master {
        fill: $colorYellow;
        transition: all .2s ease;
    }
    .ic--company {
        fill: $colorYellow;
        transition: all .2s ease;
    }
    .role_switcher__role {
        color: $colorBlack;
        transition: all .2s ease;
    }
}

.user_photo {
    &.is_profile {
        position: relative;
        cursor: pointer;
        height: 160px;
        width: 160px;
        margin-right: 25px;
        //background: url(/media/images/img_bg_stub.svg);
        background-size: cover;
        .file-drop-zone {
            margin: 0 !important;
            padding: 0 !important;
            border: none !important;
            min-height: unset !important;
        }
        @include mobile {
            width: 100px;
            height: 100px;
            max-width: none;
        }
        .user_photo__img {
            height: 160px;
            width: 160px;
            position: relative;

            @include mobile {
                width: 100px;
                height: 100px;
                max-width: none;
            }
        }
        &:hover {
            &:after {
                content: '';
                width: 160px;
                height: 160px;
                background: $colorYellow;
                position: absolute;
                border-radius: 50%;
                opacity: .8;
                top: 0;
                right: 0;
                @include mobile {
                    width: 100px;
                    height: 100px;
                }
            }
            &:before {
                content: '';
                width: 56px;
                height: 41px;
                position: absolute;
                z-index: 1;
                background: url('/media/images/photo.svg') no-repeat;
                background-size: cover;
                top: 62px;
                left: 53px;
                @include mobile {
                    top: 36px;
                    left: 31px;
                    width: 37px;
                    height: 26px;
                }
            }
        }
    }
}

.img_profile_placeholder {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 2px solid $btnSecondaryHoverColor;
    box-sizing: border-box;
    &:before {
        content: '';
        width: 56px;
        height: 41px;
        position: absolute;
        background: url('/media/images/photo_placeholder.svg');
        left: 53px;
        top: 60px;
    }
}

.user_photo_upload {
    display: inline-flex;
    align-items: center;
    &:hover {
        .user_photo__text {
            display: flex;
        }
    }
    @include mobile {
        display: block;
        &:hover {
            .user_photo__text {
                display: none;
            }
        }
    }
}

.user_photo__text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    line-height: 24px;
    display: none;
    &.is_portfolio {
        display: block;
        left: 0;
        margin-top: 10px;
    }
}

.ic--photo_placeholder {
    stroke: #000;
    fill: #fff;
    stroke-width: 2px;
}

.portfolio_controls__file_input,
.review_controls__file_input,
.feedback_controls__file_input {
    display: none !important;
}

.modal_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(255, 255, 255, .8);
    z-index: 201;

    //&:nth-of-type(2n) {
    //    background: rgba(0, 0, 0, 1 );
    //}
}

.confirm_popup {
    width: 577px;
    position: fixed;
    left: 22%;
    background: $colorWhite;
    text-align: center;
    z-index: 1000;
    padding: 42px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .05);
    border-radius: 5px;
    display: none;
    .btn--reset {
        background: #e3e5e8;
        color: $textPrimaryColor;
        font-family: $fontOpenSansSemibold;
        &:hover {
            opacity: 0.9;
        }
    }
    .btn--submit {
        margin-right: 18px;
    }
    @include mobile {
        width: 280px;
        left: 50%;
        top: 50% !important;
        transform: translate(-50%, -50%);
        padding: 18px 0;
        .form__btns {
            padding: 0 10px;
        }
    }
    @include tablet {
        .form__btns {
            padding: 0 60px;
        }
    }
}

.confirm_popup__qstn {
    font-family: $fontOpenSansBold;
    font-size: 18px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 23px;
    @include mobile {
        text-align: left;
        margin: 0 22px 0 18px;
        margin-bottom: 19px;
    }
}

.confirm_popup__text {
    text-align: left;
    font-family: $fontOpenSans;
    font-size: 12px;
    color: #262626;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 76px;
    padding-right: 53px;
    margin-bottom: 33px;
    @include mobile {
        margin: 0 22px 0 18px;
        padding: 0;
        margin-bottom: 28px;
    }
}

.star_cover {
    background: rgba(255, 210, 77, .15);
    z-index: 11;
    height: 18px;
    position: absolute;
    width: 90px;
}

.portfolio_items {
    &.is_profile {
        @include mobile {
            margin: 0;
            padding: 0;
        }
        .portfolio_item {
            margin-right: 40px;
            @include mobile {
                margin: 0;
            }
        }
    }
}

.portfolio_controls__label {
    &.is_load_photo {
        display: none;
        @include mobile {
            display: block;
            .btn--reset {
                width: 100%;
            }
        }
    }
}

.portfolio_controls {
    padding: 0 20px 28px;

    .section_title {
        margin-bottom: 37px;
    }

    @include mobile {
        margin-top: 32px;
        padding: 0;
    }
}

.portfolio_controls__form {
    @include mobile {
        padding: 0;
    }
    .form_row {
        margin-bottom: 20px;
        @include desktop-less {
            flex-direction: column;
        }
    }

    .form__btns {
        text-align: left;
        @include mobile {
            padding-bottom: 50px;
        }
    }
}

.portfolio_controls_imgs {
    margin: 33px -20px 0 0;
}

.portfolio_controls_imgs__container {
    display: flex;
    flex-wrap: wrap;
    @include mobile {
        justify-content: center;
    }
}

.portfolio_controls_imgs__item {
    margin: 0 20px 20px 0;
    position: relative;
    display: block;
    &:hover {
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            background: #f5f6f7;
            z-index: 1;
            left: 0;
            opacity: .8;
            cursor: pointer;
        }
        &:before {
            content: '';
            width: 29px;
            height: 29px;
            top: 36%;
            left: 56px;
            position: absolute;
            background: url(/media/images/del_img.svg);
            z-index: 2;
        }
    }

    img {
        width: 140px;
    }
}

.portfolio_controls_imgs_error__text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $colorError;
    letter-spacing: 0;
    line-height: 30px;
    padding-left: 9px;
}

.portfolio_controls_imgs_error {
    &:last-of-type {
        margin-bottom: 41px;
    }
    .ic--delete {
        fill: $colorError;
        padding: 0;
        position: static;
    }
    &.is_mobile {
        display: none;
        @include mobile {
            display: block;
            text-align: center;
            padding-right: 20px;
            padding-top: 36px;
            .ic--mob_error {
                margin-bottom: 7px;
            }
            .form_error {
                margin-bottom: 29px;
            }

            .btn--reset {
                width: 100%;
            }
        }
    }

    &.is_desktop {
        display: block;
        @include mobile {
            display: none;
        }
    }
}

.skills_table {
    tbody tr:hover {
        @include mobile {
            .table_styled__value {
                background: none;
            }
        }
    }

    @include mobile {
        .skills_table &__value {
            &--name {
                flex: 0 0 80%;
                order: 0;
                flex-grow: 1;
            }

            &--subscribe {
                width: 30px;
                order: 1;
                border-bottom: 0 none;
            }

            &--close_spec {
                width: 30px;
                margin: 0 5px;
                position: relative;
                order: 2;
                right: 0;
                padding-right: 0;

                .delete_item {
                    align-items: center;

                    .ic--delete {
                        top: 5px;
                    }
                }
            }

            &--prices {
                padding: 0 10px;
                order: 3;
                border-bottom: 0 none;
            }

            &--stars {
                order: 4;
            }

            &--rating {
                order: 4;
            }
        }
    }
}

.spec_control {
    margin-top: 5px;
    position: relative;
    display: flex;

    &:first-child {
        margin-top: 0;
    }
}

.spec_control__label {
    max-height: 32px;
    display: flex;
    align-items: center;
    position: relative;
    color: #333;
    cursor: pointer;

    .spec_control--active & {
        cursor: default;
    }
}

.spec_control__icons {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: 4px 3px 3px 4px;
    border: #e8e8e8 1px solid;
    background: #e8e8e8;

    .spec_control--active & {
        &:after {
            border-color: #ffd24d;
            background: #ffd24d;
        }
    }
}

.spec_control__icon_pencil {
    display: block;

    .spec_control--active & {
        display: none;
    }
}

.spec_control__icon_check {
    display: none;

    .spec_control--active & {
        display: block;
    }
}

.spec_control__prefix {
    width: 25px;
    margin-right: 8px;
}

.spec_control__input {
    padding-left: 10px;
    max-width: 110px;
    height: 32px;
    border-radius: 4px;
    cursor: default;
    border: #c6c7ce 1px solid;
    background: #f1f1f1;

    .spec_control--active & {
        cursor: text;
        border-color: #ffd24d;
        background: #fff;
    }
}

.section_title {
    &.is_company {
        display: none;
    }
}

.profile_form {
    &.status_is_individual {
        .js_is_company {
            display: none;
        }

        .js_is_master {
            display: flex;
        }
    }
    &.status_is_company {
        .js_is_company {
            display: flex;
        }

        .js_is_master {
            display: none;
        }
    }
}

.profile_create_form {
    &.status_is_individual {
        .js_is_company {
            display: none;
        }

        .js_is_master {
            display: inline-block;
        }
    }
    &.status_is_company {
        .js_is_company {
            display: inline-block;
        }

        .js_is_master {
            display: none;
        }
    }
}

.change_status {
    display: flex;
    background-color: $bgColor;
    padding: 39px 87px 34px 40px;
    align-items: flex-end;
    max-width: 696px;
    flex-wrap: wrap;

    @include mobile {
        padding: 39px 20px;
        position: absolute;
        left: -20px;
        right: -20px;
    }
}

.change_status__inquiry {
    color: $textPrimaryColor;
    font-family: $fontOpenSansBold;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 9px;
}

.change_status__controls {
    width: 185px;
    margin-top: 20px;
    .button {
        width: 100%;
        height: auto;
    }
    @include mobile {
        width: 100%;
    }
}

.change_status__msgs {
    margin-right: 64px;
}

.change_status__explanation {
    color: $textSecondaryColor;
    font-family: $fontOpenSans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    max-width: 309px;
}

.passport_data_apply {
    padding: 30px;
    background: #F5F6F7;
    border-radius: 10px;
    margin-bottom: 50px;

    p {
        font-family: $fontOpenSans;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 30px;
    }

    p.failed {
        display: none;
        visibility: hidden;
    }

    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;

        p {
            font-family: $fontOpenSansBold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #262626;
            margin: 0;
        }

        svg {
            margin-right: 5px;

            &.ic.ic--failed {
                margin-right: 10px;
                position: relative;
                top: -1px;
            }
        }
    }

    .management {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        a.hint {
            font-family: $fontOpenSans;
            font-size: 14px;
            line-height: 30px;
            display: inline-block;
            align-items: center;
            color: #A5A8AD;
        }

        a.check_passport_data_button_js_open_modal {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
        }
        a.btn--submit.check_passport_data_button {
            margin-right: 40px;
            margin-bottom: 0;
            text-align: center;
        }

        p.checked_successfull {
            font-family: $fontOpenSansBold;
            font-size: 14px;
            line-height: 20px;
            color: #262626;
            margin-right: 40px;
            margin-bottom: 0;
        }

        .checking_in_progress {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: #E8E9EC;
            border-radius: 21px;
            padding: 6px;
            padding-right: 20px;
            text-align: center;
            width: auto;
            height: auto;
            margin-right: 40px;

            svg {
                margin-right: 10px;
            }

            span {
                font-family: $fontOpenSansBold;
                font-size: 14px;
                line-height: 20px;
                color: #262626;
            }
        }
    }

    @include mobile {
        padding-bottom: 0;

        .ic {
            height: 30px;
            width: 30px;
            margin-right: 10px !important;
        }
        a.btn--submit.check_passport_data_button {
            margin: 0 auto;
            margin-right: 0 !important;
        }
        .hint {
            display: block !important;
            text-align: center;
            width: 100%;
            margin-top: 15px;
            margin-bottom: 30px;
        }

    }

    .pending,
    .success,
    .failed {
        display: none;
        visibility: hidden;
    }

    &.pending {
        .default {
            display: none;
            visibility: hidden;
        }
        .pending {
            display: block;
            visibility: visible;
        }
    }
    &.success {
        .default {
            display: none;
            visibility: hidden;
        }
        .success {
            display: block;
            visibility: visible;
        }
    }
    &.failed {
        .default {
            display: none;
            visibility: hidden;
        }
        .failed {
            display: block;
            visibility: visible;
        }
    }
}

.button.button__small.button--confirm.js_confirm_btn {
    height: 44px;
}

.subscribe_button {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    border: none;
    cursor: pointer;

    .ic {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .ic--subscribe-enabled {
        display: none;
    }

    &.is_subscribed {
        background-color: $colorYellow;

        .ic--subscribe-disabled {
            display: none;
        }

        .ic--subscribe-enabled {
            display: inline-block;
        }
    }
}

.subscribe_button__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    visibility: hidden;

    &:checked {
        ~ .ic--subscribe-disabled {
            display: none;
        }

        ~ .ic--subscribe-enabled {
            display: inline-block;
        }

        ~ .subscribe_button__bg {
            background-color: $colorYellow;
        }

        &:hover {
            ~ .subscribe_hint {
                opacity: 1;
            }
        }
    }
}

.subscribe_button__bg {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: $btnSecondaryHoverColor;
}

.subscribe_hint {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    color: $colorWhite;
    background-color: $textSecondaryHoverColor;
    font-size: 11px;
    width: 146px;
    padding: 3px 5px;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity $animationTime $animationFunc;
    margin-left: 16px;
    text-align: left;
    border-radius: 3px;

    &:before {
        content: "";
        background: url(../images/bg-subscribe-hint@2x.png);
        position: absolute;
        top: 0;
        left: -9px;
        height: 100%;
        bottom: 0;
        width: 10px;
        background-size: auto 100%;
    }
}

.button {
    cursor: pointer;
}

.empty-album {
    background: url(../images/empty_album.png) no-repeat center center;
}

.parent_portfolio {
    min-height: 300px;
}

.portfolio {
    &__itm {
        cursor: pointer;
        width: 100%;
    }
    &__info {
        color: #A5A8AD;
        position: absolute;
        top: 80px;
        left: 7%;
    }
    .portfolio__cont {
        margin-top: 200px;
        width: 100%;
    }
}

.album_nav {
    display: flex;
    .button {
        //min-width: 200px;
        display: inline-flex;
        position: relative;
        span {
            background: #fff;
            font-size: 16px;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            color: #a5a8ad;
            display: inline-block;
            margin-top: 8px;
            margin-left: 25px;
            line-height: 27px;
        }
    }
}

.upload_album {
    margin-left: auto;
    display: flex;
    &:before {
        content: '+';
        background: rgba(0, 0, 0, 0.1);
        font-size: 25px;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        display: block;
        margin-top: 7px;
        margin-right: 7px;
        margin-left: -8px;
        line-height: 27px;
    }
}

.albums__container {
    .album {
        width: 50%;
        @media (max-width: 596px) {
            width: 100%;
        }
        @media (min-width: 900px) and (max-width: 1279px) {
            width: 100%;
        }
    }

    &.albums__container_preview {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (min-width: 321px) and (max-width: 619px) {
            justify-content: center;
        }
        @media (min-width: 899px) and (max-width: 1279px) {
            justify-content: center;
        }
    }
    margin-top: 5%;
    min-height: 400px;
    //display: flex;
    //flex-wrap: wrap;
    //padding: 20px 0 0;
    //justify-content: space-between;
}

.album {
    &:hover {
        .album_edit {
            display: flex;
        }
        .img_wrap {
            filter: grayscale(65%);
        }
    }
}

.album_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    flex-grow: 1;
    text-align: center;
    position: relative;
    min-height: 360px;
    .img_wrap {
        position: absolute;
        overflow: hidden;
        width: 100%;
        padding: 1%;
        &:nth-child(1) {
            top: 0;
            z-index: 10;
        }
        &:nth-child(2) {
            opacity: 0.7;
            top: 20px;
            width: 95%;
            z-index: 8;
        }
        &:nth-child(3) {
            opacity: 0.7;
            top: 35px;
            width: 90%;
            z-index: 7;
        }
    }
    img {
        border-radius: 8px;
        width: 100%;
        height: 300px;
        //max-height: 245px;
        object-fit: cover;
    }
}

.album_title {
    font-family: 'Open Sans';
    font-size: 18px;
    color: #262626;
    margin-left: 3px;
}

.album_subtitle {
    font-size: 12px;
    font-family: 'Open Sans';
    color: #A5A8AD;
    margin-left: 3px;
}

.portfolio__itm__wrapper {
    height: 150%;
    width: 360px;
}

.album_edit {
    position: absolute;
    z-index: 11;
    width: 100%;
    display: none;
    padding: 15px;

    .btn_update {
        height: 25px;
        font-size: 12px;
        line-height: 0;
        padding-left: 25px;
        padding-right: 10px;
        &:before {
            content: '';
            display: inline-block;
            height: 20px;
            width: 20px;
            background: url(../images/pencil.svg) no-repeat;
            position: absolute;
            top: 21px;
            left: 25px;
        }
    }

    .btn_close {
        background: url(../images/close-new.svg) #fff no-repeat center center;
        border-radius: 50px;
        border: none;
        width: 0;
        height: 0;
        position: absolute;
        right: 50px;
        padding: 10px;
    }
}

.swiper-container {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-size: contain;
}

.swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.gallery-top {
    height: 80%;
    width: 100%;
}
.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
    width: 20%;
    height: 100%;
    opacity: 0.4;
    background-size: cover;
}
.gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
}

@media (max-width: 1024px) {
    .album_nav {
        .button {
            font-size: 12px;
        }
    }

}

@media (max-width: 768px) {
    .album_nav {
        .button {
            margin: 2%;
            span {
                margin-left: 10px;
            }
        }
        .upload_album {
            margin: unset;
            z-index: 200;
            position: fixed;
            bottom: 0;
            width: 100%;
            border-radius: 0;
            height: 44px;
            justify-content: center;
            &:before {
                background: unset;
            }
        }
    }
    .empty-album {
        padding-bottom: 50px;
        margin: auto;
    }
}
.photo-items-wrapper- {
    display: flex;
    flex-flow: row wrap;
}

.tmp_photos {
    padding-left: 30px;
}

.photo-items-wrapper- {
    margin: 15px -5px;
}


.portfolio_item, .tmp_photo_item__img_placeholder {
    margin: 5px !important;
    width: auto;
}

.master-pro .master {
    width: 36%;
}

@media(max-width: 991px) {
    .photo-items-wrapper-, .tmp_photos, .photo-items-, .master-pro .master {
        width: 100% !important;
    }
}
