@import 'base/variables';
@import 'common/animations';

.order_create_wrapper {
    padding: 0 20px;
    margin-bottom: 60px;
    max-width: 670px;

    .form_row {
        margin-bottom: 10px;
        flex-flow: row wrap;
        align-items: flex-start;
        max-width: 590px;

        @media (min-width: 900px) {
            width: 100%;
            margin-bottom: 10px;
        }

        @media (min-width: 600px) and (max-width: 899px) {
            width: 100%;
            margin-bottom: 25px;
        }

        @media (max-width: 599px) {
            width: 100%;
            margin-bottom: 24px;
        }

        &--half-width {
            width: calc(50% - 10px);

            &:first-child {
                margin-right: 10px;
            }

            &:nth-child(2) {
                margin-left: 10px;
            }

            @media (max-width: 599px) {
                width: 100%;

                &:first-child {
                    margin-right: 0;
                }

                &:nth-child(2) {
                    margin-left: 0;
                }
            }
        }
    }

    .form_row--with_hint {
        .form_cell--label {
            padding-left: 24px;

            &--budget {
                margin-left: 25px;
            }
        }
    }

    .form_cell--field {
        width: 100%;
        flex-flow: row wrap;
    }

    .form_cell--centered {
        align-items: center;
    }

    .form_cell--visibility {
        justify-content: space-between;
        align-items: stretch;
    }

    .form_row--account {
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;

        .form_cell--label {
            flex-shrink: 0;
            margin-right: 10px;
        }

        .form_cell--field {
            width: auto;
        }

        .form__label {
            margin-bottom: 0;
        }

        @include for-tablet-portrait-up {
            .form_cell--label {
                margin-right: 0;
            }
        }
    }

    .form__label {
        margin-bottom: 16px;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #262626;
        opacity: 0.5;

        span {
            display: block;
            font-size: 12px;
            font-family: $fontOpenSans;
            color: $textSecondaryColor;
            padding-top: 10px;
        }

        .ic {
            fill: $colorYellow;
        }

        @media (max-width: 599px) {
            margin-bottom: 8px;
        }
    }

    .form__text {
        border-color: $inputBorder;
        width: 100%;

        &:focus {
            border-color: $colorYellow;
        }
    }

    .form_cell__inner {
        width: 100%;
    }

    .form_message_long {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    .form_cell {
        &.is_spec {
            @include mobile {
                flex-direction: column;
                .form_cell__inner {
                    flex-basis: unset;
                }
            }
        }
    }

    .form-section {
        margin-top: 0;
        margin-bottom: 30px;

        @include for-tablet-portrait-up {
            margin-bottom: 0;
        }
    }

    .form_message_light {
        //text-align: left;
        //max-width: inherit;
    }

    textarea.form__text {
        resize: none;
        height: 164px;
        line-height: 20px;
        padding: 12px 17px 15px;

        @media (max-width: 599px) {
            height: 158px;
        }
    }

    .form__btns {
        margin: 32px auto 0 auto;
    }

    //.profile_create_spec__text {
    //    font-size: 14px;
    //}

    .form_row--account {
        margin-top: -10px;
        margin-bottom: 36px;

        .form_cell--label {
            padding-top: 0;
        }
    }

    .file_input_link__container {
        display: none;
    }

    .phone_input__wrap {
        width: 100%;
        margin-right: 0;
    }

    @include for-phone-only {

        .form_message_light {
            text-align: center;
            padding-top: 26px;
        }
    }
    @media (max-width: 599px) {

        .form_message_light {
            text-align: center;
            padding-top: 6px;
            margin-bottom: 26px;
        }
    }

    @include for-tablet-portrait-only {
        .phone_input__wrap {
            + .button--confirm {
                margin-top: 0;
                max-width: 132px;
                min-width: calc(50% - 10px);
            }
        }
    }

    @include for-tablet-portrait-up {
        padding-left: 42px;
        padding-right: 42px;

        .form__label {
            margin-bottom: 7px;
        }

        textarea.form__text {
            height: 100px;
        }

        .form_cell--visibility {
            margin-bottom: 20px;
        }

        .form_row--account {
            margin-top: -19px;
            margin-bottom: 25px;
        }

        .form_message_light {
            padding-top: 5px;
            margin-bottom: 32px;
        }
    }

    @include for-tablet-landscape-up {
        padding: 20px 0 0 26px;
        margin-bottom: 80px;

        .form_cell--label {
            width: 160px;
            padding-top: 16px;
        }

        .form_row--with_hint {
            .form_cell--label {
                padding-left: 0;
            }
        }

        .form_cell--field {
            width: 480px;
        }

        .form__label {
            margin-bottom: 7px;
        }

        .form_row--account {
            margin-top: 0;
            margin-bottom: 15px;
            padding-top: 0;

            .form_cell--label {
                margin-right: 0;
            }
        }

        .form_message_light {
            padding-top: 32px;
            margin-bottom: 50px;
            //max-width: 350px;
        }
    }

    @include for-desktop-up {
        padding-left: 80px;
        max-width: 720px;

        .form__text:focus ~ .hint_block {
            .hint_block__content {
                opacity: 1;
                visibility: visible;
            }
        }

        .file_input_link__container {
            display: none;
        }

        .form_cell--visibility {
            margin-bottom: 24px;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
        text-align: left;
    }

    /* Hack for IE */
    .number-spin-btn-container {
        display: none !important;
    }
}

.order {
    &.lt_2col {
        .page_title {
            padding-left: 28px;

            @include mobile {
                margin-left: 20px;
                margin-top: 21px;
                padding: 0 0 28px !important;
            }

            @include for-desktop-up {
                padding-left: 60px;
            }

            .page_title__text {
                @include mobile {
                    text-align: left;
                }
            }
        }
    }

    .master_stats_section {
        .button {
            margin-top: 0;
            margin-bottom: 18px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.form_cell__inner {
    &.is_duration {
        display: flex;
        flex-flow: row nowrap;

        .form_cell__inner_sect {
            width: 100%;
            margin-bottom: 16px;
            position: relative;

            &:last-of-type {
                margin-bottom: 0;
            }

            .form__text {
                width: 100%;
            }
        }

        @include for-tablet-portrait-up {
            .form_cell__inner_sect {
                width: 50%;
                margin-bottom: 0;

                &--budget {
                    width: 100%;
                    margin-left: 25px;
                }
            }
        }

        @include for-tablet-landscape-up {
            .form_cell__inner_sect {
                &:first-of-type {
                    .hint_block {
                        left: 480px;
                    }
                }
            }
        }

        @include for-desktop-up {
            .form_cell__inner_sect {
                &:first-of-type {
                    .hint_block {
                        left: 460px;
                    }
                }
            }
        }
    }

    //@media (max-width: 599px) {
    //    &.is_duration {
    //        flex-wrap: wrap;
    //    }
    //}
}

.form_message_long {
    background: $bgColor;
    border-radius: 4px;
    padding: 15px 0 22px 20px;
    margin-bottom: 44px;
    margin-top: 13px;
    opacity: 1;
    //transition: opacity 0.3s ease-out;
    //
    //&:hover {
    //    opacity: 0.6;
    //}

    @include for-phone-only {
        padding-right: 20px;
    }

    @include for-tablet-portrait-up {
        width: 100%;
    }

    @media (max-width: 900px) {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    @media (max-width: 600px) {
        margin-top: 28px;
        margin-bottom: 7px;
    }

    @media (max-width: 599px) {
        padding: 6px 6px 12px 12px;
        margin-bottom: 0;
    }
}

.form_message_long_low {
	margin-bottom: 0;
}

.form_message_long__text {
    margin: 10px 0 0 30px;
    font-family: $fontOpenSans;
    color: $textSecondaryColor;
    letter-spacing: 0;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;

    @media (max-width: 599px) {
        font-size: 8px;
        line-height: 10px;
    }
    //max-width: 317px;
}

.form_cell__inner_sect {
    &.is_date {

        min-width: 200px;

        &:first-child {
            .form__text {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                position: relative;
            }
        }

        &:last-child {
            .form__text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                position: relative;
                left: -1px;
            }
        }

        &:before {
            content: '';
            width: 18px;
            height: 18px;
            position: absolute;
            z-index: 1;
            background: url(/media/images/ic_date.svg);
            right: 15px;
            top: 13px;
            pointer-events: none;
        }

        @media (max-width: 599px) {
            min-width: 50%;
        }
    }

    @include for-tablet-landscape-up {
        &.is_date {
            position: relative;
        }
    }
}

.form_cell__inner_quantity {
    width: 55px;
    margin-right: 25px;
}

.form_cell__inner_select {
    width: 160px;

    @include mobile {
        width: calc(100% - 80px);
    }
}

.form_cell__inner_budget {
    width: 240px;

    @include mobile {
        width: 100%;
    }
}

.order_wrapper {
    margin-left: 61px;
    padding-top: 33px;
    padding-bottom: 79px;
    @include mobile {
        padding: 35px 0 20px;
        margin: 0;
    }
}

.order__description {
    font-family: $fontOpenSans;
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 25px;

    p {
        padding-bottom: 25px;
    }

    ol {
        li {
            list-style: decimal;
            padding-bottom: 25px;
            margin-left: 17px;
            padding-left: 28px;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    div {
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.order_description__text {
    margin-bottom: 36px;
    word-break: break-word;
}

.order_info {
    margin-top: 10px;
}

.order_info__item {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
}

.account_confirm {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 499px;
    @include tablet {
        min-height: 380px;
    }
    @include mobile {
        min-height: 380px;
    }
}

.account_confirm__inner {
    text-align: center;
    max-width: 410px;
}

.account_confirm__title {
    font-family: $fontOpenSans;
    font-size: 24px;
    color: $textPrimaryColor;
    letter-spacing: -.5px;
    font-weight: bold;
    margin-bottom: 25px;
    @include mobile {
        font-size: 18px;
    }
}

.account_confirm__text {
    font-family: $fontOpenSans;
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 52px;
    @include mobile {
        width: 257px;
        margin: 0 auto 53px;
    }
}

.section_order {
    margin-bottom: 56px;

    .section_title {
        line-height: 1;
        padding: 0;
        margin: 0 0 36px;

        @include mobile {
            margin-bottom: 22px;
        }
    }

    &.order__description {
        padding-top: 0;

        & ~ .advertising_wrap {
            padding-bottom: 40px;
        }
    }

    &:first-child {
        padding-top: 0;
    }

    &.is_offers {
        @include mobile {
            padding: 0;
            margin-bottom: 20px;
        }

        .section_title {
            @include mobile {
                padding-left: 20px;
            }
        }
    }

    &:last-child {
        padding-bottom: 0;
    }

    @include mobile {
        padding: 0 20px;
        margin-bottom: 33px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.client_info {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
}

.client_info__img {
    width: 48px;
    height: 48px;
    background-color: $btnSecondaryColor;
    border-radius: 50%;
}

.client_info__name {
    display: block;
    font-family: $fontOpenSansSemibold;
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    margin-left: 21px;
}

.client_info__phone {
    font-family: $fontOpenSans;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    color: $textPrimaryColor;
    margin-bottom: 12px;

    &.is_stub {
        color: $textSecondaryColor;
    }

    &.is_phone {
        color: $textPrimaryColor;
        font-size: 14px;
    }
}

.goods_slider {
    border-top: 1px solid #edeef0;
    padding: 76px 0 79px 80px;
    position: relative;
    overflow: hidden;

    @include mobile {
        padding: 37px 20px;
    }

    .more-link {
        margin-top: 40px;
    }

    .slick-arrow {
        cursor: pointer;
        width: 30px;
        height: 30px;
    }

    .ic {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.goods_slider__controls {
    position: absolute;
    top: 76px;
    right: 80px;

    .ic--next {
        margin-right: 40px;
    }
}

.goods_slider_wrapper {
    font-size: 0;
    @include mobile {
        margin-bottom: 40px;
    }

    .slick-slide {
        margin: 0 10px;
    }

    .slick-list {
        margin: 0 -10px;
    }
}

.goods_slider_item {
    width: 280px;
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;
    @include mobile {
        margin-right: 0;
    }
}

.goods_slider_item__img {
    text-align: center;

    img {
        max-width: 160px;
        max-height: 182px;
    }

    @include mobile {
        display: flex;
        justify-content: center;
    }
}

.goods_slider_item__category {
    font-family: $fontOpenSansSemibold;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    margin-bottom: 11px;
}

.goods_slider_item__name {
    font-family: $fontOpenSansSemibold;
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 34px;
}

.goods_slider_item__price {
    font-family: $fontOpenSansBold;
    font-size: 20px;
    color: $textPrimaryColor;
    letter-spacing: 0;
}

.goods_slider_item__price_amount {
    font-family: $fontOpenSans;
    font-size: 12px;
    text-align: left;
    color: $textSecondaryColor;
}

.goods_slider__btn {
    text-align: center;
    margin-top: 36px;
    @include mobile {
        margin-top: 76px;
    }
}

.master_offer {
    border: 1px solid $lineColor;
    padding: 36px 34px;
    border-radius: 8px;

    .is_selected {
        display: none;
    }

    .is_init {
        display: flex;
    }

    .button--icon {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &.is_in_progress {
        background: $bgColor;

        .stars__mask {
            background: url(/media/images/stars-h.svg) 0 0 no-repeat;
        }

        .is_selected {
            display: block;
        }

        .is_init {
            display: none;
        }

        @include mobile {
            padding: 25px 20px;
        }
    }

    &.is_deleted {
        padding: 26px 34px;
    }

    @include mobile {
        border-radius: 0;
        border: none;
        padding: 26px 20px 30px;
    }
}

.master_offer_info__status {
    font-family: $fontOpenSans;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    color: $textSecondaryColor;
    max-width: 122px;
    display: block;
    position: relative;
    right: 23px;

    .ic--ok_prime {
        position: absolute;
        top: 7px;
        left: -20px;
    }

    .master_offer_info__in_progress {
        margin: 0;
        position: absolute;
      top: -2px;
        left: -35px;
    }

    @include mobile {
        width: 100%;
        max-width: none;
        right: auto;
      left: 34px;
        margin-top: 10px;
    }
}

.master_offer_info__col {
    display: flex;
    flex-direction: column;
    margin-right: 68px;

    &.is_edit_btn {
        margin-left: auto;

        &.is_msg {
            .js_decline_text {
                display: block;
            }
        }

        @include mobile {
            text-align: center;
            position: relative;
            height: 30px;
            .button {
                margin: 0;
                width: 100%;
            }
        }
    }

    &:last-of-type {
        margin-right: 0;
        @include mobile {
            margin-bottom: 30px;
        }
    }

    @include mobile {
        margin: 0;
        display: table-row;
    }
}

.master_offer_info__title {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    @include mobile {
        display: table-cell;
        padding-bottom: 13px;
        width: 50%;
    }
}

.master_offer_info__description {
    font-family: $fontOpenSans;
    font-size: 18px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 25px;
    margin-bottom: 28px;

    .is_deleted & {
        color: $placeholderColor;
        margin: 0;
    }

    @include mobile {
        font-size: 14px;
        margin-top: 9px;
        margin-bottom: 10px;
    }
}

.master_offer_info__value {
    @include mobile {
        display: table-cell;
        width: 50%;
    }
}

.master_offer_person {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.master_offer_info {
    display: flex;
    flex-wrap: wrap;
    @include mobile {
        display: table;
        width: 100%;
    }

    &.is_master_info {
        .master_offer_info__col {
            margin-right: 8%;

            &:last-of-type {
                margin-right: 0;
            }

            &:first-of-type {
                @include desktop() {
                    width: 100%;
                }
                @include tablet {
                    width: 100%;
                }
            }
        }
    }

    &.is_offer_info {
        @include mobile {
            display: flex;
            flex-direction: column;
        }

        .master_offer_info__col {
            margin-right: 8%;
            @include mobile {
                margin-right: 0;
                display: flex;
                flex-direction: row;
            }

            .master_offer_info__title {
                @include mobile {
                    margin-right: 0;
                    display: flex;
                }
            }

            .master_offer_info__value {
                @include mobile {
                    display: flex;
                    margin-left: auto;
                    width: 42%;
                }
            }

            @include desktop() {
                margin-right: 5%;
            }
            @include tablet {
                margin-right: 3%;
            }

            &:nth-child(3) {
                margin-right: 0;
            }

            &:last-of-type {
                margin-right: auto;
                margin-left: 0;
                margin-top: 20px;

                @include mobile {
                    margin: 0;
                }

                @include big-desktop {
                    margin-left: auto;
                    margin-right: 0;
                    margin-top: 0;
                }
            }
        }
    }
}

.master_offer_info__data {
    margin-bottom: 12px;
}

.master_offer_info__data_value {
    display: flex;
    justify-content: flex-end;

    @include mobile {
        display: table-cell;
        font-weight: 600;
    }
}

.master_offer_info__data_title {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    @include mobile {
        display: table-cell;
        padding-left: 70px;
    }
}

.master_offer_info__photo {
    width: 48px;
    border-radius: 50%;
    margin-right: 21px;
}

.master_offer_info__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.master_offer_info__grade {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
}

.master_offer_info__name {
    font-family: $fontOpenSansSemibold;
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 25px;
  // max-width: 200px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;

  .checked_master {
    display: inline-block;
    position: relative;

    svg {
      height: 11px;
      width: 11px;
    }

    > svg.ic.ic--king {
      position: relative;

      &:hover + .checked_master_tooltip {
        display: block;
      }
    }
  }

  .checked_master_tooltip {
    display: none;
    background: #555659;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-align: center;
    font-family: $fontOpenSans;
    font-size: 11px;
    line-height: 15px;
    color: #FFFFFF;
    padding: 2.3px 5px;
    position: absolute;
    top: 3px;
    right: -145px;
    z-index: 100;

    &:before {
      content: "\A";
      border-style: solid;
      border-width: 10px 15px 10px 0;
      border-color: transparent #555659 transparent transparent;
      position: absolute;
      left: -14px;
      top: 0;
    }
  }
}

.master_offer_info__stars {
    display: flex;
}

.master_offer_info__in_progress {
    width: 24px;
    height: 24px;
    background-color: $btnPrimaryColor;
    border-radius: 50%;
    line-height: 24px;
    text-align: center;
    margin-top: 15px;
    @include mobile {
        margin-left: 70px;
    }
}

.master_tabs {
    .tabs {
        padding: 0;
    }

    @include mobile {
        padding: 0 20px;
    }
}

.master_offers {
    .master_offer__outer {
        margin-bottom: 44px;
        position: relative;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include mobile {
            margin-bottom: 40px;
            border-top: 1px solid $lineColor;
            border-bottom: 1px solid $lineColor;
            padding: 0 20px;
        }
    }

    @include mobile {
        margin: 0 -20px;
        .request_form {
            margin: 0 20px;
        }
    }
}

.favourite-masters__wrap {
    .master_stub__message {
        width: 220px;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 36px;
    }
}

.evaluate_popup {
    background: $colorWhite;
    box-shadow: 0 4px 16px 0 rgba(38, 38, 38, .1);
    border-radius: 10px;
    //border: 1px solid red;
    max-width: 500px;
    margin: 0 auto;
    position: fixed;
    left: 12px;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 202;
    padding: 16px 16px 25px;
    //padding: 69px 83px 69px 80px;
    display: none;
    height: auto;
    max-height: 100vh;
    overflow: auto;


    @include for-tablet-portrait-up {
        padding: 24px 30px 40px;
    }
}

.evaluate_popup .stars__list {
    width: 90px;
    margin-right: 6px;
    align-self: flex-start;

    @include for-tablet-portrait-up {
        width: 126px;
        height: 24px;
        margin-right: 18px;
    }
}

.evaluate_popup .stars__mask {
    background-image: url(/media/images/stars2.svg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    width: 92px;
    height: 18px;
    top: -1px;
    left: -1px;

    @include for-tablet-portrait-up {
        width: 128px;
        height: 26px;
    }
}

.evaluate_popup .stars__fill {
    top: 1px;
    height: 15px;
    @include for-tablet-portrait-up {
        height: 23px;
    }
}

.evaluate_popup .stars__quantity {
    font-size: 14px;

    @include for-tablet-portrait-up {
        font-size: 18px;
    }
}

.evaluate_popup__close {
    margin-top: 5px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    .ic--close {
        width: 17px;
        height: 17px;
        cursor: pointer;
    }

    &:hover {
        opacity: 0.7;
    }
}

.evaluate_popup__title {
    font-family: $fontOpenSansBold;
    font-size: 20px;
    line-height: 25px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    width: 90%;
}

.evaluate_popup__list {
    padding-bottom: 5px;

    @include for-tablet-portrait-up {
        padding-bottom: 20px;
    }
}

.evaluate_popup__element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-bottom: 15px;

    @include for-tablet-portrait-up {
        padding-bottom: 16px;
    }
}

.evaluate_popup__element--top {
    border-bottom: 1px solid $colorGreyThird;
    margin-bottom: 17px;
    padding-bottom: 12px;

    .evaluate_popup__description {
        letter-spacing: normal;
        font-family: $fontOpenSansBold;
    }

    .stars__quantity {
        font-family: $fontOpenSansBold;
    }

    @include for-tablet-portrait-up {
        margin-bottom: 20px;
        padding-bottom: 18px;
    }
}

.evaluate_popup__description {
    letter-spacing: -0.02em;
    font-size: 14px;
    line-height: 19px;
    color: $textPrimaryColor;
    font-family: $fontOpenSans;

    @include for-tablet-portrait-up {
        letter-spacing: normal;
        font-size: 18px;
        line-height: 24px;
    }
}

.evaluate_popup__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;

    @media (min-width: 345px) {
        margin-bottom: 36px;
    }

    @include for-tablet-portrait-up {
        margin-bottom: 41px;
    }
}

.evaluate_popup__stars {
    margin-bottom: 42px;

    .stars__list {
        width: 384px;
        height: 75px;
        margin: 0 auto;
        @include mobile {
            width: 315px;
            height: 63px;
        }
    }

    .stars__mask {
        width: 392px;
        height: 79px;
        top: -2px;
        left: -3px;
        background-size: 100% 100%;
        @include mobile {
            width: 318px;
            height: 67px;
        }
    }

    .stars__fill {
        height: 75px;
        @include mobile {
            height: 63px;
        }
    }
}

.evaluate_popup__textarea {
    border: 1px solid #e3e5e8;
    border-radius: 4px;
    height: 120px;
    width: 100%;
    resize: none;
    padding: 5px 60px 0 15px;
    font-size: 14px;
    line-height: 30px;
    -webkit-appearance: none;

    &:focus {
        outline: none;
        border-color: $colorYellow;
    }

    ::-webkit-input-placeholder { /* Chrome */
        color: $placeholderColor;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $placeholderColor;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $placeholderColor;
        opacity: 1;
    }
    :-moz-placeholder { /* Firefox 4 - 18 */
        color: $placeholderColor;
        opacity: 1;
    }

    @include for-tablet-portrait-up {
        padding-top: 8px;
        height: 140px;
    }
}

.comment_container {
    margin-bottom: 27px;
}

.evaluate_popup__text {
    margin-bottom: 20px;
}

.evaluate_popup__btns {
    display: flex;
    justify-content: space-between;
    @include mobile {
        flex-direction: column;
        .button {
            margin-bottom: 18px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.order_top {
    @include mobile {
        padding: 0;
        .page_title__text {
            max-width: 260px;
            text-align: left;
        }
    }
}

.goods_slider_next {
    position: absolute;
    top: -36px;
    right: 14%
}

.goods_slider_prev {
    position: absolute;
    top: -36px;
    right: 10%
}

.mobile_title {
    padding-right: 44px;
    display: inline-block;

    @include for-desktop-up {
        display: none;
    }
}

.order_form_title {
    padding-bottom: 38px;

    @include mobile {
        padding-bottom: 0;
    }
}

.order_form_msg {
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    font-family: $fontOpenSans;
    line-height: 20px;
    font-weight: normal;
    position: relative;
    margin-left: 20px;

    &:before {
        content: '!';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $btnPrimaryColor;
        position: absolute;
        left: -26px;
        top: 2px;
        line-height: 16px;
        text-align: center;
    }
}

.master_offer_info__btns_wrap {
    &.js_decline_text {
        display: none;
    }

    .button {
        &:nth-child(2) {
            margin-left: 30px;
            width: 100px;
        }
    }

    @include mobile {
        justify-content: space-between;
        position: absolute;
        .button {
            width: auto;
        }

        .button--confirm.is_edit {
            height: 34px;
        }
    }
}

.confirm_decline {
    background: $colorWhite;
    box-shadow: 0 4px 16px 0 rgba(38, 38, 38, .1);
    border-radius: 10px;
    max-width: 640px;
    margin: 0 auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 202;
    padding: 54px 81px 56px;
    @include mobile {
        padding: 30px 20px 41px;
    }
}

.confirm_decline__avatar {
    margin-right: 50px;

    img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }

    @include mobile {
        margin: 0;
        width: 100%;
        margin-bottom: 18px;
    }
}

.confirm_decline__info {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    position: relative;
    @include mobile {
        flex-wrap: wrap;
        margin-bottom: 18px;
    }
}

.confirm_decline__text {
    max-width: 273px;
    color: #262626;
    font-family: $fontOpenSans;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    @include mobile {
        max-width: 190px;
    }
}

.confirm_decline__close_btn {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 20px;

    .ic {
        width: 14px;
        height: 14px;
    }
}

.confirm_decline__btns {
    display: flex;
    justify-content: space-between;

    .button {
        &.decl_btn {
            width: auto;
            height: 34px;

            &.button__primary {
                padding: 7px 29px;
                line-height: 20px;
            }

            &.button__secondary {
                padding: 7px 47px;
                line-height: 20px;
            }
        }
    }

    @include mobile {
        flex-direction: column;
        .button {
            &.decl_btn {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.confirm_decline__comment {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .confirm_decline__comment_inner {
        &.is_error {
            .form__text {
                border: 1px solid $colorError;
            }
        }
    }

    textarea {
        width: 100%;
        height: 110px;
        resize: none;
        margin: 15px 0 0;
    }
}

.confirm_decline_comment__label {
    color: #262626;
    font-family: $fontOpenSans;
    font-size: 14px;
    font-weight: 400;
}

.master_offers__selected {
    margin-bottom: 40px;
}

.button--border {
    &.decline_has_send {
        height: 34px;
        line-height: 30px;
        display: block;
        padding: 0 19px;

        &:hover {
            .master_stats_section__title {
                background-color: $colorWhite;
                border: solid 1px #e3e5e8;
                color: #a5a8ad;
            }
        }
    }
}

.auth_buttons {
    display: flex;
    padding-top: 24px;
    flex-flow: row wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .button {
        display: inline-flex;
        align-items: center;
        margin-bottom: 20px;

        @include tablet-up {
            margin-bottom: 0;
        }
    }

    @include tablet-up {
        justify-content: flex-start;
        flex-direction: row;

        .button + .button {
            margin-left: 48px;
        }
    }
}

.request_form {
    .form_row {
        margin-bottom: 26px;
    }

    @include mobile {
        max-width: 360px;

        .form_row {
            margin-bottom: 20px;
        }

        .form_cell__inner.is_duration {
            flex-direction: row;
        }

        .form__text {
            width: 100%;
        }
    }

    @include tablet {
        .form_row {
            flex-flow: row wrap;
            margin-bottom: 20px;
        }

        .form_cell__inner.is_long {
            max-width: 400px;
            width: 100%;
            min-width: 300px;
        }

        .form_cell.is_label {
            width: 120px;
            align-items: flex-start;
        }
    }
}

.master_offer__date {
    font-size: 12px;
    color: $placeholderColor;
    position: absolute;
    top: 100%;
    right: 20px;
    margin-top: 5px;

    @include tablet-up {
        right: 0;
        margin-top: 8px;
    }
    @include mobile {
        right: 40px;
    }
}

.order_type_switcher {
    position: relative;
    width: 100%;
    cursor: pointer;
    height: 270px;
    overflow: hidden;
    display: flex;
    flex-flow: row;

    .order_type__content {
        display: flex;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        background-color: $colorYellow;
        flex-shrink: 0;
        flex-basis: 100%;
        transition: transform 0.2s ease-in-out;
    }

    @include for-tablet-portrait-up {
        display: none;
    }
}

.order_type_switcher__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;

    + .order_type__content {
        transform: translateX(0);
    }

    &:checked {
        + .order_type__content {
            transform: translateX(-100%);

            + .order_type__content {
                transform: translateX(-100%);
            }
        }

        ~ .order_type_switcher__label {
            &:before {
                animation: bounce 0.5s linear;
                left: calc(100% - 30px);
            }
        }
    }
}

.order_type_switcher__label {
    width: 82px;
    height: 36px;
    display: block;
    border-radius: 36px;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $colorWhite;

    &:before {
        content: '';
        width: 24px;
        height: 24px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        background: $colorYellow;
        animation: bounceOut 0.5s linear;
        left: 6px;
    }
}

.order_type {
    position: relative;
    width: 100%;
    cursor: pointer;

    @media (max-width: 900px) {
        width: calc(50% - 10px);
        //&:first-of-type {
        //    margin-bottom: 10px;
        //}
    }

    @media (max-width: 599px) {
        &:first-of-type {
            margin-bottom: 10px;
        }
    }

    @include for-phone-only {
        width: 100%;
    }

    //@include for-tablet-portrait-up {
    //    max-width: 285px;
    //    min-height: 80px;
    //}

    @include for-tablet-landscape-up {
        width: 285px;
        height: 80px;
    }
}

.order_type__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;

    &:checked + .order_type__content {
        background-color: $colorYellow;
    }

    @include for-phone-only {
        & + .order_type__content {
        }
    }
}

.order_type__content {
    border-radius: 3px;
    display: flex;
    //height: 258px;
    //padding: 26px 40px;
    //align-items: center;
    //text-align: center;
    position: relative;
    flex-wrap: nowrap;
    height: 100%;
    padding: 13px 18px;
    background-color: #eceeef;

    //@include for-tablet-landscape-up {
    //    padding: 13px 18px;
    //}

    @media (max-width: 600px) {
        padding: 13px 7px;
        min-height: 80px;
        min-width: 260px;
    }

    @media (max-width: 599px) {
        padding: 10px 14px;
        min-height: 60px;
        min-width: 260px;
    }
}

.page_content__left .order_type__content {
    flex-direction: column;
    align-items: center;

    @media (max-width: 600px) {
        padding-bottom: 70px;
    }

}

.page_content__left .order_type {
    height: auto;

   /* @media (max-width: 600px) {
        display: none;
    }*/
}

//.page_content__left .order_type_switcher .order_type__content:nth-of-type(2n) {
//    background-color: #eceeef;
//}

.page_content__left .order_create_wrapper .order_signup .button--confirm {
    margin-left: 0;
    //margin-top: 20px;
}
.page_content__left .phone_wrap {
    justify-content: space-between;
}

.page_content__left .order_type_switcher {
    @media (max-width: 600px) {
        height: auto;
    }
}

.order_type__icon {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-bottom: 0;
    background-color: $colorWhite;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 14px;

    svg {
        height: 31px;
        width: 31px;
    }

    @include for-tablet-portrait-up {
        margin-bottom: 0;
    }

    @media (max-width: 600px) {
        margin-right: 7px;
    }

    @media (max-width: 599px) {
        width: 42px;
        height: 42px;
        margin-right: 15px;
    }
}

.order_type__name {
    font-size: 12px;
    font-family: $fontOpenSansSemibold;
    line-height: normal;
    margin-bottom: 5px;
    display: block;

    @media (max-width: 599px) {
        font-size: 10px;
        line-height: 14px;
    }
}

.order_type__text {
    font-size: 10px;
    line-height: 14px;
    display: block;

    @media (max-width: 599px) {
        font-size: 8px;
        line-height: 10px;
    }
}

.hint_block {
    position: absolute;
    left: 0;
    top: -34px;

    @include for-tablet-portrait-up {
        top: -30px;
    }

    @include for-tablet-landscape-up {
        left: 100%;
        margin-left: 22px;
        top: 12px;
    }

    @include for-desktop-up {
        top: 0;
        width: 480px;
        margin-left: 40px;
    }
}

.hint_block__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 202;
    background-color: rgba($textPrimaryColor, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: all $animationTime $animationFunc;

    @include for-desktop-up {
        display: none;
    }
}

.hint_block__content {
    background: $colorWhite;
    border-radius: 14px 14px 0 0;
    padding: 34px 20px;
    opacity: 0;
    visibility: hidden;
    transition: all $animationTime $animationFunc;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 203;

    p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 12px;
    }

    @include for-desktop-up {
        position: relative;
        background: #eceeef;
        border-radius: 4px;
        padding: 16px 35px;

        &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9px 9px 9px 0;
            border-color: transparent #eceeef transparent transparent;
            position: absolute;
            left: -9px;
            top: 15px;
        }
    }
}

.hint_block__icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: $colorDisabled;
    font-size: 12px;
    font-family: $fontOpenSansSemibold;
    text-align: center;
    color: $colorWhite;
    line-height: 15px;
    border-radius: 50%;
    padding: 0;
    border: none;

    &.hint_active ~ .hint_block__overlay,
    &.hint_active ~ .hint_block__content {
        opacity: 1;
        visibility: visible;
    }

    @include for-desktop-up {
        display: none;
    }
}

.hint_block__close {
    position: absolute;
    bottom: 100%;
    width: 26px;
    height: 26px;
    margin-bottom: 16px;
    background-color: $colorWhite;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    right: 20px;

    .ic {
        fill: $textPrimaryColor;
    }

    @include for-tablet-portrait-up {
        right: 38px;
    }

    @include for-desktop-up {
        display: none;
    }
}

.order_signup {
    @include for-tablet-portrait-only {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;

        //.form_row {
        //    width: calc(50% - 19px);
        //}

        .form_row:nth-child(1) {
            order: 1;
        }

        .form_row:nth-child(2) {
            order: 2;
        }

        .form_row:nth-child(3) {
            order: 4;
        }

        .form_row:nth-child(4) {
            order: 3;
        }
    }

    @include for-tablet-landscape-up {
        .form_cell--field {
            width: 240px;
        }

        .button--confirm {
            position: absolute;
            left: 260px;
            top: 50%;
            transform: translateY(-50%);
            max-width: 132px;
            padding: 0;
        }
    }
}

.form_step {
    //border: 1px solid red;
    display: none;
}

.form_step.js-active-step {
    display: block;
}

.not-valid {
    box-shadow: 0px 0px 1px 1px rgba(237,85,43,1) !important;
}

.upload-thumb__img {
    height: 107px;
    width: 107px;
    object-fit: cover;
    border-radius: 3px;

    @media (max-width: 900px) {
        height: 125px;
        width: 125px;
    }

    @media (max-width: 599px) {
        height: 136px;
        width: 136px;
    }
}

.upload-thumb__img-text {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: 48px;
    line-height: normal;
    text-transform: uppercase;
    font-family: "OpenSans-Bold", "Arial", sans-serif;
    color: #ffd24d;
}

.upload-thumb__del {
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    position: absolute;
    right: 3px;
    top: 3px;
    cursor: pointer;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.4);

    svg {
        width: 7px;
        height: 7px;
    }

    @media (max-width: 1280px) {
        height: 17px;
        width: 17px;
        visibility: visible;
        opacity: 1;
    }
}

.upload-thumb__move {
    position: absolute;
    left: 3px;
    top: 3px;
    display: flex;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #000000;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    pointer-events: none;
}

.upload-thumb__stripe {
    display: block;
    background-color: #ffffff;
    width: 7px;
    height: 1px;

    &:not(:first-child) {
        margin-top: 1px;
    }
}

.upload-thumb__cover {
    background-color: #EDEEF0;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    width: auto;
    padding: 0 5px;
    height: 20px;
    border-radius: 30px;
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden;
    cursor: pointer;

    &.is-cover {
        background-color: $btnPrimaryColor;
        padding-left: 16px;

        &:after {
            position: absolute;
            content: '';
            display: block;
            height: 6px;
            width: 4px;
            left: 5px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            border-bottom: 2px solid #000000;
            border-right: 2px solid #000000;
        }
    }
}

.upload-thumb__error {
    background-color: rgba(#262626, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    span {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
    }
}

.loader {
    height: 57px;
    width: 57px;
    animation: loader-2-1 3s linear infinite;
}

@-webkit-keyframes loader-2-1 {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-1 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 57px;
    width: 57px;
    border: 3px solid transparent;
    border-top: 3px solid #FFD24D;
    border-right: 3px solid #FFD24D;
    border-radius: 50%;
    //animation: loader-2-2 1.5s linear infinite;
    z-index: 2;
}

.loader::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 57px;
    width: 57px;
    border: 3px solid #C6C7CE;
    border-radius: 50%;
    z-index: 1;
}

.script-steps {
    width: 100%;
    max-width: 413px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;

    &:after {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: #EDEEF0;
        height: 2px;
        width: 100%;
        z-index: -1;
    }

    li {
        height: 30px;
        width: 30px;
        border: 2px solid #EDEEF0;
        border-radius: 50%;
        background-color: #ffffff;
        position: relative;
    }

    .done {
        background-color: $btnPrimaryColor;
        border: 2px solid $btnPrimaryColor;

        &:after {
            display: block;
            content: "";
            position: absolute;
            top: 9px;
            left: 9px;
            border-bottom: 2px solid #000000;
            border-left: 2px solid #000000;
            transform: rotate(-45deg);
            height: 4px;
            width: 8px;
            z-index: 1;
            background-color: transparent;
        }
    }

    .active {
        box-shadow: 0px 4px 16px rgba(38, 38, 38, 0.15);
        border: 2px solid #ffffff;

        &:after {
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 12px;
            width: 12px;
            z-index: 1;
            border-radius: 50%;
            background-color: $btnPrimaryColor;
        }
    }

    @media (max-width: 599px) {
        max-width: 72%;

        li {
            height: 14px;
            width: 14px;

            &.done {
                &:after {
                    height: 4px;
                    width: 6px;
                    left: 1px;
                    top: 1px;
                    border-width: 1px;
                }
            }

            &.active {
                &:after {
                    height: 6px;
                    width: 6px;
                }
            }
        }
    }
}

.ok-form {
    position: relative;
    filter: blur(8px);

    &::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 5;
        background: rgba(255, 255, 255, 0.7);
    }
}

.order_create_wrapper .form_cell--field {
    width: 100%;
    max-width: 590px;
}

.order_create_wrapper .form_cell--short {
    width: 66%;
}

.form_row_nowrap {
    flex-wrap: nowrap !important;
    max-width: 590px;
    margin-bottom: 0 !important;

    @media (max-width: 600px) {
        flex-wrap: wrap !important;
    }
}

.location_selector + .form_row_nowrap {
    @media (max-width: 600px) {
        flex-wrap: nowrap !important;
    }

    @media (max-width: 599px) {
        flex-wrap: wrap !important;
    }
}

.form_row.form_row--btns {
    justify-content: center;
    margin-bottom: 0;
}

.order_upper-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #262626;
    opacity: 0.5;
    margin-top: 13px;
    margin-bottom: 22px;

    @media (max-width: 599px) {
        font-size: 12px;
        line-height: 16px;
        margin-top: 10px;
        margin-bottom: 25px;
    }
}

.order_create_wrapper .form_row--empty {

    .portfolio_controls__drag_container {
        transition: height 0.3s ease-in-out;
        height: 225px;
    }

    @media (max-width: 900px) {
        
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }

    //@media (max-width: 599px) {
    //    max-width: 409px;
    //    margin-left: auto;
    //    margin-right: auto;
    //}
}

.order_create_wrapper .form_row--none-empty {
    height: 425px;
    margin-top: 22px;

    .form_cell--label {
        height: 0 !important;
        padding-top: 0 !important;
    }

    .portfolio_controls__drag_container {
        flex-direction: row;
        transition: height 0.3s ease-in-out;
        height: 94px;

        .portfolio_controls__btn,
        .dnd_file_input__text {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #262626;
            margin-top: 0;
            margin-left: 9px;
        }
    }

    .dnd_file_input {
        margin-top: 0;
    }

    .ic--upload {
        height: 34px;
        width: 55px;
    }

    @media (max-width: 900px) {
        max-width: 409px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    @media (max-width: 599px) {
        margin-top: 60px;
    }

}

.form_cell--label--budget {
    margin-left: 25px;

    @media (max-width: 599px) {
        margin-left: 0;
    }
}

.form__label--not-label {
    font-weight: normal;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #262626 !important;
    opacity: 1 !important;
    width: 150px !important;

    @media (max-width: 600px) {
        width: 143px !important;
    }

    @media (max-width: 599px) {
        width: 130px !important;
    }
}

.order_created {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 202;
    width: 100%;
    height: 100%;
}

.order_created__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.order_created__icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $btnPrimaryColor;
    margin: 0 auto;
    position: relative;

    &:after {
        display: block;
        content: "";
        position: absolute;
        top: calc(50% - 5px);
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        border-bottom: 3px solid #000000;
        border-left: 3px solid #000000;
        height: 14px;
        width: 20px;
        z-index: 1;
        background-color: transparent;
    }
}

.order_created__text {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #262626;
    margin-top: 26px;
}

.form_subrow {
    @media (max-width:  599px) {
        width: 100%;
    }
}

.order_create_wrapper .form_row--account {
    @media (min-width: 1279px) {
        margin-top: 2px;
        margin-bottom: 32px;
    }

    @media (max-width: 599px) {
        margin-top: -15px;
        margin-bottom: 29px;
    }
}

.order_create_wrapper .form_message_light {
    @media (min-width: 1279px) {
        padding-top: 45px;
        margin-bottom: 40px;
    }
}

.order_create_wrapper .form_cell--long {
    @media (min-width: 900px) {
        width: 100%;
    }
}

.file__descr_text {
    font-size: 12px;
    color: #a5a8ad;
    line-height: 14px;
    width: 100%;
    margin-top: 10px;
    display: block;

    @media (max-width: 900px) {
        font-size: 10px;
    }
}

