.lt_2col {
  .page_content {
    display: flex; //ex-block,  for card-master-achivements
    flex-direction: column; //for card-master-achivements
    position: relative;
    min-height: 800px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      min-height: 0;
      & > div[itemscope] {
        display: flex;
        flex-direction: column;
      }
    }

    @include desktop-up {
      //padding-bottom: 25px;
    }

    &--card-master {
      @media (max-width: 899px){
        display: flex;
        flex-direction: column;
        min-height: 0;
        & > div[itemscope] {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &--chat {
      .page_content__left--listing {
        order: 1;
        float: none; //for chat-chat
      }

      @include for-tablet-landscape-only {
        .page_content__left--chat {
          width: 100%;
          padding: 0;
        }
      }
    }
  }

  .page_title {
    width: 75%;
    float: none;
    padding-top: 28px;
    padding-left: 28px;

    .master_descr {
      @include desktop-up {
        padding: 0 80px 74px 220px;
      }
    }

    @include desktop-up {
      width: 68%;
      padding-left: 80px;
    }

    @include desktop-less {
      width: 66%;
    }

    @include mobile {
      width: auto;
      float: none;
      order: 1;
      padding: 28px 0;

      &:not(.page_title--listing) > .breadcrumbs {
        padding-left: 28px;
      }
    }
  }

  .page_content__left {
    width: 75%;
    float: left;
    padding-top: 0;
    //.master {
    //    padding-top: 36px;
    //}
    @include desktop-up {
      width: 69%;
      .specialization {
        padding: 0 80px 20px
      }
    }
    @include desktop-less {
      width: 64%;
      float: none;
    }

    @include mobile {
      width: auto;
      float: none;
      order: 3;
      //.master {
      //    display: none;
      //}
      .master_descr {
        display: none;
      }
    }

    @media (max-width: 899px) {
      width: 100%;
      order: 3;
    }
  }

  .page_content__right {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 99;

    @media (max-width: 899px) {
      height: auto;
      position: static;
      display: block;
      float: none;
      order: 2;
      padding-top: 0;
      margin-bottom: 20px;
      .master {
        display: none;
      }
      .master_stats.is_fixed {
        position: static;
      }
      .master_stats.is_fixed .master {
        display: none;
      }
    }

    .check_passport_data {
      background: #FFFFFF;
      box-shadow: 0px 14px 64px rgba(38, 38, 38, 0.09);
      border-radius: 10px;
      padding: 20px;
      text-align: center;

      .check_passport_data_context {
        display: block;
        margin: 0 auto;
        padding: 0;
        text-align: left;
        height: auto;
        max-width: 207px;

        .icon_text {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 19px;

          .ic.ic--king {
            height: 30px;
            width: 30px;
            display: block;
            margin-right: 13px;
          }
          span {
            font-family: $fontOpenSansBold;
            font-size: 14px;
            line-height: 19px;
            color: #262626;
          }
        }

        span.text {
          font-family: $fontOpenSans;
          font-size: 12px;
          line-height: 150.5%;
          color: #262626;
        }
        a.btn--submit.check_passport_data_button {
          margin-top: 19px;
          margin-bottom: 0;
          text-align: center;
          height: auto;
          width: auto;
          cursor: pointer;
        }
      }
    }

    .checked_master_tooltip {
      top: 3px;
    }

    @include mobile {
      position: static;
      display: block;
      float: none;
      order: 2;
      padding-top: 0;
      margin-bottom: 20px;
      .master {
        display: none;
      }
      .master_stats.is_fixed {
        position: static;
      }
      .master_stats.is_fixed .master {
        display: none;
      }
    }

    &--empty.page_content__right--listing {
      display: none;

      @include for-desktop-up {
        display: block;
      }
    }
  }

  .page_content__right.page_content__right--chat {
    @include mobile {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include desktop-up {
    .page_content--grid {
      display: grid;
      grid-template-columns: 68% 30%;
      justify-content: space-between;

      &:after {
        display: none;
      }

      .page__title {
        grid-column: 1 / span 1;
        width: 100%;
      }

      .page_content__left {
        grid-column: 1;
        grid-row: 2;
        width: 100%;
      }

      .page_content__right {
        position: static;
        margin-left: auto;
        grid-column: 2;
        grid-row: 1 / span 3;
        width: 100%;
      }
    }
  }
}

.icon_hover {
  display: inline-block;
  position: relative;
  width: auto;

  svg {
    margin-left: 3px;
  }

  .ic.ic--checked_master_tooltip {
    display: none;
    position: absolute;
    top: 13px;
    right: -145px;
    z-index: 100;
    margin: 0;
  }

  > svg.ic.ic--king {
    position: relative;

    &:hover + .ic.ic--checked_master_tooltip {
      display: block;
    }
  }
}

.master {
  padding-bottom: 16px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  overflow: hidden;

  &.mobile {
    display: none;
    visibility: hidden;
  }

  .user_photo {
    width: 52px;
    height: 52px;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    border: 2px solid white;

    img {
      height: 100%;
    }

    &.checked {
      border: 2px solid #FFD24D;
    }
  }

  .fav_link {
    position: absolute;
    top: -33px;
    right: 35px;
    z-index: 200;
  }

  @include tablet-up {
    padding: 0 6px 46px 26px;
    flex-wrap: nowrap;
    overflow: inherit;

    .user_photo {
      width: inherit;
      height: inherit;
      margin-right: 20px;
      margin-left: -10px;
      min-width: 60px;
      flex-shrink: 1;
    }

    .fav_link {
      top: 7px;
      right: auto;
      left: -40px;
    }
  }

  @include desktop-up {
    padding: 0 20px 46px 0;

    .user_photo {
      width: 160px;
      height: 160px;
      margin-right: 50px;
      margin-left: 10px;
      flex-shrink: 0;
    }
  }

  .button {
    height: auto;
  }

  @include big-desktop {
    .fav_link {
      top: 6px;
      right: auto;
      left: -44px;
    }
  }
}

.user_photo {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .user_photo__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
}

.master_info {
  padding-top: 10px;
  width: 100%;
  position: relative;

  @include tablet-up {
    width: auto;
    padding-top: 6px;
  }
}

.master_info__grade {
  @extend %section_title;

  margin-bottom: 6px;
  min-height: 19px;

  @include mobile {
    text-align: center;
  }

  @include tablet-up {
    text-align: left;
    margin-bottom: 12px;
  }
}

.master_info__name {
  font-size: 18px;
  font-family: $fontOpenSansBold;
  line-height: 1.39;
  color: $textPrimaryColor;
  text-align: center;
  padding: 0 10px;
  position: relative;
  margin: 0;

  @include tablet-up {
    text-align: left;
    font-size: 36px;
    line-height: 1.11;
    padding: 0;
  }
}

.page_title {
  .master_info__name {
    svg.ic.ic--king {
      height: 22px;
      width: 22px;
    }
  }
}

.page_content__right {
  .master_info__name {
    svg.ic.ic--king {
      height: 12px;
      width: 12px;
    }

    svg.ic.ic--checked_master_tooltip {
      top: 5px;
    }
  }
}

@media screen and (max-width: 639px) {
  .page_title {
    .master_info__name {
      svg.ic.ic--king {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.checked_master_tooltip {
  display: none;
  background: #555659;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  font-family: $fontOpenSans;
  font-size: 11px;
  line-height: 15px;
  color: #FFFFFF;
  padding: 2.3px 5px;
  position: absolute;
  top: 0;
  right: -150px;
  z-index: 100;

  &:before {
    content: "\A";
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #555659 transparent transparent;
    position: absolute;
    left: -14px;
    top: 0;
  }
}

.master_info__row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  flex-flow: row wrap;

  .petr_tooltip {
    margin-bottom: 0;
    margin-left: 8px;
    font-family: $fontOpenSansSemibold;
  }

  .petr_tooltip__hint {
    margin-left: 0;
  }

  @include desktop-less {
    .petr_tooltip__text {
      width: 100%;
    }
  }

  @include tablet-up {
    justify-content: flex-start;
    margin-bottom: 0;

    &:last-child {
      margin-top: 30px;
    }

    .petr_tooltip {
      margin-left: 0;
    }

    .petr_tooltip__content {
      min-width: 270px;
    }
  }
}

.master_info__start_date,
.master_info__status {
  font-size: 12px;
  color: $textSecondaryColor;
  margin-right: 18px;
}

.master_info__status {
  display: flex;
  align-items: center;

  @include mobile {
    margin-right: 0;
  }
}

.ic_status {
  width: 7px;
  height: 7px;
  background-color: $hintColor;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.ic_status--online {
  background-color: $btnPrimaryColor;
}

.master_info__ur,
.master_info__location {
  font-size: 14px;
  color: $textPrimaryColor;
  margin-right: 18px;
}

.master_info__location {
  .ic--map-tag {
    margin-right: 6px;
    top: -2px;
  }

  .petr_tooltip {
    display: inline-flex;
  }

  @include mobile {
    margin-right: 0;
  }
}

.specialization {
  padding: 28px 10px 54px;

  .section_title {
    padding-left: 10px;
  }

  @include tablet-up {
    padding: 0 16px 74px;
  }

  @include desktop-up {
    padding: 0 80px 74px;
  }

  @include big-desktop {
    .section_title {
      padding-left: 20px;
    }
  }
}

.section_title {
  @extend %section_title;

  margin-bottom: 10px;
}


.stars {
  display: flex;
  align-items: center;
}

.stars__list {
  margin-right: 20px;
  background-color: $btnSecondaryColor;
  width: 90px;
  height: 17px;
  position: relative;
  overflow: hidden;

  //@media (max-width: 900px) {
  //  width: 93px;
  //}
}

.stars__mask {
  background-image: url(/media/images/stars.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 90px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  //transition: background-image $animationTime $animationFunc;

  //@media (max-width: 900px) {
  //  width: 93px;
  //}
}

.stars__fill {
  background-color: $btnPrimaryColor;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.stars__fill--f05 {
  width: 9px;
}

.stars__fill--f1 {
  width: 15px;
}

.stars__fill--f15 {
  width: 27px;
}

.stars__fill--f2 {
  width: 33px;
}

.stars__fill--f25 {
  width: 45px;
}

.stars__fill--f3 {
  width: 51px;
}

.stars__fill--f35 {
  width: 63px;
}

.stars__fill--f4 {
  width: 69px;
}

.stars__fill--f45 {
  width: 81px;
}

.stars__fill--f5 {
  width: 87px;
}

.master_grade {
  display: flex;
  margin-bottom: 25px;
  padding: 0 10px;

  @include desktop-up {
    margin-bottom: 50px;
  }

  @include big-desktop {
    padding: 0 20px;
  }
}

.master_grade_average {
  display: flex;
  align-items: flex-end;

  @include tablet-up {
    display: block;
    margin-right: 0;
    width: 102px;
    flex-shrink: 0;
  }

  @include desktop-up {
    margin-right: 56px;
    width: auto;
  }
}

.master_grade_average__quantity {
  font-size: 56px;
  line-height: 56px;
  font-family: $fontOpenSansBold;
  color: $textPrimaryColor;
  margin-right: 20px;

  @include tablet-up {
    line-height: 70px;
    margin-bottom: 6px;
    margin-right: 0;
  }
}

.master_grade_average__hint {
  font-size: 12px;
  line-height: 1.67;
  text-align: left;
  color: $textSecondaryColor;
}

.master_stars {
  display: none;

  @include tablet-up {
    display: block;
  }
}

.master_stars__row {
  display: flex;
  align-items: center;
  margin-bottom: 1px;

  .stars {
    width: 72px;
    justify-content: flex-end;
  }

  .stars__list {
    margin-right: 0;
    background-color: transparent;
    justify-content: flex-end;
    display: flex;
  }

  .stars__item {
    width: 10px;
    height: 12px;
    background: url(/media/images/star_small.png) no-repeat;
  }
}

.master_stars__text {
  font-size: 12px;
  line-height: 2.08;
  text-align: right;
  color: $textPrimaryColor;
  width: 30px;
  margin-right: 20px;
}

.master_stars__line {
  width: 150px;
  position: relative;
  height: 6px;
  border-radius: 2px;
  background-color: $btnSecondaryHoverColor;
}

.master_stars__line_inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 2px;
  background-color: $btnPrimaryColor;
}

.master_feedbacks {
  padding: 0 10px 20px;

  @include big-desktop {
    padding: 0 20px;
  }
}

.master_feedback {
  border: solid 1px $btnSecondaryHoverColor;
  margin: -1px -20px 0;
  padding: 15px 19px;

  &:last-of-type {
    margin-bottom: 30px;
  }

  @include tablet-up {
    border-radius: 8px;
    margin: 0 -16px 30px;
    padding: 25px 19px 25px 25px;
  }

  @media (min-width: 600px) {
    margin: 0 10px 20px;
    border-radius: 8px;
    padding: 25px 19px 25px 24px;
  }

  @media (min-width: 900px) {
    margin-bottom: 20px;
    padding: 25px 20px 24px 24px;
  }

  @include desktop-up {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include big-desktop {
    margin: 0 -40px 20px;
    padding: 36px 35px 30px 39px;
  }

  .feedback-review-image img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 3px;
    margin: 0 5px 5px 0;
  }
}

.master_feedback__header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  .stars__list {
    margin-right: 8px;
  }

  @media (min-width: 600px) {
    margin-bottom: 25px;
  }

  @media (min-width: 900px) {
    margin-bottom: 25px;
  }


  @include desktop-up {
    margin-bottom: 24px;
  }
}

.master_feedback__specialization {
  font-size: 12px;
  font-family: $fontOpenSansSemibold;
  color: $textSecondaryColor;
  margin-bottom: 0;
  line-height: 16px;
  margin-left: 11px;
}

.master_feedback__text {
  font-size: 14px;
  line-height: 1.79;
  text-align: left;
  color: $textPrimaryColor;
  margin-bottom: 7px;

  @include tablet-up {
    font-size: 16px;
    margin-bottom: 8px;
  }

  @media (min-width: 600px) {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 5px;
  }

  @media (min-width: 900px) {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 5px;
  }

  @include desktop-up {
    font-size: 18px;
    line-height: 1.67;
    margin-bottom: 6px;
  }
}

.master_feedback__author,
.master_feedback__date {
  font-size: 12px;
  text-align: left;
  color: $textSecondaryColor;
}

.master_stats {
  padding: 26px 20px 3px;
  background-color: $bgColor;

  @include for-tablet-portrait-up {
    padding-top: 40px;
  }

  &.is_fixed {
    top: 0;
    position: fixed;
    background-color: $bgColor;
    z-index: 100;
    width: inherit;
    max-width: 360px;

    @include mobile {
      width: 100%;
    }

    .master {
      display: flex;
    }

    .master_info {
      max-width: 240px;
    }

    .master_info__grade_text {
      margin-right: 10px;
    }

    .link_with_icons {
      margin-right: 15px;
    }
  }

  .page_content__left & {
    display: block;

    @include tablet-up {
      display: none;
    }
  }

  + .tabs {
    margin-top: 28px;
  }

  //@include tablet-up {
  //    padding: 0 16px 20px;
  //
  //    + .tabs {
  //        margin-top: 0;
  //    }
  //}

  @include desktop-up {
    padding: 0 40px 40px;
  }

  @include big-desktop {
    padding: 0 40px 40px;
  }
}

.master_stats__inner {
  .master {
    padding: 0 20px 36px 16px;
    margin: 0 -20px 36px -16px;
    border-bottom: 1px solid $lineColor;
    flex-wrap: nowrap;
    display: none;

    @include desktop-up {
      margin: 0 -40px 36px;
      padding: 0 40px 36px;
    }
  }

  .is_fixed & {
    @include desktop-up {
      .user_photo {
        margin-top: 0;
      }

      .master {
        padding-top: 30px;
        padding-bottom: 0;
        margin-bottom: 33px;
        border-bottom-width: 0;
        align-items: center;
      }

      .master_info {
        padding-top: 0;
      }

      .master_info__grade {
        position: absolute;
        bottom: 100%;
        display: flex;
        align-items: center;
      }

      .master + .master_stats_section {
        padding-top: 15px;
        margin-top: 0;
        position: relative;

        &:before {
          content: '';
          display: block;
          background-color: $lineColor;
          height: 1px;
          left: -17px;
          right: -17px;
          top: 0;
          position: absolute;
        }

        .master_stats_section__rating {
          top: 10px;
        }
      }
    }
  }

  .user_photo {
    width: 54px;
    height: 54px;
    box-shadow: 0 1px 1px $btnSecondaryColor;
    flex-shrink: 0;
    margin-right: 12px;
    margin-left: -67px;
    margin-top: 28px;
    min-width: inherit;
    border: 3px solid $colorWhite;
    box-sizing: border-box;
  }

  .master_info__grade {
    margin-bottom: 6px;
  }

  .master_info__name {
    font-size: 18px;
    font-family: $fontOpenSansBold;
    line-height: 1.39;
    margin-bottom: 0;
  }
}

.master_stats_section {
  position: relative;
  margin-bottom: 26px;

  &:not(:first-of-type) {
    margin-top: 26px;
  }

  @include tablet-up {
    margin-bottom: 36px;
  }
}

.master_stats_section__rating {
  font-size: 56px;
  font-family: $fontOpenSansBold;
  line-height: 1;
  text-align: right;
  color: $textPrimaryColor;
  position: absolute;
  top: -6px;
  right: 0;

  @include desktop-up {
    right: 19px;
  }
}

.master_stats_section__title {
  @extend %section_title;
  font-family: $fontOpenSansSemibold;

  margin-bottom: 10px;

  .ic--key {
    top: -2px;
  }
}

.master_stats_section__hint {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: $fontOpenSansSemibold;
  color: $textSecondaryColor;
  margin-bottom: 12px;
}

.master_stats_section__link {
  font-size: 12px;
  font-family: $fontOpenSansSemibold;
  color: $textSecondaryColor;
  display: inline-block;
  margin-bottom: 12px;

  &:hover {
    color: $textSecondaryHoverColor;
  }
}

.master_stats_orders {
  font-size: 12px;
  line-height: 2.08;
  color: #000000;
  margin-bottom: 8px;
}

.master_stats_orders__row {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    height: 1px;
    border-bottom: 1px dotted #d7dade;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -11px;
  }
}

.master_stats_orders__row--novalue {
  &:after {
    display: none;
  }
}

.master_stats_orders__key,
.master_stats_orders__value {
  background-color: $bgColor;
  position: relative;
  z-index: 5;
}

.master_stats_orders__key {
  padding-right: 10px;
  float: left;
}

.master_stats_orders__value {
  text-align: right;
  padding-left: 10px;
  float: right;
}

.portfolio_items {
  display: flex;
  flex-flow: row wrap;
  padding-top: 11px;

  @include tablet-up {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -60px;
  }

  @include big-desktop {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.portfolio_item {
  margin-bottom: 28px;
  display: block;
  width: 100%;

  @include tablet-up {
    margin-bottom: 20px;
    max-width: 360px;
    margin-right: 40px;
  }

  @include desktop-up {
    width: auto;
  }
}

.master_stats.is_fixed {
  width: auto;
}

.portfolio_item__img {
  border-radius: 8px;
  background-color: $btnSecondaryColor;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;

  img {
    border-radius: 8px;
    width: 100%;
    display: block;
  }

  @include tablet-up {
    margin-bottom: 20px;
  }

  @include desktop-up {
    width: 360px;
    height: 220px;
  }
}

.portfolio_item__type {
  font-size: 12px;
  color: #a5a8ad;
  margin-bottom: 5px;
}

.portfolio_item__title {
  font-size: 14px;
  color: #262626;
  line-height: 2.14;
  margin-bottom: 5px;

  @include tablet-up {
    font-size: 18px;
    line-height: 30px;
  }
}

.portfolio_item__photo_count {
  font-size: 12px;
  color: #a5a8ad;

  @include tablet-up {
    margin-bottom: 20px;
  }
}

.master_stub {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 110px 0 100px;
}

.master_stub__message {
  font-size: 14px;
  color: $textSecondaryColor;
  letter-spacing: 0;
  line-height: 30px;
}

.ic--stub_icon {
  margin-bottom: 10px;
}

.stars__item {
  width: 16px;
  height: 16px;
  background: url(/media/images/star.png) no-repeat;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }

  &.is_full {
    background: url(/media/images/star_is_active.png) no-repeat;
  }

  &.is_half {
    background: url(/media/images/star_half.png) no-repeat;
  }
}

.petr_tooltip {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: $fontOpenSansSemibold;
  color: $textSecondaryColor;
  margin-bottom: 12px;

  &--float {
    position: absolute;
    left: 40px;
    top: 40px;
    //font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }

  .petr_tooltip__hint {
    &:hover {
      background-color: $btnPrimaryHoverColor;

      & + .petr_tooltip__content {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
  }
}

.form_order_create {
  width: 100%;
  max-width: 590px;
  //
  //@media (max-width: 600px) {
  //  max-width: ;
  //}
}

.form_order_create .petr_tooltip {
  margin-left: auto;
  margin-bottom: -8px;

  @media (max-width: 899px) {

    &.petr_tooltip--float {
      margin-bottom: 0;
      top: 33px;
      left: 27px;

      .petr_tooltip__hint {
        width: 24px;
        height: 24px;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }

  @media (max-width: 899px) {
    margin-bottom: 3px;
    span {
      display: none;
    }
  }

  @media (max-width: 599px) {
    &.petr_tooltip--float {
      margin-bottom: 0;
      top: 20px;
      left: 4px;

      .petr_tooltip__hint {
        width: 24px;
        height: 24px;
        font-size: 15px;
        line-height: 24px;
      }
    }
    span {
      display: none;
    }

  }
}

.petr_tooltip__hint {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: $btnSecondaryColor;
  font-size: 12px;
  font-family: $fontOpenSansSemibold;
  text-align: center;
  color: $colorWhite;
  line-height: 15px;
  border-radius: 50%;
  margin-left: 7px;
  cursor: pointer;

  &:hover {
    background-color: $btnPrimaryHoverColor;
  }
}

.petr_tooltip__content {
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  display: none;
  right: -10px;
  margin-top: 10px;
  background-color: $colorWhite;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .05);
  padding: 15px 20px;
  z-index: 50;
  font-weight: 400;
  transition: all $animationTime $animationFunc;
  min-width: 400px;

  .master_stats_orders__key,
  .master_stats_orders__value {
    background-color: $colorWhite;
    font-family: $fontOpenSans;
  }

  @media (max-width: 599px) {
    min-width: 300px;
  }
}

.petr_tooltip__header {
  margin: 0 -20px 15px;
  padding: 0 20px;
  border-bottom: 1px solid $btnSecondaryHoverColor;

  .master_stats_orders__row:after {
    display: none;
  }
}

.petr_tooltip__text {
  font-size: 12px;
  line-height: 1.67;
  color: $textPrimaryColor;
  font-family: $fontOpenSans;
}

.is_hidden {
  display: none;
}

.fav_link {
  position: relative;

  &:before,
  &:after {
    opacity: 0;
    visibility: hidden;
    transition: opacity $animationTime $animationFunc;
    height: 20px;
    position: absolute;
    top: 0;
    left: 100%;
    display: block;
    z-index: 20;
  }

  &:before {
    content: '';
    background: url(/media/images/fav-label-tag.png) 0 0 no-repeat;
    width: 8px;
    margin-left: 13px;
  }

  &:after {
    content: attr(data-label);
    line-height: 20px;
    font-size: 12px;
    color: $colorWhite;
    margin-left: 20px;
    border-radius: 0 3px 3px 0;
    background-color: $textSecondaryHoverColor;
    white-space: nowrap;
    padding: 0 5px;
  }

  .ic--fav-tag {
    fill: $btnSecondaryColor;
    pointer-events: none;
  }

  &:hover {
    .ic--fav-tag {
      fill: #cecfd0;
    }
  }

  &.is_active {
    &:after {
      content: attr(data-label-active);
    }

    .ic--fav-tag {
      fill: $btnPrimaryColor;
    }
  }

  @include tablet-up {
    &:hover {
      &:before,
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.master_descr {
  padding: 4px 20px 35px;

  .button__secondary {
    display: none;
  }

  @include tablet-up {
    padding: 0 16px 74px;
  }

  @include desktop-up {
    padding: 0 80px 74px 280px;
  }

  @include mobile {
    .button__secondary {
      display: block;
      margin-top: 20px;
    }
  }
}

.master_descr__content {
  max-height: 150px;
  font-size: 14px;
  line-height: 1.86;
  color: $textPrimaryColor;
  overflow: hidden;
  margin-bottom: 28px;

  @include tablet-up {
    font-size: 18px;
    line-height: 1.67;
    margin-bottom: 10px;
  }

  &.is_active {
    max-height: inherit;
  }
}

.bids_counter {
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: $textSecondaryColor;

  &.bids_counter--open {
    color: $textPrimaryColor;
    background-color: $btnPrimaryColor;
  }

  .is_closed & {
    background-color: transparent;
    color: $textSecondaryColor;
  }

  @include mobile {
    text-align: left;

    .is_closed &,
    .is_closed &.bids_counter--open {
      text-align: left;
    }

    &.bids_counter--open {
      text-align: center;
    }
  }
}

.masters {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: 0 0 30px;
  margin-top: -10px;

  @include tablet-up {
    margin-top: 0;
    padding: 0 10px;
  }

  @include desktop-up {
    padding: 0;
    margin-left: -60px;
    margin-right: -40px;
  }
}

.masters_item {
  width: 100%;
  border-radius: 8px;
  border: solid 1px $btnSecondaryHoverColor;
  position: relative;
  box-sizing: border-box;
  padding: 14px 20px 14px 14px;
  margin: 0 0 10px;

  .fav_link {
    position: absolute;
    top: 13px;
    right: 20px;
    z-index: 110;
  }

  .user_photo {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-right: 12px;
    min-width: inherit;
    box-sizing: border-box;
    margin-left: 0;
  }

  .master {
    padding: 0;
    align-items: flex-start;
    margin-bottom: 4px;
  }

  .master_info {
    padding-top: 0;
    width: 200px;
  }

  .master_info__grade {
    margin-bottom: 6px;
    text-align: left;
  }

  .master_info__name {
    font-size: 16px;
    font-family: $fontOpenSansBold;
    line-height: 1.56;
    text-align: left;
    padding: 0;
  }

  .master_stats_orders__key {
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .master_stats_orders__key,
  .master_stats_orders__value {
    background-color: $colorWhite;
  }

  .master_stats_orders {
    padding: 0 0 0 5px;
    margin-bottom: 0;
    margin-top: 18px;
  }

  @include tablet-up {
    max-width: 400px;
  }

  @include desktop-up {
    padding: 35px 3% 32px;
    margin: 0 0 40px 40px;
    min-width: 360px;
    width: calc(50% - 40px);

    .fav_link {
      top: 30px;
      right: 30px;
    }

    .master {
      margin-bottom: 18px;
    }

    .master_info__name {
      font-size: 18px;
      line-height: 1.39;
    }

    .master_stats_orders {
      padding: 0 8px 0 60px;
    }
  }

  @include big-desktop {
    width: 400px;
    padding: 35px 30px 32px 40px;
  }
}

.masters_item__link {
  position: relative;
  display: block;

  .master {
    margin-top: 0 !important;
  }

  .master_stats_section__rating {
    position: absolute;
    left: 7px;
    top: 40px;
    width: 36px;
    height: 36px;
    background-color: $btnPrimaryColor;
    border: solid 3px $colorWhite;
    border-radius: 50%;
    font-size: 12px;
    font-family: $fontOpenSansSemibold;
    color: $textPrimaryColor;
    text-align: center;
    box-sizing: border-box;
    line-height: 32px;
    z-index: 20;
  }
}

.card_profile_msg {
  background: $bgColor;
  border-radius: 8px;
  width: 100%;
  padding: 17px 27px 21px;
  margin: 36px 0 19px;
  line-height: 21px;
  font-size: 14px;
}

.master_stats_section__text {
  font-family: $fontOpenSans;
  font-size: 12px;
  color: $textSecondaryColor;
  letter-spacing: 0;
  line-height: 20px;
}

.master_stats_section__phone {
  font-family: $fontOpenSans;
  font-size: 14px;
  color: $textPrimaryColor;
  letter-spacing: 0;
}

.master_info__header {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  flex-flow: row wrap;

  .master_info__grade {
    margin-right: 17px;
    margin-bottom: 6px;
    background-color: $bgColor;
    height: 23px;
    border-radius: 2px;
    padding: 0 10px;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: center;

    .master_info__grade {
      margin-right: 0;
    }

    .master_info__grade:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @include tablet {
    .master_info__grade {
      font-size: 10px;
    }
  }
}

.link_with_icons {
  display: inline-flex;
  cursor: pointer;
  height: 100%;
  align-items: center;

  .ic {
    pointer-events: none;
  }

  .ic--liga {
    margin-right: 5px;
    margin-left: -2px;
    width: 20px;
    height: 11px;
  }

  .ic--star {
    fill: $colorYellow;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    margin-left: -4px;
  }

  .petr_tooltip__hint {
    background-color: $colorDisabled;
    pointer-events: none;
    letter-spacing: normal !important;
  }

  &:hover {
    color: $textPrimaryColor;

    .petr_tooltip__hint {
      background-color: $colorYellow;
    }
  }

  .page_content__right & {
    .ic--liga {
      margin-left: 5px;
    }

    .ic--star {
      margin-right: 2px;
      width: 15px;
      height: 15px;
    }
  }

  @include mobile {
    &.link_with_icons--high_liga {
      .link_with_icons__text {
        order: 1;
      }

      .ic {
        order: 2;
        margin-left: 5px;
      }

      .petr_tooltip__hint {
        order: 3;
      }
    }

  }
}

#user_avatar {
  display: none;
}

.field-user_avatar {
  .btn-file {
    display: none !important;
  }
}

.master--national-expert {
  .user_photo {
    overflow: visible;
  }

  .ic--wreath {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50px);
  }

  .page_content__right & {
    .ic--wreath {
      width: 70px;
      height: 30px;
      transform: translate(-50%, -15px);
    }
  }

  @include mobile {
    .user_photo {
      margin-bottom: 20px;
    }

    .ic--wreath {
      width: 115px;
      height: 70px;
      transform: translate(-50%, -37px);
    }
  }
}

/** svg fav @ovverride */

svg.ic.ic--fav-tag {
  height: 23px;
  width: 23px;
}

/** mobile @ovveride */

@media screen and (max-width: 420px) {
  .breadcrumbs {
    //display: none;
    //visibility: hidden;
  }
  .master {
    &.desktop {
      display: none;
      visibility: hidden;
    }

    &.mobile {
      display: block;
      visibility: visible;
      padding: 0 20px;

      .fav_link {
        top: 20px;

        svg.ic.ic--fav-tag {
          height: 18px;
          width: 18px;
        }
      }

      .master_info_image_and_status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
      }

      .master_info__header {
        align-items: flex-start;
        margin: 0;
        margin-left: 15px;
      }

      .master_info__name {
        text-align: left;
        padding: 0;
        margin-bottom: 10px;

        .icon_hover {
          svg.ic.ic--king {
            height: 14px;
            width: 14px;
            margin: 0;
          }
        }
      }

      .master_info__row {
        justify-content: flex-start;
      }
    }
  }
  .master_descr {
    display: none;
    visibility: hidden;
  }
}

.form_cell--long .petr_tooltip,
.form_cell--label--budget .petr_tooltip {
  margin-bottom: 6px;
}

.master_feedback__answer {
  border: none;
  background-color: transparent;
  font-family: $fontOpenSans;
  font-size: 12px;
  line-height: 16px;
  padding-right: 0;
  margin-left: auto;
  color: $textSecondaryColor;
  cursor: pointer;
  transition: color 0.3s ease;

  svg {
    height: 15px;
    width: 15px;
    fill: $textSecondaryColor;
    margin-left: 6px;
  }

  &:hover {
    color: $textPrimaryColor;

    svg {
      fill: $textPrimaryColor;
    }
  }

  span {
    display: none;
  }

  @media (min-width: 600px) {
    padding-right: 5px;
    span {
      display: inline;
    }
  }
}

.page_content__right--chat {
  .master_stats__inner {
    .petr_tooltip {
      margin-bottom: 23px;
    }

    .button:not(:first-of-type) {
      margin-top: 14px;
    }

    .ic--lock-red {
      margin-right: 4px;
    }

    @include for-tablet-portrait-only {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      .master_stats_section:first-child {
        width: 39.7%;
        margin-bottom: 0;


        .petr_tooltip {
          margin-bottom: 27px;
        }
      }

      .master_stats_section:last-child {
        width: 48.3%;
        margin-top: 0;
        margin-bottom: 0;

        .petr_tooltip {
          margin-bottom: 16px;
          justify-content: flex-end;
        }
      }

      .master_stats_section__title {
        letter-spacing: 2.8px;
      }

      .master_stats_orders {
        padding-top: 5px;
        margin-bottom: 17px;
      }

      .master_stats_orders__row {
        margin-bottom: 4px;
      }
    }
  }

  @include for-tablet-landscape-up {
    .master_stats {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0;
    }

    .master_stats__inner {
      .master_stats_section__rating {
        margin-right: 18px;
      }

      .petr_tooltip {
        margin-bottom: 32px;
      }

      .master_stats_section {
        margin-bottom: 28px;
      }
    }
  }

  @include for-desktop-up {
    .master_stats {
      padding-left: 40px;
      padding-right: 40px;
    }

    .master_stats__inner {
      .master_stats_section__rating {
        margin-right: 0;
      }
    }
  }
}

.page_content--chat {
  align-items: flex-start;
  height: 100%;

  .breadcrumbs {
    display: flex;
    visibility: unset;
    margin-bottom: 6px;
  }

  .page_title:not(.page_title--listing) > .breadcrumbs {
    padding-left: 20px;
  }

  .page_title {
    padding-top: 18px;
    padding-bottom: 20px;
  }

  @include for-phone-only {
    .page_title__text {
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.5px;
      margin-left: 20px;
    }
  }

  @include for-tablet-portrait-up {
    .page_title {
      padding-top: 8px;
      padding-bottom: 31px;
    }

    .page_title:not(.page_title--listing) > .breadcrumbs {
      padding-left: 28px;
    }

    .page_title__text {
      margin-left: 28px;
    }
  }

  @include for-tablet-landscape-up {
    overflow-x: hidden;

    .page_title:not(.page_title--listing) > .breadcrumbs {
      padding-left: 0;
    }

    .page_title__text {
      margin-left: 0;
    }
  }

  @include for-desktop-up {
    .page_title {
      margin-top: 25px;
      padding-bottom: 25px;
    }
  }
}

.page_content__left--chat {
  .listing__paginator--v2 {
    padding-top: 24px;
    padding-bottom: 25px;

    @include for-desktop-up {
      padding-top: 20px;
    }
  }
}

.page_content__left--chat .listing--v2 {
  @include for-tablet-landscape-up {
    padding-right: 25px;
    padding-bottom: 15px;
  }

  @include for-desktop-up {
    padding-bottom: 5px;
  }
}

.lt_2col .page_content--listing.page_content--chat {
  @include for-tablet-landscape-up {
    min-height: unset;
    min-height: 800px;
  }
}

.block-relative{
  position: relative;
}

.btn_pro{
  width: 100%;
  height: 42px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #FFD24D;
}

.info-item{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    .info-absolute{
      display: block;
    }
  }
}



.info-absolute {
  margin-top:10px;
  width:420px;
  height:106px;
  position:absolute;
  top: 95%;
  right: -23px;
  background: #FFFFFF;
  border: 1px solid #F1F3F5;
  box-sizing: border-box;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  z-index: 2;
  display: none;
}

.info-absolute:before,.info-absolute:after{
  content:"";
  position:absolute;
  top:-14px;
  right:15px;
  border-bottom:15px solid #FFFFFF;
  border-left:15px solid transparent;
  border-right:15px solid transparent;
}
.info-absolute:before{
  top:-14px;
  border-bottom-color:#F1F3F5;
}

.btn_pro_yes{
  background: #FFD24D;
  &:hover{
    border: 1px solid #ffd966;
    background: #ffd966;
    color: #263238;
  }
}

@media(max-width: 767px){
  .info-item{
    position: static;
  }
  .btn_pro{
    position: relative;
  }
  .info-absolute{
    width: 100%;
    height: auto;
    right: 0;
  }
}

.btn_pro_no{
  background: transparent;
  &:hover{
    border: 1px solid #ffd966;
    color: #ffd966;
    color: #263238;
    .info--absolute{
      color: #263238;
    }
  }
}

//test