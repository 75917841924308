$color-black: #262626;
$color-yellow: #FFD24D;
$color-gray: #5F5F61;
$color-hr: #EDEEF0;

@mixin adaptive {
  // XL adaptive
  @media (min-width: 1300px) {
    $full-height: 720px;
    $full-width: 1280px;
    flex-direction: row;
    min-height: $full-height;
    width: $full-width;
    overflow: hidden;

    &--gallery {
      width: 853px;
      min-height: $full-height;
      .top {
        width: 773px;
        margin: 38px;
        .close-link { display: none }
      }

      .gallery-top {
        height: 421px;
        width: 773px;
      }

      .gallery-thumbs {
        width: 773px;
        height: 95px;
      }

      .scrollbar-container {
        margin: 43px 40px;
      }

      .swiper-scrollbar {
        width: 694px;
      }
    }

    &--description {
      max-width: 427px;
      max-height: 720px;
      overflow-y: auto;
      width: 100%;

      .top {
        flex-direction: column;
        margin: 23px 40px 30px 40px;
        .usermedia {
          padding-bottom: 23px;
          margin-bottom: 30px;
          border-bottom: 1px solid $color-hr;
        }
      }

      .description-content {
        margin: 0 40px;
      }
    }
  }
  // LG adaptive
  @media (max-width: 1301px) and (min-width: 930px) {
    $full-height: 693px;
    $full-width: 900px;
    $padding: 33px;
    flex-direction: column;
    width: 900px;
    .modal-wrapper__close { display: none }

    &--gallery {
      width: $full-width;
      height: $full-height;

      .top {
        margin: 33px;
        width: 840px;
        display: inline-flex;
        justify-content: space-between;

        .divider { padding-left: 381px }
      }

      .gallery-top {
        height: 431px;
        width: 834px;
      }

      .gallery-thumbs {
        width: 834px;
        height: 95px;
      }

      .scrollbar-container {
        padding: 30px 33px;
      }

      .swiper-scrollbar {
        width: 754px;
      }
    }

    &--description {
      width: $full-width;
      padding: 29px 33px 41px 33px;
      .top {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-bottom: 19px;
        margin-bottom: 23px;
        border-bottom: 1px solid $color-hr;
      }

      .description-content {
      }
    }
  }
  // MD adaptive
  @media (max-width: 931px) and (min-width: 600px) {
    $full-width: 600px;
    $padding: 22px;
    .modal-wrapper__close { display: none }

    &--gallery {
      width: $full-width;
      padding: $padding;

      .gallery-top {
        width: 556px;
        height: 281px;
      }

      .gallery-thumbs {
        height: 64px;
        margin-top: 6px;
      }

      .top {
        margin-bottom: $padding;
        width: 560px;
      }

      .divider { padding-left: 160px }

      .scrollbar-container {
        margin: 20px 20px 0 20px;
      }

      .swiper-scrollbar {
        width: 507px;
      }
    }

    &--description {
      width: $full-width;
      height: auto;
      padding: $padding;

      .top {
        $whitespace: 20px;
        flex-direction: column-reverse;
        margin-bottom: $whitespace;

        .object-description {
          margin-bottom: $whitespace;
          padding-bottom: $whitespace;
          border-bottom: 1px solid $color-hr;
        }
      }
    }

    flex-direction: column;
    width: $full-width;
  }
  // SM adaptive
  @media (max-width: 600px) and (min-width: 320px) {
    $width: 280px;
    $padding: 22px;

    .gallery-top-wrapper {
      width: 260px;
      margin: 0 30px;
    }

    .modal-wrapper__close { display: none }

    &--gallery {
      width: $width;
      height: 289px;

      .top {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin: 18px 15px 18px 22px;
        width: auto;
        .edit-link {
          font-size: 10px;
          line-height: 24px;
          width: 157px;
        }
        .close-link { margin: 0 }
        &* { margin: 0 }
        .back-link {
          width: auto;
          span { display: none }
        }
      }

      .gallery-top {
        height: 194px;
        width: 262px;
      }

      .gallery-thumbs {
        display: none;
      }

      .delete-link {
        display: none;
      }

      .scrollbar-container {
        display: none;
      }
    }

    &--description {
      width: $width;
      height: auto;
      padding: $padding;
      .top {
        margin-bottom: 30px;
        .object-description {
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid $color-hr;
          .title { font-size: 16px }
          .plain-text { font-size: 12px }
        }
        .usermedia {
          .name {
            font-size: 14px;
            line-height: 19px;
          }
        }
        flex-direction: column-reverse;
      }
    }

    flex-direction: column;

    width: $width;
  }
  
  @media (max-width: 600px) {
    &--gallery {
      .swiper-slide {
        .upload-thumb__del {
          display: flex;
          top: 15px;
          right: 10px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.swiper_album {
  .btn_update {
    height: 25px;
    font-size: 12px;
    line-height: 0;
    padding-left: 25px;
    padding-right: 10px;
  }

  overflow: hidden;
  display: flex;
  font-family: "OpenSans", "Arial", sans-serif;

  .plain-text { font-weight: normal }

  &--gallery {
    background-color: $color-black;

    .top {
      color: $color-black;
      display: inline-flex;
      //flex-direction: row;
      justify-content: space-between;


      .div svg {
        margin: 0 5px;
      }

      .divider {
        display: inline-flex;
      }
    }

    .swiper-slide {
      .upload-thumb__del {
        display: none;
      }
    }

    .gallery-thumbs { margin-top: 5px }

    .scrollbar-container {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .swiper-arrows {
      svg {
        width: 12px;
        height: 10px;
      }
    }

    .swiper-scrollbar{
      display: block !important;
      height: 6px;
      width: auto;
      background-color: grey;
      margin: auto 30px;
    }

    .swiper-scrollbar-drag {
      background-color: white;
    }
  }

  &--description {
    .top {
      display: flex;
    }
    overflow: paged-y;
    .usermedia {
      display: inline-flex;
      .name {
        vertical-align: middle;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        width: 135px;
      }
    }

    .object-description {
      .title {
        margin: 0 0 6px 0;
        font-size: 24px;
        font-style: normal;
        font-weight: bold;
      }
      .plain-text { font-size: 16px }
    }
  }

  .description-content {
    font-size: 16px;

    p { margin-bottom: 30px }
    .shadow-title { opacity: .5 }
  }

  .nav_user__photo-54 {
    @extend .nav_user__photo;
    width: 54px;
    height: 54px;
    margin: 0 15px 0 0;
  }

  .slim-btn {
    border-radius: 30px;
    align-content: center;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 10px;
    line-height: 23px;
    height: 24px;

    a {
      magrin: 12px 12px;
      font-size: 10px;
      line-height: 14px;
    }

    svg {
      margin: 0 5px;
    }

    &--primary {
      background-color: $color-yellow;
      color: $color-black;
    }
    &--secondary {
      background-color: $color-gray;
      color: white;
    }
  }

  .back-link {
    color: white;
    opacity: .5;
    font-weight: 400;
    width: 62px;
    line-height: 24px;
    cursor: pointer;
    transition-duration: 0.5s;
    &:hover {
      transition-duration: 0.2s;
      opacity: 0.8;
    }
  }

  .close-link {
    width: 24px;
    height: 24px;
    margin-left: 0;
  }

  .edit-link {
    width: auto;
    padding: 0 10px;
    line-height: 24px;
  }

  .delete-link {
    line-height: 24px;
    background-color: #5F5F61;
    color: white;
    font-size: 12px;
    padding: 0 10px;
  }

  @include adaptive;
}
