
.all_cities {
  .page_cities {
    padding: 0 80px;
    @include for-phone-only {
      padding: 0 10px;
    }

    ul li {
      display: inline-block;
      width: 100%;
      margin-bottom: .5em;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    .cities-column {
      column-count: 4;
      margin-bottom: 20px;
      @include for-phone-only {
        column-count: 1;
      }
      @include for-tablet-portrait-only {
        column-count: 2;
      }

      @include for-tablet-landscape-only {
        column-count: 3;
      }
    }

    &__title h1 {
      font-size: 24px;
      margin-bottom: 30px;
      font-weight: bold;
    }

    .favorite-cities,
    .all-cities {
      &__title {
        display: block;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 18px;
      }
    }

    .search-city {
      margin-bottom: 20px;

      a {
        color: #00678c;
        cursor: pointer;

        &:hover {
          color: #fd2d2c;
          border-color: #fd2d2c;
          outline: 0;
        }
      }
    }
  }

  .page_content {
    flex-direction: column;
    justify-content: flex-start;
  }

  .page_title {
    margin: 20px 20px 30px;

    @include for-tablet-portrait-up {
      margin: 36px 42px 26px;
    }

    @include for-tablet-landscape-up {
      margin-left: 26px;
      margin-right: 26px;
    }

    @include for-desktop-up {
      margin-left: 80px;
      margin-top: 60px;
    }
  }
}