$fontOpenSans: 'Open-Sans', 'Arial', sans-serif;
$fontArial: 'Arial', sans-serif;
$fontOpenSansExtraBoldItalic: 'OpenSans-ExtraboldItalic', 'Arial', sans-serif;
$fontOpenSansBold: 'OpenSans-Bold', 'Arial', sans-serif;
$fontOpenSansSemibold: 'OpenSans-Semibold', 'Arial', sans-serif;
$fontOpenSansExtrabold: 'OpenSans-Extrabold', 'Arial', sans-serif;

$animationTime: .2s;
$animationFunc: ease-in-out;

$footerHeight: 354px;

$colorRed: rgba(253, 45, 44, .61);
$colorYellow: #ffd24d;
$colorGrey: #d3d3d3;
$colorBlack: #000;

@mixin input-placeholder {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

// text colors
$textPrimaryColor: #262626;
$textSecondaryColor: #a5a8ad;
$textSecondaryHoverColor: #555659;

// buttons colors
$btnPrimaryColor: #ffd24d;
$btnPrimaryHoverColor: #ffd966;
$btnSecondaryColor: #e3e5e8;
$btnSecondaryHoverColor: #edeef0;
$btnRedColor: #fd051f;

// other colors
$lineColor: #edeef0;
$bgColor: #f5f6f7;
$hintColor: #cfd1d4;
$colorGreyFirst: #f5f6f7;
$colorGreySecond: #edeef0;
$colorGreyThird: #e8e8e8;
$colorGreyFourth: #e3e3e3;
$colorWhite: #ffffff;

$inputBorder: #c6c7cE;

//error color

$colorError: #fe253c;

// disabled text

$colorDisabled: #d2d2d4;

$placeholderColor: #a5a8ad;

@mixin mobile {
    @media (max-width: 639px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 640px) and (max-width: 999px) {
        @content;
    }
}

@mixin tablet-header {
    @media (min-width: 640px) and (max-width: 1140px) {
        @content;
    }
}

@mixin tablet-up {
    @media (min-width: 640px) {
        @content;
    }
}

@mixin tablet-up-680 {
    @media (min-width: 680px) {
        @content;
    }
}

@mixin tablet-up-720 {
    @media (min-width: 720px) {
        @content;
    }
}

@mixin tablet-up-800 {
    @media (min-width: 800px) {
        @content;
    }
}

@mixin wide-tablet-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1000px) and (max-width: 1279px) {
        @content;
    }
}

@mixin desktop-less {
    @media (max-width: 999px) {
        @content;
    }
}

@mixin desktop-less-header {
    @media (max-width: 1280px) {
        @content;
    }
}

@mixin small-desktop {
    @media (max-width: 1140px) {
        @content;
    }
}

@mixin desktop-up {
    @media (min-width: 1000px) {
        @content;
    }
}

@mixin desktop-up-header {
    @media (min-width: 1301px) {
        @content;
    }
}

@mixin desktop-up-header-max {
    @media (max-width: 1300px) {
        @content;
    }
}

@mixin big-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin extra-big-desktop {
    @media (min-width: 1441px) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: 1280px) and (max-width: 1440px) {
        @content;
    }
}


%section_title {
    font-size: 11px;
    font-family: $fontOpenSansBold;
    letter-spacing: 1.8px;
    color: $textPrimaryColor;
    text-transform: uppercase;
    position: relative;

    @include tablet-up {
        font-size: 12px;
        letter-spacing: 2px;
    }
}

// new breakpoints

$phone: 600px;
$tablet: 900px;
$desktop: 1280px;
$desktopBig: 1400px;

// media queries

@mixin for-phone-only {
    @media (max-width: #{$phone - 1}) {
        @content;
    }
}

@mixin for-phone-landscape-only {
    @media (min-width: 400px) and (max-width: #{$phone - 1}) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: #{$phone}) {
        @content;
    }
}

@mixin for-tablet-portrait-only {
    @media (min-width: #{$phone}) and (max-width: #{$tablet - 1}) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

@mixin for-tablet-landscape-only {
    @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1}) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

@mixin for-desktop-only {
    @media (min-width: #{$desktop}) and (max-width: #{$desktopBig - 1}) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: #{$desktopBig}) {
        @content;
    }
}