.auth__reg_hint {
    font-size: 14px;
    color: $textPrimaryColor;
}

.auth {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    padding-top: 55px;
}

.form-row__master__info{
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;

}

.form-row__master__info svg{
    margin-right: 8px;
}

.modal-wrapper .auth {
    padding-top: 38px;
    max-width: 510px;
}

.auth__title {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    text-align: center;
    color: $textPrimaryColor;
    margin-bottom: 45px;

    @include tablet-up {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 25px;
        font-weight: 700;
    }

    @include mobile {
        margin-bottom: 25px;
    }
}

.modal-wrapper .auth__title {
    @include tablet-up {
        letter-spacing: 1px;
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 18px;
        font-weight: 900;
    }
}

.auth__text {
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    max-width: 300px;
    margin-bottom: 32px;
}

.auth__form {
    width: 100%;
    max-width: 380px;
    padding: 0 20px;
    @include mobile {
        max-width: 280px;
    }

    .form_row {
        margin-bottom: 32px;
        flex-direction: column;
        &.has-error {
            .form_error {
                display: block;
            }
            .form__text {
                border: 1px solid $colorError;
            }
        }

        &.has-success {
            .form_error {
                display: none;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
        .form_error {
            display: none;
            position: absolute;
            bottom: -34px;
            width: 100%;
            text-align: center;
        }
    }

    .form_error {
        margin: 0;
        justify-content: center;
        align-items: center;
    }
}

.modal-wrapper .auth__form {
    max-width: none;
    padding: 0;

    .form_row {
        margin-bottom: 22px;
    }
}

.auth__footer {
    padding-top: 16px;
    text-align: center;

    .button__login {
        min-width: 160px;
        max-width: 310px;
        margin-bottom: 14px;
        border-radius: 29px;
    }

    @include tablet-up {
        padding-top: 26px;
    }
}

.modal-wrapper .auth__footer .button__login {
    width: 100%;
    margin-bottom: 28px;
    height: 55px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}


.auth__restore_pass {
    display: inline-block;
    color:  gray;
    border-bottom: 2px solid  #eceff1;
    margin-top: 8px;
    &.is_cancel {
        font-size: 14px;
        font-family: $fontOpenSansSemibold;
    }

}

.modal-wrapper .auth__restore_pass {
    line-height: 25px;
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 600;
    color: #A3A3A3;
    //position: relative;
    //&:after {
    //    position: absolute;
    //    content: '';
    //    display: block;
    //    height: 1px;
    //    width: 100%;
    //    left: 0;
    //    bottom: 0;
    //    background-color: #A3A3A3;
    //}

    &:hover {
        border-bottom: 2px solid  #eceff1;
        color: #fd2d2c;
    }
}

.auth_msg {
    font-family: $fontOpenSans;
    font-size: 12px;
    text-align: left;
    color: $textSecondaryColor;
    margin-bottom: 23px;
    @include mobile {
        text-align: center;
    }
}

.form_row--text_input {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    &:last-child {
        margin-bottom: 0;
    }
}

.signup_form__msg {
    font-family: $fontOpenSans;
    line-height: 22px;
    font-size: 12px;
    color: $textSecondaryColor;
    max-width: 397px;
    text-align: left;
    margin-top: 32px;
    a {
        display: inline;
        font-family: $fontOpenSansBold;
    }
}

.registration {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding-top: 55px;
    @include mobile {
        padding: 55px 20px 0;
    }
    .auth__footer {
        margin: 0;
        padding: 0;
    }
    .auth__title {
        font-size: 28px;
        font-family: $fontOpenSansBold;
    }

    .role_switcher__ic {
        padding: 0;
        @include mobile {
            margin: 0;
            padding: 0;
        }
    }

    .role_switcher__radio_label {
        margin-right: 74px;
        @include mobile {
            flex-direction: row;
            align-items: center;
        }
    }

    .role_switcher__role {
        padding-left: 14px;
    }

    .form__text {
        @include mobile {
            width: 100%;
        }
    }
    .form_row {
        position: relative;

        .form_error {
            text-align: center;
            padding-top: 6px;
            position: absolute;
            bottom: -36px;
            width: 100%;
            display: none;
        }

        &.form_row--user_role {
            margin-bottom: 32px;
            .role_switcher {
                width: 100%;
                justify-content: center;
            }
        }
        &.has_arrow {
            &:after {
                content: '';
                width: 7px;
                height: 11px;
                background: url(/media/images/arrow.svg) no-repeat;
                position: absolute;
                right: 15px;
                top: 17px;
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }
        &.has-error {
            input {
                border: 1px solid $colorError;
            }
            .form_error {
                position: absolute;
                bottom: -36px;
                width: 100%;
                padding-top: 6px;
                text-align: center;
                display: block;
            }
        }
        &.has-success {
            .form_error {
                display: none;
            }
        }
    }
    .role_switcher__radio_label {
        &:last-child {
            margin-right: 0;
        }
    }

    .auth__restore_pass {
        font-size: 14px;
    }
}

.button__reg {
    padding: 0 31px;
}

.signup_form {
    max-width: 400px;
}

.auth__register {
    display: inline-block;
    line-height: 40px;
    font-size: 12px;
    color: #a5a8ad;
    font-weight: 600;
    margin-top: auto;
}

.registration {
    .js_dropdown_cities {
        &.has-error{
            .form_error {
                position: absolute;
                bottom: -28px;
                width: 100%;
                padding-top: 6px;
                text-align: center;
                display: block;
            }
        }
    }
}

.registration_info {
    position: absolute;
    max-width: 300px;
    background: $bgColor;
    border-radius: 4px;
    right: -324px;
    top: 0;
    padding: 29px 35px 28px 45px;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid $bgColor;
        position: absolute;
        left: -10px;
        top: 15px;
    }
    @include mobile {
        position: relative;
        margin: 30px auto 0;
        right: 0;
        left: 0;
        max-width: none;
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid $bgColor;
            position: absolute;
            top: -15px;
            left: 10%;
            transform: rotate(90deg);
        }
    }
    @include tablet {
        position: relative;
        margin: 30px auto 0;
        right: 0;
        left: 0;
        max-width: none;
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid $bgColor;
            position: absolute;
            top: -15px;
            left: 10%;
            transform: rotate(90deg);
        }
    }
}

.registration_info__title {
    font-weight: bold;
    line-height: 20px;
    font-size: 12px;
    color: $textPrimaryColor;
    padding-bottom: 12px;
}

.registration_info__msg {
    line-height: 20px;
    font-size: 12px;
    color: $textPrimaryColor;
    padding-bottom: 17px;
}

.registration_info__close {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
}

.enter_btn {
    height: 34px;
    padding: 0 38px;
    line-height: 34px;
}

.ic--close {
    &.grey {
        fill: #979797;
    }
}

.field-signupform-inn {
    position: relative;
}
