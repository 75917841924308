
.add_new_spec {

  display: flex;
  font-size: 11px;
  max-width: 330px;
  align-items: center;
  background: #EDEEF0;
  padding: 5%;
  border-radius: 5px;
  .btn--submit {
    padding: 0;
    font-size: 12px;
    width: 150px;
    height: 40px;
  }

  .popmechanic-icon {
    width: 70px;
    height: 40px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAAAqFBMVEUAAAD/zwD/0AD/ygD/zwH/zwH/zgD/zwD/zgD/zwD/yQD/0AH/zwD/zwD/zQD/zwD/zwD/3wD/zwD/zwD/zgD/zwD/zwD/wgD/zwH/zwD/0AD/zwD/zgD/0AD/zgD/zwD/zwD/zQD/zwD/zwD/0AD/0AD/zwD/zwD/0gD/ygD/zwD/zgD/zwD/0AD/zwD/zgD/zQD/zwD/zwD/zgD/0AD/zwD/zwD/zwFA2zDOAAAAN3RSTlMAQFQh/fV960orDPry13Q+BQPm0lw5GAj37s2/vbSyqGwdnJSJhHhhExDIwq6fjYIz3rmjZ0ZR3PMH8wAAAeNJREFUWMPt1NlyqkAUheGlzKAIKk5RwDkep4xnv/+bpei2KJpgAt1XqfK7teqHttkbD3/NyIl6rUq9F8fF7w7/V13L8OgOz+gfd06An7wkU6phNhzfT7xNqKaBjUpjf0r1GZeql1kuqJnFCGXajJqaP0P0PKDmklBsHEmCbqJoTVI6UaFhT0jOPMwboy5J8vZ5xCdp/3Dj9kmaEYFrk4I1uCdS0AfjeqRiySI9UsLH+YPKOom5yifaWpuLSb5INteZToJd9dfadwBog8KU2RYxWxcIfBKsWOREJcPCKXV+5Av/KNh+DcWLOCEzp5IxX7bs6V3wosdeBIxJRW/VN+zySytGIvZHbMDsxPFBZlF9nFbxOBu+WNmWD8Snxsi8U4mlFYbyyc2CBi9uKibtPT+iyDiZZyO/q5UZ5790L9vyCjT5MiElbb5NSIkDpksKOgGYKyk4gxtZJE1v4eassB5D3DhTtbvhtiQpHiN3mJEUy0FBr0MS9BSCoS4RMQOI0ubvsUNZ6E+okf4Q34X2K9VnJA4qaQnVNI1t3DP+HNQoeEdTw49sKrm2BZ/RMsBvNBKlkCBGJj6UI8YH1CN7qEV4Qz3ShnpkCOWIkULegS+Wzh4qfC9rpFDTi18XEZSFeHgQfQG79uZfGIkg/wAAAABJRU5ErkJggg==);
    background-size: 100% 100%;
    margin: 0 15px 0 0;
  }
}

.spec_on_registration {
  position: absolute;
  top: 17%;
  right: 20%;
}

@media (max-width: 1746px) {
  .spec_on_registration {
    right: 5%;
  }
}

@media (max-width: 1024px) {
  .spec_on_registration {
    position: static;
  }
}

.popup_content {
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(38, 38, 38, .1);
  border-radius: 10px;
  max-width: 880px;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 202;
  display: none;
}

.popup_content__close {
  display: inline-flex;
  position: absolute;
  right: 60px;
  top: 64px;
  cursor: pointer;
  z-index: 20;
  @include mobile {
    right: 20px;
    top: 25px;
  }
}

.offer_order {
  margin: 64px 60px 60px;
  @include mobile {
    margin: 20px;
  }
}

.offer_order__title {
  color: $textPrimaryColor;
  font-family: $fontOpenSansBold;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 29px;
  @include mobile {
    margin-bottom: 0;
  }
}

.offer_order_table__title {
  color: $textSecondaryColor;
  font-family: $fontOpenSans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding-bottom: 17px;
  border-bottom: 1px solid #edeef0;
}

.offer_order_table {
  display: flex;
  flex-basis: 760px;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 19px;
}

.offer_order_table__row {
  &:first-child {
    border-bottom: 1px solid #edeef0;
  }
  &:last-of-type {
    border: none;
  }
  padding: 17px 0;
  border-bottom: 1px solid #edeef0;
  display: flex;
  @include mobile {
    flex-wrap: wrap;
  }
}

.offer_order_table_order__title {
  color: $textPrimaryColor;
  font-family: $fontOpenSansSemibold;
  font-size: 13px;
  line-height: 25px;
}

.offer_order_table_order {
  display: inline-flex;
  flex-direction: column;
  @include mobile {
    margin-bottom: 17px;
  }
}

.offer_order_table_order__data {
  //display: flex;
  color: $textSecondaryColor;
  font-family: $fontOpenSans;
  font-size: 12px;
  line-height: 14px;

  .ic--key {
    margin-right: 10px;
    top: -1px;
  }
}

.offer_order_table__clm {
  padding-right: 10px;

  &.is_order {
    flex: 1;
    @include mobile {
      flex: auto;
      width: 100%;
    }
  }
  &.is_controls {
    @include mobile {
      width: 100%;
      padding-right: 0;
      .button--confirm.is_offer.button__primary {
        margin: 0;
        width: 100%;
      }
    }
  }
}

.order_has_sent {
  border: solid 1px #e3e5e8;
  padding: 8px 39px 10px;
  width: auto;
  height: auto;
  line-height: 17px;
  color: $textSecondaryColor;
  font-size: 14px;
}

.offer_order_stub {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-bottom: 41px;
  .ic--stub_icon {
    margin-bottom: 27px;
    margin-top: 27px;
  }
  .offer_order_stub__message {
    margin-bottom: 40px;
    color: $textSecondaryColor;
    font-family: $fontOpenSans;
    font-size: 14px;
    line-height: 16px;
  }
  .button__primary.button--confirm {
    @include mobile {
      margin-left: 0 !important;
    }
  }
}

.offer_order_table__body {
  max-height: 370px;
  overflow-y: auto;

  @include mobile {
    max-height: 420px;
  }
}

.offer_order_controls {
  @include mobile {
    .button {
      width: 100%;
    }
  }
}

.ajax_loader {
  width: 200px;
  height: 200px;
  background: url('/media/images/loader.gif');
  margin: 100px auto;
}

.offer_order__inner--stub {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding: 50px 0 40px;
  z-index: 10;

  &:before {
    content: '';
    display: block;
    height: 240px;
    width: 1px;
    background-color: #dadada;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .offer_order_stub {
    flex-basis: 50%;
    text-align: center;

    .offer_order_stub__message {
      color: #262626;
    }
  }

  @include mobile {
    padding: 20px 0;

    &:before {
      left: 0;
      height: 1px;
      width: 100%;
      top: 50%;
      transform: translate(0, -50%);
      margin-top: -20px;
    }

    .offer_order_stub {
      flex-basis: 100%;
    }
  }
}

.modal-geoip {
  position: fixed;
  z-index: 100000;
  padding: 20px 25px 22px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 1);
  border: 2px solid #ffd24d;
  top: 10%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);

  &__wrapper {
    max-width: none;
  }

  &__content {
    padding-top: 5px;
  }

  &__footer {
    display: flex;
    padding-top: 15px;
  }

  &__btn {
    white-space: nowrap;
  }

  &__discard {
    background: none;
  }
}
