.notify {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 300px;
  color: white;
  position: fixed;
  top: 30px;
  right: 30px;
  padding: 20px;
  background: rgba(242, 38, 19, 0.9);
  border: 1px solid rgba(217, 30, 24, 0.6);
  box-shadow: 0 0 5px rgba(217, 30, 24, 0.6);
  z-index: 5000;

  span {
    font-size: 13px;
  }
}