@import 'base/variables';

mark {
  background: #ffd24d;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
}

.main_promo__container {
  background: url(../images/main/new-back.png) no-repeat;
  // height: 216px;
  height: 512px;
  background-size: cover;
  background-position: center center;
  margin-bottom: 244px;
  position: relative;

  @include tablet-up {
    background-image: url(../images/main/new-back.png);
    height: 560px;
    margin-bottom: -18px;
  }

  @include extra-big-desktop {
    background-image: url(../images/main/new-back.png);
    height: 620px;
  }
}

.main_promo__texts {
  background: $colorWhite;
  box-shadow: 0 4px 84px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 40px 30px 46px;
  position: absolute;
  top: 177px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: $colorBlack;
  max-width: 411px;
  width: calc(100% - 20px);

  @include tablet-up {
    right: calc(50% + 10px);
    left: inherit;

    max-width: 285px;
    padding: 34px 30px 34px;
    transform: none;
  }

  @include tablet-up-680 {
    right: calc(50% + 15px);
  }

  @include tablet-up-720 {
    right: calc(50% + 20px);
  }

  @include tablet-up-800 {
    max-width: 330px;
  }

  @include wide-tablet-up {
    top: auto;
    bottom: 116px;
    padding: 44px 56px 40px;
    max-width: 411px;
  }

  &.right {
    top: 485px;

    @include tablet-up {
      left: calc(50% + 10px);
      right: inherit;
      top: 177px;
    }

    @include tablet-up-680 {
      left: calc(50% + 15px);
    }

    @include tablet-up-720 {
      left: calc(50% + 20px);
    }

    @include wide-tablet-up {
      top: auto;
    }
  }
}

.page_content_section_about {
  background-color: $colorGreyFirst;
  background-image: url(../images/about-house.png);
  background-repeat: no-repeat;
  background-size: auto 92%;
  background-position: bottom left;
  padding: 70px 0 62px;

  @include small-desktop {
    background-size: 30% auto;
  }

  @include mobile {
    background-image: none;
  }
}

.ic--hobby {
  margin-bottom: 30px;
}

.page_content_context .about-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  max-width: 650px;
  margin-left: auto;
  margin-right: 28px;
  position: relative;
  z-index: 2;

  &--reverse {
    margin-left: 35px;
    margin-right: auto;
  }

  p.bold {
    margin-left: 0;
    text-align: left;
  }

  h3 {
    text-align: left;
  }

  p {
    text-align: left;
    margin-bottom: 15px;
    width: 100%;
  }

  @include small-desktop {
    width: 70%;
  }

  @include mobile {
    width: auto;
  }
}

.page_content_section_best {
  background-color: $colorGreyFirst;
  //background-image: url(../images/about-worker.png);
  //background-repeat: no-repeat;
  //background-size: contain;
  //background-position: bottom right;
  padding: 70px 0 71px;
  position: relative;
}

.page_content_context .work_content {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    max-width: 640px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h3 {
    margin-bottom: 47px;
  }
}

.page_content_section_work {
  padding: 70px 0 70px;
}

.page_content_section_history {
  padding: 70px 0 73px;

  .page_content_container {
    h3 {
      margin-top: 10px;
      max-width: 460px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 48px;
      text-align: center;
    }

    p {
      max-width: 630px !important;
    }

  }
}

.page_content_section_cost {
  padding: 70px 0 80px;
  background-color: $btnPrimaryColor;

  .page_content_container {
    h3 {
      margin-top: 10px;
      margin-bottom: 47px;
    }

    p {
      max-width: 640px;
    }
  }
}

.info--about {
  position: relative;
  z-index: 2;
  width: 50%;

  @include small-desktop {
    width: 70%;
  }

  @include mobile {
    width: 100%;
  }
}

.image--about {
  position: absolute;
  right: 0;
  bottom: -4px;
  z-index: 1;

  @include small-desktop {
    width: 45%;
    height: 100%;
    overflow: hidden;

    img {
      height: 100%;
      width: auto !important;
    }
  }

  @include mobile {
    display: none;
  }
}

.image--best {
  display: block;
  position: absolute;
  right: 0;
  bottom: -4px;
  z-index: 1;
  width: 598px;
  height: 557px;
  background-image: url(../images/about-worker.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;

  @include small-desktop {
    width: 50%;
    height: 100%;
    right: -10%;
  }

  @include mobile {
    display: none;
  }
}

.main__title {
  font-family: $fontOpenSansBold;
  font-size: 28px;
  line-height: 30px;
  margin: 0 0 20px;
  text-align: center;

  @include tablet-up {
    font-size: 28px;
    line-height: 30px;
  }

  @include tablet-up-800 {
    font-size: 34px;
    line-height: 36px;
  }

  @include wide-tablet-up {
    font-size: 40px;
    line-height: 46px;
  }

  @include desktop-up {
    font-size: 40px;
    line-height: 46px;
  }
}

.main__text {
  font-family: $fontOpenSans;
  font-size: 14px;
  line-height: 24px;
  max-width: 276px;
  margin: 0 auto 32px;

  @include tablet-up {
    font-size: 14px;
    line-height: 24px;
    max-width: 286px;
    margin-bottom: 30px;
  }

  @include tablet-up-800 {
    font-size: 16px;
    line-height: normal;
  }

  @include desktop-up {
    font-size: 16px;
    line-height: normal;
    max-width: 286px;
    margin-bottom: 30px;
  }
}

.button__place_order {
  font-family: $fontOpenSansSemibold;
  font-size: 14px;
  padding: 0;
  width: 100%;
  max-width: 241px;

  @include tablet-up {
    font-size: 14px;
    padding: 0;
    width: 100%;
    max-width: 241px;
  }

  @include desktop-up {
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    border-radius: 29px;
  }
}

.main_section {
  @include mobile {
    padding: 0 15px;
  }
  &.is_gray {
    background-color: #f5f6f7;
  }
  &.is_testimonials {
    padding: 0;
  }
  &.is_advantages {
    padding: 0 25px;
    background-color: $btnPrimaryColor;
  }
  &.is_portfolio {
    padding: 0 25px;
    @include mobile {
      padding: 0 20px;
    }
  }
  &.is_newsletter {
    padding: 0 10px;
    background-color: #f5f6f7;
  }
}

.main_favorite {
  padding-bottom: 41px;
}

.main__section_title {
  text-align: center;
  font-family: $fontOpenSansBold;
  font-size: 32px;
  color: $colorBlack;
  letter-spacing: 0;
  margin: 0 0 20px 0;
  line-height: 45px;
  @include mobile {
    font-size: 20px;
    line-height: 30px;
  }
  &.is_switch {
    span {
      cursor: pointer;
      color: #ffd24d;
      &.is_active {
        color: $colorBlack;
      }
    }
  }
}

.main__section_sub_title {
  text-align: center;
  font-size: 18px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  line-height: 30px;

  &.is_capitalized {
    font-family: $fontOpenSansBold;
    font-size: 12px;
    color: $textPrimaryColor;
    letter-spacing: 2px;
    line-height: 30px;
    margin-top: 41px;
    text-transform: uppercase;
  }
  span {
    @include mobile {
      display: none;
    }
  }
  @include mobile {
    font-size: 14px;
    line-height: 1.79;
    text-align: center;
    max-width: 200px;
    display: block;
    margin: 0 auto;
  }
}

.main_section__container {
  padding: 86px 0 0;
  max-width: 1046px;
  margin: 0 auto;
  @include mobile {
    padding: 47px 0 41px;
  }
}

.main_favorite__content {
  &.is_order {
    display: none;
  }
  margin-bottom: 105px;
  @include mobile {
    margin-bottom: 66px;
  }
}

.main_favorite_specs {
  display: flex;
  flex-wrap: wrap;
  padding: 78px 0 0;
  justify-content: space-between;
  @include mobile {
    padding: 10px 0 54px;
  }
}

.main_favorite_spec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  margin-bottom: 77px;
  flex-grow: 1;
  width: calc(100% * (1 / 4) - 10px - 1px);
  &__href {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  @include mobile {
    width: 49%;
    margin-bottom: 16px;
  }
  &:hover {
    .main_favorite_spec__title {
      color: #ffd24d;
    }
    .main_favorite_spec__icon {
      .ic {
        fill: #ffd24d;
      }
    }
  }
  .main_favorite_spec__icon {
    .ic {
      fill: $textPrimaryColor;
    }
  }

  .sub_favorite_spec {
    width: 150px;
    margin: 0 auto;

    li {
      margin-bottom: 8px;
    }

    @include mobile {
      width: 120px;
    }

    &__title {
      font-size: 12px;
      word-wrap: break-word;
      width: inherit;
      line-height: 16px;
    }

    .show_more {
      display: none;
    }
  }
  .btn_show_more {
    color: #3c4146;
    font-size: 13px;
    &:hover {
      color: #0b72b8;
    }
  }
}

.about_specs {
  display: flex;
  flex-wrap: wrap;
  padding: 78px 0 0;
  justify-content: space-between;
  @include mobile {
    padding: 10px 0 54px;
  }

  &--about {
    .about_spec {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      min-width: 105px;
    }
  }
}

.about_spec {
  display: flex;
  margin-bottom: 77px;
  width: calc(100% * (1 / 4) - 10px - 1px);
  @include mobile {
    width: 49%;
    margin-bottom: 16px;
  }
  &:hover {
    .main_favorite_spec__title {
      color: #ffd24d;
    }
    .main_favorite_spec__icon {
      .ic {
        fill: #ffd24d;
      }
    }
  }
  .main_favorite_spec__icon {
    .ic {
      fill: $textPrimaryColor;
    }
  }
}

.about_spec__title {
  margin-left: 20px;
}

.main_favorite_spec__title {
  font-family: $fontOpenSansSemibold;
  font-size: 14px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  width: 175px;
  @include mobile {
    font-size: 12px;
    width: 85px;
  }
}

.main_favorite_spec__icon {
  margin-bottom: 16px;
  &.is_stub {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid $colorBlack;
  }
  .ic {
    height: 49px;
  }
}

.ic--engineering {
  margin-bottom: 20px;
}

.button__see_all {
  width: 240px;
  display: block;
  margin: 0 auto;
}

.steps__container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0;
  justify-content: space-between;
}

.step__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  margin-bottom: 75px;
  flex-grow: 1;
  margin-right: 1px;
  text-align: center;
  .step_link {
    margin-top: 20px;
  }
  @include mobile {
    margin-bottom: 37px;
  }
}

.step_link {
  font-family: $fontOpenSansSemibold;
  font-size: 12px;
  color: $textSecondaryColor;
  letter-spacing: 0;
}

.step_num {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $btnPrimaryColor;
  line-height: 32px;
  margin-bottom: 10px;
  position: relative;
  right: 30px;
  font-family: $fontOpenSansSemibold;
  @include mobile {
    right: -52px;
  }
}

.step_name {
  width: 240px;
  font-size: 14px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  line-height: 25px;
  @include mobile {
    width: 100%;
    text-align: center;
  }
}

.main_warranty {
  padding: 41px 0 79px;
  background-color: $bgColor;
  @include mobile {
    padding: 41px 0 39px;
  }
  .main__section_title {
    margin: 26px 0 36px;
    &.is_desktop {
      @include mobile {
        display: none;
      }
    }
    &.is_mobile {
      display: none;
      @include mobile {
        display: block;
        margin: 28px 0 25px;
      }
    }
  }
  .main__section_sub_title {
    &.is_desktop {
      display: block;
      margin-bottom: 51px;
      @include mobile {
        display: none;
      }
    }
    &.is_mobile {
      display: none;
      @include mobile {
        display: block;
        margin-bottom: 20px;
        max-width: 100%;
      }
    }
  }
}

.main_warranty__icon {
  text-align: center;
}

.main_testimonials {
  padding: 106px 0 96px;
  @include mobile {
    padding: 49px 0 51px;
  }
}

.main_testimonials__container {
  //max-width: 800px;
  margin: 41px auto 0;
  .master_feedback {
    margin: 20px;
    padding: 35px 40px;
    //background: #fff;
    &:not(.slick-current) {
      filter: blur(1px);
      opacity: 0.8;
    }
  }
  .slick-dots {
    //bottom: -52px;
  }
}

.arrow_right, .arrow_left {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  width: 44px;
  height: 44px;
  fill: $textSecondaryColor;
  &:hover {
    fill: #EDEEF0;
  }
}

.arrow_left {
  left: -85px;
  transform: rotate(270deg);
}

.arrow_right {
  right: -85px;
  transform: rotate(90deg);
}

.main_advantages {
  padding: 106px 0 81px;
  background: $btnPrimaryColor;
  @include mobile {
    padding: 47px 0 21px;
  }
  .main__section_title {
    display: block;
    max-width: 480px;
    margin: 0 auto;
    @include mobile {
      max-width: 100%;
    }
  }
  .main__section_sub_title {
    @include mobile {
      max-width: 100%;
    }
  }
}

.main_advantages__container {
  max-width: 820px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 60px;
  @include mobile {
    max-width: 100%;
    padding-top: 30px;
  }
}

.main_advantage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  flex-grow: 1;
  margin-right: 1px;
  text-align: center;
  @include mobile {
    margin-bottom: 34px;
  }
}

.main_advantage__title {
  font-size: 14px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  line-height: 25px;
}

.main_portfolio {
  padding: 108px 0 80px;
  display: flex;
  flex-direction: column;
  @include mobile {
    padding: 42px 0 77px;
  }
}

.main_portfolio__inner {
  margin-top: 30px;
  @include mobile {
    max-width: 100%;
  }
}

.main_portfolio__container {
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  padding-bottom: 60px;

  &.slick-dotted {
    margin-bottom: 0;
  }
}

.main_portfolio__item {
  display: flex;
  flex-direction: column;
  margin-right: 40px;

  @include mobile {
    margin-right: 0;
  }

  &:hover {
    .main_portfolio__user_info {
      visibility: visible;
    }
  }

  &.checked {
    .main_portfolio__user_info {
      .image {
        img {
          border: 2px solid #ffd24d;
        }
      }

      .info {
        .name {
          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
    }
  }
}

.main_portfolio__item_inner {
  position: relative;
}

.main_portfolio__item_inner_wrap {
  padding: 23px;
}

.main_portfolio__item_img {
  border-radius: 8px;
  overflow: hidden;
  img {
    width: 100%;
  }
  @include mobile {
    width: auto;
    height: auto;
    background-color: transparent;
    img {
      border-radius: 8px;
      margin: 0 auto;
    }

  }
}

.main_portfolio__nav {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow_left--is_port,
  .arrow_right--is_port {
    position: static;
  }

  .arrow_left--is_port {
    order: 1;
  }

  .arrow_right--is_port {
    order: 3;
  }

  .slick-dots {
    position: relative;
    order: 2;
    width: auto;
    bottom: 2px;
    min-width: 200px;
  }
}

.checked_master_tooltip {
  display: none;
  background: #555659;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  font-family: $fontOpenSans;
  font-size: 11px;
  line-height: 15px;
  color: #FFFFFF;
  padding: 2.3px 5px;
  position: absolute;
  top: 3px;
  right: -145px;
  z-index: 100;

  &:before {
    content: "\A";
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #555659 transparent transparent;
    position: absolute;
    left: -14px;
    top: 0;
  }
}

.checked_master {
  display: inline-block;
  position: relative;

  > svg.ic.ic--king {
    position: relative;

    &:hover + .checked_master_tooltip {
      display: block;
    }
  }
}

.main_portfolio__user_info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(38, 38, 38, 0.7);
  mix-blend-mode: normal;
  border-radius: 8px;
  padding: 23px 19px 23px;
  visibility: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .image {
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid transparent;
    }
  }
  .info {
    padding-left: 20px;

    .name {
      font-family: $fontOpenSansBold;
      font-size: 18px;
      color: white;
      letter-spacing: 0;
      line-height: 25px;
      margin-bottom: 15px;

      a {
        display: inline;

        &:hover {
          color: white;
          border-color: transparent;
        }
      }
    }
    .grade {
      font-family: $fontOpenSansBold;
      font-size: 10px;
      color: $textPrimaryColor;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 5px;
      background: #F5F6F7;
      border-radius: 2px;
      padding: 2px 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: max-content;

      .ic.ic--liga-small {
        margin-right: 5px;
      }
      &.last {
        margin-bottom: 15px;
      }
    }
    ul {
      li {
        font-family: $fontOpenSans;
        font-size: 14px;
        color: white;
        letter-spacing: 0;
        line-height: 30px;
      }
    }
  }
  .rating {
    font-family: $fontOpenSans;
    font-size: 56px;
    color: white;
    letter-spacing: 0;
    line-height: 1;
  }
}

.main_portfolio__item_name {
  font-family: $fontOpenSans;
  font-size: 12px;
  text-align: left;
  color: $textSecondaryColor;
  margin-top: 10px;
}

.portfolio_slider_user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.arrow_left--is_port {
  position: absolute;
  z-index: 20;
  bottom: -46px;
  left: 40%;
  width: 44px;
  height: 44px;
  fill: $textSecondaryColor;
  transform: rotate(270deg);
  &:hover {
    fill: #EDEEF0;
  }
}

.arrow_right--is_port {
  position: absolute;
  z-index: 20;
  bottom: -46px;
  right: 40%;
  width: 44px;
  height: 44px;
  fill: $textSecondaryColor;
  transform: rotate(90deg);
  &:hover {
    fill: #EDEEF0;
  }
}

.slick-dots {
  bottom: -34px;
}

.main_start_work {
  padding: 106px 0 81px;
  border-top: 1px solid #edeef0;
  @include mobile {
    border-top: none;
    padding: 0 0 59px;
  }
}

.start_work__container {
  max-width: 672px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mobile {
    justify-content: center;
  }
}

.start_work_item {
  text-align: center;
  @include mobile {
    &:last-child {
      margin-top: 39px;
    }
  }
  &:hover {
    color: $textPrimaryColor;
  }
}

.start_work_item__icon {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 1px solid $lineColor;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color $animationTime $animationFunc;

  .start_work_item:hover & {
    background-color: $bgColor;
    border-color: $bgColor;
  }
}

.start_work_item__text {
  font-size: 14px;
  color: $textSecondaryColor;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 22px;
  @include mobile {
    font-size: 12px;
  }
}

.start_work_item__status {
  margin: 31px 0 17px;
  font-size: 18px;
}

.ic--customer, .ic--contractor {
  @include mobile {
    width: 90px;
    height: 90px;
  }
}

.main_newsletter {
  background: $bgColor;
  padding: 57px 0 34px;
  @include mobile {
    padding: 38px 0 27px;
  }
}

.main_newsletter__inner {
  text-align: center;

  .section_title {
    font-family: $fontOpenSansBold;
  }
}

.form_newsletter {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 840px;
  margin: 35px auto;
  @include mobile {
    max-width: 100%;
    flex-direction: column;
    margin: 26px auto 0;
  }
}

.form_newsletter__cell {
  display: flex;
  flex-basis: 280px;
  margin-right: 35px;
  text-align: left;
  position: relative;
  &.is_btn {
    flex-basis: 160px;
    @include mobile {
      flex-basis: auto;
      justify-content: center;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  @include mobile {
    margin-right: 0;
    flex-basis: auto;
    width: 280px;
    margin-bottom: 18px;
    .form__text {
      width: 100%;
    }
  }
  &.is_error {
    .form__text {
      border: 1px solid $colorError;
    }
  }
  .form_error {
    position: absolute;
    bottom: -30px;
  }
}

.selectize-dropdown {
  &.js_user_selector {
    //height: 80px !important;
  }
}

.form_newsletter__personal_data {
  font-family: $fontOpenSans;
  font-size: 11px;
  color: $textSecondaryColor;
  letter-spacing: 0;
  line-height: 20px;
  max-width: 508px;
  margin: 0 auto;
  a {
    color: $textSecondaryColor;
  }
  @include mobile {
    max-width: 100%;
    line-height: 15px;
    a {
      color: $textSecondaryColor;
    }
  }
}

.main_favorite_orders {
  margin-top: 78px;
  @include mobile {
    margin-top: 0;
  }
  .table_styled__title {
    &:last-child {
      text-align: left;
    }
  }
}

.step__item {
  .ic {
    height: 48px;
  }
}

.ic--main_arrow_left {
  cursor: pointer;
}

.ic--main_arrow_right {
  cursor: pointer;
}

.selectize-dropdown.js_user_selector {
  .option {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/** Master card on main page in slider */

.main_testimonials__container {
  .master_feedback {
    &:hover {
      border-color: #C6C7CE;
      outline: none;
    }

    &.checked {
      .master_feedback__header {
        .master_feedback__image {
          img {
            border: 2px solid #ffd24d;
          }
        }

        .master_feedback__fullname {
          a {
            display: inline;

            &:hover {
              color: black;
              border-color: transparent;
            }
          }

          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
    }

    .master_feedback__header {
      .master_feedback__image {
        margin-right: 20px;

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }

      .master_feedback__fullname {
        font-family: $fontOpenSansBold;
        font-size: 18px;
        color: black;
        letter-spacing: 0;
        line-height: 25px;

        .checked_master {
          display: inline-block;
          position: relative;
        }
      }

      .master_feedback__rating {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }

    .master_feedback__info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .master_feedback__author {
        font-style: italic;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .main_portfolio__user_info {
    visibility: visible;
    position: static;
    height: auto;
    background: transparent;
    border-radius: 0;
    padding: 0;
    width: 95%;
    margin: 0 auto;
    position: relative;
    top: -25px;
    flex-wrap: wrap;

    .image {
      width: 100%;
    }
    .info {
      padding-left: 0;
      margin-top: 20px;
      width: 75%;

      .name {
        color: black;
        width: 70%;

        a {
          &:hover {
            color: white;
            border-color: transparent;
          }
        }
      }
      ul {
        visibility: hidden;
        display: none;
      }
    }
    .rating {
      color: black;
      margin-top: 20px;
    }
  }
  .main_portfolio__item_name {
    width: 95%;
    position: relative;
    top: -25px;
    font-size: 14px;
    margin: 0 auto;
  }
  .main_portfolio__container.js_portfolio_slider.slick-initialized.slick-slider.slick-dotted {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 415px) {
  .main_testimonials__container {
    .master_feedback__header {
      .master_feedback__rating {
        .stars__list {
          margin: 5px 0;
        }
      }
    }
  }
}

.hidden {
  display: none !important;
}


.block-online{
  max-width: 800px;
  margin: 0 auto;
}

.title-page__text {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  font-family: 'OpenSans-Bold';
}

.block-store__grey{
  background: #E9E9E9;
  border: 1px solid #E9E9E9;
  padding: 25px 38px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
}

.block-store__pic{
  margin-right: 70px;
}

.block-store__content{
  max-width: 350px;
  padding-bottom: 15px;
  a{
    font-size: 12px;
    line-height: 34px;
    height: 34px;
    font-family: 'OpenSans-Bold';
    font-weight: 600;
  }
}

.block-store__title{
  font-family: 'OpenSans-Bold';
  font-size: 20px;
  margin-bottom: 14px;
}

.main-block{
  padding: 0 20px;
  .breadcrumbs{
    padding-left: 0 !important;
  }
}

.block-store__description{
  margin-bottom: 22px;
  font-size: 12px;
  font-family: 'Open-Sans';
  line-height: 17px;
}

.block-store__grey_bg{
  background: #E9E9E9;
  padding: 54px 50px 40px;
  margin-top: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

@media (max-width: 768px){
  .block-store__content{
    padding-bottom: 0;
  }
  .block-store__pic{
    display: none;
  }
  .block-store__grey{
    padding: 20px;
  }
  .block-store__grey{
    margin-bottom: 30px;
  }
  .block-store__grey_bg{
    padding: 20px;
    background-image: none !important;
  }
}
