.page_error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 136px 0;
    @include mobile {
        margin: 105px 0;
    }
}

.page_error__text {
    font-size: 14px;
    line-height: 1.79;
    text-align: center;
    color: #262626;
    margin-bottom: 36px;
    @include mobile {
        max-width: 226px;
        line-height: 1.4;
        margin-bottom: 96px;
    }
}

.page_error_promo {
    opacity: 0.7;
    font-size: 14px;
    line-height: 1.79;
    color: #262626;
    margin-bottom: 29px;
    @include mobile {
        max-width: 258px;
        text-align: center;
    }
}
.page_error_links {
    display: flex;
    @include mobile {
        max-width: 258px;
        justify-content: space-between;
    }
}

.page_error_links__item {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #262626;
    margin-right: 50px;
    display: block;
    cursor: pointer !important;
    &:last-of-type {
        margin-right: 0;
    }
    @include mobile {
        margin-right: 19px;
        max-width: 258px;
    }
}

.page_error__value {
    font-size: 150px;
    line-height: 1;
    display: block;
    margin-bottom: 80px;
    font-weight: 900;
    position: relative;

    &:after {
        content: '';
        background: url('/media/images/overlay.png');
        width: 239px;
        height: 143px;
        position: absolute;
        top: 10px;
        right: 20px;
    }

    & + .page_error__text {
        margin-bottom: 88px;
    }
}

.page_error_img {
    max-width: 282px;
    height: 169px;

    img {
        width: 100%;
        height: auto;
    }
    @include mobile {
        max-width: 226px;
        margin-bottom: 20px;
    }
}