.tabs {
    padding: 0 10px;
    margin-bottom: 76px;
    overflow: hidden;

    &:last-of-type {
        margin-bottom: 0;
    }

    @include tablet-up {
        padding: 0 16px;
        overflow: inherit;
    }

    @include desktop-up {
        padding: 0 80px;
    }
}

.tabs_links {
    display: flex;
    border-bottom: 1px solid $lineColor;
    padding-bottom: 15px;
    margin-bottom: 18px;
    white-space: nowrap;
    overflow-x: auto;

    @include tablet-up {
        margin-bottom: 20px;
    }

    @include desktop-up {
        margin-bottom: 20px;
    }
}

.tabs_links__item {
    @extend %section_title;

    color: $textSecondaryColor;
    position: relative;

    &:after {
        content: '';
        display: none;
        left: 0;
        right: 0;
        top: 100%;
        margin-top: 13px;
        background-color: $btnPrimaryColor;
        height: 2px;
        position: absolute;
    }

    &.is_active {
        &:after {
            display: block;
        }
    }

    @include tablet-up {
        padding-bottom: 10px;
    }
}

.tabs_links__link {
    color: $textSecondaryColor;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    align-items: center;

    .is_active & {
        color: $textPrimaryColor;

        .quantity {
            background-color: $btnPrimaryColor;
        }
    }

    .quantity {
        height: 20px;
        border-radius: 3px;
        background-color: $btnSecondaryColor;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
        color: $colorWhite;
        margin-left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 7px;
        line-height: 20px;
    }

    &:hover {
        color: $textPrimaryColor;
    }

    @include big-desktop {
        padding: 0 20px;
    }
}

.tabs_content__item {
    display: none;

    &.is_active {
        display: block;
    }
}

.tabs_content {
    margin-bottom: 20px;
}