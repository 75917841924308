@import "../../base/variables";

.album-confirmation--wrapper,
.image-confirmation--wrapper {
  @extend .modal-wrapper;
  display: block;
  min-width: 290px;
  padding: 41px 80px;
  width: 550px;
  height: min-content;

  .text {
    font-weight: bold;
    margin: 26px auto;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > .button__primary {
      width: 256px
    }

    > .button__disabled {
      border: 2px solid #E3E5E8;
      color: #A5A8AD;
      line-height: 0;
      background-color: inherit;

      &:hover {
        color: #75787D;
        border-color: #C3C5C8;
      }
    }
  }

  @media (max-width: 899px) and (min-width: 600px) {
    margin: auto;
    transform: none;
    overflow-y: auto;
  }

  @media (max-width: 600px) {
    padding: 33px;
    width: 290px;
    .text {
      font-size: 16px;
      line-height: 22px;
    }

    .buttons {
      flex-direction: column;

      .button {
        width: 100%;
        justify-content: stretch;

        &:first-child {
          margin-bottom: 14px;
        }
      }
    }
  }

  .timer {
    margin: auto;
    position: relative;
    height: 120px;
    width: 120px;


    span {
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 56px;
      line-height: 76px;
      position: absolute;
      top: 20%;
      left: 0;
      width: 120px;
      text-align: center;
    }

    .base-timer__circle {
      fill: none;
      stroke: none;
    }

    .base-timer__path-elapsed {
      stroke-width: 7px;
      stroke: $btnPrimaryColor;
      stroke-linecap: round;
      transform: rotate(-90deg);
      transition: 975ms linear all;
      transform-origin: center;
    }
  }
}

.image-confirmation--wrapper {
  z-index: 10001;
  &.fancybox-margin { margin-right: 0 }
  .text { flex-direction: column }
}