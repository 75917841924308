.master_answer {
  border-left: 2px solid $colorYellow;
  padding-left: 14px;
  margin-top: 21px;
  margin-bottom: 10px;

  @media (min-width: 600px) {
    border-left: 4px solid $colorYellow;
    margin-top: 24px;
    padding-left: 25px;
    padding-right: 6px;
    margin-bottom: 2px;
  }

  @media (min-width: 900px) {
    margin-top: 24px;
    margin-bottom: 6px;
  }

  @media (min-width: 1280px) {
    margin-top: 30px;
    margin-bottom: 14px;
  }

  .master_answer__flag--edit,
  .master_answer__flag--moderation,
  .master_answer__flag--rejected {
    display: none;
  }

  &--error {
    margin-bottom: 5px;

    .master_answer__flag--edit,
    .master_answer__flag--rejected {
      display: inline-block;
    }
  }

  &--approved {
    .master_answer__action {
      display: none;
    }
  }
}

.master_answer--error {
  border-left: 2px solid $bgColor;

  @media (min-width: 600px) {
    border-left: 4px solid $bgColor;
  }

  @media (min-width: 900px) {
    margin-bottom: 11px;
  }

  .master_reply .button {
    margin-right: -1px;

    @media (min-width: 600px) {
      margin-right: 15px;
    }
  }
}

.master_answer--edit .master_answer__flag--edit {
  display: none;
}

.master_answer--moderation {
  border-left: 2px solid $bgColor;
  margin-top: 24px;
  margin-bottom: 6px;

  @media (min-width: 600px) {
    border-left: 4px solid $bgColor;
    margin-bottom: 2px;
  }

  @media (min-width: 900px) {
    margin-top: 25px;
    margin-bottom: 7px;
  }

  @media (min-width: 1280px) {
    margin-top: 30px;
    margin-bottom: 13px;
  }

  .master_answer__flag--moderation {
    display: inline-block;
  }
}

.master_answer--moderation .master_reply h4 {
  display: none;
}

.master_answer__text {
  font-family: $fontOpenSans;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #515151;
  margin-bottom: 8px;

  @media (min-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}

.master_answer__info {
  font-family: $fontOpenSans;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: $textSecondaryColor;
  padding-bottom: 6px;

  @media (min-width: 600px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (min-width: 900px) {
    padding-bottom: 2px;
  }
}

.master_answer__status {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: flex-start;

  @media (min-width: 600px) {
    margin-bottom: 15px;
  }
}

.master_answer__flag {
  border-radius: 21px;
  padding: 3px 7px;
  font-family: $fontOpenSans;
  color: #181818;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  margin-right: 7px;
  //margin-bottom: 15px;

  &--moderation {
    background-color: $btnSecondaryColor;
  }

  &--rejected {
    color: $colorWhite;
    background-color: #EA3924;
  }

  &--edit {
    font-weight: 600;
    color: $textPrimaryColor;
    background-color: $btnPrimaryColor;
    cursor: pointer;
  }

  @media (min-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 15px;
    margin-right: 15px;
  }
}

.master_answer__action {
  cursor: pointer;
  font-family: $fontOpenSans;
  color: $textSecondaryColor;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  transition: color 0.3s ease;
  margin-left: auto;
  margin-top: 3px;

  span {
    display: none;
  }

  &:hover {
    color: $textPrimaryColor;
  }

  @media (min-width: 600px) {
    font-size: 12px;
    line-height: 16px;

    span {
      display: inline;
    }
  }
}

.master_answer__flags {
  width: 100%;
}

.master_answer__error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF5F3;
  border: 1px solid #ECECEC;
  border-radius: 4px;
  padding: 6px 12px;
  margin-bottom: 13px;

  span {
    font-family: $fontOpenSans;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #515151;
    max-width: 70%;
  }

  svg {
    height: 14px;
    width: 14px;
  }

  @media (min-width: 600px) {
    padding: 10px 20px;
    margin-bottom: 21px;

    span {
      font-size: 14px;
      line-height: 24px;
      max-width: 100%;
    }
  }

  @media (min-width: 900px) {
    margin-bottom: 21px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 16px;
  }
}
