.album_edit_wrapper {
  padding: 0 20px;
  max-width: 720px;
  margin: auto;
  //margin: 33px 40px 40px;

  .form_row--btns {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;

    .button {
      width: 200px
    }

    @media (max-width: 600px) {
      flex-direction: column-reverse;

      .button {
        &:last-child { margin-bottom: 20px }
        width: 100%;
      }
    }
  }

  .select-type-wrapper {
    display: flex;
    flex-direction: row;
  }

  .form_row {
    margin-bottom: 16px;

    &__description {
      display: flex;
      flex-direction: column;

      .form_cell--label {
        width: 100%;
      }
    }
  }

  .form_row_nowrap {
    max-width: none;
  }

  .file_input_link__container {
    display: none;
  }

  @include for-tablet-portrait-up {
    padding-left: 42px;
    padding-right: 42px;

    .form__label {
      margin-bottom: 7px;
    }

    textarea.form__text {
      height: 100px;
    }
  }

  @include for-tablet-landscape-up {
    .form_cell--label {
      width: 160px;
      padding-top: 16px;
    }

    .form_row--with_hint {
      .form_cell--label {
        padding-left: 0;
      }
    }

    .form__label {
      margin-bottom: 7px;
    }
  }

  @include for-desktop-up {
    .form__text:focus ~ .hint_block {
      .hint_block__content {
        opacity: 1;
        visibility: visible;
      }
    }

    .file_input_link__container {
      display: none;
    }

    .form_cell--visibility {
      margin-bottom: 24px;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    text-align: left;
  }

  /* Hack for IE */
  .number-spin-btn-container {
    display: none !important;
  }

  .form_cell--short {
    width: 66%;
  }

  .form_cell--long {
    @media (min-width: 900px) {
      width: 100%;
    }
  }

  .section_title {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  .album_upper-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #262626;
    opacity: 0.5;
    margin-top: 13px;
    margin-bottom: 22px;

    @media (max-width: 599px) {
      font-size: 12px;
      line-height: 16px;
      margin-top: 10px;
      margin-bottom: 25px;
    }
  }
}
