@import '../base/variables';

.form_row {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  @include for-desktop-up {
    flex-direction: row;
    margin-bottom: 49px;
  }

  &.is_distance {
    position: relative;
    @include mobile {
      .form_item__input {
        &.is_short {
          width: 50%;
        }
      }
    }
    .form_item__input {
      &:first-child {
        margin-right: 20px;
        @include mobile {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.is_phone {
    .form_item__input {
      &:first-child {
        margin-right: 20px;
        @include mobile {
          margin-bottom: 20px;
        }
      }

      &.is_short {
        margin-right: 20px;
      }
    }
  }

  &.is_change_spec {
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    max-width: 590px;
    &:after {
      content: '';
      width: 7px;
      height: 11px;
      background: url(/media/images/arrow.svg) no-repeat;
      position: absolute;
      right: 15px;
      top: 17px;
      transform: rotate(90deg);
    }
  }
  &.is_active {
    .label_point {
      display: block !important;
    }
  }
  &.is_phone_edit {
    .form_cell__inner {
      margin-right: 20px;
    }
  }
  &.is_checkbox {
    position: relative;
    top: -18px;
    margin-bottom: 51px;
  }
  &.is_subscriptions {
    .check_item {
      margin-bottom: 27px;
    }
    .form_cell.is_label {
      padding-top: 0;
    }
    @include mobile {
      margin-bottom: 35px;
    }
  }
  &.is_master {
    margin: 15px 0;
  }
  &.is_company {
    margin: 15px 0;
  }
  &.is_order_btns {
    margin-top: 78px;
    @include mobile {
      margin-top: 0;
    }
  }

  &.status {
    @include mobile {
      height: 230px;
      position: relative;
    }
  }

  &.has-error {
    input,
    textarea {
      border: 1px solid $colorError;
    }

    .form_error {
      position: absolute;
      bottom: -36px;
      width: 100%;
      padding-top: 6px;
      display: block;
    }
  }
}

.has-error {
  input,
  textarea,
  .form__select.selectize-control {
    border-color: $colorError;
  }

  .form_error {
    position: absolute;
    top: 100%;
    width: 100%;
    display: block;
    line-height: 18px;
  }
}

.form_cell {
  display: flex;
  position: relative;
  width: calc(100% - 25px);

  @include tablet {
    width: auto;
  }
  @include mobile {
    width: auto;
  }

  &.is_label {
    width: 162px;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    padding-top: 10px;

    &.is_centered {
      justify-content: center;
      padding-top: 0;
    }

    @include desktop {
      width: 130px;
    }
    @include small-desktop {
      width: 120px;
    }

    @include tablet {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: auto;
    }
    @include mobile {
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: auto;
    }
  }

  &.is_field {
    display: flex;
    &.is_card {
      flex-wrap: wrap;
      width: 510px;

      .form_cell__inner {
        margin-right: 38px;
      }

      @include desktop {
        width: 410px;
      }

      @include tablet {
        flex-direction: column;
        width: auto;
      }
      @include mobile {
        flex-direction: column;
        width: auto;
      }
    }
    &.is_clmn {
      flex-direction: column;
      .form__btns {
        text-align: left;
      }
    }
    &.is_order_phone {
      flex-direction: column;
    }
  }

  &.is_distance {
    @include mobile {
      width: 220px;
    }
  }

  &.is_about {
    @include mobile {
      flex-direction: row;
      align-items: center;
    }
  }

  &.is_phone_confirm {
    .form_error {
      line-height: normal;
      padding-top: 8px;
    }
    @include mobile {
      flex-direction: column;
      .form_cell__inner {
        margin-bottom: 20px;
      }
    }
    @include tablet {
      flex-direction: column;
      .form_cell__inner {
        margin-bottom: 20px;
      }
    }
  }
}

.order_create_wrapper {
  .form_cell {
    &.is_label {
      width: 162px;
      align-items: flex-start;
      flex-direction: column;
      position: relative;
      padding-top: 10px;

      &.is_centered {
        justify-content: center;
        padding-top: 0;
        @include mobile {
          justify-content: flex-start;
        }
      }
    }
  }
}

.form_cell__inner {
  display: flex;
  flex-direction: column;
  &.is_long {
    width: 480px;
    @include desktop {
      width: 410px;
    }
    @include mobile {
      width: 100%;
    }
  }
  &.is_short {
    width: 200px;
    @include mobile {
      width: 100%;
    }
  }
  &.is_error {
    input, textarea {
      border: 1px solid $colorError;
    }
  }
  &.is_success {
    position: relative;

    &:after {
      content: '';
      width: 11px;
      height: 9px;
      background: url(/media/images/is_confirmed.svg) no-repeat;
      position: absolute;
      right: 18px;
      top: 21px;
    }
  }
  &.is_change_spec {
    position: relative;
    width: 100%;
    &:after {
      content: '';
      width: 7px;
      height: 11px;
      background: url(/media/images/arrow.svg) no-repeat;
      position: absolute;
      right: 15px;
      top: 17px;
      transform: rotate(90deg);
    }
    .select_spec_items {
      position: static;
    }
    .select_spec {
      position: absolute;
      padding-bottom: 25px;
      max-height: 320px;
      width: 100%;
      overflow-y: auto;
    }
    .select_spec_categories__item {
      span {
        &.is_active {
          &:after {
            background: none;
          }
        }
      }
    }
  }
}

.form__label {
  font-family: $fontOpenSans;
  font-size: 14px;
  color: $colorBlack;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  &.is_photo {
    margin-top: 31%;
    @include tablet {
      margin-top: 0;
    }
    @include mobile {
      margin-top: 0;
    }
  }
  @include desktop {
    font-size: 13px;
  }
}

.form__text {
  border: 1px solid $lineColor;
  border-radius: 4px;
  height: 44px;
  font-size: 14px;
  color: $colorBlack;
  letter-spacing: 0;
  line-height: 30px;
  padding-left: 15px;
  width: 100%;
  transition: border-color $animationTime $animationFunc;
  background: #fff;
  text-overflow: ellipsis;
  &::-ms-clear {
    display: none;
  }

  @include mobile {
    width: auto;
  }

  &:focus {
    border: 1px solid $colorYellow;
    outline-style: none;
    box-shadow: none;
  }

  &.is_textarea {
    max-width: 530px;
    min-width: 320px;
    height: 80px;
    min-height: 80px;
    max-height: 117px;
    @include mobile {
      width: 100%;
      height: 117px;
      max-width: none;
      min-width: 0;
      max-height: none;
      min-height: 0;
      padding-left: 15px;
      resize: none;
    }
  }

  &.is_order_textarea {
    resize: none;
    height: 224px;
    line-height: 20px;
    padding: 12px 17px 15px;
  }

  &.is_change_spec {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 25px;

    @include tablet {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 25px;
    }
    @include mobile {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 25px;
    }
  }

  &.is_complex_number {
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  &.is_dropdown_selector {
    padding: 0;
  }

  &::-webkit-input-placeholder {
    color: $placeholderColor;
  }

  &:-moz-placeholder,
  &::-moz-placeholder {
    color: $placeholderColor;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $placeholderColor;
  }
}

.form_newsletter {
  .is_dropdown_selector {
    border: none;
  }
}

.form__btns {
  @include mobile {
    max-width: none;
    width: 100%;
  }
  @include tablet {
    max-width: none;
    width: 100%;
  }
  &.is_spec {
    max-width: none;
    text-align: left;
  }
}

.btn--submit {
  background: $colorYellow;
  border-radius: 21px;
  font-family: $fontOpenSansSemibold;
  font-size: 14px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  border: 0;
  padding: 12px 41px 13px;
  width: 100%;
  margin: 0 0 20px;
  text-align: center;

  &.btn_is_big {
    padding: 18px 41px 19px;
    margin: 0;
    font-size: 18px;
    color: inherit;
    letter-spacing: -.2px;
    border-radius: 29px;
  }

  &.btn_is_delete {
    background: #ff3d47;
    color: #fff;
    margin-left: 20px;
  }

  &:hover {
    color: #555659;
    background-color: #ffd966;
  }

  @include for-tablet-portrait-up {
    margin: 0 0 20px;
    width: 240px;
  }

}

.btn--submit:disabled,
.btn--submit[disabled] {
  background: $btnSecondaryColor;
  color: $placeholderColor;
  cursor: not-allowed;
}

.btn--reset {
  font-family: $fontOpenSans;
  font-size: 14px;
  color: $textSecondaryColor;
  letter-spacing: 0;
  line-height: 20px;
  border: 1px solid $btnSecondaryColor;
  border-radius: 21px;
  padding: 12px 41px 13px;

  &.is_load_photo {
    background: $colorWhite;
    text-align: center;
  }

  @include tablet {
    margin: 0;
    width: 100%;
  }

  @include mobile {
    margin: 0;
    width: 100%;
  }

  &:hover {
    border-color: $btnSecondaryHoverColor;
    color: #555659;
    background-color: $btnSecondaryHoverColor;
  }
}

.btn--save {
  font-family: $fontOpenSansSemibold;
  font-size: 14px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  line-height: 20px;
  border: 1px solid $btnSecondaryColor;
  border-radius: 21px;
  padding: 11px 48px;
  background: $btnSecondaryColor;
  margin-left: 20px;
  height: 42px;
  @include mobile {
    margin: 0;
    color: $colorBlack;
  }
}

.btn--save:disabled,
.btn--save[disabled] {
  background: $btnSecondaryColor;
  color: $colorWhite;
  cursor: not-allowed;
}

.label_point {
  border-radius: 3px;
  font-family: $fontOpenSansBold;
  font-size: 12px;
  letter-spacing: 0;
  padding: 0 7px 0 8px;
  display: inline-flex;
  color: $textPrimaryColor;
  border: 2px solid $colorYellow;
  white-space: nowrap;
  height: 24px;
  align-items: center;

  @include tablet {
    position: static;
    margin-left: 8px;
  }

  @include mobile {
    position: static;
    margin-left: 8px;
  }
}

.form__message {
  font-family: $fontOpenSans;
  font-size: 12px;
  color: $textSecondaryColor;
  letter-spacing: 0;
  line-height: 30px;
  @include mobile {
    line-height: 20px;
    margin-top: 5px;
  }
}

.form__dimension {
  font-family: $fontOpenSans;
  font-size: 14px;
  color: $colorBlack;
  letter-spacing: 0;
  line-height: 30px;
  padding-left: 10px;
  padding-top: 7px;
  display: flex;
  @include mobile {
    display: block;
    line-height: 42px;
    padding: 0;
    margin: 0;
    position: relative;
    left: 8px;
  }
}

.form-section {
  margin-top: 39px;
  margin-bottom: 60px;

  &:first-child {
    margin-bottom: 0;
  }

  .section_title {
    margin-bottom: 30px;
    text-align: center;
    text-transform: none;
    letter-spacing: 0;
  }

  &.profile_data {
    margin-bottom: 35px;

    .is_checkbox {
      &:last-of-type {
        margin-bottom: 0;
      }
      @include mobile {
        margin-bottom: 0;
      }
    }

    @include mobile {
      margin-bottom: 15px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @include mobile {
    margin-top: 0;
    &:first-child {
      margin-top: 0;
    }
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  @include for-tablet-portrait-up {
    .section_title {
      //font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 35px;
    }
  }

  @include for-tablet-landscape-up {
    .section_title {
      margin-bottom: 20px;
      //font-weight: bold;
      font-size: 24px;
      line-height: 33px;
    }
  }

  @media (max-width: 599px) {
    .section_title {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.form_btns {
  display: flex;
  &.is_delete {
    margin-bottom: 60px;
    @include mobile {
      flex-direction: column;

      .is_label {
        display: none;
      }
    }
  }
  &.is_order_btns {
    .form_cell {
      &.is_label {
        @include mobile {
          display: none;
        }
      }
    }
  }

  .button {
    padding: 0 23px;
  }
}

.input_upload_file {
  display: none;
}

.form_error {
  font-family: $fontOpenSans;
  font-size: 12px;
  color: $colorError;
  letter-spacing: 0;
  line-height: 30px;
  .form__text {
    border: 1px solid #fe253c;
  }
}

.form_item__title {
  font-family: $fontOpenSansBold;
  font-size: 18px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  line-height: 25px;
  margin-right: 17px;
}

.form_item__top {
  display: flex;
  @include mobile {
    margin-bottom: 9px;
  }
}

.form_item__input {
  &.is_long {
    width: 320px;
    @include mobile {
      width: 100%;
    }
  }
  &.is_short {
    width: 200px;
    @include mobile {
      width: 100%;
    }
  }
  &.is_textarea {
    margin-top: 14px;
    width: 520px;
    @include mobile {
      width: auto;
    }
  }
  &.is_error {
    input {
      border: 1px solid $colorRed;
    }

    textarea {
      border: 1px solid $colorRed;
    }
  }

  &.is_success {
    position: relative;

    &:after {
      content: '';
      width: 11px;
      height: 9px;
      background: url(/media/images/is_confirmed.svg) no-repeat;
      position: absolute;
      right: 18px;
      top: 21px;
    }
  }
  @include mobile {
    input {
      width: 100%;
    }
  }
}

.form_item__text {
  margin: 7px 0 14px;
  max-width: 580px;
  line-height: 22px;
}

.form_item {
  margin-bottom: 45px;
  position: relative;
  &.is_bottom_btn {
    display: flex;
    .btn--submit {
      height: 44px;
      margin-right: 20px;
    }
    @include mobile {
      flex-direction: column;

      .btn--submit {
        margin-right: 0;
      }
    }
    @include tablet {
      flex-direction: column;
    }
  }
  &.is_create_account {
    margin-top: 60px;
    @include mobile {
      margin: 0;
      position: relative;
    }
  }
  &.is_visible {
    display: block;
  }
  &.is_name {
    .form_item__title {
      margin-bottom: 15px;
    }
  }
}

.form_msg {
  font-family: $fontOpenSans;
  font-size: 12px;
  color: $textPrimaryColor;
  letter-spacing: 0;
  line-height: 30px;
  &.is_send_again {
    cursor: pointer;
  }
}

.profile_form {
  padding-left: 19px;

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;

    .form_row {
      margin-bottom: 15px;

      &:first-child {
        margin-bottom: 30px;
      }
    }

    .form_row.is_master,
    .form_row.is_company {
      margin-top: 0;
    }

    .form_row.is_checkbox {
      top: 0;
      margin-bottom: 18px;
    }

    .form_cell.is_label {
      padding-top: 0;
    }
  }
}

.form_message_light {
  font-family: $fontOpenSans;
  font-size: 11px;
  color: $textSecondaryColor;
  letter-spacing: 0;
  line-height: 15px;
  max-width: 590px;
  text-align: center;
  //min-width: 280px;
  padding-top: 45px;
  margin-bottom: 40px;

  p {
    text-align: center;
  }
}

.profile_create_form {
  @include desktop-up {
    .form_item {
      &:first-of-type {
        margin-top: 45px;
      }
    }
  }
}

.form__select {
  &.selectize-control {
    border: 1px solid $lineColor;
    border-radius: 4px;
    height: 44px;
    font-size: 14px;
    color: $colorBlack;
    letter-spacing: 0;
    line-height: 30px;
    width: 100%;
    transition: border-color $animationTime $animationFunc;
    background: #fff;
    text-overflow: ellipsis;
  }

  .selectize-dropdown-header {
    border-radius: 0;
  }
  .selectize-input {
    border: none;
    padding: 0 0 0 15px;
    border-radius: 4px;
    height: 100%;
    line-height: 44px;
    box-shadow: none;
    font-size: 14px;
  }
  .selectize-input.focus {
    outline-style: none;
    box-shadow: none;
  }
  .selectize-input.dropdown-active {
    border-radius: 0;
  }
  .selectize-input.dropdown-active::before {
    display: none;
  }
  .selectize-dropdown {
    border-radius: 0 0 3px 3px;
    box-shadow: none;
    border: 1px solid $lineColor;
    left: -1px !important;
    width: calc(100% + 2px) !important;
  }
  .selectize-dropdown [data-selectable] .highlight {
    font-weight: bold;
    background: $bgColor;
    border-radius: 0;
  }
  .selectize-dropdown [data-selectable],
  .selectize-dropdown .optgroup-header {
    padding: 5px 15px;
  }

  .selectize-dropdown .active {
    color: $colorBlack;
    background: $bgColor;
  }
  .selectize-dropdown-content {
    overflow-y: initial;
    overflow-x: initial;
    max-height: 200px;
    background: $colorWhite;
    box-shadow: 0 4px 8px 0 rgba(38, 38, 38, .2);
    border-radius: 5px;
    /*-webkit-overflow-scrolling: touch;*/
  }
  &.selectize-control.single .selectize-input.input-active,
  &.selectize-control.single .selectize-input.input-active input {
    cursor: default;
  }

  &.selectize-control.single .selectize-input:after {
    width: 7px;
    height: 11px;
    background: url(/media/images/arrow.svg) no-repeat;
    right: 15px;
    top: 17px;
    transform: rotate(90deg);
    z-index: 0;
    margin-top: 0;
    border: none;
  }
  &.selectize-control.single .selectize-input.dropdown-active:after {
    transform: rotate(-90deg);
  }
  .selectize-input .item:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 104%;
    height: 100%;
    z-index: 1;
  }
}

.field-replyform-description {
  position: relative;
  .form__message {
    position: absolute;
    bottom: -46px;
    display: block;
  }

  &.has-error {
    .form__message {
      display: none;
    }
  }
  &.has-success {
    .form__message {
      display: block;
    }
  }
}

.form_button_next {
  appearance: none;
  border: none;
  background-color: #FFD24D;
  border-radius: 21px;
  width: 200px;
  height: 44px;
  padding: 0 20px;
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  color: #000000;
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  &:after {
    position: absolute;
    content: '';
    display: block;
    height: 28px;
    width: 28px;
    right: 7px;
    background-color: #ffffff;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    content: '';
    width: 10px;
    height: 15px;
    background: url(/media/images/arrow-right.png) no-repeat;
    position: absolute;
    right: 13px;
    top: 17px;
    z-index: 1;
  }

  &:hover {
    color: #000000;
    background-color: #FFD24D;
    opacity: 0.7;
  }
}

.form_button_prev {
    appearance: none;
    border: none;
    background-color: #FFD24D;
    border-radius: 21px;
    width: 200px;
    height: 44px;
    padding: 0 0 0 130px;
    position: relative;
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    color: #000000;
    margin-left: 20px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;

    &:after{
        position: absolute;
        content: '';
        display: block;
        height: 28px;
        width: 28px;
        left: 7px;
        background-color: #ffffff;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
    }

    &:before {
        content: '';
        width: 10px;
        height: 15px;
        background: url(/media/images/arrow-right.png) no-repeat;
        position: absolute;
        left: 13px;
        top: 14px;
        z-index: 1;
        transform: rotate(180deg);
    }

    &:hover {
        color: #000000;
        background-color: #FFD24D;
        opacity: 0.7;
    }
}

.form_button_close {
  display: block;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: rgba(#C6C7CE, 0.3);
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  color: #000000;

  svg {
    position: absolute;
    z-index: 2;
    height: 13px;
    width: 13px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    position: absolute;
    content: '';
    display: block;
    height: 28px;
    width: 28px;
    right: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 50%;
  }

  &:hover {
    color: #000000;
    background-color: rgba(#C6C7CE, 0.3);
    opacity: 0.7;
  }
}

.form_button_unnecessary {
  background-color: rgba(#787984, 0.3);
}

.form_row.form_row_nowrap {
  align-items: flex-end;
}

.order_create_wrapper .form__label.no-opacity {
  opacity: 1;
}

feedback__body {
  margin: unset;
}

.feedback__image {
  margin-top: 50px;

  .form_feedback_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 900px) {
      min-height: 440px;
    }
    @media (max-width: 600px) {
      min-height: 385px;
    }
    @media (max-width: 599px) {
      min-height: 350px;
      margin-bottom: 10px;
      padding: 0 6px;
    }

    .form_row--empty {
      .feedback_controls__drag_container {
        transition: height 0.3s ease-in-out;
        height: 225px;
      }

      @media (max-width: 900px) {
        max-width: 409px;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
      }
    }

    .form_row--none-empty {
      height: auto;

      .form_cell--label {
        height: 0 !important;
        padding-top: 0 !important;
      }

      .feedback_controls__drag_container {
        flex-direction: row;
        transition: height 0.3s ease-in-out;
        height: 94px;

        .portfolio_controls__btn,
        .feedback_file_input__text {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #262626;
          margin-top: 0;
          margin-left: 9px;
        }
      }

      .ic--upload {
        height: 34px;
        width: 55px;
      }

      @media (max-width: 900px) {
        max-width: 409px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
      }

      @media (max-width: 599px) {
        margin-top: 60px;
      }

    }

    .form_cell--field {
      width: 100%;
      flex-flow: row wrap;
    }
    .feedback_file_input {
      margin-top: 0;
      width: 100%;
    }
  }
}

.feedback_controls__drag_container {
  height: 225px;
  width: 100%;
  border: 3px dashed #C6C7CE;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: border-color $animationTime $animationFunc;

  &.is-dragover {
    background-color: $bgColor;
  }

  &.is_error {
    border-color: $colorRed;
  }

  &:hover {
    border-color: $inputBorder;
  }

  @include for-desktop-up {
    display: flex;
  }
}

.feedback_mobile_btn {
  display: flex;
  max-width: 409px;
  height: 127px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 210, 77, 0.12);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;

  span {
    display: block;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #262626;
    margin-top: 10px;
  }

  .button__attach {
    display: flex;
    position: relative;
  }

  .ic--clip {
    position: absolute;
    top: 10px;
    left: 13px;
  }

  @include for-desktop-up {
    display: none;
  }
}

feedback__body {
  margin: unset;
}

.feedback__review {

  .form_review_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 900px) {
      min-height: 440px;
    }
    @media (max-width: 600px) {
      min-height: 385px;
    }
    @media (max-width: 599px) {
      min-height: 350px;
      margin-bottom: 10px;
      padding: 0 6px;
    }

    .form_row--none-empty {

      .form_cell--label {
        height: 0 !important;
        padding-top: 0 !important;
      }

      .review_controls__drag_container {
        flex-direction: row;
        transition: height 0.3s ease-in-out;
        height: 94px;
        text-align: center;

        .review_controls__btn {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #262626;
          margin-top: 0;
        }
      }

      .ic--upload {
        height: 34px;
        width: 55px;
      }

      @media (max-width: 900px) {
        max-width: 409px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
      }

      @media (max-width: 599px) {
        margin-top: 60px;
      }

    }

    .form_cell--field {
      width: 100%;
      flex-flow: row wrap;
    }
    .feedback_file_input {
      margin-top: 0;
      width: 100%;
    }
  }
}

.feedback_controls__drag_container {
  height: 225px;
  width: 100%;
  border: 3px dashed #C6C7CE;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: border-color $animationTime $animationFunc;

  &.is-dragover {
    background-color: $bgColor;
  }

  &.is_error {
    border-color: $colorRed;
  }

  &:hover {
    border-color: $inputBorder;
  }

  @include for-desktop-up {
    display: flex;
  }
}

.feedback_mobile_btn {
  display: flex;
  max-width: 409px;
  height: 127px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 210, 77, 0.12);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;

  span {
    display: block;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #262626;
    margin-top: 10px;
  }

  .button__attach {
    display: flex;
    position: relative;
  }

  .ic--clip {
    position: absolute;
    top: 10px;
    left: 13px;
  }

  @include for-desktop-up {
    display: none;
  }
}

.feedback__review {

  .form_review_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 900px) {
      min-height: 440px;
    }
    @media (max-width: 600px) {
      min-height: 385px;
    }
    @media (max-width: 599px) {
      min-height: 350px;
      margin-bottom: 10px;
      padding: 0 6px;
    }

    .form_row--none-empty {

      .form_cell--label {
        height: 0 !important;
        padding-top: 0 !important;
      }

      .review_controls__drag_container {
        flex-direction: row;
        transition: height 0.3s ease-in-out;
        height: 94px;
        text-align: center;

        .review_controls__btn {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #262626;
          margin-top: 0;
        }
      }

      .ic--upload {
        height: 34px;
        width: 55px;
        margin-left: 10px;
      }

      @media (max-width: 900px) {
        max-width: 409px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
      }

      @media (max-width: 599px) {
        margin-top: 60px;
      }

    }

    .form_cell--field {
      width: 100%;
      flex-flow: row wrap;
    }
    .review_file_input {
      margin-top: 0;
      width: 100%;
    }
    .has-error .form_error {
      position: unset;
    }
  }
}

.review_controls__drag_container {
  height: 225px;
  width: 100%;
  border: 3px dashed #C6C7CE;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: border-color $animationTime $animationFunc;

  &.is-dragover {
    background-color: $bgColor;
  }

  &.is_error {
    border-color: $colorRed;
  }

  &:hover {
    border-color: $inputBorder;
  }

  @include for-desktop-up {
    display: flex;
  }
}

.review_mobile_btn {
  display: flex;
  max-width: 409px;
  height: 127px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 210, 77, 0.12);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;

  span {
    display: block;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #262626;
    margin-top: 10px;
  }

  .button__attach {
    display: flex;
    position: relative;
  }

  .ic--clip {
    position: absolute;
    top: 10px;
    left: 13px;
  }

  @include for-desktop-up {
    display: none;
  }
}
