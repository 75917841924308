@import 'base/variables';

.teaser {
    border-bottom: 1px solid $lineColor;
    position: relative;
    background: linear-gradient(to bottom, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, .82) 51%, rgba(243, 244, 244, 1) 62%, rgba(237, 238, 240, 1) 80%);
    @include mobile {
        border-top: none;
    }
}

.teaser__container {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    height: 620px;
    @include mobile {
        height: 290px;
    }
}

.teaser__col {
    width: 967px;
    margin: 0 auto;

    @include mobile {
        padding-left: 20px;
        padding-top: 36px;
        width: auto;
    }
}

.teaser__slogan {
    font-family: $fontOpenSansBold;
    font-size: 44px;
    color: #262626;
    letter-spacing: 0;
    line-height: 60px;
    padding-top: 153px;

    @include mobile {
        font-size: 24px;
        color: #262626;
        letter-spacing: 0;
        line-height: 30px;
        padding-top: 0;
        position: relative;
        max-width: 100px;
        z-index: 2;
    }

    @include tablet {
        padding-left: 20px;
        max-width: 250px;
    }
}

.teaser__title {
    font-family: $fontOpenSansExtraBoldItalic;
    font-size: 160px;
    color: $btnPrimaryColor;
    letter-spacing: 4px;
    line-height: 155px;
    text-transform: uppercase;
    position: relative;
    padding-top: 105px;
    z-index: 2;

    @include mobile {
        width: 250px;
        font-family: $fontOpenSansExtraBoldItalic;
        font-size: 48px;
        color: $btnPrimaryColor;
        letter-spacing: 1.2px;
        line-height: 50px;
    }
}

.teaser__read_more {
    font-family: $fontOpenSansBold;
    font-size: 12px;
    color: #262626;
    letter-spacing: 2px;
    line-height: 25px;
    padding-top: 78px;
    text-transform: uppercase;
    position: relative;

    //&:before {
    //    content: '';
    //    width: 8px;
    //    height: 5px;
    //    background: url(../images/down.png) no-repeat;
    //    position: absolute;
    //    bottom: 9px;
    //    left: -16px;
    //}

    @include mobile {
        padding-top: 20px;
        width: 200px;
        padding-bottom: 25px;
        z-index: 2;
        &:before {
            content: '';
            display: none;
            left: 0;
        }
    }

    @include tablet {
        padding-left: 20px;
        max-width: 200px;
        &:before {
            content: '';
            display: none;
            left: 0;
        }
    }
}

.teaser__img {
    width: 580px;
    height: 580px;
    background: url(../images/rating-head.png) no-repeat;
    background-position: center bottom;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 38%;
    z-index: 1;

    @include mobile {
        width: 190px;
        height: 259px;
        background: url(../images/rating-head-600.png) no-repeat;
        background-position: center bottom;
        background-size: contain;
        position: absolute;
        left: auto;
        right: 0;
        z-index: 1;
    }
}

.teaser__rating {
    font-family: $fontOpenSansExtrabold;
    font-size: 228px;
    color: $btnPrimaryColor;
    letter-spacing: -10px;
    position: absolute;
    right: 103px;
    top: 124px;
    display: block;
    z-index: 0;
    line-height: 1;

    span {
        font-size: 114px;
        position: relative;
        top: -74px;
    }

    @include desktop {
        font-size: 185px;
        right: 40px;
        span {
            font-size: 90px;
        }
    }

    @include mobile {
        display: none;
    }

    @include tablet {
        display: none;
    }
}

.points_info__top {
    padding-top: 115px;
    text-align: center;
    @include mobile {
        padding-top: 55px;
    }
}

.points_info__bottom {
    text-align: center;
    border-top: 1px solid $lineColor;
    padding-top: 120px;
    padding-bottom: 100px;
    @include mobile {
        padding-bottom: 45px;
        border-top: none;
        padding-top: 0;
    }
}

.section__container_inner {
    max-width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 20px;
}

.section__sub_title {
    font-family: $fontOpenSansBold;
    font-size: 12px;
    color: #262626;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 25px;

    @include mobile {
        font-size: 11px;
        span {
            display: none;
        }
    }
}

.section__title {
    font-family: $fontOpenSansBold;
    font-size: 32px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 50px;
    line-height: 30px;

    @include mobile {
        font-family: $fontOpenSansBold;
        font-size: 20px;
        color: $textPrimaryColor;
        letter-spacing: 0;
        margin-bottom: 26px;
    }
}

.section_text {
    text-align: center;
    max-width: 650px;
    margin: 0 auto;
    font-family: $fontOpenSans;
    font-size: 18px;
    color: #262626;
    letter-spacing: 0;
    line-height: 30px;

    i {
        font-family: $fontOpenSansBold;
        color: #262626;
        font-size: 20px;
        letter-spacing: 0;
        font-style: normal;
    }

    &.is_top_text {
        margin-bottom: 51px;
    }

    &.is_sort_section {
        margin-bottom: 79px;
    }

    &--bold {
        font-weight: bold;
        //text-align: left;
    }

    &--left {
        margin-bottom: 10px;
        //text-align: left;
    }

    @include mobile {
        font-family: $fontOpenSans;
        font-size: 14px;
        color: #262626;
        letter-spacing: 0;
        line-height: 25px;
        padding: 0 20px;
        &.is_sort_section {
            margin-bottom: 56px;
        }

        i {
            font-family: $fontOpenSansBold;
            color: #262626;
            font-size: 20px;
            letter-spacing: 0;
            font-style: normal;
        }

    }
}

.points_info_item__title {
    font-family: $fontOpenSans;
    font-size: 18px;
    color: #262626;
    letter-spacing: 0;
    margin-bottom: 22px;
    @include mobile {
        font-size: 14px;
    }
}

.points_info_item__data {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: #919599;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 20px;
}

.points_info_item__score {
    font-family: $fontOpenSansBold;
    font-size: 12px;
    color: $textPrimaryColor;
    letter-spacing: 2px;
    line-height: 20px;
}

.points_info_items__middle {
    display: flex;
    justify-content: space-between;
}

.points_info_items__col {
    &.is_middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.is_aside {
        .points_info_item {
            &:first-child {
                padding-bottom: 54px;
            }
        }
    }
}

.points_info_items {
    margin-bottom: 115px;
}

.points_info_items__top {
    display: flex;
    justify-content: center;
    .points_info_item {
        margin-left: 23px;
        width: 49.9%;
        text-align: center;
        //margin-left: 16px;
    }
}

.points_info_items__bottom {
    .points_info_item {
        margin-left: 27px;
        //margin-left: 0;
    }
}

.points_info_item__middle_img {
    width: 240px;
    height: 266px;
    background: url(../images/ic_score_10.png) no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
        width: 160px;
        height: 180px;
        background: url(../images/hexagon.png) no-repeat;
        margin-top: 63px;
        margin-left: 0 !important;
    }
}

.points_info_item__middle_number {
    font-family: $fontOpenSansExtrabold;
    font-size: 96px;
    line-height: normal;
    color: $colorWhite;
    text-indent: -7px;
}

.points_info_item__number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $btnPrimaryColor;
    font-family: $fontOpenSansBold;
    font-size: 14px;
    color: #262626;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 22px;

    @include mobile {
        margin-left: auto;
        margin-right: auto;
    }
}

.points_info_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
        display: none;
        margin-bottom: 60px;
    }
}

.points_info__max_text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: #919599;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 30px;
    margin-top: 70px;
}

.points_info_item__img {
    margin-bottom: 30px;
}

.points_info__max_value {
    font-family: $fontOpenSansExtrabold;
    font-size: 128px;
    color: $btnPrimaryColor;
}

.buy_items {
    position: relative;
    border-top: 1px solid $lineColor;
    background: url(../images/rating-bonuses.png) no-repeat;
    background-size: contain;
    background-position: bottom -165px left;

    .section__container_inner {
        padding: 63px 0 92px;
    }

    @media (max-width: 1920px) {
        background-position: bottom -165px left calc(50% - 500px);
    }

    @include mobile {
        background: url(../images/rating-bonuses-600.png) no-repeat;
        background-position: bottom left;

        .section__container_inner {
            padding: 0 0 100px;
        }
        border-top: none;
        &:after {
            content: '';
            display: none;
        }
    }

    @include tablet {
        &:after {
            content: '';
            display: none;
        }
    }
}

.sort_how_to {
    padding-top: 117px;
    padding-bottom: 140px;
    border-top: 1px solid $lineColor;

    @include mobile {
        border-top: none;
        padding-top: 59px;
        padding-bottom: 59px;
        .section__sub_title {
            margin-bottom: 13px;
        }
    }
}

.sort_how_to__bottom_container {
    display: flex;
    justify-content: space-between;
    margin-top: 59px;

    @include mobile {
        margin-top: 40px;
    }

    @include tablet {
        padding: 0 20px;
    }
}

.sort_how_to__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
        padding-bottom: 59px;
    }
}

.sort_how_to__title {
    font-family: $fontOpenSans;
    font-size: 18px;
    color: #262626;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 25px;
    @include mobile {
        font-size: 14px;
    }
}

.sort_how_to__text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: #919599;
    letter-spacing: 0;
    line-height: 20px;
}

.sort_how_to__number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $btnPrimaryColor;
    line-height: 32px;
    font-family: $fontOpenSansSemibold;
    font-size: 14px;
    color: #262626;
    letter-spacing: 0;
    margin-bottom: 23px;
    @include mobile {
        margin-left: auto;
        margin-right: auto;
    }
}

.section__part_title {
    font-family: $fontOpenSansBold;
    font-size: 20px;
    color: #262626;
    letter-spacing: 0;
    @include mobile {
        font-size: 16px;
        &.is_value {
            margin-top: 88px;
            font-size: 16px;
        }
    }
}

.section__inner {
    text-align: center;
    max-width: 960px;
    margin: 0 auto;
}

.new_rating_count {
    padding-top: 100px;
    padding-bottom: 116px;
    background: $bgColor;
    @include mobile {
        padding-top: 52px;
        padding-bottom: 59px;
    }
}

.points_info_order__container {
    display: flex;
    justify-content: space-between;
    margin: 71px 0 69px;
    @include tablet {
        padding: 0 20px;
    }
}

.points_info_order__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    left: 16px;

    &:last-child {
        &:after {
            display: none;
            width: 164px;
            height: 2px;
            background: $lineColor;
            position: absolute;
        }
    }

    &:after {
        content: '';
        width: 164px;
        height: 2px;
        background: $lineColor;
        position: absolute;
        right: -167px;
        top: 14px;
    }

    @include tablet {
        &:after {
            display: none;
        }
    }

    @include mobile {
        margin-bottom: 58px;
        left: 0;

        &:after {
            display: none;
        }
    }
}

.points_info_order__number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    line-height: 32px;
    background: $btnPrimaryColor;
    font-family: $fontOpenSansSemibold;
    font-size: 14px;
    color: #262626;
    letter-spacing: 0;
    margin-bottom: 17px;
    @include mobile {
        margin-left: auto;
        margin-right: auto;
    }
}

.points_info_items__mobile {
    @include mobile {
        .points_info_item {
            display: block;
            margin-bottom: 61px;
        }
    }
}

.points_info_order__title {
    font-family: $fontOpenSans;
    font-size: 18px;
    color: #262626;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 17px;
    @include mobile {
        font-size: 14px;
    }
}

.points_info_order__text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: #919599;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 20px;

    &.is_not_first {
        margin-bottom: 40px;
    }
}

.points_info_order__score {
    font-family: $fontOpenSansBold;
    font-size: 12px;
    color: #262626;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
}

.new_rating_count__text {
    max-width: 640px;
    font-family: $fontOpenSans;
    font-size: 24px;
    color: #262626;
    letter-spacing: 0;
    line-height: 40px;
    margin: 0 auto 115px;

    @include mobile {
        padding: 0 20px;
        font-family: $fontOpenSans;
        font-size: 14px;
        color: #262626;
        letter-spacing: 0;
        line-height: 25px;
        margin-bottom: 73px;
    }
}

.new_rating_count__title {
    font-family: $fontOpenSans;
    font-size: 18px;
    color: #262626;
    letter-spacing: 0;
    margin-bottom: 32px;
    @include mobile {
        margin-bottom: 21px;
        font-size: 14px;
    }
}

.new_rating_count__teaser_text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: #919599;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 40px;

    @include mobile {
        margin-bottom: 34px;
    }
}

.new_rating_count__btn {
    padding: 0 76px;
}

.grade__wrapper {
    max-width: 480px;
    margin: 44px auto 0;

    @include mobile {
        padding: 0 20px;
        margin: 32px auto 0;
    }
}

.grade_table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 38px;

    .grade_table__title {
        border-bottom: none;
    }

    .grade_table__value {
        border-bottom: none;
    }

    .with_border {
        td {
            border-bottom: solid 1px $lineColor;
            padding-bottom: 20px;
        }

        & + tr {
            td {
                padding-top: 18px;
            }
        }
    }
}

.grade_table_stars__list {
    margin-right: 20px;
    width: 90px;
    height: 18px;
    position: relative;
    display: flex;
}

.grade_table__title {
    font-size: 12px;
    text-align: left;
    color: $textSecondaryColor;
    font-weight: normal;
    border-bottom: solid 1px $lineColor;
    padding: 10px 0 16px;

    &:not(:first-child) {
        text-align: right;
    }

    &:last-child {
        width: 160px;
    }

    &.grade_table__value--grade {

        @include mobile {
            display: none;
        }
    }
}

.grade_table__value {
    font-size: 14px;
    line-height: 1;
    text-align: left;
    color: $textPrimaryColor;
    border-bottom: solid 1px $lineColor;

    &:last-child {
        text-align: right;
        font-weight: bold;
    }

    @include tablet-up {
        line-height: 3.57;
    }

    &.grade_table__value--grade {
        text-align: right;
        @include mobile {
            display: none;
        }
    }

    &.grade_table__value--ratio {
        span {
            font-family: $fontOpenSansBold;
            font-size: 20px;
            color: #262626;
            letter-spacing: 0;
            line-height: 50px;
            position: relative;
            display: block;
            text-align: right;

            &:before {
                content: 'X';
                font-family: $fontOpenSansBold;
                font-size: 12px;
                color: #262626;
                letter-spacing: 0;
                padding-right: 20px;
                position: absolute;
                top: 1px;
                left: 10px;
            }
        }
    }
}

.grade_categories {
    display: flex;
    margin: 76px 0;
    justify-content: center;

    @include tablet {
        padding: 0 20px;
    }

    @include mobile {
        flex-wrap: wrap;
        min-width: 280px;
        justify-content: flex-start;
        margin: 53px auto;
        padding: 0 20px;
    }
}

.grade_categories__item {
    width: 160px;
    text-align: center;
    padding: 27px 0;

    @include mobile {
        width: 50%;
    }

    &.is_no_card {
        background-color: $colorWhite;
    }

    &.is_specialist {
        background-color: $colorGreyFirst;
    }

    &.is_master {
        background-color: $colorGreySecond;
    }

    &.is_profi {
        background-color: $colorGreyThird;
    }

    &.is_expert {
        background-color: $colorGreyFourth;
    }

    &.is_superexpert {
        background-color: $btnPrimaryColor;
    }
}

.grade_categories__type {
    font-family: $fontOpenSansBold;
    font-size: 12px;
    color: #262626;
    letter-spacing: 2px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.grade_categories__budget {
    margin-bottom: 31px;
}

.grade_categories__ratio_percentage {
    margin-bottom: 25px;
}

.grade_categories__ratio_value {
    font-family: $fontOpenSansBold;
    font-size: 20px;
    color: #262626;
    letter-spacing: 0;

    span {
        &:first-child {
            padding-right: 5px;
            position: relative;
            top: -1px;
            font-size: 14px;
        }
    }
}
