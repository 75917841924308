.profile_create_page {
    .form_cell.is_field.is_card {
        width: 510px;
        @include mobile {
            width: auto;
        }
        .form_cell__inner {
            margin-right: 38px;
        }
        .form__text.is_textarea {
            max-width: 530px;
        }
    }
    .card_info {
        line-height: 44px;
    }
    .profile_content {
        @include mobile {
            display: none;
        }
    }
    .form__text {
        &.is_textarea {
            @include tablet {
                max-width: 400px;
            }
        }
    }


    .is_company {
        &.is_visible {
            display: inline;
            &.form_item {
                display: block;
            }
        }
    }
}

.profile_create {
    margin: 45px 59px 0 80px;

    .portfolio_controls {
        padding: 0;
        &.is_old {
            position: relative;
            right: 27px;
            @include mobile {
                position: relative;
                padding-left: 20px;
                top: -20px;
            }
        }
    }
    .form__dimension {
        @include mobile {
            position: absolute;
            margin: 0;
            padding: 0;
            top: 66px;
            left: 54%;
        }
    }
    @include tablet {
        margin-left: 30px;
    }

    @include mobile {
        margin: 0 19px 0 21px;
        padding-top: 33px;
    }
}

.profile_create__title {
    font-family: $fontOpenSansBold;
    font-size: 36px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 46px;
    @include tablet {
        font-size: 24px;
    }
    @include mobile {
        font-family: $fontOpenSansBold;
        font-size: 18px;
        color: $textPrimaryColor;
        letter-spacing: 0;
        line-height: 25px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 25px;
    }
}

.profile_create__descr {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: #a5a8ad;
    letter-spacing: 0;
    line-height: 24px;
    max-width: 600px;
}

.profile_create_spec__sect_inner {
    display: flex;
    margin-bottom: 14px;
    @include desktop-less {
        flex-direction: column;
    }
    @include mobile {
        flex-direction: column;
    }
}

.profile_create_spec__container {
    padding-top: 46px;
    @include mobile {
        padding-top: 26px;
    }
}
.profile_create_spec__sect {
    .section_title {
        margin-bottom: 26px;
        cursor: pointer;
        position: relative;
        display: inline-flex;
        padding-left: 38px;
        align-items: center;

        &:before {
            content: '';
            width: 7px;
            height: 11px;
            background: url(/media/images/arrow.svg) no-repeat;
            position: absolute;
            left: 10px;
            top: 3px;
            transform: rotate(90deg);
        }

        &.is_active {
            &:before {
                transform: rotate(270deg);
            }
        }
    }
}

.profile_create_spec__quantity {
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 2px;
    color: $inputBorder;
    margin-left: 20px;
}
.profile_create_spec__col {
    margin-right: 111px;
    @include desktop {
        margin-right: 60px;
    }
    @include mobile {
        margin-right: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}

.profile_create_spec__item_wrap {
    margin-bottom: 22px;
    position: relative;
    width: 292px;
    display: flex;
    justify-content: space-between;
    min-height: 26px;

    &:before {
        content: '';
        background-color: $inputBorder;
        top: -8px;
        left: -8px;
        bottom: -8px;
        right: -8px;
        opacity: 0;
        border-radius: 6px;
        position: absolute;
    }

    &:hover {
        &:before {
            opacity: 0.2;
        }
    }

    .subscribe_button {
        display: none;
        &:not(.is_subscribed) {
            background-color: transparent;
        }
    }

    &.is_spec_checked {
        .subscribe_button {
            display: inline-flex;
        }
    }
}

.profile_create_spec__text {
    float: right;
    padding-left: 17px;
    position: relative;
    top: 4px;
    user-select: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    @media (max-width: 900px) {
        padding-left: 30px;
    }

    @media (max-width: 599px) {
        font-size: 10px;
        line-height: 14px;
    }

    //@include tablet {
    //    padding-left: 40px;
    //}
    //@include mobile {
    //    padding-left: 35px;
    //}
}

.user_info__photo {
    &.is_stub {
        border: 0;
        box-shadow: none;
    }
}

.profile_create_form {
    .user_photo_upload {
        vertical-align: top;

        @include mobile {
            display: flex;
        }
    }

    .file-preview {
        border: none;
        margin: 0;
        padding: 0;
    }
}

.profile_create {
    @include mobile {
        padding-top: 0;
        .portfolio_controls {
            margin-top: 0;
            .mt40 {
                margin-top: 0;
            }
        }
    }

}
