* {
    box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

article,
details,
section,
summary,
aside,
main,
menu,
nav,
figcaption,
figure,
footer,
header {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

body {
    //min-width: 1020px;
    margin: 0;
    line-height: 20px;
    color: #000;
    background: #fff;
    font-family: $fontOpenSans;

    &:before {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1000;
        content: url('/media/images/prev_btn.png') url('/media/images/next_btn.png');
    }
}

html {
    text-size-adjust: 100%;
}

a {
    color: #263238;
    transition-property: color, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    text-decoration: none;
    display: inline-block;
    background-color: transparent;

    @include desktop-up {
        &:hover {
            background-color: transparent;
            color: #fd2d2c;
            border-color: #fd2d2c;
            outline: 0;
        }
    }


    &:active {
        outline: 0;
    }
}

abbr[title] {
    border-bottom: 1px dotted;
}

dfn {
    font-style: italic;
}

mark {
    background: #ff0;
    color: #000;
}

b,
strong {
    font-weight: bold;
}

h1 {
    font-size: 2em;
    margin: .67em 0;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    cursor: pointer;
    appearance: button;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    height: auto;
}

input[type='search'] {
    appearance: textfield;
    box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    appearance: none;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: .35em .625em .75em;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    vertical-align: middle;
}

td,
th {
    padding: 0;
}

ol,
ul {
    list-style: none;
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
}

a img {
    border: none;
}

.span-reg__label {
    width: 160px;
}

.span-reg__options {
    width: 247px;
}

.span-reg__options--photo {
    width: 548px;
}

.row--empty {
    display: none;
}

.row {
    zoom: 1;
    padding-left: 14px;
    padding-right: 13px;
}

.row:before,
.row:after {
    content: '';
    display: table;
}

.row:after {
    clear: both;
}

.row .row {
    margin-left: -27px;
    margin-right: -27px;
}

.span--u-info {
    width: 297px;
}

.span1 {
    width: 96px;
    margin-left: 13px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.span2 {
    width: 218px;
    margin-left: 10px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.span3 {
    width: 342px;
    margin-left: 11px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.span4 {
    width: 465px;
    margin-left: 9px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.span5 {
    width: 588px;
    margin-left: 13px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.span6 {
    width: 711px;
    margin-left: 13px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.span7 {
    width: 834px;
    margin-left: 13px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.span8 {
    width: 957px;
    margin-left: 13px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.span {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin-left: 13px;
    margin-right: 14px;
}

.span2--with-paddings {
    width: 246px;
    margin-left: 0;
    margin-right: -13px;
}

.span--center {
    margin-left: auto;
    margin-right: auto;
}

.span-reg__dropdown {
    width: 265px;
    margin-right: 0;
}

.span-reg__dropdown .dropdown-skill-select {
    margin-left: 3px;
}

.span-reg__dropdown + .span-reg__dropdown {
    margin-left: 4px;
}

.form-span {
    width: 529px;
    padding-right: 32px;
    padding-left: 32px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

.login-form {
    border-left: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 32px;
}

.wrapper {
    width: 1020px;
    position: relative;
    margin: 0 auto 30px;
    flex-grow: 1;
    flex-shrink: 0;
}

.link__text {
    transition-property: border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    border-bottom: 2px solid transparent;
}

.hor-pad {
    padding-left: 14px;
    padding-right: 13px;
}

.link__text--gray {
    color: #808080;
    border-bottom: 2px solid transparent;
    transition-property: color, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.fancybox-skin {
    padding: 0 !important;
}

.fancybox-title {
    padding-left: 10px;
    padding-right: 10px;
}

//.link__text--footer-gray {
//    color: #a3a6a8;
//    border-bottom: none !important;
//}

.link__text--yellow {
    border-bottom: 2px solid #ffda4a;
}

.link__text--bord {
    border-bottom: 2px solid #eceff1;
}

.link__text--lightgray {
    color: #808080;
    border-bottom: 2px solid #eceff1;
}

.link {
    cursor: pointer;
    transition-property: color, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.link:hover {
    background-color: transparent;
    color: #fd2d2c;
    border-color: #fd2d2c;
}

.link:hover .header__link--yellow {
    border-color: #fd2d2c;
}

.link:hover .header__link--orange {
    border-color: #fd2d2c;
}

.link:hover .link__text {
    color: #fd2d2c;
    border-bottom: 2px solid #fd2d2c;
}

.link:hover .b-bottom--text {
    color: #fd2d2c;
    border-bottom: 2px solid #fd2d2c;
}

.link:hover .link__text--lightgray {
    border-bottom: 2px solid #eceff1;
}

.link:hover .link__text--gray {
    border-color: #808080;
    color: #808080;
}

.link:hover .link__text--footer-gray {
    border-color: #a3a6a8;
}

.link:hover .link__text--black {
    color: #263238;
    border-bottom: 2px solid transparent;
}

.link--with-border {
    border-bottom: 2px solid #fd2d2c;
}

.yellow-button {
    border: 2px solid #feda4a;
    background-color: #feda4a;
    padding: 10px 20px;
    border-radius: 70px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    color: black;
}

.yellow-button:hover {
    font-family: 'Ubuntu', sans-serif;
    background-color: transparent;
    color: black;
    border: 2px solid #feda4a;
}

.subscribe-title {
    font-style: inherit;
    font-family: inherit;
}

.unsubscribe_btn {
    width: 300px;
}

a.btn {
    color: #fff;
}

.btn {
    color: #fff;
    outline: none;
    background-color: #fd2d2c;
    display: inline-block !important;
    box-sizing: border-box;
    text-transform: uppercase;
    border: none;
    appearance: none !important;
    cursor: pointer;
    transition-property: background, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    padding: 15px 25px;
}

.btn:hover {
    background-color: #fc9801;
}

.btn:active {
    background-color: #fd2d2c;
}

.btn:disabled {
    background-color: #dcdedf !important;
    cursor: not-allowed !important;
}

.bl--line {
    display: inline;
}

.bl--inline {
    display: inline-block;
    white-space: normal;
    //vertical-align: top;
}

.btn--full-width {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.btn--round {
    border-radius: 2px;
    padding: 10px 12px !important;
}

.btn--transp {
    background-color: transparent;
    border: 2px solid #eceff1;
    color: #eceff1;
    border-radius: 2px;
}

.btn--disable {
    background-color: #dcdedf !important;
    cursor: not-allowed !important;
}

.left-cont {
    float: left;
}

.right-cont {
    float: right;
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    content: '';
    display: table;
}

.clearfix:after {
    clear: both;
}

.f-label {
    font-size: 24px;
    line-height: 28px;
    font-weight: 100;
}

.f-left {
    text-align: left;
}

.f-center {
    text-align: center;
}

.f-right {
    text-align: right;
}

.f-vcenter {
    vertical-align: middle;
}

.f-vbottom {
    vertical-align: bottom;
}

.f-big {
    font-size: 36px;
    line-height: 40px;
}

.f-med {
    font-size: 18px;
    line-height: 24px;
}

.f-medb {
    font-size: 20px;
    line-height: 24px;
}

.f-giga {
    font-size: 64px;
    line-height: 74px;
}

.f-med-bid {
    font-size: 24px;
    line-height: 28px;
}

.f-smal {
    font-size: 16px;
    line-height: 18px;
}

.f-supsmal {
    font-size: 12px;
    line-height: 13px;
}

.f-light {
    font-weight: 100;
}

.light-text {
    color: #515b60;
    font-weight: 100;
}

.f-med-width {
    font-weight: 800;
}

.mt4 {
    margin-top: 4px;
}

.mt8 {
    margin-top: 8px;
}

.mt16 {
    margin-top: 16px;
}

.mt24 {
    margin-top: 24px;
}

.mt32 {
    margin-top: 32px;
}

.mt40 {
    margin-top: 40px;
}

.mt48 {
    margin-top: 48px;
}

.mt56 {
    margin-top: 56px;
}

.mt72 {
    margin-top: 72px;
}

.mt80 {
    margin-top: 80px;
}

.pr8 {
    padding-right: 8px;
}

.ml0 {
    margin-left: 0;
}

.ml8 {
    margin-left: 8px;
}

.ml16 {
    margin-left: 16px;
}

.ml24 {
    margin-left: 24px;
}

.ml38 {
    margin-left: 38px;
}

.ml48 {
    margin-left: 48px;
}

.help-text {
    color: #808080;
}

.help-text--inlblock {
    display: inline-block;
}

.darkgrey-text {
    color: #35464e;
}

.b-top {
    border-top: 1px solid #eceff1;
}

.b-bottom {
    padding-bottom: 32px;
    border-bottom: 1px solid #eceff1;
}

.b-bottom--8 {
    padding-bottom: 8px;
    border-bottom: 1px solid #eceff1;
}

.b-bottom--40 {
    padding-bottom: 40px;
    border-bottom: 1px solid #eceff1;
}

.b-bottom--24 {
    padding-bottom: 32px;
    border-bottom: 1px solid #eceff1;
}

.b-bottom--16 {
    padding-bottom: 16px;
    border-bottom: 1px solid #eceff1;
}

.b-bottom--text {
    border-bottom: 2px solid #eceff1;
    transition-property: color, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.b-bottom--text:hover {
    border-color: inherit;
}

.petrovich__img {
    display: inline;
}

.petrovich__img--right-space {
    margin-right: 8px;
}

.hbln {
    display: none;
}

.ovh {
    word-wrap: break-word;
    overflow: hidden;
}

.nowrap {
    white-space: nowrap;
}

.nspace {
    white-space: normal;
    //word-break: break-all;
}

.aside-login {
    line-height: 24px;
    width: 240px;
}

.dynamic-img {
    position: relative;
    float: left;
    margin-right: 24px;
    margin-top: 16px;
}

.dynamic-img__wrap {
    width: 96px;
    height: 96px;
    overflow: hidden;
}

.input--dynamic-gal_array {
    display: none;
}

.dynamic-img__img {
    width: 96px;
}

.btn--remove {
    z-index: 10;
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #fd2d2c;
    border-radius: 24px;
    top: -12px;
    right: -12px;
    background-position: center;
    background-image: url(/media/images/x.png);
    background-repeat: no-repeat;
    cursor: pointer;
}

a.btn-find-m {
    background-color: #fff;
    color: #fd2d2c !important;
    border: 2px solid #fd2d2c;
    border-radius: 3px;
}

a.btn-find-m:hover {
    color: #fff !important;
    background-color: #fd2d2c;
}

.image_tag--wrapper {
    position: relative;
    width: 220px;
    height: 165px;
    overflow: hidden;
}

.dn {
    display: none;
}

.master_links {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user__ava__img--empty-32 {
    border: 1px solid #808080;
    width: 30px;
    height: 30px;
    display: inline-block;
}

#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url(/media/images/fancybox_loading.gif);
    background-position: center center;
    background-repeat: no-repeat;
}

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
    background-image: url(/media/images/fancybox_sprite.png);
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    #fancybox-loading,
    .fancybox-close,
    .fancybox-prev span,
    .fancybox-next span {
        background-image: url(/media/images/fancybox_sprite@2x.png);
        background-size: 44px 152px; /* The size of the normal image, half the size of the hi-res image */
    }
    #fancybox-loading div {
        background-image: url(/media/images/fancybox_loading@2x.gif);
        background-size: 24px 24px;
    }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    #fancybox-loading,
    .fancybox-close,
    .fancybox-prev span,
    .fancybox-next span {
        background-image: url(/media/images/fancybox_sprite@2x.png);
        background-size: 44px 152px; /* The size of the normal image, half the size of the hi-res image */
    }
    #fancybox-loading div {
        background-image: url(/media/images/fancybox_loading@2x.gif);
        background-size: 24px 24px;
    }
}

.table_link {
    cursor: pointer;
}

.time {
    padding-left: 20px;
    background-image: url(/media/images/time.png);
    background-repeat: no-repeat;
    background-position: 0 2px;
}

.round-ava {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: top;
    border-radius: 24px;
    overflow: hidden;
}

.round-ava--img {
    width: 24px;
    height: 24px;
}

.album__u__text {
    display: inline-block;
    width: 185px;
    margin-left: 8px;
}

.top-ava {
    width: 32px;
    height: 32px;
    display: inline-block;
    overflow: hidden;
}

.top-ava__img {
    width: 32px;
    height: 32px;
}

.txt-ovh {
    text-overflow: ellipsis;
}

.fav__text {
    width: 140px;
    display: inline-block;
    vertical-align: top;
}

.fav__value {
    margin-left: 10px;
    width: 20px;
    display: inline-block;
    vertical-align: top;
}

.notice--info {
    background-color: #fbde42;
    color: #97841c;
}

.notice--info .notice__close {
    background-color: #e0c42d;
    border: 2px solid #fbde42;
}

.notice--alert {
    background-color: #fd2d2c;
    color: #fff;
}

.notice--alert:before {
    background-position: 0 -44px;
}

.notice--alert .notice__close {
    background-color: #c82221;
    border: 2px solid #fd2d2c;
}

.accept_request {
    padding: 24px 16px;
}

.notice__wrapper {
    position: fixed;
    width: 400px;
    top: 130px;
    right: 50px;
    z-index: 10000;
}

.notice + .notice {
    margin-top: 32px;
}

.notice {
    position: relative;
    margin-bottom: 0;
    width: 400px;
    box-sizing: border-box;
    padding-left: 78px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .75);
    border-top: 1px solid #d3d3d3;
    padding-right: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    z-index: 1000000;
    min-height: 76px;
    transition-property: opacity, margin;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.notice:before {
    content: '';
    width: 44px;
    height: 44px;
    background-image: url(/media/images/notice_icons.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 14px;
    top: 16px;
}

.notice--close {
    opacity: 0;
    filter: alpha(opacity=0);
}

.notice__close {
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 40px;
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
    line-height: 24px;
    color: #fff;
    box-shadow: -3px 3px 0 0 transparent;
    transition-property: box-shadow;
    transition-duration: .6s;
    transition-timing-function: ease-in-out;
    text-shadow: 0 0 2px rgba(0, 0, 0, .4);
}

.notice__close:before {
    content: '\2716';
}

.notice__close:hover {
    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, .75);
}

.pos_r {
    position: relative;
}

.proposal_count {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-color: #fd2d2c;
    color: #fff;
    text-align: center;
    line-height: 24px;
}

.proposal_count--grey {
    background-color: #808080;
}

.add_request--hidden {
    display: none !important;
}

.feedback__pop-up {
    display: none;
}

.text-cell {
    width: 170px;
}

.login-row {
    position: relative;
}

.login-error {
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 30px;
    width: 110px;
}

.ellipsis {
    display: block;
    word-wrap: break-word;
    height: 60px;
    overflow: hidden;
}

.ellipsis:before {
    content: '';
    float: left;
    width: 5px;
    height: 60px;
}

.ellipsis > *:first-child {
    float: right;
    width: 100%;
    margin-left: -5px;
}

.ellipsis:after {
    content: '\02026';
    box-sizing: content-box;
    float: right;
    position: relative;
    top: -22px;
    left: 100%;
    width: 3em;
    margin-left: -3em;
    padding-right: 5px;
    text-align: right;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white));
    background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
}

.comment.opened {
    height: auto;
}

.comment__full {
    height: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;
}

.orange-icon {
    width: 108px;
    height: 108px;
    background-image: url(/media/images/orangeIcons.png);
    background-repeat: no-repeat;
}

.error-text {
    background-color: #fd2d2c;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    text-align: left;
    zoom: 1;
}

.error-text:before,
.error-text:after {
    content: '';
    display: table;
}

.error-text:after {
    clear: both;
}

.error-text:before {
    content: 'Ошибка: ';
    display: inline;
}

.orange-icon__eq1 {
    background-position: 0 -108px;
}

.orange-icon__eq2 {
    background-position: 0 -216px;
}

.orange-list {
    counter-reset: li;
}

.orange-list__itm {
    position: relative;
    padding-left: 24px;
}

.orange-list__itm:before {
    content: counter(li) '.';
    counter-increment: li;
    position: absolute;
    top: 0;
    left: 0;
}

@keyframes noticeShow {
    0% {
        visibility: visible;
        opacity: 1;
        filter: none;
    }
    50% {
        visibility: visible;
        opacity: .9;
        filter: alpha(opacity=90);
    }
    100% {
        opacity: 0;
        filter: alpha(opacity=0);
        display: none;
    }
}

li {
    list-style: none;
}

.grid_test_fixed {
    position: fixed;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, .08);
}

.grid_test_fixed .span1 {
    height: 2000px;
    background-color: rgba(0, 0, 0, .3);
}

.body--main {
    padding-top: 40px;
    border-top: 1px solid #eceff1;
}

.we-do__img {
    width: 142px;
    height: 32px;
    display: inline;
}

.find-m {
    width: 575px;
}

.find-m:hover .find-m__input {
    border-color: #fc9801;
}

.find-m:hover .find-m__btn {
    background-color: #fc9801;
}

.find-m--short {
    width: 405px;
}

.find-m--short .dropdown--red {
    width: 230px;
}

.find-m__input {
    display: block;
    width: 370px;
    height: 42px;
    border: 2px solid #fd2d2c;
    box-sizing: border-box;
    vertical-align: bottom;
    transition-property: border, box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    padding-left: 10px;
    padding-right: 30px;
    outline: none;
    box-shadow: inset 0 0 0 1px transparent;
}

.find-m__input:focus {
    border-color: #fc9801;
    box-shadow: inset 0 0 0 1px #fc9801;
}

.find-m__btn {
    height: 42px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 42px;
}

.today {
    padding-left: 45px;
    position: relative;
}

.today__itm {
    zoom: 1;
    animation: todayShow .2s ease-out;
    position: relative;
    transition-property: height, margin, opacity, visibility, font-size, left;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.today__itm:before,
.today__itm:after {
    content: '';
    display: table;
}

.today__itm:after {
    clear: both;
}

.today__itm + .today__itm {
    margin-top: 8px;
}

.today__itm__text {
    display: inline-block;
    width: 200px;
    margin-left: 10px;
}

.today__list {
    height: 200px;
    overflow: hidden;
    padding-bottom: 16px;
    border-bottom: 1px solid #eceff1;
}

.today__itm--hidden {
    height: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    font-size: 0;
}

.service_promo {
    border-top: 1px solid #eceff1;
    padding-bottom: 30px;
    padding-top: 30px;
    font-size: 20px;
}

.service_promo--red {
    position: relative;
    background-color: #fd2d2c;
    color: #fff;
    display: inline-block;
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 4px;
    font-style: italic;
}

.service_promo--red:before {
    background-image: url(/media/images/redline.png);
    content: '';
    position: absolute;
    width: 89px;
    height: 91px;
    top: -101px;
    left: 48px;
}

.service_promo--login .service_promo--red:before {
    display: none;
}

@keyframes todayShow {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
        height: 0;
        visibility: hidden;
        font-size: 0;
    }
}

.main-menu {
    line-height: 100px;
    text-align: center;
    border-top: 1px solid #eceff1;
    border-bottom: 1px solid #eceff1;
}

.main-menu__list {
    display: inline-block;
}

.main-menu__item {
    display: inline-block;
}

.main-menu__item + .main-menu__item {
    margin-left: 40px;
}

.main-menu__item--active .main-menu__link {
    color: #fd2d2c;
}

.main-menu__link {
    line-height: normal;
    font-size: 16px;
}

.main-menu--without-border {
    border-bottom: none;
}

.statistic {
    background-color: #ffda4a;
}

.statistic__list {
    margin-top: 32px;
    margin-bottom: 32px;
}

.statistic__item {
    line-height: normal;
    min-height: 332px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #fff;
    position: relative;
    padding-top: 55px;
    box-sizing: border-box;
    text-align: left;
    margin-left: 10px;
    padding-bottom: 20px;
}

.statistic__item:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 20px;
    background-repeat: no-repeat;
}

.statistic__wall:before {
    background-image: url(/media/images/statisticSprite.png);
    width: 40px;
    height: 32px;
    background-position: 0 0;
}

.statistic__brush:before {
    background-image: url(/media/images/statisticSprite.png);
    width: 32px;
    height: 40px;
    background-position: -40px 0;
}

.statistic__home:before {
    background-image: url(/media/images/statisticSprite.png);
    width: 32px;
    height: 33px;
    background-position: -73px 0;
}

.statistic__chat:before {
    background-image: url(/media/images/statisticSprite.png);
    width: 34px;
    height: 40px;
    background-position: -105px 0;
}

.statistic__value {
    font-size: 48px;
    font-weight: 100;
}

.statistic__label {
    font-size: 18px;
    line-height: 21px;
    height: 45px;
}

.statistic__points {
    margin-top: 24px;
}

.statistic__point {
    display: block;
}

.statistic__point + .statistic__point {
    margin-top: 8px;
}

.statistic_point__name {
    width: 130px;
    display: inline-block;
    color: #808080;
}

.statistic_point__value {
    float: right;
    color: #263238;
}

.baner {
    position: relative;
    vertical-align: middle;
}

.baner-240 {
    width: 219px;
    height: 400px;
}

.baner-240 img {
    width: 219px;
}

.baner--link {
    position: relative;
}

.baner--link--768 {
    margin-top: 32px;
}

.baner--link--240 {
    margin-top: 16px;
}

.listing__menu__label {
    color: #808080;
    display: inline-block;
    line-height: 32px;
}

.round-switcher,
.listing__menu__itm {
    line-height: 28px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 40px;
    cursor: pointer;
    border: 2px solid transparent;
    transition-property: border, color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;

    /* Redesign filter only on user page. To do so the class .is_user had been added */
    &.is_user {
        border: none;
        color: #858c90;
        padding: 0;
        position: relative;
        padding-bottom: 3px;
        margin-left: -7px;
        &:before {
            content: '';
            position: absolute;
            right: -22px;
            top: 6px;
            width: 12px;
            height: 8px;
            background-image: url(/media/images/ic_sort.png);
        }
        &:hover {
            border: none;
            color: #fd2d2c;
            &:before {
                content: "";
                position: absolute;
                right: -22px;
                top: 6px;
                width: 12px;
                height: 8px;
                background-image: url("/media/images/sort-down.png");
            }
        }
    }
    &.is_rating {
        border: none;
        color: #858c90;
        padding: 0;
        position: relative;
        padding-bottom: 3px;
        margin-left: -7px;
        &:before {
            content: "";
            position: absolute;
            right: 12px;
            top: 11px;
            width: 12px;
            height: 8px;
            background-image: url(/media/images/ic_sort.png);
        }
        &:hover {
            border: none;
            color: #fd2d2c;
            &:before {
                content: '';
                position: absolute;
                right: 12px;
                top: 11px;
                width: 12px;
                height: 8px;
                background-image: url(/media/images/sort-down.png);
            }
        }
    }
}

.round-switcher:hover,
.listing__menu__itm:hover {
    border: 2px solid #fd2d2c;
    color: #fd2d2c;
}

.round-switcher--active,
.listing__menu__itm--active {
    border: 2px solid #fd2d2c;
    color: #fd2d2c;
    cursor: default;
    position: relative;
    &.is_user {
        border: none;
        position: relative;
        color: #fd2d2c;
        &:hover {
            &:before {
                content: '';
                position: absolute;
                right: -24px;
                top: 6px;
                width: 12px;
                height: 8px;
                background-image: url("/media/images/sort-up.png");
            }
        }

        &:before {
            content: "";
            position: absolute;
            right: -24px;
            top: 6px;
            width: 12px;
            height: 8px;
            background-image: url("/media/images/sort-up.png");
        }
    }
    &.is_rating {
        border: none;
        position: relative;
        color: #fd2d2c;

        &:hover {
            &:before {
                content: "";
                position: absolute;
                right: 12px;
                top: 11px;
                width: 12px;
                height: 8px;
                background-image: url(/media/images/sort-up.png);
            }
        }

        &:before {
            content: '';
            position: absolute;
            right: 12px;
            top: 11px;
            width: 12px;
            height: 8px;
            background-image: url(/media/images/sort-up.png);
        }
    }

}

.listing__menu__itm {
    display: inline-block;
    margin-left: 6px;
    line-height: 18px;
}

.sort_itm.table__cell:hover {
    color: #fd2d2c;
    cursor: pointer;
}

.listing__menu__itm--active {
    cursor: pointer;
    padding-right: 24px;
}

.listing__menu__itm--active:before {
    content: '';
    position: absolute;
    right: 7px;
    top: 11px;
    width: 12px;
    height: 8px;
    background-image: url(/media/images/sort-up.png);
}

.listing__menu__itm--active.table__cell {
    padding-right: 20px;
    color: #fd2d2c;
    border: none;
    border-bottom: 1px solid #dddfe0;
}

.listing__menu__itm--active.table__cell:before {
    top: 26px;
    right: 16px;
}

.listing__menu__itm--active--minus:before {
    background-image: url(/media/images/sort-down.png) !important;
}

.listing__columns li {
    margin-left: 10px;
}

.listing__column__itm {
    zoom: 1;
}

.listing__column__itm:before,
.listing__column__itm:after {
    content: '';
    display: table;
}

.listing__column__itm:after {
    clear: both;
}

.listing__column__ava {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}

.listing__column__text {
    display: inline-block;
    color: #808080;
    vertical-align: middle;
}

.listing__column__itm__left {
    display: inline-block;
    width: 177px;
    margin-right: 7px;
    vertical-align: middle;
    white-space: nowrap;
}

.listing__column__itm__right {
    display: inline-block;
    vertical-align: middle;
}

.listing__columns--round .listing__column__ava {
    width: 32px;
    height: 32px;
    border-radius: 20px;
    overflow: hidden;
    border-width: 0 !important;
}

.listing__columns--round .listing__column__ava img {
    width: 32px;
    height: 32px;
}

.listing__columns--round .listing__column__text {
    line-height: 32px;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.listing__columns--round .listing__column__value {
    width: 30px;
    text-overflow: ellipsis;
    line-height: 32px;
}

.listing__column__itm + .listing__column__itm {
    margin-top: 8px;
}

.listing__column__list {
    margin-top: 16px;
}

.listing__baner {
    position: relative;
}

.listing__baner__img--wrapper {
    width: 220px;
    height: 165px;
    overflow: hidden;
    position: relative;
}

.listing__baner__img-count {
    position: absolute;
    bottom: 8px;
    right: 16px;
    color: #fff;
}

.listing__baner__val {
    background-color: #ffda4a;
    position: absolute;
    width: 48px;
    height: 48px;
    top: 0;
    right: 16px;
    text-align: center;
    line-height: 48px;
    font-size: 24px;
    color: #263238;
}

.listing .table__cell--header {
    border-top: 1px solid #dddfe0;
}

.listing__paginator {
    display: block;
    position: relative;
    text-align: center;
}

.listing__paginator__itm {
    display: inline-block;
    border-bottom: 2px solid transparent;
    padding-left: 3px;
    padding-right: 3px;
    transition-property: border, color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}

.listing__paginator__itm:hover {
    border-bottom: 2px solid #fd2d2c;
}

.listing__paginator__itm + .listing__paginator__itm {
    margin-left: 3px;
}

.listing__paginator__itm--active {
    color: #adadad;
    border-bottom: 2px solid #fd2d2c;
    cursor: default;
}

.listing__paginator__btn {
    position: absolute;
    color: #adadad;
}

.listing__paginator__btn:before {
    top: 3px;
    content: '';
    position: absolute;
    width: 8px;
    height: 13px;
}

.listing__paginator__btn--active {
    color: #000;
    cursor: pointer;
}

.listing__paginator__btn--active .listing__paginator__b-text {
    transition-property: border, color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    border-bottom: 2px solid transparent;
}

.listing__paginator__btn--active .listing__paginator__b-text:hover {
    border-bottom: 2px solid #fd2d2c;
}

.listing__paginator__itm--etc {
    border: none;
    cursor: default;
}

.listing__paginator__itm--etc:hover {
    border: none;
}

.listing__paginator__btn--prev {
    display: inline-block;
    left: 0;
    padding-left: 23px;
}

.listing__paginator__btn--prev:before {
    left: 0;
    background-image: url(/media/images/rotatorLeft.png);
}

.listing__paginator__btn--prev.listing__paginator__btn--active:before {
    background-image: url(/media/images/rotatorLeftActive.png);
}

.listing__paginator__btn--next {
    display: inline-block;
    right: 0;
    padding-right: 23px;
}

.listing__paginator__btn--next:before {
    right: 0;
    background-image: url(/media/images/rotatorRigth.png);
}

.listing__paginator__btn--next.listing__paginator__btn--active:before {
    background-image: url(/media/images/rotatorRigthActive.png);
}

.listing__paginator__list {
    display: inline-block;
    text-align: center;
}

.listing__filter .input__wrapper {
    cursor: pointer;
    border-radius: 4px;
}

.catalog-rotator {
    white-space: nowrap;
    padding-top: 32px;
    position: relative;
}

.catalog-rotator__itm {
    white-space: normal;
}

.catalog-rotator__wrapper {
    overflow: hidden;
}

.catalog-rotator__product {
    margin-bottom: 16px;
    width: 140px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.catalog-rotator__img {
    max-width: 140px;
    max-height: 120px;
}

.catalog-rotator__text {
    width: 180px;
    height: 74px;
    overflow: hidden;
    color: #808080;
    margin-bottom: 8px;
    text-overflow: ellipsis;
}

.catalog-rotator__price {
    color: #35464e;
    font-weight: 100;
    font-size: 20px;
    line-height: 24px;
}

.catalog-rotator__nav {
    position: absolute;
    right: 30px;
    top: 30px;
}

.catalog-rotator__nav__itm {
    float: left;
    opacity: .16;
    filter: alpha(opacity=16);
    width: 8px;
    height: 13px;
}

.catalog-rotator__nav__itm + .catalog-rotator__nav__itm {
    margin-left: 16px;
}

.catalog-rotator__list {
    transition-property: margin;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
}

.catalog-rotator__left {
    background-image: url(/media/images/rotatorLeftActive.png);
}

.catalog-rotator__right {
    background-image: url(/media/images/rotatorRigthActive.png);
}

.catalog-rotator__nav__itm--active {
    opacity: 1;
    filter: none;
    cursor: pointer;
}

.test_grid {
    position: fixed;
    height: 100%;
    left: 0;
    right: 0;
    top: 250px;
    z-index: 100000;
}

.test_span {
    height: 100%;
    background-color: rgba(253, 45, 44, .2);
}

.accept_request_import-row {
    margin-bottom: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid #eceff1;
}

html {
    min-height: 100%;
    position: relative;
}

//.footer__white {
//    color: #e4e5e6;
//}
//
//.footer__gray {
//    color: #a3a6a8;
//}
//
.subscribe__container {
    width: 340px;
}

.subscribe {
    height: 200px;
}

.subscribe__container--active .subscribe__input {
    width: 210px;
}

.subscribe__container--active .subscribe__btn {
    width: 117px;
    visibility: visible;
    height: 100%;
}

.subscribe__btn {
    height: 0;
    width: 0;
    border: none;
    padding: 0;
    color: #fd2d2c;
    background-color: transparent;
    visibility: hidden;
    outline: none;
    overflow: hidden;
    transition-property: width, visibility;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.subscribe__form {
    width: 100%;
    border: 1px solid #a3a6a8;
    height: 42px;
    box-sizing: border-box;
    overflow: hidden;
    transition-property: border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.subscribe__form--done {
    border-color: rgba(255, 255, 255, .08);
}

.subscribe__form--done .subscribe__input {
    display: none;
}

.subscribe__form--done .subscribe__btn {
    display: none;
}

.subscribe__form--done .subscribe__form--greetings {
    visibility: visible;
}

.subscribe__form--greetings {
    visibility: hidden;
    color: #fff;
    line-height: 42px;
    margin-left: 16px;
    transition-property: visibility;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.subscribe__input {
    display: inline-block;
    height: 100%;
    border: none;
    width: 100%;
    vertical-align: middle !important;
    box-sizing: border-box;
    background-color: transparent;
    transition-property: width;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    color: #a3a6a8;
    outline: none;
    padding-left: 8px;
    padding-right: 8px;
}

//.copyright {
//    display: inline-block;
//}

.site-map {
    display: inline-block;
}

.site-map__item {
    display: inline-block;
    margin-left: 24px;
}

.header {
    padding-top: 32px;
    line-height: 32px;
    margin-bottom: 32px;
}

.header--devise {
    padding-top: 16px;
    height: 116px;
}

.top_line--right {
    margin-top: 25px;
}

.logo__text--divese {
    display: block;
    padding-right: 0;
    text-align: center;
    margin-top: 16px;
}

.logo__text {
    white-space: nowrap;
    font-size: 11px;
    line-height: 12px;
    text-align: left;
    margin-left: 5px;
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    vertical-align: middle;
}

.dropdown {
    position: relative;
    display: block;
    vertical-align: bottom;

}

.dropdown:hover .dropdown__wrapper {
    border-color: #fc9801;
    box-shadow: inset 0 0 0 1px #fc9801;
}

.dropdown--full-width {
    width: 100%;
}

.dropdown--full-width .dropdown__wrapper {
    width: inherit;
}

.dropdown--red {
    width: 276px;
}

.dropdown--red .input__wrapper,
.dropdown--red .dropdown__wrapper {
    border-color: #fd2d2c;
}

.dropdown--red:hover .input__wrapper,
.dropdown--red:hover .dropdown__wrapper {
    border-color: #fc9801;
}

.dropdown__wrapper {
    border: 2px solid #dddfe0;
    height: 42px;
    width: 370px;
    box-sizing: border-box;
    line-height: 42px;
    position: relative;
    background-color: #fff;
    transition-property: border, box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    box-shadow: inset 0 0 0 1px transparent;
}

.dropdown__wrapper:before {
    position: absolute;
    content: '';
    width: 13px;
    height: 8px;
    top: 17px;
    right: 15px;
    background-image: url(/media/images/arrow.png);
}

.dropdown--autocomplete {
    position: relative;
}

.dropdown--autocomplete .input__wrapper {
    padding-right: 30px;
    color: #263238;
}

.dropdown--autocomplete:before {
    z-index: 10;
    position: absolute;
    content: '';
    width: 13px;
    height: 8px;
    top: 17px;
    right: 15px;
    background-image: url(/media/images/arrow.png);
}

.dropdown__value {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.dropdown__value--default {
    color: #808080;
}

.fancybox-inner .dropdown__list {
    max-height: 210px;
    overflow: auto;
}

.dropdown--active .dropdown__wrapper {
    z-index: 11;
}

.dropdown--active .dropdown__list {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .8);
    visibility: visible;
    opacity: 1;
    filter: none;
}

.dropdown__list {
    position: absolute;
    background-color: #fff;
    opacity: 0;
    filter: alpha(opacity=0);
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
    visibility: hidden;
    top: 100%;
    border-top: none;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 0 0 transparent;
    z-index: 200;
    transition-property: box-shadow, margin, visibility, opacity;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.dropdown__itm,
.dropdown__itm--disabled {

    transition-property: background, box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    margin-top: 0;

    cursor: pointer;
}

.dropdown__itm a{
    padding-top: 12px;
    padding-bottom: 11px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
}

.dropdown__itm:hover {
    background-color: #eceff1;
}

.dropdown__itm--hover {
    background-color: #eceff1;
}

.dropdown__itm--hidden {
    display: none !important;
}

.dropdown__itm__cont {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
}

.dropdown__itm--active {
    background-color: #eceff1;
    cursor: default;
}

.dropdown__itm--disabled {
    background-color: #ccc;
}

.like-input,
.input {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
}

.like-input:hover .input__wrapper,
.input:hover .input__wrapper {
    border-color: #fc9801;
}

.input--error .input__wrapper {
    border-color: #fd2d2c;
}

.input--error .input__wrapper:hover {
    border-color: #fd2d2c;
}

.input--error .input__wrapper:focus {
    border-color: #fd2d2c;
    box-shadow: inset 0 0 0 1px #fd2d2c;
}

.input__wrapper {
    border: 1px solid #edeef0;
    height: 44px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
    line-height: 24px;
    position: relative;
    background-color: #fff;
    transition-property: border, box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    z-index: 2;
    box-shadow: inset 0 0 0 1px transparent;
    outline: none;
    color: #808080;
    padding: 9px 10px;
}

.input__wrapper:focus {
    border-color: #fc9801;
    box-shadow: inset 0 0 0 1px #fc9801;
}

.input__wrapper[disabled] {
    box-shadow: none !important;
    border-color: #e4e5e5 !important;
    color: #dadbdb !important;
}

.input--file .input {
    position: relative;
    cursor: pointer;
}

.input__upload {
    opacity: 0;
    filter: alpha(opacity=0);
    display: block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
}

.input__btn {
    cursor: pointer;
}

.ui-state-disabled .ui-state-default {
    color: #eceff1 !important;
}

.ui-datepicker {
    width: 216px;
    height: auto;
    z-index: 9990000 !important;
    margin: 5px auto 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
}

.ui-datepicker table {
    width: 100%;
}

.ui-datepicker th {
    text-align: center;
}

.ui-datepicker .ui-state-default {
    padding: 3px;
    color: #858c90;
}

.ui-datepicker td {
    text-align: center;
}

//.ui-datepicker td:hover .ui-state-default {
//    color: #fd2d2c;
//}
//
//.ui-datepicker td .ui-state-active {
//    color: #fd2d2c;
//}

.ui-datepicker-header {
    line-height: 30px;
    border-style: solid;
    border-color: #eceff1;
}

.ui-datepicker-title {
    text-align: center;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    position: absolute !important;
    top: 5px !important;
    width: 12px !important;
    height: 10px !important;
    text-align: center !important;
    cursor: pointer !important;
    background-repeat: no-repeat !important;
    overflow: hidden !important;
}

.ui-widget-header .ui-icon {
    background-image: url(/media/images/ui-icons_444444_256x240.png);
}

.propetrovich_theme {
    .ui-datepicker-prev {
        //top: 10px !important;
    }

    .ui-datepicker-prev {
        left: 10px !important;
        background-image: url(/media/images/next_btn.png) !important;

        &:hover {
            background-image: url(/media/images/next_btn_hov.png) !important;
        }
    }

    .ui-datepicker-next {
        right: 10px !important;
        background-image: url(/media/images/prev_btn.png) !important;

        &:hover {
            background-image: url(/media/images/prev_btn_hov.png) !important;
        }
    }
}

.ui-state-hover {
    border: none !important;
    background: none !important;
}

.textarea {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    height: 200px;
}

.textarea .field_with_errors {
    height: 100%;
}

.textarea:hover .textarea__wrapper,
.textarea:hover textarea {
    border-color: #fc9801;
}

.textarea--error .textarea__wrapper,
.textarea--error textarea {
    border-color: #fd2d2c;
}

.textarea--error .textarea__wrapper:hover,
.textarea--error textarea:hover {
    border-color: #fd2d2c;
}

.textarea--error .textarea__wrapper:focus,
.textarea--error textarea:focus {
    border-color: #fd2d2c;
    box-shadow: inset 0 0 0 1px #fd2d2c;
}

.textarea .textarea__wrapper,
.textarea textarea {
    resize: none;
    border: 2px solid #dddfe0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box;
    position: relative;
    background-color: #fff;
    transition-property: border, box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    z-index: 2;
    box-shadow: inset 0 0 0 1px transparent;
    outline: none;
    color: #808080;
    padding: 10px;
}

.textarea .textarea__wrapper:focus,
.textarea textarea:focus {
    border-color: #fc9801;
    box-shadow: inset 0 0 0 1px #fc9801;
}

.user {
    display: inline-block;
}

.user__ava {
    display: inline-block;
    vertical-align: middle;
}

.user__name {
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
}

.table-listing {
    width: 100%;
    table-layout: fixed;
}

.table-listing .btn {
    height: 32px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.table-listing .table__cell__round-red {
    background-color: inherit;
    color: inherit;
}

.table__cell__round-red {
    width: 35px;
    height: 35px;
    color: #fff;
    text-align: center;
    line-height: 35px;
    background-color: #fd2d2c;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0 !important;
}

.table__cell {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dddfe0;
    transition-property: opacity, visibility, height, padding, margin;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.table__cell.wb {
    border-bottom-width: 0;
}

.table__comments {
    vertical-align: top;
    padding-top: 8px;
    padding-bottom: 8px;
}

.table__cell--header {
    color: #858c90;
}

.table__cell--user {
    width: 267px;
}

.table__cell--checkbox {
    width: 16px;
}

.table__user__ava--fav {
    width: 14px;
    height: 19px;
    background-repeat: no-repeat;
    background-image: url(/media/images/tableFlagActive.png);
    position: absolute;
    bottom: -5px;
    right: -5px;
}

.table__checkbox {
    width: 14px;
    height: 19px;
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(/media/images/tableFlag.png);
    transition-property: background;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}

.table__checkbox-input {
    display: none;
}

.table__checkbox-input:checked + .table__checkbox {
    background-repeat: no-repeat;
    background-image: url(/media/images/tableFlagActive.png);
}

.table__row {
    background-color: #fff;
}

.table__row.table__row--close.table__row--yellow {
    background-color: #fff;
}

.table__row:nth-child(2n) {
    background-color: #f9fafb;
}

.table__row:nth-child(2n).table__row--close.table__row--yellow {
    background-color: #f9fafb;
}

.table__row:nth-child(2n).table__row--yellow {
    background-color: rgba(255, 218, 74, .8);
}

.table__row:nth-child(2n).table__row--yellow:hover {
    background-color: #eceff1;
}

.table__row:hover {
    background-color: #eceff1;
}

.table__row--hide > td {
    height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}

.table__row--yellow {
    background-color: rgba(255, 218, 74, .4);
}

.table__row--close {
    text-decoration: line-through;
}

.table__row--close > * {
    opacity: .3;
    filter: alpha(opacity=30);
}

.table__row--close > * .help-text {
    opacity: .3;
    filter: alpha(opacity=30);
}

.table__row--close .table__cell--repeat {
    opacity: 1;
    filter: none;
    border-bottom: 1px solid rgba(221, 223, 224, .3);
}

.table__row--close .help-text {
    text-decoration: line-through;
}

.table__row--close .table__cell__round-red {
    background-color: inherit;
    color: inherit;
}

.table__row--decl {
    opacity: .3;
    filter: alpha(opacity=30);
}

.table__row--decl:hover {
    opacity: 1;
    filter: none;
}

.table__row--link {
    cursor: pointer;
}

.table__row--thead:hover {
    background-color: inherit;
}

.table__row .btn--transp {
    color: #263238;
}

.is-working .btn {
    background-color: #fc9801 !important;
    cursor: default;
    border-color: #fc9801 !important;
    color: #fff !important;
}

.phone-sms--done {
    background-color: #fc9801;
    color: #fff;
    border: 1px solid #fc9801;
}

.user__ava--small {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
}

.user__ava--small .user__ava__img {
    width: 24px;
    height: 24px;
}

.user__ava__img {
    transition-property: opacity;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.table-listing__row {
    color: #babdbf;
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.table-listing__row .btn--transp {
    border-color: #fd2d2c;
    color: #fd2d2c;
}

.table-listing__row .phone-sms,
.table-listing__row .user__ava__img {
    opacity: .032;
    filter: alpha(opacity=3);
}

.table-listing__row .help-text {
    color: #babdbf;
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.table-listing__row.without__hover,
.table-listing__row.is-working,
.table-listing__row:hover {
    color: #263238;
}

.table-listing__row.without__hover .help-text,
.table-listing__row.is-working .help-text,
.table-listing__row:hover .help-text {
    color: #808080;
}

.table-listing__row.without__hover .btn--transp,
.table-listing__row.is-working .btn--transp,
.table-listing__row:hover .btn--transp {
    border-color: #babdbf;
    color: #263238;
}

.table-listing__row.without__hover .btn--transp:hover,
.table-listing__row.is-working .btn--transp:hover,
.table-listing__row:hover .btn--transp:hover {
    border-color: #fc9801;
    color: #fff;
    background-color: #fc9801;
}

.table-listing__row.without__hover .phone-sms,
.table-listing__row.is-working .phone-sms,
.table-listing__row:hover .phone-sms,
.table-listing__row.without__hover .user__ava__img,
.table-listing__row.is-working .user__ava__img,
.table-listing__row:hover .user__ava__img {
    opacity: 1;
    filter: none;
}

.gray-form__item {
    width: 254px;
    display: inline-block;
    margin-right: 8px;
}

.gray-form {
    background-color: #eceff1;
    padding: 16px;
}

.gray-form .btn {
    vertical-align: bottom;
    height: 42px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 2px;
}

.gray-form .btn--middle {
    vertical-align: middle;
}

.gray-form--order-show {
    padding-top: 32px;
    padding-bottom: 32px;
}

.breadcrumbs__item {
    display: inline-block;
}

.breadcrumbs__item + .breadcrumbs__item {
    margin-left: 8px;
}

.breadcrumbs__link {
    color: #858c90;
    border-bottom: 2px solid #858c90;
}

.breadcrumbs__link:hover {
    border-color: #fd2d2c;
    text-decoration: none;
}

.profile__name {
    position: relative;
    padding-right: 33px;
    display: inline-block;
}

.profile__ava {
    width: 128px;
    height: 128px;
    overflow: hidden;
    display: inline-block;
}

.profile__ava__img {
    width: 128px;
    height: 128px;
}

.profile__add-fav {
    position: relative;
    width: 14px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    margin-left: 16px;
    background-image: url(/media/images/tableFlagRed.png);
    top: 0;
    right: 0;
    cursor: pointer;
    transition-property: background-image;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.profile__add-fav--active {
    background-image: url(/media/images/tableFlagRedActive.png);
}

.profile__images__itm {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 8px;
    margin-top: 8px;
    width: 96px;
    height: 86px;
    overflow: hidden;
    text-align: center;
}

.profile__images__itm img {
    max-height: 96px;
    max-width: 86px;
}

.profile__images__itm:hover .profile__show-more {
    background-color: #000;
}

.profile__show-more {
    background-color: rgba(0, 0, 0, .56);
    line-height: 72px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.profile__spec__val {
    display: inline-block;
    width: 30px;
    margin-right: 3px;
}

.profile__spec__line {
    width: 28px;
    height: 4px;
    background-color: #eceff1;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
}

.profile__spec__line + .profile__spec__line {
    margin-left: 5px;
}

.profile__spec__line-color {
    background-color: #fc9801;
}

.portfolio__itm {
    width: 219px !important;
    height: 165px;
    line-height: 165px;
    background-color: #eceff1;
    text-align: center;
    position: relative;
    margin-bottom: 24px;
    margin: auto;
}

.portfolio-open--disabled {
    opacity: .3;
    filter: alpha(opacity=30);
}

.portfolio-open--disabled .link {
    cursor: default;
    color: #263238 !important;
}

.portfolio__itm__wrapper {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.portfolio__itm__img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    opacity: .7;
    filter: alpha(opacity=70);
    max-width: 100%;
    min-height: 165px;
}

.portfolio__cont {
    position: relative;
    z-index: 2;
    display: inline-block;
    line-height: normal;
}

.profile__avatar {
    width: 127px;
    height: 127px;
}

.profile__avatar img {
    width: 127px;
    height: 127px;
}

.star {
    width: 12px;
    height: 12px;
    background-image: url(/media/images/star.jpg);
    display: inline-block;
    margin-bottom: -1px;
}

.star + .star {
    margin-left: 8px;
}

.star--active {
    background-image: url(/media/images/star-active.jpg);
}

.star--big {
    width: 26px;
    height: 24px;
    cursor: pointer;
    background-image: url(/media/images/starBig.png);
    transition-property: background;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.star--big.star--hover,
.star--big.star--active {
    background-image: url(/media/images/starBig-active.png);
}

.review__raiting__start {
    display: inline-block;
    margin-right: 8px;
}

.review__user__ava {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}

.review__user__text {
    display: inline-block;
    width: 179px;
    vertical-align: middle;
}

.checkbox {
    display: inline-block;
    cursor: pointer;
    line-height: 20px;
}

.checkbox__input {
    display: none;
}

.checkbox__input:checked + .checkbox__box {
    background-color: #fd2d2c;
    border: 1px solid transparent;
}

.checkbox__input:checked + .checkbox__box:before {
    content: '';
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 5px;
    width: 14px;
    height: 11px;
    background-image: url(/media/images/checkbox.png);
}

.checkbox__box {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: relative;
    border: 1px solid #dddfe0;
    display: inline-block;
    margin-right: 8px;
    background-color: #fff;
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.checkbox__text {
    display: inline-block;
    vertical-align: top;
    line-height: 22px;
}

.switcher-hor {
    display: inline-block;
    cursor: pointer;
    line-height: 31px;
}

.switcher-hor__box {
    display: inline-block;
    vertical-align: middle;
    background-color: #dddfe0;
    position: relative;
    border-radius: 16px;
    width: 31px;
    height: 16px;
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.switcher-hor__box:before {
    content: '';
    position: absolute;
    left: 3px;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #fff;
    margin-left: 0;
    transition-property: margin;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.switcher-on {
    display: none;
}

.checkbox__input:checked + .switcher-hor__wrapper .switcher-hor__box {
    background-color: #fd2d2c;
}

.checkbox__input:checked + .switcher-hor__wrapper .switcher-hor__box:before {
    margin-left: 13px;
}

.checkbox__input:checked + .switcher-hor__wrapper .switcher-off {
    display: none;
}

.checkbox__input:checked + .switcher-hor__wrapper .switcher-on {
    display: inline-block;
}

.radio input[type='radio'] {
    display: none;
}

.radio input[type='radio']:checked + .radio__box--round {
    border: 2px solid #fd2d2c;
    color: #fd2d2c;
}

.radio input[type='radio']:checked + .radio__box:after {
    border-color: #fd2d2c;
}

.radio input[type='radio']:checked + .radio__box:before {
    opacity: 1;
    filter: none;
    visibility: visible;
}

.radio--checkbox__input {
    display: block;
}

.radio {
    text-align: left;
    display: list-item;
    text-decoration: none;
    list-style: none;
}

.radio:hover .radio__box:after {
    border-color: #fd2d2c;
}

.radio:hover .radio__box--round {
    border: 2px solid #fd2d2c;
    color: #fd2d2c;
}

.radio--round {
    display: inline-block;
}

.radio--round + .radio--round {
    margin-left: 8px;
}

.radio__box {
    line-height: 24px;
    position: relative;
    padding-left: 44px;
    cursor: pointer;
}

.radio__box:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid #eceff1;
    left: 0;
    top: 0;
    transition-property: border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.radio__box:before {
    content: '';
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #fd2d2c;
    left: 5px;
    top: 5px;
    transition-property: opacity, visibility;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.radio__box--round {
    width: auto;
    height: auto;
    line-height: 28px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 40px;
    border: 2px solid transparent;
    transition-property: border, color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.radio__box--round:after {
    display: none;
}

.radio__box--round:before {
    display: none;
}

.radio--checkbox__input {
    display: none;
}

.radio--checkbox__input + .radio--checkbox .radio:first-child .radio__box:after {
    border-color: #fd2d2c;
}

.radio--checkbox__input + .radio--checkbox .radio:first-child .radio__box:before {
    opacity: 1;
    filter: none;
    visibility: visible;
}

.radio--checkbox__input + .radio--checkbox .radio:first-child .radio__box--round {
    border: 2px solid #fd2d2c;
    color: #fd2d2c;
}

.radio--checkbox__input:checked + .radio--checkbox .radio:first-child .radio__box:after {
    border-color: #eceff1;
}

.radio--checkbox__input:checked + .radio--checkbox .radio:first-child .radio__box:before {
    background-color: transparent;
}

.radio--checkbox__input:checked + .radio--checkbox .radio:first-child .radio__box--round {
    border: 2px solid transparent;
    color: #263238;
}

.radio--checkbox__input:checked + .radio--checkbox .radio + .radio .radio__box:after {
    border-color: #fd2d2c;
}

.radio--checkbox__input:checked + .radio--checkbox .radio + .radio .radio__box:before {
    opacity: 1;
    filter: none;
    visibility: visible;
}

.radio--checkbox__input:checked + .radio--checkbox .radio .radio__box--round {
    border: 2px solid #fd2d2c;
    color: #fd2d2c;
}

.input--file__photo.ava {
    width: 128px;
}

.for_not_individual {
    display: block;
}

.for_individual {
    display: none;
}

.is_individual .for_not_individual {
    display: none;
}

.is_individual .for_individual {
    display: block;
}

.for_master {
    display: none;
}

.lh42 {
    line-height: 42px;
}

.petrovich__login {
    height: 46px;
    display: inline-block;
    width: 100%;
    background-color: #ffda4a;
    color: #fd2d2c;
    cursor: pointer;
    text-transform: uppercase;
    transition-property: background, color, box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
}

.petrovich__login:hover {
    background-color: #fd2d2c;
    color: #fff;
    box-shadow: 0 0 0 rgba(0, 0, 0, .1);
}

.petrovich__login__text {
    vertical-align: sub;
}

.pro_login {
    overflow: hidden;
    transition-property: opacity, height, visibility;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.pro_login--hide {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 0;
    display: none;
}

.petrovich__form-wrap {
    opacity: 0;
    filter: alpha(opacity=0);
    overflow: hidden;
    visibility: hidden;
    height: 0;
    transition-property: opacity, height, visibility;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.petrovich__form-wrap--active {
    opacity: 1;
    filter: none;
    visibility: visible;
    height: auto;
}

.login__tab {
    display: inline-block;
    border: 1px solid #eceff1;
    border-bottom-width: 0;
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    transition-property: background, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    margin-right: 0 !important;
}

.login__tab + .login__tab.login__tab--active,
.login__tab + .login__tab:hover {
    background-color: #ffda4a;
    border-color: #ffda4a;
    color: #263238;
}

.login__tab + .login__tab.login__tab--active:before,
.login__tab + .login__tab:hover:before {
    background-color: #ffda4a;
}

.login__tab:hover {
    background-color: #fd2d2c;
    color: #fff;
    border-color: #fd2d2c;
}

.login__tab__wrapper {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    box-sizing: border-box;
    border-bottom: 1px solid #eceff1;
}

.login__tab--active {
    background-color: #fd2d2c;
    color: #fff;
    border-color: #fd2d2c;
    position: relative;
}

.login__tab--active:before {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    padding-left: 2px;
    width: 100%;
    bottom: -1px;
    left: -1px;
    background-color: #fd2d2c;
    right: 0;
}

.login__tab--active .link--with-border {
    border-bottom-width: 0;
    cursor: default;
}

.login__tab--active .link:hover {
    color: inherit;
}

.login_form {
    margin-left: auto;
    margin-right: auto;
}

@keyframes showAgain {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden;
        right: 0;
    }
    70% {
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden;
        right: 0;
    }
}

.popup {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    opacity: 0;

    filter: alpha(opacity=0);
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.popup__inner {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.popup--active {
    opacity: 1;
    filter: none;
    visibility: visible;
}

.popup__wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: inline-block;
    background-color: rgba(0, 0, 0, .25);
    vertical-align: middle;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    box-sizing: border-box;
}

.popup__body {
    padding: 35px 12px;
    background-color: #fff;
    display: inline-block;
}

.popup__close {
    border-bottom: 2px solid #eceff1;
    transition-property: border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.popup__close:hover {
    border-color: #fd2d2c;
}

.news__text {
    max-height: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.article > * {
    margin-bottom: 24px;
}

.article img {
    max-width: 100%;
    margin-bottom: 7px;
    margin-top: -11px;
}

.article {
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: bold;
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.6rem;
    }

    h4 {
        font-size: 1.4rem;
    }

    h5 {
        font-size: 1.2rem;
    }
}

.article table {
    width: 100%;
}

.article table td + td {
    border-left: 1px solid #dddfe0;
}

.article table tr {
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.article table tr:hover {
    background-color: #eceff1;
}

.article table tr + tr td,
.article table tr + tr th {
    border-top: 1px solid #dddfe0;
}

.article table th + td {
    border-left: 1px solid #dddfe0;
}

.article table td {
    color: #808080;
}

.article table th,
.article table td {
    font-weight: 100;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.article ol li {
    list-style: decimal inside;
    margin-bottom: 12px;
}

.article a {
    text-decoration: underline;
}

.services__item {
    background-color: #f9fafb;
    padding: 32px 24px;
    position: relative;
}

.services__item--disabled {
    opacity: .35;
    filter: alpha(opacity=35);
}

.services__item--disabled:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.services__item__price-text {
    width: 130px;
}

.service__price__text {
    width: 130px;
}

.service__date .round-switcher {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 3px;
}

.service__date__text {
    line-height: 32px;
}

.best-m-cont {
    margin-top: 30px;
}

.best-m--itm {
    display: inline-block;
    vertical-align: top;
    max-height: 150px;
    overflow: hidden;
}

.best-m--itm + .best-m--itm {
    margin-left: 15px;
}

.best-m--ava {
    width: 96px;
    height: 96px;
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
    display: inline-block;
    transition-property: border, background;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    position: relative;
}

.best-m--ava .best-m--img {
    opacity: 1;
    filter: none;
    transition-property: opacity;
    position: absolute;
    top: 0;
    left: 0;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.best-m--ava .best-m--img--hover {
    opacity: 0;
    filter: alpha(opacity=0);
    transition-property: opacity;
}

.best-m--img {
    width: 96px;
    height: 96px;
}

.top-type {
    display: none;
}

.top-type--active {
    display: block;
}

.sms__wrapper {
    text-align: left;
    display: none;
    position: relative;
}

.sms__wrapper--active {
    display: block;
}

.sms__text {
    float: left;
    width: 130px;
}

.sms__link {
    float: right;
}

.sms__profile .sms__input {
    float: none;
}

.sms__profile .sms__link {
    float: none;
}

.sms__profile .sms__timer {
    float: none;
}

.sms__profile .sms__rep {
    float: none;
}

.sms__input {
    float: left;
    width: 106px;
}

.sms__timer {
    float: right;
    height: 42px;
    line-height: 42px;
}

.sms__rep__text {
    transition-property: color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.sms__rep {
    display: none;
    float: right;
}

.sms__rep:hover .reg__again__text {
    color: #fd2d2c;
}

.reg__sms__step {
    display: none;
}

.reg__sms--step-1 .reg__sms--send {
    display: block;
}

.reg__sms--step-2 .reg__sms--conf {
    display: block;
}

.reg--step-1 .reg__step-1 {
    display: block;
}

.reg--step-2 .reg__step-2 {
    display: block;
}

.reg__spec__row .btn--remove {
    display: none;
}

.reg__spec__row + .reg__spec__row {
    margin-top: 16px;
}

.reg__spec__row + .reg__spec__row .btn--remove {
    display: block;
}

.reg__spec__list_itm {
    position: relative;
    margin-bottom: 16px;
}

.fav__pop-up {
    width: 521px;
    position: relative;
    display: none;
    table-layout: fixed;
}

.pop-up__close {
    font-size: 16px;
    position: absolute;
    right: 27px;
    top: 25px;
    cursor: pointer;
    transition-property: color, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}

.pop-up__close:hover {
    color: #fd2d2c;
}

.fav-list {
    margin-left: auto;
    margin-right: auto;
}

.fav-list__itm {
    padding-top: 16px;
    padding-bottom: 16px;
}

.fav-list__itm + .fav-list__itm {
    border-top: 1px solid #eceff1;
}

.fav__pop-up--boby {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.fav__pop-up--bobyinner {
    height: 100%;
    overflow: auto;
    width: 100%;
}

.remove-fav {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    float: right;
    background-color: #fd2d2c;
    color: #fff;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}

.fav__pop-up--footer {
    background-color: #eceff1;
    height: 78px;
    width: 100%;
}

.fav .fancybox-skin {
    padding: 0 !important;
}

.fav .fancybox-inner {
    overflow: hidden !important;
}

.fav__pop-up--header {
    padding-left: 27px;
    padding-right: 27px;
    position: relative;
    border-bottom: 1px solid #eceff1;
}

.q-tip {
    position: relative;
    display: inline-block;
}

.q-tip:hover .q-tip--buble {
    margin-top: 0;
    opacity: 1;
    filter: none;
    visibility: visible;
}

.q-tip td {
    padding-top: 8px;
    vertical-align: top;
}

.q-tip--buble {
    visibility: hidden;
    position: absolute;
    font-weight: normal;
    width: 276px;
    bottom: 10px;
    margin-bottom: 100%;
    background-color: #263238;
    color: #e4e5e6;
    padding: 24px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    opacity: 0;
    filter: alpha(opacity=0);
    transition-property: visibility, opacity, margin;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    left: 50%;
    right: 0;
    margin-left: -138px;
    text-transform: none;
    z-index: 110;
}

.q-tip--buble:hover {
    margin-top: 0;
    opacity: 1;
    filter: none;
    visibility: visible;
}

.q-tip--buble .link__text--yellow {
    color: #e4e5e6;
}

.q-tip--buble:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #263238 transparent transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    bottom: -5px;
}

.q-tip--buble--rigth {
    left: 0;
    margin-left: 0;
    padding: 14px;
    text-align: center;
    border-radius: 2px 2px 2px 0;
}

.q-tip--buble--rigth:after {
    margin-left: 0;
    border-width: 5px 5px 0 0;
    border-color: #263238 transparent transparent;
}

.q-tip--buble--inline {
    margin-bottom: 0;
    bottom: 100%;
}

.listing__column__value .q-tip--buble--inline {
    width: 140px;
    margin-left: -76px;
    padding: 10px;
}

.q-tip--buble--master {
    margin-bottom: 20px;
    bottom: 100%;
}

.q-tip--buble--rating {
    margin-bottom: 0;
    margin-left: 0;
    bottom: 100%;
    left: 80px;
    padding: 15px;
}

.q-tip--buble--rating:after {
    margin-left: 30px;
}

.q-tip--buble--small {
    width: 200px;
    top: auto;
    margin-bottom: -85px;
    margin-left: -100px;
    z-index: 100;
}

.q-tip--buble--small:after {
    margin-bottom: -85px;
    top: -55px;
    bottom: auto;
    border-width: 0 5px 5px;
    border-color: transparent transparent #263238;
}

.yellow-text {
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #ffda4a;
}

.service_promo .q-tip--buble--rating {
    margin-bottom: 6px;
    left: 70px;
}

.service_promo .q-tip--buble--rating:after {
    margin-left: 10px;
}

.feedback__pop-up,
.message__pop-up {
    width: 521px;
    position: relative;
    table-layout: fixed;
}

.feedback__pop-up .textarea,
.message__pop-up .textarea {
    height: 110px;
}

.feedback__body,
.feedback--header {
    padding-left: 27px;
    padding-right: 27px;
}

.feedback__body {
    margin-bottom: 32px;

    .error {
        margin: 8px 0 0;
        display: block;
    }
}

.feedback__body textarea {
    resize: none;
}

.feedback__body .url_field {
    position: absolute;
    left: -999999px;
}

.search__btn {
    border: none;
    background-image: url(/media/images/search.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #dddfe0;
    width: 42px;
    height: 42px;
    border: 2px solid #dddfe0;
    display: inline-block;
    transition-property: background, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    outline: none;
}

.search__btn--active {
    border-color: #fc9801;
    background-color: #fc9801;
}

.search__container:hover .search__btn {
    border-color: #fc9801;
    background-color: #fc9801;
}

.search__input {
    width: 295px;
    &.is_user_master {
        width: 665px;
        padding-left: 14px;
    }
}

@media only screen and (max-width: 979px) {
    .search__input {
        &.is_user_master {
            width: calc(100% - 70px);
            padding-left: 14px;
        }
    }
}

@media only screen and (max-width: 979px) {
    .span1 {
        margin-left: 0;
        margin-right: 0;
        padding-left: 13px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 12.5%;
    }
}

@media only screen and (max-width: 979px) {
    .span2 {
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 100%;
    }
}

@media only screen and (max-width: 979px) {
    .span3 {
        margin-left: 0;
        margin-right: 0;
        padding-left: 13px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 37.5%;
    }
}

@media only screen and (max-width: 979px) {
    .span4 {
        margin-left: 0;
        margin-right: 0;
        padding-left: 13px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 50%;
    }
}

@media only screen and (max-width: 979px) {
    .span5 {
        margin-left: 0;
        margin-right: 0;
        padding-left: 13px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 62.5%;
    }
}

@media only screen and (max-width: 979px) {
    .span6 {
        margin-left: 0;
        margin-right: 0;
        padding-left: 13px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 75%;
    }
}

@media only screen and (max-width: 979px) {
    .span7 {
        margin-left: 0;
        margin-right: 0;
        padding-left: 13px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 87.5%;
    }
}

@media only screen and (max-width: 979px) {
    .span8 {
        margin-left: 0;
        margin-right: 0;
        padding-left: 13px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 100%;
    }
}

.span {
    margin-left: 0;
    margin-right: 0;
    padding-left: 13px;
    padding-right: 14px;
    box-sizing: border-box;
}

@media only screen and (max-width: 979px) {
    .m-pad16 {
        padding-left: 16px;
        padding-right: 16px;
    }
    .span-reg__options--photo {
        width: auto;
    }
    .review__user__text {
        width: 100%;
    }
    .m-q-tip-ur {
        bottom: -400px;
        height: 300px;
        width: 240px !important;
        min-width: 240px;
        z-index: 4000;
        top: 0;
        left: -110px;
    }
    .m-q-tip-ur:after {
        bottom: auto;
        top: 25px;
        right: -5px;
        margin-right: 0;
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent #263238;
        margin-top: auto;
    }
    .row {
        padding-left: 0;
        padding-right: 0;
    }
    .row .row {
        margin-left: 0;
        margin-right: 0;
    }
    .m-mt8 {
        margin-top: 8px;
    }
    .m-mt16 {
        margin-top: 16px;
    }
    .m-mt32 {
        margin-top: 32px;
    }
    .m-dn {
        display: none;
    }
    .m-span8 {
        width: 100%;
        display: inline-block;
    }
    .m-pad0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .m-span4 {
        box-sizing: border-box;
        width: 50%;
        display: inline-block;
    }
    .m-span2 {
        box-sizing: border-box;
        width: 25%;
        display: inline-block;
    }
    .m-span6 {
        box-sizing: border-box;
        width: 75%;
        display: inline-block;
    }
    .m-span33 {
        box-sizing: border-box;
        width: 33.33%;
        display: inline-block;
    }
    .m-ml0 {
        margin-left: 0;
    }
    .m-mt24 {
        margin-top: 24px;
    }
    .m-mh0 {
        margin-left: 0;
        margin-right: 0;
    }
    .m-span-pad {
        box-sizing: border-box;
        padding-left: 13px;
        padding-right: 13.5px;
    }
    .m-row {
        zoom: 1;
        padding-left: 14px;
        padding-right: 13px;
    }
    .m-row:before,
    .m-row:after {
        content: '';
        display: table;
    }
    .m-row:after {
        clear: both;
    }
    .m-row .m-row {
        margin-left: -27px;
        margin-right: -27px;
    }
    .m-span4-wm {
        box-sizing: border-box;
        width: calc(50%);
        display: inline-block;
    }
    .m-span4-wm + .m-span4-wm {
        margin-left: 13px;
        width: calc(50% - 13px);
    }
    .m-span4-wm + .m-span4-wm:nth-child(2n+1) {
        width: calc(50%);
        margin-left: 0;
    }
}

@media only screen and (max-width: 400px) {
    .q-tip--buble {
        max-width: 100%;
    }
    .mm-ml0 {
        margin-left: 0;
    }
    .m-span4-wm + .m-span4-wm {
        margin-left: 0 !important;
    }
    .m-q-tip140 {
        width: 140px !important;
        min-width: 140px;
    }
    .mm-pad0 {
        padding-left: 0;
        padding-right: 0;
    }
    .mm-span8 {
        width: 100% !important;
        display: inline-block;
    }
    .mm-span4 {
        box-sizing: border-box;
        width: 50% !important;
        display: inline-block;
    }
    .mm-span2 {
        box-sizing: border-box;
        width: 25% !important;
        display: inline-block;
    }
    .mm-span6 {
        box-sizing: border-box;
        width: 75% !important;
        display: inline-block;
    }
    .mm-span33 {
        box-sizing: border-box;
        width: 33.33% !important;
        display: inline-block;
    }
    .m-f-vtop {
        vertical-align: top;
    }
    .mm-mt8 {
        margin-top: 8px;
    }
    .mm-mt16 {
        margin-top: 16px;
    }
    .mm-mt24 {
        margin-top: 24px;
    }

    .top_banner__container {

        .order_letter__btn,
        .order_phone__btn {
            float: none;
            width: 213px;
            margin: 10px auto 30px;
            padding: 12px 0;
        }

        .top_banner_message,
        .top_banner_phone {
            width: 235px;
            margin: 0 auto;
            float: none;
            padding: 0;
            padding-top: 40px;

            .top_banner_message__title,
            .top_banner__message__title,
            .top_banner_phone__title {
                text-align: center;
            }

            .top_banner_message__text,
            .top_banner_phone__text{
                text-align: center;
            }
        }
    }

    .login__tab__wrapper {
        padding-left: 8px;
        padding-right: 8px;

        .row {
            white-space: nowrap;
        }

        .login__tab {
            padding-left: 0;
            padding-right: 0;
            white-space: normal;
        }
    }
}

.fancybox-overlay {
    z-index: 10000;
}

.fancybox-opened {
    z-index: 10001;
}

@media only screen and (max-width: 979px) {

    .top_banner__container {

        .top_banner_message,
        {
            width: 50%;
        }
    }

    .service_promo .f-center {
        text-align: left;
    }
    body {
        //min-width: 320px;
        width: 100%;
    }
    .scroll_stop {
        overflow: hidden;
        max-height: 100%;
        position: relative;
    }
    .wrapper {
        width: 100%;
    }
    .left-cont {
        float: none;
    }
    .logo__text {
        white-space: nowrap;
        font-size: 11px;
        line-height: 12px;
        text-align: left;
        margin-left: 5px;
        display: inline-block;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 700;
    }
    .header {
        padding-top: 0;
        height: 75px;
    }
    .header:before {
        content: '';
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
    }
    .header--devise .logo {
        right: 0 !important;
        left: 0 !important;
    }
    .logo {
        position: absolute;
        width: auto;
        left: 25px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        top: 55px;
    }
    .logo__img {
        height: 42px;
        width: auto;
    }
    .right-cont {
        float: none;
    }
    .right-cont .header__menu {
        margin-left: 0;
    }
}

@media only screen and (max-width: 400px) {
    .dropdown--red {
        width: 100% !important;
    }
    .form-span {
        padding-left: 16px;
        padding-right: 16px;
    }
    .login__tab {
        vertical-align: bottom;
    }
    .best-m--itm {
        width: 50%;
    }
    .radio__box--round {
        padding-left: 9px;
        padding-right: 9px;
    }
    .radio--round + .radio--round {
        margin-left: 4px;
    }
    .header__menu {
        text-align: left !important;
    }
    .mm-mt8 {
        margin-top: 8px;
    }
    .mm-mt16 {
        margin-top: 16px;
    }
    .mm-dn {
        display: none !important;
    }
}

.m-main-menu__item {
    display: none;
}

@media only screen and (max-width: 520px) {
    .top_banner__container {

        .order_letter__btn,
        .order_phone__btn {
            float: none;
            width: 213px;
            margin: 10px auto 30px;
            padding: 12px 0;
        }

        .top_banner_message,
        .top_banner_phone, {
            width: 235px;
            margin: 0 auto;
            float: none;
            padding: 0;
            padding-top: 40px;

            .top_banner_message__title,
            .top_banner__message__title,
            .top_banner_phone__title {
                text-align: center;
            }

            .top_banner_message__text,
            .top_banner_phone__text{
                text-align: center;
            }
        }
    }

    .top_line--left {
        display: none;
    }
    .notice__wrapper {
        right: 0;
        left: 0;
        width: 100%;
        top: auto;
        bottom: 0;
        box-sizing: border-box;
    }
    .notice {
        width: 100%;
    }
    .notice + .notice {
        margin-top: 0;
    }
    .notice__close {
        top: 0;
        right: 0;
        display: inline-block;
        bottom: 0;
        border-radius: 0;
        width: 30px;
        height: 30px;
        line-height: 28px;
        font-size: 20px;
    }
}

.navbar-toggle {
    display: none;
}

@media only screen and (max-width: 979px) {
    .m-main-menu__item {
        display: block;
    }
    .proposal_count {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 0;
        line-height: 60px;
        font-size: 20px;
        top: 0;
        right: 60px;
    }
    .top_line {
        position: absolute;
        top: 8px;
        margin-top: 0;
        z-index: 20001;
    }
    .top_line--left {
        left: 25px;
    }
    .top_line--right {
        right: 25px;
    }
    .b_user .petr_link {
        display: none;
    }
    .b_user .top_line__wrapper {
        opacity: 0;
        filter: alpha(opacity=0);
        visibility: hidden;
        height: 0;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }
    .b_user .body--main {
        border-top-width: 0;
    }
    .b_user .top_line--right {
        top: 0;
        right: 0;
    }
    .b_user .navbar-toggle {
        top: 0;
    }
    .b_user .logo {
        top: 8px;
    }
    .b_user .header {
        height: 60px;
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .top_line__wrapper {
        height: 48px;
        z-index: 20000;
        border-bottom: 1px solid #d3d3d3;
        background-color: #fff;
        position: relative;
    }
    .main-menu {
        border-width: 0;
    }
    .main-menu__buble {
        margin-right: -250px !important;
        clear: both;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        right: 0;
        width: 250px;
        box-sizing: border-box;
        padding-top: 48px;
        transition-property: margin;
        transition-duration: .2s;
        transition-timing-function: ease-in-out;
        position: fixed;
        height: 100%;
        display: block;
        background-color: #2d343a;
        z-index: 10000;
    }
    .main-menu__buble--active {
        margin-right: 0 !important;
    }
    .main-menu__buble--active .main-menu__buble__close {
        display: block;
    }
    .main-menu__list {
        width: 100%;
        overflow: auto;
    }
    .main-menu__item {
        display: block;
        margin-left: 0 !important;
        padding-left: 20px;
        padding-right: 20px;
    }
    .main-menu__item .main-menu__link {
        display: table-cell;
        text-align: left;
        padding-top: 8px;
        padding-bottom: 8px;
        vertical-align: middle;
        border-bottom-width: 0;
        color: #d3d3d3;
    }
    .main-menu__item .main-menu__link:hover {
        color: #fff;
    }
    .main-menu__item--mobile {
        padding-top: 8px;
        margin-top: 8px;
        border-top: 1px solid #808080;
    }
    .main-menu__item--active .main-menu__link {
        color: #fff;
    }
    .main-menu__buble__close {
        position: fixed;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
    .main-menu__buble__close:hover {
        color: #fd2d2c;
    }
    body {
        transition-property: padding;
        transition-duration: .2s;
        transition-timing-function: ease-in-out;
    }
    .petr_link {
        text-align: right;
    }
    .header__menu {
        margin-left: 0;
    }
}

.m-d {
    display: none;
}

.mm-b {
    display: none;
}

.mm-diln {
    display: none;
}

@media only screen and (max-width: 400px) {
    .f-med {
        font-size: 16px;
        line-height: 20px;
    }
    .f-medb {
        font-size: 18px;
        line-height: 22px;
    }
    .f-big {
        font-size: 30px;
        line-height: 34px;
    }
    .f-giga {
        font-size: 45px;
        line-height: 49px;
    }
    .span.span-reg__dropdown {
        margin-left: 0;
        width: 100% !important;
        padding-left: 0 !important;
    }
    .span.span-reg__dropdown + .span.span-reg__dropdown {
        margin-top: 8px;
    }
    .span.span-reg__dropdown + .span.span-reg__dropdown .dropdown--autocomplete {
        margin-left: 0 !important;
    }
    .mm-b {
        display: block;
    }
    .mm-diln {
        display: inline-block;
    }
    .find-m .dropdown--red .dropdown__list {
        width: 100% !important;
    }
    .article .rating_table {
        table-layout: fixed;
    }
    .article table td,
    .article table th {
        padding-left: 5px;
        padding-right: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media only screen and (max-width: 999px) {
    .profile__spec__val {
        width: 100%;
    }
    .profile__spec__line {
        width: 17%;
    }
    .profile__spec__line + .profile__spec__line {
        margin-left: 3%;
    }
    .profile__add-fav.user_page {
        position: absolute;
        right: 0;
        top: 0;
    }
    .m-f-right {
        text-align: right;
    }
    .m-f-center {
        text-align: center;
    }
    .m-btn {
        color: #fff;
        outline: none;
        background-color: #fd2d2c;
        display: inline-block;
        box-sizing: border-box;
        text-transform: uppercase;
        border: none;
        appearance: none !important;
        cursor: pointer;
        transition-property: background, border;
        transition-duration: .2s;
        transition-timing-function: ease-in-out;
        width: 100%;
        border-radius: 2px;
        height: 36px;
        padding: 10px 25px;
    }
    .m-btn:hover {
        background-color: #fc9801;
    }
    .m-btn:active {
        background-color: #fd2d2c;
    }
    .listing__columns--round .q-tip--buble {
        max-width: 140px;
    }
    .m-q-tip--left .q-tip--buble {
        margin-left: -100%;
    }
    .m-q-tip--left .q-tip--buble:after {
        margin-left: auto !important;
        right: 20px;
    }
    .feedback__pop-up,
    .message__pop-up {
        width: 100%;
    }
    .m-wsp {
        white-space: normal;
    }
    .m-d {
        display: block;
    }
    .btn--round {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
    }
    input {
        appearance: none;
        border-radius: 0;
    }
    .find-m .dropdown--red .dropdown__list {
        width: 200%;
    }
    .best-m--itm {
        margin-top: 16px;
    }
    .best-m--itm + .best-m--itm {
        margin-left: 0;
    }
    .today {
        padding-left: 0;
        padding-right: 0;
        margin-top: 32px;
    }
    .today__itm__text {
        width: calc(100% - 50px);
    }
    .service_promo {
        overflow: hidden;
    }
    .service_promo p {
        line-height: 24px;
    }
    .service_promo--red:before {
        display: none;
    }
    .listing__column__itm__left {
        margin: 0;
    }
    .today__list {
        height: auto;
    }
    .search__input {
        width: calc(100% - 43px);
    }
    .table__cell {
        display: block;
        box-sizing: border-box;
        text-overflow: ellipsis;
        word-break: break-all;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .table__cell.m-dn {
        display: none;
    }
    .table__cell .m-pad16 {
        padding-left: 16px;
        padding-right: 16px;
    }
    //.table__cell * {
    //    display: inline-block;
    //    padding-right: 8px;
    //}
    .table__cell .m-dn {
        display: none;
    }
    .table__cell .dn {
        display: none;
    }
    .table__cell > ul {
        display: inline;
    }
    .table__cell:before {
        content: attr(data-title) ':';
        width: 30%;
        font-weight: bold;
        margin-bottom: 8px;
        padding-right: 8px;
    }
    .table__cell--checkbox:before {
        display: none !important;
    }
    .table__row .table__cell {
        border-bottom-width: 0;
    }
    .table__row .table__cell:last-child {
        border-bottom-width: 1px;
    }
    .span.span-reg__dropdown {
        margin-left: 0;
        width: 50%;
    }
    .span.span-reg__dropdown + .span.span-reg__dropdown {
        width: calc(50% - 13px);
        padding-left: 5px;
    }
    .span.span-reg__dropdown + .span.span-reg__dropdown .dropdown--autocomplete {
        margin-left: 2px;
    }
    .m-fl {
        float: left;
    }
    .m-fr {
        float: right;
    }
    .m-db {
        display: block;
    }
    .table__cell--header {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .header__link .hidden-mobile {
        display: none !important;
    }
}

.article table td,
.article table th {
    margin: 4px;
    padding: 4px;
    font-size: 15px;
    font-weight: normal;
}

.logout__link {
    background: none;
    border: none;
}

.main-menu form {
    line-height: normal;
}

.profile .checkbox {
    margin: 0;
}

.profile .checkbox label {
    padding-left: 0;
}

.align-top {
    vertical-align: top;
}

.profile .change-input.link,
.profile .sms__send.link {
    text-decoration: none;
}

.profile.is_master .for_master {
    display: block;
}

.profile .speciality_dropdown {
    width: 500px;
}

.add-skill {
    text-transform: none;
}

.personal_data {
    text-align: center;
    font-family: $fontOpenSans;
    font-size: 11px;
    display: block;
    color: #737373;
    line-height: 1.455;
    a {
        text-decoration: underline;
    }
}

.personal_data--is-signup {
    max-width: 334px;
    margin: 20px auto 0;
    a {
        display: inline;
    }
}

.personal_data--is-index {
    max-width: 579px;
    margin: 20px auto 0;
}

.personal_data--is-mailing-footer {
    max-width: 100%;
    text-align: left;

    a {
        color: #999a9a;
        &:hover {
            background-color: transparent;
            color: $colorRed;
            border-color: $colorRed;
        }
    }
}

.personal_data--is-write-pop-up {
    padding: 20px 41px 25px;
}

.personal_data--is-order {
    text-align: left;
    padding: 20px 0;
    max-width: 574px;
    margin-left: 14px;
}

.write_us__pop-up {
    button {
        margin-top: 20px;
    }
}

#new_order {
    // margin-bottom: 40px;
}

.enter {
    text-align: center;
    max-width: 334px;
    margin: 0 auto;
    display: block;
}

.top_banner {
    background: $colorYellow;

    .menu_open & {
        display: none;
    }
}

.top_banner__container {
    // this is clearfix
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.top_banner_message,
.top_banner_phone {
    float: left;
    padding: 40px 0;
    width: 60%;
}

.top_banner_message__title,
.top_banner__message__title,
.top_banner_phone__title {
    font-size: 18px;
    color: rgb(35, 35, 35);
    line-height: 1.222;
    text-align: left;
    margin-bottom: 26px;
    font-family: $fontOpenSans;
}

.top_banner_message__text,
.top_banner_phone__text {
    font-size: 12px;
    color: rgb(35, 35, 35);
    line-height: 1.714;
    text-align: left;
    font-family: $fontOpenSans;
}

.order_letter__btn,
.order_phone__btn {
    display: block;
    text-transform: uppercase;
    border: 2px solid rgb(27, 26, 34);
    padding: 12px 28px 12px 29px;
    border-radius: 100px/90px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    color: rgb(27, 26, 34);
    margin-top: 86px;
    float: right;
}
.order_phone__btn {
    margin-top: 60px;
}

.more_orders {
    text-align: center;
}

.sub_title {
    font-size: 21px;
    color: rgb(38, 50, 56);
    line-height: 3.81;
    text-align: left;
}

@media only screen and (max-width: 415px) {
  .offer_order_table__wrap {
    max-height: calc(85vh - 100px);
    overflow-y: auto;
  }
}

.offer_order_table {
    .table__cell--header {
        border-top: 1px solid #eceff1;
    }
}

.user_block_offer_order {
    margin-top: 37px;
    padding-bottom: 20px;

    .user__ava__img {
        position: relative;
        top: 3px;
    }
}

.order_msgs {
    font-size: 14px;
    color: rgb(38, 50, 56);
    line-height: 1.714;
    text-align: left;
    margin-bottom: 8px;
    padding: 0 20px;
}

.msgs_link {
    border-bottom: 2px solid #dddfe0;
    cursor: pointer;
}

.order_msg_block {
    padding: 15px 62px 15px 22px;
    background: #eaeff2;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px 0;
        border-color: transparent #eaeff2 transparent transparent;
        left: -10px;
        top: 0;
    }
}

.order_msg_block__text {
    font-size: 14px;
    color: rgb(38, 50, 56);
    line-height: 1.714;
    text-align: left;
}

@media only screen and (max-width: 979px) {
    .offer_button {
        button {
            height: 42px !important;
            padding-right: 26px;
            margin-left: -3px;
            margin-bottom: 10px;
        }
    }

    .offer_button:before {
        content: '';
        width: 0;
        margin: 0;
        padding: 0;
    }
    .offer_order_table {
        margin-top: 32px;
    }

    .order_msg_block {
        padding: 15px 16px 15px 22px;
    }
}

// fix overflow in rotator

.catalog-rotator__itm {
    &.span2 {
        margin-left: 14px;
    }
}

.order_notice {
    height: 110px;
}

.order_notice__text {
    font-size: 36px;
    color: rgb(253, 45, 44);
    line-height: .5;
    text-align: left;
}

.order_notice__prolong {
    padding-right: 60px;

    p {
        color: #61c027;
        font-size: 14px;
        line-height: 1.286;
    }
}

.form-error {
    font-family: $fontOpenSans;
    color: #fd2d2c;
    font-size: 11px;
    &.help-text {
        line-height: 10px;
    }
}

.required_asterisk {
    font-family: $fontArial;
    font-size: 16px;
    color: #fd2d2c;
}

.required-alert {
  font-size: 10px;
  color: #fd2d2c;
}

.help-text__is_required {
    font-family: $fontArial;
    font-size: 12px;
    padding: 36px 0 0;

    &.is_popup {
        font-size: 11px;
        font-family: $fontOpenSans;
        padding: 0;
        margin-bottom: 20px;
        .required_asterisk {
            color: #fd2d2c;
            font-size: 16px;
            position: relative;
            top: 5px;
        }
    }

    span {
        color: #808080;
    }

    .required_asterisk {
        color: #fd2d2c;
        font-size: 16px;
    }
}

.required-alert {
  font-size: 10px;
  color: #fd2d2c;

}

.switch_type {
    font-size: 0;
    height: 33px;
    line-height: 33px;
    box-sizing: border-box;
}

.switch_type__label {
    font-size: 12px;
    text-align: center;
    display: inline-block;
    padding: 0 8px;
    cursor: pointer;
    border-left: 1px solid #dddfe0;
    border-top: 1px solid #dddfe0;
    border-bottom: 1px solid #dddfe0;
    box-sizing: inherit;
    &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    &:last-child {
        border-right: 1px solid #dddfe0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
}

@media only screen and (max-width: 979px) {
    .switch_type__label {
        width: calc(100% / 3);
    }
}

@media screen and (max-width: 400px) {
    .switch_type__label {
        width: calc(100% / 3);
    }
}

.switch_type__radio_input:checked + label {
    background: #fd2d2c;
    color: #fff;
    &:first-of-type {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    &:last-child {
        border-right: 1px solid #dddfe0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

.switch_type__radio_input:not(checked) {
    position: absolute;
    opacity: 0;
}

@media screen and (max-width: 400px) {
    .order_finsh {
        padding-left: 30px;
        width: 100%;
    }
}

.chose_order__title {
    text-align: left;
    margin-top: 40px;
    position: relative;
    top: 2px;

}

.line_bottom {
    width: 685px;
    border-bottom: 1px solid #eceff1;
}

@media only screen and (max-width: 979px) {
    .chose_order__title {
        text-align: left;
    }
}

@media screen and (max-width: 400px) {
    .chose_order__title {
        text-align: left;
    }
}

.descr_item {
    padding-right: 16px;
}

@media only screen and (max-width: 979px) {
    .m-span1 {
        box-sizing: border-box;
        width: 10%;
        display: inline-block;
    }
}

@media screen and (max-width: 400px) {
    .m-span1 {
        box-sizing: border-box;
        width: 20%;
        display: inline-block;
    }
}

.popup_city_alert {
    padding-top: 20px;
}

.popup_city_alert + .m-span-pad {
    padding-bottom: 40px;
}

/** autocomplete */

div.autocomplette {
    margin-bottom: 30px;

    .autocomplette-found,
    .autocomplette-exist {
      position: relative;
      display: none;
      visibility: hidden;

      ul {
        position: absolute;
        top: 0;
        background: #ffffff;
        box-shadow: 0 4px 8px 0 rgba(38, 38, 38, 0.2);
        border-radius: 5px;
        z-index: 99;
        width: 100%;
        max-height: 157px;
        overflow-y: scroll;
        text-align: left;

        li {
          padding: 15px 12px;
          cursor: pointer;

          &:hover {
            background: #ebebeb;
          }
        }
      }
    }
  }
