.chat-reply {
  border-top: 1px solid #F1F3F5;
  border-radius: 4px;
  padding: 18px 15px 0;
  width: 100%;
  background-color: $colorWhite;

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  input {
    width: 100%;
    margin-right: 14px;
  }

  @include for-tablet-portrait-up {
    padding: 18px;
  }

  @include for-desktop-up {
    border: 1px solid #F1F3F5;
  }
}