.grade-list {
  //display: flex;
  //flex-direction: column;
  background-color: #f8f8f8;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 19px;

  &__element {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 8px 12px 8px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EDEDED;
    min-height: 35px;

    &:nth-last-of-type(2) {
      border: none;
    }

    &:last-of-type {
      display: none;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #84878B;
  }

  .master_stars {
    display: flex;
  }

  .master_stars__row {
    flex-direction: row-reverse;
  }

  .master_stars__line {
    width: 62px;
    height: 4px;
    background-color: #ACB0B6;
    border-radius: 0;
    margin-right: 8px;
  }

  .master_stars__line_inner {
    height: 4px;
    border-radius: 0;
  }

  .master_stars__text {
    color: $btnPrimaryColor;
    font-size: 12px;
    line-height: 16px;
    font-family: $fontOpenSansBold;
    margin-right: 0;
  }

  @include for-tablet-portrait-up {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    //display: flex;
    //flex-wrap: wrap;
    //max-height: 125px;
    //flex-direction: column;
    margin-bottom: 26px;
    border-left: 1px solid #EDEDED;
    border-top: 1px solid #EDEDED;


    &__element {
      padding: 9px 12px 10px;
      width: 100%;
      border: none;
      border-right: 1px solid #EDEDED;
      border-bottom: 1px solid #EDEDED;
      min-height: 38px;

      &:nth-last-of-type(2) {
        border-right: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;
      }

      &:last-of-type {
        display: block;
      }
    }

    .master_stars__text {
      margin-left: 0;
    }

    .master_stars {
      padding-top: 0;
    }
  }

  @include for-desktop-up {
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(2, auto);
    //max-height: 87px;
    margin-bottom: 24px;

    &__element {
      //width: 33.33%;
      padding: 9px 13px 10px 15px;

      &:first-of-type {
        order: 0;
      }

      &:nth-of-type(2) {
        order: 1;
      }

      &:nth-of-type(3) {
        order: 4;
      }

      &:nth-of-type(4) {
        order: 2;
      }

      &:nth-of-type(5) {
        order: 3;
      }

      &:nth-of-type(6) {
        order: 5;
      }
    }
  }
}
