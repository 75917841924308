/* stylelint-disable */
.ic {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: middle;
    position: relative;
}

.ic--about-1 {
    height: 44px;
    width: 44px;
}
.ic--about-2 {
    height: 44px;
    width: 44px;
}
.ic--add_file {
    height: 15px;
    width: 15px;
}
.ic--alert-circle {
    height: 32px;
    width: 32px;
}
.ic--alert-triangle {
    height: 16px;
    width: 16px;
}
.ic--arrow-left {
    height: 12px;
    width: 10px;
}
.ic--arrow_down {
    height: 8px;
    width: 12px;
}
.ic--arrow_main {
    height: 48px;
    width: 48px;
}
.ic--asterix {
    height: 6px;
    width: 6px;
}
.ic--award {
    height: 50px;
    width: 39px;
}
.ic--big_yellow_two {
    height: 93px;
    width: 68px;
}
.ic--calendar {
    height: 38px;
    width: 36px;
}
.ic--check {
    height: 10px;
    width: 13px;
}
.ic--check-circle {
    height: 32px;
    width: 32px;
}
.ic--checked {
    height: 10px;
    width: 13px;
}
.ic--checked_master_tooltip {
    height: 19px;
    width: 138px;
}
.ic--choose_master {
    height: 41px;
    width: 41px;
}
.ic--clip {
    height: 15px;
    width: 14px;
}
.ic--close {
    height: 11px;
    width: 11px;
}
.ic--close-hint {
    height: 12.31px;
    width: 12.31px;
}
.ic--close-new {
    height: 10px;
    width: 10px;
}
.ic--cls {
    height: 15px;
    width: 15px;
}
.ic--columns {
    height: 47px;
    width: 47px;
}
.ic--company {
    height: 48px;
    width: 48px;
}
.ic--contractor {
    height: 107px;
    width: 106px;
}
.ic--corner-up-left {
    height: 15px;
    width: 15px;
}
.ic--cross-big {
    height: 23px;
    width: 23px;
}
.ic--cross-new {
    height: 10px;
    width: 10px;
}
.ic--customer {
    height: 99px;
    width: 106px;
}
.ic--dark-lock {
    height: 11px;
    width: 10px;
}
.ic--dark-stars {
    height: 12px;
    width: 81px;
}
.ic--delete {
    height: 10px;
    width: 10px;
}
.ic--disabled {
    height: 37px;
    width: 36px;
}
.ic--done {
    height: 43px;
    width: 36px;
}
.ic--double-arrows {
    height: 7px;
    width: 9px;
}
.ic--download {
    height: 14px;
    width: 10px;
}
.ic--dropdown-arrow {
    height: 8px;
    width: 12px;
}
.ic--excellent {
    height: 82px;
    width: 82px;
}
.ic--excellent-mini {
    height: 17px;
    width: 18px;
}
.ic--exp {
    height: 82px;
    width: 83px;
}
.ic--exp-mini {
    height: 17px;
    width: 18px;
}
.ic--face {
    height: 48px;
    width: 48px;
}
.ic--failed {
    height: 15px;
    width: 15px;
}
.ic--fav-tag {
    height: 14px;
    width: 12px;
}
.ic--free {
    height: 48px;
    width: 48px;
}
.ic--good-start {
    height: 82px;
    width: 82px;
}
.ic--good-start-mini {
    height: 17px;
    width: 17px;
}
.ic--green-check {
    height: 8px;
    width: 10px;
}
.ic--hobby {
    height: 60px;
    width: 60px;
}
.ic--ic_dnd {
    height: 52px;
    width: 66px;
}
.ic--ic_sort {
    height: 8px;
    width: 9px;
}
.ic--key {
    height: 8px;
    width: 15px;
}
.ic--key-order {
    height: 50px;
    width: 50px;
}
.ic--king {
    height: 15px;
    width: 15px;
}
.ic--left-arrow {
    height: 16px;
    width: 16px;
}
.ic--liga {
    height: 13px;
    width: 24px;
}
.ic--liga-full {
    height: 89px;
    width: 110px;
}
.ic--liga-small {
    height: 10px;
    width: 18px;
}
.ic--lock {
    height: 13px;
    width: 11px;
}
.ic--lock-open {
    height: 15px;
    width: 11px;
}
.ic--lock-red {
    height: 16px;
    width: 15px;
}
.ic--logo-gr {
    height: 11px;
    width: 20px;
}
.ic--mail {
    height: 41px;
    width: 51px;
}
.ic--main_arrow_left {
    height: 24px;
    width: 32px;
}
.ic--main_arrow_right {
    height: 24px;
    width: 32px;
}
.ic--map-tag {
    height: 13px;
    width: 9px;
}
.ic--master {
    height: 48px;
    width: 48px;
}
.ic--mob_error {
    height: 50px;
    width: 50px;
}
.ic--next {
    height: 10px;
    width: 12px;
}
.ic--notify {
    height: 50px;
    width: 50px;
}
.ic--object {
    height: 112px;
    width: 145px;
}
.ic--offer_order {
    height: 44px;
    width: 48px;
}
.ic--ok {
    height: 9px;
    width: 11px;
}
.ic--ok_prime {
    height: 9px;
    width: 11px;
}
.ic--pencil {
    height: 10px;
    width: 10px;
}
.ic--pencil-blank {
    height: 16px;
    width: 16px;
}
.ic--photo_placeholder {
    height: 50px;
    width: 60px;
}
.ic--place_order {
    height: 48px;
    width: 48px;
}
.ic--popular {
    height: 82px;
    width: 82px;
}
.ic--popular-mini {
    height: 17px;
    width: 17px;
}
.ic--prev {
    height: 10px;
    width: 12px;
}
.ic--priceless {
    height: 82px;
    width: 82px;
}
.ic--priceless-mini {
    height: 17px;
    width: 17px;
}
.ic--reopen {
    height: 12px;
    width: 15px;
}
.ic--result {
    height: 48px;
    width: 48px;
}
.ic--review {
    height: 48px;
    width: 48px;
}
.ic--review_cloud {
    height: 12px;
    width: 12px;
}
.ic--round_waiting {
    height: 30px;
    width: 30px;
}
.ic--search {
    height: 17px;
    width: 17px;
}
.ic--send {
    height: 18px;
    width: 18px;
}
.ic--send-black {
    height: 18px;
    width: 18px;
}
.ic--shield {
    height: 43px;
    width: 36px;
}
.ic--skill-1 {
    height: 47px;
    width: 33px;
}
.ic--skill-2 {
    height: 48px;
    width: 43px;
}
.ic--skill-3 {
    height: 45px;
    width: 45px;
}
.ic--skill-4 {
    height: 43px;
    width: 43px;
}
.ic--skill-5 {
    height: 48px;
    width: 38px;
}
.ic--skill-6 {
    height: 46px;
    width: 33px;
}
.ic--skill-7 {
    height: 34px;
    width: 48px;
}
.ic--skill-8 {
    height: 44px;
    width: 35px;
}
.ic--smile {
    height: 48px;
    width: 48px;
}
.ic--sms {
    height: 34px;
    width: 36px;
}
.ic--sortable-handle {
    height: 10px;
    width: 10px;
}
.ic--speaker {
    height: 46px;
    width: 54px;
}
.ic--sprite {
    height: 0px;
    width: 0px;
}
.ic--star {
    height: 16px;
    width: 16px;
}
.ic--star-achive {
    height: 82px;
    width: 83px;
}
.ic--star-achive-mini {
    height: 17px;
    width: 18px;
}
.ic--star_half {
    height: 16px;
    width: 16px;
}
.ic--stars-grey {
    height: 18px;
    width: 90px;
}
.ic--stars1 {
    height: 30px;
    width: 94px;
}
.ic--stars2 {
    height: 34px;
    width: 94px;
}
.ic--stub_icon {
    height: 48px;
    width: 48px;
}
.ic--subscribe-disabled {
    height: 16px;
    width: 15px;
}
.ic--subscribe-enabled {
    height: 16px;
    width: 14px;
}
.ic--swiper-arrow-left {
    height: 10px;
    width: 12px;
}
.ic--swiper-arrow-right {
    height: 10px;
    width: 12px;
}
.ic--telegram {
    height: 240px;
    width: 240px;
}
.ic--time {
    height: 11px;
    width: 11px;
}
.ic--upload {
    height: 46px;
    width: 71px;
}
.ic--upload-orange {
    height: 46px;
    width: 71px;
}
.ic--user {
    height: 24px;
    width: 24px;
}
.ic--wise {
    height: 82px;
    width: 82px;
}
.ic--wise-mini {
    height: 17px;
    width: 17px;
}
.ic--wreath {
    height: 75px;
    width: 167px;
}
/* stylelint-enable */