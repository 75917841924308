@media (min-width: 1024px) {
  .lt_1col .page_title {
    margin-left: 120px;
  }
}

.masters-listing {
  padding-left: 0;
}

.main_portfolio {
  padding: 50px 0 80px
}

.container-wrapper {
  margin-left: 120px;
  margin-right: 150px;
}

.info-top {
  display: flex;
  position: relative;
}

.is_gray_wrapper {
  .main_testimonials {
    background: #F5F6F7;
  }
  .master_feedback {
    background: #fff;
  }
}

.main_ready_banner {
  text-align: center;
  background: url("../images/check-mark.png") no-repeat 52%;

  .main__section_title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;

    text-align: center;

    color: #262626;
  }

  .main__section_sub_title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #919599;
  }
}

.master-grid-container {
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr;
  grid-template-rows: 0.7fr 0.7fr;

  grid-template-areas: "master master photos photos" "inf-1 inf-1 photos photos";

  & > div {
    border: 1px solid #EDEEF0;
  }

  .master {
    .fav_link {
      position: unset;
      svg.ic.ic--fav-tag {
        height: 15px;
        width: 15px;
        margin-top: -5px;
      }
    }
  }
}

.photos {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 1fr;
  grid-template-rows: 1.4fr 0.6fr;
  gap: 1px 1px;
  grid-template-areas: "photo-items photo-items photo-items" "btn-1 btn-1 btn-1";
  grid-area: photos;

  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #262626;
  }
}

.photo-items {
  grid-area: photo-items;
  border-bottom: 2px solid #EDEEF0;
  padding: 2%;
  &-wrapper {
    display: flex;
    margin-top: 15px;
    //justify-content: space-between;
    .photo_item__img {
      //width: 30%;
      height: 150px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.filter-check__mobile{
  display: none;
}

.listing.listing--v2.masters-listing p{
  text-align: center;
  padding: 0 15px;
}

.is_gray_wrapper{
  margin: 50px 0 0;
}

.filter_block {
  display: flex;
  border-bottom: 2px solid #EDEEF0;
  justify-content: space-between;
  padding-bottom: 50px;
  margin-bottom: 50px;
  align-items: flex-end;
  .dropdown--autocomplete {
    &:before {
      display: none;
    }
  }
  .ic--dropdown-arrow {
    position: absolute;
    right: 15px;
    top: 20px;
    fill: #C4C4C4;
    z-index: 5;
  }

  .filter_item {

    &.apply {
      display: flex;
      align-items: flex-end;
    }
  }

  .dropdown__list {
    &.additional_filer {
      padding: 15px;
      min-height: 200px;

      .radio_list {
        border-bottom: 1px solid #EDEEF0;
        .radio_item {
          &__text {
            color: #808080;
          }
          margin-bottom: 7%;
          &__checked-active {
            background-color: #fff;
            & + .radio_item__text {
              color: #000;
            }
          }
          &__checked-active:before {
            width: 13px;
            height: 13px;
            background-color: #FFD24D;
          }
        }
      }
    }
  }

  .only_checked_masters {
    margin-bottom: 0;
    .switcher {
      margin-top: 20px;
      .toggle {
        .minitoggle {
          width: 50px;
        }
        .text {
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }
  }

}

.master_list {
  margin: 3%;
}

.master_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-review {
  width: 70%;
}

.btn-chat {
  width: 90%;
}

.btn-1 {
  grid-area: btn-1;

  height: 90px;

  .button {
    margin: 5%;
  }
}

.btn-2 {
  grid-area: btn-2;
}

.master {
  grid-area: master;
  padding: 2%;

  &_skills {
    font-size: 12px;
    line-height: 18px;
    color: #262626;
    margin-top: 10px;
    .more {
      color: #a5a8ad;
    }
  }

  .help-text {
    display: inline-block;
    height: 17px;
    line-height: 17px;
    white-space: nowrap;
    border-radius: 18px;
    background-color: #f4f4f4;
    font-size: 11px;
    padding: 0 8px;
    color: #262626;
    margin-right: 6px;
    vertical-align: middle;
    margin-bottom: 6px;
    cursor: pointer;
  }
  .ic--star {
    fill: #ffd24d;
    width: 12px;
    height: 12px;
    top: -1px;
  }

  &_rating {
    .ic--star {
      fill: #E3E5E8;
      width: 15px;
      height: 15px;
    }
    .yellow {
      fill: #ffd24d;
    }
    .number {
      font-weight: 600;
      font-size: 12px;
      color: #333333;
    }
  }
  .main_skill {
    font-size: 12px;
  }
  .count_review {
    .number {
      font-weight: 600;
    }
    font-size: 12px;
    color: #333333;
  }

  &-info {
    display: flex;

    .title {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;
      color: #262626;
    }

    .ava_wrapper {
      width: 54px;
      min-width: 54px;
      height: 54px;
      margin-right: 18px;
      border-radius: 50%;
      overflow: hidden;
    }
    .checked_master {
      border: 2px solid #FFD24D;
    }
    .achivements-mini {
      padding: 3px;
      padding-left: 10px;
    }
  }

  &_bottom_info {
    display: flex;
    justify-content: space-between;
  }
}

.inf-1 {
  position: relative;
  grid-area: inf-1;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #a5a8ad;

  &:after {
    width: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    content: '';
    display: none;
    background: #edeef0;
  }

  &.empty:after {
    display: none;
  }

  @include tablet-up {
    &:after {
      display: block;
    }
  }
}

.master-prices {
  width: 100%;
  padding: 4px 0;
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;

  @include tablet-up {
    padding: 0;
  }

  @include tablet-up {
    flex-direction: row;
  }
}

.master-prices__btn-all {
  margin-top: 5px;
  color: #d9aa21;

  @include tablet-up {
    margin-top: 0;
  }
}

.master-price {
  width: 100%;
  padding: 3px 14px;
  display: flex;
  justify-content: space-between;
  color: #262626;
  font-size: 12px;
  line-height: 18px;

  @include tablet-up {
    width: 50%;
    max-width: 50%;
    padding: 6px 24px;
  }
}

.master-price__label {

}

.master-price__val {
  font-weight: 600;
}

.image--master {
  position: absolute;
  right: 10%;
  bottom: -4px;
  z-index: 1;
}

.subtitle {
  font-family: Open Sans;
  font-size: 20px;
  color: #262626;
}

.master_specs {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #262626;

  display: flex;
  //flex-wrap: wrap;
  padding: 50px 0 0;
  justify-content: space-between;
  //width: 80%;
}

.info--master {
  position: relative;
  z-index: 2;
  width: 50%;
  .page_title__text {
    width: 80%;
    margin-bottom: 5%;
  }
}

.master_spec {
  display: flex;
  margin-bottom: 77px;
  width: calc(100% * (1 / 3) - 11px);
  align-items: center;
  margin-right: 20px;

  &:first-child {
    width: 50px;
    min-width: 99px;
  }

  &:last-child {
    width: 290px;
  }

  &:before {
    content: '';
    display: block;
    border: 4px solid #FFD24D;
    margin-right: 20px;
    height: 50px;
  }
}

.create_order_banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Open Sans;
  font-weight: 600;
  background: #EAECEF;
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  height: 115px;
  padding-left: 120px;
  padding-right: 200px;
}

@media (max-width: 1024px) {

  .master_specs {
    justify-content: unset;
    flex-wrap: wrap;
  }

  .masters-listing {
    padding-left: 20px;
  }
  .filter_block {
    padding: 20px;
  }
  .container-wrapper {
    margin-left: 25px;
    margin-right: 25px;
  }

  .info--master {
    width: 80%;
  }
  .image--master {
    right: -15%;
  }
  .master_spec {
    &:first-child {
      width: 100px;
    }
  }
  .create_order_banner {
    padding-left: 50px;
    padding-right: 50px;
  }

  .main_testimonials__container {
    margin: 41px auto 0;
    .master_feedback {
      margin: 0;
      padding: 35px 40px;
      &:not(.slick-current) {
        filter: unset;
        opacity: 1;
      }
    }
  }

  .btn-1 .button {
    margin: auto;
  }
  .btn-review {
    width: 100%;
  }

  .btn-chat {
    width: 100%;
  }
}

.filter_item .section_title{
  margin: 20px 0 10px;
}

@media (max-width: 768px) {
  p.section_title {
    margin: 20px 0 10px 0;
  }
  .filter_block .skill-chouser{
    margin: 0 !important;
  }

  .filter-check__mobile{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    justify-content: center;
    padding-bottom: 34px;
    margin-top: 48px;
    &:after{
      content: url(../images/filter-check__arrow.svg);
      margin-left: 8px;
      transition: .25s;
    }
    &.active{
      &:after{
        transform: rotate(180deg);
      }
    }
  }
  .container-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .filter_block {
    display: none;
    padding-top: 0;
  }

  .info-top {
    display: block;
  }

  .master_button {
    display: block;
    height: auto;
    text-align: center;
  }
  .btn-1 .button {
    margin: 2%;
  }
  .btn-review {
    width: 80%;
    font-size: 12px;
  }

  .btn-chat {
    width: 80%;
    font-size: 12px;
  }

  .master-grid-container {
    display: block;
  }
  .main_warranty {
    background-position: 0;
  }
  .create_order_banner {
    .button__place_order {
      margin: 10%;
    }
  }
  .page_content_section_head_title {
    text-align: center;
  }
  .breadcrumbs {
    padding-left: 30%;
  }

  .info--master {
    width: 100%;
    text-align: center;
    .page_title__text {
      width: 100%;
      margin-bottom: 5%;
    }
  }
  .master_specs {
    padding: 20px 0 0;
  }
  .master_spec {
    margin-bottom: 10px;
  }
  .subtitle {
    font-size: 16px;
  }
  .image--master {
    right: 8%;
    width: 400px;
    bottom: 0;
    position: relative;
    height: 260px;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .create_order_banner {
    text-align: center;
    display: block;
    padding: 10%;
    height: 260px;
  }
}
