.hero-adv {
    margin-left: auto;
    margin-right: auto;

    img {
        max-width: 100%;
        display: block;
    }

    p {
        line-height: 1;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.hero-adv--horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 710px;

    .main_section & {
        padding-bottom: 44px;
    }

    .section_order & {
        justify-content: flex-start;
        margin-left: inherit;
    }

    img {
        max-width: 100%;
        display: block;
    }
}

.hero-adv--vertical {
    max-width: 240px;
    display: none;

    @include tablet-up {
        margin-top: 58px;
        display: block;
    }
}

.advertising_wrap {
    width: 100%;
    display: none;
}

.table__cell {
    .mobile_wrap {
        background: $colorWhite;
        top: -5px;
        position: relative;
    }
}

.mobile_wrap {
    width: 100%;
}

.desktop_wrap {
    width: 100%;
}

tr.table__row.table__row--adv.advertising_wrap.js_advertising {
    display: none;
}