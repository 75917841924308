@import 'controls/_phone_control';

.button {
    height: 44px;
    border-radius: 22px;
    font-family: $fontOpenSansSemibold;
    font-size: 14px;
    text-align: center;
    color: $textPrimaryColor;
    line-height: 44px;
    padding: 0 20px;
    text-transform: none;
    text-decoration: none;
    border: none;
    box-sizing: border-box;
    transition: background-color $animationTime $animationFunc, border-color $animationTime $animationFunc, color $animationTime $animationFunc;

    &.is_sidebar_btn {
        width: 100%;
    }
}

.button-telegram {
    width: calc(100% - 4px);
    border: 1px solid #C6C7CE;
    border-radius: 4px;
    padding: 9px 11px 11px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    //max-width: 280px;
    position: relative;
    margin: 0 2px;
    align-items: center;
    height: auto;
    box-sizing: border-box;

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: 0 auto;
        position: relative;
        width: 260px;

        &:after {
            content: '';
            background-color: transparent;
            width: 6px;
            height: 6px;
            position: absolute;
            top: 50%;
            right: 10px;
            display: block;
            transform: translateY(-50%) rotate(45deg);
            border-right: 1px solid #181818;
            border-top: 1px solid #181818;
        }
    }

    &:hover {
        color: transparent;
        border-color: #C6C7CE;
        box-shadow: 0 0 0 2px #F2F2F2 inset, 0 0 0 2px #F2F2F2 inset;
    }


    &__text {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__title {
        font-size: 12px;
        line-height: 20px;
        color: #A5A8AD;
        letter-spacing: -0.2px;
        padding-right: 3px;
    }

    &__title--bot {
        color: $colorBlack;
        //padding-right: 0;
    }

    .ic--telegram {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
        margin-right: 12px;
    }

    @include for-tablet-portrait-up {
        padding: 8px 9px 10px;
        max-width: 162px;
        margin: 0;
        margin-right: auto;
        margin-top: 9px;

        &__wrapper {
            align-items: center;
        }

        &__wrapper:after {
            display: none;
        }

        &__text {
            flex-wrap: wrap;
        }

        .ic--telegram {
            margin-bottom: 3px;
            margin-right: 9px;
        }
    }

    @include for-tablet-landscape-up{
        align-items: center;
        padding: 12px 9px 10px 15px;
        width: 216px;
        max-width: 216px;

        .ic--telegram {
            width: 34px;
            height: 34px;
            min-width: 34px;
            min-height: 34px;
            margin-right: 14px;
        }


        &__title {
            font-size: 14px;
        }
    }

}

.button__primary {
    background-color: $btnPrimaryColor;

    &:hover {
        color: $textSecondaryHoverColor;
        background-color: $btnPrimaryHoverColor;
    }
}

.button__secondary {
    background-color: $btnSecondaryColor;

    &:hover {
        color: $textSecondaryHoverColor;
        background-color: $btnSecondaryHoverColor;
    }

    &--disabled {
        color: $textSecondaryHoverColor;
        background-color: $btnSecondaryHoverColor;
        opacity: 0.5;
        pointer-events: none;
    }

    &--chat {
        color: #FF4545;
    }
}

.button__delete {
    background: $btnRedColor;
    width: 200px;
    color: $colorWhite;

    @include mobile {
        width: 100%;
    }

    &:hover {
        background: #ff3d47;
        color: $colorWhite;
    }

    &[disabled="disabled"],
    &.is_disabled {
        background: $btnSecondaryColor;
        color: $placeholderColor;
        cursor: not-allowed;
    }
}

.button__login {
    .ic {
        top: -2px;
        margin-right: 10px;
    }

    .ic--lock-open {
        fill: $textSecondaryHoverColor;
        display: none;
    }

    &:hover {
        .ic--lock {
            display: none;
        }

        .ic--lock-open {
            display: inline-block;
        }
    }
}

.button--border {
    border: solid 2px $btnSecondaryColor;
    font-size: 14px;
    color: $textSecondaryColor;
    line-height: 42px;
    font-weight: normal;

    &:hover {
        border-color: $btnSecondaryHoverColor;
        color: $textSecondaryHoverColor;
        background-color: $btnSecondaryHoverColor;
    }
}

.button--transparent {
    display: none;

    .ic--left-arrow {
        width: 16px;
        height: 16px;
        margin-right: 12px;
    }

    @include for-tablet-portrait-up {
        font-family: $fontOpenSans;
        display: inline-flex;
        align-items: center;
        border: 1px solid $btnSecondaryColor;
        border-radius: 4px;
        font-size: 14px;
        line-height: 19px;
        color: #4D5054;
        padding: 12px 20px 12px 15px;
        margin-left: 20px;
        margin-bottom: 31px;
        margin-top: 23px;

        &:hover {
            color: #4D5054;
            border: 1px solid $btnSecondaryColor;
            box-shadow: 0 0 0 2px #F2F2F2 inset, 0 0 0 2px #F2F2F2 inset;
        }

    }

    @include for-tablet-landscape-up {
        margin-top: 37px;
        margin-left: 30px;
        margin-bottom: 12px;
    }

    @include for-desktop-up {
        margin-left: 80px;
    }
}
.button--open_filters {
    border: solid 2px $btnSecondaryColor;
    font-size: 14px;
    font-family: $fontOpenSansSemibold;
    color: $textPrimaryColor;
    line-height: 40px;
    width: 93%;
    margin: 0 auto 8px;
    display: block;

    @include for-tablet-landscape-up {
        display: none;
    }
}

.button--show_more {
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 200px;
}

.button--icon {
    width: 20px;
    height: 20px;
    padding: 0;
    background-color: transparent;
    border: none;
    text-align: center;
    line-height: 20px;

    .ic {
        fill: $textPrimaryColor;
    }

    position: relative;

    &:before,
    &:after {
        opacity: 0;
        visibility: hidden;
        transition: opacity $animationTime $animationFunc;
        height: 20px;
        position: absolute;
        top: 0;
        left: 100%;
        display: block;
        z-index: 110;
    }

    &:before {
        content: '';
        background: url(/media/images/fav-label-tag.png) 0 0 no-repeat;
        width: 8px;
        margin-left: 13px;
    }

    &:after {
        content: attr(data-label);
        line-height: 20px;
        font-size: 12px;
        color: $colorWhite;
        margin-left: 20px;
        border-radius: 0 3px 3px 0;
        background-color: $textSecondaryHoverColor;
        white-space: nowrap;
        padding: 0 5px;
    }

    &:hover {
        .ic {
            fill: $textSecondaryHoverColor;
        }
    }

    @include desktop-up {
        &:hover {
            &:before,
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.showmore__link {
    font-size: 12px;
    font-family: $fontOpenSansSemibold;
    color: $textSecondaryColor;

    &:hover {
        color: $textSecondaryHoverColor;
    }
}

input {
    &:focus {
        outline-style: none;
        box-shadow: none;
    }
}

button {
    &:focus {
        outline-style: none;
        box-shadow: none;
    }
}

.text_descr {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    line-height: 30px;
    text-transform: none;
    font-weight: 400;
    display: block;
    @include mobile {
        line-height: 24px;
    }
}

.button--confirm {
    font-size: 14px;
    letter-spacing: 0;
    border: 1px solid $btnSecondaryColor;
    border-radius: 21px;
    background: $btnSecondaryColor;
    font-family: $fontOpenSansSemibold;
    color: $textPrimaryColor;
    display: inline-block;
    cursor: pointer;
    width: 160px;

    &:hover {
        border: 1px solid $lineColor;
        color: $textSecondaryHoverColor;
        background-color: $lineColor;
    }

    &.is_edit {
        padding: 7px 19px 8px 18px;
        line-height: 17px;
        height: auto;
        width: auto;
    }

    &.is_offer {
        padding: 8px 38px 10px;
        line-height: 17px;
        height: auto !important;
        width: auto;
    }

    &.is_create {
        padding: 9px 28px;
        height: auto !important;
        width: auto;
        line-height: 17px;
    }

    &.button__primary {
        background: $btnPrimaryColor;
        border: none;

        &:hover {
            border: none;
            background: $btnPrimaryColor;
        }
    }

    @include for-phone-only {
        width: auto;

        &.is_offer,
        &.is_edit {
            &.button__primary {
                margin-left: 70px;
                margin-top: 10px;
            }
        }

        &.button__small {
            padding: 0;
        }
    }

    @include for-tablet-portrait-only {
        &.button__small {
            padding: 0;
        }
    }
}

.button--confirm:disabled,
.button--confirm[disabled] {
    background: $btnSecondaryColor;
    color: $colorWhite;
    cursor: not-allowed;
}

.button--confirm:hover {
    border: 1px solid $lineColor;
    color: $textSecondaryHoverColor;
    background-color: $lineColor;
}

.button__search {
    background-image: none;
    width: 44px;
    height: 44px;
    border-radius: 0 4px 4px 0;
    padding: 0;

    .search__container:hover & {
        background-color: $btnPrimaryHoverColor;
    }
}

.button--chat {
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    transition-property: background, border;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    outline: none;
    background-image: none;
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    border-radius: 4px;
    padding: 0;

    &:hover {
        background-color: $btnPrimaryHoverColor;
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.check_item {
    position: relative;
    display: flex;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;

    .check_item__input {
        opacity: 0;
    }

    input[type="checkbox"] {
        visibility: hidden;
    }
}

input[type=checkbox]:checked + .check_item__checked {
    background: $btnPrimaryColor;
    border-color: $btnPrimaryColor;

    &:before {
        display: block;
    }
}

input[type=checkbox]:disabled + .check_item__checked + .profile_create_spec__text {
    color: $textSecondaryColor;
    cursor: not-allowed;
}

input[type=checkbox]:disabled + .check_item__checked {
    font-size: 14px;
    color: $colorDisabled;
    letter-spacing: 0;
    line-height: 40px;
    cursor: not-allowed;
    background: $colorWhite;
    border: 1px solid $lineColor;
    width: 25px;
    height: 25px;

    &:before {
        display: none;
    }
}

.check_item__checked {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    border-radius: 4px;
    left: -5px;
    display: inline-block;
    border: 1px solid $inputBorder;
    background: $colorWhite;

    &:before {
        content: '';
        background: url(/media/images/ok.svg);
        width: 11px;
        height: 9px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: none;
        transform: translate(-50%, -50%);
    }

    .listing_sidebar & {
        border-color: $lineColor;
    }

    @media (max-width: 599px) {
        width: 18px;
        height: 18px;
        left: 2px;
        top: 5px;
    }
}

.check_item__text {
    font-size: 14px;
    color: $textPrimaryColor;
}

.select_spec {
    position: absolute;
    top: 50px;
    left: 0;
    background: $colorWhite;
    z-index: 999;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .05);
    border-radius: 5px;
    padding-top: 25px;
    padding-left: 19px;
    overflow-y: auto;

    @include mobile {
        position: static;
        padding-bottom: 25px;
    }

    @include tablet-up {
        padding-bottom: 25px;
        height: 480px;
    }

    @include for-desktop-up {
        padding-left: 12px;
    }
}

.select_spec_items {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 25px;
    display: none;
    position: static;

    @include for-desktop-up {
        position: absolute;
        left: 300px;
        top: 27px;
        padding-right: 12px;
    }
}

.select_spec_items__item {
    line-height: normal;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &:hover {
        //color: $colorYellow;
        cursor: pointer;
        background-color: #EDEEF0;
    }

    &--active {
        position: relative;
        background-color: $btnPrimaryColor !important;

        &::after {
            display: block;
            content: "";
            position: absolute;
            top: 5px;
            left: -10px;
            border-bottom: 2px solid #000000;
            border-left: 2px solid #000000;
            transform: rotate(-45deg);
            height: 4px;
            width: 8px;
            z-index: 1;
            background-color: transparent;
        }
    }
}

.select_spec_categories__item {

    font-family: $fontOpenSansBold;
    font-size: 14px;
    color: $textSecondaryColor;
    line-height: 20px;

    span {
        position: relative;
        cursor: pointer;
        width: 100%;
        //display: block;

        &:hover {
            background-color: rgba(237, 238, 240, 1) !important;
        }

        &.is_active {
            color: $textPrimaryColor;
            background-color: $btnPrimaryColor;

            &:after {
                content: '';
                width: 7px;
                height: 10px;
                background: url(/media/images/arrow.svg);
                position: absolute;
                right: -26px;
                top: 6px;
                @include small-desktop {
                    background: none;
                }
                @include tablet {
                    background: none;
                }
                @include mobile {
                    background: none;
                }
            }
        }
    }
}

.sub-wrap {
    width: 50%;
    background-color: rgba(237, 238, 240, 0.5);
    border-radius: 3px;
    overflow-y: scroll;
}

.select_spec_categories {
    margin-bottom: 20px;
}


.button__newsletter {
    display: block;
    width: 160px;
}

.form_newsletter__personal_data {
    a {
        display: inline;
    }
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;

    @include mobile {
        overflow-x: auto;
    }

    //@include tablet {
    //    flex-wrap: wrap;
    //    max-width: 90%;
    //}
}

.breadcrumb__item {
    margin-right: 28px;
    position: relative;

    &:last-of-type {
        margin-right: 0;

        &:after {
            position: static;
        }
    }

    a {
        font-family: $fontOpenSans;
        font-size: 12px;
        color: $textSecondaryColor;
        letter-spacing: 0;

        &:hover {
            color: $textSecondaryHoverColor;
        }
    }

    > span {
        font-family: $fontOpenSans;
        font-size: 12px;
        color: $textSecondaryColor;
        letter-spacing: 0;
    }

    &:after {
        content: '';
        width: 10px;
        height: 5px;
        background: url(/media/images/bd_divider.svg) no-repeat;
        right: -18px;
        top: 10px;
        position: absolute;
    }

    @include mobile {
        white-space: nowrap;
    }
}

.file_input_link {
    display: inline-table;
    padding-top: 12px;
    cursor: pointer;

    @include for-tablet-portrait-up {
        padding-top: 18px;
    }

    @include for-tablet-landscape-up {
        padding-top: 28px;
    }
}

.file_input_link__msg {
    font-family: $fontOpenSansSemibold;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    line-height: 15px;

    &:after {
        content: '+';
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $colorDisabled;
        text-align: center;
        line-height: 15px;
        right: -23px;
        top: 1px;
        font-family: inherit;
        font-size: 12px;
        color: $colorWhite;
        letter-spacing: 0;
        display: inline-block;
        margin-left: 8px;
    }
}

.file_input_link__input {
    display: none;
}

.button__small {
    height: 34px;
    line-height: 34px;
    padding: 0 35px;

    .ic--lock-open {
        margin-right: 14px;
    }

    @media (max-width: 600px) {
        padding: 0 31px;
    }
}

.card_profile_msg {
    background: $bgColor;
    border-radius: 8px;
    width: 100%;
    padding: 17px 27px 21px;
    margin: 36px 0 19px;
    line-height: 21px;
    font-size: 14px;

    &.is_order {
        padding: 32px 40px 33px;
    }
}

.top_msg {
    padding: 68px 0 75px 80px;
    background-color: $bgColor;
    @include mobile {
        padding: 22px 20px 25px;
    }
}

.top_msg__msg {
    font-family: $fontOpenSans;
    font-size: 18px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 30px;
    max-width: 711px;
    padding-bottom: 31px;
    @include mobile {
        font-size: 14px;
        line-height: 25px;
        max-width: 280px;
        padding-bottom: 21px;
    }
}

.top_msg__text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 14px;
    @include mobile {
        font-size: 12px;
        max-width: 220px;
    }
}

.file-preview {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;

    .file-preview-thumbnails {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.phone_service_text {
    font-family: $fontOpenSans;
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 40px;
}

.user_message {
    background: $bgColor;
    border-radius: 8px;

    p {
        font-family: $fontOpenSans;
        font-size: 14px;
        color: $textPrimaryColor;
        letter-spacing: 0;
        padding: 12px 34px 10px 27px;
        margin: 0;
    }
}

.delete_item {
    &:hover {
        color: $textPrimaryColor;
    }
}

.file_item {
    border: 1px solid $lineColor;
    border-radius: 4px;
    padding: 14px 0 14px 40px;
    margin-bottom: 26px;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;

    &:before {
        content: '';
        width: 11px;
        height: 9px;
        background: url(/media/images/is_confirmed.svg) no-repeat;
        position: absolute;
        left: 18px;
        top: 21px;
    }

    &:first-of-type {
        margin-top: 26px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}


.file_item__control {
    .ic--delete {
        position: static;
        padding: 0;
        margin-right: 19px;
    }
}

.file_descr_text {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    line-height: normal;
    width: 100%;
    margin-top: 10px;
    display: block;

    @include for-tablet-portrait-up {
        line-height: 20px;
    }

    @include for-desktop-up {
        text-align: center;
        margin-top: 16px;
    }

    @media (max-width: 900px) {
        font-size: 10px;
        line-height: 14px;
        margin-top: 17px;
    }

    @media (max-width: 599px) {
        margin-top: 9px;
    }
}

.portfolio_controls__btn {
    font-family: $fontOpenSans;
    font-size: 24px;
    color: $textPrimaryColor;
    line-height: normal;
    margin-top: 10px;
}

.dnd_file_input {
    width: 100%;
    margin-top: 13px; //from 21
    max-height: 454px;
    position: relative;
    overflow-y: auto;


    @media (max-height: 1280px) {
        max-height: 100%;
        overflow-y: visible;
    }

    @media (min-width: 600px) and (max-width: 1279px){
        margin-top: 10px;
    }

    @media (max-width: 599px) {
        margin-top: 27px;
    }

}

.dnd_file_input__text {
    text-align: center;
    font-family: $fontOpenSans;
    font-size: 14px;
    color: $textSecondaryColor;
    line-height: normal;
    margin-top: 10px;
}

.dns_errors_container {
    margin-top: 15px;

    p {
        padding-bottom: 15px;
    }

    .error {
        color: red;
    }

    .descr {
        color: $textPrimaryColor;
    }
}

.dnd_file_error {
    color: red;
    padding-bottom: 15px;
}

.dnd_file_descr {
    color: $textPrimaryColor;
    padding-bottom: 15px;
}

.dnd_file_input__label {
    display: block;
    width: 100%;
    text-align: center;
}

.page_content__right {
    .button {
        width: 100%;

        margin-top: 35px;
        &.js_offer_order{
            margin-top: 0;
        }
    }
}

//.location_selector {
//    margin-bottom: 26px;
//}

.delete_popup {
    background: $colorWhite;
    box-shadow: 0 4px 16px 0 rgba(38, 38, 38, 0.10);
    border-radius: 10px;
    max-width: 640px;
    margin: 0 auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 202;
    padding: 69px 83px 69px 80px;
    display: none;
}

.delete_popup__inner {
    display: flex;
    flex-direction: column;
}

.delete_popup__top {
    display: flex;
    flex-direction: row;
}

.delete_popup__title {
    font-family: $fontOpenSansBold;
    font-size: 20px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 15px;
}

.delete_popup__msg {
    margin-bottom: 32px;
    font-family: $fontOpenSans;
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 25px;
}

.delete_popup__btns {
    display: flex;
    justify-content: space-between;
    max-width: 420px;

    .button__secondary {
        width: 200px;
    }
}

.delete_msg {
    font-family: $fontOpenSans;
    font-size: 12px;
    color: $textSecondaryColor;
    letter-spacing: 0;
    margin-top: 11px;
}

.order_file_item {
    border: 1px solid $btnSecondaryColor;
    border-radius: 4px;
    max-width: 479px;
    height: 44px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    &:last-of-type {
        margin-bottom: 0;
    }

    &:hover {
        border: 1px solid $btnSecondaryColor;
    }
}

.order_file_item__icon {
    margin: 0 21px;
}

.order_file_item__file_name {
    font-family: $fontOpenSans;
    font-size: 14px;
    color: $textPrimaryColor;
    letter-spacing: 0;
    line-height: 24px;
}

.spec_wrap {
    position: relative;

    &:after {
        content: '';
        width: 7px;
        height: 11px;
        background: url(/media/images/arrow.svg) no-repeat;
        position: absolute;
        right: 22px;
        top: 17px;
        transform: rotate(90deg);
    }
}

.form_spec {
    .spec_wrap {
        position: relative;
        margin-bottom: 30px;

        &:after {
            content: '';
            width: 7px;
            height: 11px;
            background: url(/media/images/arrow.svg) no-repeat;
            position: absolute;
            right: 15px;
            top: 17px;
            transform: rotate(90deg);
        }
    }
}

.top_banner {
    .wrapper {
        &.is_top_banner_width_icon {
            width: auto;
            display: flex;
            margin: 41px auto 44px;
            align-items: center;
            padding: 0 20px;

            @include tablet-up {
                padding-left: 26px;
            }

            @include desktop-up {
                padding: 0 30px 0 90px;
            }
        }

        .top_banner_message,
        .top_banner_phone {
            margin: 0 auto 0 0;
            @include mobile {
                width: auto;
            }
        }

        .top_banner__message__icon {
            margin-right: 102px;
            @include mobile {
                display: none;
            }
        }

        .top_banner__close {
            align-self: flex-start;
            cursor: pointer;
        }

        .top_banner_message__title,
        .top_banner__message__title,
        .top_banner_phone__title {
            margin-bottom: 0;
        }

        .top_banner_message__text,
        .top_banner_phone__text {
            margin-top: 28px;
        }

        .top_banner_message +.top_banner_phone {
            padding-top: 0;
        }
        order_message__btn +.order_phone__btn {
            margin-top: 5px;
        }
    }
}

.portfolio_controls__drag_container {
    height: 225px;
    width: 100%;
    border: 3px dashed #C6C7CE;
    border-radius: 6px;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    transition: border-color $animationTime $animationFunc;

    &.is-dragover {
        background-color: $bgColor;
    }

    &.is_error {
        border-color: $colorRed;
    }

    &:hover {
        border-color: $inputBorder;
    }

    @include for-desktop-up {
        display: flex;
    }
}

.dnd_mobile_btn {
    display: flex;
    height: 150px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;

    span {
        display: block;
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
        color: #262626;
        margin-top: 10px;
    }

    .button__attach {
        display: flex;
        position: relative;
    }

    .ic--clip {
        position: absolute;
        top: 10px;
        left: 13px;
    }

    @include for-desktop-up {
        display: none;
    }
}

.dnd_file_input {
    @include mobile {
        //margin-bottom: 41px;
    }
}

.cities_list {
    position: relative;

    .cities_list__items {
        position: absolute;
        top: 0;
        background: $colorWhite;
        box-shadow: 0 4px 8px 0 rgba(38, 38, 38, 0.20);
        border-radius: 5px;
        z-index: 99;
        width: 100%;
        max-height: 157px;
        overflow-y: scroll;
        text-align: left;

        .cities_list__item {
            padding: 15px 12px;
            cursor: pointer;

            &:hover {
                background: #ebebeb;
            }
        }
    }
}

.preselect_input {
    width: 100%;
    height: 100%;
}

input[readonly] {
    cursor: text;
    background-color: $colorWhite;
}

.master_stats {
    &.is_bottom {
        position: absolute;
        bottom: 0;
        width: 100%;

        .master {
            display: flex;
        }

        @include mobile {
            position: static !important;
        }
    }
}

.ruble {
    .fallback {
        display: none;
    }

    &:after {
        content: "\0020\20BD";
        font-size: inherit;
        font-family: inherit;
        color: currentColor;
    }
}

.field-city_id {
    background: $colorWhite;

    .input__wrapper {
        padding: 0 30px 0 9px;
        background: transparent;
    }
}

.field-city_id {
    &.dropdown--autocomplete:before {
        z-index: 1;
    }
}

.radio_item {
    display: flex;
    align-items: center;

    .radio_item__input {
        opacity: 0;
        visibility: hidden;
    }
}

.radio_item__input:checked + .radio_item__checked {
    background: $btnPrimaryColor;
    border-color: $btnPrimaryColor;

    &:before {
        width: 10px;
        height: 10px;
    }
}

.radio_item__text {
    font-size: 14px;
}

.radio_item__checked {
    width: 24px;
    height: 24px;
    position: relative;
    top: 0;
    border-radius: 50%;
    left: 0;
    display: block;
    border: 1px solid $lineColor;
    background: $colorWhite;
    margin-right: 15px;

    &:before {
        content: '';
        background-color: $colorWhite;
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .15s ease-in-out;
    }
}

.radio_item__checked-active {
    background: $btnPrimaryColor;
    border-color: $btnPrimaryColor;

    &:before {
        width: 10px;
        height: 10px;
    }
}

.check_item__text {
    font-size: 14px;
    color: $textPrimaryColor;
}

.select_spec_subheader {
    position: absolute;
    right: 0;
    top: 30px;
}

.close-dropdown {
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
    width: 20px;
    border: none;
    appearance: none;
    background-color: transparent;
    background-image: url(/media/images/cross.png);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
}

//temporal rule?
.phone_block.phone_is_confirmed .button--confirm {
    display: block !important;
    //pointer-events: none;
    opacity: 0.7;
}

.order_create_wrapper .order_signup .button--confirm {
    position: static;
    transform: none;
    width: 286px !important;
    min-width: 286px !important;

    @media (min-width: 600px) {
        width: calc(50% - 11px) !important;
        min-width: calc(50% - 11px) !important;
    }

    @media (max-width: 599px) {
        width: 280px !important;
        min-width: 280px !important;
        //width: calc(50% - 10px) !important;
        //min-width: calc(50% - 10px) !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
}

.order_create_wrapper .order_signup .phone_input__wrap:first-child {
  margin-right: 18px;
}

.phone_nowrap .phone_wrap {
    flex-wrap: nowrap !important;

    @media (min-width: 600px) {
        flex-direction: row;
    }

    @media (max-width: 599px) {
        flex-direction: column;
    }
}

.phone_input__wrap {
    width: 286px !important;
    min-width: 286px !important;

    @media (max-width: 900px) {
        width: calc(50% - 10px) !important;
        min-width: calc(50% - 10px) !important;
    }
    @media (max-width: 599px) {
        width: 100% !important;
        min-width: 100% !important;
    }
}
.check_item__checked-active {
    background: $btnPrimaryColor;
    border-color: $btnPrimaryColor;

    &:before {
        display: block;
    }
}

.file_container {
    margin-bottom: 5px;
    padding-top: 12px;
    max-height: 254px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    //overflow-y: auto;

    &:after {
        content: " ";
        clear: both;
        display: table;
    }

    @media (max-width: 1280px) {
        max-height: 100%;
    }
}
.portfolio_controls.dnd_file_input__label {
    padding: 0;
    margin: 0;
}
